import { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import CloseOutlined from '../../../../../assets/svg/CloseOutlined'
import Button from '../../../../../components/Button'
import TextField from '../../../../../components/TextField'

import AsyncSelect from '../../../../../components/AsyncSelect'
import { getClubs, playersClubRegistration } from '../../../../../lib/api'
import { sleep } from '../../../../../lib/helpers'
import './styles.scss'

const RegisterPlayerToClub = () => {
    const { id } = useParams()
    const navigate = useNavigate()

    const [transferForm, setTransferForm] = useState({
        joining_date: null,
        departure_date: null,
        player_uuid: id,
        club_uuid: '',
    })

    // Get all clubs
    const getClubList = async (searchValue, loadedOptions, { page }) => {
        await sleep(1000)

        let name = searchValue.split(' ')[0]

        let search_params = {
            name,
        }
        let { clubs, pgCount } = await getClubs(
            ['name', 'uuid'],
            search_params,
            null,
            page
        )

        if (clubs) {
            let list = []
            clubs.map((club) => {
                let clubDetails = {
                    value: `${club?.attributes?.name}`,
                    uuid: club?.attributes?.uuid,
                }
                list.push(clubDetails)
            })

            return {
                options: list,
                hasMore: page >= pgCount ? false : true,
                additional: {
                    page: page + 1,
                },
            }
        }
    }

    //navigate to competitor profile
    const handleClose = () => {
        navigate(`/takmicari/${id}`)
    }

    const handlePlayerClubRegistration = async () => {
        const registration = await playersClubRegistration(transferForm)

        if (registration) {
            handleClose()
        }
    }

    return (
        <Row className="competitor-modal">
            <Col>
                <Row className="my-4 me-md-3">
                    <Col className="d-flex align-items-center justify-content-end">
                        <CloseOutlined
                            className="close-icon"
                            onClick={handleClose}
                        />
                    </Col>
                </Row>

                <Row className="competitor-form-wrapper justify-content-center">
                    <Col md={4}>
                        <h1 className="text-center mb-2">Upiši takmičara</h1>

                        <Row>
                            <Col>
                                <TextField
                                    required
                                    className="mb-3"
                                    type="date"
                                    label="Datum upisa"
                                    value={transferForm?.joining_date || ''}
                                    onChange={(e) =>
                                        setTransferForm((prevState) => ({
                                            ...prevState,
                                            joining_date: e.target.value,
                                        }))
                                    }
                                />

                                <AsyncSelect
                                    className="mb-3"
                                    loadOptions={getClubList}
                                    onChange={(e) =>
                                        setTransferForm((prevState) => ({
                                            ...prevState,
                                            club_uuid: e.uuid,
                                        }))
                                    }
                                    label={'Klub u koji prelazi'}
                                    placeholder={
                                        transferForm?.club_uuid
                                            ? transferForm.club_uuid
                                            : 'Pretraži i izaberi klub...'
                                    }
                                    noOptionsMessage={'Nema više klubova'}
                                    closeMenuOnSelect={true}
                                    isMulti={false}
                                    required
                                />

                                <Row className="mt-4">
                                    <Col className="d-flex justify-content-end">
                                        <Button
                                            type="text"
                                            className="me-2"
                                            onClick={handleClose}
                                        >
                                            Odustani
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                handlePlayerClubRegistration()
                                            }
                                        >
                                            Upiši
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default RegisterPlayerToClub
