import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Link } from 'react-router-dom'
import { formatDate, formatImgUrl, handleGenders } from '../../lib/helpers'
import { generateAvatar } from '../../utils/generateAvatar'

const TournamentsTableCard = ({ isWhite, result, finished }) => {
    return (
        <Card
            className={`tournaments-table-card ${
                isWhite ? 'white-club-card' : 'grey-club-card'
            }`}
        >
            <Card.Body className="py-0">
                <Row>
                    <Col>
                        <Row className="car-row align-items-center">
                            <Col xs={5}>
                                <h4>Naziv</h4>
                            </Col>
                            <Col xs={7}>
                                <Link
                                    to={`/takmicenja/${result?.attributes?.uuid}`}
                                >
                                    <p className="link">
                                        {result?.attributes?.name}
                                    </p>
                                </Link>
                            </Col>
                        </Row>
                        <Row className="car-row align-items-center">
                            <Col xs={5}>
                                <h4>Organizator</h4>
                            </Col>
                            <Col xs={7} className="d-flex align-items-center">
                                {result?.attributes?.club_organizer?.attributes
                                    ?.name ? (
                                    <>
                                        <img
                                            src={
                                                result?.attributes
                                                    ?.club_organizer?.attributes
                                                    ?.logo?.data
                                                    ? formatImgUrl(
                                                          result?.attributes
                                                              ?.club_organizer
                                                              ?.attributes?.logo
                                                              ?.data?.attributes
                                                              ?.url
                                                      )
                                                    : generateAvatar(
                                                          result?.attributes
                                                              ?.club_organizer
                                                              ?.attributes?.name
                                                      )
                                            }
                                            alt={`Logo kluba`}
                                            width={'24px'}
                                            height={'24px'}
                                            style={{
                                                maxHeight: '24px',
                                                maxWidth: '24px',
                                                borderRadius: '50%',
                                            }}
                                        />
                                        <Link
                                            to={`/klubovi/${result?.attributes?.club_organizer?.attributes?.uuid}`}
                                        >
                                            <p className="ms-2 link">
                                                {
                                                    result?.attributes
                                                        ?.club_organizer
                                                        ?.attributes?.name
                                                }
                                            </p>
                                        </Link>
                                    </>
                                ) : (
                                    '-'
                                )}
                            </Col>
                        </Row>
                        <Row className="car-row align-items-center">
                            <Col xs={5}>
                                <h4>Prijava do</h4>
                            </Col>
                            <Col xs={7}>
                                <p>
                                    {formatDate(
                                        result?.attributes
                                            ?.application_deadline_date
                                    )}
                                </p>
                            </Col>
                        </Row>
                        <Row className="car-row align-items-center">
                            <Col xs={5}>
                                <h4>Odjava do</h4>
                            </Col>
                            <Col xs={7}>
                                <p>
                                    {formatDate(
                                        result?.attributes
                                            ?.checkout_deadline_date
                                    )}
                                </p>
                            </Col>
                        </Row>
                        <Row className="car-row align-items-center">
                            <Col xs={5}>
                                <h4>Pol</h4>
                            </Col>
                            <Col xs={7}>
                                <p>
                                    {result?.attributes?.genders
                                        ? handleGenders(
                                              result?.attributes?.genders
                                          )
                                        : '-'}
                                </p>
                            </Col>
                        </Row>
                        <Row
                            className={`car-row ${
                                !finished && 'border-bottom-0'
                            } align-items-center`}
                        >
                            <Col xs={5}>
                                <h4>Kategorija</h4>
                            </Col>
                            <Col xs={7}>
                                <p>
                                    {result?.attributes?.tournament_category
                                        ?.tournament_category_name
                                        ? result?.attributes
                                              ?.tournament_category
                                              ?.tournament_category_name
                                        : '-'}
                                </p>
                            </Col>
                        </Row>
                        <Row
                            className={`car-row ${
                                !finished && 'border-bottom-0'
                            } align-items-center`}
                        >
                            <Col xs={5}>
                                <h4>Bodovi</h4>
                            </Col>
                            <Col xs={7}>
                                <p>{result?.attributes?.ranking_points || 0}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default TournamentsTableCard
