import React from 'react'
import { Link } from 'react-router-dom'
import { formatDate, formatImgUrl, numbersOnly } from '../../lib/helpers'
import { generateAvatar } from '../../utils/generateAvatar'
import TextField from '../TextField'

const CompetitorsRankingTableRow = ({
    isManualPointsChangeEnabled,
    player,
    rankPosition,
    setPlayersList,
}) => {
    return (
        <tr>
            <td>
                <p>{rankPosition}.</p>
            </td>
            <td>
                <Link
                    to={`/takmicari/${player?.player_data?.attributes?.uuid}`}
                    className="link"
                >
                    {player?.player_data?.attributes?.person_info?.given_name}{' '}
                    {player?.player_data?.attributes?.person_info?.family_name}
                </Link>
            </td>
            <td className="d-flex align-items-center justify-content-start">
                {player?.club?.attributes?.name ? (
                    <>
                        <img
                            src={
                                player?.club?.attributes?.logo?.data
                                    ? formatImgUrl(
                                          player?.club?.attributes?.logo?.data
                                              ?.attributes?.url
                                      )
                                    : generateAvatar(
                                          player?.club?.attributes?.name
                                      )
                            }
                            alt={`Logo kluba`}
                            width={'24px'}
                            height={'24px'}
                            style={{
                                maxHeight: '24px',
                                maxWidth: '24px',
                                borderRadius: '50%',
                            }}
                        />
                        <Link to={`/klubovi/${player?.club?.attributes?.uuid}`}>
                            <p className="ms-2 link">
                                {player?.club?.attributes?.name}
                            </p>
                        </Link>
                    </>
                ) : (
                    <p>-</p>
                )}
            </td>
            <td>
                <p>
                    {formatDate(
                        player?.player_data?.attributes?.person_info?.birthdate
                    )}
                </p>
            </td>

            <td>
                {isManualPointsChangeEnabled ? (
                    <TextField
                        type="tel"
                        min={0}
                        value={player?.attributes?.total_points}
                        pattern="[0-9]*"
                        onChange={(e) => {
                            numbersOnly(e)
                            setPlayersList((prev) =>
                                prev.map((playerFromList) => {
                                    if (
                                        player?.attributes?.uuid !==
                                        playerFromList?.attributes?.uuid
                                    ) {
                                        return playerFromList
                                    }
                                    return {
                                        ...playerFromList,
                                        attributes: {
                                            ...playerFromList.attributes,
                                            total_points: +e.target.value,
                                        },
                                    }
                                })
                            )
                        }}
                    />
                ) : (
                    <p>{player?.attributes?.total_points}</p>
                )}
            </td>
        </tr>
    )
}

export default CompetitorsRankingTableRow
