import React from 'react'
import Table from 'react-bootstrap/Table'
import { isArrayEmpty } from '../../lib/helpers'
import NoDataForView from '../NoDataForView'
import PaginationWrapper from '../Pagination'
import SingleCompetitorRow from './SingleCompetitorRow'
import './styles.scss'

const TableCompetitorsPagination = ({
    className,
    data,
    currentPage,
    setCurrentPage,
    pageCount,
    setRefreshData,
    total,
    fromHomePage = false,
    tabValue,
    rowsPerPage,
    setRowsPerPage,
}) => {
    const handleNoDataViewDescription = (tab) => {
        switch (tab) {
            case 'svi':
                return `Ovde je prikazana lista svih takmičara.`
            case 'aktivni':
                return `Ovde je prikazana lista aktivnih takmičara.`
            case 'neaktivni':
                return `Ovde je prikazana lista neaktivnih takmičara.`
            case 'kaznjeni':
                return `Ovde je prikazana lista kažnjenih takmičara.`
            default:
                return 'Ovde je prikazana lista takmičara.'
        }
    }

    return !isArrayEmpty(data) ? (
        <div className={`table-competitors-pagination-wrapper ${className}`}>
            <Table className="mb-0">
                <thead>
                    <tr>
                        <th>
                            <h5>Takmičar</h5>
                        </th>
                        <th style={{ minWidth: '70px' }}>
                            <h5>Datum rođenja</h5>
                        </th>
                        <th>
                            <h5>Klub</h5>
                        </th>
                        <th>
                            <h5>Lekarski pregled</h5>
                        </th>
                        <th>
                            <h5>Status</h5>
                        </th>
                        {!fromHomePage && (
                            <>
                                <th></th>
                                <th></th>
                            </>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {data?.map((competitor, index) => (
                        <SingleCompetitorRow
                            competitor={competitor}
                            key={index}
                            setCurrentPage={setCurrentPage}
                            setRefreshData={setRefreshData}
                            fromHomePage={fromHomePage}
                            tabValue={tabValue}
                        />
                    ))}
                </tbody>
            </Table>
            <PaginationWrapper
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageCount={pageCount}
                total={total}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
            />
        </div>
    ) : (
        <NoDataForView
            title={`Nema takmičara`}
            description={handleNoDataViewDescription(tabValue)}
            className="d-none d-md-flex border-0"
        />
    )
}

export default TableCompetitorsPagination
