import React, { useState } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import Button from '../Button'
import AsyncSelect from '../AsyncSelect'
import { getTournamentClubsParticipantsList } from '../../lib/helpers'
import {
    createClubMatch,
    updateClubMatch,
    getSingleClubMatch,
} from '../../lib/api'
import RadioButton from '../RadioButton'

const CreateMatchModal = ({
    show,
    onHide,
    matchDetails,
    handleGetSingleMatch,
    onChange = false,
    createNextMatch = false,
}) => {
    const [matchData, setMatchData] = useState()

    const loadParticipants = async (
        inputValue,
        loadedOptions,
        paginationData
    ) => {
        return getTournamentClubsParticipantsList(
            inputValue,
            loadedOptions,
            paginationData,
            {
                tournament_uuid: matchDetails?.tournament_uuid,
                selected_side_1: matchData?.side_1_uuid || null,
                selected_side_2: matchData?.side_2_uuid || null,
                draw_type_name: matchDetails?.draw_type?.draw_type_name,
                round_number: matchDetails?.round_number,
                pair_number: matchDetails?.pair_number,
            }
        )
    }

    const handleSubmitMatch = async () => {
        try {
            let match
            if (matchDetails?.uuid) {
                match = await updateClubMatch(matchDetails?.uuid, {
                    side_1_uuid:
                        matchDetails?.sideOneData?.participant_uuid ||
                        matchData?.side_1_uuid,
                    side_2_uuid:
                        matchDetails?.sideTwoData?.participant_uuid ||
                        matchData?.side_2_uuid,
                })
            } else {
                if (
                    matchData?.side_1_uuid === '-1' ||
                    matchData?.side_2_uuid === '-1'
                ) {
                    matchData.loser_participant_uuid = '-1'
                }
                match = await createClubMatch(matchData)
            }
            if (match) {
                if (
                    createNextMatch &&
                    (matchData?.side_1_uuid === '-1' ||
                        matchData?.side_2_uuid === '-1')
                ) {
                    let participantObj = {}

                    if (matchData?.side_1_uuid === '-1') {
                        if (matchData.pair_number % 2 === 0) {
                            participantObj.side_2_uuid = matchData?.side_2_uuid
                        } else {
                            participantObj.side_1_uuid = matchData?.side_2_uuid
                        }
                    } else {
                        if (matchData.pair_number % 2 === 0) {
                            participantObj.side_2_uuid = matchData?.side_1_uuid
                        } else {
                            participantObj.side_1_uuid = matchData?.side_1_uuid
                        }
                    }

                    const {
                        side_1_uuid,
                        side_2_uuid,
                        loser_participant_uuid,
                        ...otherAttributes
                    } = matchData

                    const nextMatchData = await getSingleClubMatch({
                        ...matchDetails,
                        round_number: matchDetails?.round_number + 1,
                        pair_number: Math.floor(
                            (matchDetails?.pair_number + 1) / 2
                        ),
                    })

                    if (nextMatchData) {
                        await updateClubMatch(nextMatchData?.attributes?.uuid, {
                            ...participantObj,
                        })
                    } else {
                        await createClubMatch({
                            ...otherAttributes,
                            round_number: otherAttributes.round_number + 1,
                            pair_number: Math.floor(
                                (otherAttributes?.pair_number + 1) / 2
                            ),
                            ...participantObj,
                        })
                    }
                }

                onHide()
                if (onChange) {
                    onChange()
                } else {
                    handleGetSingleMatch()
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Modal
            size="lg"
            centered
            show={show}
            onHide={() => {
                setMatchData(null)
                onHide()
            }}
            dialogClassName="create-draw-pair-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>Dodaj par</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!matchDetails?.sideOneData?.participant_uuid && (
                    <Row>
                        <Col>
                            <AsyncSelect
                                loadOptions={loadParticipants}
                                onChange={(e) =>
                                    setMatchData((prev) => ({
                                        ...prev,
                                        side_1_uuid: e.uuid,
                                        ...matchDetails,
                                    }))
                                }
                                label={'Klub 1'}
                                placeholder={'Izaberi klub...'}
                                noOptionsMessage={'Nema slobodnih klubova'}
                                closeMenuOnSelect={true}
                                isMulti={false}
                                cacheUniqs={[
                                    matchData?.side_1_uuid || null,
                                    matchData?.side_2_uuid || null,
                                ]}
                            />
                        </Col>
                    </Row>
                )}
                {!matchDetails?.sideTwoData?.participant_uuid && (
                    <Row className="mt-3">
                        <Col>
                            <AsyncSelect
                                loadOptions={loadParticipants}
                                onChange={(e) =>
                                    setMatchData((prev) => ({
                                        ...prev,
                                        side_2_uuid: e.uuid,
                                        ...matchDetails,
                                    }))
                                }
                                label={'Klub 2'}
                                placeholder={'Izaberi klub...'}
                                noOptionsMessage={'Nema slobodnih klubova'}
                                closeMenuOnSelect={true}
                                isMulti={false}
                                cacheUniqs={[
                                    matchData?.side_1_uuid || null,
                                    matchData?.side_2_uuid || null,
                                ]}
                            />
                        </Col>
                    </Row>
                )}

                {matchData?.side_1_uuid &&
                    matchData?.side_2_uuid &&
                    matchData?.side_1_uuid !== '-1' &&
                    matchData?.side_2_uuid !== '-1' && (
                        <Row className="mt-3">
                            <Col>
                                <RadioButton
                                    name="Domaćin susreta"
                                    radioValues={['Klub 1', 'Klub 2']}
                                    onChange={(e) => {
                                        e.target.value === 'Klub 1'
                                            ? setMatchData((prev) => ({
                                                  ...prev,
                                                  match_host_uuid:
                                                      matchData?.side_1_uuid,
                                              }))
                                            : setMatchData((prev) => ({
                                                  ...prev,
                                                  match_host_uuid:
                                                      matchData?.side_2_uuid,
                                              }))
                                    }}
                                    checkedValue={
                                        matchData?.match_host_uuid ===
                                        matchData?.side_1_uuid
                                            ? 'Klub 1'
                                            : matchData?.match_host_uuid ===
                                              matchData?.side_2_uuid
                                            ? 'Klub 2'
                                            : ''
                                    }
                                />
                            </Col>
                        </Row>
                    )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    type="text"
                    onClick={() => {
                        setMatchData(null)
                        onHide()
                    }}
                >
                    Odustani
                </Button>
                <Button onClick={() => handleSubmitMatch()}>Dodaj par</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CreateMatchModal
