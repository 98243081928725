import React from 'react'
import Table from 'react-bootstrap/Table'
import { Link } from 'react-router-dom'

import { generateAvatar } from '../../utils/generateAvatar'
import PaginationWrapper from '../Pagination'
import './styles.scss'

const TableCoachesPagination = ({
    data,
    setCurrentPage,
    currentPage,
    pageCount,
    total,
    rowsPerPage,
    setRowsPerPage,
}) => {
    return (
        <div className="table-couches-pagination-wrapper d-none d-md-block">
            <Table className="mb-0">
                <thead>
                    <tr>
                        <th>
                            <h5>Trener</h5>
                        </th>
                        <th className="d-flex justify-content-md-center">
                            <h5>Nacionalna licenca</h5>
                        </th>
                        <th className="d-flex justify-content-md-center">
                            <h5>ITF licenca</h5>
                        </th>
                        <th className="d-flex justify-content-md-center">
                            <h5>GPTCA licenca</h5>
                        </th>
                        <th className="d-flex justify-content-md-center">
                            <h5>USPTA licenca</h5>
                        </th>
                        <th className="d-flex justify-content-md-center">
                            <h5>LTA licenca</h5>
                        </th>
                        <th className="d-flex justify-content-md-center">
                            <h5>PTR licenca</h5>
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((coach) => (
                        <tr key={coach?.attributes?.uuid}>
                            <td className="link">
                                <div className="d-flex align-items-center justify-content-start">
                                    <img
                                        src={
                                            coach?.attributes?.person_info
                                                ?.picture
                                                ? coach?.attributes?.person_info
                                                      ?.picture
                                                : generateAvatar(
                                                      `${coach?.attributes?.person_info?.given_name} ${coach?.attributes?.person_info?.family_name}`
                                                  )
                                        }
                                        alt={`${coach?.attributes?.person_info?.given_name} ${coach?.attributes?.person_info?.family_name}`}
                                        className="me-2 table-coaches-pagination-coach-avatar"
                                        height={'24px'}
                                        width={'24px'}
                                    />
                                    <Link
                                        to={`/treneri/${coach?.attributes?.uuid}`}
                                    >
                                        <p className="link">
                                            {
                                                coach?.attributes?.person_info
                                                    ?.given_name
                                            }{' '}
                                            {
                                                coach?.attributes?.person_info
                                                    ?.family_name
                                            }
                                        </p>
                                    </Link>
                                </div>
                            </td>

                            <td className="d-flex justify-content-md-center">
                                <p>
                                    {coach?.attributes?.licenses?.attributes
                                        ?.national_license
                                        ? coach?.attributes?.licenses
                                              ?.attributes?.national_license
                                              ?.national_license_name
                                        : '-'}
                                </p>
                            </td>
                            <td className="d-flex justify-content-md-center">
                                <p>
                                    {coach?.attributes?.licenses?.attributes
                                        ?.itf_license
                                        ? coach?.attributes?.licenses
                                              ?.attributes?.itf_license
                                              ?.itf_license_name
                                        : '-'}
                                </p>
                            </td>
                            <td className="d-flex justify-content-md-center">
                                <p>
                                    {coach?.attributes?.licenses?.attributes
                                        ?.gptca_license
                                        ? coach?.attributes?.licenses
                                              ?.attributes?.gptca_license
                                              ?.gptca_license_name
                                        : '-'}
                                </p>
                            </td>
                            <td className="d-flex justify-content-md-center">
                                <p>
                                    {coach?.attributes?.licenses?.attributes
                                        ?.uspta_license
                                        ? coach?.attributes?.licenses
                                              ?.attributes?.uspta_license
                                              ?.uspta_license_name
                                        : '-'}
                                </p>
                            </td>
                            <td className="d-flex justify-content-md-center">
                                <p>
                                    {coach?.attributes?.licenses?.attributes
                                        ?.lta_license
                                        ? coach?.attributes?.licenses
                                              ?.attributes?.lta_license
                                              ?.lta_license_name
                                        : '-'}
                                </p>
                            </td>
                            <td className="d-flex justify-content-md-center">
                                <p>
                                    {coach?.attributes?.licenses?.attributes
                                        ?.ptr_license
                                        ? coach?.attributes?.licenses
                                              ?.attributes?.ptr_license
                                              ?.ptr_license_name
                                        : '-'}
                                </p>
                            </td>
                            <td>
                                <Link
                                    to={`/treneri/${coach?.attributes?.uuid}/izmeni-profil-trenera`}
                                >
                                    <p className="link">Izmeni</p>
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <PaginationWrapper
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageCount={pageCount}
                total={total}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
            />
        </div>
    )
}

export default TableCoachesPagination
