import React from 'react'
import Table from 'react-bootstrap/Table'
import { Link } from 'react-router-dom'
import {
    formatDate,
    formatImgUrl,
    handleGenders,
    isArrayEmpty,
} from '../../lib/helpers'
import { generateAvatar } from '../../utils/generateAvatar'

import NoDataForView from '../NoDataForView'
import PaginationWrapper from '../Pagination'
import './styles.scss'

const TournamentsTable = ({
    data,
    finished,
    className,
    currentPage,
    setCurrentPage,
    pageCount,
    total,
    rowsPerPage,
    setRowsPerPage,
}) => {
    return !isArrayEmpty(data) ? (
        <div
            className={`tournaments-table-wrapper table-competitors-pagination-wrapper ${className}`}
        >
            <Table className="mb-0">
                <thead>
                    <tr>
                        <th>
                            <h5>Naziv</h5>
                        </th>
                        <th>
                            <h5>Organizator</h5>
                        </th>
                        <th>
                            <h5>Prijava do</h5>
                        </th>
                        <th>
                            <h5>Odjava do</h5>
                        </th>
                        <th>
                            <h5>Pol</h5>
                        </th>
                        <th>
                            <h5>Kategorija</h5>
                        </th>
                        <th>
                            <h5>Bodovi</h5>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((result, index) => (
                        <tr key={index}>
                            <td className="align-middle">
                                <Link
                                    to={`/takmicenja/${result?.attributes?.uuid}`}
                                >
                                    <p className="link">
                                        {result?.attributes?.name}
                                    </p>
                                </Link>
                            </td>
                            <td className="d-flex align-middle justify-content-start">
                                {result?.attributes?.club_organizer?.attributes
                                    ?.name ? (
                                    <>
                                        <img
                                            src={
                                                result?.attributes
                                                    ?.club_organizer?.attributes
                                                    ?.logo?.data
                                                    ? formatImgUrl(
                                                          result?.attributes
                                                              ?.club_organizer
                                                              ?.attributes?.logo
                                                              ?.data?.attributes
                                                              ?.url
                                                      )
                                                    : generateAvatar(
                                                          result?.attributes
                                                              ?.club_organizer
                                                              ?.attributes?.name
                                                      )
                                            }
                                            alt={`Logo kluba`}
                                            width={'24px'}
                                            height={'24px'}
                                            style={{
                                                maxHeight: '24px',
                                                maxWidth: '24px',
                                                borderRadius: '50%',
                                            }}
                                        />
                                        <Link
                                            to={`/klubovi/${result?.attributes?.club_organizer?.attributes?.uuid}`}
                                        >
                                            <p className="ms-2 link">
                                                {
                                                    result?.attributes
                                                        ?.club_organizer
                                                        ?.attributes?.name
                                                }
                                            </p>
                                        </Link>
                                    </>
                                ) : (
                                    '-'
                                )}
                            </td>
                            <td className="align-middle">
                                <p>
                                    {formatDate(
                                        result?.attributes
                                            ?.application_deadline_date
                                    )}
                                </p>
                            </td>
                            <td>
                                <p>
                                    {formatDate(
                                        result?.attributes
                                            ?.checkout_deadline_date
                                    )}
                                </p>
                            </td>
                            <td className="align-middle">
                                <p>
                                    {result?.attributes?.genders
                                        ? handleGenders(
                                              result?.attributes?.genders
                                          )
                                        : '-'}
                                </p>
                            </td>
                            <td className="align-middle">
                                <p>
                                    {result?.attributes?.tournament_category
                                        ?.tournament_category_name
                                        ? result?.attributes
                                              ?.tournament_category
                                              ?.tournament_category_name
                                        : '-'}
                                </p>
                            </td>
                            <td className="align-middle">
                                <p>{result?.attributes?.ranking_points || 0}</p>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <PaginationWrapper
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageCount={pageCount}
                total={total}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
            />
        </div>
    ) : (
        <NoDataForView
            title={`Nema ${finished ? 'završenih' : 'nadolazećih'} takmičenja`}
            description={`Ovde je prikazana lista ${
                finished ? 'završenih' : 'nadolazećih'
            } takmičenja.`}
            className="border-0"
        />
    )
}

export default TournamentsTable
