import { useEffect, useState } from 'react'
import { Breadcrumb, Card, Col, Row, Tab, Tabs } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Call from '../../../../assets/svg/Call'
import Change from '../../../../assets/svg/Change'
import DocumentTextOutlined from '../../../../assets/svg/DocumentTextOutlined'
import Flag from '../../../../assets/svg/Flag'
import Person from '../../../../assets/svg/Person'
import PrintOutlined from '../../../../assets/svg/PrintOutlined'
import Trash from '../../../../assets/svg/Trash'
import Wallet from '../../../../assets/svg/Wallet'
import Button from '../../../../components/Button'
import ConfirmationModal from '../../../../components/ConfirmationModal'
import IconInfoCard from '../../../../components/IconInfoCard'
import './styles.scss'

import jsPDF from 'jspdf'
import 'jspdf-autotable'
import ArrowRight from '../../../../assets/svg/ArrowRight'
import MedicalHistory from '../../../../components/MedicalHistory'
import ProfileCard from '../../../../components/ProfileCard'
import {
    deleteReferee,
    getLastMedicalCerificate,
    getRefereeLicense,
    getRefereeTest,
    getSingleReferee,
} from '../../../../lib/api'
import { useAppContext } from '../../../../lib/contextLib'
import { formatDate } from '../../../../lib/helpers'
import { generateAvatar } from '../../../../utils/generateAvatar'
import { font } from '../../Competitions/CompetitionProfile/font'

const RefereeProfile = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const { setShowCustomToast } = useAppContext()
    const [refeereeLicense, setRefeereeLicense] = useState()
    const [lastMedicalCertificate, setLastMedicalCertificate] = useState()
    const [refereeTest, setRefereeTest] = useState([])

    const [selectedTab, setSelectedTab] = useState('profil')
    const [data, setData] = useState({})
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [refreshData, setRefreshData] = useState(false)
    const [showMedicalHistory, setShowMedicalHistory] = useState(false)

    const getRefereesTests = async () => {
        const refereesTests = await getRefereeTest(id)
        if (refereesTests) {
            setRefereeTest(refereesTests?.attributes)
        }
    }

    const getRefereeProfile = async (id) => {
        let referee = await getSingleReferee(id)
        if (referee) {
            setData(referee)
        } else {
            navigate('/sudije')
        }
    }

    const handleRefereeLicense = async () => {
        const licenses = await getRefereeLicense(id)

        if (licenses) {
            setRefeereeLicense(licenses)
        }
    }

    useEffect(() => {
        getRefereeProfile(id)
        setRefreshData(false)
        handleRefereeLicense(id)
        handleLastMedicalCertificate()
        getRefereesTests()
    }, [id, refreshData])

    // Function for deleting referee
    const handleDeleteReferee = async (refId) => {
        try {
            let deletedReferee = await deleteReferee(refId)

            if (deletedReferee) {
                navigate('/sudije')
                setShowCustomToast({
                    show: true,
                    type: 'success',
                    message: 'Sudija je obrisan.',
                })
            }
        } catch (error) {
            setShowCustomToast({
                show: true,
                type: 'error',
                message: 'Došlo je do greške.',
            })
        }
    }

    // Function for opening delete modal
    const handleOpenDeleteModal = () => {
        setShowDeleteModal(!showDeleteModal)
    }

    const createDoc = (orientation, unit, size, font) => {
        const marginLeft = 40
        const doc = new jsPDF(orientation, unit, size)
        doc.addFileToVFS('WorkSans-normal.ttf', font)
        doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
        doc.setFont('WorkSans')
        doc.setFontSize(15)

        const title = `${data?.attributes?.person_info?.given_name} ${
            data?.attributes?.person_info?.family_name
        } - ${formatDate(new Date())} - ${new Date().toLocaleTimeString()}`
        doc.text(title, marginLeft, 40)

        const generalInfo = [
            [
                'Ime',
                data?.attributes?.person_info?.given_name
                    ? data?.attributes?.person_info?.given_name
                    : 'Nema informacije',
            ],
            [
                'Prezime',
                data?.attributes?.person_info?.family_name
                    ? data?.attributes?.person_info?.family_name
                    : 'Nema informacije',
            ],
            [
                'Datum rođenja',
                data?.attributes?.person_info?.birthdate
                    ? formatDate(data?.attributes?.person_info?.birthdate)
                    : 'Nema informacije',
            ],
            [
                'Mesto rođenja',
                data?.attributes?.birth_address?.city
                    ? data?.attributes?.birth_address?.city
                    : 'Nema informacije',
            ],
            [
                'Pol',
                data?.attributes?.gender?.gender_name
                    ? data?.attributes?.gender?.gender_name
                    : 'Nema informacije',
            ],
            [
                'Zaposlen/Nezaposlen',
                data?.attributes?.is_employed === true ||
                data?.attributes?.is_employed === false
                    ? data?.attributes?.is_employed
                        ? 'Zaposlen'
                        : 'Nezaposlen'
                    : 'Nema informacije',
            ],
            [
                'Roditelj',
                data?.attributes?.parent_name
                    ? data?.attributes?.parent_name
                    : 'Nema informacije',
            ],
            [
                'Stručna sprema',
                data?.attributes?.professional_training
                    ? data?.attributes?.professional_training
                    : 'Nema informacije',
            ],
            [
                'Region',
                data?.attributes?.region?.region_name
                    ? data?.attributes?.region?.region_name
                    : 'Nema informacije',
            ],
            [
                'Telefon',
                data?.attributes?.person_info?.phone
                    ? data?.attributes?.person_info?.phone
                    : 'Nema informacije',
            ],
            [
                'Email',
                data?.attributes?.person_info?.email
                    ? data?.attributes?.person_info?.email
                    : 'Nema informacije',
            ],
            [
                'Broj računa i banka',
                `${
                    data?.attributes?.bank_account_number
                        ? data?.attributes?.bank_account_number
                        : 'Nema informacije o računu'
                }, ${
                    data?.attributes?.bank_name
                        ? data?.attributes?.bank_name
                        : 'Nema informacije o banci'
                }`,
            ],
        ]

        let generalInfoContent = {
            startY: 50,
            head: [['Opšte informacije', ' ']],
            body: generalInfo,
            styles: {
                font: 'WorkSans',
            },
            columnStyles: {
                0: { cellWidth: 250 },
            },
        }

        doc.autoTable(generalInfoContent)

        // Licenses

        const licencesInfo = [
            [
                `Licenca: ${
                    refeereeLicense?.attributes?.core_license?.core_license_name
                        ? refeereeLicense?.attributes?.core_license
                              ?.core_license_name
                        : 'Nema informacije'
                }`,
                `Datum sticanja ${
                    refeereeLicense?.attributes?.core_license
                        ?.core_license_acquisition_date
                        ? formatDate(
                              refeereeLicense?.attributes?.core_license
                                  ?.core_license_acquisition_date
                          )
                        : 'Nema informacije'
                }`,
            ],
            [
                `Tip licence: ${
                    refeereeLicense?.attributes?.national_license_type
                        ?.national_license_type_name
                        ? refeereeLicense?.attributes?.national_license_type
                              ?.national_license_type_name
                        : 'Nema informacije'
                }`,
                `Datum sticanja: ${
                    refeereeLicense?.attributes?.national_license_type
                        ?.national_license_type_acquisition_date
                        ? formatDate(
                              refeereeLicense?.attributes?.national_license_type
                                  ?.national_license_type_acquisition_date
                          )
                        : 'Nema informacije'
                }`,
            ],
            [
                `Bedž (sudija u stolici): ${
                    refeereeLicense?.attributes?.international_chair_badge
                        ?.international_chair_badge_name
                        ? refeereeLicense?.attributes?.international_chair_badge
                              ?.international_chair_badge_name
                        : 'Nema informacije'
                }`,
                `Datum sticanja: ${
                    refeereeLicense?.attributes?.international_chair_badge
                        ?.international_chair_badge_acquisition_date
                        ? formatDate(
                              refeereeLicense?.attributes
                                  ?.international_chair_badge
                                  ?.international_chair_badge_acquisition_date
                          )
                        : 'Nema informacije'
                }`,
            ],
            [
                `Bedž (glavni sudija): ${
                    refeereeLicense?.attributes?.international_main_badge
                        ?.international_main_badge_name
                        ? refeereeLicense?.attributes?.international_main_badge
                              ?.international_main_badge_name
                        : 'Nema informacije'
                }`,
                `Datum sticanja: ${
                    refeereeLicense?.attributes?.international_main_badge
                        ?.international_main_badge_acquisition_date
                        ? formatDate(
                              refeereeLicense?.attributes
                                  ?.international_main_badge
                                  ?.international_main_badge_acquisition_date
                          )
                        : 'Nema informacije'
                }`,
            ],
        ]

        let licensesContent = {
            startY: doc.lastAutoTable.finalY + 20,
            head: [['Informacije o licencama', ' ']],
            body: licencesInfo,
            styles: {
                font: 'WorkSans',
            },
            columnStyles: {
                0: { cellWidth: 250 },
            },
        }

        doc.autoTable(licensesContent)

        if (refereeTest?.test_date) {
            const testInfo = [
                [
                    'Datum testiranja:',
                    refereeTest?.test_date
                        ? formatDate(refereeTest?.test_date)
                        : 'Nema informacije',
                ],
                ['Rezultat testiranja', refereeTest?.test_result],
            ]

            let refereeTestContent = {
                startY: doc.lastAutoTable.finalY + 20,
                head: [['Informacija o testiranju', ' ']],
                body: testInfo,
                styles: {
                    font: 'WorkSans',
                },
                columnStyles: {
                    0: { cellWidth: 250 },
                },
            }

            doc.autoTable(refereeTestContent)
        }

        return doc
    }

    const handleExportRefereePDF = () => {
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'portrait' // portrait or landscape

        const doc = createDoc(orientation, unit, size, font)

        doc.save(
            `Sudija_${data?.attributes?.person_info?.given_name}_${
                data?.attributes?.person_info?.family_name
            }_${new Date().toLocaleTimeString()}.pdf`
        )
    }

    const handlePrintReferee = () => {
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'portrait' // portrait or landscape

        const doc = createDoc(orientation, unit, size, font)

        // for printing
        doc.autoPrint()
        doc.output('dataurlnewwindow')
    }

    const handleLastMedicalCertificate = async () => {
        const lastMedicalCertificate = await getLastMedicalCerificate(id)

        if (lastMedicalCertificate) {
            setLastMedicalCertificate(lastMedicalCertificate[0])
        }
    }

    return (
        <Row className="referee-profile-wrapper h-100">
            {!showMedicalHistory ? (
                <>
                    <Col md={12}>
                        <Row className="pt-3">
                            <Col xs={12} md={6} className="d-flex align-center">
                                <Row>
                                    <Col md={12}>
                                        <h2 className="d-none d-md-block">
                                            Sudije
                                        </h2>
                                        <p className="d-block d-md-none">
                                            Sudije
                                        </p>
                                        <Breadcrumb className="mt-md-2 mt-1">
                                            <Breadcrumb.Item
                                                linkAs={Link}
                                                linkProps={{ to: '/sudije' }}
                                            >
                                                Sudije
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item active>
                                                {`${data?.attributes?.person_info?.given_name} ${data?.attributes?.person_info?.family_name}`}
                                            </Breadcrumb.Item>
                                        </Breadcrumb>
                                    </Col>
                                </Row>
                                <div className="ms-5 d-flex align-items-center d-md-none">
                                    <Button
                                        type="text"
                                        onClick={() =>
                                            navigate(
                                                `/sudije/${data?.attributes?.uuid}/izmeni-profil-sudije`
                                            )
                                        }
                                    >
                                        <Change height="24px" width="24px" />
                                    </Button>
                                    <Button
                                        type="text"
                                        onClick={handleOpenDeleteModal}
                                    >
                                        <Trash />
                                    </Button>
                                </div>
                            </Col>
                            <Col md={6} className="d-none d-md-block">
                                <Row className="d-flex align-items-center justify-content-end">
                                    <Col
                                        md={7}
                                        className="ps-0 pe-3 d-flex justify-content-end"
                                    >
                                        <Button
                                            type="text"
                                            leadingIcon={
                                                <DocumentTextOutlined />
                                            }
                                            onClick={() => {
                                                handleExportRefereePDF()
                                            }}
                                            className="me-2"
                                        >
                                            Eksportuj u PDF
                                        </Button>
                                        <Button
                                            type="secondary"
                                            leadingIcon={<PrintOutlined />}
                                            onClick={() => {
                                                handlePrintReferee()
                                            }}
                                        >
                                            Štampaj
                                        </Button>
                                        <Button
                                            type="secondary"
                                            leadingIcon={
                                                <Change height="24px" />
                                            }
                                            className="mx-2"
                                            onClick={() =>
                                                navigate(
                                                    `/sudije/${data?.attributes?.uuid}/izmeni-profil-sudije`
                                                )
                                            }
                                        >
                                            Izmeni
                                        </Button>
                                        <Button
                                            type="secondary"
                                            leadingIcon={<Trash />}
                                            onClick={handleOpenDeleteModal}
                                        >
                                            Obriši
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="m-0 card-profile">
                            <ProfileCard
                                className="referee-background"
                                name={`${data?.attributes?.person_info?.given_name} ${data?.attributes?.person_info?.family_name}`}
                                email={data?.attributes?.email}
                                avatar={
                                    data?.attributes?.person_info?.picture
                                        ? data?.attributes?.person_info?.picture
                                        : generateAvatar(
                                              `${data?.attributes?.person_info?.given_name} ${data?.attributes?.person_info?.family_name}`
                                          )
                                }
                            />
                            <Col
                                md={12}
                                className="pt-0 pt-md-4 d-flex align-items-center justify-content-start justify-content-md-end"
                            >
                                <Tabs
                                    id="uncontrolled-tab-example"
                                    activeKey={selectedTab}
                                    onSelect={(tab) => setSelectedTab(tab)}
                                >
                                    <Tab
                                        eventKey="profil"
                                        title={
                                            <>
                                                <Person width="20px" />
                                                <p className="ms-2">Profil</p>
                                            </>
                                        }
                                        tabClassName="d-flex"
                                    ></Tab>
                                </Tabs>
                            </Col>
                        </Row>
                        {/* Kartice */}
                        <Row className="mt-4 m-0 info-cards-wrapper">
                            <Col xs={12} md className="px-0">
                                <Row>
                                    <Col xs={12}>
                                        <IconInfoCard
                                            icon={<Call />}
                                            title="Kontakt"
                                            className="mb-2 mb-md-3"
                                        >
                                            <span className="mb-3 d-flex align-items-start flex-wrap">
                                                <h5 className="me-1">
                                                    Adresa:
                                                </h5>
                                                <p className="card-title-color">
                                                    {data?.attributes
                                                        ?.residential_address
                                                        ?.street &&
                                                        data?.attributes
                                                            ?.residential_address
                                                            ?.street + ', '}
                                                    {
                                                        data?.attributes
                                                            ?.residential_address
                                                            ?.city
                                                    }
                                                </p>
                                            </span>

                                            <span className="mb-3 d-flex align-items-center">
                                                <h5 className="me-1">
                                                    Telefon:
                                                </h5>
                                                <p className="card-title-color">
                                                    {
                                                        data?.attributes
                                                            ?.person_info?.phone
                                                    }
                                                </p>
                                            </span>

                                            <span className="d-flex align-items-center flex-wrap">
                                                <h5 className="me-1">Email:</h5>
                                                <p className="card-title-color">
                                                    {
                                                        data?.attributes
                                                            ?.person_info?.email
                                                    }
                                                </p>
                                            </span>
                                        </IconInfoCard>
                                    </Col>
                                    <Col xs={12}>
                                        <IconInfoCard
                                            icon={<Person />}
                                            title="Opšte informacije"
                                            className="mb-2 mb-md-3"
                                        >
                                            <span className="mb-3 d-flex align-items-center flex-wrap">
                                                <h5 className="me-1">
                                                    Ime i prezime:
                                                </h5>
                                                <p className="card-title-color">
                                                    {`${data?.attributes?.person_info?.given_name} ${data?.attributes?.person_info?.family_name}`}
                                                </p>
                                            </span>

                                            <span className="mb-3 d-flex align-items-center">
                                                <h5 className="me-1">
                                                    Datum rođenja:
                                                </h5>
                                                <p className="card-title-color">
                                                    {data?.attributes
                                                        ?.person_info?.birthdate
                                                        ? formatDate(
                                                              data?.attributes
                                                                  ?.person_info
                                                                  ?.birthdate
                                                          )
                                                        : 'Nema informacija'}
                                                </p>
                                            </span>

                                            <span className="mb-3 d-flex align-items-center">
                                                <h5 className="me-1">JMBG:</h5>
                                                <p className="card-title-color">
                                                    {data?.attributes
                                                        ?.person_info?.jmbg
                                                        ? data?.attributes
                                                              ?.person_info
                                                              ?.jmbg
                                                        : 'Nema informacija'}
                                                </p>
                                            </span>

                                            <span className="mb-3 d-flex align-items-center flex-wrap">
                                                <h5 className="me-1">
                                                    Mesto rođenja:
                                                </h5>
                                                <p className="card-title-color">
                                                    {data?.attributes
                                                        ?.birth_address?.city
                                                        ? data?.attributes
                                                              ?.birth_address
                                                              ?.city
                                                        : 'Nema informacija'}
                                                </p>
                                            </span>
                                            <span className="mb-3 d-flex align-items-center">
                                                <h5 className="me-1">Pol:</h5>
                                                <p className="card-title-color">
                                                    {data?.attributes?.gender
                                                        ? data?.attributes
                                                              ?.gender
                                                              ?.gender_name
                                                        : 'Nema informacija'}
                                                </p>
                                            </span>

                                            <span className="mb-3 d-flex align-items-center">
                                                <h5 className="me-1">
                                                    Zaposlen/Nezaposlen:
                                                </h5>
                                                <p className="card-title-color">
                                                    {data?.attributes
                                                        ?.is_employed ===
                                                        true ||
                                                    data?.attributes
                                                        ?.is_employed === false
                                                        ? data?.attributes
                                                              ?.is_employed
                                                            ? 'Zaposlen'
                                                            : 'Nezaposlen'
                                                        : 'Nema informacija'}
                                                </p>
                                            </span>
                                            <span className="mb-3 d-flex align-items-center">
                                                <h5 className="me-1">
                                                    Roditelj:
                                                </h5>
                                                <p className="card-title-color">
                                                    {data?.attributes
                                                        ?.parent_name
                                                        ? data?.attributes
                                                              ?.parent_name
                                                        : 'Nema informacija'}
                                                </p>
                                            </span>
                                            <span className="d-flex align-items-center">
                                                <h5 className="me-1">
                                                    Stručna sprema:
                                                </h5>
                                                <p className="card-title-color">
                                                    {data?.attributes
                                                        ?.professional_training
                                                        ? data?.attributes
                                                              ?.professional_training
                                                        : 'Nema informacija'}
                                                </p>
                                            </span>
                                        </IconInfoCard>

                                        <Card className="referee-region-card">
                                            <Card.Body>
                                                <h2 className="mb-2">
                                                    {data?.attributes?.region
                                                        ? data?.attributes
                                                              ?.region
                                                              ?.region_name
                                                        : 'Nema informacija'}
                                                </h2>
                                                <p className="mb-2">Region</p>
                                            </Card.Body>
                                        </Card>

                                        {refereeTest?.test_date && (
                                            <Card className="referee-region-card mt-3">
                                                <Card.Body>
                                                    <h2 className="mb-2">
                                                        Testiranje
                                                    </h2>
                                                    <p className="mb-2">
                                                        Datum testiranja:{' '}
                                                        {formatDate(
                                                            refereeTest?.test_date
                                                        )}
                                                    </p>
                                                    <p className="mb-2">
                                                        Rezultat testiranja:{' '}
                                                        {
                                                            refereeTest?.test_result
                                                        }
                                                    </p>
                                                </Card.Body>
                                            </Card>
                                        )}
                                    </Col>
                                </Row>
                            </Col>

                            <Col xs={12} md className="px-0">
                                <IconInfoCard
                                    icon={<Flag />}
                                    title={
                                        refeereeLicense?.attributes
                                            ?.core_license?.core_license_name ||
                                        'Nema informacija'
                                    }
                                    className="mb-2 mb-md-3"
                                    subtitle={'Licenca'}
                                >
                                    {refeereeLicense?.attributes
                                        ?.national_license_type
                                        ?.national_license_type_name ? (
                                        <>
                                            <h5 className="account-card mt-3">
                                                {
                                                    refeereeLicense?.attributes
                                                        ?.national_license_type
                                                        ?.national_license_type_name
                                                }
                                            </h5>
                                            <p className="mb-3">Tip licence</p>
                                        </>
                                    ) : null}

                                    {refeereeLicense?.attributes
                                        ?.international_chair_badge
                                        ?.international_chair_badge_name ? (
                                        <>
                                            <h5 className="account-card">
                                                {
                                                    refeereeLicense?.attributes
                                                        ?.international_chair_badge
                                                        ?.international_chair_badge_name
                                                }
                                            </h5>
                                            <p className="mb-3">
                                                Bedž (sudija u stolici)
                                            </p>
                                        </>
                                    ) : null}

                                    {refeereeLicense?.attributes
                                        ?.international_main_badge
                                        ?.international_main_badge_name ? (
                                        <>
                                            <h5 className="account-card">
                                                {
                                                    refeereeLicense?.attributes
                                                        ?.international_main_badge
                                                        ?.international_main_badge_name
                                                }
                                            </h5>
                                            <p>Bedž (glavni sudija)</p>
                                        </>
                                    ) : null}

                                    {refeereeLicense?.attributes
                                        ?.coordinators_club_uuid ? (
                                        <>
                                            <Link
                                                className="link"
                                                to={`/klubovi/${refeereeLicense?.attributes?.coordinators_club_uuid}`}
                                            >
                                                Koorinator kluba: Link kluba
                                            </Link>
                                        </>
                                    ) : null}
                                </IconInfoCard>
                                <Row>
                                    <Col>
                                        <IconInfoCard
                                            icon={<Wallet />}
                                            className="mb-2 mb-md-3"
                                            title={
                                                data?.attributes?.bank_name ||
                                                data?.attributes
                                                    ?.bank_account_number
                                                    ? 'Tekući račun'
                                                    : 'Nema informacija'
                                            }
                                            subtitle={
                                                data?.attributes?.bank_name ||
                                                data?.attributes
                                                    ?.bank_account_number
                                                    ? ''
                                                    : 'Tekući račun'
                                            }
                                        >
                                            <h5 className="account-card">
                                                {data?.attributes?.bank_name}
                                            </h5>
                                            <p className="mb-3">
                                                {data?.attributes?.bank_name
                                                    ? 'Naziv banke'
                                                    : ''}
                                            </p>
                                            <h5 className="account-card">
                                                {
                                                    data?.attributes
                                                        ?.bank_account_number
                                                }
                                            </h5>
                                            <p>
                                                {data?.attributes
                                                    ?.bank_account_number
                                                    ? 'Broj računa'
                                                    : ''}
                                            </p>
                                        </IconInfoCard>
                                    </Col>
                                </Row>

                                <Card className="competitor-flow-profile-card-wrapper">
                                    <Card.Body className="competitor-flow-profile-card-body membership-card">
                                        <Row className="pb-5">
                                            <Col className="d-flex align-items-center">
                                                <h2>Lekarski pregled</h2>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="membership-view">
                                                <Col
                                                    className={`d-flex align-items-center justify-content-center single-membership`}
                                                >
                                                    <p
                                                        className={`membership-year`}
                                                    >
                                                        {lastMedicalCertificate
                                                            ? `${formatDate(
                                                                  lastMedicalCertificate
                                                                      ?.attributes
                                                                      ?.start_date
                                                              )} - ${formatDate(
                                                                  lastMedicalCertificate
                                                                      ?.attributes
                                                                      ?.end_date
                                                              )}`
                                                            : 'Nema lekarsko uverenje.'}
                                                    </p>
                                                </Col>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                    <Card.Footer className="competitor-flow-profile-card-footer">
                                        <Row>
                                            <Col className="d-flex align-items-center justify-content-end">
                                                <Button
                                                    type="text"
                                                    endIcon={<ArrowRight />}
                                                    onClick={() =>
                                                        setShowMedicalHistory(
                                                            true
                                                        )
                                                    }
                                                >
                                                    Detaljnije
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    {/* Delete referee modal */}
                    <ConfirmationModal
                        modalText="Jeste li sigurni da želite obrisati sudiju?"
                        onHide={() => setShowDeleteModal(false)}
                        show={showDeleteModal}
                        onClick={() => handleDeleteReferee(id)}
                    />
                </>
            ) : (
                <MedicalHistory
                    profileData={data}
                    setShowMedicalHistory={setShowMedicalHistory}
                />
            )}
        </Row>
    )
}

export default RefereeProfile
