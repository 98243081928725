import React from 'react'
import { Col, Row } from 'react-bootstrap'
import FileTrayOutlined from '../../assets/svg/FileTrayOutlined'

//Styles
import { competitorPillStatusHandler } from '../../lib/helpers'
import { pillStatusHandler } from '../../utils/pillHandler'
import './styles.scss'

function ProfileCard({
    name,
    email,
    avatar,
    pillContent,
    className,
    punished = false,
}) {
    return (
        <>
            {/* Col for desktop */}
            <Col
                md={12}
                className={`card-profile-cover d-none d-md-block ${className}`}
            >
                <Row className="card-info flex-nowrap">
                    <div className="profile-card-avatar-place"></div>
                    <Col
                        md
                        className="d-flex flex-column align-items-start justify-content-start profile-card-info"
                    >
                        <div className="d-flex d-xl-none">
                            {pillContent && pillStatusHandler(pillContent)}
                            <div className="ps-1">
                                {punished &&
                                    pillStatusHandler(
                                        competitorPillStatusHandler('punished')
                                    )}
                            </div>
                        </div>
                        <div className="d-flex align-items-center w-100">
                            <h1 className="me-0 me-xl-4">{name}</h1>
                            <div className="d-none d-xl-flex">
                                {pillContent && pillStatusHandler(pillContent)}
                                <div className="ps-1">
                                    {punished &&
                                        pillStatusHandler(
                                            competitorPillStatusHandler(
                                                'punished'
                                            )
                                        )}
                                </div>
                            </div>
                        </div>
                        {email && (
                            <div className="d-flex align-items-center w-100">
                                <FileTrayOutlined
                                    width="22px"
                                    color="#FFFFFF"
                                />
                                <p className="ms-2">{email}</p>
                            </div>
                        )}
                    </Col>
                </Row>

                <img src={avatar} className="profile-picture-avatar" />
            </Col>
            {/* Col for mobile */}
            <Col
                md={12}
                className={`card-profile-cover-mobile d-block d-md-none ${className} ${
                    pillContent && 'pill-card'
                }`}
            >
                <Row className="card-info-mobile d-flex p-4 h-100">
                    <div className="p-0 profile-card-mobile-avatar-place">
                        <img src={avatar} className="profile-picture-mobile" />
                    </div>
                    <Col xs className="profile-card-mobile-info">
                        <Row className="p-0 mb-1">
                            <h5>{name}</h5>
                        </Row>
                        <Row className="p-0 mb-2">
                            <p>{email}</p>
                        </Row>
                        {pillContent && (
                            <Row
                                className="p-0 mb-2"
                                style={{ columnGap: '4px' }}
                            >
                                {pillStatusHandler(pillContent, true)}
                                {punished &&
                                    pillStatusHandler(
                                        competitorPillStatusHandler('punished'),
                                        true
                                    )}
                            </Row>
                        )}
                    </Col>
                </Row>
            </Col>
        </>
    )
}

export default ProfileCard
