import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { numbersOnly } from '../../../lib/helpers'

import TextField from '../../TextField'

const StepThree = ({
    newClub,
    updateClub,
    setNewClub,
    updateClubStaff,
    staffValue,
}) => {
    return (
        <Row className="step-three-wrapper">
            <form>
                <Col md={12}>
                    <h3>Klub</h3>
                </Col>
                <Col md={12} className="mt-3">
                    <TextField
                        id="email"
                        name="email"
                        label="Email"
                        placeholder="Email kluba"
                        onChange={updateClub}
                        type="email"
                        value={newClub?.email || ''}
                        required
                    />
                </Col>
                <Col md={12} className="mt-3">
                    <TextField
                        id="phone"
                        name="phone"
                        label="Telefon"
                        placeholder="Telefon kluba"
                        onChange={(e) => {
                            numbersOnly(e)
                            updateClub(e)
                        }}
                        type="tel"
                        value={newClub?.phone || ''}
                        required
                    />
                </Col>
                <Col md={12} className="mt-3">
                    <TextField
                        id="website_url"
                        name="website_url"
                        label="Oficijalni website"
                        onChange={updateClub}
                        type="url"
                        placeholder="https://"
                        value={newClub?.website_url || ''}
                    />
                </Col>
                <Col md={12} className="mt-3">
                    <h3> Predsednik kluba</h3>
                </Col>
                <Col md={12} className="mt-3">
                    <TextField
                        id="Predsednik_given_name"
                        name="Predsednik"
                        label="Ime"
                        onChange={(e) => updateClubStaff(e)}
                        placeholder="Ime predsednika"
                        value={staffValue('Predsednik', 'given_name')}
                    />
                </Col>
                <Col md={12} className="mt-3">
                    <TextField
                        id="Predsednik_family_name"
                        name="Predsednik"
                        label="Prezime"
                        onChange={(e) => updateClubStaff(e)}
                        placeholder="Prezime predsednika"
                        value={staffValue('Predsednik', 'family_name')}
                    />
                </Col>
                <Col md={12} className="mt-3">
                    <TextField
                        id="Predsednik_email"
                        name="Predsednik"
                        label="Email"
                        placeholder="Email predsednika"
                        onChange={(e) => updateClubStaff(e)}
                        type="email"
                        value={staffValue('Predsednik', 'email')}
                    />
                </Col>
                <Col md={12} className="mt-3">
                    <TextField
                        id="Predsednik_phone"
                        name="Predsednik"
                        label="Telefon"
                        placeholder="Telefon predsednika"
                        onChange={(e) => {
                            numbersOnly(e)
                            updateClubStaff(e)
                        }}
                        type="tel"
                        value={staffValue('Predsednik', 'phone')}
                    />
                </Col>
            </form>
        </Row>
    )
}

export default StepThree
