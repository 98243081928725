const SampleNextArrow = (props) => {
    const { className, style, onClick } = props
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'flex',
                alignItems: 'center',
                background: '#25527b',
                marginLeft: '-50px',
                width: '50px',
                height: '50px',
                padding: '10px',
                borderRadius: '10px',
                top: '25px',
                boxShadow: '-2px 4px 10px 0px rgba(0, 0, 0, 0.15)',
            }}
            onClick={onClick}
        />
    )
}

const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
                background: '#c96c59',
                marginRight: '-50px',
                width: '50px',
                height: '50px',
                padding: '10px',
                borderRadius: '10px',
                zIndex: '100',
                top: '25px',
                boxShadow: '2px 4px 10px 0px rgba(0, 0, 0, 0.15)',
            }}
            onClick={onClick}
        />
    )
}

export const settings = (slidesToShow) => {
    return {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    }
}
