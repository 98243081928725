import React from 'react'
import Table from 'react-bootstrap/Table'
import PaginationWrapper from '../../../../components/Pagination'
import PlayersMigrationRow from './PlayersMigrationRow'

const PlayersMigrationTable = ({
    className,
    data,
    currentPage,
    setCurrentPage,
    pageCount,
    total,
    rowsPerPage,
    setRowsPerPage,
}) => {
    return (
        <div className={`table-competitors-pagination-wrapper ${className}`}>
            <Table className="mb-0">
                <thead>
                    <tr>
                        <th>
                            <h5>Takmičar</h5>
                        </th>

                        <th>
                            <h5>Stara šifra</h5>
                        </th>
                        <th style={{ minWidth: '70px' }}>
                            <h5>Datum rođenja</h5>
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((competitor, index) => (
                        <PlayersMigrationRow
                            competitor={competitor}
                            key={index}
                        />
                    ))}
                </tbody>
            </Table>
            <PaginationWrapper
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageCount={pageCount}
                total={total}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
            />
        </div>
    )
}

export default PlayersMigrationTable
