import { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import RadioButton from '../../../../../components/RadioButton'
import TextField from '../../../../../components/TextField'
import UploadImage from '../../../../../components/UploadImage'
import AsyncSelect from '../../../../../components/AsyncSelect'
import {
    addPhoto,
    checkFileSize,
    numbersOnly,
    getClubList,
} from '../../../../../lib/helpers'
import { getCodebook } from '../../../../../lib/api'

import './styles.scss'

const BasicInfo = ({
    setProgress,
    formData,
    setFormData,
    setCoachImg,
    coachPreviewImg,
    setCoachPreviewImg,
    handleClubSelect,
    coachsClub,
}) => {
 
    // Implementing codebooks

    const [genders, setGenders] = useState([])

    const handleCodebooks = async () => {
        await getCodebook('codebooks.gender', setGenders)
    }

    useEffect(() => {
        setProgress(50)
        handleCodebooks()
    }, [])

    return (
        <Row className="basic-info-wrapper">
            <Col md={12} className="d-flex justify-content-center">
                <UploadImage
                    htmlFor="coachImg"
                    textBelow={'Unesi sliku trenera /.jpg 2MB'}
                    previewData={coachPreviewImg}
                />

                <input
                    type="file"
                    id="coachImg"
                    name="coachImg"
                    className="d-none invisible"
                    onChange={(e) =>
                        addPhoto(
                            e,
                            setCoachImg,
                            setCoachPreviewImg,
                            checkFileSize
                        )
                    }
                    accept="image/jpg, image/jpeg, image/png"
                />
            </Col>

            <Col md={12} className="mt-3">
                <TextField
                    id="name"
                    name="name"
                    type="text"
                    value={formData?.person_info?.given_name || ''}
                    onChange={(e) => {
                        setFormData({
                            ...formData,
                            person_info: {
                                ...formData?.person_info,
                                given_name: e.target.value,
                            },
                        })
                    }}
                    required
                    label="Ime"
                />
            </Col>
            <Col md={12} className="mt-3">
                <TextField
                    id="surname"
                    name="surname"
                    type="text"
                    value={formData?.person_info?.family_name || ''}
                    onChange={(e) => {
                        setFormData({
                            ...formData,
                            person_info: {
                                ...formData?.person_info,
                                family_name: e.target.value,
                            },
                        })
                    }}
                    required
                    label="Prezime"
                />
            </Col>

            <Col md={12} className="mt-3">
                <TextField
                    type="date"
                    label="Datum rođenja"
                    value={formData?.person_info?.birthdate || ''}
                    onChange={(e) =>
                        setFormData({
                            ...formData,
                            person_info: {
                                ...formData?.person_info,
                                birthdate: e.target.value,
                            },
                        })
                    }
                    required
                />
            </Col>

            <Col md={12} className="mt-3">
                <RadioButton
                    name="Pol"
                    onChange={(e) => {
                        setFormData({
                            ...formData,
                            gender: {
                                ...formData?.gender,
                                gender_name: e.target.value,
                            },
                        })
                    }}
                    radioValues={genders}
                    checkedValue={formData?.gender?.gender_name || ''}
                    required
                />
            </Col>

            <Col md={12} className="mt-3">
                <TextField
                    id="id"
                    name="email"
                    type="email"
                    value={formData?.person_info?.email}
                    onChange={(e) => {
                        setFormData({
                            ...formData,
                            person_info: {
                                ...formData?.person_info,
                                email: e.target.value,
                            },
                        })
                    }}
                    label="Email"
                    required
                />
            </Col>
            <Col md={12} className="mt-3">
                <TextField
                    id="id"
                    name="phone"
                    type="tel"
                    value={formData?.person_info?.phone}
                    onChange={(e) => {
                        numbersOnly(e)
                        setFormData({
                            ...formData,
                            person_info: {
                                ...formData?.person_info,
                                phone: e.target.value,
                            },
                        })
                    }}
                    label="Telefon"
                />
            </Col>
            <Col md={12} className="mt-3">
                <TextField
                    id="JMBG"
                    name="JMBG"
                    type="text"
                    value={formData?.person_info?.jmbg || ''}
                    onChange={(e) => {
                        numbersOnly(e)
                        // e.target.value.length > 0
                        //     ? checkJMBG(e.target.value)
                        //         ? setDisableNextButton(false)
                        //         : setDisableNextButton(true)
                        //     : setDisableNextButton(false)
                        setFormData({
                            ...formData,
                            person_info: {
                                ...formData?.person_info,
                                jmbg: e.target.value,
                            },
                        })
                    }}
                    label="JMBG"
                    minLength={13}
                    maxLength={13}
                />
            </Col>
            <Col md={12} className="mt-3">
                <AsyncSelect
                    loadOptions={getClubList}
                    onChange={handleClubSelect}
                    label={'Izaberi klub'}
                    placeholder={
                        coachsClub?.club
                            ? coachsClub?.club?.attributes?.name
                            : 'Pretraži i izaberi klub...'
                    }
                    noOptionsMessage={'Traženi klub ne postoji'}
                    closeMenuOnSelect={true}
                    isMulti={false}
                />
            </Col>
        </Row>
    )
}

export default BasicInfo
