import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Link, useNavigate } from 'react-router-dom'

import { handleShowContactPerson, formatImgUrl } from '../../lib/helpers'
import { generateAvatar } from '../../utils/generateAvatar'
import StatusField from '../ClubList/statusField'
import './styles.scss'

const ClubListTableCard = ({ className, data, status }) => {
    const navigate = useNavigate()
    const handleEdit = (e) => {
        navigate(`/klubovi/${e.target.id}/izmeni-podatke-kluba`)
    }

    return (
        <>
            {data?.map(({ attributes, id }, index) => (
                <Row
                    key={`${attributes?.name}-${index}-${attributes?.uuid}`}
                    className={`club-list-table-card-wrapper g-3 ${className}`}
                >
                    <Col xs={6} className="mt-3 d-flex align-items-center">
                        <h5>Klub</h5>
                    </Col>
                    <Col xs={6} className="d-flex align-items-center p-0 mt-3">
                        <img
                            src={
                                attributes?.logo?.data
                                    ? formatImgUrl(
                                          attributes?.logo?.data?.attributes
                                              ?.url
                                      )
                                    : generateAvatar(attributes?.name)
                            }
                            alt={`${attributes?.name} logo`}
                            width={'24px'}
                            height={'24px'}
                            className="me-2"
                            style={{
                                borderRadius: '50%',
                                maxHeight: '24px',
                                maxWidth: '24px',
                            }}
                        />
                        <Link
                            to={`/klubovi/${attributes?.uuid}`}
                            className="link"
                        >
                            <p>{attributes?.name}</p>
                        </Link>
                    </Col>
                    <hr className="table-card-divider" />
                    <Col xs={6} className="m-0">
                        <h5>Skraćeno ime</h5>
                    </Col>
                    <Col xs={6} className="d-flex align-items-center p-0 m-0">
                        <p>
                            {attributes?.short_name
                                ? attributes?.short_name
                                : '-'}
                        </p>
                    </Col>
                    <hr className="table-card-divider" />
                    <Col xs={6} className="m-0">
                        <h5>Region</h5>
                    </Col>
                    <Col xs={6} className="d-flex align-items-center p-0 m-0">
                        <p>{attributes?.region?.region_name || '-'}</p>
                    </Col>
                    <hr className="table-card-divider" />
                    <Col xs={6} className="m-0">
                        <h5>Email</h5>
                    </Col>
                    <Col xs={6} className="d-flex align-items-center p-0 m-0">
                        <p>{attributes?.email || '-'}</p>
                    </Col>
                    <hr className="table-card-divider" />
                    <Col xs={6} className="m-0">
                        <h5>Kontakt osoba</h5>
                    </Col>
                    <Col xs={6} className="d-flex align-items-center p-0 m-0">
                        <p>{handleShowContactPerson(attributes?.staff)}</p>
                    </Col>
                    <hr className="table-card-divider" />
                    <Col xs={6} className="m-0">
                        <h5>Telefon</h5>
                    </Col>
                    <Col xs={6} className="d-flex align-items-center p-0 m-0">
                        <p>{attributes?.phone || '-'}</p>
                    </Col>

                    <hr className="table-card-divider" />
                    <Col xs={6} className="m-0">
                        <h5>Status</h5>
                    </Col>
                    <Col xs={6} className="d-flex align-items-center p-0 m-0">
                        <StatusField mobile clubStatus={attributes?.status} />
                    </Col>
                    <hr className="table-card-divider" />
                    <Col xs={12} className="m-0 mb-3">
                        <p
                            className="link"
                            onClick={handleEdit}
                            id={attributes?.uuid}
                        >
                            Izmeni
                        </p>
                    </Col>
                </Row>
            ))}
        </>
    )
}

export default ClubListTableCard
