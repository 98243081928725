import { Table } from 'react-bootstrap'

const AssemblyInternationalOrganizationTable = ({ data }) => {
    return (
        <div className="table-clubs-ranking-wrapper">
            {data?.length > 0 ? (
                <Table className="mb-0 settings-table">
                    <thead>
                        <tr>
                            <th>
                                <h5>Tip turnira</h5>
                            </th>
                            <th>
                                <h5>Glasova</h5>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {data?.map((result, index) => (
                            <tr key={index} className="setting-table-row">
                                <td>
                                    <p className="align-middle">
                                        {result?.organization_type ?? ''}
                                    </p>
                                </td>
                                <td>
                                    <p className="align-middle">
                                        {result?.votes}
                                    </p>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            ) : null}
        </div>
    )
}

export default AssemblyInternationalOrganizationTable
