import React, { useEffect, useState } from 'react'
import AsyncSelect from '../../../../../components/AsyncSelect'
import { getTournamentPlayersListForReport } from '../../../../../lib/helpers'

function Competitors({ setProgress, formData, setFormData, tournamentData }) {
    useEffect(() => {
        setProgress(33)
    }, [])

    const [playersList, setPlayersList] = useState()
    const [listChanged, setListChanged] = useState(0)

    const handleSelected = (e) => {
        const competitorList = []

        e.map(({ label, code, club }) => {
            let competitorInfo = {
                name: label,
                code: code,
                club: club,
            }
            competitorList.push(competitorInfo)
        })

        setFormData((prevData) => ({
            ...prevData,
            competitors: competitorList,
        }))

        setListChanged((prev) => prev + 1)
    }

    const handleValue = () => {
        const values = []

        formData?.competitors?.map(({ name, code, club }) => {
            values.push({ label: name, code: code, club: club, value: name })
        })

        return values
    }

    const getTournamentPlayersList = async (
        searchValue,
        loadedOptions,
        { page }
    ) => {
        return getTournamentPlayersListForReport(
            searchValue,
            loadedOptions,
            { page },
            { tournament_uuid: tournamentData?.uuid },
            formData?.competitors
        )
    }

    useEffect(() => {
        // console.log(formData?.competitors, 'igraci')
    }, [formData])

    return (
        <AsyncSelect
            loadOptions={getTournamentPlayersList}
            onChange={handleSelected}
            label={'Takmičari'}
            value={handleValue()}
            placeholder={'Pretraži i izaberi...'}
            noOptionsMessage={'Nema rezultata'}
            closeMenuOnSelect={false}
            cacheUniqs={[listChanged]}
        />
    )
}

export default Competitors
