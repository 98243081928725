import React, { Fragment } from 'react'
import { Table } from 'react-bootstrap'
import ParticipantsRow from './participantsRow'

import PaginationWrapper from '../Pagination'
import './styles.scss'

const CompetitionsParticipantsTable = ({
    participants,
    enableRemove = false,
    setCurrentPage,
    currentPage,
    handleGetPlayersForTournament,
    pageCount,
    total,
    isDouble = false,
    competitionProfileData,
    tableForGranPri = false,
    isManualPointsChangeEnabled = false,
    setPlayersForTournamentList,
    rowsPerPage,
    setRowsPerPage,
    handleAutoSeed,
    numberOfDAPlayers = 0,
    numberOfQPlayers = 0,
    numberOfWCPlayers = 0,
    numberOfWCQPlayers = 0,
    numberOfLLPlayers = 0,
}) => {
    const participantsExpectedNumber =
        competitionProfileData?.attributes?.tournament_type
            ?.tournament_type_name !== 'Ekipno'
            ? competitionProfileData?.attributes
                  ?.participants_expected_number || 0
            : 0

    const wcQualificationNumber =
        competitionProfileData?.attributes?.wc_qualification_number || 0
    return (
        <div className="table-competitors-pagination-wrapper table-competitors-wrapper competitions-participation-table">
            <Table className="mb-0">
                <thead>
                    <tr>
                        <th style={{ width: '70px', minWidth: '70px' }}>
                            <h5>Pozicija</h5>
                        </th>
                        <th style={{ minWidth: '140px' }}>
                            <h5>Takmičar</h5>
                        </th>
                        <th style={{ minWidth: '170px' }}>
                            <h5>Klub</h5>
                        </th>
                        <th style={{ minWidth: '114px' }}>
                            <h5>Datum rođenja</h5>
                        </th>
                        <th style={{ minWidth: '80px' }}>
                            <h5>ATP/WTA</h5>
                        </th>
                        <th style={{ minWidth: '60px' }}>
                            <h5>ITF</h5>
                        </th>
                        <th style={{ minWidth: '60px' }}>
                            <h5>ETA 14</h5>
                        </th>
                        <th style={{ minWidth: '60px' }}>
                            <h5>ETA 16</h5>
                        </th>
                        <th style={{ minWidth: '60px' }}>
                            <h5>TSS</h5>
                        </th>
                        {!competitionProfileData?.attributes
                            ?.tournament_have_grand_prix &&
                            competitionProfileData?.attributes?.tournament_type
                                ?.tournament_type_name !== 'Ekipno' && (
                                <th style={{ minWidth: '80px' }}>
                                    <h5>Status</h5>
                                </th>
                            )}
                        {competitionProfileData?.attributes
                            ?.tournament_have_grand_prix && (
                            <th style={{ minWidth: '85px' }}>
                                <h5>Grupa</h5>
                            </th>
                        )}
                        <th style={{ minWidth: '85px' }}>
                            <h5>Bodovi</h5>
                        </th>
                        {!competitionProfileData?.attributes
                            ?.tournament_have_grand_prix &&
                            competitionProfileData?.attributes?.tournament_type
                                ?.tournament_type_name !== 'Ekipno' && (
                                <th></th>
                            )}
                        {enableRemove && <th></th>}
                    </tr>
                </thead>
                <tbody>
                    {participants?.map((player, index) => (
                        <Fragment key={index}>
                            {index + (currentPage - 1) * rowsPerPage ===
                                numberOfDAPlayers +
                                    numberOfQPlayers +
                                    numberOfWCPlayers +
                                    numberOfLLPlayers &&
                                !isDouble &&
                                Array.from({
                                    length:
                                        participantsExpectedNumber -
                                        numberOfDAPlayers -
                                        numberOfQPlayers -
                                        numberOfWCPlayers -
                                        numberOfLLPlayers,
                                }).map((_, i) => (
                                    <ParticipantsRow
                                        key={
                                            index +
                                            (currentPage - 1) * rowsPerPage +
                                            i
                                        }
                                        index={
                                            index +
                                            (currentPage - 1) * rowsPerPage +
                                            i
                                        }
                                        enableRemove={false}
                                        participant={{}}
                                        handleGetPlayersForTournament={
                                            handleGetPlayersForTournament
                                        }
                                        isDouble={isDouble}
                                        competitionProfileData={
                                            competitionProfileData
                                        }
                                        handleAutoSeed={handleAutoSeed}
                                        tableForGranPri={tableForGranPri}
                                        isManualPointsChangeEnabled={
                                            isManualPointsChangeEnabled
                                        }
                                        setPlayersForTournamentList={
                                            setPlayersForTournamentList
                                        }
                                        numberOfDAPlayers={numberOfDAPlayers}
                                        numberOfQPlayers={numberOfQPlayers}
                                        numberOfWCPlayers={numberOfWCPlayers}
                                        numberOfWCQPlayers={numberOfWCQPlayers}
                                        numberOfLLPlayers={numberOfLLPlayers}
                                    />
                                ))}

                            {(player?.attributes?.player_status?.status_name ===
                                'ALT' ||
                                player?.attributes?.player_status
                                    ?.status_name === 'LL') &&
                                participants[index - 1]?.attributes
                                    ?.player_status?.status_name !== 'ALT' &&
                                participants[index - 1]?.attributes
                                    ?.player_status?.status_name !== 'LL' &&
                                !isDouble &&
                                Array.from({
                                    length:
                                        wcQualificationNumber -
                                        numberOfWCQPlayers,
                                }).map((_, i) => (
                                    <ParticipantsRow
                                        key={
                                            index +
                                            (currentPage - 1) * rowsPerPage +
                                            i
                                        }
                                        index={
                                            index +
                                            (currentPage - 1) * rowsPerPage +
                                            i
                                        }
                                        enableRemove={false}
                                        participant={{}}
                                        handleGetPlayersForTournament={
                                            handleGetPlayersForTournament
                                        }
                                        isDouble={isDouble}
                                        competitionProfileData={
                                            competitionProfileData
                                        }
                                        handleAutoSeed={handleAutoSeed}
                                        tableForGranPri={tableForGranPri}
                                        isManualPointsChangeEnabled={
                                            isManualPointsChangeEnabled
                                        }
                                        setPlayersForTournamentList={
                                            setPlayersForTournamentList
                                        }
                                        numberOfDAPlayers={numberOfDAPlayers}
                                        numberOfQPlayers={numberOfQPlayers}
                                        numberOfWCPlayers={numberOfWCPlayers}
                                        numberOfWCQPlayers={numberOfWCQPlayers}
                                        numberOfLLPlayers={numberOfLLPlayers}
                                    />
                                ))}

                            <ParticipantsRow
                                key={
                                    index + (currentPage - 1) * rowsPerPage <
                                    numberOfDAPlayers +
                                        numberOfQPlayers +
                                        numberOfWCPlayers +
                                        numberOfLLPlayers
                                        ? index +
                                          (currentPage - 1) * rowsPerPage
                                        : index +
                                          (currentPage - 1) * rowsPerPage +
                                          participantsExpectedNumber -
                                          numberOfDAPlayers -
                                          numberOfQPlayers -
                                          numberOfWCPlayers -
                                          numberOfLLPlayers
                                }
                                index={
                                    index + (currentPage - 1) * rowsPerPage <
                                    numberOfDAPlayers +
                                        numberOfQPlayers +
                                        numberOfWCPlayers +
                                        numberOfLLPlayers
                                        ? index +
                                          (currentPage - 1) * rowsPerPage
                                        : index +
                                          (currentPage - 1) * rowsPerPage +
                                          participantsExpectedNumber -
                                          numberOfDAPlayers -
                                          numberOfQPlayers -
                                          numberOfWCPlayers -
                                          numberOfLLPlayers
                                }
                                enableRemove={enableRemove}
                                participant={player}
                                handleGetPlayersForTournament={
                                    handleGetPlayersForTournament
                                }
                                isDouble={isDouble}
                                competitionProfileData={competitionProfileData}
                                handleAutoSeed={handleAutoSeed}
                                tableForGranPri={tableForGranPri}
                                isManualPointsChangeEnabled={
                                    isManualPointsChangeEnabled
                                }
                                setPlayersForTournamentList={
                                    setPlayersForTournamentList
                                }
                                numberOfDAPlayers={numberOfDAPlayers}
                                numberOfQPlayers={numberOfQPlayers}
                                numberOfWCPlayers={numberOfWCPlayers}
                                numberOfWCQPlayers={numberOfWCQPlayers}
                                numberOfLLPlayers={numberOfLLPlayers}
                            />
                        </Fragment>
                    ))}
                </tbody>
            </Table>
            <PaginationWrapper
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageCount={pageCount}
                total={total}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
            />
        </div>
    )
}

export default CompetitionsParticipantsTable
