import * as React from 'react'

const GameController = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="none"
        viewBox="0 0 32 32"
        {...props}
    >
        <path
            d="M30.195 15.336c-1.325-5.993-3.32-9.192-6.28-10.065A6.695 6.695 0 0 0 22 5c-.857 0-1.603.208-2.393.43-.95.266-2.032.57-3.607.57-1.576 0-2.657-.303-3.61-.57C11.6 5.209 10.854 5 10 5a7.234 7.234 0 0 0-1.983.27c-2.944.87-4.938 4.067-6.283 10.062C.288 21.783 1 25.857 3.73 26.802c.375.132.769.2 1.165.201 1.871 0 3.371-1.558 4.396-2.834 1.158-1.443 2.514-2.176 6.709-2.176 3.747 0 5.297.508 6.637 2.176.842 1.05 1.637 1.783 2.43 2.245 1.057.614 2.112.75 3.136.398 1.614-.55 2.539-2.006 2.75-4.328.16-1.78-.087-4.118-.758-7.148ZM13 15h-2v2a1 1 0 0 1-2 0v-2H7a1 1 0 0 1 0-2h2v-2a1 1 0 1 1 2 0v2h2a1 1 0 1 1 0 2Zm5.25.25a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5ZM21 18a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5Zm0-5.5a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5Zm2.75 2.75a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5Z"
            fill="#214B73"
        />
    </svg>
)

export default GameController
