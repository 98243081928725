import React, { useEffect, useState } from 'react'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import TennisPlayer from '../../assets/svg/TennisPlayer'
import TennisPlayerNoData from '../../assets/svg/TennisPlayerNoData'
import {
    getClubs,
    getPlayers,
    getReferees,
    getTournaments,
} from '../../lib/api'
import { useAppContext } from '../../lib/contextLib'

import './styles.scss'

const Welcome = () => {
    const { user } = useAppContext()

    const [welcomeData, setWelcomeData] = useState({
        finished_tournaments: 0,
        competitors: 0,
        referees: 0,
        clubs: 0,
    })

    const [loaded, setLoaded] = useState(false)

    const initData = async () => {
        let { total: referees } = await getReferees([], null, null, 1)
        let { total: clubs } = await getClubs([], null, null, 1)
        let { total: competitors } = await getPlayers(
            [],
            null,
            {
                status: 'active',
            },
            1
        )
        let { total: finished_tournaments } = await getTournaments(
            [],
            null,
            null,
            1,
            true
        )

        setWelcomeData({
            ...welcomeData,
            competitors: competitors,
            referees: referees,
            clubs: clubs,
            finished_tournaments: finished_tournaments,
        })
        setLoaded((_) => {
            return true
        })
    }

    useEffect(() => {
        initData()
    }, [])
    return (
        <Card className="welcome-component-wrapper mb-3 p-3">
            <Row className="d-flex align-items-center justify-content-start">
                <Col
                    md={8}
                    className="d-flex flex-column justify-content-center"
                >
                    <Row className="mb-4 d-flex align-items-center justify-content-start">
                        <Col xs={8} md={8}>
                            <p className="pe-0">Dobrodošao,</p>
                            <h3 className="pe-0">{`${
                                user?.data?.attributes?.name
                                    ? user.data.attributes.name
                                    : 'Administrator'
                            } ${
                                user?.data?.attributes?.surname
                                    ? user.data.attributes.surname
                                    : ''
                            }`}</h3>
                        </Col>
                        <Col
                            xs={3}
                            md={4}
                            className="d-block d-md-none d-flex justify-content-start p-0"
                        >
                            {loaded ? (
                                <TennisPlayer width="100px" />
                            ) : (
                                <TennisPlayerNoData width="100px" />
                            )}
                        </Col>
                    </Row>
                    {loaded ? (
                        <Row className="mb-4 justify-content-start">
                            <Col
                                xs={6}
                                md={3}
                                className="d-flex flex-column align-items-md-start mb-3 mb-md-0"
                            >
                                <h2 className="mb-1">
                                    {welcomeData?.competitors?.toString()}
                                </h2>
                                <p>
                                    {welcomeData?.competitors?.toString() <= 1
                                        ? 'Aktivni takmičar'
                                        : 'Aktivna takmičara'}
                                </p>
                            </Col>
                            <Col
                                xs={6}
                                md={3}
                                className="d-flex flex-column align-items-md-start mb-3 mb-md-0"
                            >
                                <h2 className="mb-1">
                                    {welcomeData?.finished_tournaments?.toString()}
                                </h2>
                                <p>Odigranih takmičenja</p>
                            </Col>
                            <Col
                                xs={6}
                                md={3}
                                className="d-flex flex-column align-items-md-start mb-3 mb-md-0"
                            >
                                <h2 className="mb-1">
                                    {welcomeData?.clubs?.toString()}
                                </h2>
                                <p>
                                    {welcomeData?.clubs?.toString() <= 1
                                        ? 'Klub'
                                        : 'Klubova'}
                                </p>
                            </Col>
                            <Col
                                xs={6}
                                md={3}
                                className="d-flex flex-column   align-items-md-start mb-3 mb-md-0"
                            >
                                <h2 className="mb-1">
                                    {welcomeData?.referees?.toString()}
                                </h2>
                                <p>Sudija</p>
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <p>Na žalost, još uvek nema podataka za prikaz.</p>
                        </Row>
                    )}
                </Col>
                <Col
                    md={4}
                    className="d-md-flex align-items-center justify-content-end d-none d-md-block"
                >
                    {loaded ? (
                        <TennisPlayer width="200px" />
                    ) : (
                        <TennisPlayerNoData width="200px" />
                    )}
                </Col>
            </Row>
        </Card>
    )
}

export default Welcome
