import * as React from 'react'

const OutlinedFilter = (props) => (
    <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 7.667C2 7.298 2.298 7 2.667 7h18.666a.667.667 0 1 1 0 1.333H2.667A.667.667 0 0 1 2 7.667Zm3.333 4.666c0-.368.299-.666.667-.666h12A.667.667 0 1 1 18 13H6a.667.667 0 0 1-.667-.667Zm4 4.667c0-.368.299-.667.667-.667h4a.667.667 0 0 1 0 1.334h-4A.667.667 0 0 1 9.333 17Z"
            fill="#2C3136"
        />
    </svg>
)

export default OutlinedFilter
