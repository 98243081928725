import * as React from 'react'

const Change = (props) => (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M19.555.07c.094.043.18.105.25.182a.782.782 0 0 1-.041 1.076l-.58.577a.385.385 0 0 1-.545 0l-.545-.544a.385.385 0 0 1 0-.544l.595-.592a.772.772 0 0 1 .866-.155ZM8.213 10.683l8.678-8.664a.433.433 0 0 1 .611 0l.479.481a.432.432 0 0 1 0 .609l-8.664 8.68a.433.433 0 0 1-.189.11l-1.194.402a.187.187 0 0 1-.234-.234l.401-1.195a.433.433 0 0 1 .112-.19Z"
            fill="#2C3136"
        />
        <path
            d="M1.876 4.953c.217-.216.51-.338.816-.338h8.052a.77.77 0 1 0 0-1.538H2.692A2.692 2.692 0 0 0 0 5.769v11.539A2.692 2.692 0 0 0 2.692 20h11.539a2.693 2.693 0 0 0 2.692-2.692V8.462a.77.77 0 0 0-1.538 0v8.846a1.154 1.154 0 0 1-1.154 1.154H2.692a1.154 1.154 0 0 1-1.154-1.154V5.769c0-.306.122-.6.338-.816Z"
            fill="#2C3136"
        />
    </svg>
)

export default Change
