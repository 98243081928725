import React from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import './styles.scss'

const TournamentRoundCard = ({ index, children, roundName, className }) => {
    const colors = ['p500', 'g500', 's500', 'w500']

    return (
        <Card
            className={`tournament-round-card tournament-round-card-${colors[index]} ${className}`}
        >
            <Row className="mb-3 tournament-round-name">
                <Col>
                    <h4 className="text-center">{roundName}</h4>
                </Col>
            </Row>
            <Row>
                <Col>{children}</Col>
            </Row>
        </Card>
    )
}

export default TournamentRoundCard
