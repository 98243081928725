import { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import AsyncSelect from '../../../../../components/AsyncSelect'
import Checkbox from '../../../../../components/Checkbox'
import Dropdown from '../../../../../components/Dropdown'
import TextField from '../../../../../components/TextField'
import { getCodebook, getSingleClub } from '../../../../../lib/api'
import './styles.scss'

const FourthStep = ({
    setProgress,
    getClubList,
    refereeLicensesData,
    setRefereeLicensesData,
    refereeTest,
    setRefereeTest,
}) => {
    //* Codebooks
    const [internationalMainRefereeBadges, setInternationalMainRefereeBadges] =
        useState([])
    const [
        internationalChairRefereeBadges,
        setInternationalChairRefereeBadges,
    ] = useState([])
    const [refereeCoreLicenses, setRefereeCoreLicenses] = useState([])
    const [refereeNationalLicenses, setRefereeNationalLicenses] = useState([])

    const handleCodebooks = async () => {
        await getCodebook(
            'codebooks.referee-international-main-badge',
            setInternationalMainRefereeBadges
        )
        await getCodebook(
            'codebooks.referee-international-chair-badge',
            setInternationalChairRefereeBadges
        )
        await getCodebook(
            'codebooks.referee-core-license',
            setRefereeCoreLicenses
        )
        await getCodebook(
            'codebooks.referee-national-license-type',
            setRefereeNationalLicenses
        )
    }

    const [clubProfile, setClubProfile] = useState()

    const [isMainReferee, setIsMainReferee] = useState(
        refereeLicensesData?.international_main_badge
            ?.international_main_badge_name
            ? true
            : false
    )
    const [isChairReferee, setIsChairReferee] = useState(
        refereeLicensesData?.international_chair_badge
            ?.international_chair_badge_name
            ? true
            : false
    )

    const getClubProfile = async () => {
        if (refereeLicensesData?.coordinators_club_uuid) {
            const club = await getSingleClub(
                refereeLicensesData?.coordinators_club_uuid
            )

            if (club) {
                setClubProfile(club)
            }
        }
    }

    const handleClubSelect = (e) => {
        setRefereeLicensesData((prevState) => ({
            ...prevState,
            coordinators_club_uuid: e.uuid,
        }))
    }

    useEffect(() => {
        setProgress(100)
        getClubProfile()
        handleCodebooks()
    }, [])

    return (
        <Row className="fourth-step-wrapper">
            <Col md={12} className="mt-3">
                <Dropdown
                    label="Licenca"
                    placeholder={'Izaberi licencu'}
                    value={refereeLicensesData?.core_license?.core_license_name}
                    handleSelect={(e) => {
                        setRefereeLicensesData((prevState) => ({
                            ...prevState,
                            core_license: {
                                core_license_name: e,
                            },
                            international_main_badge: {
                                international_main_badge_name: null,
                                international_main_badge_acquisition_date: null,
                            },
                            international_chair_badge: {
                                international_chair_badge_name: null,
                                international_chair_badge_acquisition_date:
                                    null,
                            },
                            national_license_type: {
                                national_license_type_name: null,
                            },
                        }))
                        setRefereeTest((prevState) => ({
                            ...prevState,
                            test_date: null,
                            test_result: null,
                        }))
                        setIsMainReferee(false)
                        setIsChairReferee(false)
                    }}
                    required={true}
                    dropdownLinks={refereeCoreLicenses}
                />
            </Col>
            {/* Medjunarodni tip licence */}
            {refereeLicensesData?.core_license?.core_license_name ===
                'Međunarodni' && (
                <>
                    <Col xs={12} className="mt-3">
                        <h5 className="mb-3">Tip sudije</h5>
                        <Checkbox
                            label="Glavni sudija"
                            checked={isMainReferee}
                            onChange={() => {
                                setIsMainReferee(true)
                            }}
                            className="mb-3"
                        />
                        <Checkbox
                            label="Sudija u stolici"
                            checked={isChairReferee}
                            onChange={() => {
                                setIsChairReferee(true)
                            }}
                        />
                    </Col>
                    {isMainReferee === true && (
                        <>
                            <Col md={12} className="mt-3">
                                <h3>Glavni sudija</h3>
                            </Col>
                            <Col md={12} className="mt-3">
                                <Dropdown
                                    label="Bedž"
                                    placeholder={'Izaberi bedž'}
                                    value={
                                        refereeLicensesData
                                            .international_main_badge
                                            ?.international_main_badge_name ||
                                        ''
                                    }
                                    handleSelect={(e) => {
                                        setRefereeLicensesData((prevState) => ({
                                            ...prevState,
                                            international_main_badge: {
                                                ...prevState.international_main_badge,
                                                international_main_badge_name:
                                                    e,
                                            },
                                        }))
                                    }}
                                    dropdownLinks={
                                        internationalMainRefereeBadges
                                    }
                                />
                            </Col>
                            <Col md={12} className="mt-3">
                                <TextField
                                    type="date"
                                    label="Datum sticanja licence"
                                    value={
                                        refereeLicensesData
                                            .international_main_badge
                                            ?.international_main_badge_acquisition_date ||
                                        ''
                                    }
                                    onChange={(e) =>
                                        setRefereeLicensesData((prevState) => ({
                                            ...prevState,
                                            international_main_badge: {
                                                ...prevState.international_main_badge,
                                                international_main_badge_acquisition_date:
                                                    e.target.value,
                                            },
                                        }))
                                    }
                                />
                            </Col>
                        </>
                    )}
                    {isChairReferee && (
                        <>
                            <Col md={12} className="mt-3">
                                <h3>Sudija u stolici</h3>
                            </Col>
                            <Col md={12} className="mt-3">
                                <Dropdown
                                    label="Bedž"
                                    placeholder={'Izaberi bedž'}
                                    value={
                                        refereeLicensesData
                                            .international_chair_badge
                                            ?.international_chair_badge_name ||
                                        ''
                                    }
                                    handleSelect={(e) => {
                                        setRefereeLicensesData((prevState) => ({
                                            ...prevState,
                                            international_chair_badge: {
                                                ...prevState.international_chair_badge,
                                                international_chair_badge_name:
                                                    e,
                                            },
                                        }))
                                    }}
                                    dropdownLinks={
                                        internationalChairRefereeBadges
                                    }
                                />
                            </Col>
                            <Col md={12} className="mt-3">
                                <TextField
                                    type="date"
                                    label="Datum sticanja licence"
                                    value={
                                        refereeLicensesData
                                            .international_chair_badge
                                            ?.international_chair_badge_acquisition_date ||
                                        ''
                                    }
                                    onChange={(e) =>
                                        setRefereeLicensesData((prevState) => ({
                                            ...prevState,
                                            international_chair_badge: {
                                                ...prevState.international_chair_badge,
                                                international_chair_badge_acquisition_date:
                                                    e.target.value,
                                            },
                                        }))
                                    }
                                />
                            </Col>
                        </>
                    )}
                </>
            )}
            {/* Nacionalni tip licence */}

            {refereeLicensesData?.core_license?.core_license_name ===
                'Nacionalni' && (
                <>
                    <Col md={12} className="mt-3">
                        <Dropdown
                            label="Tip licence"
                            placeholder={'Izaberi tip licence'}
                            value={
                                refereeLicensesData?.national_license_type
                                    ?.national_license_type_name || ''
                            }
                            handleSelect={(e) => {
                                setRefereeLicensesData((prevState) => ({
                                    ...prevState,
                                    national_license_type: {
                                        ...prevState.national_license_type,
                                        national_license_type_name: e,
                                    },
                                }))
                            }}
                            required
                            dropdownLinks={refereeNationalLicenses}
                        />
                    </Col>
                    <Col md={12} className="mt-3">
                        <TextField
                            type="date"
                            label="Datum poslednjeg testiranja"
                            value={refereeTest?.test_date || ''}
                            onChange={(e) =>
                                setRefereeTest((prevState) => ({
                                    ...prevState,
                                    test_date: e.target.value,
                                }))
                            }
                            required
                        />
                    </Col>
                    <Col md={12} className="mt-3">
                        <TextField
                            type="text"
                            name="testResult"
                            label="Rezultat testiranja"
                            value={refereeTest?.test_result || ''}
                            onChange={(e) =>
                                setRefereeTest((prevState) => ({
                                    ...prevState,
                                    test_result: e.target.value,
                                }))
                            }
                            required
                        />
                    </Col>
                </>
            )}

            {/* Koordinator tip licence */}
            {refereeLicensesData?.core_license?.core_license_name ===
                'Koordinator' && (
                <>
                    <Col md={12} className="mt-3">
                        <TextField
                            type="date"
                            label="Datum poslednjeg testiranja"
                            value={refereeTest?.test_date || ''}
                            onChange={(e) =>
                                setRefereeTest((prevState) => ({
                                    ...prevState,
                                    test_date: e.target.value,
                                }))
                            }
                            required
                        />
                    </Col>
                    <Col md={12} className="mt-3">
                        <TextField
                            type="text"
                            name="testResult"
                            label="Rezultat testiranja"
                            value={refereeTest.test_result || ''}
                            onChange={(e) =>
                                setRefereeTest((prevState) => ({
                                    ...prevState,
                                    test_result: e.target.value,
                                }))
                            }
                            required
                        />
                    </Col>
                    <Col md={12} className="mt-3">
                        <AsyncSelect
                            loadOptions={getClubList}
                            onChange={handleClubSelect}
                            label={'Izaberi klub'}
                            placeholder={
                                clubProfile
                                    ? clubProfile?.attributes?.name
                                    : 'Pretraži i izaberi klub...'
                            }
                            noOptionsMessage={'Traženi klub ne postoji'}
                            closeMenuOnSelect={true}
                            isMulti={false}
                        />
                    </Col>
                </>
            )}
        </Row>
    )
}

export default FourthStep
