import * as React from 'react'

const Settings = (props) => (
    <svg
        width={22}
        height={22}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M11.295 8.015a3 3 0 1 0-.59 5.97 3 3 0 0 0 .59-5.97v0ZM18.518 11a7.238 7.238 0 0 1-.071.975l2.119 1.662a.507.507 0 0 1 .115.644l-2.005 3.469a.507.507 0 0 1-.616.215l-2.105-.847a.753.753 0 0 0-.71.082 7.708 7.708 0 0 1-1.01.588.747.747 0 0 0-.414.569l-.315 2.244a.519.519 0 0 1-.501.43h-4.01a.52.52 0 0 1-.5-.415l-.316-2.242a.753.753 0 0 0-.422-.573 7.278 7.278 0 0 1-1.006-.59.75.75 0 0 0-.708-.08l-2.104.848a.507.507 0 0 1-.616-.215l-2.005-3.469a.506.506 0 0 1 .115-.644l1.791-1.406a.752.752 0 0 0 .281-.66 6.392 6.392 0 0 1 0-1.165.75.75 0 0 0-.284-.654L1.43 8.36a.507.507 0 0 1-.111-.641L3.324 4.25a.507.507 0 0 1 .616-.215l2.105.847a.755.755 0 0 0 .711-.082 7.71 7.71 0 0 1 1.01-.587.747.747 0 0 0 .413-.57L8.495 1.4a.52.52 0 0 1 .5-.43h4.01a.52.52 0 0 1 .501.416l.315 2.241a.753.753 0 0 0 .422.573c.35.17.687.366 1.006.59a.75.75 0 0 0 .708.08l2.104-.848a.507.507 0 0 1 .616.215l2.005 3.469a.506.506 0 0 1-.115.644l-1.79 1.406a.752.752 0 0 0-.284.66c.015.195.026.39.026.585Z"
            stroke="#2C3136"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default Settings
