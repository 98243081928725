import { FormControl, InputGroup } from "react-bootstrap";
import SearchOutlined from "../../assets/svg/SearchOutlined";
import "./styles.scss";

const SearchInput = ({ className, ...otherProps }) => {
  return (
    <InputGroup className="search-input-wrapper d-flex align-items-center">
      <div className="icon d-flex align-items-center justify-content-center me-2">
        <SearchOutlined />
      </div>
      <FormControl
        className={`input-style ${className}`}
        {...otherProps}
      />
    </InputGroup>
  );
};

export default SearchInput;
