import Form from "react-bootstrap/Form";
import "./styles.scss";

const Select = ({ label, value, onChange, data, required=false}, className) => {
  // const [sel, setSel] = useState("");

  // const handleChange = (event) => {
  //   setSel(event.target.value);
  // };

  return (
    <div className={`select-wrapper ${className}`}>
      {label && <h5 className="mb-2 me-1">{label}</h5>}
      <Form.Select
        className="sel"
        aria-label="Default select example"
        value={value}
        onChange={onChange}
        required={required}
      >
        {/* <option value="1">One</option>
        <option value="2">Two</option>
        <option value="3">Three</option> */}
        {data?.map((item) => (
          <option key={item} value={item}>
            {item}
          </option>
        ))}
      </Form.Select>
    </div>
  );
};

export default Select;
