import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PaginationWrapper from '../Pagination'
import Pill from '../Pill'
const CompetitionsEntryPointsTable = ({
    entryPoints,
    className,
    currentPage,
    setCurrentPage,
    pageCount,
    total,
    rowsPerPage,
    setRowsPerPage,
}) => {
    const pillHandler = (status) => {
        if (!status) return ''
        switch (status) {
            case 'active':
                return (
                    <Pill type="active">
                        <p>Aktivan</p>
                    </Pill>
                )
            case 'inactive':
                return (
                    <Pill type="inactive">
                        <p>Neaktivan</p>
                    </Pill>
                )
            case 'punished':
                return (
                    <Pill type="active">
                        <p>Draft</p>
                    </Pill>
                )
            default:
                return (
                    <Pill type="punished">
                        <p>Draft</p>
                    </Pill>
                )
        }
    }
    return (
        <div
            className={`table-competitors-pagination-wrapper table-competitors-wrapper ${className}`}
        >
            <Table className="mb-0">
                <thead>
                    <tr>
                        <th>
                            <h5>Naziv</h5>
                        </th>
                        <th>
                            <h5>Status</h5>
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {entryPoints?.map((entry, index) => (
                        <tr key={index}>
                            <td>
                                <p>{entry?.attributes?.entry_points_name}</p>
                            </td>
                            <td>
                                <p>
                                    {pillHandler(
                                        entry?.attributes?.entry_points_status
                                            ?.status_name
                                    )}
                                </p>
                            </td>
                            <td>
                                <Link
                                    to={`/takmicenja/podesavanje-takmicenja/${entry?.attributes?.uuid}`}
                                    // state={{ type: entry?.attributes?.type }}
                                >
                                    <p className="link">Pregled</p>
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <PaginationWrapper
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageCount={pageCount}
                total={total}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
            />
        </div>
    )
}

export default CompetitionsEntryPointsTable
