import { createContext, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getLoggedInUserInfo, getUserInfo } from './api'

const CODEBOOKS = {
    competitor_draw_rankings: [
        {
            id: 1,
            name: 'Pozicija ostvarena u grupi',
        },
        {
            id: 2,
            name: 'Po rang listi',
        },
    ],
    competitor_group_rankings: [
        {
            id: 1,
            name: 'Broj pobeda',
        },
        {
            id: 2,
            name: 'Međusobni dueli',
        },
        {
            id: 3,
            name: 'Set količnik',
        },
        {
            id: 4,
            name: 'Gem količnik',
        },
        {
            id: 5,
            name: 'Poen količnik',
        },
    ],
    competitor_licence_types: [
        {
            id: 1,
            name: 'Crveni',
        },
        {
            id: 2,
            name: 'Narandžasti',
        },
        {
            id: 3,
            name: 'Zeleni',
        },
        {
            id: 4,
            name: 'Regularna',
        },
        {
            id: 5,
            name: 'Veteranski',
        },
    ],
    genders: [
        {
            id: 1,
            name: 'Muški',
        },
        {
            id: 2,
            name: 'Ženski',
        },
    ],
    regions: [
        {
            id: 1,
            name: 'TSS',
        },
        {
            id: 2,
            name: 'Beograd',
        },
        {
            id: 3,
            name: 'Vojvodina',
        },
        {
            id: 4,
            name: 'Istočna Srbija',
        },
        {
            id: 5,
            name: 'Zapadna Srbija i Šumadija',
        },
    ],
    team_draw_rankings: [
        {
            id: 1,
            name: 'Pozicija ostvarena u grupi',
        },
        {
            id: 2,
            name: 'Po rang listi',
        },
    ],
    tournament_formats: [
        {
            id: 1,
            name: 'Klasično na ispadanje',
        },
        {
            id: 2,
            name: 'Round-Robin',
        },
        {
            id: 3,
            name: 'Srpski Gran Pri',
        },
    ],
    tournament_sorting_rang_lists: [
        {
            id: 1,
            name: 'ATP/WTA',
        },
        {
            id: 2,
            name: 'ITF',
        },
        {
            id: 3,
            name: 'ETA',
        },
        {
            id: 4,
            name: 'TSS',
        },
        {
            id: 5,
            name: 'REGIONALNA',
        },
        {
            id: 6,
            name: 'UTR',
        },
        {
            id: 7,
            name: 'WTN',
        },
        {
            id: 8,
            name: 'STN',
        },
    ],
    tournament_team_group_rankings: [
        {
            id: 1,
            name: 'Broj pobeda',
        },
        {
            id: 2,
            name: 'Međusobni duel',
        },
        {
            id: 3,
            name: 'Set količnik',
        },
        {
            id: 4,
            name: 'Gem količnik',
        },
        {
            id: 5,
            name: 'Poen količnik',
        },
        {
            id: 6,
            name: 'Bodovi ekipa',
        },
        {
            id: 7,
            name: 'Rang lista klubova',
        },
        {
            id: 8,
            name: 'Po rangiranju klubova',
        },
        {
            id: 9,
            name: 'Žreb',
        },
    ],
    tournament_tie_winner_options: [
        {
            id: 1,
            name: 'Set količnik',
        },
        {
            id: 2,
            name: 'Gem količnik',
        },
        {
            id: 3,
            name: 'Pobeda prve table',
        },
    ],
    periodsValue: [
        {
            id: 1,
            name: 'Leto',
        },
        {
            id: 2,
            name: 'Zima',
        },
    ],
    results_values: [
        {
            id: 1,
            name: 'Pobednik',
        },
        {
            id: 2,
            name: 'Finalista',
        },
        {
            id: 3,
            name: 'Polufinalista',
        },
    ],
    ranking_lists_values: [
        {
            id: 1,
            name: 'ATP/WTA',
        },
        {
            id: 2,
            name: 'WTN',
        },
        {
            id: 3,
            name: 'TSS',
        },
        {
            id: 4,
            name: 'ETA',
        },
        {
            id: 5,
            name: 'ITF',
        },
    ],
}

//file with all cities from Serbia
import citiesOfSerbia from './localData/cities.json'

const AppContext = createContext()

export function AppContextProvider({ children }) {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const [authenticated, setAuthenticated] = useState(false)
    const [user, setUser] = useState()
    const [clubs, setClubs] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [openModalSecond, setOpenModalSecond] = useState(false)

    const [unverifiedCompetitions, setUnverifiedCompetitions] = useState([])

    const [showCustomToast, setShowCustomToast] = useState(false)

    const [coaches, setCoaches] = useState([])

    const [creteCompetitionForm, setCreteCompetitionForm] = useState({
        type: '',
        name: '',
        competition_type: '',
        club_organizer_id: '',
        category: '',
        format: '',
    })

    const [isLoading, setIsLoading] = useState(false)
    const [codebooks, setCodebooks] = useState(CODEBOOKS)
    const [previousLoadedCompetitors, setPreviousLoadedCompetitors] =
        useState(null)
    const [sgpDataForPDF, setSgpDataForPDF] = useState({
        groupMatches: [],
        drawToShow: null,
        tournament: null,
    })

    const handleInitCompetitionsData = async () => {}

    useEffect(() => {
        if (pathname === '/takmicar/zavrsi-nalog')
            navigate('/takmicar/zavrsi-nalog')
    }, [pathname])

    const handleRefresh = async () => {
        try {
            const user = await getLoggedInUserInfo()
            if (user) {
                setAuthenticated(true)
                getUserInfo(user?.user, navigate, setUser, pathname)
            }
        } catch (error) {
            console.error(error)
        }
    }

    // Years from Current Year to passed year (number)
    const createYearsList = (startYear) => {
        const currentYear = new Date().getFullYear()

        return Array.from(
            { length: currentYear - startYear + 1 },
            (_, index) => currentYear - index
        )
    }

    let yearsList = createYearsList(1899)

    useEffect(() => {
        handleRefresh()
    }, [])

    return (
        <AppContext.Provider
            value={{
                authenticated,
                setAuthenticated,
                clubs,
                setClubs,
                openModal,
                setOpenModal,
                openModalSecond,
                setOpenModalSecond,
                creteCompetitionForm,
                setCreteCompetitionForm,
                user,
                coaches,
                setCoaches,
                setUser,
                unverifiedCompetitions,
                setUnverifiedCompetitions,
                isLoading,
                setIsLoading,
                citiesOfSerbia,
                handleInitCompetitionsData,
                codebooks,
                showCustomToast,
                setShowCustomToast,
                previousLoadedCompetitors,
                setPreviousLoadedCompetitors,
                yearsList,
                handleRefresh,
                sgpDataForPDF,
                setSgpDataForPDF,
            }}
        >
            {children}
        </AppContext.Provider>
    )
}

export function useAppContext() {
    return useContext(AppContext)
}
