import 'jspdf-autotable'
import { useEffect, useState } from 'react'
import {
    Breadcrumb,
    Card,
    Col,
    Form,
    Modal,
    Row,
    Tab,
    Tabs,
} from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Change from '../../../../assets/svg/Change'
import DocumentTextOutlined from '../../../../assets/svg/DocumentTextOutlined'
import PrintOutlined from '../../../../assets/svg/PrintOutlined'
import Trash from '../../../../assets/svg/Trash'
import Button from '../../../../components/Button'
import PDFScheduleModal from '../../../../components/PDFScheduleModal'
import { useAppContext } from '../../../../lib/contextLib'

import ConfirmationModal from '../../../../components/ConfirmationModal'
import TextField from '../../../../components/TextField'
import {
    deleteTournament,
    getSingleClub,
    getSingleEntryPoints,
    getSingleTournament,
    getTournamentAccommodation,
    getTournamentsAdditionalInformations,
    updateTournament,
} from '../../../../lib/api'
import NewDrawTab from './Tabs/Draw/index'
import InformationTab from './Tabs/Information'
import ParticipantsTab from './Tabs/Participants'
import RefereesTab from './Tabs/Referees'
import ReportTab from './Tabs/Report'
import ScheduleTab from './Tabs/Schedule/index'

import {
    handleExportInformationTabPDF,
    handlePrintInformationTab,
} from './Tabs/Information/pdfExport'

import {
    handleExportParticipantsTabPDF,
    handlePrintParticipantsTab,
} from './Tabs/Participants/pdfExport'

import {
    handleExportDrawSerbianGranPriPDF,
    handlePrintDrawSerbianGranPriPDF,
} from './Tabs/Draw/GranPri/pdfExport'
import {
    handleExportRefereesTabPDF,
    handlePrintRefereesTab,
} from './Tabs/Referees/pdfExport'
import {
    handleExportScheduleTabPDF,
    handlePrintScheduleTabPDF,
} from './Tabs/Schedule/schedulePdfExport'
import './styles.scss'

const CompetitionProfile = ({ type = false }) => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [showDrawPDFModal, setShowDrawPDFModal] = useState(false)
    const { user, setShowCustomToast, setIsLoading, sgpDataForPDF } =
        useAppContext()
    const [showDrawChangeModal, setShowDrawChangeModal] = useState(false)
    const [activeTab, setActiveTab] = useState('informacije')
    const [participantsToShow, setParticipantsToShow] = useState({
        is_double: false,
    })

    const [showScheduleForExportModal, setShowScheduleForExportModal] =
        useState(false)
    const [competitionProfileData, setCompetitionProfileData] = useState()
    const [accommodationData, setAccommodationData] = useState()
    const [
        tournamentsAdditionalInformationsData,
        setTournamentsAdditionalInformationsData,
    ] = useState()
    const [tournamentsEntryPoints, setTournamentsEntryPoints] = useState()
    const [playersForTournamentList, setPlayersForTournamentList] = useState([])
    const [showScheduleModalForExport, setShowScheduleModalForExport] =
        useState(false)
    const [
        showConfirmFinishingCompetition,
        setShowConfirmFinishingCompetition,
    ] = useState(false)
    const [showConfirmVerifyCompetition, setShowConfirmVerifyCompetition] =
        useState(false)
    const [competitionEndDate, setCompetitionEndDate] = useState(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [referees, setReferees] = useState([])
    const [allPlayersOnTournament, setAllPlayersOnTournament] = useState([])
    const [printDrawSelected, setPrintDrawSelected] = useState(false)

    // Function to handle delete competition modal
    const handleDeleteCompetitionModal = () => {
        setShowDeleteModal((_) => {
            return true
        })
    }

    const handleSingleTournamentData = async () => {
        setIsLoading(true)
        const data = await getSingleTournament(id)
        const tournamentAccommodation = await getTournamentAccommodation(id)
        const additionalInformations =
            await getTournamentsAdditionalInformations(id)
        if (data) {
            const club_organizer = await getSingleClub(
                data.attributes.club_organizer_uuid
            )

            data.attributes.club_organizer = { ...club_organizer }

            setActiveTab(
                localStorage.getItem('competitionActiveTab')
                    ? localStorage.getItem('competitionActiveTab')
                    : 'informacije'
            )
            if (data?.attributes?.entry_points_uuid) {
                const entryPoints = await getSingleEntryPoints(
                    data?.attributes?.entry_points_uuid
                )
                setTournamentsEntryPoints(entryPoints)
            }
            setIsLoading(false)
            setCompetitionProfileData(data)
            setParticipantsToShow((prev) => ({
                ...prev,
                gender: data?.attributes?.genders[0]?.gender_name,
            }))
            setAccommodationData(tournamentAccommodation)
            setTournamentsAdditionalInformationsData(additionalInformations)
        }
        setIsLoading(false)
    }

    const handleEdit = (id) => {
        navigate(`/takmicenja/${id}/izmeni-takmicenje`)
    }

    const handleDelete = async (id) => {
        try {
            const data = await deleteTournament(id)
            if (data) {
                navigate(`/takmicenja`)
                setShowDeleteModal(false)
                setShowCustomToast({
                    show: true,
                    type: 'success',
                    message: 'Takmičenje je obrisano.',
                })
            }
        } catch (error) {
            if (
                error?.response?.data?.error ===
                'It is impossible to delete a tournament because it is finished.'
            ) {
                setShowCustomToast({
                    show: true,
                    type: 'error',
                    message: 'Nije moguće obrisati takmičenje jer je završeno.',
                })
            } else if (
                error?.response?.data?.error ===
                'It is impossible to delete a tournament because there is already a match created with it.'
            ) {
                setShowCustomToast({
                    show: true,
                    type: 'error',
                    message:
                        'Nije moguće obrisati takmičenje jer ima kreiranih mečeva.',
                })
            } else if (
                error?.response?.data?.error ===
                'It is impossible to delete a tournament because there is already a winner on it.'
            ) {
                setShowCustomToast({
                    show: true,
                    type: 'error',
                    message:
                        'Nije moguće obrisati takmičenje jer ima pobednika.',
                })
            } else if (
                error?.response?.data?.error ===
                'It is impossible to delete a tournament because there is already a report for it.'
            ) {
                setShowCustomToast({
                    show: true,
                    type: 'error',
                    message:
                        'Nije moguće obrisati takmičenje jer već postoji izveštaj za isti.',
                })
            } else {
                setShowCustomToast({
                    show: true,
                    type: 'error',
                    message: 'Došlo je do greške.',
                })
            }
            setShowDeleteModal(false)
        }
    }

    const handleVerifyReport = async (id) => {}

    // Handle paths in breadcumbs when this component is used in admin and competitor flow
    const pathHandler = () => {
        switch (user?.role?.type) {
            case 'admin':
                return '/pocetna'
            case 'competitor':
                return '/takmicar/pocetna'
            case 'referee':
                if (user?.data?.attributes?.is_administrator) {
                    return '/administrator-sudjenja/pocetna'
                } else {
                    return '/sudije/pocetna'
                }
        }
    }

    const handleCompetitionFinishingConfirmation = async () => {
        const data = await updateTournament(id, {
            is_finished: competitionProfileData?.attributes?.is_finished
                ? false
                : true,
            end_date: competitionEndDate,
        })

        if (data) {
            setShowConfirmFinishingCompetition((_) => {
                return false
            })
            setCompetitionEndDate(() => {
                return null
            })
            handleSingleTournamentData()
        }
    }

    useEffect(() => {
        handleSingleTournamentData()
    }, [])

    const backToCompetitionsHandler = (tournamentType) => {
        switch (user?.role?.type) {
            case 'competitor':
                return '/takmicar/takmicenja'
            case 'referee':
                if (tournamentType === 'Ekipno') {
                    if (user?.data?.attributes?.is_administrator) {
                        return '/administrator-sudjenja/ekipna-takmicenja'
                    } else {
                        return '/sudije/ekipna-takmicenja'
                    }
                } else if (tournamentType === 'Individualno') {
                    if (user?.data?.attributes?.is_administrator) {
                        return '/administrator-sudjenja/individualna-takmicenja'
                    } else {
                        return '/sudije/individualna-takmicenja'
                    }
                } else {
                    if (user?.data?.attributes?.is_administrator) {
                        return '/administrator-sudjenja/takmicenja-iz-kalendara-TSS'
                    } else {
                        return '/sudije/takmicenja-iz-kalendara-TSS'
                    }
                }
        }
    }

    return (
        <Row className="club-ranking-wrapper h-100 competition-profile">
            <Col>
                <Row className="pt-3">
                    <Col
                        xs={12}
                        className="d-flex align-items-center justify-content-between"
                    >
                        <Row>
                            <Col>
                                <h2 className="d-none d-md-block">
                                    {competitionProfileData?.attributes?.name}{' '}
                                </h2>
                                <p className="d-block d-md-none">
                                    {competitionProfileData?.attributes?.name}
                                </p>
                                <Breadcrumb className="mt-md-2 mt-1">
                                    <Breadcrumb.Item
                                        linkAs={Link}
                                        linkProps={{ to: pathHandler() }}
                                    >
                                        Početna
                                    </Breadcrumb.Item>
                                    {user?.role?.type === 'admin' ? (
                                        <Breadcrumb.Item active>
                                            Takmičenja
                                        </Breadcrumb.Item>
                                    ) : (
                                        <>
                                            <Breadcrumb.Item
                                                linkAs={Link}
                                                linkProps={{
                                                    to: backToCompetitionsHandler(
                                                        type
                                                    ),
                                                    state: {
                                                        type: type
                                                            ? type
                                                            : null,
                                                    },
                                                }}
                                            >
                                                {type
                                                    ? type === 'Ekipno'
                                                        ? 'Ekipna'
                                                        : type ===
                                                          'Individualno'
                                                        ? 'Individualna'
                                                        : 'Iz kalendara TSS'
                                                    : 'Iz kalendara TSS'}
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item active>
                                                {
                                                    competitionProfileData
                                                        ?.attributes?.name
                                                }
                                            </Breadcrumb.Item>
                                        </>
                                    )}
                                </Breadcrumb>
                            </Col>
                        </Row>

                        <div className="d-flex align-items-center d-md-none">
                            <Button type="text">
                                <PrintOutlined height="24px" width="24px" />
                            </Button>
                            {user?.role?.type === 'admin' && (
                                <>
                                    <Button type="text">
                                        <Change height="24px" width="24px" />
                                    </Button>

                                    {competitionProfileData?.attributes
                                        ?.is_finished !== true && (
                                        <Button type="text">
                                            <Trash height="24px" width="24px" />
                                        </Button>
                                    )}
                                </>
                            )}
                        </div>
                        <div
                            className="d-none d-md-flex justify-content-end mt-2 mt-md-0"
                            style={{ gap: '8px' }}
                        >
                            <Button
                                type="text"
                                leadingIcon={
                                    <DocumentTextOutlined height="24px" />
                                }
                                onClick={() => {
                                    activeTab === 'ucesnici'
                                        ? handleExportParticipantsTabPDF({
                                              competitionProfileData,
                                              participantsToShow,
                                          })
                                        : activeTab === 'raspored'
                                        ? // ? setShowScheduleModalForExport(true)
                                          handleExportScheduleTabPDF({
                                              competitionProfileData,
                                              setIsLoading,
                                          })
                                        : activeTab === 'rezultati'
                                        ? // &&
                                          //   competitionProfileData?.attributes
                                          //       ?.tournament_type
                                          //       ?.tournament_type_name ===
                                          //       'Individualno'
                                          sgpDataForPDF?.drawToShow?.draw_type
                                              ?.draw_type_name ===
                                          'Srbija Gran Pri'
                                            ? handleExportDrawSerbianGranPriPDF(
                                                  {
                                                      competitionProfileData,
                                                      sgpDataForPDF,
                                                  }
                                              )
                                            : setShowDrawPDFModal(true)
                                        : activeTab === 'sudjenje'
                                        ? handleExportRefereesTabPDF({
                                              competitionProfileData,
                                              referees,
                                          })
                                        : handleExportInformationTabPDF({
                                              competitionProfileData,
                                              tournamentsAdditionalInformationsData,
                                              accommodationData,
                                              tournamentsEntryPoints,
                                          })
                                }}
                            >
                                Eksportuj u PDF
                            </Button>

                            <Button
                                type="secondary"
                                leadingIcon={<PrintOutlined height="24px" />}
                                onClick={() => {
                                    activeTab === 'ucesnici'
                                        ? handlePrintParticipantsTab({
                                              competitionProfileData,
                                              playersForTournamentList,
                                          })
                                        : activeTab === 'sudjenje'
                                        ? handlePrintRefereesTab({
                                              competitionProfileData,
                                              referees,
                                          })
                                        : activeTab === 'raspored'
                                        ? handlePrintScheduleTabPDF({
                                              competitionProfileData,
                                              setIsLoading,
                                          })
                                        : activeTab === 'rezultati'
                                        ? // &&
                                          //   competitionProfileData?.attributes
                                          //       ?.tournament_type
                                          //       ?.tournament_type_name ===
                                          //       'Individualno'
                                          sgpDataForPDF?.drawToShow?.draw_type
                                              ?.draw_type_name ===
                                          'Srbija Gran Pri'
                                            ? handlePrintDrawSerbianGranPriPDF({
                                                  competitionProfileData,
                                                  sgpDataForPDF,
                                              })
                                            : (setPrintDrawSelected(true),
                                              setShowDrawPDFModal(true))
                                        : handlePrintInformationTab({
                                              competitionProfileData,
                                              tournamentsAdditionalInformationsData,
                                              accommodationData,
                                              tournamentsEntryPoints,
                                          })
                                }}
                            >
                                Štampaj
                            </Button>

                            {user?.role?.type === 'admin' && (
                                <>
                                    <Button
                                        leadingIcon={<Change height="24px" />}
                                        type="secondary"
                                        onClick={() =>
                                            handleEdit(
                                                competitionProfileData
                                                    ?.attributes?.uuid
                                            )
                                        }
                                    >
                                        Izmeni
                                    </Button>
                                    {competitionProfileData?.attributes
                                        ?.is_finished !== true && (
                                        <Button
                                            leadingIcon={
                                                <Trash height="24px" />
                                            }
                                            type="secondary"
                                            onClick={
                                                handleDeleteCompetitionModal
                                            }
                                        >
                                            Obriši
                                        </Button>
                                    )}
                                </>
                            )}
                        </div>
                    </Col>
                </Row>

                <Row className="pt-3 m-0">
                    <Col className="p-0 tabular-navigation">
                        <Tabs
                            id="uncontrolled-tab-example"
                            defaultActiveKey={activeTab}
                            activeKey={activeTab}
                            onSelect={(e) => {
                                setActiveTab(e)
                                localStorage.setItem(
                                    'competitionActiveTab',
                                    e.toString()
                                )
                            }}
                        >
                            <Tab eventKey="informacije" title="Informacije">
                                <Row className="mt-3 flex-column">
                                    <Col
                                        xs={12}
                                        md
                                        className="d-flex align-items-center justify-content-center"
                                    >
                                        <Form.Switch
                                            name="competition-finished-switch"
                                            className="competition-finished-switch p-0"
                                            label={
                                                <h4 className="competition-finished-switch-label pb-2">
                                                    Takmičenje završeno
                                                </h4>
                                            }
                                            checked={
                                                competitionProfileData
                                                    ?.attributes
                                                    ?.is_finished === true
                                                    ? true
                                                    : false
                                            }
                                            onChange={() =>
                                                setShowConfirmFinishingCompetition(
                                                    true
                                                )
                                            }
                                            disabled={
                                                user?.role?.type ===
                                                'competitor'
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </Col>
                                    {/* {user?.role?.type === 'admin' ? (
                                        <Col className="d-flex align-items-center justify-content-center mt-3">
                                            <Button
                                                type="secondary"
                                                // onClick={exportXLSX}
                                            >
                                                Eksportuj mečeve
                                            </Button>
                                        </Col>
                                    ) : null} */}
                                </Row>
                                <InformationTab
                                    tournamentsAdditionalInformationsData={
                                        tournamentsAdditionalInformationsData
                                    }
                                    competitionProfileData={
                                        competitionProfileData
                                    }
                                    accommodationData={accommodationData}
                                    tournamentsEntryPoints={
                                        tournamentsEntryPoints
                                    }
                                    activeTab={activeTab}
                                />
                            </Tab>

                            <Tab eventKey="ucesnici" title="Status">
                                <ParticipantsTab
                                    competitionProfileData={
                                        competitionProfileData
                                    }
                                    activeTab={activeTab}
                                    playersForTournamentList={
                                        playersForTournamentList
                                    }
                                    setPlayersForTournamentList={
                                        setPlayersForTournamentList
                                    }
                                    participantsToShow={participantsToShow}
                                    setParticipantsToShow={
                                        setParticipantsToShow
                                    }
                                    allPlayersOnTournament={
                                        allPlayersOnTournament
                                    }
                                    setAllPlayersOnTournament={
                                        setAllPlayersOnTournament
                                    }
                                />
                            </Tab>

                            <Tab eventKey="rezultati" title="Žreb/Rezultati">
                                <NewDrawTab
                                    activeTab={activeTab}
                                    showDrawPDFModal={showDrawPDFModal}
                                    setShowDrawPDFModal={setShowDrawPDFModal}
                                    printDrawSelected={printDrawSelected}
                                    setPrintDrawSelected={setPrintDrawSelected}
                                />
                            </Tab>

                            <Tab
                                eventKey="raspored"
                                title="Raspored"
                                className="pb-3"
                            >
                                <ScheduleTab
                                    tournament={competitionProfileData}
                                    activeTab={activeTab}
                                    showScheduleModalForExport={
                                        showScheduleModalForExport
                                    }
                                    setShowScheduleModalForExport={
                                        setShowScheduleModalForExport
                                    }
                                />
                            </Tab>
                            <Tab
                                eventKey="sudjenje"
                                title="Suđenje"
                                className="pb-3"
                            >
                                <RefereesTab
                                    tournament={competitionProfileData}
                                    activeTab={activeTab}
                                    referees={referees}
                                    setReferees={setReferees}
                                />
                            </Tab>

                            {competitionProfileData?.attributes?.is_finished ? (
                                <Tab
                                    eventKey="izvestaj"
                                    title="Izveštaj"
                                    className="pb-3 report-tab-wrapper"
                                >
                                    <ReportTab
                                        tournament={competitionProfileData}
                                        activeTab={activeTab}
                                    />
                                </Tab>
                            ) : (
                                <Tab
                                    eventKey="izvestaj"
                                    title="Izveštaj"
                                    className="pb-3"
                                >
                                    <Row className="mt-3">
                                        <Col>
                                            <Card>
                                                <Card.Body>
                                                    <p>
                                                        Još uvek ne postoji
                                                        izveštaj za ovo
                                                        takmičenje.
                                                    </p>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Tab>
                            )}
                        </Tabs>
                    </Col>
                </Row>
            </Col>

            <ConfirmationModal
                modalText="Jeste li sigurni da želite obrisati takmičenje?"
                onHide={() => setShowDeleteModal(false)}
                show={showDeleteModal}
                onClick={() =>
                    handleDelete(competitionProfileData?.attributes?.uuid)
                }
            />

            <ConfirmationModal
                modalTitle="Prebacivanje takmičenja"
                modalText={
                    competitionProfileData?.attributes?.is_finished
                        ? 'Da li ste sigurni da želite prebaciti takmičenje u nadolazeće?'
                        : 'Da li ste sigurni da želite prebaciti takmičenje u završeno?'
                }
                firstButtonText="Odustani"
                secondButtonText="Potvrdi"
                show={showConfirmFinishingCompetition}
                onHide={() => {
                    setShowConfirmFinishingCompetition((_) => {
                        return false
                    })
                    setCompetitionEndDate(() => {
                        return null
                    })
                }}
                onClick={handleCompetitionFinishingConfirmation}
            >
                {!competitionProfileData?.attributes?.is_finished ? (
                    <TextField
                        type="date"
                        className="mt-3"
                        label="Datum završetka"
                        value={competitionEndDate || ''}
                        onChange={(e) =>
                            setCompetitionEndDate((_) => {
                                return e.target.value
                            })
                        }
                    />
                ) : null}
            </ConfirmationModal>

            <ConfirmationModal
                modalTitle="Verifikacija takmičenja"
                modalText={'Da li ste sigurni da želite izvršiti verifikaciju?'}
                firstButtonText="Odustani"
                secondButtonText="Potvrdi"
                show={showConfirmVerifyCompetition}
                onHide={() =>
                    setShowConfirmVerifyCompetition((_) => {
                        return false
                    })
                }
                onClick={() =>
                    handleVerifyReport(competitionProfileData?.attributes?.uuid)
                }
            />

            <Modal
                centered
                show={showDrawChangeModal}
                onHide={() => setShowDrawChangeModal(false)}
                dialogClassName="add-penalties-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>Veličina kostura</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Da li ste sigurni da želite da promenite veličinu
                        kostura?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="text"
                        onClick={() => setShowDrawChangeModal(false)}
                    >
                        Odustani
                    </Button>
                    <Button onClick={() => submitDrawSizeChange()}>
                        Potvrdi
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* <PDFDrawModal
                show={showDrawForExportModal}
                onHide={() => setShowDrawForExportModal(false)}
                drawToShow={drawToShow}
                competitionProfileData={competitionProfileData}
            /> */}

            <PDFScheduleModal
                show={showScheduleForExportModal}
                onHide={() => setShowScheduleForExportModal(false)}
                competitionProfileData={competitionProfileData}
            />
        </Row>
    )
}

export default CompetitionProfile
