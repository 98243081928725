import { Table } from 'react-bootstrap'

const AssemblyClubQualityTable = ({ data }) => {
    const TableCellName = {
        senior: 'Učesnik seniorske lige',
        junior: 'Učesnik juniorske lige',
    }
    return (
        <div className="table-clubs-ranking-wrapper">
            {data?.length > 0 ? (
                <Table className="mb-0 settings-table">
                    <thead>
                        <tr>
                            <th>
                                <h5>Učesnik u ligi</h5>
                            </th>
                            <th>
                                <h5>Glasova</h5>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {data?.map((result, index) => (
                            <tr key={index} className="setting-table-row">
                                <td>
                                    <p className="align-middle">
                                        {TableCellName[
                                            result?.league?.toLowerCase()
                                        ] ?? ''}
                                    </p>
                                </td>
                                <td>
                                    <p className="align-middle">
                                        {result?.votes}
                                    </p>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            ) : null}
        </div>
    )
}

export default AssemblyClubQualityTable
