import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Button from '../../../../../../components/Button'
import CompetitionReport from '../../../../../../components/CompetitionReport'
import Pill from '../../../../../../components/Pill'
import {
    getRefereeLicense,
    getSingleReferee,
    getTournamentReport,
    getTournamentsReferees,
} from '../../../../../../lib/api'
import { useAppContext } from '../../../../../../lib/contextLib'
import VerifyTournamentModal from './verifyTournamentModal'

function ReportTab({ tournament, activeTab }) {
    const { user, setShowCustomToast } = useAppContext()

    const [reportData, setReportData] = useState()
    const [showVerifyModal, setShowVerifyModal] = useState(false)
    const [referees, setReferees] = useState([])

    const fetchTournamentReport = async (tournamentUuid) => {
        try {
            let tournamentReportResponse = await getTournamentReport(
                tournamentUuid
            )

            if (tournamentReportResponse) {
                setReportData(tournamentReportResponse[0])
            }
        } catch (error) {
            console.log(error)
        }
    }

    const fetchTournamentReferees = async (tournamentUuid) => {
        const refereesResponse = await getTournamentsReferees(tournamentUuid)

        if (refereesResponse && refereesResponse?.length > 0) {
            let refereePromises = refereesResponse?.map(
                async (singleReferee) => {
                    let refereeData = await getSingleReferee(
                        singleReferee?.attributes?.referee_uuid
                    )

                    if (refereeData) {
                        let licenses = await getRefereeLicense(
                            singleReferee?.attributes?.referee_uuid
                        )

                        if (licenses) {
                            return {
                                ...singleReferee,
                                attributes: {
                                    ...singleReferee.attributes,
                                    refereeData: {
                                        ...refereeData,
                                        licenses,
                                    },
                                },
                            }
                        }
                    }
                }
            )

            let updatedRefereesResponse = await Promise.all(refereePromises)
            setReferees(updatedRefereesResponse)
        }
    }

    const refreshData = async () => {
        await fetchTournamentReport(tournament?.attributes?.uuid)
        await fetchTournamentReferees(tournament?.attributes?.uuid)
    }

    useEffect(() => {
        if (activeTab === 'izvestaj') {
            fetchTournamentReport(tournament?.attributes?.uuid)
            fetchTournamentReferees(tournament?.attributes?.uuid)
        }
    }, [tournament, activeTab])

    return (
        <>
            <Row className="pt-3 d-flex justify-content-center">
                <Col xs={12} md={8}>
                    <Row>
                        <Col className="d-flex align-items-center justify-content-between mb-3 mx-md-0 mx-2">
                            <h2>Izveštaj glavnog sudije</h2>

                            {reportData?.attributes?.is_verified ? (
                                <Pill
                                    type="active"
                                    className="report-pill-content"
                                >
                                    <p>Verifikovano</p>
                                </Pill>
                            ) : user?.role?.type === 'admin' ? (
                                <Button
                                    className="report-pill-content"
                                    onClick={() =>
                                        setShowVerifyModal(
                                            reportData?.attributes?.uuid
                                        )
                                    }
                                >
                                    Verifikuj
                                </Button>
                            ) : (
                                <Pill
                                    type="punished"
                                    className="report-pill-content"
                                >
                                    <p>Čeka verifikaciju</p>
                                </Pill>
                            )}
                        </Col>
                    </Row>
                    <CompetitionReport
                        reportData={{
                            attributes: {
                                ...tournament?.attributes,
                                competition_report: [
                                    {
                                        report_data:
                                            reportData?.attributes?.report_data,
                                    },
                                ],
                                referees: referees,
                            },
                        }}
                    />
                </Col>
            </Row>
            <VerifyTournamentModal
                show={showVerifyModal}
                onHide={() => setShowVerifyModal(false)}
                refreshData={refreshData}
            />
        </>
    )
}

export default ReportTab
