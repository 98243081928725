import axios from 'axios'
import { getDataFromSession } from './helpers'

const baseURL = process.env.REACT_APP_BASE_URL

const axiosInstance = axios.create({
    baseURL,
})

axiosInstance.interceptors.request.use((config) => {
    const token =
        typeof window !== 'undefined' && getDataFromSession('tssUserJwt')
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
})

export default axiosInstance
