import { useEffect, useState } from 'react'
import { Breadcrumb, Col, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import Add from '../../../../assets/svg/Add'
import AsyncSelect from '../../../../components/AsyncSelect'
import Button from '../../../../components/Button'
import CompetitionsEntryPointsTable from '../../../../components/CompetitionsEntryPointsTable'
import ConfirmationModal from '../../../../components/ConfirmationModal'
import { RowsPerPageOptions } from '../../../../components/RowsPerPageDropdown'
import TextField from '../../../../components/TextField'
import { createEntryPoints, getEntryPoints } from '../../../../lib/api'
import { getEntryPointsList } from '../../../../lib/helpers'

const CompetitionsEntryPoints = () => {
    // Pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [total, setTotal] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(RowsPerPageOptions[0])

    const navigate = useNavigate()
    const [entryPoints, setEntryPoints] = useState([])
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [selectedScoringClone, setSelectedScoringClone] = useState(null)
    const [newScoringName, setNewScoringName] = useState(null)

    useEffect(() => {
        handleGetEntryPoints(currentPage)
    }, [currentPage, rowsPerPage])

    const handleGetEntryPoints = async (page) => {
        let {
            entryPoints: data,
            pgCount,
            total,
        } = await getEntryPoints(
            ['entry_points_name', 'uuid'],
            page,
            false,
            rowsPerPage
        )
        if (data) {
            setEntryPoints(data)
            setPageCount(pgCount)
            setTotal(total)
            setCurrentPage(page)
        }
    }

    const handleEntryPointsCloneSelect = (e) => {
        setSelectedScoringClone(e?.uuid)
    }

    const handleCreateNewScoring = async () => {
        const newScoring = await createEntryPoints(
            newScoringName,
            selectedScoringClone
        )
        if (newScoring) {
            handleCloseModal()
            handleGetEntryPoints(currentPage)
            navigate(
                `/takmicenja/podesavanje-takmicenja/${newScoring?.attributes?.uuid}`
            )
        }
    }

    const handleCloseModal = () => {
        setShowCreateModal((_) => {
            return false
        })
        setNewScoringName(null)
        setSelectedScoringClone(null)
    }

    return (
        <>
            <Row className="club-ranking-wrapper h-100">
                <Col>
                    <Row className="pt-3">
                        <Col
                            xs={12}
                            md={4}
                            className="d-flex align-items-center justify-content-between"
                        >
                            <Row>
                                <Col className="">
                                    <h2 className="d-none d-md-block">
                                        Podešavanja
                                    </h2>
                                    <p className="d-block d-md-none">
                                        Podešavanja
                                    </p>
                                    <Breadcrumb className="mt-md-2 mt-1">
                                        <Breadcrumb.Item
                                            linkAs={Link}
                                            linkProps={{ to: '/pocetna' }}
                                        >
                                            Početna
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item
                                            linkAs={Link}
                                            linkProps={{ to: '/takmicenja' }}
                                        >
                                            Takmičenja
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item active>
                                            Podešavanja
                                        </Breadcrumb.Item>
                                    </Breadcrumb>
                                </Col>
                            </Row>

                            <div className="d-flex align-items-center d-md-none">
                                <Link to="/takmicenja/kreiraj-bodovanje">
                                    <Button type="text">
                                        <Add />
                                    </Button>
                                </Link>
                            </div>
                        </Col>
                        <Col xs={12} md={8}>
                            <Row className="d-flex align-items-center">
                                <div className="d-flex justify-content-end w-100 mt-2 mt-md-0">
                                    <Button
                                        onClick={() => setShowCreateModal(true)}
                                    >
                                        Kreiraj bodovanje
                                    </Button>
                                </div>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="pt-3 m-0">
                        <Col className="p-0">
                            <CompetitionsEntryPointsTable
                                entryPoints={entryPoints}
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                                pageCount={pageCount}
                                total={total}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            {/* Creating new Scoring Modal  */}
            <ConfirmationModal
                modalTitle="Kreiranje novog bodovanja"
                modalText={null}
                show={showCreateModal}
                onHide={handleCloseModal}
                secondButtonText="Kreiraj"
                disabledConfirm={!(newScoringName && selectedScoringClone)}
                onClick={handleCreateNewScoring}
            >
                <AsyncSelect
                    loadOptions={getEntryPointsList}
                    onChange={handleEntryPointsCloneSelect}
                    label={'Izaberi bodovanje za kloniranje'}
                    placeholder={'Izaberi bodovanje'}
                    noOptionsMessage={'Traženo bodovanje ne postoji'}
                    closeMenuOnSelect={true}
                    isMulti={false}
                    required
                />
                <TextField
                    label="Naziv novog bodovanja"
                    placeholder="Unesite naziv novog bodovanja"
                    type="text"
                    required
                    value={newScoringName || ''}
                    onChange={(e) =>
                        setNewScoringName((_) => {
                            return e?.target?.value
                        })
                    }
                    className="mt-3"
                />
            </ConfirmationModal>
        </>
    )
}

export default CompetitionsEntryPoints
