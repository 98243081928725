import React from 'react'
import ConfirmationModal from '../../../../../../components/ConfirmationModal'
import { removeRefereeFromTournament } from '../../../../../../lib/api'
import { useAppContext } from '../../../../../../lib/contextLib'

function RemoveTournamentRefereeModal({ show, onHide, refreshData }) {
    const { setShowCustomToast } = useAppContext()

    const handleRefereeRemove = async (entryUuid) => {
        try {
            const deletedReferee = await removeRefereeFromTournament(entryUuid)

            if (deletedReferee) {
                setShowCustomToast({
                    show: true,
                    type: 'success',
                    message: 'Sudija uspešno uklonjen sa takmičenja.',
                })
                await refreshData()
                onHide()
            }
        } catch (error) {
            setShowCustomToast({
                show: true,
                type: 'error',
                message: 'Došlo je do greške.',
            })
        }
    }
    return (
        <ConfirmationModal
            modalTitle="Potvrda brisanja"
            modalText="Jeste li sigurni da želite obrisati sudiju sa takmičenja?"
            show={show}
            onClick={() => handleRefereeRemove(show)}
            onHide={onHide}
            dialogClassName="remove-tournament-referees-modal"
        />
    )
}

export default RemoveTournamentRefereeModal
