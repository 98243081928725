import React from 'react'
import Slider from 'react-slick'
import TournamentRoundCard from '../../../../../../../components/TournamentRoundCard'
import { settings } from '../carouselSettings'
import GroupParticipantsSection from './groupParticipantsSection'

const RoundRobinSlider = ({ tournament, drawToShow, numberOfGroups }) => {
    return (
        <Slider {...settings(1)} className="rounds-slider">
            {[...Array(numberOfGroups)].map((_, groupIndex) => (
                <TournamentRoundCard
                    key={groupIndex}
                    index={groupIndex}
                    roundName={`${groupIndex + 1}. grupa`}
                >
                    <GroupParticipantsSection
                        groupDetails={{
                            tournament_uuid: tournament?.attributes?.uuid,
                            group_number: groupIndex + 1,
                        }}
                        drawToShow={drawToShow}
                    />
                </TournamentRoundCard>
            ))}
        </Slider>
    )
}

export default RoundRobinSlider
