import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Cell, Pie, PieChart } from 'recharts'

import ArrowRight from '../../../assets/svg/ArrowRight'
import Button from '../../../components/Button'
import {
    getLastMedicalCerificate,
    getLastMembership,
    getPlayerStatistisc,
    getPlayersClub,
    getPlayersClubHistory,
    getPlayersClubLoan,
    getPlayersRankingLists,
    getSingleClub,
    getSingleCoach,
    getSingleCompetitorsRankingList,
} from '../../../lib/api'
import { useAppContext } from '../../../lib/contextLib'
import {
    calculateCategory,
    calculateSubcategory,
    formatDate,
    formatImgUrl,
} from '../../../lib/helpers'
import { generateAvatar } from '../../../utils/generateAvatar'
import './style.scss'

const Profile = ({
    setIsEditing,
    setShowMembership,
    setShowMedicalHistory,
}) => {
    const { user } = useAppContext()
    const [lastMembership, setLastMembership] = useState()
    const [lastMedicalCertificate, setLastMedicalCertificate] = useState()
    const [playersClub, setPlayersClub] = useState()
    const [playersClubLoan, setPlayersClubLoan] = useState()
    const [playersClubHistory, setPlayersClubHistory] = useState([])
    const [playersRankingList, setPlayersRankingList] = useState([])

    //* Coach States
    const [coachProfile, setCoachProfile] = useState()

    const COLORS = ['#4B852D', '#D23E3F']

    const [competitorStatistics, setCompetitorStatistics] = useState({
        total_matches: 0,
        result: [
            { name: 'Pobeda', value: 0 },
            { name: 'Poraza', value: 0 },
        ],
    })

    const userBasicInfo = [
        {
            label: 'Ime',
            name: user?.data?.attributes?.person_info?.given_name,
        },
        {
            label: 'Prezime',
            name: user?.data?.attributes?.person_info?.family_name,
        },
        {
            label: 'Trener',
            name: coachProfile
                ? `${coachProfile?.attributes?.person_info?.given_name} ${coachProfile?.attributes?.person_info?.family_name}`
                : false,
        },
        {
            label: 'Email',
            name: user?.data?.attributes?.person_info?.email
                ? user?.data?.attributes?.person_info?.email
                : false,
        },
    ]

    const userDetails = [
        {
            label: 'Datum rođenja',
            name: user?.data?.attributes?.person_info?.birthdate
                ? formatDate(user?.data?.attributes?.person_info?.birthdate)
                : false,
        },
        {
            label: 'Mesto rođenja',
            name: user?.data?.attributes?.birth_address?.city
                ? user?.data?.attributes?.birth_address?.city
                : false,
        },
        {
            label: 'Adresa',
            name:
                user?.data?.attributes?.birth_address?.street &&
                user?.data?.attributes?.birth_address?.city
                    ? `${user?.data?.attributes?.birth_address?.street}, ${user?.data?.attributes?.birth_address?.city}`
                    : false,
        },
        {
            label: 'Stranac',
            name:
                user?.data?.attributes?.citizenship
                    ?.is_serbian_citizen_internationally_registered !== null &&
                user?.data?.attributes?.citizenship
                    ?.is_serbian_citizen_internationally_registered !==
                    undefined
                    ? user?.data?.attributes?.citizenship
                          ?.is_serbian_citizen_internationally_registered ===
                          true &&
                      (user?.data?.attributes?.citizenship
                          ?.citizenship_alpha_3_code === 'SRB' ||
                          user?.data?.attributes?.citizenship
                              ?.second_citizenship_alpha_3_code === 'SRB')
                        ? 'Ne'
                        : 'Da'
                    : false,
        },
        {
            label: 'Dvojno državljanstvo',
            name:
                user?.data?.attributes?.citizenship?.citizenship_alpha_3_code &&
                user?.data?.attributes?.citizenship
                    ?.second_citizenship_alpha_3_code &&
                user?.data?.attributes?.citizenship?.citizenship_type
                    ?.citizenship_type_name === 'Dvojno'
                    ? 'Da'
                    : 'Ne',
        },
        {
            label: 'Pol',
            name: user?.data?.attributes?.gender
                ? user?.data?.attributes?.gender?.gender_name
                : false,
        },
        {
            label: 'JMBG',
            name: user?.data?.attributes?.person_info?.jmbg
                ? user?.data?.attributes?.person_info?.jmbg
                : false,
        },
        {
            label: 'Telefon',
            name: user?.data?.attributes?.person_info?.phone
                ? user?.data?.attributes?.person_info?.phone
                : false,
        },
        {
            label: 'Otac',
            name: user?.data?.attributes?.fathers_name
                ? user?.data?.attributes?.fathers_name
                : false,
        },
        {
            label: 'Majka',
            name: user?.data?.attributes?.mothers_name
                ? user?.data?.attributes?.mothers_name
                : false,
        },
        {
            label: 'Ruka kojom igra',
            name: user?.data?.attributes?.playing_hand
                ? user?.data?.attributes?.playing_hand?.playing_hand_name
                : false,
        },
        {
            label: 'Visina',
            name: user?.data?.attributes?.height
                ? user?.data?.attributes?.height
                : false,
        },
        {
            label: 'Težina',
            name: user?.data?.attributes?.weight
                ? user?.data?.attributes?.weight
                : false,
        },
        {
            label: 'Datum poslednje registracije',
            name: lastMembership
                ? formatDate(lastMembership?.attributes?.date_of_payment)
                : false,
        },
    ]

    const getCoachProfile = async () => {
        if (user?.data?.attributes?.coach_uuid) {
            const coach = await getSingleCoach(
                user?.data?.attributes?.coach_uuid
            )

            if (coach) {
                setCoachProfile(coach)
            }
        }
    }

    const handlePlayerStatistics = async () => {
        const playerStatisticData = await getPlayerStatistisc(
            user?.data?.attributes?.uuid
        )
        if (!playerStatisticData) return

        setCompetitorStatistics({
            total_matches: playerStatisticData?.total_matches || 0,
            result: [
                {
                    name: `Pobeda (${
                        playerStatisticData?.number_of_wins || '0'
                    })`,
                    value: playerStatisticData?.number_of_wins || '0',
                },
                {
                    name: `Poraza (${
                        playerStatisticData?.number_of_defeats || '0'
                    })`,
                    value: playerStatisticData?.number_of_defeats || '0',
                },
            ],
        })
    }

    // Calculate 15 days or less until the end of the medical
    const endDateArray = user?.data?.attributes?.medical_history?.map(
        (value) => value.end_date
    )
    const lastEndDate =
        endDateArray && endDateArray.length > 0
            ? endDateArray[endDateArray.length - 1]
            : null

    const today = new Date()
    const endDate = lastEndDate ? new Date(lastEndDate) : null

    const differenceInDays = endDate
        ? Math.floor((endDate - today) / (1000 * 60 * 60 * 24))
        : null

    const handleLastMembership = async () => {
        const lastMembership = await getLastMembership(
            user?.data?.attributes?.uuid
        )

        if (lastMembership) {
            setLastMembership(lastMembership[0])
        }
    }

    const handleLastMedicalCertificate = async () => {
        const lastMedicalCertificate = await getLastMedicalCerificate(
            user?.data?.attributes?.uuid
        )

        if (lastMedicalCertificate) {
            setLastMedicalCertificate(lastMedicalCertificate[0])
        }
    }

    const handlePlayersHistory = async () => {
        const playersClubHistoryData = await getPlayersClubHistory(
            user?.data?.attributes?.uuid
        )

        if (playersClubHistoryData) {
            const playersClubs = await handlePlayersClubsHistory(
                playersClubHistoryData
            )
            if (playersClubs) {
                setPlayersClubHistory(playersClubs)
            }
        }
    }

    const handlePlayersClubsHistory = async (playersClubs) => {
        const clubs = playersClubs?.map(async (clubItem) => {
            const obj = await getSingleClub(clubItem?.attributes?.club_uuid)
            return { ...clubItem, club: { ...obj } }
        })
        const clubsObjects = await Promise.all(clubs)
        return clubsObjects
    }

    const handlePlayersClub = async () => {
        const playersClubData = await getPlayersClub(
            user?.data?.attributes?.uuid
        )

        if (playersClubData) {
            const club = await getSingleClub(
                playersClubData?.attributes?.club_uuid
            )
            if (club) {
                setPlayersClub(club)
            }
        }
    }

    const handlePlayersClubLoan = async () => {
        const playersClubLoanData = await getPlayersClubLoan(
            user?.data?.attributes?.uuid
        )

        if (playersClubLoanData) {
            const club = await getSingleClub(
                playersClubLoanData?.attributes?.club_uuid
            )
            if (club) {
                setPlayersClubLoan({
                    ...playersClubLoanData,
                    club,
                })
            }
        }
    }

    const handlePlayersRankingLists = async () => {
        if (!user?.data?.attributes?.uuid) return
        const playersRankingListsData = await getPlayersRankingLists(
            user?.data?.attributes?.uuid
        )

        if (!playersRankingListsData) return

        const updatedRankingLists = await Promise.all(
            playersRankingListsData.map(async (rankingList) => {
                const singleRankingList = await getSingleCompetitorsRankingList(
                    rankingList?.attributes?.ranking_list_uuid
                )

                return {
                    ...rankingList,
                    attributes: {
                        ...rankingList?.attributes,
                        ranking_list: singleRankingList,
                    },
                }
            })
        )
        setPlayersRankingList(updatedRankingLists)
    }

    useEffect(() => {
        handleLastMembership()
        handleLastMedicalCertificate()
        handlePlayersClub()
        handlePlayersHistory()
        handlePlayersClubLoan()
        getCoachProfile()
        handlePlayerStatistics()
        handlePlayersRankingLists()
    }, [user])

    return (
        <Row md={12} className="d-flex mt-3 user-profile m-0">
            <Col className="px-0" xs={12} md>
                <Card className="competitor-flow-profile-card-wrapper">
                    <Card.Body className="competitor-flow-profile-card-body details-card">
                        <Row className="pb-5">
                            <Col className="d-flex justify-content-between details-header-wrapper align-items-center">
                                <h2>Detalji</h2>
                                <h5
                                    onClick={() => setIsEditing(true)}
                                    role="button"
                                    className="details-h5"
                                >
                                    Izmeni
                                </h5>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col>
                                <h3 className="mb-4">Konkurencija</h3>
                                <Col className="ps-3 details-single-info">
                                    <p className="data-info">
                                        {calculateCategory(
                                            user?.data?.attributes?.person_info
                                                ?.birthdate
                                        )}
                                    </p>

                                    <p className="small-text title">
                                        Konkurencija
                                    </p>
                                </Col>

                                {calculateSubcategory(
                                    user?.data?.attributes?.person_info
                                        ?.birthdate
                                ) ? (
                                    <Col className="ps-3 details-single-info">
                                        <p className="data-info">
                                            {calculateSubcategory(
                                                user?.data?.attributes
                                                    ?.person_info?.birthdate
                                            )}
                                        </p>

                                        <p className="small-text title">
                                            Podkategorija konkurencije
                                        </p>
                                    </Col>
                                ) : null}
                            </Col>
                        </Row>
                        <Row className="pb-4">
                            <Col className="d-flex justify-content-between details-header-wrapper align-items-center">
                                <h3>Osnovni podaci</h3>
                            </Col>
                        </Row>

                        <Row className="pb-4">
                            <Col>
                                {userBasicInfo.map(
                                    ({ label, name }, index) =>
                                        name && (
                                            <Col
                                                className="ps-3 details-single-info"
                                                key={`${name}-${index}`}
                                            >
                                                <p className="data-info">
                                                    {name}
                                                </p>
                                                <p className="small-text title">
                                                    {label}
                                                </p>
                                            </Col>
                                        )
                                )}
                            </Col>
                        </Row>
                        <Row className="pb-4">
                            <Col className="d-flex justify-content-between details-header-wrapper align-items-center">
                                <h3>Detalji</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {userDetails.map(
                                    ({ label, name }, index) =>
                                        name && (
                                            <Col
                                                className="ps-3 details-single-info"
                                                key={`${name}-${index}`}
                                            >
                                                <p className="data-info">
                                                    {name}
                                                </p>

                                                <p className="small-text title">
                                                    {label}
                                                </p>
                                            </Col>
                                        )
                                )}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="px-0" xs={12} md>
                <Card className="competitor-flow-profile-card-wrapper mb-2 mb-md-4">
                    <Card.Body className="competitor-flow-profile-card-body">
                        <Row className="pb-4">
                            <Col>
                                <h2>Pozicija na rang listama</h2>
                            </Col>
                        </Row>

                        {playersRankingList?.length > 0 ||
                        user?.data?.attributes.eta_14_rank ||
                        user?.data?.attributes?.eta_16_rank ||
                        user?.data?.attributes?.atp_wta_rank ||
                        user?.data?.attributes?.itf_rank ? (
                            <>
                                {playersRankingList?.map(
                                    (rankingList, index) => (
                                        <Row key={index} className="mb-2">
                                            <Col>
                                                <span className="d-flex align-items-center">
                                                    <p className="me-2">
                                                        {
                                                            rankingList
                                                                ?.attributes
                                                                ?.ranking_list
                                                                ?.attributes
                                                                ?.name
                                                        }
                                                        :{' '}
                                                    </p>
                                                    <h5 className="card-title-color">
                                                        {rankingList?.attributes
                                                            ?.ranking_number ||
                                                            'Nema rang'}
                                                    </h5>
                                                </span>
                                            </Col>
                                        </Row>
                                    )
                                )}

                                {user?.data?.attributes?.eta_14_rank && (
                                    <Row className="mb-2">
                                        <Col>
                                            <span className="d-flex align-items-center">
                                                <p className="me-2">ETA 14: </p>
                                                <h5 className="card-title-color">
                                                    {user?.data?.attributes
                                                        ?.eta_14_rank ||
                                                        'Nema rang'}
                                                </h5>
                                            </span>
                                        </Col>
                                    </Row>
                                )}

                                {user?.data?.attributes?.eta_16_rank && (
                                    <Row className="mb-2">
                                        <Col>
                                            <span className="d-flex align-items-center">
                                                <p className="me-2">ETA 16: </p>
                                                <h5 className="card-title-color">
                                                    {user?.data?.attributes
                                                        ?.eta_16_rank ||
                                                        'Nema rang'}
                                                </h5>
                                            </span>
                                        </Col>
                                    </Row>
                                )}
                                {user?.data?.attributes?.atp_wta_rank && (
                                    <Row className="mb-2">
                                        <Col>
                                            <span className="d-flex align-items-center">
                                                <p className="me-2">
                                                    ATP/WTA:{' '}
                                                </p>
                                                <h5 className="card-title-color">
                                                    {user?.data?.attributes
                                                        ?.atp_wta_rank ||
                                                        'Nema rang'}
                                                </h5>
                                            </span>
                                        </Col>
                                    </Row>
                                )}
                                {user?.data?.attributes?.itf_rank && (
                                    <Row className="mb-2">
                                        <Col>
                                            <span className="d-flex align-items-center">
                                                <p className="me-2">ITF: </p>
                                                <h5 className="card-title-color">
                                                    {user?.data?.attributes
                                                        ?.itf_rank ||
                                                        'Nema rang'}
                                                </h5>
                                            </span>
                                        </Col>
                                    </Row>
                                )}
                            </>
                        ) : (
                            <p>Takmičar nije rangiran</p>
                        )}
                    </Card.Body>
                </Card>

                <Card className="competitor-flow-profile-card-wrapper">
                    <Card.Body className="competitor-flow-profile-card-body">
                        <Row className="pb-5">
                            <Col>
                                <h2>Skor takmičara</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex flex-column competition">
                                <Col className="d-flex flex-column">
                                    <Col className="position-relative align-self-center">
                                        <PieChart width={292} height={292}>
                                            <Pie
                                                data={
                                                    competitorStatistics?.result
                                                }
                                                innerRadius={131}
                                                outerRadius={146}
                                                paddingAngle={2}
                                                dataKey="value"
                                                startAngle={-340}
                                            >
                                                {competitorStatistics?.result.map(
                                                    (entry, index) => (
                                                        <Cell
                                                            key={`cell-${index}`}
                                                            fill={
                                                                COLORS[
                                                                    index %
                                                                        COLORS.length
                                                                ]
                                                            }
                                                        />
                                                    )
                                                )}
                                            </Pie>
                                        </PieChart>
                                        <div className="total-matches">
                                            <p>Ukupno mečeva</p>
                                            <h1>
                                                {competitorStatistics?.total_matches ||
                                                    '0'}
                                            </h1>
                                        </div>
                                    </Col>
                                    <Col className="matches">
                                        {competitorStatistics?.result?.map(
                                            ({ name }) => (
                                                <div
                                                    className="match d-flex"
                                                    key={name}
                                                >
                                                    <div
                                                        className={
                                                            name.includes(
                                                                'Pobeda'
                                                            )
                                                                ? 'green'
                                                                : 'red'
                                                        }
                                                    ></div>
                                                    <p>{name}</p>
                                                </div>
                                            )
                                        )}
                                    </Col>
                                </Col>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="px-0" xs={12} md>
                <Card className="competitor-flow-profile-card-wrapper mb-4">
                    <Card.Body className="competitor-flow-profile-card-body">
                        <Row className="pb-5">
                            <Col>
                                <h2>Klub</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                xs={12}
                                className="club d-flex flex-column align-items-center text-center"
                            >
                                {playersClub?.id ? (
                                    <>
                                        <img
                                            className="mb-3 club-image"
                                            src={
                                                playersClub?.attributes?.logo
                                                    ?.data
                                                    ? formatImgUrl(
                                                          playersClub
                                                              ?.attributes?.logo
                                                              ?.data?.attributes
                                                              ?.url
                                                      )
                                                    : generateAvatar(
                                                          playersClub
                                                              ?.attributes?.name
                                                      )
                                            }
                                            alt={`Logo kluba`}
                                        />
                                        <Row>
                                            <Col>
                                                <h3 className="mb-2">
                                                    {
                                                        playersClub?.attributes
                                                            ?.name
                                                    }
                                                </h3>
                                                <p className="link">
                                                    {
                                                        playersClub?.attributes
                                                            ?.website_url
                                                    }
                                                </p>
                                            </Col>
                                        </Row>
                                    </>
                                ) : (
                                    <p>Nema klub</p>
                                )}
                            </Col>

                            <Col xs={12} className="my-2">
                                {new Date(
                                    playersClubLoan?.attributes?.departure_date
                                ) >= new Date() && (
                                    <p>
                                        <span className="muted-text">
                                            Pozajmljen u{' '}
                                        </span>

                                        <span className="link">
                                            <Link
                                                to={`/klubovi/${playersClubLoan?.club?.attributes?.uuid}`}
                                                tabIndex="1"
                                            >
                                                {
                                                    playersClubLoan?.club
                                                        ?.attributes?.name
                                                }
                                            </Link>
                                            .{' '}
                                        </span>
                                        <span className="muted-text">
                                            {`Od ${formatDate(
                                                playersClubLoan?.attributes
                                                    ?.joining_date
                                            )}`}{' '}
                                            do{' '}
                                            {formatDate(
                                                playersClubLoan?.attributes
                                                    ?.departure_date
                                            )}
                                        </span>
                                    </p>
                                )}
                            </Col>
                            <Col xs={12}>
                                <Row className="mb-3">
                                    <Col>
                                        <h2 className="mt-5">
                                            Prethodni klubovi
                                        </h2>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        {playersClubHistory?.length > 0 ? (
                                            playersClubHistory?.map(
                                                (clubData, index) => (
                                                    <p
                                                        key={index}
                                                        className="mb-2"
                                                    >
                                                        <span className="link">
                                                            <Link
                                                                to={`/klubovi/${clubData?.club?.attributes?.uuid}`}
                                                                tabIndex="1"
                                                            >
                                                                {
                                                                    clubData
                                                                        ?.club
                                                                        ?.attributes
                                                                        ?.name
                                                                }
                                                            </Link>
                                                        </span>
                                                        <span className="muted-text">
                                                            : Od{' '}
                                                            {formatDate(
                                                                clubData
                                                                    ?.attributes
                                                                    ?.joining_date
                                                            )}
                                                        </span>
                                                        {clubData?.attributes
                                                            ?.departure_date && (
                                                            <span className="muted-text">
                                                                {' '}
                                                                do{' '}
                                                                {formatDate(
                                                                    clubData
                                                                        ?.attributes
                                                                        ?.departure_date
                                                                )}
                                                            </span>
                                                        )}

                                                        {clubData?.attributes
                                                            ?.is_loan && (
                                                            <span>
                                                                {' '}
                                                                (Pozajmica)
                                                            </span>
                                                        )}
                                                    </p>
                                                )
                                            )
                                        ) : (
                                            <p>Nema prethodnih klubova</p>
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Body>
                    {playersClub?.id && (
                        <Card.Footer className="competitor-flow-profile-card-footer">
                            <Row>
                                <Col className="d-flex align-items-center justify-content-end">
                                    <Link
                                        to={`/klubovi/${playersClub?.attributes?.uuid}`}
                                    >
                                        <Button
                                            type="text"
                                            endIcon={<ArrowRight />}
                                        >
                                            Detaljnije
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>
                        </Card.Footer>
                    )}
                </Card>
                <Card className="competitor-flow-profile-card-wrapper mb-4">
                    <Card.Body className="competitor-flow-profile-card-body membership-card">
                        <Row className="pb-5">
                            <Col className="d-flex align-items-center">
                                {differenceInDays !== null &&
                                differenceInDays <= 15 ? (
                                    <h2 className="d-flex align-center">
                                        Lekarski pregled{' '}
                                        <span className="text-danger ms-2">
                                            !
                                        </span>
                                    </h2>
                                ) : (
                                    <h2>Lekarski pregled</h2>
                                )}{' '}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="membership-view">
                                <Col
                                    className={`d-flex align-items-center justify-content-between single-membership`}
                                >
                                    <p className={`membership-year`}>
                                        {lastMedicalCertificate
                                            ? `${formatDate(
                                                  lastMedicalCertificate
                                                      ?.attributes?.start_date
                                              )} - ${formatDate(
                                                  lastMedicalCertificate
                                                      ?.attributes?.end_date
                                              )}`
                                            : 'Nema lekarsko uverenje.'}
                                    </p>
                                </Col>
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer className="competitor-flow-profile-card-footer">
                        <Row>
                            <Col className="d-flex align-items-center justify-content-end">
                                <Button
                                    type="text"
                                    endIcon={<ArrowRight />}
                                    onClick={() => setShowMedicalHistory(true)}
                                >
                                    Detaljnije
                                </Button>
                            </Col>
                        </Row>
                    </Card.Footer>
                </Card>

                <Card className="competitor-flow-profile-card-wrapper">
                    <Card.Body className="competitor-flow-profile-card-body membership-card">
                        <Row className="pb-5">
                            <Col className="d-flex align-items-center">
                                <h2>Članarina</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="membership-view">
                                <Col
                                    className={`d-flex align-items-center justify-content-center single-membership`}
                                >
                                    <Col className="d-flex justify-content-center">
                                        <p className={`membership-year`}>
                                            {lastMembership
                                                ? formatDate(
                                                      lastMembership?.attributes
                                                          ?.date_of_payment
                                                  )
                                                : 'Nema članarina.'}
                                        </p>
                                    </Col>
                                </Col>
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer className="competitor-flow-profile-card-footer">
                        <Row>
                            <Col className="d-flex align-items-center justify-content-end">
                                <Button
                                    type="text"
                                    endIcon={<ArrowRight />}
                                    onClick={() => setShowMembership(true)}
                                >
                                    Detaljnije
                                </Button>
                            </Col>
                        </Row>
                    </Card.Footer>
                </Card>
            </Col>
        </Row>
    )
}

export default Profile
