import { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import RadioButton from '../../../../../components/RadioButton'
import TextField from '../../../../../components/TextField'
import UploadImage from '../../../../../components/UploadImage'
import { getCodebook } from '../../../../../lib/api'
import { addPhoto, checkFileSize } from '../../../../../lib/helpers'

const FirstStep = ({
    setProgress,
    formData,
    setFormData,
    setRefereeImg,
    refereePreviewImg,
    setRefereePreviewImg,
}) => {
    useEffect(() => {
        setProgress(25)
        handleCodebooks()
    }, [])

    //* Codebooks
    const [genders, setGenders] = useState([])

    const handleCodebooks = async () => {
        await getCodebook('codebooks.gender', setGenders)
    }

    return (
        <Row className="first-step-wrapper">
            <Col md={12} className="d-flex justify-content-center">
                <UploadImage
                    htmlFor="refereeImg"
                    textBelow={'Unesi sliku sudije /.jpg 2MB'}
                    previewData={refereePreviewImg}
                />

                <input
                    type="file"
                    id="refereeImg"
                    name="refereeImg"
                    className="d-none invisible"
                    onChange={(e) =>
                        addPhoto(
                            e,
                            setRefereeImg,
                            setRefereePreviewImg,
                            checkFileSize
                        )
                    }
                    accept="image/jpg, image/jpeg, image/png"
                />
            </Col>

            <Col md={12} className="mt-3">
                <TextField
                    id="name"
                    name="name"
                    type="text"
                    value={formData?.person_info?.given_name || ''}
                    onChange={(e) => {
                        setFormData((prevState) => ({
                            ...prevState,
                            person_info: {
                                ...prevState.person_info,
                                given_name: e.target.value,
                            },
                        }))
                    }}
                    placeholder="Vaše ime"
                    required
                    label="Ime"
                />
            </Col>
            <Col md={12} className="mt-3">
                <TextField
                    id="surname"
                    name="surname"
                    type="text"
                    value={formData?.person_info?.family_name || ''}
                    onChange={(e) => {
                        setFormData((prevState) => ({
                            ...prevState,
                            person_info: {
                                ...prevState.person_info,
                                family_name: e.target.value,
                            },
                        }))
                    }}
                    required
                    placeholder="Vaše prezime"
                    label="Prezime"
                />
            </Col>
            <Col md={12} className="mt-3">
                <TextField
                    id="id"
                    name="email"
                    type="email"
                    value={formData?.person_info?.email || ''}
                    onChange={(e) => {
                        setFormData((prevState) => ({
                            ...prevState,
                            person_info: {
                                ...prevState.person_info,
                                email: e.target.value,
                            },
                        }))
                    }}
                    label="Email"
                    placeholder="Vaš email"
                    required
                />
            </Col>
            <Col md={12} className="mt-3">
                <RadioButton
                    name="Pol"
                    checkedValue={formData?.gender?.gender_name || ''}
                    radioValues={genders}
                    onChange={(e) => {
                        setFormData((prevState) => ({
                            ...prevState,
                            gender: {
                                ...prevState.gender,
                                gender_name: e.target.value,
                            },
                        }))
                    }}
                />
            </Col>
        </Row>
    )
}

export default FirstStep
