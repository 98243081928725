import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Button from '../../../../../../components/Button'
import NoDataForView from '../../../../../../components/NoDataForView'
import RefereeCard from '../../../../../../components/RefereeCard'
import {
    getSingleReferee,
    getTournamentsReferees,
    isRefereeTournamentAdmin,
} from '../../../../../../lib/api'
import { useAppContext } from '../../../../../../lib/contextLib'
import AddTournamentRefereeModal from './addTournamentRefereeModal'
import RemoveTournamentRefereeModal from './removeTournamentRefereeModal'
import './styles.scss'

function RefereesTab({ tournament, activeTab, referees, setReferees }) {
    const { user, setShowCustomToast } = useAppContext()
    const [isRefereeAdmin, setIsRefereeAdmin] = useState(false)
    const [showAddRefereeModal, setShowAddRefereeModal] = useState(false)
    const [showRemoveRefereeModal, setShowRemoveRefereeModal] = useState(false)

    const checkIsRefereeTournamentAdmin = async (tournamentUuid) => {
        const isAdmin = await isRefereeTournamentAdmin(
            user?.data?.attributes?.uuid,
            tournamentUuid
        )

        if (isAdmin?.length > 0) {
            setIsRefereeAdmin(true)
        }
    }

    const fetchTournamentReferees = async (tournamentUuid) => {
        const refereesResponse = await getTournamentsReferees(tournamentUuid)

        if (refereesResponse && refereesResponse?.length > 0) {
            let refereePromises = refereesResponse?.map(
                async (singleReferee) => {
                    let refereeData = await getSingleReferee(
                        singleReferee?.attributes?.referee_uuid
                    )

                    if (refereeData) {
                        return {
                            ...singleReferee,
                            attributes: {
                                ...singleReferee.attributes,
                                refereeData,
                            },
                        }
                    }
                }
            )

            let updatedRefereesResponse = await Promise.all(refereePromises)
            setReferees(updatedRefereesResponse)
        } else {
            setReferees([])
        }
    }

    const refreshData = async () => {
        await checkIsRefereeTournamentAdmin(tournament?.attributes?.uuid)
        await fetchTournamentReferees(tournament?.attributes?.uuid)
    }

    useEffect(() => {
        if (activeTab === 'sudjenje') {
            checkIsRefereeTournamentAdmin(tournament?.attributes?.uuid)
            fetchTournamentReferees(tournament?.attributes?.uuid)
        }
    }, [tournament, activeTab, user])

    return (
        <>
            {referees?.length > 0 ? (
                <Row className="d-flex justify-content-center referees-tab-wrapper">
                    <Col className="d-flex flex-column">
                        <Row className="mb-auto">
                            <Col
                                md={12}
                                className="d-flex flex-column flex-md-row flex-wrap justify-content-center py-3 referees-cards-wrapper"
                            >
                                {referees?.map((referee, index) => (
                                    <RefereeCard
                                        key={index}
                                        referee={
                                            referee?.attributes?.refereeData
                                        }
                                        type={
                                            referee?.attributes?.referee_type
                                                ?.referee_type_name
                                        }
                                        showCloseButton={
                                            (user?.role?.type === 'referee' &&
                                                isRefereeAdmin &&
                                                !tournament?.attributes
                                                    ?.is_finished) ||
                                            (user?.role?.type === 'admin' &&
                                                !tournament?.attributes
                                                    ?.is_finished)
                                                ? true
                                                : false
                                        }
                                        onCloseClick={() =>
                                            setShowRemoveRefereeModal(
                                                referee?.attributes?.uuid
                                            )
                                        }
                                    />
                                ))}
                            </Col>
                        </Row>
                        <Row className="py-3">
                            <Col
                                md={12}
                                className="d-flex flex-column flex-md-row justify-content-center"
                            >
                                {(user?.role?.type === 'referee' &&
                                    isRefereeAdmin &&
                                    !tournament?.attributes?.is_finished) ||
                                (user?.role?.type === 'admin' &&
                                    !tournament?.attributes?.is_finished) ? (
                                    <Button
                                        onClick={() =>
                                            setShowAddRefereeModal(true)
                                        }
                                    >
                                        Dodaj sudije
                                    </Button>
                                ) : null}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            ) : (
                <Row className="mt-3">
                    <Col>
                        <NoDataForView
                            title="Sudjenje"
                            description="Još uvek nisu dezignirane sudije takmičenja."
                        />
                    </Col>
                </Row>
            )}
            <AddTournamentRefereeModal
                show={showAddRefereeModal}
                onHide={() => setShowAddRefereeModal(false)}
                tournament={tournament}
                refreshData={refreshData}
                referees={referees}
            />
            <RemoveTournamentRefereeModal
                show={showRemoveRefereeModal}
                onHide={() => setShowRemoveRefereeModal(false)}
                refreshData={refreshData}
            />
        </>
    )
}

export default RefereesTab
