import PropTypes from 'prop-types'
import { Table } from 'react-bootstrap'

const AssemblySettingsTable = ({ data, title_from_to }) => {
    return (
        <div className="table-clubs-ranking-wrapper">
            {data?.length > 0 ? (
                <Table className="mb-0 settings-table">
                    <thead>
                        <tr>
                            <th>
                                <h5>
                                    Broj {title_from_to?.toLowerCase()} (od)
                                </h5>
                            </th>
                            <th>
                                <h5>
                                    Broj {title_from_to?.toLowerCase()} (do)
                                </h5>
                            </th>
                            <th>
                                <h5>Glasova</h5>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {data?.map((result, index) => (
                            <tr key={index} className="setting-table-row">
                                <td>
                                    <p className="align-middle">
                                        {result?.number_from}
                                    </p>
                                </td>
                                <td
                                    style={{
                                        background:
                                            result?.number_to !== null
                                                ? ''
                                                : '#e5eaef',
                                    }}
                                >
                                    <p className="align-middle">
                                        {result?.number_to}
                                    </p>
                                </td>
                                <td>
                                    <p className="align-middle">
                                        {result?.votes}
                                    </p>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            ) : null}
        </div>
    )
}

AssemblySettingsTable.propTypes = {
    title_from_to: PropTypes.string.isRequired,
}

export default AssemblySettingsTable
