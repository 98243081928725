import React from 'react'
import { pillStatusHandler } from '../../utils/pillHandler'

function StatusField({ clubStatus, mobile = false }) {
    return !mobile ? (
        <td style={{ minWidth: '170px' }}>{pillStatusHandler(clubStatus)}</td>
    ) : (
        pillStatusHandler(clubStatus)
    )
}

export default StatusField
