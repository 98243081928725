import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Button from '../Button'

import { getReferees, updateTournament } from '../../lib/api'
import { useAppContext } from '../../lib/contextLib'
import {
    isArray,
    isArrayEmpty,
    isObject,
    isObjectEmpty,
    sleep,
} from '../../lib/helpers'
import AsyncSelect from '../AsyncSelect'
import './styles.scss'

const AddAdminReferee = (edit = false) => {
    const navigate = useNavigate()
    const { id } = useParams()
    const { state } = useLocation()

    let state_main_referee = state?.main_referee_id ?? {}
    let state_other_referees = state?.other_referees ?? []

    const { handleInitCompetitionsData } = useAppContext()

    const [mainReferee, setMainReferee] = useState([])
    const [otherReferees, setOtherReferees] = useState([])
    const [disabled, setDisabled] = useState(true)
    const [mainRefereeChanged, setMainRefereeChanged] = useState(0)
    const [otherRefereeChanged, setOtherRefereeChanged] = useState(0)

    const loadMainRefereeOptions = async (
        searchValue,
        loadedOptions,
        { page }
    ) => {
        isObjectEmpty(state_main_referee) &&
            isArrayEmpty(mainReferee) &&
            (await sleep(1000))

        let name = searchValue.split(' ')[0]
        let surname = searchValue.split(' ')[1]
        let license = searchValue.split(' ')[2]

        let search_params = {
            name,
            surname,
            license,
        }

        let { allReferees, pgCount } = await getReferees(
            ['name', 'surname'],
            search_params,
            {},
            page
        )
        if (allReferees) {
            let list = []
            let otherRefereesList = []
            let mainRefereeInfo = []
            let mainRefereeOptions = []
            allReferees.map((referee) => {
                let refereeDetails = {
                    value: `${referee?.attributes?.name} ${referee?.attributes?.surname}`,
                    id: referee?.id,
                }
                list.push(refereeDetails)

                if (
                    !isObjectEmpty(state_main_referee) &&
                    referee?.id === state_main_referee &&
                    isArrayEmpty(mainReferee)
                ) {
                    mainRefereeInfo = {
                        value: `${referee?.attributes?.name} ${referee?.attributes?.surname}`,
                        id: referee?.id,
                    }
                }

                if (
                    !isArrayEmpty(state_other_referees) &&
                    state_other_referees.includes(referee?.id) &&
                    isArrayEmpty(otherReferees)
                ) {
                    let singleReferee = {
                        value: `${referee?.attributes?.name} ${referee?.attributes?.surname}`,
                        id: referee?.id,
                    }
                    otherRefereesList.push(singleReferee)
                }
            })

            if (isArray(mainReferee)) {
                isArray(mainRefereeInfo)
                    ? setMainReferee(mainReferee)
                    : setMainReferee(mainRefereeInfo)
            }

            mainRefereeOptions = list.filter((referee) =>
                otherReferees?.length > 0
                    ? otherReferees.every(
                          (otherRef) => otherRef.id !== referee.id
                      )
                    : otherRefereesList.every(
                          (otherRef) => otherRef.id !== referee.id
                      )
            )

            return {
                options: mainRefereeOptions,
                hasMore: page >= pgCount ? false : true,
                additional: {
                    page: page + 1,
                },
            }
        }
    }

    const loadOtherRefereesOptions = async (
        searchValue,
        loadedOptions,
        { page }
    ) => {
        isArrayEmpty(state_other_referees) &&
            isArrayEmpty(otherReferees) &&
            (await sleep(1000))

        let name = searchValue.split(' ')[0]
        let surname = searchValue.split(' ')[1]
        let license = searchValue.split(' ')[2]

        let search_params = {
            name,
            surname,
            license,
        }

        let { allReferees, pgCount } = await getReferees(
            ['name', 'surname'],
            search_params,
            {},
            page
        )

        if (allReferees) {
            let list = []
            let otherRefereesList = []
            let mainRefereeInfo = []
            let otherRefereesOptions = []
            allReferees.map((referee) => {
                let refereeDetails = {
                    value: `${referee?.attributes?.name} ${referee?.attributes?.surname}`,
                    id: referee?.id,
                }
                list.push(refereeDetails)

                if (
                    !isObjectEmpty(state_main_referee) &&
                    referee?.id === state_main_referee &&
                    isArrayEmpty(mainReferee)
                ) {
                    mainRefereeInfo = {
                        value: `${referee?.attributes?.name} ${referee?.attributes?.surname}`,
                        id: referee?.id,
                    }
                }

                if (
                    !isArrayEmpty(state_other_referees) &&
                    state_other_referees.includes(referee?.id) &&
                    isArrayEmpty(otherReferees)
                ) {
                    let singleReferee = {
                        value: `${referee?.attributes?.name} ${referee?.attributes?.surname}`,
                        id: referee?.id,
                    }
                    otherRefereesList.push(singleReferee)
                }
            })

            isArrayEmpty(otherReferees) && setOtherReferees(otherRefereesList)

            otherRefereesOptions = list.filter((referee) =>
                isArrayEmpty(mainReferee) && isObject(mainRefereeInfo)
                    ? referee?.id !== mainRefereeInfo?.id
                    : referee?.id !== mainReferee?.id
            )

            return {
                options: otherRefereesOptions,
                hasMore: page >= pgCount ? false : true,
                additional: {
                    page: page + 1,
                },
            }
        }
    }

    // Handling main referee and detect changes
    const handleMainReferee = (e) => {
        setMainReferee(e)
        setMainRefereeChanged((prev) => prev + 1)
    }

    //Handle other referees
    const handleOtherReferees = (e) => {
        setOtherReferees(e)
        setOtherRefereeChanged((prev) => prev + 1)
    }

    //Handle updating tournament referees
    const handleTournamentReferees = async () => {
        let refereeData = {
            main_referee_id: mainReferee.id,
            other_referees: otherReferees.map((referee) => referee?.id),
            designated: true,
        }
        let data = await updateTournament(id, refereeData)

        if (data) {
            handleInitCompetitionsData()
            navigate('/administrator-sudjenja/takmicenja-iz-kalendara-TSS')
        }
    }

    useEffect(() => {
        if (
            !isArray(mainReferee) &&
            !isObjectEmpty(mainReferee) 
        ) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [mainReferee])

    return (
        <Row className="add-admin-referee-wrapper">
            <Col md={12}>
                <Row className="mt-2">
                    <Col
                        md={12}
                        className="d-flex align-items-center justify-content-center"
                    >
                        <h3>Dodeli sudiju</h3>
                    </Col>
                </Row>
                <Row className="align-items-center justify-content-center">
                    <Col md={5} className="mt-4">
                        <AsyncSelect
                            loadOptions={loadMainRefereeOptions}
                            isMulti={false}
                            onChange={handleMainReferee}
                            label={'Glavni sudija'}
                            value={mainReferee}
                            placeholder={'Pretraži i izaberi...'}
                            noOptionsMessage={'Nema rezultata'}
                            closeMenuOnSelect={true}
                            cacheUniqs={[otherRefereeChanged]}
                        />
                    </Col>
                </Row>
                <Row className="align-items-center justify-content-center">
                    <Col md={5} className="mt-3">
                        <AsyncSelect
                            loadOptions={loadOtherRefereesOptions}
                            onChange={handleOtherReferees}
                            label={'Ostale sudije'}
                            value={otherReferees}
                            placeholder={'Pretraži i izaberi...'}
                            noOptionsMessage={'Nema rezultata'}
                            closeMenuOnSelect={false}
                            cacheUniqs={[mainRefereeChanged]}
                        />
                    </Col>
                </Row>
                <Row className="d-flex align-items-center justify-content-center mt-4">
                    <Col
                        md={5}
                        className="mb-2 mb-md-0 d-flex justify-content-end"
                    >
                        <Link
                            to={'/treneri'}
                            className="link-text"
                            tabIndex="1"
                        >
                            <Button type="text" onClick={() => navigate(-1)}>
                                {'Odustani'}
                            </Button>
                        </Link>
                        <Button
                            className="ms-1"
                            onClick={handleTournamentReferees}
                            disabled={disabled}
                        >
                            {'Dodeli'}
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default AddAdminReferee
