import { Form } from 'react-bootstrap'

import './styles.scss'

const RadioButton = ({
    name,
    onChange,
    className,
    required,
    radioValues,
    radioHiddenValues,
    showLabel = true,
    alignStart,
    flexColumn,
    checkedValue,
    value,
    label,
    otherValuesDisabled,
    onClick,
    ...inputProps
}) => {
    return (
        <div className={`${className}`}>
            <div className="input-wrapper">
                {showLabel && (
                    <label className="mb-2 d-flex">
                        <h5 className="me-1">
                            {label ? label : name}
                            {required ? (
                                <span className="ms-1 required-asterisk">
                                    *
                                </span>
                            ) : null}
                        </h5>
                    </label>
                )}
            </div>

            <div
                className={`${flexColumn ? 'd-flex flex-column' : 'd-flex'} ${
                    alignStart ? 'align-items-start' : 'align-items-center'
                }`}
            >
                {radioValues?.map((value, index) => (
                    <div key={index} className="radio-wrapper me-3">
                        <Form.Check
                            inline
                            label={value}
                            value={
                                radioHiddenValues
                                    ? radioHiddenValues[index]
                                    : value
                            }
                            type="radio"
                            name={name}
                            checked={checkedValue === value}
                            onClick={onClick}
                            onChange={onChange}
                            disabled={
                                otherValuesDisabled && checkedValue !== value
                            }
                            {...inputProps}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default RadioButton
