import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { formatImgUrl } from '../../lib/helpers'
import { generateAvatar } from '../../utils/generateAvatar'
import { getClubsPlayers } from '../../lib/api'

const ClubRankingTableRow = ({ club, position }) => {
    const [playersNumber, setPlayersNumber] = useState(0)
    const handlePlayersNumber = async () => {
        const { total } = await getClubsPlayers(
            club?.club_data?.attributes?.uuid
        )
        if (!total) return
        setPlayersNumber(total)
    }

    useEffect(() => {
        handlePlayersNumber()
    }, [])
    return (
        <tr>
            <td className="link-align-end">
                <div className="d-flex align-items-center justify-content-start">
                    <p className="me-5">{position}.</p>
                    <img
                        src={
                            club?.club_data?.attributes?.logo?.data
                                ? formatImgUrl(
                                      club?.club_data?.attributes?.logo?.data
                                          ?.attributes?.formats?.thumbnail?.url
                                  )
                                : generateAvatar(
                                      club?.club_data?.attributes?.name
                                  )
                        }
                        className="me-2"
                        alt={`Logo kluba`}
                        width={'24px'}
                        height={'24px'}
                    />
                    <Link to={`/klubovi/${club?.club_data?.attributes?.uuid}`}>
                        <p className="link">
                            {club?.club_data?.attributes?.name}
                        </p>
                    </Link>
                </div>
            </td>
            <td>
                <p>{club?.attributes?.total_points}</p>
            </td>
            <td>
                <p>{playersNumber || 0}</p>
            </td>
        </tr>
    )
}

export default ClubRankingTableRow
