import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import Button from '../../components/Button'
import CardParticipantSection from './cardParticipant'
import CardResultSection from './cardResultSection'
import CreateMatchModal from './createMatchModal'
import ChangeMatchResultModal from './changeMatchResultModal'
import DeleteMatchModal from './deleteMatchModal'
import ClubsMatchesModal from './clubsMatchesModal'
import {
    getSingleClubMatch,
    getSingleClubWithQuery,
    getClubMatchParticipants,
} from '../../lib/api'
import './styles.scss'
import { useAppContext } from '../../lib/contextLib'

const TournamentClubMatchCard = ({
    tournament,
    matchDetails,
    className,
    onChange = false,
    isDeleteEnabled = true,
    onDelete,
    hideButtons = false,
    requireRoundNumber = true,
    createNextMatch = false,
}) => {
    const { user } = useAppContext()
    const [showCreateMatchModal, setShowCreateMatchModal] = useState(false)
    const [showChangeMatchResultModal, setShowChangeMatchResultModal] =
        useState(false)
    const [showDeleteMatchModal, setShowDeleteMatchModal] = useState(false)
    const [showMatchesModal, setShowMatchesModal] = useState(false)
    const [sideOneData, setSideOneData] = useState()
    const [sideTwoData, setSideTwoData] = useState()
    const [matchData, setMatchData] = useState()

    const fetchParticipantsAndClubs = async (side_uuid) => {
        if (side_uuid && side_uuid !== '-1') {
            const participant = await getClubMatchParticipants(side_uuid)
            const club = await getSingleClubWithQuery(
                participant?.attributes?.club_uuid,
                'fields[0]=name&fields[1]=short_name&fields[2]=uuid&populate[logo][populate]'
            )
            return { participant, club }
        } else {
            return { participant: null, club: null }
        }
    }

    const handleGetSingleMatch = async () => {
        const { round_number, tournament_uuid, match } = matchDetails || {}

        if (requireRoundNumber) {
            if (!round_number || !tournament_uuid) {
                return
            }
        } else {
            if (!tournament_uuid) {
                return
            }
        }

        try {
            const effectiveMatch = match?.attributes?.uuid
                ? match
                : await getSingleClubMatch(matchDetails)

            if (!effectiveMatch) {
                setSideOneData(null)
                setSideTwoData(null)
                setMatchData({ ...matchDetails })
                return
            }

            const {
                side_1_uuid,
                side_2_uuid,
                uuid,
                loser_participant_uuid,
                side_1_result,
                side_2_result,
                match_host_uuid,
            } = effectiveMatch.attributes

            const [sideOne, sideTwo] = await Promise.all([
                fetchParticipantsAndClubs(side_1_uuid),
                fetchParticipantsAndClubs(side_2_uuid),
            ])

            setSideOneData({
                participant_uuid: side_1_uuid,
                club: sideOne.club,
                result: side_1_result,
            })
            setSideTwoData({
                participant_uuid: side_2_uuid,
                club: sideTwo.club,
                result: side_2_result,
            })

            setMatchData({
                ...matchDetails,
                loser_participant_uuid,
                single_club_match_uuid: uuid,
                match_host_uuid,
            })
        } catch (error) {
            console.error('Greška:', error)
        }
    }

    useEffect(() => {
        handleGetSingleMatch()
    }, [matchDetails])
    return (
        <Row className={`tournament-club-match-card ${className}`}>
            <Col>
                <Row>
                    <Col>
                        <CardParticipantSection
                            participantData={sideOneData}
                            matchData={matchData || matchDetails}
                        />
                        <CardResultSection resultData={sideOneData?.result} />
                        <CardResultSection resultData={sideTwoData?.result} />
                        <CardParticipantSection
                            participantData={sideTwoData}
                            matchData={matchData || matchDetails}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col className="d-flex justify-content-center px-0">
                        {sideOneData && sideTwoData ? (
                            <Row className="px-0 w-100">
                                <Col className="d-flex justify-content-between px-0 w-100">
                                    {sideOneData?.participant_uuid &&
                                        sideTwoData?.participant_uuid &&
                                        sideOneData?.participant_uuid !==
                                            '-1' &&
                                        sideTwoData?.participant_uuid !==
                                            '-1' && (
                                            <Button
                                                type="text"
                                                className="w-100"
                                                onClick={() =>
                                                    setShowMatchesModal(true)
                                                }
                                            >
                                                Mečevi
                                            </Button>
                                        )}
                                    {!hideButtons && (
                                        <>
                                            {sideOneData?.participant_uuid &&
                                            sideTwoData?.participant_uuid &&
                                            sideOneData?.participant_uuid ? (
                                                sideOneData?.participant_uuid !==
                                                    '-1' &&
                                                sideTwoData?.participant_uuid !==
                                                    '-1' && (
                                                    <Button
                                                        type="text"
                                                        className="w-100"
                                                        onClick={() =>
                                                            setShowChangeMatchResultModal(
                                                                true
                                                            )
                                                        }
                                                        disabled={
                                                            user?.role?.type ===
                                                            'competitor'
                                                                ? true
                                                                : false
                                                        }
                                                    >
                                                        Rezultat
                                                    </Button>
                                                )
                                            ) : (
                                                <Button
                                                    type="text"
                                                    className="w-100"
                                                    onClick={() =>
                                                        setShowCreateMatchModal(
                                                            true
                                                        )
                                                    }
                                                    disabled={
                                                        user?.role?.type ===
                                                        'competitor'
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    Dodaj klubove
                                                </Button>
                                            )}

                                            {isDeleteEnabled && (
                                                <Button
                                                    type="text"
                                                    className="w-100"
                                                    onClick={() =>
                                                        setShowDeleteMatchModal(
                                                            true
                                                        )
                                                    }
                                                    disabled={
                                                        user?.role?.type ===
                                                        'competitor'
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    Obriši
                                                </Button>
                                            )}
                                        </>
                                    )}
                                </Col>
                            </Row>
                        ) : (
                            <Button
                                type="text"
                                className="w-100"
                                onClick={() => setShowCreateMatchModal(true)}
                                disabled={
                                    user?.role?.type === 'competitor'
                                        ? true
                                        : false
                                }
                            >
                                Dodaj klubove
                            </Button>
                        )}
                    </Col>
                </Row>
            </Col>

            <CreateMatchModal
                show={showCreateMatchModal}
                onHide={() => setShowCreateMatchModal(false)}
                matchDetails={{
                    ...matchDetails,
                    sideOneData: sideOneData,
                    sideTwoData: sideTwoData,
                    uuid: matchData?.single_club_match_uuid,
                }}
                handleGetSingleMatch={handleGetSingleMatch}
                createNextMatch={createNextMatch}
                onChange={onChange}
            />

            <ChangeMatchResultModal
                show={showChangeMatchResultModal}
                onHide={() => setShowChangeMatchResultModal(false)}
                matchDetails={{
                    sideOneData: sideOneData,
                    sideTwoData: sideTwoData,
                    ...matchData,
                }}
                handleGetSingleMatch={handleGetSingleMatch}
                onChange={onChange}
                createNextMatch={createNextMatch}
            />

            <DeleteMatchModal
                show={showDeleteMatchModal}
                onHide={() => setShowDeleteMatchModal(false)}
                matchUuid={matchData?.single_club_match_uuid}
                handleGetSingleMatch={handleGetSingleMatch}
                onDelete={onDelete}
            />

            <ClubsMatchesModal
                show={showMatchesModal}
                onHide={() => setShowMatchesModal(false)}
                matchDetails={matchData}
                sideOneData={sideOneData}
                sideTwoData={sideTwoData}
                tournament={tournament}
            />
        </Row>
    )
}

export default TournamentClubMatchCard
