import React from 'react'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { formatDate, formatImgUrl, isArrayEmpty } from '../../lib/helpers'
import { generateAvatar } from '../../utils/generateAvatar'

import NoDataForView from '../NoDataForView'
import PaginationWrapper from '../Pagination'
import './styles.scss'
const TableRefereeAdminCompetitionsPagination = ({
    data,
    finished,
    className,
    currentPage,
    setCurrentPage,
    pageCount,
    total,
    rowsPerPage,
    setRowsPerPage,
}) => {
    return !isArrayEmpty(data) ? (
        <div
            className={`table-referee-admin-competitions-pagination-wrapper ${className}`}
        >
            <Table className="mb-0">
                <thead>
                    <tr>
                        <th>
                            <h5>Naziv</h5>
                        </th>
                        <th>
                            <h5>Organizator</h5>
                        </th>
                        <th>
                            <h5>Odjaviti do</h5>
                        </th>
                        <th>
                            <h5>Kategorija</h5>
                        </th>
                        {!finished && (
                            <th>
                                <h5></h5>
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {data.map((result) => (
                        <tr key={result.id} className="setting-table-row">
                            <td className="align-middle">
                                <Link to={`${result.attributes?.uuid}`}>
                                    <p className="link">
                                        {result.attributes.name}
                                    </p>
                                </Link>
                            </td>
                            <td className="align-middle d-flex align-items-center">
                                {result?.attributes?.club_organizer?.attributes
                                    ?.name ? (
                                    <>
                                        <img
                                            src={
                                                result?.attributes
                                                    ?.club_organizer?.attributes
                                                    ?.logo?.data
                                                    ? formatImgUrl(
                                                          result?.attributes
                                                              ?.club_organizer
                                                              ?.attributes?.logo
                                                              ?.data?.attributes
                                                              ?.url
                                                      )
                                                    : generateAvatar(
                                                          result?.attributes
                                                              ?.club_organizer
                                                              ?.attributes?.name
                                                      )
                                            }
                                            alt={`Logo kluba`}
                                            width={'24px'}
                                            height={'24px'}
                                            style={{
                                                maxHeight: '24px',
                                                maxWidth: '24px',
                                                borderRadius: '50%',
                                            }}
                                        />
                                        <Link
                                            to={`/klubovi/${result?.attributes?.club_organizer?.attributes?.uuid}`}
                                        >
                                            <p className="ms-2 link">
                                                {
                                                    result?.attributes
                                                        ?.club_organizer
                                                        ?.attributes?.name
                                                }
                                            </p>
                                        </Link>
                                    </>
                                ) : (
                                    <p>-</p>
                                )}
                            </td>
                            <td className="align-middle">
                                <p>
                                    {result?.attributes?.checkout_deadline_date
                                        ? formatDate(
                                              result?.attributes
                                                  ?.checkout_deadline_date
                                          )
                                        : '-'}
                                </p>
                            </td>
                            <td className="align-middle">
                                <p>
                                    <p>
                                        {result?.attributes?.tournament_category
                                            ?.tournament_category_name
                                            ? result?.attributes
                                                  ?.tournament_category
                                                  ?.tournament_category_name
                                            : '-'}
                                    </p>
                                </p>
                            </td>
                            {!finished && (
                                <td>
                                    <Link
                                        to={
                                            result?.attributes?.designated
                                                ? `/administrator-sudjenja/takmicenja/${result?.attributes?.uuid}/izmeni-sudije`
                                                : `/administrator-sudjenja/takmicenja/${result?.attributes?.uuid}/dodaj-sudiju`
                                        }
                                        state={result?.attributes}
                                    >
                                        <p className="link">
                                            {result?.attributes?.designated
                                                ? 'Izmeni dezigniranje'
                                                : 'Dezigniraj'}
                                        </p>
                                    </Link>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </Table>
            <PaginationWrapper
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageCount={pageCount}
                total={total}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
            />
        </div>
    ) : (
        <NoDataForView
            title={`Nema ${finished ? 'završenih' : 'nadolazećih'} takmičenja`}
            description={`Ovde je prikazana lista ${
                finished ? 'završenih' : 'nadolazećih'
            } takmičenja.`}
            className="border-0"
        />
    )
}

export default TableRefereeAdminCompetitionsPagination
