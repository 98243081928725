import { Table } from 'react-bootstrap'
import ClubRankingTableRow from './clubRankingTableRow'

import PaginationWrapper from '../Pagination'
import './styles.scss'

const ClubsRankingTable = ({
    data,
    className,
    currentPage,
    setCurrentPage,
    pageCount,
    total,
    rowsPerPage,
    setRowsPerPage,
}) => {
    return (
        <div
            className={`table-competitors-pagination-wrapper table-competitors-wrapper ${className}`}
        >
            <Table className="mb-0">
                <thead>
                    <tr>
                        <th>
                            <h5 style={{ marginLeft: '64px' }}>Klub</h5>
                        </th>
                        <th>
                            <h5>Broj bodova</h5>
                        </th>
                        <th>
                            <h5>Broj takmičara</h5>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((club, index) => (
                        <ClubRankingTableRow
                            key={index}
                            position={
                                currentPage * rowsPerPage +
                                (index + 1) -
                                rowsPerPage
                            }
                            club={club}
                        />
                    ))}
                </tbody>
            </Table>
            <PaginationWrapper
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageCount={pageCount}
                total={total}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
            />
        </div>
    )
}

export default ClubsRankingTable
