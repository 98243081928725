import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Modal } from 'react-bootstrap'
import Button from '../Button'
import { getClubsMatchesFromTournament } from '../../lib/api'
import TournamentClubMatchCard from '../TournamentClubMatchCard'

const ClubTournamentResultTableRow = ({ tournament, club }) => {
    const [showModal, setShowModal] = useState(false)
    const [matches, setMatches] = useState([])

    const handleGetClubsMatchesFromTournament = async () => {
        const matchesData = await getClubsMatchesFromTournament(
            tournament?.tournament?.attributes?.uuid,
            tournament?.attributes?.uuid
        )
        if (matchesData) {
            setMatches(matchesData)
        }
    }

    return (
        <tr>
            <td>
                <Link
                    to={`/takmicenja/${tournament?.tournament?.attributes?.uuid}`}
                    className="link"
                >
                    {tournament?.tournament?.attributes?.name}
                </Link>
            </td>
            <td>
                <p
                    className="link"
                    onClick={() => {
                        handleGetClubsMatchesFromTournament()
                        setShowModal(true)
                    }}
                >
                    Pregledaj mečeve kluba
                </p>
            </td>

            <Modal
                centered
                show={showModal}
                onHide={() => setShowModal(false)}
                dialogClassName="add-penalties-modal"
                fullscreen={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>Mečevi kluba</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        {matches?.map((match, index) => {
                            return (
                                <Col xs={12} md={4} key={index}>
                                    <TournamentClubMatchCard
                                        requireRoundNumber={false}
                                        hideButtons={true}
                                        matchDetails={{
                                            match,
                                            tournament_uuid:
                                                tournament?.attributes?.uuid,
                                        }}
                                    />
                                </Col>
                            )
                        })}
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="text" onClick={() => setShowModal(false)}>
                        Zatvori
                    </Button>
                </Modal.Footer>
            </Modal>
        </tr>
    )
}

export default ClubTournamentResultTableRow
