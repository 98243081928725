import { useEffect, useReducer, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {
    addPlayerOnTurnament,
    getPlayersForTournament,
    getSingleEntryPoints,
    removeClubFromTournament,
    updateTournamentClubParticipant,
} from '../../lib/api'
import {
    formatImgUrl,
    getPlayersList,
    handleGetPlayersForTournament,
    numbersOnly,
} from '../../lib/helpers'
import { generateAvatar } from '../../utils/generateAvatar'
import AsyncSelect from '../AsyncSelect'
import Button from '../Button'
import CompetitionsParticipantsTable from '../CompetitionParticipantsTable'
import CompetitionsParticipantsCard from '../CompetitionsTableCards/CompetitionsParticipantsCard'
import Dropdown from '../Dropdown'
import { RowsPerPageOptions } from '../RowsPerPageDropdown'
import TextField from '../TextField'

const CompetitionClubsParticipantsRow = ({
    club,
    enableRemove = false,
    handleGetClubsForTournament,
    competitionProfileData,
    index,
    totalClubs,
}) => {
    const [positionChangeEnabled, setPositionChangeEnabled] = useState(false)
    const [positionValue, setPositionValue] = useState('')
    const [showCompetitorsModal, setShowCompetitorsModal] = useState(false)
    const [showRemoveClubModal, setShowRemoveClubModal] = useState(false)
    const [selectedPlayer, setSelectedPlayer] = useState()
    const [showAddCompetitorsModal, setShowAddCompetitorsModal] =
        useState(false)
    const [participantsToShow, setParticipantsToShow] = useState({
        is_double: false,
        gender: competitionProfileData?.attributes?.genders[0]?.gender_name,
    })
    const [tournamentsEntryPoints, setTournamentsEntryPoints] = useState()

    const [playersForTournamentList, setPlayersForTournamentList] = useState([])
    const [allPlayers, setAllPlayers] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [total, setTotal] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(RowsPerPageOptions[0])

    const handleEntryPoints = async () => {
        const entryPoints = await getSingleEntryPoints(
            competitionProfileData?.attributes?.entry_points_uuid
        )
        setTournamentsEntryPoints(entryPoints)
    }

    const handleRemoveTournamentClubParticipant = async (participantUuid) => {
        const removedClub = await removeClubFromTournament(participantUuid)

        if (removedClub) {
            setShowRemoveClubModal(false)
            handleGetClubsForTournament()
        }
    }

    const handleGetPlayersForTournamentWrapper = async () => {
        setPlayersForTournamentList([])
        const { updatedParticipantsList, pgCount, total } =
            await handleGetPlayersForTournament(
                {
                    tournament_uuid: competitionProfileData?.attributes?.uuid,
                    is_double: participantsToShow?.is_double,
                    gender: participantsToShow?.gender,
                    club_uuid: club?.attributes?.club_uuid,
                },
                currentPage,
                participantsToShow,
                false,
                rowsPerPage
            )

        const { participants } = await getPlayersForTournament(
            null,
            null,
            {
                tournament_uuid: competitionProfileData?.attributes?.uuid,
                is_double: participantsToShow?.is_double,
                gender: participantsToShow?.gender,
                club_uuid: club?.attributes?.club_uuid,
            },
            1,
            true
        )
        setPageCount(pgCount)
        setTotal(total)
        setPlayersForTournamentList(updatedParticipantsList)
        setAllPlayers(participants)
    }

    const handleSubmitSelectedPlayer = async () => {
        delete selectedPlayer.value
        const addedPlayer = await addPlayerOnTurnament(selectedPlayer)
        if (addedPlayer) {
            setShowAddCompetitorsModal(false)
            setSelectedPlayer(null)
            handleGetPlayersForTournamentWrapper()
        }
    }

    const loadPlayers = async (inputValue, loadedOptions, paginationData) => {
        return getPlayersList(inputValue, loadedOptions, paginationData, {
            club_uuid: club?.attributes?.club_uuid,
            gender: participantsToShow?.gender,
            players_competition:
                competitionProfileData?.attributes?.players_competition
                    ?.players_competition_name,
            alreadySelectedPlayers: allPlayers?.length
                ? allPlayers?.reduce((accumulator, entry) => {
                      let tempList = []

                      if (selectedPlayer) {
                          if (selectedPlayer?.player_1_uuid) {
                              tempList.push(selectedPlayer?.player_1_uuid)
                          }

                          if (selectedPlayer?.player_2_uuid) {
                              tempList.push(selectedPlayer?.player_2_uuid)
                          }
                      }

                      if (entry?.attributes?.player_1_uuid) {
                          tempList.push(entry?.attributes?.player_1_uuid)
                      }

                      if (entry?.attributes?.player_2_uuid) {
                          tempList.push(entry?.attributes?.player_2_uuid)
                      }

                      return [...accumulator, ...tempList]
                  }, [])
                : selectedPlayer
                ? (() => {
                      let tempList = []

                      if (selectedPlayer) {
                          if (selectedPlayer?.player_1_uuid) {
                              tempList.push(selectedPlayer?.player_1_uuid)
                          }

                          if (selectedPlayer?.player_2_uuid) {
                              tempList.push(selectedPlayer?.player_2_uuid)
                          }
                      }
                      return tempList
                  })()
                : null,
        })
    }

    const handleUpdateClubParticipant = async () => {
        try {
            if (+positionValue) {
                const updatedParticipan = await updateTournamentClubParticipant(
                    club?.attributes?.uuid,
                    {
                        club_tournament_seed: +positionValue,
                    }
                )

                if (!updatedParticipan) return
                handleGetClubsForTournament()
            }

            setPositionChangeEnabled(false)
        } catch (error) {
            setPositionChangeEnabled(false)
            console.error(error)
        } finally {
            setPositionChangeEnabled(false)
        }
    }

    useEffect(() => {
        handleGetPlayersForTournamentWrapper()
    }, [
        club,
        showCompetitorsModal,
        currentPage,
        participantsToShow,
        tournamentsEntryPoints,
        rowsPerPage,
    ])

    useEffect(() => {
        handleEntryPoints()
        setParticipantsToShow((prev) => ({
            ...prev,
            gender: competitionProfileData?.attributes?.genders[0]?.gender_name,
        }))
    }, [competitionProfileData])

    useEffect(() => {
        setPositionValue(club?.attributes?.club_tournament_seed)
    }, [positionChangeEnabled])

    return (
        <>
            <tr className="club">
                <td
                    className="align-middle first-column"
                    style={{ width: '70px', minWidth: '70px' }}
                >
                    {positionChangeEnabled ? (
                        <TextField
                            placeholder={index + 1}
                            onChange={(e) => {
                                numbersOnly(e)

                                if (+e.target.value <= totalClubs) {
                                    setPositionValue(+e.target.value)
                                }
                            }}
                            value={positionValue}
                        />
                    ) : (
                        <p>
                            {club?.attributes?.club_tournament_seed ||
                                index + 1}
                        </p>
                    )}
                </td>
                <td>
                    <div className="d-flex align-items-center justify-content-start">
                        <div className="img-wrapper me-2">
                            <img
                                src={
                                    club?.attributes?.club?.attributes?.logo
                                        ?.data
                                        ? formatImgUrl(
                                              club?.attributes?.club?.attributes
                                                  ?.logo?.data?.attributes?.url
                                          )
                                        : generateAvatar(
                                              club?.attributes?.club?.attributes
                                                  ?.name
                                          )
                                }
                                alt={`Logo kluba`}
                                width={'24px'}
                                height={'24px'}
                            />
                        </div>
                        <Link
                            to={`/klubovi/${club?.attributes?.club?.attributes?.uuid}`}
                        >
                            <p className="text-elipsis link">
                                {club?.attributes?.club?.attributes?.name}
                            </p>
                        </Link>
                    </div>
                </td>
                <td style={{ minWidth: '118px' }}>
                    <p>
                        {club?.attributes?.club?.attributes?.region
                            ? club?.attributes?.club?.attributes?.region
                                  ?.region_name
                            : '-'}
                    </p>
                </td>
                <td style={{ minWidth: '118px' }}>
                    <p>
                        {club?.attributes?.club?.attributes?.address?.city
                            ? club?.attributes?.club?.attributes?.address?.city
                            : '-'}
                    </p>
                </td>
                <td
                    className="link"
                    onClick={() => setShowCompetitorsModal(true)}
                >
                    Takmičari
                </td>
                {enableRemove && (
                    <>
                        <td className="align-middle">
                            {positionChangeEnabled ? (
                                <Button
                                    type="secondary"
                                    onClick={handleUpdateClubParticipant}
                                >
                                    Sačuvaj
                                </Button>
                            ) : (
                                <p
                                    className="link"
                                    onClick={() =>
                                        setPositionChangeEnabled(true)
                                    }
                                >
                                    Izmeni poziciju
                                </p>
                            )}
                        </td>
                        <td className="align-middle">
                            <p
                                className="red-link"
                                onClick={() => setShowRemoveClubModal(true)}
                            >
                                Ukloni
                            </p>
                        </td>
                    </>
                )}
            </tr>

            <Modal
                centered
                show={showCompetitorsModal}
                onHide={() => setShowCompetitorsModal(false)}
                dialogClassName="add-penalties-modal"
                fullscreen={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>Takmičari</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12} md={4}>
                            <Dropdown
                                placeholder="Tip učesnika"
                                label="Tip učesnika"
                                value={
                                    participantsToShow?.is_double
                                        ? 'Dubl'
                                        : 'Singl'
                                }
                                handleSelect={(e) =>
                                    e === 'Singl'
                                        ? setParticipantsToShow((prev) => ({
                                              ...prev,
                                              is_double: false,
                                          }))
                                        : setParticipantsToShow((prev) => ({
                                              ...prev,
                                              is_double: true,
                                          }))
                                }
                                dropdownLinks={['Singl', 'Dubl']}
                            />
                        </Col>
                        {competitionProfileData?.attributes?.genders?.length >
                            1 && (
                            <Col xs={12} md={4} className="mt-2 mt-md-0">
                                <Dropdown
                                    label={'Pol učesnika'}
                                    placeholder="Izaberite pol"
                                    dropdownLinks={competitionProfileData?.attributes?.genders?.map(
                                        (gender) => {
                                            return gender?.gender_name
                                        }
                                    )}
                                    value={participantsToShow?.gender}
                                    handleSelect={(e) =>
                                        setParticipantsToShow((prev) => ({
                                            ...prev,
                                            gender: e,
                                        }))
                                    }
                                />
                            </Col>
                        )}

                        {enableRemove && (
                            <Col
                                xs={12}
                                md={4}
                                className="d-flex align-items-end mt-2 md-mt-0"
                            >
                                <Button
                                    onClick={() =>
                                        setShowAddCompetitorsModal(true)
                                    }
                                >
                                    Dodaj igrače
                                </Button>
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <Col>
                            <Row className="d-none d-md-block mt-5">
                                <Col>
                                    <CompetitionsParticipantsTable
                                        participants={playersForTournamentList}
                                        enableRemove={true}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        handleGetPlayersForTournament={
                                            handleGetPlayersForTournamentWrapper
                                        }
                                        pageCount={pageCount}
                                        total={total}
                                        isDouble={participantsToShow?.is_double}
                                        rowsPerPage={rowsPerPage}
                                        setRowsPerPage={setRowsPerPage}
                                        competitionProfileData={
                                            competitionProfileData
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row className="d-block d-md-none mt-3">
                                <Col>
                                    {playersForTournamentList?.map(
                                        (participant, index) => (
                                            <CompetitionsParticipantsCard
                                                key={index}
                                                index={index}
                                                participant={participant}
                                                enableRemove={true}
                                                isWhite={
                                                    index % 2 === 0
                                                        ? true
                                                        : false
                                                }
                                                handleGetPlayersForTournament={
                                                    handleGetPlayersForTournamentWrapper
                                                }
                                            />
                                        )
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="text"
                        onClick={() => setShowCompetitorsModal(false)}
                    >
                        Zatvori
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                size="md"
                centered
                show={showRemoveClubModal}
                onHide={() => setShowRemoveClubModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>Uklanjanje kluba</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Da li ste sigurni da želite da uklonite klub (
                        <Link
                            to={`/klubovi/${club?.attributes?.club?.attributes?.uuid}`}
                            className="link"
                        >
                            {club?.attributes?.club?.attributes?.name}
                        </Link>
                        ) sa ovog takmičenja?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="text"
                        onClick={() => setShowRemoveClubModal(false)}
                    >
                        Odustani
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            handleRemoveTournamentClubParticipant(
                                club?.attributes?.uuid
                            )
                        }}
                    >
                        Ukloni
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                centered
                show={showAddCompetitorsModal}
                onHide={() => setShowAddCompetitorsModal(false)}
                dialogClassName="add-penalties-modal"
                size="lg"
            >
                <Modal.Body>
                    <AsyncSelect
                        loadOptions={loadPlayers}
                        onChange={(e) => {
                            setSelectedPlayer((prev) => ({
                                ...prev,
                                tournament_uuid:
                                    competitionProfileData?.attributes?.uuid,
                                club_uuid: club?.attributes?.club_uuid,
                                player_1_uuid: e.uuid,
                                gender: {
                                    gender_name: participantsToShow?.gender,
                                },
                            }))
                        }}
                        label={`Izaberi igrača ${
                            participantsToShow?.is_double ? '1' : ''
                        }`}
                        placeholder={
                            'Pretraži i izaberi igrača po imenu i prezimenu...'
                        }
                        noOptionsMessage={'Traženi igrač ne postoji'}
                        closeMenuOnSelect={true}
                        isMulti={false}
                        cacheUniqs={[selectedPlayer]}
                    />

                    {participantsToShow?.is_double && (
                        <Row className="mt-3">
                            <Col>
                                <AsyncSelect
                                    loadOptions={loadPlayers}
                                    onChange={(e) => {
                                        setSelectedPlayer((prev) => ({
                                            ...prev,
                                            tournament_uuid:
                                                competitionProfileData
                                                    ?.attributes?.uuid,
                                            player_2_uuid: e.uuid,
                                            club_uuid:
                                                club?.attributes?.club_uuid,
                                            gender: {
                                                gender_name:
                                                    participantsToShow?.gender,
                                            },
                                        }))
                                    }}
                                    label={'Izaberi igrača 2'}
                                    placeholder={
                                        'Pretraži i izaberi igrača po imenu i prezimenu...'
                                    }
                                    noOptionsMessage={
                                        'Traženi igrač ne postoji'
                                    }
                                    closeMenuOnSelect={true}
                                    isMulti={false}
                                    cacheUniqs={[selectedPlayer]}
                                />
                            </Col>
                        </Row>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="text"
                        onClick={() => {
                            setShowAddCompetitorsModal(false)
                            setSelectedPlayer(null)
                        }}
                    >
                        Odustani
                    </Button>
                    <Button
                        onClick={() => handleSubmitSelectedPlayer()}
                        disabled={
                            participantsToShow?.is_double
                                ? selectedPlayer?.player_1_uuid &&
                                  selectedPlayer?.player_2_uuid
                                    ? false
                                    : true
                                : selectedPlayer?.player_1_uuid
                                ? false
                                : true
                        }
                    >
                        Potvrdi
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CompetitionClubsParticipantsRow
