import { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import logoTss from '../../assets/images/logo-longer.png'
import Button from '../../components/Button'
import TextField from '../../components/TextField'
import { sendForgotPassword } from '../../lib/api'

import './styles.scss'

const ForgotPassword = () => {
    const [email, setEmail] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [disableButton, setDisableButton] = useState(true)

    const validateEmail = (email) => {
        var re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase())
    }

    const handleSubmit = async () => {
        try {
            const forgotPassword = await sendForgotPassword(email)
            if (forgotPassword) {
                setShowModal(true)
                setEmail('')
            }
        } catch (error) {
            setEmail('')
            setErrorMessage('Došlo je do greške. Pokušajte ponovo.')
            setShowModal(true)
        }
    }

    const handleFieldValidation = () => {
        if (validateEmail(email)) {
            setDisableButton(false)
        } else {
            setDisableButton(true)
        }
    }

    useEffect(() => {
        handleFieldValidation()
    }, [email])

    return (
        <Row className="mt-5 forgot-password-wrapper">
            <Col md={4}></Col>
            <Col md={4}>
                <Row>
                    <Col
                        md={12}
                        className="d-flex align-items-center justify-content-center"
                    >
                        <Link to={'/'}>
                            <img
                                src={logoTss}
                                alt="logo-tss"
                                className="tss-logo"
                            />
                        </Link>
                    </Col>
                    <Col
                        md={12}
                        className="d-flex header align-items-center justify-content-center text-center"
                    >
                        <h1>Zaboravljena / Promena lozinke</h1>
                    </Col>
                    <Col
                        md={12}
                        className="mt-2 mb-2 d-flex align-items-center justify-content-center text-center"
                    >
                        <p className="small-text">
                            Na mejl ćemo ti poslati link pomoću kojeg možeš da
                            kreiraš svoju lozinku.
                        </p>
                    </Col>
                    <Col md={12} className="mt-4">
                        <TextField
                            id="1"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            label="Email"
                            placeholder="Unesite Vaš email"
                        />
                    </Col>
                    <Col className="mt-4 mb-5 d-flex align-items-center justify-content-end">
                        <Link to="/" className="link-text" tabIndex="1">
                            <Button type={'text'} className="me-2">
                                Odustani
                            </Button>
                        </Link>

                        <Button
                            onClick={() => handleSubmit()}
                            disabled={disableButton}
                        >
                            Sledeća
                        </Button>
                    </Col>

                    <Col xs={12}>
                        <h3 className="text-center">
                            Samostalno kreiraj nalog
                        </h3>
                        <p className="small-text text-center mt-2">
                            Za igrače koji nisu dobili mail za prijavu na novu
                            platformu ili nisu imali svoj email na prethodnom
                            nalogu.{' '}
                            <span>
                                {' '}
                                <Link to="/kreiraj-nalog" className="link">
                                    Link
                                </Link>
                            </span>
                        </p>
                    </Col>

                    <Col
                        md={12}
                        className="logo-below mt-5 pt-5 d-flex align-items-center justify-content-center"
                    >
                        <p className="chainapp-text">
                            Razvija:{' '}
                            <a
                                href="https://chainapp.tech/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Chainapp Technologies doo
                            </a>
                        </p>{' '}
                    </Col>
                </Row>
            </Col>
            <Col md={4}></Col>

            <Modal centered show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>{errorMessage ? 'Greška' : 'Uspešno'}</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {errorMessage
                            ? errorMessage
                            : 'Mail sa linkom za izmenu lozinke je uspešno poslat na uneti email. Proverite vaše sanduče.'}
                    </p>
                </Modal.Body>
            </Modal>
        </Row>
    )
}

export default ForgotPassword
