import { useEffect } from 'react'
import Col from 'react-bootstrap/Col'
import { useAppContext } from '../../lib/contextLib'
// Icons outlined
import { useNavigate } from 'react-router-dom'
import logoTss from '../../assets/images/logo-longer.png'
import avatar from '../../assets/images/maleAvatar.png'
// import ColorFilter from '../../assets/svg/ColorFilter'
// import ColorFilterOutlined from '../../assets/svg/ColorFilterOutlined'
import DocumentText from '../../assets/svg/DocumentText'
import DocumentTextOutlined from '../../assets/svg/DocumentTextOutlined'
import Flag from '../../assets/svg/Flag'
import FlagOutlined from '../../assets/svg/FlagOutlined'
import Grid from '../../assets/svg/Grid'
import GridOutlined from '../../assets/svg/GridOutlined'
import Home from '../../assets/svg/Home'
import HomeOutlined from '../../assets/svg/HomeOutlined'
import People from '../../assets/svg/People'
import PeopleOutlined from '../../assets/svg/PeopleOutlined'
import Person from '../../assets/svg/Person'
import PersonOutlined from '../../assets/svg/PersonOutlined'
import Podium from '../../assets/svg/Podium'
import PodiumOutlined from '../../assets/svg/PodiumOutlined'
import TennisBall from '../../assets/svg/TennisBall'
import TennisBallOutlined from '../../assets/svg/TennisBallOutlined'
import ProfileInfo from '../ProfileInfo'
import SidebarLink from '../SidebarLink'

import { generateAvatar } from '../../utils/generateAvatar'
import './styles.scss'

const adminLinks = [
    {
        text: 'Početna',
        path: '/pocetna',
        icon: <HomeOutlined />,
        activeIcon: <Home />,
        subMenu: [],
    },
    {
        text: 'Takmičari',
        path: '/takmicari',
        icon: <PeopleOutlined />,
        activeIcon: <People />,
        subMenu: [],
    },
    {
        text: 'Takmičenja',
        path: '/takmicenja',
        icon: <GridOutlined />,
        activeIcon: <Grid />,
        subMenu: [],
    },
    {
        text: 'Rang liste',
        icon: <PodiumOutlined />,
        activeIcon: <Podium />,
        subMenu: [
            {
                text: 'Klubova',
                path: '/rang-lista-klubova',
                icon: <PodiumOutlined />,
                activeIcon: <Podium />,
            },
            {
                text: 'Takmičara',
                path: '/rang-lista-takmicara',
                icon: <PodiumOutlined />,
                activeIcon: <Podium />,
            },
        ],
    },
    {
        text: 'Sudije',
        path: '/sudije',
        icon: <FlagOutlined />,
        activeIcon: <Flag />,
        subMenu: [],
    },
    {
        text: 'Klubovi',
        path: '/klubovi',
        icon: <TennisBallOutlined />,
        activeIcon: <TennisBall />,
        subMenu: [],
    },
    {
        text: 'Treneri',
        path: '/treneri',
        icon: <PersonOutlined />,
        activeIcon: <Person />,
        subMenu: [],
    },
]

const competitorLinks = [
    {
        text: 'Početna',
        path: '/takmicar/pocetna',
        icon: <HomeOutlined />,
        activeIcon: <Home />,
        subMenu: [],
    },
    {
        text: 'Takmičenja',
        path: '/takmicar/takmicenja',
        icon: <GridOutlined />,
        activeIcon: <Grid />,
        subMenu: [],
    },
    {
        text: 'Rang liste',
        icon: <PodiumOutlined />,
        activeIcon: <Podium />,
        subMenu: [
            {
                text: 'Klubova',
                path: '/takmicar/rang-lista-klubova',
                icon: <PodiumOutlined />,
                activeIcon: <Podium />,
            },
            {
                text: 'Takmičara',
                path: '/takmicar/rang-lista-takmicara',
                icon: <PodiumOutlined />,
                activeIcon: <Podium />,
            },
        ],
    },
]

const competitorDraftLinks = [
    {
        text: 'Početna',
        path: '/takmicar/pocetna',
        icon: <HomeOutlined />,
        activeIcon: <Home />,
        subMenu: [],
    },
    {
        text: 'Rang liste',
        icon: <PodiumOutlined />,
        activeIcon: <Podium />,
        subMenu: [
            {
                text: 'Klubova',
                path: '/takmicar/rang-lista-klubova',
                icon: <PodiumOutlined />,
                activeIcon: <Podium />,
            },
            {
                text: 'Takmičara',
                path: '/takmicar/rang-lista-takmicara',
                icon: <PodiumOutlined />,
                activeIcon: <Podium />,
            },
        ],
    },
]

const refereeLinks = [
    {
        text: 'Početna',
        path: '/sudije/pocetna',
        icon: <HomeOutlined />,
        activeIcon: <Home />,
        subMenu: [],
    },
    {
        text: 'Takmičenja',
        icon: <GridOutlined />,
        activeIcon: <Grid />,
        subMenu: [
            {
                text: 'Iz kalendara TSS',
                path: '/sudije/takmicenja-iz-kalendara-TSS',
                icon: <PodiumOutlined />,
                activeIcon: <Podium />,
            },
            {
                text: 'Individualna',
                path: '/sudije/individualna-takmicenja',
                state: 'Individualno',
                icon: <PodiumOutlined />,
                activeIcon: <Podium />,
            },
            {
                text: 'Ekipna',
                path: '/sudije/ekipna-takmicenja',
                state: 'Ekipno',
                icon: <PodiumOutlined />,
                activeIcon: <Podium />,
            },
        ],
    },
    {
        text: 'Rang liste',
        icon: <PodiumOutlined />,
        activeIcon: <Podium />,
        subMenu: [
            {
                text: 'Klubova',
                path: '/rang-lista-klubova',
                icon: <PodiumOutlined />,
                activeIcon: <Podium />,
            },
            {
                text: 'Takmičara',
                path: '/rang-lista-takmicara',
                icon: <PodiumOutlined />,
                activeIcon: <Podium />,
            },
        ],
    },
]
const refereeAdminLinks = [
    {
        text: 'Početna',
        path: '/administrator-sudjenja/pocetna',
        icon: <HomeOutlined />,
        activeIcon: <Home />,
        subMenu: [],
    },
    {
        text: 'Takmičenja',
        icon: <GridOutlined />,
        activeIcon: <Grid />,
        subMenu: [
            {
                text: 'Iz kalendara TSS',
                path: '/administrator-sudjenja/takmicenja-iz-kalendara-TSS',
                icon: <PodiumOutlined />,
                activeIcon: <Podium />,
            },
            {
                text: 'Individualna',
                path: '/administrator-sudjenja/individualna-takmicenja',
                state: 'Individualno',
                icon: <PodiumOutlined />,
                activeIcon: <Podium />,
            },
            {
                text: 'Ekipna',
                path: '/administrator-sudjenja/ekipna-takmicenja',
                state: 'Ekipno',
                icon: <PodiumOutlined />,
                activeIcon: <Podium />,
            },
        ],
    },
    {
        text: 'Rang liste',
        icon: <PodiumOutlined />,
        activeIcon: <Podium />,
        subMenu: [
            {
                text: 'Klubova',
                path: '/rang-lista-klubova',
                icon: <PodiumOutlined />,
                activeIcon: <Podium />,
            },
            {
                text: 'Takmičara',
                path: '/rang-lista-takmicara',
                icon: <PodiumOutlined />,
                activeIcon: <Podium />,
            },
        ],
    },
]

const Sidebar = () => {
    const navigate = useNavigate()
    const { user, setAuthenticated } = useAppContext()

    const linksHandler = () => {
        switch (user?.role?.type) {
            case 'admin':
                return adminLinks
            case 'competitor':
                if (user?.data?.attributes?.status?.status_name === 'draft') {
                    return competitorDraftLinks
                } else {
                    return competitorLinks
                }
            case 'referee':
                if (user?.data?.attributes?.is_administrator) {
                    return refereeAdminLinks
                } else {
                    return refereeLinks
                }
        }
    }

    // Avatar handler

    const avatarHandler = () => {
        if (user?.role?.type === 'admin') {
            if (user?.data?.attributes?.person_info?.picture) {
                return user?.data?.attributes?.person_info?.picture
            } else {
                return avatar
            }
        } else {
            if (user?.data?.attributes?.person_info?.picture) {
                return user?.data?.attributes?.person_info?.picture
            } else {
                return generateAvatar(
                    `${user?.data?.attributes?.person_info?.given_name} ${user?.data?.attributes?.person_info?.family_name}`
                )
            }
        }
    }

    useEffect(() => {
        linksHandler()
    }, [user])

    const handleRedirectToHome = () => {
        switch (user?.role?.type) {
            case 'admin':
                return navigate('/pocetna')
            case 'competitor':
                return navigate('/takmicar/pocetna')
            case 'referee':
                if (user?.data?.attributes?.is_administrator) {
                    return navigate('/administrator-sudjenja/pocetna')
                } else {
                    return navigate('/sudije/pocetna')
                }
        }
    }

    return (
        <div className="sidebar-wrapper">
            <Col
                md={12}
                className="d-none d-md-flex justify-content-center pt-3"
            >
                <img
                    onClick={handleRedirectToHome}
                    src={logoTss}
                    alt="logo-tss"
                    className="sidebar-logo"
                />
            </Col>
            <Col md={12} className="mt-4 ps-4 pe-4 d-none d-md-block">
                <ProfileInfo
                    avatar={avatarHandler()}
                    name={
                        user?.data?.attributes?.person_info?.given_name
                            ? user?.data?.attributes?.person_info?.given_name
                            : 'Administrator'
                    }
                    surname={
                        user?.data?.attributes?.family_name
                            ? user?.data?.attributes?.family_name
                            : ''
                    }
                    email={user?.email}
                    alt={avatar}
                />
            </Col>
            <Col md={12} className="d-none d-md-block ps-md-3 pe-md-3">
                <hr />
            </Col>

            <Col>
                {linksHandler()?.map((link, index) => (
                    <Col key={index} md={12} className="mt-2">
                        <SidebarLink key={index} link={link} />
                    </Col>
                ))}
            </Col>
        </div>
    )
}

export default Sidebar
