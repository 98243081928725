import * as React from 'react'

const ArrowUp = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        width={12}
        height={12}
        {...props}
    >
        <title>{'Arrow Up'}</title>
        <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={48}
            d="m112 244 144-144 144 144M256 120v292"
        />
    </svg>
)

export default ArrowUp
