import * as React from 'react'

const ExitOutlined = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="ionicon"
        viewBox="0 0 512 512"
        {...props}
    >
        <title>{'Exit'}</title>
        <path
            d="M320 176v-40a40 40 0 0 0-40-40H88a40 40 0 0 0-40 40v240a40 40 0 0 0 40 40h192a40 40 0 0 0 40-40v-40m64-160 80 80-80 80m-193-80h273"
            fill="none"
            stroke={props.color ? props.color : `#2C3136`}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={32}
        />
    </svg>
)

export default ExitOutlined
