import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { formatDate } from '../../../../lib/helpers'
import { generateAvatar } from '../../../../utils/generateAvatar'
import { Modal, Row, Col } from 'react-bootstrap'
import Button from '../../../../components/Button'
import TextField from '../../../../components/TextField'
import { useNavigate } from 'react-router-dom'

function PlayersMigrationRow({ competitor }) {
    const navigate = useNavigate()
    const [showModal, setShowModal] = useState(false)
    const [insertedPassword, setInsertedPassword] = useState('')
    return (
        <tr>
            <td style={{ minWidth: '100px' }} className="link">
                <div className="d-flex align-items-center justify-content-start">
                    <img
                        src={
                            competitor?.attributes?.person_info?.picture
                                ? competitor?.attributes?.person_info?.picture
                                : generateAvatar(
                                      `${competitor?.attributes?.person_info?.given_name} ${competitor?.attributes?.person_info?.family_name}`
                                  )
                        }
                        alt={`${competitor?.attributes?.person_info?.given_name}
                            ${competitor?.attributes?.person_info?.family_name}`}
                        className="me-2 table-competitors-avatar"
                    />
                    <Link to={`/takmicari/${competitor?.attributes?.uuid}`}>
                        <p className="link">
                            {competitor?.attributes?.person_info?.given_name}{' '}
                            {competitor?.attributes?.person_info?.family_name}
                        </p>
                    </Link>
                </div>
            </td>
            <td style={{ minWidth: '100px' }}>
                <p>{competitor?.attributes?.legacy_id}</p>
            </td>
            <td style={{ minWidth: '70px' }}>
                <p>
                    {competitor?.attributes?.person_info?.birthdate
                        ? formatDate(
                              competitor?.attributes?.person_info?.birthdate
                          )
                        : '-'}
                </p>
            </td>
            <td
                style={{ minWidth: '100px' }}
                className="link link-align-end"
                onClick={() => setShowModal(true)}
            >
                Uloguj se
            </td>

            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Potvrda identiteta</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <p>
                                Ime i prezime:{' '}
                                {
                                    competitor?.attributes?.person_info
                                        ?.given_name
                                }{' '}
                                {
                                    competitor?.attributes?.person_info
                                        ?.family_name
                                }
                            </p>
                            <p>
                                Datum rodjenja:{' '}
                                {formatDate(
                                    competitor?.attributes?.person_info
                                        ?.birthdate
                                )}
                            </p>
                            <h5 className="my-2">Lozinka</h5>
                            <TextField
                                type="text"
                                placeholder="Unesite vašu staru lozinku"
                                value={insertedPassword || ''}
                                onChange={(e) => {
                                    setInsertedPassword(e.target.value)
                                }}
                            />

                            {false && (
                                <p className="error-p mt-3">
                                    Uneli ste pogrešnu lozinku.
                                </p>
                            )}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="secondary"
                        onClick={() => setShowModal(false)}
                    >
                        Odustani
                    </Button>
                    <Button
                        onClick={() =>
                            navigate(
                                `/obnova-starog-naloga/${competitor?.attributes?.uuid}`
                            )
                        }
                        disabled={
                            +insertedPassword ===
                            +competitor?.attributes?.legacy_id * 3 + 7
                                ? false
                                : true
                        }
                    >
                        Potvrdi
                    </Button>
                </Modal.Footer>
            </Modal>
        </tr>
    )
}

export default PlayersMigrationRow
