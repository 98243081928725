import {
    updateTournamentPlayerParticipant,
    updateParticipantsGroup,
} from '../../../../../../../lib/api'
export const calculateGroupParticipantRank = async (
    groupMatches,
    participantUuid
) => {
    const participantsMatches = groupMatches?.filter(
        (match) =>
            match?.attributes?.side_1_uuid === participantUuid ||
            match?.attributes?.side_2_uuid === participantUuid
    )

    let winsNumber = 0
    let setsWonNumber = 0
    let gamesWonNumber = 0

    let setsLostNumber = 0
    let gamesLostNumber = 0

    participantsMatches?.map((match) => {
        if (match?.attributes?.loser_participant_uuid) {
            if (match?.attributes?.loser_participant_uuid !== participantUuid) {
                winsNumber++
            }

            if (match?.attributes?.side_1_uuid === participantUuid) {
                match?.attributes?.side_1_result?.result?.map((game, index) => {
                    gamesWonNumber += +game
                    gamesLostNumber +=
                        +match?.attributes?.side_2_result?.result[index]
                    if (
                        game > match?.attributes?.side_2_result?.result[index]
                    ) {
                        setsWonNumber++
                    } else {
                        setsLostNumber++
                    }
                })
            } else {
                match?.attributes?.side_2_result?.result?.map((game, index) => {
                    gamesWonNumber += +game
                    gamesLostNumber +=
                        +match?.attributes?.side_1_result?.result[index]
                    if (
                        game > match?.attributes?.side_1_result?.result[index]
                    ) {
                        setsWonNumber++
                    } else {
                        setsLostNumber++
                    }
                })
            }
        }
    })

    return {
        winsNumber: winsNumber || 0,
        gamesWonNumber: gamesWonNumber || 0,
        setsWonNumber: setsWonNumber || 0,
        setRatio: setRatioAsDecimal(setsWonNumber, setsLostNumber) || 0.0,
        gameRatio: setRatioAsDecimal(gamesWonNumber, gamesLostNumber) || 0.0,
    }
}

const setRatioAsDecimal = (wonSets, lostSets) => {
    if (lostSets === 0) {
        if (wonSets === 0) {
            return null
        } else {
            return 1
        }
    } else {
        return +(wonSets / (wonSets + lostSets)).toFixed(2)
    }
}

export const rankCompetitors = async (
    groupParticipants,
    groupMatches,
    enableUpdate = false
) => {
    let competitors = []
    for (let i = 0; i < groupParticipants?.length; i++) {
        const {
            winsNumber,
            gamesWonNumber,
            setsWonNumber,
            setRatio,
            gameRatio,
        } = await calculateGroupParticipantRank(
            groupMatches,
            groupParticipants[i]?.attributes?.participant_uuid
        )
        competitors.push({
            participant_data: groupParticipants[i],
            participant_uuid:
                groupParticipants[i]?.attributes?.participant_uuid,
            wins: winsNumber,
            total_sets: setsWonNumber,
            total_games: gamesWonNumber,
            set_ratio: setRatio,
            game_ratio: gameRatio,
            group_manual_rank:
                groupParticipants[i]?.attributes?.group_manual_rank || 1,
            group_uuid: groupParticipants[i]?.attributes?.uuid,
        })
    }
    competitors.sort((a, b) => {
        if (a.group_manual_rank !== b.group_manual_rank) {
            return a.group_manual_rank - b.group_manual_rank
        }

        if (a.wins !== b.wins) {
            return b.wins - a.wins
        } else if (a.wins === b.wins) {
            let match = groupMatches?.find(
                (match) =>
                    (match?.attributes?.side_1_uuid === a.participant_uuid &&
                        match?.attributes?.side_2_uuid ===
                            b.participant_uuid) ||
                    (match?.attributes?.side_1_uuid === b.participant_uuid &&
                        match?.attributes?.side_2_uuid === a.participant_uuid)
            )

            if (match) {
                if (
                    match.attributes?.loser_participant_uuid ===
                    a.participant_uuid
                ) {
                    return 1
                } else if (
                    match.attributes?.loser_participant_uuid ===
                    b.participant_uuid
                ) {
                    return -1
                }
            }
        }

        if (a.set_ratio !== b.set_ratio) {
            return b.set_ratio - a.set_ratio
        } else if (a.total_sets !== b.total_sets) {
            return b.total_sets - a.total_sets
        } else if (a.game_ratio !== b.game_ratio) {
            return b.game_ratio - a.game_ratio
        } else {
            return b.total_games - a.total_games
        }
    })

    let rank = 1
    for (const competitor of competitors) {
        competitor.group_rank = rank
        rank++
    }

    if (enableUpdate) {
        await Promise.all(
            competitors?.map((competitor) =>
                updateParticipantsGroup(competitor?.group_uuid, {
                    group_rank: competitor?.group_rank,
                })
            )
        )
    }

    const updateFirstTwo = competitors?.slice(0, 2).map((competitor) => {
        if (competitor?.participant_uuid) {
            return updateTournamentPlayerParticipant(
                competitor.participant_uuid,
                {
                    player_status: { status_name: 'DA' },
                }
            )
        }
        return Promise.resolve()
    })

    const updateOthers = competitors?.slice(2).map((competitor) => {
        if (competitor?.participant_uuid) {
            return updateTournamentPlayerParticipant(
                competitor.participant_uuid,
                {
                    player_status: { status_name: 'QA' },
                }
            )
        }
        return Promise.resolve()
    })

    await Promise.all([...updateFirstTwo, ...updateOthers])

    return competitors
}
