import * as React from "react"

const SuccessIcon = (props) => (
  <svg
    width={21}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 10.001a8 8 0 1 1 16 0 8 8 0 0 1-16 0Zm11.738-.908a.8.8 0 1 0-1.076-1.184L9.3 11.419l-1.462-1.328a.8.8 0 1 0-1.076 1.184l2 1.818a.8.8 0 0 0 1.076 0l4.4-4Z"
      fill="#498500"
    />
  </svg>
)

export default SuccessIcon
