import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import TextArea from '../../../../../components/TextArea'

//Styles
import './styles.scss'

function Conclusion({ setProgress, formData, setFormData }) {
    useEffect(() => {
        setProgress(83)
    }, [])

    const setField = (e) => {
        let field = e.target.id
        let value = e.target.value
        let mainField = e.target.name

        setFormData((prevData) => ({
            ...prevData,
            [mainField]: { ...prevData[mainField], [field]: value },
        }))
    }

    return (
        <Row className="conclusion-wrapper flex-column">
            <Col className="mb-3">
                <TextArea
                    label="Uslovi organizacije na turniru koji su bili dobri"
                    placeholder="Uslovi organizacije na turniru koji su bili dobri"
                    id="goodCondition"
                    name="conclusion"
                    rows={4}
                    onChange={setField}
                    value={formData?.conclusion?.goodCondition}
                />
            </Col>
            <Col>
                <TextArea
                    label="Uslovi organizacije na turniru koji zahtevaju poboljšanja"
                    placeholder="Uslovi organizacije na turniru koji zahtevaju poboljšanja"
                    id="improvementCondition"
                    name="conclusion"
                    rows={4}
                    onChange={setField}
                    value={formData?.conclusion?.improvementCondition}
                />
            </Col>
            <Col>
                <TextArea
                    label="Saveti za buduće turnire"
                    placeholder="Saveti za buduće turnire"
                    id="tips"
                    name="conclusion"
                    rows={4}
                    onChange={setField}
                    value={formData?.conclusion?.tips}
                />
            </Col>
            <Col>
                <TextArea
                    label="Generalne napomene"
                    placeholder="Generalne napomene"
                    id="generalRemarks"
                    name="conclusion"
                    rows={4}
                    onChange={setField}
                    value={formData?.conclusion?.generalRemarks}
                />
            </Col>
        </Row>
    )
}

export default Conclusion
