import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Card, Col, Modal, Row } from 'react-bootstrap'
import Button from '../Button'
import { formatImgUrl } from '../../lib/helpers'
import { generateAvatar } from '../../utils/generateAvatar'
import { removeClubFromTournament } from '../../lib/api'

const CompetitionClubsCardParticipants = ({
    isWhite,
    club,
    className,
    enableRemove,
    handleGetClubsForTournament,
}) => {
    const [showCompetitorsModal, setShowCompetitorsModal] = useState(false)
    const [selectedCompetitors, setSelectedCompetitors] = useState([])
    const [showRemoveClubModal, setShowRemoveClubModal] = useState(false)

    const handleRemoveTournamentClubParticipant = async (participantUuid) => {
        const removedClub = await removeClubFromTournament(participantUuid)

        if (removedClub) {
            setShowRemoveClubModal(false)
            handleGetClubsForTournament()
        }
    }

    return (
        <>
            <Card
                className={`club-card ${
                    isWhite ? 'white-club-card' : 'grey-club-card'
                } ${className}`}
            >
                <Card.Body className="py-0">
                    <Row>
                        <Col>
                            <Row className="car-row align-items-center">
                                <Col xs={5}>
                                    <h4>Klub</h4>
                                </Col>
                                <Col xs={7}>
                                    <div className="d-flex align-items-center justify-content-start">
                                        <div className="img-wrapper me-2">
                                            <img
                                                src={
                                                    club?.attributes?.club
                                                        ?.attributes?.logo?.data
                                                        ? formatImgUrl(
                                                              club?.attributes
                                                                  ?.club
                                                                  ?.attributes
                                                                  ?.logo?.data
                                                                  ?.attributes
                                                                  ?.url
                                                          )
                                                        : generateAvatar(
                                                              club?.attributes
                                                                  ?.club
                                                                  ?.attributes
                                                                  ?.name
                                                          )
                                                }
                                                alt={`Logo kluba`}
                                                width={'24px'}
                                                height={'24px'}
                                            />
                                        </div>
                                        <Link
                                            to={`/klubovi/${club?.attributes?.club?.attributes?.uuid}`}
                                        >
                                            <p className="text-elipsis link">
                                                {
                                                    club?.attributes?.club
                                                        ?.attributes?.name
                                                }
                                            </p>
                                        </Link>
                                    </div>{' '}
                                </Col>
                            </Row>

                            <Row className="car-row align-items-center">
                                <Col xs={5}>
                                    <h4>Region</h4>
                                </Col>
                                <Col
                                    xs={7}
                                    className="d-flex align-items-center"
                                >
                                    {club?.attributes?.club?.attributes?.region
                                        ? club?.attributes?.club?.attributes
                                              ?.region?.region_name
                                        : '-'}
                                </Col>
                            </Row>

                            <Row className="car-row align-items-center">
                                <Col xs={5}>
                                    <h4>Sedište</h4>
                                </Col>
                                <Col xs={7}>
                                    <p>
                                        {club?.attributes?.club?.attributes
                                            ?.address?.city
                                            ? club?.attributes?.club?.attributes
                                                  ?.address?.city
                                            : '-'}
                                    </p>
                                </Col>
                            </Row>

                            <Row className="car-row border-bottom-0 align-items-center">
                                <Col xs={5}>
                                    <h4>Takmičari</h4>
                                </Col>
                                <Col xs={7}>
                                    <p
                                        className="link"
                                        onClick={() =>
                                            setShowCompetitorsModal(true)
                                        }
                                    >
                                        Takmičari
                                    </p>
                                </Col>
                            </Row>

                            {enableRemove && (
                                <Row className="car-row border-bottom-0 align-items-center">
                                    <Col xs={5}>
                                        <h4>Ukloni</h4>
                                    </Col>
                                    <Col xs={7}>
                                        <p
                                            className="red-link"
                                            onClick={() =>
                                                setShowRemoveClubModal(true)
                                            }
                                        >
                                            Ukloni
                                        </p>
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Modal
                centered
                show={showCompetitorsModal}
                onHide={() => setShowCompetitorsModal(false)}
                dialogClassName="add-penalties-modal"
                fullscreen={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>Takmičari</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body></Modal.Body>
                <Modal.Footer>
                    <Button
                        type="text"
                        onClick={() => setShowCompetitorsModal(false)}
                    >
                        Odustani
                    </Button>
                    <Button
                        onClick={() => {}}
                        disabled={
                            selectedCompetitors?.length > 0 ? false : true
                        }
                    >
                        Potvrdi
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                size="md"
                centered
                show={showRemoveClubModal}
                onHide={() => setShowRemoveClubModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>Uklanjanje kluba</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Da li ste sigurni da želite da uklonite klub (
                        <Link
                            to={`/klubovi/${club?.attributes?.club?.attributes?.uuid}`}
                            className="link"
                        >
                            {club?.attributes?.club?.attributes?.name}
                        </Link>
                        ) sa ovog takmičenja?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="text"
                        onClick={() => setShowRemoveClubModal(false)}
                    >
                        Odustani
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            handleRemoveTournamentClubParticipant(
                                club?.attributes?.uuid
                            )
                        }}
                    >
                        Ukloni
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CompetitionClubsCardParticipants
