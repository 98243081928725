import jsPDF from 'jspdf'
import 'jspdf-autotable'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import Add from '../../assets/svg/Add'
import CloseOutlined from '../../assets/svg/CloseOutlined'
import DocumentTextOutlined from '../../assets/svg/DocumentTextOutlined'
import DownloadCloudOutlined from '../../assets/svg/DownloadCloudOutlined'
import PrintOutlined from '../../assets/svg/PrintOutlined'
import { font } from '../../containers/Admin/Competitions/CompetitionProfile/font'
import { useAppContext } from '../../lib/contextLib'
import { formatDate, formatImgUrl, isArrayEmpty } from '../../lib/helpers'
import ScrollToTop from '../../lib/hooks/ScrollToTop'
import Button from '../Button'

import { useParams } from 'react-router-dom'
import { createMedical, getMedicals, deleteMedical } from '../../lib/api'
import { downloadImage } from '../../utils/downloadimage'
import FileUploadTag from '../FileUploadTag'
import NoDataForView from '../NoDataForView'
import TextField from '../TextField'
import MedicalHistoryCard from './MedicalHistoryCard'
import moment from 'moment'
import ConfirmationModal from '../ConfirmationModal'
import './styles.scss'

const MedicalHistory = ({ profileData, setShowMedicalHistory }) => {
    const { id } = useParams()
    const { user, setShowCustomToast } = useAppContext()
    const [addModal, setAddModal] = useState(false)
    const [medicals, setMedicals] = useState([])
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    // Medical states

    const [medicalData, setMedicalData] = useState({
        start_date: '',
        end_date: '',
        member_uuid: profileData?.attributes?.uuid,
    })
    const [medicalImg, setMedicalImg] = useState(null)
    const [medicalImgTitle, setMedicalImgTitle] = useState('')
    const inputRef = useRef(null)
    const [disabledAddMedical, setDisableAddMedical] = useState(true)
    // Medical states

    // Handle Medical Data Logic
    const addPhoto = (e) => {
        const fileReader = new FileReader()
        const file = e.target.files[0]
        setMedicalImgTitle((_) => {
            return file?.name
        })
        const fileSizeKilobytes = file.size / 1024
        if (checkSize(fileSizeKilobytes, 2048)) return
        else {
            fileReader.onload = () => {
                setMedicalImg((_) => {
                    return file
                })
            }
            fileReader.readAsDataURL(file)
        }
    }

    const checkSize = (fileSize, limit) => {
        if (fileSize > limit) {
            alert('Fajl je prevelik')
            return limit
        } else return
    }

    const handleOpenFileInput = () => {
        inputRef.current.click()
    }

    const updateMedicalField = (field, value) => {
        setMedicalData((prev) => ({ ...prev, [field]: value }))
    }

    const submitMedical = async () => {
        try {
            const { certificateData } = await createMedical(
                medicalData,
                medicalImg
            )

            if (certificateData) {
                handleCloseModal()
                setShowCustomToast({
                    show: true,
                    type: 'success',
                    message: 'Lekarski pregled uspešno produžen.',
                })
                handleGetUsersMedicals()
            }
        } catch (error) {
            setShowCustomToast({
                show: true,
                type: 'error',
                message: 'Došlo je do greške.',
            })
        }
    }
    // Handle Medical Data Logic

    const calculateEndDate = (startDate) => {
        const endDate = moment(startDate).add(6, 'months')
        return endDate.format('YYYY-MM-DD')
    }

    const updateStartDate = (newStartDate) => {
        updateMedicalField('start_date', newStartDate)

        const newEndDate = calculateEndDate(newStartDate)
        updateMedicalField('end_date', newEndDate)
    }

    // Handle PDF Logic
    const handlePdfExport = (print = false) => {
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'portrait' // portrait or landscape

        const marginLeft = 40
        const doc = new jsPDF(orientation, unit, size)
        doc.addFileToVFS('WorkSans-normal.ttf', font)
        doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
        doc.setFont('WorkSans')
        doc.setFontSize(15)

        const title = `${profileData?.attributes?.person_info?.given_name} ${
            profileData?.attributes?.person_info?.family_name
        } - Istorija lekarskih pregleda (${formatDate(Date.now())})`
        doc.text(title, marginLeft, 40)

        const rows = []
        medicals.forEach((medical) => {
            rows.push([
                formatDate(medical?.attributes?.start_date),
                formatDate(medical?.attributes?.end_date),
            ])
        })

        let positionContent = {
            startY: 50,
            head: [['Datum obavljenog pregleda', 'Lekarski pregled traje do']],
            body: rows,
            styles: {
                font: 'WorkSans',
            },
        }

        doc.autoTable(positionContent)

        if (print) {
            doc.autoPrint()
            var blob = doc.output('blob')
            window.open(URL.createObjectURL(blob), '_blank')
        } else {
            doc.save(
                `${profileData?.attributes?.person_info?.given_name}_${
                    profileData?.attributes?.person_info?.family_name
                }_Istorija_lekarskih_(${formatDate(Date.now())}).pdf`
            )
        }
    }

    const handleDeleteMedical = async (medicalUUID) => {
        try {
            const deletedMedical = await deleteMedical(medicalUUID)

            if (!deletedMedical) return
            setShowConfirmationModal((_) => {
                return false
            })
            handleGetUsersMedicals()
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (
            medicalImgTitle &&
            medicalData.start_date &&
            medicalData.end_date &&
            medicalImg
        ) {
            setDisableAddMedical(false)
        } else {
            setDisableAddMedical(true)
        }
    }, [medicalData, medicalImg, medicalImgTitle])

    const handleCloseModal = () => {
        setAddModal(false)
        setMedicalImg(null)
        setMedicalImgTitle('')
        setMedicalData({
            start_date: '',
            end_date: '',
            member_uuid: profileData?.attributes?.uuid,
        })
    }

    const handleGetUsersMedicals = async () => {
        const medicalsData = await getMedicals(profileData?.attributes?.uuid)
        if (medicalsData?.length > 0) {
            setMedicals(medicalsData)
        } else {
            setMedicals([])
        }
    }

    useEffect(() => {
        handleGetUsersMedicals()
    }, [id, profileData])

    return (
        <>
            <Row className="medical-history-view">
                <ScrollToTop />
                <Col className="medical-history-wrapper">
                    <Row>
                        <Col className="d-flex align-items-center justify-content-end close-icon">
                            <CloseOutlined
                                onClick={() => setShowMedicalHistory(false)}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-center flex-column">
                        <Col className="d-flex align-items-center justify-content-center medical-history-title">
                            <h1>Istorija lekarskih pregleda</h1>
                        </Col>

                        {!isArrayEmpty(medicals) ? (
                            <Col
                                xs={12}
                                className="d-flex flex-column align-items-center justify-content-center align-self-center medical-history-container"
                            >
                                <Row className="w-100">
                                    {user?.role?.type === 'admin' && (
                                        <Col
                                            xs={12}
                                            className="add-medical-col d-md-flex d-none align-items-center justify-content-end py-1"
                                        >
                                            <Button
                                                type="text"
                                                leadingIcon={<Add />}
                                                onClick={() =>
                                                    setAddModal(true)
                                                }
                                            >
                                                Produži
                                            </Button>
                                        </Col>
                                    )}
                                    <Col
                                        xs={12}
                                        className={`add-medical-col d-flex d-md-none align-items-center ${
                                            user?.role?.type === 'admin'
                                                ? 'justify-content-between'
                                                : 'justify-content-around'
                                        } gx-2`}
                                    >
                                        <Button
                                            type="text"
                                            onClick={() =>
                                                handlePdfExport(true)
                                            }
                                        >
                                            <PrintOutlined height="24px" />
                                        </Button>
                                        <Button
                                            type="text"
                                            onClick={() => handlePdfExport()}
                                        >
                                            <DocumentTextOutlined height="24px" />
                                        </Button>
                                        {user?.role?.type === 'admin' && (
                                            <Button
                                                type="text"
                                                onClick={() =>
                                                    setAddModal(true)
                                                }
                                            >
                                                <Add height="24px" />
                                            </Button>
                                        )}
                                    </Col>
                                    <Col
                                        xs={12}
                                        className={`d-md-flex d-none align-items-center justify-content-between single-medical px-0`}
                                    >
                                        <Col className="d-flex justify-content-center text-center">
                                            <h5 className={`medical-header`}>
                                                Datum obavljenog pregleda
                                            </h5>
                                        </Col>
                                        <Col className="d-flex justify-content-center">
                                            <h5 className={`medical-header`}>
                                                Lekarski pregled traje do
                                            </h5>
                                        </Col>
                                        <Col className="d-flex justify-content-center">
                                            <h5 className={`medical-header`}>
                                                Slika
                                            </h5>
                                        </Col>
                                        {user?.role?.type === 'admin' && (
                                            <Col className="d-flex justify-content-center"></Col>
                                        )}
                                    </Col>
                                </Row>
                                <div className="medical-history-list">
                                    {medicals?.map((medical, index) => (
                                        <Fragment key={index}>
                                            <Col
                                                className={`d-md-flex d-none align-items-center justify-content-between single-medical`}
                                            >
                                                <Col className="d-flex justify-content-center">
                                                    <p className="medical-info-text">
                                                        {formatDate(
                                                            medical?.attributes
                                                                ?.start_date
                                                        )}
                                                    </p>
                                                </Col>
                                                <Col className="d-flex justify-content-center">
                                                    <p className="medical-info-text">
                                                        {formatDate(
                                                            medical?.attributes
                                                                ?.end_date
                                                        )}
                                                    </p>
                                                </Col>
                                                <Col className="d-flex justify-content-center">
                                                    <Button
                                                        type="text"
                                                        onClick={() =>
                                                            downloadImage(
                                                                formatImgUrl(
                                                                    medical
                                                                        ?.attributes
                                                                        ?.attachment
                                                                        ?.data
                                                                        ?.attributes
                                                                        ?.url
                                                                ),
                                                                `${profileData?.attributes?.person_info?.given_name}_${profileData?.attributes?.person_info?.family_name}_medicalID_${medical?.id}`,
                                                                medical
                                                                    ?.attributes
                                                                    ?.attachment
                                                                    ?.data
                                                                    ?.attributes
                                                                    ?.ext
                                                            )
                                                        }
                                                    >
                                                        <DownloadCloudOutlined
                                                            width="24px"
                                                            height="24px"
                                                        />
                                                    </Button>
                                                </Col>

                                                {user?.role?.type ===
                                                    'admin' && (
                                                    <Col className="d-flex justify-content-center">
                                                        <p
                                                            className="red-link"
                                                            onClick={() =>
                                                                setShowConfirmationModal(
                                                                    (_) => {
                                                                        return medical
                                                                            ?.attributes
                                                                            ?.uuid
                                                                    }
                                                                )
                                                            }
                                                        >
                                                            Ukloni
                                                        </p>
                                                    </Col>
                                                )}
                                            </Col>
                                            <MedicalHistoryCard
                                                isWhite={
                                                    index % 2 !== 0
                                                        ? true
                                                        : false
                                                }
                                                medical={medical}
                                                profileData={profileData}
                                                className="d-md-none"
                                            />
                                        </Fragment>
                                    ))}
                                </div>
                                <Col className="d-md-flex d-none align-items-center justify-content-end medical-history-buttons-wrapper px-0">
                                    <Button
                                        type="text"
                                        onClick={() => handlePdfExport()}
                                        className="me-2"
                                    >
                                        Eksportuj u PDF
                                    </Button>

                                    <Button
                                        type="secondary"
                                        onClick={() => handlePdfExport(true)}
                                    >
                                        Štampaj
                                    </Button>
                                </Col>
                            </Col>
                        ) : (
                            <NoDataForView
                                title="Istorija lekarskih pregleda je prazna"
                                description={
                                    user?.role?.type === 'admin'
                                        ? 'Produžite lekarski pregled klikom na dugme ispod.'
                                        : ''
                                }
                                buttonText={
                                    user?.role?.type === 'admin'
                                        ? 'Produži'
                                        : null
                                }
                                onClick={() =>
                                    user?.role?.type === 'admin'
                                        ? setAddModal(true)
                                        : null
                                }
                                className="border-0"
                                column
                            />
                        )}
                    </Row>
                </Col>
            </Row>
            {/* MODAL -  LEKARSKO */}
            <Modal size="md" centered show={addModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>Produži Lekarski Pregled</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TextField
                        type="date"
                        label="Datum obavljenog pregleda"
                        className="mb-4"
                        value={medicalData?.start_date}
                        onChange={(e) => updateStartDate(e.currentTarget.value)}
                        required
                    />
                    <TextField
                        type="date"
                        label="Lekarski pregled važi do"
                        value={medicalData?.end_date}
                        min={medicalData?.start_date}
                        onChange={(e) =>
                            updateMedicalField(
                                'end_date',
                                e.currentTarget.value
                            )
                        }
                        required
                    />

                    {medicalImg && (
                        <FileUploadTag
                            className="mt-3"
                            fileName={medicalImgTitle}
                            deleteFile={() => {
                                setMedicalImg((_) => {
                                    return null
                                })
                                setMedicalImgTitle((_) => {
                                    return ''
                                })
                                inputRef.current.value = null
                            }}
                        />
                    )}

                    <span className="mt-4 mb-4 d-flex">
                        <p>Uploaduj sliku ili sken lekarskog pregleda</p>
                        <h6 className="required-asterisk ms-1">*</h6>
                    </span>

                    <label onClick={handleOpenFileInput} className="w-100">
                        <Button
                            type="secondary"
                            className="w-100"
                            disabled={medicalImg ? true : false}
                        >
                            Uploaduj lekarski pregled
                        </Button>
                    </label>
                    <form>
                        <input
                            ref={inputRef}
                            type="file"
                            id="medicalImage"
                            name="medicalImage"
                            className="d-none invisible"
                            onChange={(e) => addPhoto(e)}
                            accept="image/jpg, image/jpeg, image/png"
                            disabled={medicalImg ? true : false}
                        />
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="text" onClick={handleCloseModal}>
                        Odustani
                    </Button>
                    <Button
                        onClick={submitMedical}
                        disabled={disabledAddMedical}
                    >
                        Produži
                    </Button>
                </Modal.Footer>
            </Modal>

            <ConfirmationModal
                show={showConfirmationModal}
                modalText="Jeste li sigurni da želite obrisati lekarsko uverenje?"
                onHide={() =>
                    setShowConfirmationModal((_) => {
                        return false
                    })
                }
                onClick={() => handleDeleteMedical(showConfirmationModal)}
            />
        </>
    )
}

export default MedicalHistory
