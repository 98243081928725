import React, { useEffect, useState } from 'react'
import { Card, Col, Modal, Row } from 'react-bootstrap'
import Trash from '../../assets/svg/Trash'
import { addPlayersPenalties, deletePenalty, getPenalties } from '../../lib/api'
import { useAppContext } from '../../lib/contextLib'
import Button from '../Button'
import ConfirmationModal from '../ConfirmationModal'
import MultiSelectSearch from '../MultiSelectSearch'

const MatchPenaltiesModal = ({
    show,
    onHide,
    sideOneData,
    sideTwoData,
    matchUuid,
    matchPenalties,
    handleGetSingleMatch,
}) => {
    const { user, setIsLoading } = useAppContext()
    const [penaltyData, setPenaltyData] = useState({})
    const [disableAdd, setDisableAdd] = useState(true)
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false)
    const [selectedPlayer, setSelectedPlayer] = useState(null)
    const [selectedPenalty, setSelectedPenalty] = useState(null)

    //* Codebooks
    const [matchPenaltyTypes, setMatchPenaltyTypes] = useState([])

    const handleCodebooks = async () => {
        try {
            setIsLoading(true)
            const matchPenaltiesPromise = getPenalties({
                query: 'filters[penalty_type]=Match',
            })

            const [matchPenaltiesOptions] = await Promise.all([
                matchPenaltiesPromise,
            ])

            if (matchPenaltiesOptions) {
                setMatchPenaltyTypes(matchPenaltiesOptions)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    const resetSelection = () => {
        setSelectedPenalty(null)
        setSelectedPlayer(null)
    }

    const handlePlayersOptions = () => {
        const playersList = []

        playersList?.push({
            uuid: sideOneData?.player_1?.attributes?.uuid,
            value: `${sideOneData?.player_1?.attributes?.person_info?.given_name} ${sideOneData?.player_1?.attributes?.person_info?.family_name}`,
        })

        sideOneData?.player_2 &&
            playersList?.push({
                uuid: sideOneData?.player_2?.attributes?.uuid,
                value: `${sideOneData?.player_2?.attributes?.person_info?.given_name} ${sideOneData?.player_2?.attributes?.person_info?.family_name}`,
            })

        playersList?.push({
            uuid: sideTwoData?.player_1?.attributes?.uuid,
            value: `${sideTwoData?.player_1?.attributes?.person_info?.given_name} ${sideTwoData?.player_1?.attributes?.person_info?.family_name}`,
        })

        sideTwoData?.player_2 &&
            playersList?.push({
                uuid: sideTwoData?.player_2?.attributes?.uuid,
                value: `${sideTwoData?.player_2?.attributes?.person_info?.given_name} ${sideTwoData?.player_2?.attributes?.person_info?.family_name}`,
            })

        return playersList
    }

    const handleSubmitPenalty = async () => {
        try {
            const newPenalty = await addPlayersPenalties(penaltyData)

            if (newPenalty) {
                handleGetSingleMatch()
                resetSelection()
            }
        } catch (error) {
            throw new Error(error)
        }
    }

    const handleDeletePenalty = async (penaltyUuid) => {
        try {
            const deletedPenalty = await deletePenalty(penaltyUuid)

            if (deletedPenalty) {
                setShowDeleteConfirmModal(false)
                handleGetSingleMatch()
            }
        } catch (error) {
            throw new Error(error)
        }
    }

    const handleRenderingPenaltiesList = () => {
        return matchPenalties?.map((penalty) => {
            const player = handlePlayersOptions()?.find(
                (playerItem) =>
                    playerItem?.uuid === penalty?.attributes?.player_uuid
            )
            return (
                <Row key={penalty?.attributes?.uuid} className="mt-2">
                    <Col>
                        <Card>
                            <Card.Body className="d-flex aling-items-center justify-content-between">
                                <div>
                                    <h5>{player?.value}</h5>
                                    <p>{penalty?.attributes?.penalty_name}</p>
                                </div>
                                {user?.role?.type !== 'competitor' && (
                                    <Button
                                        type="text"
                                        onClick={() =>
                                            setShowDeleteConfirmModal(
                                                penalty?.attributes?.uuid
                                            )
                                        }
                                    >
                                        <Trash />
                                    </Button>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            )
        })
    }

    const handleSelectMatchPenalty = (e) => {
        const selectedPenalty = matchPenaltyTypes?.find(
            (penalty) => penalty?.attributes?.penalty_name === e
        )
        setPenaltyData((prev) => ({
            ...prev,
            penalty_name: e,
            penalty_type: 'Match',
            match_uuid: matchUuid,
            penalty_points: selectedPenalty?.attributes?.penalty_points || 0,
        }))
    }

    useEffect(() => {
        show && handleCodebooks()
    }, [show])

    useEffect(() => {
        if (selectedPenalty && selectedPlayer) {
            setDisableAdd(false)
        } else {
            setDisableAdd(true)
        }
    }, [selectedPenalty, selectedPlayer])

    return (
        <>
            <Modal
                size="lg"
                centered
                show={show}
                onHide={() => {
                    onHide()
                    resetSelection()
                }}
                dialogClassName="create-draw-pair-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>Kazne sa meča</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {user?.role?.type !== 'competitor' && (
                        <>
                            <Row>
                                <Col>
                                    <MultiSelectSearch
                                        label={'Igrač'}
                                        animated
                                        options={handlePlayersOptions()}
                                        noOptionsMessage={'Nema rezultata'}
                                        onInputChange={(e) => {}}
                                        onChange={(e) => {
                                            setPenaltyData((prev) => ({
                                                ...prev,
                                                player_uuid: e.uuid,
                                                penalty_type: 'Match',
                                                match_uuid: matchUuid,
                                            }))
                                            setSelectedPlayer(e)
                                        }}
                                        placeholder={
                                            'Pretraži i izaberi igrača...'
                                        }
                                        isMulti={false}
                                        value={selectedPlayer || null}
                                        required
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <MultiSelectSearch
                                        label={'Kazna'}
                                        animated
                                        options={matchPenaltyTypes?.map(
                                            (penaltyType) => {
                                                return {
                                                    value: penaltyType
                                                        ?.attributes
                                                        ?.penalty_name,
                                                }
                                            }
                                        )}
                                        noOptionsMessage={'Nema rezultata'}
                                        onInputChange={(e) => {}}
                                        onChange={(e) => {
                                            handleSelectMatchPenalty(e?.value)
                                            setSelectedPenalty(e)
                                        }}
                                        placeholder={
                                            'Pretraži i izaberi kaznu...'
                                        }
                                        isMulti={false}
                                        value={selectedPenalty || null}
                                        required
                                    />
                                </Col>
                            </Row>
                        </>
                    )}

                    {matchPenalties?.length > 0 && (
                        <Row className="mt-4 mb-2">
                            <Col>
                                <h4>Dodate kazne</h4>

                                {handleRenderingPenaltiesList()}
                            </Col>
                        </Row>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button type="text" onClick={onHide}>
                        Zatvori
                    </Button>
                    {user?.role?.type !== 'competitor' && (
                        <Button
                            onClick={() => handleSubmitPenalty()}
                            disabled={disableAdd}
                        >
                            Dodaj kaznu
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
            <ConfirmationModal
                modalText="Jeste li sigurni da želite obrisati kaznu sa meča?"
                show={showDeleteConfirmModal}
                onClick={() => {
                    handleDeletePenalty(showDeleteConfirmModal)
                }}
                onHide={() => {
                    setShowDeleteConfirmModal(false)
                }}
            />
        </>
    )
}

export default MatchPenaltiesModal
