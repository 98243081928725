import { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import RadioButton from '../../../../../components/RadioButton'
import TextField from '../../../../../components/TextField'
import { getCodebook } from '../../../../../lib/api'

import { useAppContext } from '../../../../../lib/contextLib'
import './styles.scss'

const LicenceInfo = ({
    setProgress,
    licenses,
    setLicenseField,
    setLicenses,
}) => {
    const { id } = useParams()
    const { setIsLoading } = useAppContext()

    // Implementing codebooks

    const [nationalLicense, setNationalLicense] = useState([])
    const [coachUSPTA, setCoachUSPTA] = useState([])
    const [coachPTR, setCoachPTR] = useState([])
    const [coachLTA, setCoachLTA] = useState([])
    const [coachITF, setCoachITF] = useState([])
    const [coachGPTCA, setCoachGPTCA] = useState([])

    const handleCodebooks = async () => {
        setIsLoading(true)
        await getCodebook('codebooks.coach-uspta-license', setCoachUSPTA)
        await getCodebook(
            'codebooks.coach-national-license',
            setNationalLicense
        )
        await getCodebook('codebooks.coach-ptr-license', setCoachPTR)
        await getCodebook('codebooks.coach-lta-license', setCoachLTA)
        await getCodebook('codebooks.coach-itf-license', setCoachITF)
        await getCodebook('codebooks.coach-gptca-license', setCoachGPTCA)
        setIsLoading(false)
    }

    useEffect(() => {
        setProgress(100)
        handleCodebooks()
    }, [])

    return (
        <Row className="basic-info-wrapper">
            {}
            <Col md={12} className="mt-3">
                <RadioButton
                    name="Nacionalna licenca"
                    radioValues={nationalLicense}
                    onClick={(e) => {
                        if (
                            licenses?.national_license &&
                            licenses?.national_license
                                ?.national_license_name === e.target.value
                        ) {
                            const updatedLicenses = { ...licenses }
                            id
                                ? (updatedLicenses.national_license = null)
                                : delete updatedLicenses.national_license
                            setLicenses(updatedLicenses)
                        } else {
                            null
                        }
                    }}
                    onChange={(e) => {
                        setLicenseField('national_license', e.target.value)
                    }}
                    checkedValue={
                        licenses?.national_license?.national_license_name || ''
                    }
                />

                <TextField
                    className="mt-3"
                    label="Datum isticanja nacionalne licence"
                    onChange={(e) => {
                        setLicenses((prev) => ({
                            ...prev,
                            national_license: {
                                ...prev?.national_license,
                                license_expiration_date: e.target.value,
                            },
                        }))
                    }}
                    type="date"
                    placeholder="DD-MM-YYYY"
                    value={
                        licenses?.national_license?.license_expiration_date ||
                        ''
                    }
                />
            </Col>
            <Col md={12} className="mt-3">
                <h5>Medjunarodna licenca</h5>
            </Col>
            <Col md={12} className="mt-3">
                <RadioButton
                    name="ITF"
                    radioValues={coachITF}
                    onClick={(e) => {
                        if (
                            licenses?.itf_license &&
                            licenses?.itf_license?.itf_license_name ===
                                e.target.value
                        ) {
                            const updatedLicenses = { ...licenses }
                            id
                                ? (updatedLicenses.itf_license = null)
                                : delete updatedLicenses.itf_license
                            setLicenses(updatedLicenses)
                        } else {
                            null
                        }
                    }}
                    onChange={(e) => {
                        setLicenseField('itf_license', e.target.value)
                    }}
                    checkedValue={licenses?.itf_license?.itf_license_name || ''}
                />
            </Col>
            <Col md={12} className="mt-3">
                <RadioButton
                    name="GPTCA"
                    radioValues={coachGPTCA}
                    onClick={(e) => {
                        if (
                            licenses?.gptca_license &&
                            licenses?.gptca_license?.gptca_license_name ===
                                e.target.value
                        ) {
                            const updatedLicenses = { ...licenses }
                            id
                                ? (updatedLicenses.gptca_license = null)
                                : delete updatedLicenses.gptca_license
                            setLicenses(updatedLicenses)
                        } else {
                            null
                        }
                    }}
                    onChange={(e) => {
                        setLicenseField('gptca_license', e.target.value)
                    }}
                    checkedValue={
                        licenses?.gptca_license?.gptca_license_name || ''
                    }
                />
            </Col>
            <Col md={12} className="mt-3">
                <RadioButton
                    name="USPTA"
                    radioValues={coachUSPTA}
                    onClick={(e) => {
                        if (
                            licenses?.uspta_license &&
                            licenses?.uspta_license?.uspta_license_name ===
                                e.target.value
                        ) {
                            const updatedLicenses = { ...licenses }
                            id
                                ? (updatedLicenses.uspta_license = null)
                                : delete updatedLicenses.uspta_license
                            setLicenses(updatedLicenses)
                        } else {
                            null
                        }
                    }}
                    onChange={(e) => {
                        setLicenseField('uspta_license', e.target.value)
                    }}
                    checkedValue={
                        licenses?.uspta_license?.uspta_license_name || ''
                    }
                />
            </Col>
            <Col md={12} className="mt-3">
                <RadioButton
                    name="LTA"
                    radioValues={coachLTA}
                    onClick={(e) => {
                        if (
                            licenses?.lta_license &&
                            licenses?.lta_license?.lta_license_name ===
                                e.target.value
                        ) {
                            const updatedLicenses = { ...licenses }
                            id
                                ? (updatedLicenses.lta_license = null)
                                : delete updatedLicenses.lta_license
                            setLicenses(updatedLicenses)
                        } else {
                            null
                        }
                    }}
                    onChange={(e) => {
                        setLicenseField('lta_license', e.target.value)
                    }}
                    checkedValue={licenses?.lta_license?.lta_license_name || ''}
                />
            </Col>
            <Col md={12} className="mt-3">
                <RadioButton
                    name="PTR"
                    radioValues={coachPTR}
                    onClick={(e) => {
                        if (
                            licenses?.ptr_license &&
                            licenses?.ptr_license?.ptr_license_name ===
                                e.target.value
                        ) {
                            const updatedLicenses = { ...licenses }
                            id
                                ? (updatedLicenses.ptr_license = null)
                                : delete updatedLicenses.ptr_license
                            setLicenses(updatedLicenses)
                        } else {
                            null
                        }
                    }}
                    onChange={(e) => {
                        setLicenseField('ptr_license', e.target.value)
                    }}
                    checkedValue={licenses?.ptr_license?.ptr_license_name || ''}
                />
            </Col>
        </Row>
    )
}

export default LicenceInfo
