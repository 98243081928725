import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { getCodebook } from '../../../lib/api'
import { formatDateExport } from '../../../lib/helpers'
import Dropdown from '../../Dropdown'
import MultiSelectSearch from '../../MultiSelectSearch'
import TextField from '../../TextField'

const StepTwo = ({
    newClub,
    updateClub,
    setNewClub,
    selectedCity,
    setSelectedCity,
    availableCites,
    setAvailableCities,
}) => {
    const [regions, setRegions] = useState([])

    const handleCodebook = async () => {
        await getCodebook('codebooks.region', setRegions)
    }

    useEffect(() => {
        handleCodebook()
    }, [])

    const handleCity = (cityName) => {
        if (cityName?.length > 2) {
            axios
                .get(`${process.env.REACT_APP_GEONAMES_URL}/searchJSON`, {
                    params: {
                        name_startsWith: cityName?.toString(),
                        featureClass: 'P',
                        country: 'RS,XK', // Serbia as country preselected for clubs
                        maxRows: 5,
                        username: `${process.env.REACT_APP_GEONAMES_USERNAME}`,
                    },
                })
                .then((response) => {
                    const cityOptions = []
                    response.data?.geonames?.map((city) => {
                        cityOptions?.push({
                            value: `${city?.name}`,
                            name: city?.name,
                        })
                    })
                    setAvailableCities(cityOptions)
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {
            setAvailableCities([])
        }
    }

    const handleSelectCity = (cityName) => {
        if (!cityName) {
            setSelectedCity((_) => {
                return ''
            })
            setNewClub((prev) => {
                return {
                    ...prev,
                    address: {
                        ...prev?.address,
                        city: '',
                    },
                }
            })
        } else {
            setNewClub((prev) => {
                return {
                    ...prev,
                    address: {
                        ...prev?.address,
                        city: cityName?.name,
                    },
                }
            })
            availableCites.map((city) => {
                if (city?.value === cityName?.value) {
                    setSelectedCity((_) => {
                        return city
                    })
                }
            })
        }
    }

    return (
        <Row className="step-two-wrapper">
            <form>
                <Col md={12}>
                    {/* <MultiSelectSearch
                        label={'Sedište'}
                        options={citiesOfSerbia}
                        animated
                        placeholder={
                            newClub.city
                                ? newClub.city
                                : 'Pretraži i izaberi grad...'
                        }
                        noOptionsMessage={'Nema više gradova'}
                        closeMenuOnSelect={true}
                        onChange={(e) =>
                            setNewClub({ ...newClub, city: e.value })
                        }
                        value={newClub.city}
                        isMulti={false}
                        required
                    /> */}
                    {/* <TextField
                        placeholder="Grad"
                        name="country"
                        type="text"
                        value={newClub.city || ''}
                        onChange={(e) => {
                            setNewClub({ ...newClub, city: e.target.value })
                        }}
                        label="Sedište"
                        required
                    /> */}
                    <MultiSelectSearch
                        label={'Sedište'}
                        animated
                        options={availableCites}
                        noOptionsMessage={'Nema rezultata'}
                        onInputChange={(e) => handleCity(e)}
                        onChange={(e) => handleSelectCity(e)}
                        placeholder={'Pretraži i izaberi grad...'}
                        isMulti={false}
                        value={selectedCity}
                        isClearable={true}
                    />
                </Col>
                <Col md={12} className="mt-3">
                    <TextField
                        id="address"
                        name="address"
                        label="Ulica i broj"
                        placeholder="Ulica i broj"
                        onChange={(e) =>
                            setNewClub((prev) => {
                                return {
                                    ...prev,
                                    address: {
                                        ...prev?.address,
                                        street: e.target.value,
                                    },
                                }
                            })
                        }
                        value={newClub?.address?.street || ''}
                    />
                </Col>
                <Col md={12} className="mt-3">
                    <Dropdown
                        placeholder="Region"
                        label="Region"
                        value={newClub?.region?.region_name || ''}
                        handleSelect={(e) =>
                            setNewClub((prev) => {
                                return {
                                    ...prev,
                                    region: { ...prev?.region, region_name: e },
                                }
                            })
                        }
                        dropdownLinks={regions}
                        required
                    />
                </Col>
                <Col md={12} className="mt-3">
                    <TextField
                        id="establishment_date"
                        name="establishment_date"
                        label="Datum osnivanja"
                        onChange={updateClub}
                        type="date"
                        placeholder="DD-MM-YYYY"
                        value={newClub?.establishment_date || ''}
                        max={formatDateExport(Date.now())}
                        required
                    />
                </Col>
            </form>
        </Row>
    )
}

export default StepTwo
