import React from 'react'
import { Row, Col } from 'react-bootstrap'
import NoPlayerImg from '../../assets/images/tennis-player-with-racket.png'
import { Link } from 'react-router-dom'
import Avatar from '../Avatar'
import Pill from '../Pill'
import TrophyOutline from '../../assets/svg/TrophyOutline'
import { formatImgUrl } from '../../lib/helpers'
import './styles.scss'

const Club = ({ data, matchData, participantUuid }) => {
    const clubAttributes = data?.attributes

    return (
        <Col className="py-2 d-flex align-items-center">
            <Avatar
                img={
                    formatImgUrl(clubAttributes?.logo?.data?.attributes?.url) ||
                    NoPlayerImg
                }
                alt="logo kluba"
                className="me-2"
            />
            {clubAttributes || participantUuid === '-1' ? (
                <div className="d-flex align-items-center justify-content-between w-100">
                    <Link
                        to={
                            participantUuid === '-1'
                                ? null
                                : `/klubovi/${clubAttributes?.uuid}`
                        }
                        className={participantUuid !== '-1' ? 'link' : ''}
                    >
                        {participantUuid === '-1'
                            ? 'BYE'
                            : `${clubAttributes?.name} (${clubAttributes?.short_name})`}
                    </Link>

                    <div className="d-flex">
                        {matchData?.match_host_uuid === participantUuid && (
                            <Pill type="default">
                                <p className="small-text">Domaćin</p>
                            </Pill>
                        )}

                        {matchData?.loser_participant_uuid &&
                            matchData?.loser_participant_uuid !==
                                participantUuid && (
                                <Pill type="active" className="ms-1">
                                    <TrophyOutline />
                                </Pill>
                            )}
                    </div>
                </div>
            ) : (
                <p>Nepoznat</p>
            )}
        </Col>
    )
}

const CardParticipantSection = ({ participantData, matchData }) => {
    const { club, participant_uuid } = participantData || {}

    return (
        <Row className="tournament-match-card-row flex-column">
            <Club
                data={club}
                matchData={matchData}
                participantUuid={participant_uuid}
            />
        </Row>
    )
}

export default CardParticipantSection
