import { Card, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import './styles.scss'

const CompetitorsCategoryCard = ({ isWhite, list, path }) => {
    return (
        <Card
            className={`club-card ${
                isWhite ? 'white-club-card' : 'grey-club-card'
            }`}
        >
            <Card.Body className="py-0">
                <Row>
                    <Col>
                        <Row className="car-row">
                            <Col xs={5}>
                                <h5>Naziv</h5>
                            </Col>
                            <Col xs={7}>
                                <p>
                                    {list?.attributes?.name
                                        ? list?.attributes?.name
                                        : '-'}
                                </p>
                            </Col>
                        </Row>
                        <Row className="car-row">
                            <Col xs={5}>
                                <h5>Konkurencija</h5>
                            </Col>
                            <Col xs={7}>
                                <p>
                                    {list?.attributes?.players_competition
                                        ?.players_competition_name
                                        ? list?.attributes?.players_competition
                                              ?.players_competition_name
                                        : '-'}
                                </p>
                            </Col>
                        </Row>
                        <Row className="car-row">
                            <Col xs={5}>
                                <h5>Takmičarska legitimacija</h5>
                            </Col>
                            <Col xs={7}>
                                <p>
                                    {list?.attributes?.players_competition
                                        ?.players_competition_subcategory_name
                                        ? list?.attributes?.players_competition
                                              ?.players_competition_subcategory_name
                                        : '-'}
                                </p>
                            </Col>
                        </Row>
                        <Row className="car-row">
                            <Col xs={5}>
                                <h5>Pol</h5>
                            </Col>
                            <Col xs={7}>
                                <p>
                                    {list?.attributes?.gender?.gender_name
                                        ? list?.attributes?.gender?.gender_name
                                        : '-'}
                                </p>
                            </Col>
                        </Row>

                        <Row className="car-row border-bottom-0">
                            <Col>
                                <Link className="link" to={path}>
                                    <p className="link">Pregled</p>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default CompetitorsCategoryCard
