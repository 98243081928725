import { useEffect, useState } from 'react'
import { Card, Col, Form, Modal, Row } from 'react-bootstrap'
import AsyncSelect from '../../../../../../components/AsyncSelect'
import Button from '../../../../../../components/Button'
import CompetitionClubsCardParticipants from '../../../../../../components/CompetitionClubsCardParticipants'
import CompetitionClubsParticipants from '../../../../../../components/CompetitionClubsParticipants'
import CompetitionsParticipantsTable from '../../../../../../components/CompetitionParticipantsTable'
import CompetitionsParticipantsCard from '../../../../../../components/CompetitionsTableCards/CompetitionsParticipantsCard'
import { RowsPerPageOptions } from '../../../../../../components/RowsPerPageDropdown'
import {
    addClubOnTurnament,
    addPlayerOnTurnament,
    calculateSeed,
    getClubsForTournament,
    getNumberOfPlayersByStatus,
    getPlayersForTournament,
    getPlayersRank,
    getSingleClub,
    updateTournamentPlayerParticipant,
    calcualteParticipantsPointsOnTournament,
} from '../../../../../../lib/api'
import { useAppContext } from '../../../../../../lib/contextLib'
import {
    getClubList,
    getPlayersList,
    handleGetPlayersForTournament,
} from '../../../../../../lib/helpers'
import DropDownSection from './dropdownSection'
import ModalAfterPointsSave from './modalAfterPointsSave'

const ParticipantsTab = ({
    competitionProfileData,
    activeTab,
    playersForTournamentList,
    setPlayersForTournamentList,
    participantsToShow,
    setParticipantsToShow,
    allPlayersOnTournament,
    setAllPlayersOnTournament,
}) => {
    const { user, setIsLoading, setShowCustomToast } = useAppContext()
    const [showCompetitorsModal, setShowCompetitorsModal] = useState(false)
    const [showClubsModal, setShowClubsModal] = useState(false)
    const [selectedClubs, setSelectedClubs] = useState()
    const [selectedPlayer, setSelectedPlayer] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [total, setTotal] = useState(1)
    const [showSaveButton, setShowSaveButton] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState(RowsPerPageOptions[0])

    const [clubsForTournamentList, setClubsForTournamentList] = useState([])
    const [allClubsOnTournament, setAllClubsOnTournament] = useState([])
    const [showModalAfterSave, setShowModalAfterSave] = useState(false)
    const [isManualPointsChangeEnabled, setIsManualPointsChangeEnabled] =
        useState(false)
    const [disableButton, setDisableButton] = useState(false)
    const [numberOfWCPlayers, setNumberOfWCPlayers] = useState(0)
    const [numberOfQPlayers, setNumberOfQPlayers] = useState(0)
    const [numberOfDAPlayers, setNumberOfDAPlayers] = useState(0)
    const [numberOfWCQPlayers, setNumberOfWCQPlayers] = useState(0)
    const [numberOfLLPlayers, setNumberOfLLPlayers] = useState(0)

    const handleAutoSeed = async () => {
        const calculatedSeed = await calculateSeed({
            tournament_uuid: competitionProfileData?.attributes?.uuid,
            gender: participantsToShow?.gender,
        })

        if (calculatedSeed) {
            handleGetPlayersForTournamentWrapper()
        }
    }

    const fetchPlayerCounts = async (tournamentUUID, isDouble, gender) => {
        try {
            setIsLoading(true)
            const daPlayersPromise = getNumberOfPlayersByStatus(
                tournamentUUID,
                isDouble,
                gender,
                'DA'
            )

            const wcPlayersPromise = competitionProfileData?.attributes
                ?.wc_number
                ? getNumberOfPlayersByStatus(
                      tournamentUUID,
                      isDouble,
                      gender,
                      'WC'
                  )
                : null

            const qPlayersPromise = competitionProfileData?.attributes
                ?.number_of_qualified
                ? getNumberOfPlayersByStatus(
                      tournamentUUID,
                      isDouble,
                      gender,
                      'Q'
                  )
                : null

            const wcqPlayersPromise = competitionProfileData?.attributes
                ?.wc_qualification_number
                ? getNumberOfPlayersByStatus(
                      tournamentUUID,
                      isDouble,
                      gender,
                      'WCQ'
                  )
                : null

            const LLPlayersPromise = getNumberOfPlayersByStatus(
                tournamentUUID,
                isDouble,
                gender,
                'LL'
            )

            const [
                numberOfDAPlayersData,
                numberOfWCPlayersData,
                numberOfQPlayersData,
                numberOfWCQPlayersData,
                numberOfLLPlayersData,
            ] = await Promise.all([
                daPlayersPromise,
                wcPlayersPromise,
                qPlayersPromise,
                wcqPlayersPromise,
                LLPlayersPromise,
            ])

            setNumberOfDAPlayers(numberOfDAPlayersData)

            if (numberOfWCPlayersData !== null) {
                setNumberOfWCPlayers(numberOfWCPlayersData)
            }

            if (numberOfQPlayersData !== null) {
                setNumberOfQPlayers(numberOfQPlayersData)
            }

            if (numberOfWCQPlayersData !== null) {
                setNumberOfWCQPlayers(numberOfWCQPlayersData)
            }

            if (numberOfLLPlayersData !== null) {
                setNumberOfLLPlayers(numberOfLLPlayersData)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleGetPlayersForTournamentWrapper = async () => {
        try {
            if (competitionProfileData) {
                setIsLoading(true)
                setDisableButton(true)
                const tournamentUUID = competitionProfileData?.attributes?.uuid
                const isDouble = participantsToShow?.is_double
                const gender = participantsToShow?.gender
                if (tournamentUUID) {
                    setPlayersForTournamentList([])
                    setIsLoading(true)
                    const { updatedParticipantsList, pgCount, total } =
                        await handleGetPlayersForTournament(
                            {
                                tournament_uuid: tournamentUUID,
                                is_double: isDouble,
                                gender: gender,
                            },
                            currentPage,
                            participantsToShow,
                            false,
                            rowsPerPage
                        )
                    setIsLoading(true)
                    const { participants: allPlayers } =
                        await getPlayersForTournament(
                            null,
                            null,
                            {
                                tournament_uuid: tournamentUUID,
                                is_double: isDouble,
                                gender: gender,
                            },
                            1,
                            true
                        )

                    fetchPlayerCounts(tournamentUUID, isDouble, gender)
                    setIsLoading(true)
                    const updatedParticipantsListWithRank = await Promise.all(
                        updatedParticipantsList.map(async (participant) => {
                            const player1Rank = await getPlayersRank(
                                participant?.attributes?.player_1?.attributes
                                    ?.uuid,
                                competitionProfileData?.attributes
                                    ?.players_competition
                                    ?.players_competition_name,
                                competitionProfileData?.attributes
                                    ?.players_competition
                                    ?.players_competition_subcategory_name ||
                                    null
                            )

                            let player2Rank

                            if (
                                participant?.attributes?.player_2?.attributes
                                    ?.uuid
                            ) {
                                player2Rank = await getPlayersRank(
                                    participant?.attributes?.player_2
                                        ?.attributes?.uuid,
                                    competitionProfileData?.attributes
                                        ?.players_competition
                                        ?.players_competition_name,
                                    competitionProfileData?.attributes
                                        ?.players_competition
                                        ?.players_competition_subcategory_name ||
                                        null
                                )
                            }
                            return {
                                ...participant,
                                attributes: {
                                    ...participant.attributes,
                                    player_1_ranking_number:
                                        player1Rank?.attributes?.ranking_number,
                                    player_2_ranking_number:
                                        player2Rank?.attributes?.ranking_number,
                                },
                            }
                        })
                    )
                    setPageCount(pgCount)
                    setTotal(total)
                    setAllPlayersOnTournament(allPlayers)
                    setPlayersForTournamentList(updatedParticipantsListWithRank)
                }
                setDisableButton(false)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleGetPlayersForTournamentWithPoints = async () => {
        try {
            setIsLoading(true)
            const pointsCalculation =
                await calcualteParticipantsPointsOnTournament(
                    competitionProfileData?.attributes?.uuid
                )

            if (!pointsCalculation) return

            handleGetPlayersForTournamentWrapper()
            setShowCustomToast({
                show: true,
                type: 'success',
                message: 'Uspešno ažuriranje bodova učesnika.',
            })
        } catch (error) {
            console.error(error)
            setShowCustomToast({
                show: true,
                type: 'error',
                message: 'Došlo je do greške.',
            })
        } finally {
            setIsLoading(false)
        }
    }

    const handleSubmitSelectedPlayer = async () => {
        setDisableButton(true)
        const addedPlayer = await addPlayerOnTurnament(selectedPlayer)
        if (addedPlayer) {
            if (participantsToShow?.is_double !== true) {
                setShowCompetitorsModal(false)
                setSelectedPlayer(null)
                handleAutoSeed()
            } else {
                setShowCompetitorsModal(false)
                handleGetPlayersForTournamentWrapper()
                setSelectedPlayer(null)
            }
        }
    }

    const handleSubmitSelectedClub = async () => {
        setDisableButton(true)
        const addedClub = await addClubOnTurnament(selectedClubs)
        if (addedClub) {
            setShowClubsModal(false)
            handleGetClubsForTournament()
        }
    }

    const handleGetClubsForTournament = async () => {
        if (competitionProfileData) {
            setIsLoading(true)
            setDisableButton(true)
            const { participants, pgCount, total } =
                await getClubsForTournament(
                    null,
                    null,
                    {
                        tournament_uuid:
                            competitionProfileData?.attributes?.uuid,
                    },
                    currentPage,
                    rowsPerPage
                )
            const { participants: allClubs } = await getClubsForTournament(
                null,
                null,
                {
                    tournament_uuid: competitionProfileData?.attributes?.uuid,
                },
                currentPage,
                rowsPerPage,
                true
            )

            if (allClubs) {
                setAllClubsOnTournament(allClubs)
            }

            if (participants) {
                setPageCount(pgCount)
                setTotal(total)
                const participantsPromises = participants.map(
                    async (participant) => {
                        const club = await getSingleClub(
                            participant.attributes.club_uuid
                        )
                        return {
                            ...participant,
                            attributes: {
                                ...participant.attributes,
                                club,
                            },
                        }
                    }
                )

                const updatedParticipantsList = await Promise.all(
                    participantsPromises
                )
                setClubsForTournamentList(updatedParticipantsList)
            }

            setIsLoading(false)
            setDisableButton(false)
        }
    }

    const loadPlayers = async (inputValue, loadedOptions, paginationData) => {
        return getPlayersList(inputValue, loadedOptions, paginationData, {
            gender: participantsToShow?.gender,
            players_competition:
                competitionProfileData?.attributes?.players_competition
                    ?.players_competition_name,
            alreadySelectedPlayers: allPlayersOnTournament?.reduce(
                (accumulator, entry) => {
                    let tempList = []
                    if (entry?.attributes?.player_1_uuid) {
                        tempList.push(entry?.attributes?.player_1_uuid)
                    }

                    if (entry?.attributes?.player_2_uuid) {
                        tempList.push(entry?.attributes?.player_2_uuid)
                    }

                    return [...accumulator, ...tempList]
                },
                []
            ),
        })
    }

    const saveParticipantsPoints = async (updatedParticipantsList) => {
        const list = updatedParticipantsList || playersForTournamentList
        if (!list) {
            return
        }

        const updatePromises = list.map((participant) => {
            const uuid = participant?.attributes?.uuid
            const rankingPoints = participant?.attributes?.ranking_points

            if (!uuid || rankingPoints === undefined) {
                return Promise.resolve()
            }

            return updateTournamentPlayerParticipant(uuid, {
                ranking_points: rankingPoints,
            })
        })

        try {
            const updatesPoints = await Promise.all(updatePromises)

            if (updatesPoints) {
                setShowSaveButton(false)
                setIsManualPointsChangeEnabled(false)
                handleGetPlayersForTournamentWrapper()
            }
        } catch (error) {
            console.error('Failed to update participants:', error)
        }
    }

    const handlePlayerSelect = async (e) => {
        setSelectedPlayer((prev) => ({
            ...prev,
            tournament_uuid: competitionProfileData?.attributes?.uuid,
            player_1_uuid: e.uuid,
            gender: {
                gender_name: participantsToShow?.gender,
            },
        }))
    }

    useEffect(() => {
        if (activeTab === 'ucesnici') {
            if (
                competitionProfileData?.attributes?.tournament_type
                    ?.tournament_type_name === 'Ekipno'
            ) {
                setSelectedPlayer(null)
                handleGetClubsForTournament()
            } else {
                setSelectedPlayer(null)
                handleGetPlayersForTournamentWrapper()
            }
        }
    }, [
        competitionProfileData,
        currentPage,
        participantsToShow,
        activeTab,
        rowsPerPage,
    ])

    useEffect(() => {
        setParticipantsToShow((prev) => ({
            ...prev,
            gender: competitionProfileData?.attributes?.genders[0]?.gender_name,
        }))
    }, [competitionProfileData])

    useEffect(() => {
        setCurrentPage(1)
    }, [participantsToShow])

    const loadClubs = async (inputValue, loadedOptions, paginationData) => {
        return getClubList(inputValue, loadedOptions, paginationData, {
            alreadySelectedClubs: allClubsOnTournament?.reduce(
                (accumulator, entry) => {
                    let tempList = []

                    if (entry?.attributes?.club_uuid) {
                        tempList.push(entry?.attributes?.club_uuid)
                    }

                    return [...accumulator, ...tempList]
                },
                []
            ),
        })
    }

    return (
        <Row>
            <Col>
                <Row>
                    <Col xs={12} className="mt-3">
                        <DropDownSection
                            participantsToShow={participantsToShow}
                            setParticipantsToShow={setParticipantsToShow}
                            competitionProfileData={competitionProfileData}
                            handleGetPlayersForTournament={
                                handleGetPlayersForTournamentWrapper
                            }
                            handleGetPlayersForTournamentWithPoints={
                                handleGetPlayersForTournamentWithPoints
                            }
                            showSaveButton={
                                isManualPointsChangeEnabled || showSaveButton
                            }
                            saveParticipantsPoints={saveParticipantsPoints}
                            setDisableButton={setDisableButton}
                        />
                    </Col>
                </Row>
                {competitionProfileData?.attributes?.tournament_type
                    ?.tournament_type_name === 'Individualno' ? (
                    <Row>
                        {user?.role?.type === 'admin' && (
                            <>
                                <Col xs={12} className="mt-3">
                                    <Form.Switch
                                        name="competition-finished-switch"
                                        label={'Omogući manuelnu izmenu bodova'}
                                        checked={isManualPointsChangeEnabled}
                                        onChange={() => {
                                            setIsManualPointsChangeEnabled(
                                                (prev) => !prev
                                            )
                                        }}
                                        disabled={
                                            user?.role?.type === 'competitor'
                                                ? true
                                                : false
                                        }
                                    />
                                </Col>

                                {/* {playersForTournamentList?.length >= 2 &&
                                    participantsToShow?.is_double !== true && (
                                        <Col xs={12} md={4} className="mt-2">
                                            <Button
                                                type="secondary"
                                                onClick={() => handleAutoSeed()}
                                                className="w-100"
                                            >
                                                Automatski rasporedi nosioce
                                                turnira
                                            </Button>
                                        </Col>
                                    )} */}
                            </>
                        )}

                        <Col xs={12}>
                            <Row className="d-none d-md-block mt-3">
                                <Col>
                                    <CompetitionsParticipantsTable
                                        participants={playersForTournamentList}
                                        enableRemove={true}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        handleGetPlayersForTournament={
                                            handleGetPlayersForTournamentWrapper
                                        }
                                        pageCount={pageCount}
                                        total={total}
                                        isDouble={participantsToShow?.is_double}
                                        competitionProfileData={
                                            competitionProfileData
                                        }
                                        handleAutoSeed={handleAutoSeed}
                                        isManualPointsChangeEnabled={
                                            isManualPointsChangeEnabled
                                        }
                                        setPlayersForTournamentList={
                                            setPlayersForTournamentList
                                        }
                                        rowsPerPage={rowsPerPage}
                                        setRowsPerPage={setRowsPerPage}
                                        numberOfDAPlayers={numberOfDAPlayers}
                                        numberOfQPlayers={numberOfQPlayers}
                                        numberOfWCPlayers={numberOfWCPlayers}
                                        numberOfWCQPlayers={numberOfWCQPlayers}
                                        numberOfLLPlayers={numberOfLLPlayers}
                                    />
                                </Col>
                            </Row>
                            <Row className="d-block d-md-none mt-3">
                                <Col>
                                    {playersForTournamentList?.map(
                                        (participant, index) => (
                                            <CompetitionsParticipantsCard
                                                key={index}
                                                index={index}
                                                participant={participant}
                                                enableRemove={true}
                                                isWhite={
                                                    index % 2 === 0
                                                        ? true
                                                        : false
                                                }
                                                handleGetPlayersForTournament={
                                                    handleGetPlayersForTournamentWrapper
                                                }
                                                isDouble={
                                                    participantsToShow?.is_double
                                                }
                                                handleAutoSeed={handleAutoSeed}
                                            />
                                        )
                                    )}
                                </Col>
                            </Row>
                        </Col>

                        {user?.role?.type === 'admin' && (
                            <Col xs={12}>
                                <Row className="mt-3">
                                    <Col xs={12}>
                                        <Button
                                            onClick={() =>
                                                setShowCompetitorsModal(true)
                                            }
                                            disabled={disableButton}
                                        >
                                            Dodaj takmičare
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        )}
                    </Row>
                ) : (
                    <Row>
                        <Col className="pt-3">
                            <Card>
                                <Card.Body>
                                    <h3 className="mb-4">
                                        Klubovi koji se takmiče
                                    </h3>
                                    <Row>
                                        <Col>
                                            <CompetitionClubsParticipants
                                                clubs={clubsForTournamentList}
                                                className="d-none d-md-block"
                                                enableRemove={
                                                    user?.role?.type ===
                                                    'competitor'
                                                        ? false
                                                        : true
                                                }
                                                handleGetClubsForTournament={
                                                    handleGetClubsForTournament
                                                }
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                total={total}
                                                pageCount={pageCount}
                                                competitionProfileData={
                                                    competitionProfileData
                                                }
                                                rowsPerPage={rowsPerPage}
                                                setRowsPerPage={setRowsPerPage}
                                            />
                                            {clubsForTournamentList?.map(
                                                (club, index) => (
                                                    <CompetitionClubsCardParticipants
                                                        key={index}
                                                        club={club}
                                                        isWhite={
                                                            index % 2 !== 0
                                                        }
                                                        enableRemove={true}
                                                        className="d-block d-md-none"
                                                        handleGetClubsForTournament={
                                                            handleGetClubsForTournament
                                                        }
                                                    />
                                                )
                                            )}
                                        </Col>
                                    </Row>

                                    {user?.role?.type === 'admin' && (
                                        <Row className="mt-4">
                                            <Col className="d-flex flex-column align-items-end">
                                                <Button
                                                    onClick={() =>
                                                        setShowClubsModal(true)
                                                    }
                                                    disabled={
                                                        disableButton ||
                                                        competitionProfileData
                                                            ?.attributes
                                                            ?.competitors?.data
                                                            ?.length > 0
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    Dodaj klub
                                                </Button>
                                                {competitionProfileData
                                                    ?.attributes?.competitors
                                                    ?.data?.length > 0 && (
                                                    <p className="small-text mt-2">
                                                        Nije moguće menjati
                                                        listu klubova ako
                                                        postoje već prijavljeni
                                                        takmičari za neki klub.
                                                    </p>
                                                )}
                                            </Col>
                                        </Row>
                                    )}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                )}
            </Col>

            <ModalAfterPointsSave
                show={showModalAfterSave}
                onHide={() => setShowModalAfterSave(false)}
            />

            <Modal
                centered
                show={showClubsModal}
                onHide={() => {
                    setShowClubsModal(false)
                    setSelectedClubs(null)
                }}
                dialogClassName="add-penalties-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>Klubovi</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AsyncSelect
                        loadOptions={loadClubs}
                        onChange={(e) =>
                            setSelectedClubs({
                                tournament_uuid:
                                    competitionProfileData?.attributes?.uuid,
                                club_uuid: e.uuid,
                                club_status: {
                                    status_name: competitionProfileData
                                        ?.attributes
                                        ?.tournament_have_round_roubin
                                        ? 'QA'
                                        : 'DA',
                                },
                            })
                        }
                        label={'Izaberi klub'}
                        placeholder={'Pretraži i izaberi klub po imenu...'}
                        noOptionsMessage={'Traženi klub ne postoji'}
                        closeMenuOnSelect={true}
                        isMulti={false}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="text"
                        onClick={() => setShowClubsModal(false)}
                    >
                        Odustani
                    </Button>
                    <Button
                        onClick={() => handleSubmitSelectedClub()}
                        disabled={!selectedClubs}
                    >
                        Potvrdi
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                centered
                show={showCompetitorsModal}
                onHide={() => setShowCompetitorsModal(false)}
                dialogClassName="add-penalties-modal"
                size="lg"
            >
                <Modal.Body>
                    <AsyncSelect
                        loadOptions={loadPlayers}
                        onChange={(e) => {
                            handlePlayerSelect(e)
                        }}
                        label={`Izaberi igrača ${
                            participantsToShow?.is_double ? '1' : ''
                        }`}
                        placeholder={
                            'Pretraži i izaberi igrača po imenu i prezimenu...'
                        }
                        noOptionsMessage={'Traženi igrač ne postoji'}
                        closeMenuOnSelect={true}
                        isMulti={false}
                    />

                    {participantsToShow?.is_double && (
                        <Row className="mt-3">
                            <Col>
                                <AsyncSelect
                                    loadOptions={loadPlayers}
                                    onChange={(e) => {
                                        setSelectedPlayer((prev) => ({
                                            ...prev,
                                            tournament_uuid:
                                                competitionProfileData
                                                    ?.attributes?.uuid,
                                            player_2_uuid: e.uuid,
                                            gender: {
                                                gender_name:
                                                    participantsToShow?.gender,
                                            },
                                        }))
                                    }}
                                    label={'Izaberi igrača 2'}
                                    placeholder={
                                        'Pretraži i izaberi igrača po imenu i prezimenu...'
                                    }
                                    noOptionsMessage={
                                        'Traženi igrač ne postoji'
                                    }
                                    closeMenuOnSelect={true}
                                    isMulti={false}
                                />
                            </Col>
                        </Row>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="text"
                        onClick={() => {
                            setShowCompetitorsModal(false)
                            setSelectedPlayer(null)
                        }}
                    >
                        Odustani
                    </Button>
                    <Button
                        onClick={() => handleSubmitSelectedPlayer()}
                        disabled={
                            participantsToShow?.is_double
                                ? selectedPlayer?.player_1_uuid &&
                                  selectedPlayer?.player_2_uuid
                                    ? false
                                    : true
                                : selectedPlayer?.player_1_uuid
                                ? false
                                : true
                        }
                    >
                        Potvrdi
                    </Button>
                </Modal.Footer>
            </Modal>
        </Row>
    )
}

export default ParticipantsTab
