import jsPDF from 'jspdf'
import { getPlayersRank } from '../../../../../../lib/api'
import {
    formatDate,
    handleGetPlayersForTournament,
} from '../../../../../../lib/helpers'
import { font } from '../../font'

const handleGetPlayersForTournamentWithPointsWrapper = async (
    competitionProfileData,
    participantsToShow
) => {
    if (competitionProfileData) {
        const tournamentUUID = competitionProfileData?.attributes?.uuid
        const isDouble = participantsToShow?.is_double
        const gender = participantsToShow?.gender

        if (tournamentUUID) {
            const { updatedParticipantsList } =
                await handleGetPlayersForTournament(
                    {
                        tournament_uuid: tournamentUUID,
                        is_double: isDouble,
                        gender: gender,
                    },
                    1,
                    participantsToShow,
                    false,
                    128
                )

            const updatedParticipantsListWithRank = await Promise.all(
                updatedParticipantsList.map(async (participant) => {
                    const player1Rank = await getPlayersRank(
                        participant?.attributes?.player_1?.attributes?.uuid,
                        competitionProfileData?.attributes?.players_competition
                            ?.players_competition_name,
                        competitionProfileData?.attributes?.players_competition
                            ?.players_competition_subcategory_name || null
                    )

                    let player2Rank

                    if (participant?.attributes?.player_2?.attributes?.uuid) {
                        player2Rank = await getPlayersRank(
                            participant?.attributes?.player_2?.attributes?.uuid,
                            competitionProfileData?.attributes
                                ?.players_competition?.players_competition_name,
                            competitionProfileData?.attributes
                                ?.players_competition
                                ?.players_competition_subcategory_name || null
                        )
                    }
                    return {
                        ...participant,
                        attributes: {
                            ...participant.attributes,
                            player_1_ranking_number:
                                player1Rank?.attributes?.ranking_number,
                            player_2_ranking_number:
                                player2Rank?.attributes?.ranking_number,
                        },
                    }
                })
            )
            return updatedParticipantsListWithRank
        }
    }
}

const createDoc = async (data, orientation, unit, size, font) => {
    const { competitionProfileData, participantsToShow } = data

    const playersForTournamentList =
        await handleGetPlayersForTournamentWithPointsWrapper(
            competitionProfileData,
            participantsToShow
        )
    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size)
    doc.addFileToVFS('WorkSans-normal.ttf', font)
    doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
    doc.setFont('WorkSans')
    doc.setFontSize(12)

    const tournamentName = competitionProfileData?.attributes?.name || ''

    const tournamentCategory =
        competitionProfileData?.attributes?.tournament_category
            ?.tournament_category_name || ''
    const playersCompetition =
        competitionProfileData?.attributes?.players_competition
            ?.players_competition_name || ''
    const tournamentStartDate =
        formatDate(competitionProfileData?.attributes?.start_date) || ''

    const title = `Naslov: ${tournamentName} - ${competitionProfileData?.attributes?.club_organizer?.attributes?.name} - ${tournamentStartDate} - ${playersCompetition} - ${tournamentCategory}`
    doc.text(title, marginLeft, 40)

    doc.text(`Naziv: ${tournamentName || ''}`, marginLeft, 55)
    doc.text(
        `Organizator: ${
            competitionProfileData?.attributes?.club_organizer?.attributes
                ?.name || ''
        }`,
        marginLeft,
        70
    )
    doc.text(
        `Mesto održavanja: ${
            competitionProfileData?.attributes?.club_organizer?.attributes
                ?.address?.street || ''
        }${
            competitionProfileData?.attributes?.club_organizer?.attributes
                ?.address?.city
                ? `, ${competitionProfileData?.attributes?.club_organizer?.attributes?.address?.city}`
                : ''
        }`,
        marginLeft,
        85
    )
    doc.text(`Početak: ${tournamentStartDate || ''}`, marginLeft, 100)
    doc.text(`Konkurencija: ${playersCompetition || ''}`, marginLeft, 115)
    doc.text(`Kategorija: ${tournamentCategory || ''}`, marginLeft, 130)

    const participantsList = []

    playersForTournamentList?.map((participant, index) => {
        participantsList?.push([
            index + 1,
            `${
                participant?.attributes?.player_1?.attributes?.person_info
                    ?.given_name
            } ${
                participant?.attributes?.player_1?.attributes?.person_info
                    ?.family_name
            }  ${
                participant?.attributes?.player_2?.attributes?.person_info
                    ?.given_name || ''
            } ${
                participant?.attributes?.player_2?.attributes?.person_info
                    ?.family_name || ''
            }`,
            `${
                participant?.attributes?.player_1_club?.attributes?.name ||
                'Bez kluba'
            }`,
            `${
                participant?.attributes?.player_1?.attributes?.person_info
                    ?.birthdate
                    ? formatDate(
                          participant?.attributes?.player_1?.attributes
                              ?.person_info?.birthdate
                      )
                    : ''
            } ${
                participant?.attributes?.player_2?.attributes?.person_info
                    ?.birthdate
                    ? ` - ${formatDate(
                          participant?.attributes?.player_2?.attributes
                              ?.person_info?.birthdate
                      )}`
                    : ''
            }`,
            participant?.attributes?.player_1_ranking_number || '',
            `${participant?.attributes?.player_status?.status_name ?? ''}`,
        ])
    })

    let generalInfoContent = {
        startY: 145,
        head: [
            [
                'Pozicija',
                'Ime i prezime',
                'Klub',
                'Datum rođenja',
                'Rang',
                'Status',
            ],
        ],
        body: participantsList,
        styles: {
            font: 'WorkSans',
        },
    }

    doc.autoTable(generalInfoContent)

    return doc
}

export const handleExportParticipantsTabPDF = async (data) => {
    const unit = 'pt'
    const size = 'A4' // Use A1, A2, A3 or A4
    const orientation = 'portrait' // portrait or landscape

    const doc = await createDoc(data, orientation, unit, size, font)

    doc.save(
        `${
            data?.competitionProfileData?.attributes?.name
        }, početak (${formatDate(
            data?.competitionProfileData?.attributes?.start_date
        )}) - Status.pdf`
    )
}

export const handlePrintParticipantsTab = async (data) => {
    const unit = 'pt'
    const size = 'A4' // Use A1, A2, A3 or A4
    const orientation = 'portrait' // portrait or landscape

    const doc = await createDoc(data, orientation, unit, size, font)

    // for printing
    doc.autoPrint()
    doc.output('dataurlnewwindow')
}
