import React from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

import './styles.scss'

function MultiSelectSearch({
    options,
    animated,
    placeholder,
    noOptionsMessage = 'Nema više opcija',
    closeMenuOnSelect = true,
    onChange,
    value,
    isMulti = true,
    maxLimit,
    label,
    className,
    required,
    onMenuScrollToBottom,
    onInputChange,
    isClearable,
    disabled = false,
    subLabel = null,
}) {
    const animatedComponents = makeAnimated()
    return (
        <>
            <div className="d-flex">
                {label && <h5 className="mb-2">{label}</h5>}
                {required && <h6 className="required ms-1">*</h6>}
                {subLabel ? (
                    <p className="sub-label caption-text ms-1">
                        {` - `}
                        {subLabel}
                    </p>
                ) : null}
            </div>
            <Select
                isDisabled={disabled}
                isMulti={isMulti}
                options={options}
                isClearable={isClearable}
                isOptionDisabled={
                    maxLimit
                        ? (option) =>
                              value.length >= maxLimit || option.isDisabled
                        : () => false
                }
                components={animated && animatedComponents}
                styles={{
                    multiValue: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: 'transparent',
                        border: '1px solid #A4ACB6',
                        borderRadius: '24px',
                        width: 'max-content',
                    }),
                    multiValueRemove: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: '#A4ACB6',
                        borderRadius: '24px',
                        margin: '3px 3px 3px 0',
                        color: 'white',
                        ':hover': {
                            backgroundColor: '#4C5054',
                        },
                        ':active': {
                            backgroundColor: '#212529',
                        },
                    }),
                    multiValueLabel: (baseStyles, state) => ({
                        ...baseStyles,
                        fontFamily: 'Source Sans Pro',
                        fontWeight: '400',
                        fontSize: '16px',
                        lineHeight: '20px',
                        letterSpacing: '0.025em',
                        color: '#3B485C',
                    }),
                    menuList: (baseStyles, state) => ({
                        ...baseStyles,
                        padding: '0',
                        borderRadius: '4px',
                    }),
                    option: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: state.isDisabled ? '#F5F5F5' : 'white',
                        fontFamily: 'Source Sans Pro',
                        fontWeight: '400',
                        fontSize: '16px',
                        lineHeight: '20px',
                        letterSpacing: '0.025em',
                        color: '#4C5054',
                        ':hover': {
                            backgroundColor: '#F5F5F5',
                            cursor: state.isDisabled ? 'default' : 'pointer',
                        },
                    }),
                    indicatorsContainer: (baseStyles, state) => ({
                        ...baseStyles,
                        cursor: 'pointer',
                    }),
                    indicatorSeparator: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: '#96989b',
                    }),
                    valueContainer: (baseStyles, state) => ({
                        ...baseStyles,
                        paddingTop: '8px',
                        paddingBottom: '10px',
                    }),
                    singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        padding: '1px 0 1px 2px',
                    }),
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        ':hover': {
                            borderColor: '#0D284B',
                            boxShadow: '0 0 0 1px #0D284B',
                        },
                        ':focus-within': {
                            borderColor: '#0D284B',
                            boxShadow: '0 0 0 1px #0D284B',
                        },
                        border: '1px solid #96989b',
                    }),
                }}
                className={`multi-select-search ${className}`}
                placeholder={placeholder}
                closeMenuOnSelect={closeMenuOnSelect}
                noOptionsMessage={() => noOptionsMessage}
                onChange={onChange}
                onInputChange={onInputChange}
                value={value}
                onMenuScrollToBottom={onMenuScrollToBottom}
                formatOptionLabel={(option) => (
                    <div className="competitor-select-option">
                        {option.img ? (
                            <div className="d-flex select-option-with-image-wrapper align-items-center">
                                <img
                                    src={option.img}
                                    alt="image"
                                    className="select-option-img"
                                />
                                <div>
                                    <p>{option.label}</p>
                                    <p className="small-text small-text-grey">
                                        {option.club}
                                    </p>
                                </div>
                            </div>
                        ) : (
                            <span>
                                {option.label ? option.label : option.value}
                            </span>
                        )}
                    </div>
                )}
            />
        </>
    )
}

export default MultiSelectSearch
