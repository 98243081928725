import React from 'react'
import { Row, Col } from 'react-bootstrap'
import NoPlayerImg from '../../assets/images/tennis-player-with-racket.png'
import { Link } from 'react-router-dom'
import Avatar from '../Avatar'
import Pill from '../Pill'
import TrophyOutline from '../../assets/svg/TrophyOutline'
import './styles.scss'

const Player = ({
    data,
    matchData,
    participantUuid,
    matchPenalties,
    ordinalNumber,
}) => {
    const playerAttributes = data?.attributes
    const { picture, given_name, family_name } =
        playerAttributes?.person_info || {}

    return (
        <Col className="py-2 d-flex align-items-center">
            <p className="small-text">{ordinalNumber || ''}</p>
            <Avatar
                img={picture || NoPlayerImg}
                alt="fotografija igraca"
                className="me-2"
            />
            {playerAttributes || participantUuid === '-1' ? (
                <div className="d-flex align-items-center justify-content-between w-100">
                    <Link
                        to={
                            participantUuid === '-1'
                                ? null
                                : `/takmicari/${playerAttributes?.uuid}`
                        }
                        className={participantUuid !== '-1' ? 'link' : ''}
                    >
                        {participantUuid === '-1'
                            ? 'BYE'
                            : `${given_name} ${family_name}`}
                    </Link>

                    <div className="d-flex align-items-center">
                        {matchPenalties?.find(
                            (penalty) =>
                                penalty?.attributes?.player_uuid ===
                                playerAttributes?.uuid
                        ) && (
                            <Pill type="inactive">
                                <p className="small-text">Kažnjen</p>
                            </Pill>
                        )}

                        {matchData?.loser_participant_uuid &&
                            matchData?.loser_participant_uuid !==
                                participantUuid && (
                                <Pill type="active" className="ms-1">
                                    <TrophyOutline />
                                </Pill>
                            )}
                    </div>
                </div>
            ) : (
                <p>Nepoznat</p>
            )}
        </Col>
    )
}

const CardParticipantSection = ({
    participantData,
    matchData,
    matchPenalties,
    className,
}) => {
    const { player_1, player_2, participant_uuid, ordinalNumber } =
        participantData || {}
    return (
        <Row className={`tournament-match-card-row flex-column ${className}`}>
            <Player
                data={player_1}
                matchData={matchData}
                participantUuid={participant_uuid}
                ordinalNumber={ordinalNumber}
                matchPenalties={matchPenalties}
            />
            {(player_2 || matchData?.is_double) && (
                <Player
                    data={player_2}
                    participantUuid={participant_uuid}
                    matchPenalties={matchPenalties}
                />
            )}
        </Row>
    )
}

export default CardParticipantSection
