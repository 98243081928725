import React, { useEffect, useState } from 'react'
import { Col, Row, Tab, Tabs } from 'react-bootstrap'
import { RowsPerPageOptions } from '../../../components/RowsPerPageDropdown'
import {
    getListOfTournamentsByPlayerUuid,
    getSingleClub,
    getTournamentsByUuids,
} from '../../../lib/api'
import { useAppContext } from '../../../lib/contextLib'
import FinishedTournaments from '../AllTournaments/Finished'
import UpcomingTournamets from '../AllTournaments/Upcoming'

const Tournaments = () => {
    const { user } = useAppContext()

    //pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [total, setTotal] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(RowsPerPageOptions[0])

    const [selectedTab, setSelectedTab] = useState('nadolazeca')

    const [comingCompetitions, setComingCompetitions] = useState([])
    const [finishedCompetitions, setFinishedCompetitions] = useState([])

    //TODO - Competitors tournaments by priority
    //TODO - Competitors tournaments by priority
    //TODO - Competitors tournaments by priority
    //TODO - Competitors tournaments by priority
    //TODO - Competitors tournaments by priority
    //TODO - Competitors tournaments by priority

    const getComingCompetitions = async (page) => {
        let {
            tournaments,
            pgCount,
            total: totalCount,
        } = await getListOfTournamentsByPlayerUuid(
            user?.data?.attributes?.uuid,
            currentPage,
            rowsPerPage
        )

        if (tournaments) {
            let tournamentUuids = tournaments?.map(
                (tournament) => tournament?.attributes?.tournament_uuid
            )

            let {
                tournaments: comingTournaments,
                pgCount: comingTournamentsPgCount,
                total: comingTournamentsTotal,
            } = await getTournamentsByUuids(
                tournamentUuids,
                page,
                false,
                null,
                null,
                rowsPerPage
            )

            if (comingTournaments) {
                const tournamentsPromises = comingTournaments.map(
                    async (tournament) => {
                        const club_organizer = await getSingleClub(
                            tournament.attributes.club_organizer_uuid
                        )

                        let pointsOnTournament = tournaments.find(
                            (tournamentData) =>
                                tournamentData?.attributes?.tournament_uuid ===
                                tournament?.attributes?.uuid
                        )

                        return {
                            ...tournament,
                            attributes: {
                                ...tournament.attributes,
                                club_organizer,
                                ranking_points:
                                    pointsOnTournament?.attributes
                                        ?.ranking_points,
                            },
                        }
                    }
                )

                const updatedTournaments = await Promise.all(
                    tournamentsPromises
                )
                setComingCompetitions(updatedTournaments)
                setPageCount(comingTournamentsPgCount)
                setTotal(comingTournamentsTotal)
                setCurrentPage(page)
            }
        }
    }

    const getAllFinishedCompetitions = async (page) => {
        let {
            tournaments,
            pgCount,
            total: totalCount,
        } = await getListOfTournamentsByPlayerUuid(
            user?.data?.attributes?.uuid,
            currentPage,
            rowsPerPage
        )

        if (tournaments) {
            let tournamentUuids = tournaments?.map(
                (tournament) => tournament?.attributes?.tournament_uuid
            )

            let {
                tournaments: finishedTournaments,
                pgCount: finishedTournamentsPgCount,
                total: finishedTournamentsTotal,
            } = await getTournamentsByUuids(
                tournamentUuids,
                page,
                true,
                null,
                null,
                rowsPerPage
            )

            if (finishedTournaments) {
                const tournamentsPromises = finishedTournaments.map(
                    async (tournament) => {
                        const club_organizer = await getSingleClub(
                            tournament.attributes.club_organizer_uuid
                        )

                        let pointsOnTournament = tournaments.find(
                            (tournamentData) =>
                                tournamentData?.attributes?.tournament_uuid ===
                                tournament?.attributes?.uuid
                        )

                        return {
                            ...tournament,
                            attributes: {
                                ...tournament.attributes,
                                club_organizer,
                                ranking_points:
                                    pointsOnTournament?.attributes
                                        ?.ranking_points,
                            },
                        }
                    }
                )

                const updatedTournaments = await Promise.all(
                    tournamentsPromises
                )
                setFinishedCompetitions(updatedTournaments)
                setPageCount(finishedTournamentsPgCount)
                setTotal(finishedTournamentsTotal)
                setCurrentPage(page)
            }
        }
    }

    useEffect(() => {
        selectedTab === 'nadolazeca'
            ? getComingCompetitions(currentPage)
            : getAllFinishedCompetitions(currentPage)
    }, [currentPage, selectedTab, user, rowsPerPage])

    const handleTabSelect = (tab) => {
        setSelectedTab((_) => {
            tab === 'nadolazeca'
                ? getComingCompetitions(1)
                : getAllFinishedCompetitions(1)
            return tab
        })
    }

    const handleTabState = (selectedTab) => {
        switch (selectedTab) {
            case 'nadolazeca':
                return (
                    <UpcomingTournamets
                        tournaments={comingCompetitions}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        pageCount={pageCount}
                        total={total}
                        profilePage
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        // prioritizing ------ Commented out code for priority
                    />
                )
            case 'zavrsena':
                return (
                    <FinishedTournaments
                        tournaments={finishedCompetitions}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        pageCount={pageCount}
                        total={total}
                        profilePage
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                )
            default:
                return (
                    <UpcomingTournamets
                        tournaments={comingCompetitions}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        pageCount={pageCount}
                        total={total}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        // prioritizing ------ Commented out code for priority
                    />
                )
        }
    }

    return (
        <Col className="competitor-tournaments">
            <Tabs
                id="competition-selection"
                defaultActiveKey={selectedTab}
                activeKey={selectedTab}
                onSelect={(tab) => {
                    handleTabSelect(tab)
                }}
            >
                <Tab
                    key="nadolazeca"
                    eventKey="nadolazeca"
                    title={<p>Nadolazeća</p>}
                    tabClassName="d-flex"
                ></Tab>
                <Tab
                    key="zavrsena"
                    eventKey="zavrsena"
                    title={<p>Završena</p>}
                    tabClassName="d-flex"
                ></Tab>
            </Tabs>
            <Row className="m-0  pt-3">{handleTabState(selectedTab)}</Row>
        </Col>
    )
}

export default Tournaments
