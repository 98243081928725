import React, { useState } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import Button from '../../../../../../../components/Button'
import { getTournamentPlayersParticipantsList } from '../../../../../../../lib/helpers'
import AsyncSelect from '../../../../../../../components/AsyncSelect'
import { addParticipantToGroup } from '../../../../../../../lib/api'

const AddPlayerToGroupModal = ({
    show,
    onHide,
    groupDetails,
    handleGetGroupParticipants,
}) => {
    const [groupData, setGroupData] = useState({})

    const handleAddParticipantToGroup = async () => {
        try {
            const addedParticipant = await addParticipantToGroup(groupData)
            if (addedParticipant) {
                onHide()
                handleGetGroupParticipants()
            }
        } catch (error) {
            throw new Error(error)
        }
    }

    const loadParticipants = async (
        inputValue,
        loadedOptions,
        paginationData
    ) => {
        return getTournamentPlayersParticipantsList(
            inputValue,
            loadedOptions,
            paginationData,
            {
                tournament_uuid: groupDetails?.tournament_uuid,
                is_double: groupDetails?.is_double,
                gender: groupDetails?.gender?.gender_name,
                draw_type_name: groupDetails?.draw_type?.draw_type_name,
            }
        )
    }

    return (
        <Modal
            size="lg"
            centered
            show={show}
            onHide={onHide}
            dialogClassName="create-draw-pair-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>Dodaj igrača u grupu</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <AsyncSelect
                            loadOptions={loadParticipants}
                            onChange={(e) =>
                                setGroupData((prev) => ({
                                    ...prev,
                                    participant_uuid: e.uuid,
                                    tournament_uuid:
                                        groupDetails?.tournament_uuid,
                                    group_number: +groupDetails?.group_number,
                                    gender: {
                                        gender_name:
                                            groupDetails?.gender?.gender_name,
                                    },
                                }))
                            }
                            label={groupDetails?.is_double ? 'Par' : 'Takmičar'}
                            placeholder={'Izaberi igrača...'}
                            noOptionsMessage={'Nema slobodnih igrača'}
                            closeMenuOnSelect={true}
                            isMulti={false}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button type="text" onClick={onHide}>
                    Odustani
                </Button>
                <Button onClick={() => handleAddParticipantToGroup()}>
                    Dodaj
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddPlayerToGroupModal
