import React, { useState, useEffect } from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import TextField from '../TextField'
import Button from '../Button'
import Trash from '../../assets/svg/Trash'
import { useAppContext } from '../../lib/contextLib'
import NotificationModal from '../GroupParticipantCard/notificationModal'
import { deleteGroupParticipant, updateParticipantsGroup } from '../../lib/api'
import { calculateGroupParticipantRank } from '../../containers/Admin/Competitions/CompetitionProfile/Tabs/Draw/TeamDraw/TeamRoundRobin/roundRobinGroupRankCalculations'

const TeamGroupParticipantCard = ({
    index,
    participantData,
    className,
    handleGetGroupParticipants,
    groupMatches,
    isManualRankingEnabled,
}) => {
    const { user, setIsLoading } = useAppContext()

    const [participantManualRank, setParticipantManualRank] = useState(0)
    const [showNotificationModal, setShowNotificationModal] = useState(false)
    const [participantStatistics, setParticipantStatistics] = useState({})

    const handleRemoveGroupParticipant = async () => {
        try {
            if (groupMatches?.length > 0) {
                setShowNotificationModal(true)
            } else {
                const deletedParticipant = await deleteGroupParticipant(
                    participantData?.attributes?.uuid
                )
                if (!deletedParticipant) return

                handleGetGroupParticipants()
            }
        } catch (error) {
            throw new Error(error)
        }
    }

    const handleGetParticipantStatistics = async () => {
        const statistics = await calculateGroupParticipantRank(
            groupMatches,
            participantData?.attributes?.participant?.attributes?.uuid
        )

        setParticipantStatistics(statistics)
    }

    const handleUpdateParticipantsGroup = async () => {
        try {
            setIsLoading(true)
            const updatedParticipant = await updateParticipantsGroup(
                participantData?.attributes?.uuid,
                {
                    group_manual_rank: +participantManualRank,
                }
            )
            if (!updatedParticipant) return
            setParticipantManualRank(0)
            handleGetGroupParticipants()
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        handleGetParticipantStatistics()
    }, [participantData, groupMatches])
    return (
        <Card className={`${className} group-participant-card`}>
            <Card.Body>
                <Row>
                    <Col xs={12} md={4} className="d-flex align-items-center">
                        <Row className="mx-2">
                            <Col className="group-position-col py-2">
                                <p className="mb-1">{index + 1}.</p>
                                <p className="small-text">Pozicija</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Link
                                    to={
                                        participantData?.attributes?.club
                                            ?.attributes?.uuid === '-1'
                                            ? null
                                            : `/klubovi/${participantData?.attributes?.club?.attributes?.uuid}`
                                    }
                                    className={
                                        participantData?.attributes?.club
                                            ?.attributes?.uuid !== '-1'
                                            ? 'link'
                                            : ''
                                    }
                                >
                                    {
                                        participantData?.attributes?.club
                                            ?.attributes?.name
                                    }{' '}
                                    (
                                    {
                                        participantData?.attributes?.club
                                            ?.attributes?.short_name
                                    }
                                    )
                                </Link>
                            </Col>
                        </Row>
                    </Col>

                    <Col>
                        <Row>
                            <Col className="group-statistic-col">
                                <p className="mb-1">
                                    {participantStatistics?.winsNumber || 0}
                                </p>
                                <p className="small-text">Pobede</p>
                            </Col>
                            {user?.role?.type !== 'competitor' && (
                                <Col className="group-statistic-col d-flex justify-content-end">
                                    {isManualRankingEnabled ? (
                                        <div className="d-flex align-items-center">
                                            <TextField
                                                type="number"
                                                value={
                                                    participantManualRank ||
                                                    participantData?.attributes
                                                        ?.group_manual_rank
                                                }
                                                onChange={(e) => {
                                                    e.target.value <= 4 &&
                                                        e.target.value >= 1 &&
                                                        setParticipantManualRank(
                                                            +e.target.value
                                                        )
                                                }}
                                                style={{ width: '50px' }}
                                            />

                                            <Button
                                                onClick={() =>
                                                    handleUpdateParticipantsGroup()
                                                }
                                                type="secondary"
                                                className="ms-2"
                                                disabled={
                                                    !participantManualRank
                                                }
                                            >
                                                Sačuvaj
                                            </Button>
                                        </div>
                                    ) : (
                                        <Button
                                            type="text"
                                            onClick={() =>
                                                handleRemoveGroupParticipant()
                                            }
                                        >
                                            <Trash />
                                        </Button>
                                    )}
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
            </Card.Body>

            <NotificationModal
                show={showNotificationModal}
                onHide={() => setShowNotificationModal(false)}
            />
        </Card>
    )
}

export default TeamGroupParticipantCard
