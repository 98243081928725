import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import Slider from 'react-slick'
import Button from '../../../../../../components/Button'
import Dropdown from '../../../../../../components/Dropdown'
import TournamentRoundCard from '../../../../../../components/TournamentRoundCard'
import TournamentScheduleCard from '../../../../../../components/TournamentScheduleCard'
import {
    getScheduleDates,
    getSchedules,
    getTournamentsFields,
} from '../../../../../../lib/api'
import { useAppContext } from '../../../../../../lib/contextLib'
import {
    convertDateFormatWithDay,
    formatDateWithDay,
} from '../../../../../../lib/helpers'
import { settings } from '../Draw/carouselSettings'
import AddFieldModal from './addFieldModal'
import AddMatchModal from './addMatchModal'
import AddScheduleDate from './addScheduleDate'
import ScheduleModalForExport from './scheduleModalForExport'
const ScheduleTab = ({
    tournament,
    activeTab,
    showScheduleModalForExport,
    setShowScheduleModalForExport,
}) => {
    const { user, setIsLoading } = useAppContext()
    const showButtons = user?.role?.type === 'competitor' ? false : true
    const [showAddFieldModal, setShowAddFieldModal] = useState(false)
    const [showAddMatchModal, setShowAddMatchModal] = useState(false)
    const [showAddScheduleDateModa, setShowAddScheduleDateModal] =
        useState(false)
    const [tournamentsFields, setTournamentsFields] = useState([])
    const [tournamentScheduleDates, setTournamentScheduleDates] = useState([])
    const [selectedFieldUuid, setSelectedFieldUuid] = useState()
    const [selectedScheduleDate, setSelectedScheduleDate] = useState()

    const [schedules, setSchedules] = useState([])

    const handleShowTournamentsField = async () => {
        if (tournament?.attributes?.uuid) {
            setIsLoading(true)
            const fieldsData = await getTournamentsFields(
                tournament?.attributes?.uuid
            )

            if (fieldsData) {
                setTournamentsFields(fieldsData)
            }
            setIsLoading(false)
        }
    }

    const handleTournamentDates = async () => {
        if (tournament?.attributes?.uuid) {
            setIsLoading(true)
            const scheduleDates = await getScheduleDates(
                tournament?.attributes?.uuid
            )

            if (scheduleDates?.length > 0) {
                setTournamentScheduleDates(scheduleDates)
                setSelectedScheduleDate(
                    formatDateWithDay(
                        scheduleDates[0]?.attributes?.schedule_date
                    )
                )
            }
            setIsLoading(false)
        }
    }

    const handleGetSchedules = async (fieldUuid) => {
        if (selectedScheduleDate) {
            const schedulesData = await getSchedules(
                fieldUuid,
                convertDateFormatWithDay(selectedScheduleDate)
            )

            if (schedulesData) {
                return schedulesData
            }
        }
    }
    useEffect(() => {
        handleShowTournamentsField()
        handleTournamentDates()
    }, [tournament])

    useEffect(() => {
        const fetchSchedules = async () => {
            const results = await Promise.all(
                tournamentsFields?.map((field) =>
                    handleGetSchedules(field?.attributes?.uuid)
                )
            )
            setSchedules(results)
        }

        if (activeTab === 'raspored') {
            fetchSchedules()
        }
    }, [tournamentsFields, selectedScheduleDate, activeTab])

    return (
        <Row className="mt-3">
            <Col>
                <Row className="mb-3">
                    <Col className="d-flex justify-content-end align-items-end">
                        <Dropdown
                            label={'Takmičarski dan'}
                            placeholder={'Izaberi takmičarski dan'}
                            dropdownLinks={tournamentScheduleDates?.map(
                                (date) =>
                                    formatDateWithDay(
                                        date?.attributes?.schedule_date
                                    )
                            )}
                            handleSelect={(e) => setSelectedScheduleDate(e)}
                            value={selectedScheduleDate || ''}
                            className="me-2"
                            style={{ width: '150px' }}
                        />
                        {showButtons && (
                            <Button
                                type="secondary"
                                onClick={() =>
                                    setShowAddScheduleDateModal(true)
                                }
                            >
                                Dodaj takmičarski dan
                            </Button>
                        )}
                    </Col>
                </Row>
                <Slider {...settings(3)} className="rounds-slider">
                    {schedules?.map((scheduleList, index) => (
                        <TournamentRoundCard
                            roundName={
                                tournamentsFields[index]?.attributes?.field_name
                            }
                            key={index}
                            className="me-3"
                            index={index}
                        >
                            <Row>
                                <Col>
                                    {selectedScheduleDate ? (
                                        <>
                                            {showButtons && (
                                                <Row>
                                                    <Col className="d-flex justify-content-center">
                                                        <Button
                                                            type="secondary"
                                                            onClick={() => {
                                                                setSelectedFieldUuid(
                                                                    tournamentsFields[
                                                                        index
                                                                    ]
                                                                        ?.attributes
                                                                        ?.uuid
                                                                )
                                                                setShowAddMatchModal(
                                                                    true
                                                                )
                                                            }}
                                                        >
                                                            Dodaj meč u raspored
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            )}

                                            {scheduleList?.map(
                                                (schedule, index) => (
                                                    <TournamentScheduleCard
                                                        key={index}
                                                        scheduleUuid={
                                                            schedule?.attributes
                                                                ?.uuid
                                                        }
                                                        handleShowTournamentsField={
                                                            handleShowTournamentsField
                                                        }
                                                        className="mt-3"
                                                        hideButton={
                                                            !showButtons
                                                        }
                                                        schedules={schedules}
                                                        tournament={tournament}
                                                    />
                                                )
                                            )}
                                        </>
                                    ) : (
                                        <Card>
                                            <Card.Body>
                                                <p>
                                                    Potrebno je izabrati
                                                    takmičarski dan pre
                                                    dodavanja meča u raspored.
                                                </p>
                                            </Card.Body>
                                        </Card>
                                    )}
                                </Col>
                            </Row>
                        </TournamentRoundCard>
                    ))}
                    {showButtons && (
                        <TournamentRoundCard roundName={``}>
                            <Row>
                                <Col className="d-flex justify-content-center">
                                    <Button
                                        type="secondary"
                                        onClick={() =>
                                            setShowAddFieldModal(true)
                                        }
                                    >
                                        Dodaj teren
                                    </Button>
                                </Col>
                            </Row>
                        </TournamentRoundCard>
                    )}
                </Slider>
            </Col>

            <AddFieldModal
                show={showAddFieldModal}
                onHide={() => setShowAddFieldModal(false)}
                tournament={tournament}
                handleShowTournamentsField={handleShowTournamentsField}
            />

            <AddMatchModal
                show={showAddMatchModal}
                onHide={() => setShowAddMatchModal(false)}
                tournament={tournament}
                selectedFieldUuid={selectedFieldUuid}
                selectedScheduleDate={selectedScheduleDate}
                handleShowTournamentsField={handleShowTournamentsField}
                schedules={schedules}
            />

            <AddScheduleDate
                show={showAddScheduleDateModa}
                onHide={() => setShowAddScheduleDateModal(false)}
                tournament={tournament}
                handleTournamentDates={handleTournamentDates}
            />

            <ScheduleModalForExport
                show={showScheduleModalForExport}
                onHide={() => setShowScheduleModalForExport(false)}
                schedules={schedules}
                tournamentsFields={tournamentsFields}
                selectedScheduleDate={selectedScheduleDate}
            />
        </Row>
    )
}

export default ScheduleTab
