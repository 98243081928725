import React from 'react'
import { Table } from 'react-bootstrap'
import NoDataForView from '../NoDataForView'
import PaginationWrapper from '../Pagination'
import ClubTournamentResultTableRow from './clubTournamentResultTableRow'

const ClubTournamentResultTable = ({
    tournaments,
    total,
    pageCount,
    currentPage,
    setCurrentPage,
    club,
    rowsPerPage,
    setRowsPerPage,
}) => {
    return (
        <div className="table-clubs-ranking-wrapper">
            {tournaments?.length ? (
                <>
                    <Table className="mb-0">
                        <thead>
                            <tr>
                                <th>
                                    <h5>Takmičenje</h5>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {tournaments?.map((tournament, index) => (
                                <ClubTournamentResultTableRow
                                    key={index}
                                    tournament={tournament}
                                    club={club}
                                />
                            ))}
                        </tbody>
                    </Table>
                    <PaginationWrapper
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        pageCount={pageCount}
                        total={total}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                </>
            ) : (
                <NoDataForView
                    title={`Nema rezultata`}
                    description={`Ovde je prikazana lista rezultata kluba.`}
                    className="border-0"
                />
            )}
        </div>
    )
}

export default ClubTournamentResultTable
