import { useEffect, useState } from 'react'
import { Card, Col, Modal, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import AsyncSelect from '../../../../../components/AsyncSelect'
import Button from '../../../../../components/Button'
import Dropdown from '../../../../../components/Dropdown'
import TextField from '../../../../../components/TextField'
import {
    addPlayersPenalties,
    deletePenalty,
    getPlayersPenalties,
    getSingleClub,
    getTournaments,
    getPenalties,
    getPlayerPenaltyPoints,
} from '../../../../../lib/api'
import { useAppContext } from '../../../../../lib/contextLib'
import SingleDisciplinaryPenalty from './SingleDisciplinaryPenalty'
import SingleTournamentPenalty from './SingleTournamentPenalty'
import './styles.scss'

const PunishmentsTab = ({ setRefreshData }) => {
    const { id } = useParams()
    const { user, setIsLoading } = useAppContext()

    //* Codebooks
    const [disciplinaryPenaltyTypes, setDisciplinaryPenaltyTypes] = useState([])
    const [tournamentPenaltyTypes, setTournamentPenaltyTypes] = useState([])
    const [penaltyPoints, setPenaltyPoints] = useState(0)

    const handleCodebooks = async () => {
        try {
            setIsLoading(true)
            const tournamentPenaltiesPromise = getPenalties({
                query: 'filters[penalty_type]=Tournament',
            })

            const disciplinaryPenaltiesPromise = getPenalties({
                query: 'filters[penalty_type]=Disciplinary',
            })

            const [tournamentPenalties, disciplinaryPenalties] =
                await Promise.all([
                    tournamentPenaltiesPromise,
                    disciplinaryPenaltiesPromise,
                ])

            if (tournamentPenalties) {
                setTournamentPenaltyTypes(tournamentPenalties)
            }

            if (disciplinaryPenalties) {
                setDisciplinaryPenaltyTypes(disciplinaryPenalties)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    const [tournamentPenalties, setTournamentPenalties] = useState([])
    const [disciplinaryPenalties, setDisciplinaryPenalties] = useState([])

    const [tournamentPenaltiesShow, setTournamentPenaltiesShow] =
        useState(false)
    const [disciplinaryPenaltiesShow, setDisciplinaryPenaltiesShow] =
        useState(false)

    const [formData, setFormData] = useState({
        penalty_type: '',
        penalty_name: '',
        duration: '',
        description: '',
        start_date: '',
        end_date: null,
        player_uuid: id,
    })

    const loadCompetitionOptions = async (
        searchValue,
        loadedOptions,
        { page }
    ) => {
        let name = searchValue

        let search_params = {
            name,
        }

        let { tournaments, pgCount } = await getTournaments(
            ['name', 'uuid', 'club_organizer_uuid'],
            search_params,
            {},
            page,
            null
        )

        if (tournaments) {
            const tournamentsPromises = tournaments.map(async (tournament) => {
                const club_organizer = await getSingleClub(
                    tournament.attributes.club_organizer_uuid
                )
                return {
                    ...tournament,
                    attributes: {
                        ...tournament.attributes,
                        club_organizer,
                    },
                }
            })

            const updatedTournaments = await Promise.all(tournamentsPromises)
            let list = []

            updatedTournaments.map((tournament) => {
                let tournamentDetails = {
                    value: `${tournament?.attributes?.name} - ${tournament?.attributes?.club_organizer?.attributes?.name}`,
                    uuid: tournament?.attributes?.uuid,
                }
                list.push(tournamentDetails)
            })
            return {
                options: list,
                hasMore: page === pgCount ? false : true,
                additional: {
                    page: page + 1,
                },
            }
        }
    }

    const handleCompetitionSelect = (e) => {
        setFormData({ ...formData, competition_uuid: e.uuid })
    }

    const handleCloseModal = () => {
        setTournamentPenaltiesShow(false)
        setDisciplinaryPenaltiesShow(false)
        setFormData({
            penalty_type: '',
            penalty_name: '',
            duration: '',
            description: '',
            start_date: '',
            end_date: null,
        })
    }

    const handleAddPenaly = async () => {
        const penalty = await addPlayersPenalties(formData)

        if (penalty) {
            setTournamentPenaltiesShow(false)
            setDisciplinaryPenaltiesShow(false)
            setFormData({
                penalty_type: '',
                penalty_name: '',
                duration: '',
                description: '',
                start_date: '',
                end_date: null,
            })
            handleGetPenalties()
            setRefreshData(true)
        }
    }

    const addDurationToEndDate = (startDate, duration) => {
        if (startDate && duration) {
            let date = new Date(startDate)
            date.setMonth(date.getMonth() + +duration)

            setFormData({
                ...formData,
                start_date: startDate,
                end_date: date.toISOString().split('T')[0],
                duration: duration,
            })
        } else if (duration && !startDate) {
            setFormData({
                ...formData,
                duration: duration,
            })
        } else if (startDate && !duration) {
            setFormData({
                ...formData,
                start_date: startDate,
            })
        }
    }

    const handleDurationInput = (e) => {
        if (+e.target.value > 0 && +e.target.value <= 24) {
            setFormData({
                ...formData,
                duration: e.target.value,
            })

            if (formData?.start_date) {
                addDurationToEndDate(formData?.start_date, +e.target.value)
            }
        } else if (+e.target.value <= 0) {
            setFormData({
                ...formData,
                duration: '',
            })
        }
    }

    const removePenalty = async (penaltyId) => {
        const deletedPenalty = await deletePenalty(penaltyId)

        if (deletedPenalty) {
            handleGetPenalties()
            setRefreshData(true)
        }
    }

    const handleGetPenalties = async () => {
        try {
            setIsLoading(true)
            const playerPenaltyPointsPromise = getPlayerPenaltyPoints(id)
            const allPenaltiesPromise = getPlayersPenalties(id)

            const [playerPenaltyPoints, allPenalties] = await Promise.all([
                playerPenaltyPointsPromise,
                allPenaltiesPromise,
            ])

            setDisciplinaryPenalties(
                allPenalties.filter(
                    (penalty) =>
                        penalty?.attributes?.penalty_type === 'Disciplinary'
                )
            )

            setTournamentPenalties(
                allPenalties.filter(
                    (penalty) =>
                        penalty?.attributes?.penalty_type === 'Tournament'
                )
            )

            setPenaltyPoints(playerPenaltyPoints)
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        handleGetPenalties()
        handleCodebooks()
    }, [id])

    const handleRequiredFields = () => {
        if (disciplinaryPenaltiesShow) {
            if (handleRequiredDuration()) {
                if (
                    String(formData?.penalty_name).length !== 0 &&
                    String(formData?.duration).length !== 0 &&
                    String(formData?.start_date).length !== 0
                ) {
                    return false
                } else {
                    return true
                }
            } else {
                if (
                    String(formData?.penalty_name).length !== 0 &&
                    String(formData?.start_date).length !== 0
                ) {
                    return false
                } else {
                    return true
                }
            }
        }

        if (tournamentPenaltiesShow) {
            if (
                String(formData?.competition_uuid).length !== 0 &&
                formData?.penalty_name &&
                String(formData?.penalty_name).length !== 0 &&
                String(formData?.start_date).length !== 0
            ) {
                return false
            } else {
                return true
            }
        }
    }

    const handleRequiredDuration = (e) => {
        let conditions = ['mesec', 'od', 'do']
        let loweredTypeString = String(formData?.penalty_name).toLowerCase()

        let test = conditions.every((condition) =>
            loweredTypeString.includes(condition)
        )

        return test
    }

    const handleSelectDisciplinaryPenalty = (e) => {
        const selectedPenalty = disciplinaryPenaltyTypes.find(
            (penalty) => penalty?.attributes?.penalty_name === e
        )
        setFormData({
            ...formData,
            penalty_name: e,
            penalty_type: 'Disciplinary',
            player_uuid: id,
            penalty_points: selectedPenalty?.attributes?.penalty_points || 0,
        })
    }

    const handleSelectTournamentPenalty = (e) => {
        const selectedPenalty = tournamentPenaltyTypes.find(
            (penalty) => penalty?.attributes?.penalty_name === e
        )
        setFormData({
            ...formData,
            penalty_name: e,
            penalty_type: 'Tournament',
            player_uuid: id,
            penalty_points: selectedPenalty?.attributes?.penalty_points || 0,
        })
    }

    return (
        <Row className="punishments-tab-wrapper">
            <Col xs={12}>
                <h5>
                    Ukupno kaznenih bodova u tekućoj sezoni:{' '}
                    {penaltyPoints || 0}
                </h5>
            </Col>
            <Col xs={12} md className="tournament-pun px-0">
                <Card>
                    <Card.Body className="p-4">
                        <Row className="mb-3">
                            <Col>
                                <h2 className="p-0">Kazne sa takmičenja</h2>
                            </Col>
                        </Row>
                        {tournamentPenalties?.map((penalty, index) => (
                            <SingleTournamentPenalty
                                key={index}
                                penalty={penalty}
                                removePenalty={removePenalty}
                                isAdmin={user?.role?.type === 'admin'}
                            />
                        ))}
                        <Row>
                            <Col xs={8} md={4}>
                                <Button
                                    className="w-100"
                                    onClick={() =>
                                        setTournamentPenaltiesShow(true)
                                    }
                                >
                                    Dodaj
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} md className="tournament-pun px-0">
                <Card>
                    <Card.Body className="p-4">
                        <Row className="mb-3">
                            <Col>
                                <h2 className="p-0">Disciplinske kazne</h2>
                            </Col>
                        </Row>
                        {disciplinaryPenalties?.map((penalty, index) => (
                            <SingleDisciplinaryPenalty
                                key={index}
                                penalty={penalty}
                                removePenalty={removePenalty}
                                isAdmin={user?.role?.type === 'admin'}
                            />
                        ))}
                        <Row>
                            <Col xs={8} md={4}>
                                <Button
                                    className="w-100"
                                    onClick={() =>
                                        setDisciplinaryPenaltiesShow(true)
                                    }
                                >
                                    Dodaj
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>

            <Modal
                show={tournamentPenaltiesShow}
                aria-labelledby="contained-modal-title-vcenter"
                onHide={handleCloseModal}
                centered
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Dodaj kaznu sa takmičenja
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AsyncSelect
                        loadOptions={loadCompetitionOptions}
                        isMulti={false}
                        onChange={handleCompetitionSelect}
                        label={'Takmičenje'}
                        placeholder={'Pretraži i izaberi...'}
                        noOptionsMessage={'Nema rezultata'}
                        closeMenuOnSelect={true}
                        className="mb-3"
                        required
                    />
                    <Dropdown
                        label={'Tip kazne'}
                        placeholder="Izaberi tip kazne"
                        dropdownLinks={tournamentPenaltyTypes?.map(
                            (penaltyType) => {
                                return penaltyType?.attributes?.penalty_name
                            }
                        )}
                        required
                        className="mb-3"
                        handleSelect={(e) => {
                            handleSelectTournamentPenalty(e)
                        }}
                        value={formData?.penalty_name}
                    />

                    <TextField
                        type="number"
                        label="Trajanje (broj meseci)"
                        placeholder="Trajanje (broj meseci)"
                        className="mb-3"
                        value={formData?.duration}
                        onChange={(e) => handleDurationInput(e)}
                        max="24"
                    />

                    <TextField
                        type="text"
                        label="Opis kazne"
                        placeholder="Opis kazne"
                        className="mb-3"
                        value={formData?.description}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                description: e.target.value,
                            })
                        }
                    />
                    <TextField
                        type="date"
                        label="Datum"
                        className="mb-4"
                        value={formData?.start_date}
                        onChange={(e) => {
                            addDurationToEndDate(
                                e.target.value,
                                formData?.duration
                            )
                        }}
                        required
                    />

                    <Row>
                        <Col className="d-flex align-items-center justify-content-end">
                            <Button
                                type="text"
                                className="me-2"
                                onClick={handleCloseModal}
                            >
                                Odustani
                            </Button>
                            <Button
                                onClick={handleAddPenaly}
                                disabled={handleRequiredFields()}
                            >
                                Dodaj
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal
                show={disciplinaryPenaltiesShow}
                aria-labelledby="contained-modal-title-vcenter"
                onHide={() => handleCloseModal()}
                centered
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Dodaj disciplinsku kaznu
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Dropdown
                        label={'Tip kazne'}
                        placeholder="Izaberi tip kazne"
                        dropdownLinks={disciplinaryPenaltyTypes?.map(
                            (penaltyType) => {
                                return penaltyType?.attributes?.penalty_name
                            }
                        )}
                        required
                        className="mb-3"
                        handleSelect={(e) => {
                            handleSelectDisciplinaryPenalty(e)
                        }}
                        value={formData?.penalty_name}
                    />

                    <TextField
                        type="number"
                        label="Trajanje (broj meseci)"
                        placeholder="Trajanje (broj meseci)"
                        className="mb-3"
                        value={formData?.duration}
                        onChange={(e) => handleDurationInput(e)}
                        max="24"
                        required={handleRequiredDuration()}
                    />

                    <TextField
                        type="text"
                        label="Opis kazne"
                        placeholder="Opis kazne"
                        className="mb-3"
                        value={formData?.description}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                description: e.target.value,
                            })
                        }
                    />

                    <TextField
                        type="date"
                        label="Datum"
                        className="mb-4"
                        value={formData?.start_date}
                        onChange={(e) => {
                            addDurationToEndDate(
                                e.target.value,
                                formData?.duration
                            )
                        }}
                        required
                    />

                    <Row>
                        <Col className="d-flex align-items-center justify-content-end">
                            <Button
                                type="text"
                                className="me-2"
                                onClick={handleCloseModal}
                            >
                                Odustani
                            </Button>
                            <Button
                                onClick={handleAddPenaly}
                                disabled={handleRequiredFields()}
                            >
                                Dodaj
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </Row>
    )
}

export default PunishmentsTab
