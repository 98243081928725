import React from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import { deleteClubMatch } from '../../lib/api'
import Button from '../Button'

const DeleteMatchModal = ({
    show,
    onHide,
    matchUuid,
    handleGetSingleMatch,
    onDelete,
}) => {
    const handleDeleteMatch = async () => {
        try {
            const deletedMatch = await deleteClubMatch(matchUuid)
            if (deletedMatch) {
                onHide()
                if (onDelete) {
                    onDelete()
                } else {
                    handleGetSingleMatch()
                }
            }
        } catch (error) {
            throw new Error(error)
        }
    }

    return (
        <Modal
            centered
            show={show}
            onHide={onHide}
            dialogClassName="create-draw-pair-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>Obriši meč</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <p>Da li ste sigurni da želite da obrišete meč?</p>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button type="text" onClick={onHide}>
                    Odustani
                </Button>
                <Button onClick={() => handleDeleteMatch()}>Obriši</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteMatchModal
