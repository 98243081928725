import { useEffect, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Col from 'react-bootstrap/Col'
import { Link, useLocation } from 'react-router-dom'
import { useAppContext } from '../../lib/contextLib'
import './styles.scss'

const SidebarLink = ({ link, setShow }) => {
    const { user, changesData } = useAppContext()
    const { pathname } = useLocation()
    const [isLinkActive, setIsLinkActive] = useState(false)
    const [hasChangesData, setHasChangesData] = useState(false)
    const [expandedItem, setExpandedItem] = useState('1')

    useEffect(() => {
        if (changesData && changesData.length > 0) {
            setHasChangesData(true)
        } else {
            setHasChangesData(false)
        }
    }, [changesData])

    useEffect(() => {
        switch (user?.role?.type) {
            case 'admin':
                if (pathname.substring(1).indexOf('/') > 0) {
                    if (
                        pathname.substring(
                            0,
                            pathname.substring(1).indexOf('/') + 1
                        ) === link.path
                    ) {
                        return setIsLinkActive(true)
                    } else {
                        return setIsLinkActive(false)
                    }
                }
                else if (link.subMenu.length > 0) {
                    let shortestLength = 0
                    let sublinkPaths = link.subMenu.reduce((acu, current) => {
                        if (
                            current.path.length < shortestLength ||
                            shortestLength === 0
                        ) {
                            shortestLength = current.path.length
                        }
                        return [...acu, current.path]
                    }, [])

                    sublinkPaths.includes(pathname) ||
                    sublinkPaths.filter((path) =>
                        path.includes(pathname.slice(0, shortestLength))
                    ).length > 0
                        ? setIsLinkActive(true)
                        : setIsLinkActive(false)
                } else {
                    if (pathname === link.path) {
                        return setIsLinkActive(true)
                    } else {
                        return setIsLinkActive(false)
                    }
                }
            case 'referee':
            case 'refereeadmin':
            case 'competitor':
                if (link.subMenu.length > 0) {
                    let shortestLength = 0
                    let sublinkPaths = link.subMenu.reduce((acu, current) => {
                        if (
                            current.path.length < shortestLength ||
                            shortestLength === 0
                        ) {
                            shortestLength = current.path.length
                        }
                        return [...acu, current.path]
                    }, [])

                    sublinkPaths.includes(pathname) ||
                    sublinkPaths.filter((path) =>
                        path.includes(pathname.slice(0, shortestLength))
                    ).length > 0
                        ? setIsLinkActive(true)
                        : setIsLinkActive(false)
                } else {
                    if (pathname.substring(1).indexOf('/') > 0) {
                        if (pathname.includes(link.path)) {
                            return setIsLinkActive(true)
                        } else {
                            return setIsLinkActive(false)
                        }
                    } else {
                        if (pathname === link.path) {
                            return setIsLinkActive(true)
                        } else {
                            return setIsLinkActive(false)
                        }
                    }
                }
        }
    }, [pathname])

    return link.subMenu.length > 0 ? (
        <Accordion
            className="sidebar-accordion"
            activeKey={expandedItem}
            onClick={() => {
                setExpandedItem((prev) => {
                    return prev === '0' ? '1' : '0'
                })
            }}
        >
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    <Col md={12} className="d-flex px-3 sidebar-link-wrapper">
                        <div className="d-flex align-items-center justify-content-start">
                            <div className="icon d-flex align-items-center justify-content-center">
                                {isLinkActive ? link.activeIcon : link.icon}
                            </div>
                            <p className="mb-0">{link.text}</p>
                        </div>
                    </Col>
                </Accordion.Header>
                <Accordion.Body className="px-0 py-0">
                    {link.subMenu.map((subLink, index) => (
                        <Link
                            key={index}
                            to={`${subLink.path}`}
                            state={
                                subLink?.state ? { type: subLink?.state } : null
                            }
                            onClick={() => {
                                setShow && setShow(false)
                            }}
                        >
                            <Col
                                md={12}
                                className={`d-flex px-3 mb-2 sidebar-link-wrapper ${
                                    (pathname === subLink.path ||
                                        pathname.indexOf(subLink.path) === 0) &&
                                    'active-link'
                                }`}
                            >
                                <div className="d-flex justify-content-between align-items-center">
                                    {pathname === subLink.path ||
                                    pathname.indexOf(subLink.path) === 0 ? (
                                        <h5 className="ms-4 ps-2">
                                            {subLink.text}
                                        </h5>
                                    ) : (
                                        <p className="ms-4 ps-2">
                                            {subLink.text}
                                        </p>
                                    )}
                                </div>
                            </Col>
                        </Link>
                    ))}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    ) : (
        <Link to={`${link.path}`}>
            <Col
                md={12}
                className={`d-flex sidebar-link-wrapper ${
                    isLinkActive && 'active-link'
                }`}
                onClick={() => {
                    setShow && setShow(false)
                }}
            >
                <div className="d-flex align-items-center justify-content-start">
                    <div className="icon d-flex align-items-center justify-content-center">
                        {isLinkActive ? link.activeIcon : link.icon}
                    </div>
                    {isLinkActive ? (
                        <h5>
                            {' '}
                            {link.text === 'Takmičari' && hasChangesData ? (
                                <span className="notification-wrapper">
                                    Takmičari&nbsp;
                                    <span className="notification">
                                        {changesData?.length}
                                    </span>
                                </span>
                            ) : link.text === 'Takmičari' ? (
                                'Takmičari'
                            ) : (
                                link.text
                            )}
                        </h5>
                    ) : (
                        <p>
                            {link.text === 'Takmičari' && hasChangesData ? (
                                <span className="notification-wrapper">
                                    Takmičari&nbsp;
                                    <span className="notification">
                                        {changesData?.length}
                                    </span>
                                </span>
                            ) : link.text === 'Takmičari' ? (
                                'Takmičari'
                            ) : (
                                link.text
                            )}
                        </p>
                    )}
                </div>
            </Col>
        </Link>
    )
}

export default SidebarLink
