import React from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import Button from '../Button'
const NotificationModal = ({ onHide, show }) => {
    return (
        <Modal
            centered
            show={show}
            onHide={onHide}
            dialogClassName="create-draw-pair-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>Obaveštenje</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <p>
                            Pre izmene liste učesnika grupe, neophodno je da
                            obrišete sve postojeće mečeve u grupu.
                        </p>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button type="text" onClick={onHide}>
                    Zatvori
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default NotificationModal
