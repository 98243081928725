import React from 'react'
import { Row, Col, Table } from 'react-bootstrap'
import { getKeys } from '../../../../../lib/helpers'
import TextField from '../../../../../components/TextField'
import { numbersOnly, notLeadingZero } from '../../../../../lib/helpers'

const TeamTournamentPointsTable = ({
    mainCategory,
    entryPoints,
    isEditable = false,
    setEntryPoints,
}) => {
    return (
        <Row>
            {getKeys(
                entryPoints?.entry_points_schema?.[`${mainCategory}`]?.points
            )?.map((tournamentCategory, index) => (
                <Col key={index} xs={12} md={4}>
                    <h4 className="mb-4">
                        {
                            entryPoints?.entry_points_schema?.[
                                `${mainCategory}`
                            ]?.points[`${tournamentCategory}`]?.name
                        }
                    </h4>

                    <Row>
                        {getKeys(
                            entryPoints?.entry_points_schema?.[
                                `${mainCategory}`
                            ]?.points[`${tournamentCategory}`]?.points
                        )?.map((competitionCategory, index) => (
                            <Col xs={12} key={index}>
                                <Table className="table-competitors-pagination-wrapper table-competitors-wrapper">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>
                                                <h5>{competitionCategory}</h5>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {getKeys(
                                            entryPoints?.entry_points_schema?.[
                                                `${mainCategory}`
                                            ]?.points[`${tournamentCategory}`]
                                                ?.points[
                                                `${competitionCategory}`
                                            ]?.board
                                        )?.map((board, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <p>Tabla {board}</p>
                                                </td>
                                                <td>
                                                    {isEditable ? (
                                                        <TextField
                                                            className="entry-points-input-field"
                                                            onChange={(e) => {
                                                                numbersOnly(e)
                                                                notLeadingZero(
                                                                    e
                                                                )
                                                                const newBoardValue =
                                                                    e.target
                                                                        .value // Vrednost koju korisnik unosi
                                                                setEntryPoints(
                                                                    (prev) => {
                                                                        // Kopiranje svega iz prev u novi objekat osim što ažuriramo specifične bodove
                                                                        return {
                                                                            ...prev,
                                                                            entry_points_schema:
                                                                                {
                                                                                    ...prev.entry_points_schema,
                                                                                    [`${mainCategory}`]:
                                                                                        {
                                                                                            ...prev
                                                                                                .entry_points_schema[
                                                                                                `${mainCategory}`
                                                                                            ],
                                                                                            points: {
                                                                                                ...prev
                                                                                                    .entry_points_schema[
                                                                                                    `${mainCategory}`
                                                                                                ]
                                                                                                    .points,
                                                                                                [`${tournamentCategory}`]:
                                                                                                    {
                                                                                                        ...prev
                                                                                                            .entry_points_schema[
                                                                                                            `${mainCategory}`
                                                                                                        ]
                                                                                                            .points[
                                                                                                            `${tournamentCategory}`
                                                                                                        ],
                                                                                                        points: {
                                                                                                            ...prev
                                                                                                                .entry_points_schema[
                                                                                                                `${mainCategory}`
                                                                                                            ]
                                                                                                                .points[
                                                                                                                `${tournamentCategory}`
                                                                                                            ]
                                                                                                                .points,
                                                                                                            [`${competitionCategory}`]:
                                                                                                                {
                                                                                                                    ...prev
                                                                                                                        .entry_points_schema[
                                                                                                                        `${mainCategory}`
                                                                                                                    ]
                                                                                                                        .points[
                                                                                                                        `${tournamentCategory}`
                                                                                                                    ]
                                                                                                                        .points[
                                                                                                                        `${competitionCategory}`
                                                                                                                    ],
                                                                                                                    board: {
                                                                                                                        ...prev
                                                                                                                            .entry_points_schema[
                                                                                                                            `${mainCategory}`
                                                                                                                        ]
                                                                                                                            .points[
                                                                                                                            `${tournamentCategory}`
                                                                                                                        ]
                                                                                                                            .points[
                                                                                                                            `${competitionCategory}`
                                                                                                                        ]
                                                                                                                            .board,
                                                                                                                        [`${board}`]:
                                                                                                                            newBoardValue, // Ažuriranje vrednosti bodova za ovu tablu
                                                                                                                    },
                                                                                                                },
                                                                                                        },
                                                                                                    },
                                                                                            },
                                                                                        },
                                                                                },
                                                                        }
                                                                    }
                                                                )
                                                            }}
                                                            type="tel"
                                                            value={
                                                                entryPoints
                                                                    ?.entry_points_schema?.[
                                                                    `${mainCategory}`
                                                                ]?.points[
                                                                    `${tournamentCategory}`
                                                                ]?.points[
                                                                    `${competitionCategory}`
                                                                ]?.board[
                                                                    `${board}`
                                                                ] || ''
                                                            }
                                                        >
                                                            {
                                                                entryPoints
                                                                    ?.entry_points_schema?.[
                                                                    `${mainCategory}`
                                                                ]?.points[
                                                                    `${tournamentCategory}`
                                                                ]?.points[
                                                                    `${competitionCategory}`
                                                                ]?.board[
                                                                    `${board}`
                                                                ]
                                                            }
                                                        </TextField>
                                                    ) : (
                                                        <p>
                                                            {
                                                                entryPoints
                                                                    ?.entry_points_schema?.[
                                                                    `${mainCategory}`
                                                                ]?.points[
                                                                    `${tournamentCategory}`
                                                                ]?.points[
                                                                    `${competitionCategory}`
                                                                ]?.board[
                                                                    `${board}`
                                                                ]
                                                            }
                                                        </p>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        ))}
                    </Row>
                </Col>
            ))}
        </Row>
    )
}

export default TeamTournamentPointsTable
