import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import NoDataForViewIlustration from '../../assets/svg/NoDataForViewIlustration'
import Button from '../Button'
import './styles.scss'

const NoDataForView = (props) => {
    const { title, description, buttonText, onClick, column, className } = props
    return (
        <Card className={`no-data-for-view-wrapper ${className}`}>
            <Card.Body>
                <Row
                    className={`d-flex align-items-center px-3 ${
                        column ? 'flex-column' : ''
                    }`}
                >
                    <Col
                        className={`d-block ${
                            column ? '' : 'd-md-none'
                        } m-auto p-2 d-flex justify-content-center`}
                    >
                        <NoDataForViewIlustration />
                    </Col>
                    <Col
                        className={`d-flex flex-column align-items-center ${
                            column ? '' : 'align-items-md-start'
                        }`}
                    >
                        <h4 className={`${column ? 'text-center' : ''}`}>
                            {title}
                        </h4>
                        <p className={`mt-2 ${column ? 'text-center' : ''}`}>
                            {description}
                        </p>
                        {buttonText && (
                            <Button onClick={onClick} className="button-margin">
                                {buttonText}
                            </Button>
                        )}
                    </Col>
                    <Col className={`d-none ${column ? '' : 'd-md-flex'}`}>
                        <NoDataForViewIlustration />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default NoDataForView
