import { useEffect, useState } from 'react'
import {
    Col,
    Container,
    Dropdown,
    DropdownButton,
    Nav,
    Navbar,
    Offcanvas,
    Row,
} from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import logoTss from '../../assets/images/logo-longer.png'
import avatar from '../../assets/images/maleAvatar.png'
// import ColorFilter from '../../assets/svg/ColorFilter'
// import ColorFilterOutlined from '../../assets/svg/ColorFilterOutlined'
import DocumentText from '../../assets/svg/DocumentText'
import DocumentTextOutlined from '../../assets/svg/DocumentTextOutlined'
import ExitOutlined from '../../assets/svg/ExitOutlined'
import Flag from '../../assets/svg/Flag'
import FlagOutlined from '../../assets/svg/FlagOutlined'
import Grid from '../../assets/svg/Grid'
import GridOutlined from '../../assets/svg/GridOutlined'
import Home from '../../assets/svg/Home'
import HomeOutlined from '../../assets/svg/HomeOutlined'
import People from '../../assets/svg/People'
import PeopleOutlined from '../../assets/svg/PeopleOutlined'
import Person from '../../assets/svg/Person'
import PersonOutlined from '../../assets/svg/PersonOutlined'
import Podium from '../../assets/svg/Podium'
import PodiumOutlined from '../../assets/svg/PodiumOutlined'
import TennisBall from '../../assets/svg/TennisBall'
import TennisBallOutlined from '../../assets/svg/TennisBallOutlined'
import { useAppContext } from '../../lib/contextLib'
import { clearSession } from '../../lib/helpers'
import { generateAvatar } from '../../utils/generateAvatar'
import ProfileInfo from '../ProfileInfo'
import SidebarLink from '../SidebarLink'
import './styles.scss'

const adminLinks = [
    {
        text: 'Početna',
        path: '/pocetna',
        icon: <HomeOutlined />,
        activeIcon: <Home />,
        subMenu: [],
    },
    {
        text: 'Takmičari',
        path: '/takmicari',
        icon: <PeopleOutlined />,
        activeIcon: <People />,
        subMenu: [],
    },
    {
        text: 'Takmičenja',
        path: '/takmicenja',
        icon: <GridOutlined />,
        activeIcon: <Grid />,
        subMenu: [],
    },
    //
    {
        text: 'Rang liste',
        icon: <PodiumOutlined />,
        activeIcon: <Podium />,
        subMenu: [
            {
                text: 'Klubova',
                path: '/rang-lista-klubova',
                icon: <PodiumOutlined />,
                activeIcon: <Podium />,
            },
            {
                text: 'Takmičara',
                path: '/rang-lista-takmicara',
                icon: <PodiumOutlined />,
                activeIcon: <Podium />,
            },
        ],
    },
    {
        text: 'Sudije',
        path: '/sudije',
        icon: <FlagOutlined />,
        activeIcon: <Flag />,
        subMenu: [],
    },
    {
        text: 'Klubovi',
        path: '/klubovi',
        icon: <TennisBallOutlined />,
        activeIcon: <TennisBall />,
        subMenu: [],
    },
    {
        text: 'Treneri',
        path: '/treneri',
        icon: <PersonOutlined />,
        activeIcon: <Person />,
        subMenu: [],
    },
]
const competitorLinks = [
    {
        text: 'Početna',
        path: '/takmicari-pocetna',
        icon: <HomeOutlined />,
        activeIcon: <Home />,
        subMenu: [],
    },
    {
        text: 'Takmičenja',
        path: '/takmicar/takmicenja',
        icon: <GridOutlined />,
        activeIcon: <Grid />,
        subMenu: [],
    },
    {
        text: 'Rang liste',
        icon: <PodiumOutlined />,
        activeIcon: <Podium />,
        subMenu: [
            {
                text: 'Klubova',
                path: '/takmicar/rang-lista-klubova',
                icon: <PodiumOutlined />,
                activeIcon: <Podium />,
            },
            {
                text: 'Takmičara',
                path: '/takmicar/rang-lista-takmicara',
                icon: <PodiumOutlined />,
                activeIcon: <Podium />,
            },
        ],
    },
]

const competitorDraftLinks = [
    {
        text: 'Početna',
        path: '/takmicar/pocetna',
        icon: <HomeOutlined />,
        activeIcon: <Home />,
        subMenu: [],
    },
    {
        text: 'Rang liste',
        icon: <PodiumOutlined />,
        activeIcon: <Podium />,
        subMenu: [
            {
                text: 'Klubova',
                path: '/takmicar/rang-lista-klubova',
                icon: <PodiumOutlined />,
                activeIcon: <Podium />,
            },
            {
                text: 'Takmičara',
                path: '/takmicar/rang-lista-takmicara',
                icon: <PodiumOutlined />,
                activeIcon: <Podium />,
            },
        ],
    },
]

const refereeLinks = [
    {
        text: 'Početna',
        path: '/sudije/pocetna',
        icon: <HomeOutlined />,
        activeIcon: <Home />,
        subMenu: [],
    },
    {
        text: 'Takmičenja',
        icon: <GridOutlined />,
        activeIcon: <Grid />,
        subMenu: [
            {
                text: 'Iz kalendara TSS',
                path: '/sudije/takmicenja-iz-kalendara-TSS',
                icon: <PodiumOutlined />,
                activeIcon: <Podium />,
            },
            {
                text: 'Individualna',
                path: '/sudije/individualna-takmicenja',
                state: 'Individualno',
                icon: <PodiumOutlined />,
                activeIcon: <Podium />,
            },
            {
                text: 'Ekipna',
                path: '/sudije/ekipna-takmicenja',
                state: 'Ekipno',
                icon: <PodiumOutlined />,
                activeIcon: <Podium />,
            },
        ],
    },
]
const refereeAdminLinks = [
    {
        text: 'Početna',
        path: '/administrator-sudjenja/pocetna',
        icon: <HomeOutlined />,
        activeIcon: <Home />,
        subMenu: [],
    },
    {
        text: 'Takmičenja',
        icon: <GridOutlined />,
        activeIcon: <Grid />,
        subMenu: [
            {
                text: 'Iz kalendara TSS',
                path: '/administrator-sudjenja/takmicenja-iz-kalendara-TSS',
                icon: <PodiumOutlined />,
                activeIcon: <Podium />,
            },
            {
                text: 'Individualna',
                path: '/administrator-sudjenja/individualna-takmicenja',
                state: 'Individualno',
                icon: <PodiumOutlined />,
                activeIcon: <Podium />,
            },
            {
                text: 'Ekipna',
                path: '/administrator-sudjenja/ekipna-takmicenja',
                state: 'Ekipno',
                icon: <PodiumOutlined />,
                activeIcon: <Podium />,
            },
        ],
    },
]

const Header = () => {
    const navigate = useNavigate()
    const [show, setShow] = useState(false)
    const [showProfileOptions, setShowProfileOptions] = useState(false)
    const { user, setAuthenticated } = useAppContext()

    const toggleOffCanvas = () => {
        setShow((show) => !show)
    }

    const handleRedirectToHome = () => {
        setAuthenticated(false)
        navigate('/')
    }

    // Role handler
    const linksHandler = () => {
        switch (user?.role?.type) {
            case 'admin':
                return adminLinks
            case 'competitor':
                if (user?.data?.attributes?.status?.status_name === 'draft') {
                    return competitorDraftLinks
                } else {
                    return competitorLinks
                }
            case 'referee':
                if (user?.data?.attributes?.is_administrator) {
                    return refereeAdminLinks
                } else {
                    return refereeLinks
                }
        }
    }

    // Avatar handler

    const avatarHandler = () => {
        if (user?.role?.type === 'admin') {
            if (user?.data?.attributes?.person_info?.picture) {
                return user?.data?.attributes?.person_info?.picture
            } else {
                return avatar
            }
        } else {
            if (user?.data?.attributes?.person_info?.picture) {
                return user?.data?.attributes?.person_info?.picture
            } else {
                return generateAvatar(
                    `${user?.data?.attributes?.person_info?.given_name} ${user?.data?.attributes?.person_info?.family_name}`
                )
            }
        }
    }

    useEffect(() => {
        linksHandler()
    }, [user])

    const handleExit = () => {
        clearSession()
        setAuthenticated(false)
        navigate('/')
    }

    return (
        <Navbar expand={'md'} className="py-3 mb-2">
            <Container fluid>
                <Navbar.Toggle
                    aria-controls={`offcanvasNavbar-expand-md`}
                    onClick={toggleOffCanvas}
                />

                <Row className="d-block d-md-none">
                    <Col className="d-flex justify-content-end align-items-center">
                        <DropdownButton
                            className="d-flex profile-image-dropdown"
                            id="dropdown-basic-button"
                            align="end"
                            title={
                                <img
                                    className="ms-4 header-profile-image"
                                    src={avatarHandler()}
                                />
                            }
                        >
                            <Dropdown.Item
                                className="d-flex align-items-center"
                                onClick={handleExit}
                            >
                                <ExitOutlined
                                    width={24}
                                    height={24}
                                    className="me-2"
                                />
                                <p>Odjavi se</p>
                            </Dropdown.Item>
                        </DropdownButton>
                    </Col>
                </Row>

                <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-md`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-md`}
                    placement="start"
                    show={show}
                    onHide={toggleOffCanvas}
                >
                    <Offcanvas.Header closeButton>
                        <img
                            className="tss-logo-header"
                            src={logoTss}
                            alt="logo-tss"
                            // style={{ width: '80%' }}
                            onClick={handleRedirectToHome}
                        />
                    </Offcanvas.Header>

                    <Offcanvas.Body>
                        <Row className="d-block d-md-none">
                            <Col className="mb-3">
                                <ProfileInfo
                                    avatar={avatarHandler()}
                                    name={
                                        user?.data?.attributes?.person_info
                                            ?.given_name
                                            ? user?.data?.attributes
                                                  ?.person_info?.given_name
                                            : 'Administrator'
                                    }
                                    surname={
                                        user?.data?.attributes?.family_name
                                            ? user?.data?.attributes
                                                  ?.family_name
                                            : ''
                                    }
                                    email={user?.email}
                                    alt={avatar}
                                />
                            </Col>
                            <hr></hr>
                        </Row>
                        <Nav className="justify-content-end flex-grow-1 px-0">
                            <Row className="d-block d-md-none">
                                <Col className="px-0">
                                    {linksHandler()?.map((link, index) => (
                                        <div
                                            key={`${index}-${link}`}
                                            className="pb-2"
                                        >
                                            <SidebarLink
                                                key={index}
                                                link={link}
                                                setShow={setShow}
                                            />
                                        </div>
                                    ))}
                                </Col>
                            </Row>

                            <Row className="d-none d-md-block">
                                <Col className="d-flex justify-content-end align-items-center">
                                    <DropdownButton
                                        className="d-flex profile-image-dropdown"
                                        id="dropdown-basic-button"
                                        align="end"
                                        title={
                                            <img
                                                className="ms-4 header-profile-image"
                                                src={avatarHandler()}
                                            />
                                        }
                                    >
                                        <Dropdown.Item
                                            className="d-flex align-items-center"
                                            onClick={handleExit}
                                        >
                                            <ExitOutlined
                                                width={24}
                                                height={24}
                                                className="me-2"
                                            />
                                            <p>Odjavi se</p>
                                        </Dropdown.Item>
                                    </DropdownButton>
                                </Col>
                            </Row>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    )
}

export default Header
