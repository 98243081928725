import React, { useEffect, useState } from 'react'
import { Offcanvas, Col, Row } from 'react-bootstrap'
import TextField from '../../../../components/TextField'
import MultiSelectSearch from '../../../../components/MultiSelectSearch'
import Button from '../../../../components/Button'
import Checkbox from '../../../../components/Checkbox'
import { getCodebook } from '../../../../lib/api'
const FiltersOffcanvas = ({
    setFilterParams,
    filterParams,
    show,
    onHide,
    handleFilters,
    handleRemoveFilters,
}) => {
    const [regions, setRegions] = useState([])
    const handleCodebook = async () => {
        await getCodebook('codebooks.region', setRegions)
    }

    useEffect(() => {
        handleCodebook()
    }, [])
    return (
        <Offcanvas show={show} onHide={onHide} placement="end">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                    <h3>Filtriraj</h3>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <hr className="p-0 mt-2 mb-2" />
            <Offcanvas.Body>
                <Row>
                    <Col md={12}>
                        <TextField
                            type="date"
                            value={filterParams?.period_from || ''}
                            label="Datum od"
                            placeholder="Datum od"
                            onChange={(e) =>
                                setFilterParams((prev) => ({
                                    ...prev,
                                    period_from: e.target.value,
                                }))
                            }
                        />
                    </Col>

                    <Col md={12} className="mt-3">
                        <TextField
                            type="date"
                            value={filterParams?.period_to || ''}
                            label="Datum do"
                            placeholder="Datum do"
                            onChange={(e) =>
                                setFilterParams((prev) => ({
                                    ...prev,
                                    period_to: e.target.value,
                                }))
                            }
                        />
                    </Col>
                    <Col xs={12} className="mt-3">
                        <MultiSelectSearch
                            label={'Region'}
                            animated
                            options={
                                regions
                                    ? regions
                                          .filter((region) => region !== 'TSS')
                                          .reduce(
                                              (accumulator, region) => [
                                                  ...accumulator,
                                                  {
                                                      id: region,
                                                      value: region,
                                                  },
                                              ],
                                              []
                                          )
                                    : []
                            }
                            noOptionsMessage={'Nema rezultata'}
                            onChange={(e) => {
                                e?.length === 0
                                    ? setFilterParams((prev) => ({
                                          ...prev,
                                          region: null,
                                      }))
                                    : setFilterParams((prev) => ({
                                          ...prev,
                                          region: e,
                                      }))
                            }}
                            isMulti={true}
                            value={filterParams?.region}
                            placeholder="Region"
                        />
                    </Col>

                    <Col xs={12} className="mt-3">
                        <Checkbox
                            id="inactive"
                            onChange={() =>
                                setFilterParams((prev) => ({
                                    ...prev,
                                    regional_only: !prev?.regional_only,
                                }))
                            }
                            checked={filterParams?.regional_only}
                            label="Samo regionalna takmičenja"
                        />
                    </Col>

                    <Col md={12} className="mt-3">
                        <Button
                            className="w-100"
                            onClick={() => handleFilters()}
                        >
                            Prikaži rezultate
                        </Button>
                    </Col>
                    <Col md={12} className="mt-3">
                        <Button
                            type="text"
                            className="w-100"
                            onClick={() => handleRemoveFilters()}
                        >
                            Ukloni filtriranje
                        </Button>
                    </Col>
                </Row>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default FiltersOffcanvas
