import React, { useEffect, useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { registerUser } from '../../lib/api'
import { useAppContext } from '../../lib/contextLib'
import Button from '../Button'
import TextField from '../TextField'

function PlayerInvitationModal({
    showInvitationModal,
    setShowInvitationModal,
    currentPage,
    setCurrentPage,
    getAllCompetitors,
}) {
    const { setShowCustomToast } = useAppContext()
    const [playerInvitationData, setPlayerInvitationData] = useState({
        given_name: '',
        family_name: '',
        email: '',
    })
    const [invitationSending, setInvitationSending] = useState(false)
    const [disableInvitationConfirm, setDisableInvitationConfirm] =
        useState(true)

    // Modal close
    const handleInvitationModalClose = () => {
        setPlayerInvitationData((prev) => {
            return {
                ...prev,
                given_name: '',
                family_name: '',
                email: '',
            }
        })
        setShowInvitationModal(false)
    }

    // Invitation Submit
    const handlePlayerInvitationSubmit = async () => {
        setInvitationSending(true)
        const auth = {
            email: playerInvitationData?.email,
            password: 'Password1!',
            role: 'competitor',
        }

        const user_info = {
            person_info: {
                given_name: playerInvitationData?.given_name,
                family_name: playerInvitationData?.family_name,
                email: playerInvitationData?.email,
            },
            status: {
                status_name: 'draft',
            },
        }

        try {
            let data = await registerUser(auth, user_info, null)

            if (data) {
                setShowCustomToast({
                    show: true,
                    type: 'success',
                    message: 'Takmičar uspešno pozvan.',
                })
                handleInvitationModalClose()
                setInvitationSending(false)
                currentPage === 1
                    ? getAllCompetitors(currentPage)
                    : setCurrentPage(1)
            }
        } catch (error) {
            switch (error.response.data.error.message) {
                case 'Email or Username are already taken':
                    setShowCustomToast({
                        show: true,
                        type: 'error',
                        message: 'Takmičar sa unetim emailom već postoji.',
                    })
                    setInvitationSending(false)
                    break
                case 'Register action is currently disabled':
                    setShowCustomToast({
                        show: true,
                        type: 'error',
                        message: 'Registracija trenutno nije moguća.',
                    })
                    setInvitationSending(false)
                    break

                default:
                    setShowCustomToast({
                        show: true,
                        type: 'error',
                        message: 'Došlo je do greške.',
                    })
                    setInvitationSending(false)
                    break
            }
        }
        setInvitationSending(false)
    }

    // Validate Email Regex
    const validateEmail = (email) => {
        var re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase())
    }

    // Enabling / Disabling Invitation Send
    useEffect(() => {
        if (
            playerInvitationData?.given_name &&
            playerInvitationData?.family_name &&
            validateEmail(playerInvitationData?.email)
        ) {
            setDisableInvitationConfirm(false)
        } else {
            setDisableInvitationConfirm(true)
        }
    }, [playerInvitationData])

    return (
        <Modal
            size="md"
            centered
            show={showInvitationModal}
            onHide={handleInvitationModalClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h3>Pozivanje takmičara</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* <p className="mb-2 text-center">{modalText}</p> */}
                <TextField
                    type="text"
                    className="mb-3"
                    label="Ime takmičara"
                    placeholder="Ime"
                    required
                    onChange={(e) =>
                        setPlayerInvitationData((prev) => {
                            return { ...prev, given_name: e.target.value }
                        })
                    }
                />
                <TextField
                    type="text"
                    className="mb-3"
                    label="Prezime takmičara"
                    placeholder="Prezime"
                    required
                    onChange={(e) =>
                        setPlayerInvitationData((prev) => {
                            return { ...prev, family_name: e.target.value }
                        })
                    }
                />
                <TextField
                    type="text"
                    label="Email takmičara"
                    placeholder="Email"
                    required
                    onChange={(e) =>
                        setPlayerInvitationData((prev) => {
                            return { ...prev, email: e.target.value }
                        })
                    }
                />
            </Modal.Body>
            <Modal.Footer>
                <Button type="text" onClick={handleInvitationModalClose}>
                    Odustani
                </Button>
                <Button
                    onClick={handlePlayerInvitationSubmit}
                    disabled={disableInvitationConfirm || invitationSending}
                    leadingIcon={
                        invitationSending ? (
                            <Spinner
                                animation="border"
                                variant="primary"
                                size="sm"
                                className="invitation-spinner"
                            />
                        ) : null
                    }
                >
                    Pošalji
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default PlayerInvitationModal
