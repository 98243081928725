import './styles.scss'

const TextField = (props) => {
    const {
        className,
        id,
        label,
        required,
        onChange,
        endIcon,
        onIconClick,
        children,
        ...inputProps
    } = props
    return (
        <div className={`input-wrapper ${className}`}>
            {label && (
                <label htmlFor={id} className="mb-2 d-flex">
                    {label && <h5 className="me-1">{label}</h5>}
                    {required && <h6 className="required">*</h6>}
                </label>
            )}
            {!endIcon && <input id={id} onChange={onChange} {...inputProps} />}
            {endIcon && (
                <div style={{ position: 'relative' }}>
                    {children ? (
                        <input id={id} onChange={onChange} {...inputProps}>
                            {children}
                        </input>
                    ) : (
                        <input id={id} onChange={onChange} {...inputProps} />
                    )}
                    <div
                        className="end-icon"
                        style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            top: '20%',
                            right: '4%',
                        }}
                        onClick={onIconClick}
                    >
                        {endIcon}
                    </div>
                </div>
            )}
        </div>
    )
}

export default TextField
