import { useEffect, useState } from 'react'
import { Row, Col, ProgressBar } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import CloseOutlined from '../../../../assets/svg/CloseOutlined'
import Button from '../../../../components/Button'
import {
    createCoach,
    createCoachLicenses,
    createCoachsClub,
    getCoachLicenses,
    getSingleCoach,
    updateCoach,
    updateCoachLicenses,
    getCoachsClub,
    updateCoachsClub,
    getSingleClubWithQuery,
} from '../../../../lib/api'
import { useAppContext } from '../../../../lib/contextLib'
import { isObjectEmpty } from '../../../../lib/helpers'
import BasicInfo from './BasicInfo'
import LicenceInfo from './LicenseInformation'
import './styles.scss'

const CreateNewCoach = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const { setShowCustomToast } = useAppContext()
    const [page, setPage] = useState(0)
    const [progress, setProgress] = useState(50)

    const [coachImg, setCoachImg] = useState(null)
    const [coachPreviewImg, setCoachPreviewImg] = useState(null)

    const [formData, setFormData] = useState({
        person_info: {
            given_name: '',
            family_name: '',
            email: '',
            phone: '',
            birthdate: '',
            jmbg: '',
        },
        gender: {
            gender_name: '',
        },
    })

    const [coachsClub, setCoachsClub] = useState()

    const [licenses, setLicenses] = useState({})

    const setLicenseField = (field, value) => {
        return setLicenses((prevData) => {
            return {
                ...prevData,
                [field]: { ...prevData[field], [`${field}_name`]: value },
            }
        })
    }

    const [disableNextButton, setDisableNextButton] = useState(true)

    const handleRequiredFields = () => {
        if (page === 0) {
            if (
                formData?.person_info?.given_name &&
                formData?.person_info?.family_name &&
                formData?.person_info?.birthdate &&
                formData?.person_info?.email &&
                formData?.gender?.gender_name
            ) {
                setDisableNextButton(false)
            } else {
                setDisableNextButton(true)
            }
        } else {
            setDisableNextButton(false)
        }
    }

    const handleClubSelect = (e) => {
        const { uuid } = e
        setCoachsClub({ club_uuid: uuid })
    }

    useEffect(() => {
        handleRequiredFields()
    }, [formData, page])

    const FormTitles = ['Osnovni podaci', 'Podaci o licenci']

    const PageDisplay = () => {
        switch (page) {
            case 0:
                return (
                    <BasicInfo
                        setProgress={setProgress}
                        formData={formData}
                        setFormData={setFormData}
                        setCoachImg={setCoachImg}
                        coachPreviewImg={coachPreviewImg}
                        setCoachPreviewImg={setCoachPreviewImg}
                        handleClubSelect={handleClubSelect}
                        coachsClub={coachsClub}
                    />
                )
            case 1:
                return (
                    <LicenceInfo
                        setProgress={setProgress}
                        licenses={licenses}
                        setLicenseField={setLicenseField}
                        setLicenses={setLicenses}
                    />
                )
            default:
                break
        }
    }

    const handleCreateCoach = async () => {
        try {
            let coachData = await createCoach(formData, coachImg)

            if (coachData) {
                if (licenses && !isObjectEmpty(licenses)) {
                    licenses.coach_uuid = coachData?.attributes?.uuid
                    await createCoachLicenses(licenses)
                }
                if (coachsClub && !isObjectEmpty(coachsClub)) {
                    coachsClub.coach_uuid = coachData?.attributes?.uuid
                    await createCoachsClub(coachsClub)
                }

                setShowCustomToast({
                    show: true,
                    type: 'success',
                    message: 'Trener uspešno kreiran.',
                })
                navigate('/treneri')
            }
        } catch (error) {
            setShowCustomToast({
                show: true,
                type: 'error',
                message: 'Došlo je do greške.',
            })
        }
    }

    const handleUpdateCoach = async (id) => {
        try {
            const updatedCoach = await updateCoach(id, formData, coachImg)

            if (updatedCoach) {
                setShowCustomToast({
                    show: true,
                    type: 'success',
                    message: 'Trener uspešno ažuriran.',
                })
                navigate('/treneri')
            }
        } catch (error) {
            setShowCustomToast({
                show: true,
                type: 'error',
                message: 'Došlo je do greške.',
            })
        }
    }

    const handleUpdateCoachLicenses = async (id) => {
        if (licenses && !isObjectEmpty(licenses)) {
            try {
                if (licenses?.uuid) {
                    let updatedLicenses = await updateCoachLicenses(
                        licenses?.uuid,
                        licenses
                    )
                    if (updatedLicenses) {
                        setShowCustomToast({
                            show: true,
                            type: 'success',
                            message: 'Trener uspešno ažuriran.',
                        })
                    }
                } else {
                    licenses.coach_uuid = id
                    let updatedLicenses = await createCoachLicenses(licenses)
                    if (updatedLicenses) {
                        setShowCustomToast({
                            show: true,
                            type: 'success',
                            message: 'Trener uspešno ažuriran.',
                        })
                    }
                }
            } catch (error) {
                setShowCustomToast({
                    show: true,
                    type: 'error',
                    message: 'Došlo je do greške.',
                })
            }
        }
    }

    const handleUpdateCoachsClub = async (id) => {
        if (coachsClub && !isObjectEmpty(coachsClub)) {
            try {
                if (coachsClub?.uuid) {
                    await updateCoachsClub(coachsClub?.uuid, coachsClub)
                } else {
                    coachsClub.coach_uuid = id
                    await createCoachsClub(coachsClub)
                }

                setShowCustomToast({
                    show: true,
                    type: 'success',
                    message: 'Trener uspešno ažuriran.',
                })
            } catch (error) {
                setShowCustomToast({
                    show: true,
                    type: 'error',
                    message: 'Došlo je do greške.',
                })
            }
        }
    }

    const handleClickButton1 = () => {
        setPage((currPage) => currPage - 1)
    }

    const handleClickButton2 = () => {
        if (page === FormTitles.length - 1) {
            if (id) {
                handleUpdateCoach(id)
                handleUpdateCoachLicenses(id)
                handleUpdateCoachsClub(id)
            } else {
                handleCreateCoach()
            }
        } else {
            setPage((currPage) => currPage + 1)
        }
    }

    // Remove id fields from component objects and also remove certain fields from default response
    // which are not needed for update form
    function removeIdFields(obj) {
        const result = {}

        for (let key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                result[key] = removeIdFields(obj[key])
            } else if (
                key !== 'id' &&
                key !== 'createdAt' &&
                key !== 'updatedAt' &&
                key !== 'locale' &&
                obj[key] !== null
            ) {
                result[key] = obj[key]
            }
        }
        return result
    }

    const getCoachProfile = async (id) => {
        let coach = await getSingleCoach(id)
        if (coach) {
            setFormData(removeIdFields(coach?.attributes))
            coach?.attributes?.person_info?.picture &&
                setCoachPreviewImg(coach?.attributes?.person_info?.picture)
        } else {
            navigate('/treneri')
        }
    }

    const getAllCoachLicenses = async (id) => {
        let coach = await getCoachLicenses(id)
        if (coach) {
            setLicenses(removeIdFields(coach?.attributes))
        }
    }

    const handeGetCoachsClub = async (id) => {
        const coachsClubData = await getCoachsClub(id)
        if (coachsClubData) {
            const clubData = await getSingleClubWithQuery(
                coachsClubData?.attributes?.club_uuid,
                'fields[0]=name&fields[1]=short_name&fields[2]=uuid'
            )
            setCoachsClub(
                removeIdFields({
                    ...coachsClubData?.attributes,
                    club: clubData,
                })
            )
        }
    }

    useEffect(() => {
        if (id) {
            getCoachProfile(id)
            getAllCoachLicenses(id)
            handeGetCoachsClub(id)
        }
    }, [id])

    return (
        <Row className="create-new-coach-wrapper justify-content-center">
            <Col className="create-coach-wrapper px-0">
                <Row className="justify-content-center">
                    <ProgressBar now={progress} className="px-0" />
                    <Col className="d-flex flex-column align-items-center justify-content-end close-icon mt-2 mt-md-3 me-md-3">
                        <Row className="w-100 align-items-center justify-content-end">
                            <Link
                                to="/treneri"
                                className="d-flex justify-content-end"
                            >
                                <CloseOutlined />
                            </Link>
                        </Row>
                    </Col>
                    <Row className="coach-form-wrapper justify-content-center">
                        <Col md={4}>
                            <Row className="mt-2">
                                <Col
                                    md={12}
                                    className="d-flex align-items-center justify-content-center"
                                >
                                    <h1>{FormTitles[page]}</h1>
                                </Col>
                                <Col
                                    md={12}
                                    className="mt-2 mb-4 d-flex align-items-center justify-content-center"
                                >
                                    <p className="small-text">{`${page + 1}/${
                                        FormTitles.length
                                    }`}</p>
                                </Col>
                                {/* content based on component */}
                                <Col className="mt-2 align-items-center justify-content-center">
                                    {PageDisplay()}
                                </Col>
                                <Col
                                    md={12}
                                    className="mt-4 d-flex flex-md-row flex-column align-items-center justify-content-end form-footer-buttons"
                                >
                                    <Link
                                        to={page === 0 && '/treneri'}
                                        className="link-text footer-button"
                                        tabIndex="1"
                                    >
                                        <Button
                                            type="text"
                                            className="footer-button"
                                            onClick={handleClickButton1}
                                        >
                                            {page === 0
                                                ? 'Odustani'
                                                : 'Prethodna'}
                                        </Button>
                                    </Link>
                                    <Button
                                        className="footer-button"
                                        onClick={handleClickButton2}
                                        disabled={disableNextButton}
                                    >
                                        {page === FormTitles.length - 1
                                            ? 'Sačuvaj'
                                            : 'Sledeća'}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Row>
            </Col>
        </Row>
    )
}

export default CreateNewCoach
