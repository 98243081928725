import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import TextField from '../../../../../../components/TextField'
import Button from '../../../../../../components/Button'
import { createScheduleDate } from '../../../../../../lib/api'

const AddScheduleDate = ({
    show,
    onHide,
    tournament,
    handleTournamentDates,
}) => {
    const [selectedDate, setSelectedDate] = useState('')

    const handleCreateScheduleDate = async () => {
        const newDate = await createScheduleDate({
            tournament_uuid: tournament?.attributes?.uuid,
            schedule_date: selectedDate,
        })

        if (newDate) {
            onHide()
            setSelectedDate('')
            handleTournamentDates()
        }
    }

    useEffect(() => {
        const today = new Date()
        const formattedDate = `${today.getFullYear()}-${String(
            today.getMonth() + 1
        ).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`
        setSelectedDate(formattedDate)
    }, [])
    return (
        <Modal
            centered
            show={show}
            onHide={onHide}
            dialogClassName="add-penalties-modal"
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>Dodavanje takmičarskog dana</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TextField
                    label="Takmičarski dan"
                    placeholder="Takmičarski dan"
                    type="date"
                    value={selectedDate || ''}
                    onChange={(e) => setSelectedDate(e.target.value)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button type="text" onClick={onHide}>
                    Zatvori
                </Button>
                <Button onClick={() => handleCreateScheduleDate()}>
                    Dodaj takmičarski dan
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddScheduleDate
