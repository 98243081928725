import Toast from '../components/Toast'
import {
    didPlayedInRound,
    getAllParticipantsWinsFromTournament,
    getClubs,
    getClubsForTournament,
    getCoaches,
    getEntryPoints,
    getLastFourMemberships,
    getMatchesFromTournament,
    getNumberOfPlayerWinsInRoundRobin,
    getPlayerDisciplinaryPenalties,
    getPlayers,
    getPlayersCurrentClub,
    getPlayersForTournament,
    getPlayersLastMatchOnTournament,
    getReferees,
    getSingleClub,
    getSingleParticipant,
    getSinglePlayer,
    isParticipantAlreadyInGroup,
    isWinnerFromPrevRound,
    didClubPlayedInRound,
    isClubWinnerFromPrevRound,
} from './api'
import axios from './axiosInstance'
export const formatImgUrl = (imgUrl) => {
    if (imgUrl) {
        return `${process.env.REACT_APP_BASE_URL.slice(0, -1)}${imgUrl}`
    }
}

export const handleFieldsQuery = (fields) => {
    let fieldsQuery = ``
    fields.forEach((tag, index) => {
        let newString = `&fields[${index}]=${tag}`
        fieldsQuery = fieldsQuery.concat(newString)
    })

    return fieldsQuery
}

export const handleFiltersQuery = (fields) => {
    let filtersQuery = ``
    fields.forEach((tag, index) => {
        let newString = `&filters[$and][${index}][${tag.name}][$contains]=${tag.value}`
        filtersQuery = filtersQuery.concat(newString)
    })

    if (fields.length >= 1) {
        filtersQuery = filtersQuery.concat('&')
    }

    return filtersQuery
}

export const handlePopulateQuery = (query) => {
    let populate = '&populate=*'
    let combinedQuery = query + populate

    return combinedQuery
}

export const handlePopulateImg = (query) => {
    let populate = '&populate=img'
    let combinedQuery = query + populate

    return combinedQuery
}
export const formatDate = (date) => {
    if (date) {
        let options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }
        return new Date(date).toLocaleDateString('sr-SR', options)
    } else {
        return ''
    }
}

export const formatDateWithDay = (date) => {
    if (date) {
        let options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            weekday: 'long',
        }
        let result = new Date(date).toLocaleDateString('sr-Latn-SR', options)
        return result.charAt(0).toUpperCase() + result.slice(1)
    } else {
        return ''
    }
}

export const formatDateExport = (date) => {
    return new Date(date).toLocaleDateString('en-CA')
}

export const formatTimeToDateFormat = (date) => {
    var dateString = date
    var [day, month, year] = dateString.split('.')
    var datum = new Date(year, month - 1, day)

    return datum.toLocaleDateString('en-CA')
}
export const formatTimeExport = (time) => {
    const input = time
    const date = new Date(`1970-01-01T${input}Z`)
    const hours = date.getUTCHours().toString().padStart(2, '0')
    const minutes = date.getUTCMinutes().toString().padStart(2, '0')
    const seconds = date.getUTCSeconds().toString().padStart(2, '0')
    const milliseconds = '000'
    const output = `${hours}:${minutes}:${seconds}.${milliseconds}`
    return output
}

export const formatTime = (time) => {
    return time?.substring(0, 5)
}

export const checkFileSize = (fileSize, limit) => {
    if (fileSize > limit) {
        alert('Fajl je prevelik.')
        return limit
    } else return
}

export const addPhoto = (e, setImg, setPreviewImg, checkFileSize) => {
    const fileReader = new FileReader()
    const file = e.target.files[0]
    const fileSizeKilobytes = file.size / 1024
    if (checkFileSize(fileSizeKilobytes, 2048)) return
    else {
        fileReader.onload = () => {
            setImg(file)
            setPreviewImg(fileReader.result)
        }
        fileReader.readAsDataURL(file)
    }
}

export const addMultiplePhoto = (
    e,
    imgs,
    setImgs,
    previwImgs,
    setPreviwImgs,
    checkFileSize
) => {
    const fileReader = new FileReader()
    const file = e.target.files[0]
    const fileSizeKilobytes = file.size / 1024
    if (checkFileSize(fileSizeKilobytes, 2048)) return
    else {
        fileReader.onload = () => {
            setImgs([...imgs, file])
            setPreviwImgs([...previwImgs, fileReader.result])
        }
        fileReader.readAsDataURL(file)
    }
}

export function debounce(cb, delay = 1000) {
    let timeout

    return (...args) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            cb(...args)
        }, delay)
    }
}

export const sleep = (ms = 1000) =>
    new Promise((resolve) => {
        setTimeout(() => {
            resolve()
        }, ms)
    })

export function checkJMBG(JMBG) {
    if (JMBG.length === 13) {
        return true
    }
    return false
}

// Function for forcing just numbers in input field type text
export function numbersOnly(event) {
    event.target.value = event.target.value.replace(/[^0-9]/gi, '')
}

export const saveDataInSession = (key, value) => {
    const data = { value, timestamp: new Date().getTime() }
    sessionStorage.setItem(key, JSON.stringify(data))
}

export const getDataFromSession = (key) => {
    const data = JSON.parse(sessionStorage.getItem(key))
    if (data) {
        return data.value
    } else {
        return null
    }
}

export const clearSession = () => {
    sessionStorage.removeItem('tssUserJwt')
}

export const toastHandler = ({
    error,
    success,
    warning,
    message,
    showToast,
    setShowToast,
}) => {
    let id = error?.response?.data?.error?.details
        ? error?.response?.data?.error?.details[0]?.messages[0]?.id
        : error?.response?.data?.error?.message

    if (error) {
        switch (id) {
            case 'Auth.form.error.password.provide':
                return (
                    <Toast
                        show={showToast}
                        type="error"
                        onClose={(e) => setShowToast(false)}
                        message="Unesite Vašu šifru."
                    />
                )
            case 'Auth.form.error.email.provide':
                return (
                    <Toast
                        show={showToast}
                        type="error"
                        onClose={(e) => setShowToast(false)}
                        message="Unesite Vašu email adresu."
                    />
                )
            case 'Auth.form.error.invalid':
                return (
                    <Toast
                        show={showToast}
                        type="error"
                        onClose={(e) => setShowToast(false)}
                        message="Neispravan email i/ili šifra."
                    />
                )

            default:
                return (
                    <Toast
                        show={showToast}
                        type="error"
                        message="Došlo je do greške."
                        onClose={(e) => setShowToast(false)}
                    />
                )
        }
    } else if (success) {
        return (
            <Toast
                show={showToast}
                type="success"
                message={message}
                onClose={() => setShowToast(false)}
            />
        )
    } else if (warning) {
        return (
            <Toast
                show={showToast}
                type="warning"
                message={message}
                onClose={() => setShowToast(false)}
            />
        )
    }
}

export const customToastHandler = (showCustomToast, setShowCustomToast) => {
    return (
        <Toast
            show={showCustomToast?.show}
            type={showCustomToast?.type}
            message={showCustomToast?.message}
            onClose={() => setShowCustomToast(false)}
        />
    )
}

export const handleShowDetail = (detailKey, detailValue) => {
    if (detailValue?.toString().length > 0) {
        return (
            <p className="mb-2">
                {detailKey}: {detailValue}
            </p>
        )
    } else {
        return null
    }
}

export const handleArrayDetail = (detailValue) => {
    let text = ''
    const length = detailValue?.length
    detailValue?.map(
        (item, index) =>
            (text += item.value + (index === length - 1 ? '' : ', '))
    )
    return text
}

export const handleShowYesNo = (booleanValue) => {
    if (booleanValue === true) {
        return 'DA'
    } else if (booleanValue === false) {
        return 'NE'
    } else {
        return ''
    }
}

export const isObjectEmpty = (objectName) => {
    return (
        Object.keys(objectName).length === 0 &&
        objectName.constructor === Object
    )
}

export const isArrayEmpty = (arrayName) => {
    return Array.isArray(arrayName) && !arrayName.length
}

export const isObject = (objectName) => {
    return objectName.constructor === Object
}

export const isArray = (arrayName) => {
    return Array.isArray(arrayName)
}

export const competitorPillStatusHandler = (status) => {
    switch (status) {
        case 'active':
            return 'Aktivan'
        case 'inactive':
            return 'Neaktivan'
        case 'draft':
            return 'Draft'
        case 'punished':
            return 'Kažnjen'
        default:
            return 'Neaktivan'
    }
}

export function calculateCategory(birthDate) {
    if (birthDate) {
        let today = new Date()
        let birthDateObj = new Date(birthDate)

        let years = today.getFullYear() - birthDateObj.getFullYear()

        if (years >= 6 && years <= 10) {
            return '10s'
        } else if (years > 10 && years <= 12) {
            return '12'
        } else if (years > 12 && years <= 14) {
            return '14'
        } else if (years > 14 && years <= 16) {
            return '16'
        } else if (years > 16 && years <= 18) {
            return '18'
        } else if (years > 18) {
            return 'Seniori'
        } else {
            return 'Kategorija ne postoji za datu starost.'
        }
    } else {
        return 'Potrebno je uneti datum rođenja'
    }
}

export const rangleOfYearsBasedOnPlayersCompetition = (playersCompetition) => {
    let startDate
    let endDate
    switch (playersCompetition) {
        case '10s':
            startDate = `${new Date().getFullYear() - 10}-01-01`
            endDate = null
            return {
                startDate,
                endDate,
            }
        case '12':
            startDate = `${new Date().getFullYear() - 12}-01-01`
            endDate = null
            return {
                startDate,
                endDate,
            }
        case '14':
            startDate = `${new Date().getFullYear() - 14}-01-01`
            endDate = null
            return {
                startDate,
                endDate,
            }
        case '16':
            startDate = `${new Date().getFullYear() - 16}-01-01`
            endDate = `${new Date().getFullYear() - 10}-01-01`
            return {
                startDate,
                endDate,
            }
        case '18':
            startDate = `${new Date().getFullYear() - 18}-01-01`
            endDate = `${new Date().getFullYear() - 12}-01-01`
            return {
                startDate,
                endDate,
            }
        case 'Seniori':
        case 'Seniorke':
        case 'I Liga Seniori':
            startDate = null
            endDate = `${new Date().getFullYear() - 14}-01-01`
            return {
                startDate,
                endDate,
            }
        default:
            startDate = null
            endDate = null
            return {
                startDate,
                endDate,
            }
    }
}

export const calculateSubcategory = (birthDate) => {
    let today = new Date()
    let birthDateObj = new Date(birthDate)

    let years = today.getFullYear() - birthDateObj.getFullYear()
    if (years >= 6 && years <= 10) {
        if (years >= 6 && years < 8) {
            return 'Crveni'
        } else if (years === 8) {
            return 'Narandžasti'
        } else if (years >= 9 && years <= 10) {
            return 'Zeleni'
        } else {
            return 'Kategorija ne postoji za datu starost.'
        }
    } else {
        return null
    }
}

export const handleApiGender = (gender) =>
    gender?.toLowerCase().startsWith('m') ? 'Muški' : 'Ženski'

export const getKeys = (entryObj) => {
    if (!entryObj) return
    return Object.keys(entryObj)
}

export const getValues = (entryObj) => {
    return Object.values(entryObj)
}

export const getEntries = (entryObj) => {
    return Object.entries(entryObj)
}
export const getClubList = async (
    searchValue,
    loadedOptions,
    { page },
    filterParams
) => {
    let name = searchValue.split(' ')[0]

    let search_params = {
        name,
    }
    let { clubs, pgCount } = await getClubs(
        ['name', 'uuid'],
        search_params,
        filterParams ? filterParams : null,
        page
    )

    if (clubs) {
        let list = []
        clubs.map((club) => {
            let clubDetails = {
                value: `${club?.attributes?.name}`,
                uuid: club?.attributes?.uuid,
            }
            list.push(clubDetails)
        })

        return {
            options: list,
            hasMore: page >= pgCount ? false : true,
            additional: {
                page: page + 1,
            },
        }
    }
}

export const getCoachList = async (searchValue, loadedOptions, { page }) => {
    let given_name = searchValue.split(' ')[0]
    let family_name = searchValue.split(' ')[1]

    let search_params = {
        given_name,
        family_name,
    }
    let { allCoaches, pgCount } = await getCoaches(
        ['given_name', 'family_name', 'uuid'],
        search_params,
        null,
        page
    )

    if (allCoaches) {
        let list = []
        allCoaches.map((coach) => {
            let coachDetails = {
                value: `${coach?.attributes?.person_info?.given_name} ${coach?.attributes?.person_info?.family_name}`,
                uuid: coach?.attributes?.uuid,
            }
            list.push(coachDetails)
        })

        return {
            options: list,
            hasMore: page >= pgCount ? false : true,
            additional: {
                page: page + 1,
            },
        }
    }
}

export const isEntryPointsDraft = (status) => {
    return status?.status_name === 'draft'
}

export function notLeadingZero(event) {
    event.target.value = event.target.value
        .replace(/^0+([1-9])/, '$1')
        .replace(/[^0-9]/g, '')
}

export const competitorsNumbersPerTournamentCategory = (tournament) => {
    const tournamentCategory =
        tournament?.tournament_category?.tournament_category_name
    const tournamentCompetitionName =
        tournament?.players_competition?.players_competition_name

    if (tournamentCompetitionName === '12') {
        return [8, 16]
    } else {
        switch (tournamentCategory) {
            case 'A':
                return [32]
            case 'TOUR':
                return [32]
            case 'A/S':
                return [32]
            case 'I':
                return [8, 16, 32, 48, 64]
            case 'A/Z':
                return [48, 64]
            case 'B':
                return [8, 16, 32, 48, 64]
            case 'II':
                return [8, 16, 32, 48, 64]
            case 'III':
                return [8, 16, 32, 48, 64, 128]
            default:
                return [4, 8, 16, 32, 48, 64, 128]
        }
    }
}

export const numberOfQualificationsParticipants = (tournament) => {
    const tournamentCompetitionName =
        tournament?.players_competition?.players_competition_name

    const qualificationsParticipantsNumber =
        tournament?.qualifications_participants_expected_number

    if (tournamentCompetitionName === '12') {
        return qualificationsParticipantsNumber
            ? ['Odselektuj', 8, 16]
            : [8, 16]
    } else {
        // switch (tournamentCategory) {
        //     case 'A':
        //         return qualificationsParticipantsNumber
        //             ? ['Odselektuj', 16, 32]
        //             : [16, 32]
        //     default:
        //         return qualificationsParticipantsNumber
        //             ? ['Odselektuj', 32]
        //             : [32]
        // }

        return qualificationsParticipantsNumber
            ? ['Odselektuj', 8, 16, 32, 64, 128]
            : [8, 16, 32, 64, 128]
    }
}

export const getEntryPointsList = async (
    searchValue,
    loadedOptions,
    { page }
) => {
    let entry_points_name = searchValue.split(' ')[0]

    let search_params = {
        entry_points_name,
    }
    let { entryPoints, pgCount } = await getEntryPoints(
        ['entry_points_name', 'uuid'],
        page,
        search_params
    )

    if (entryPoints) {
        let list = []
        entryPoints.map((entryPoint) => {
            let entryPointDetails = {
                value: `${entryPoint?.attributes?.entry_points_name}`,
                uuid: entryPoint?.attributes?.uuid,
            }
            list.push(entryPointDetails)
        })

        return {
            options: list,
            hasMore: page >= pgCount ? false : true,
            additional: {
                page: page + 1,
            },
        }
    }
}

export const getPlayersList = async (
    searchValue,
    loadedOptions,
    { page },
    filterParams
) => {
    let given_name = searchValue.split(' ')[0]
    let family_name = searchValue.split(' ')[1]

    let searchParams = {
        given_name: given_name,
        family_name: family_name,
    }

    let { competitors, pgCount } = await getPlayers(
        ['given_name', 'family_name', 'uuid'],
        searchParams,
        filterParams,
        page
    )

    if (competitors) {
        let list = []
        competitors.map((competitor) => {
            let competitorDetails = {
                value: `${competitor?.attributes?.person_info?.given_name} ${
                    competitor?.attributes?.person_info?.family_name
                } ${formatDate(
                    competitor?.attributes?.person_info?.birthdate
                )}`,
                uuid: competitor?.attributes?.uuid,
            }
            list.push(competitorDetails)
        })

        return {
            options: list,
            hasMore: page >= pgCount ? false : true,
            additional: {
                page: page + 1,
            },
        }
    }
}

export const getRefereesList = async (
    searchValue,
    loadedOptions,
    { page },
    filterParams
) => {
    let given_name = searchValue.split(' ')[0]
    let family_name = searchValue.split(' ')[1]

    let searchParams = {
        given_name: given_name,
        family_name: family_name,
    }
    let { allReferees, pgCount } = await getReferees(
        ['given_name', 'family_name', 'uuid'],
        searchParams,
        filterParams ? filterParams : null,
        page
    )

    if (allReferees) {
        let list = []
        allReferees.map((referee) => {
            let refereeDetails = {
                value: `${referee?.attributes?.person_info?.given_name} ${referee?.attributes?.person_info?.family_name}`,
                uuid: referee?.attributes?.uuid,
            }
            list.push(refereeDetails)
        })

        return {
            options: list,
            hasMore: page >= pgCount ? false : true,
            additional: {
                page: page + 1,
            },
        }
    }
}

export const handleTournamentHasDoublesMatches = (tournamentCategory) => {
    switch (tournamentCategory) {
        case 'A':
            return true
        case 'B':
            return true
        case 'I':
            return true
        case 'III':
            return true
        case 'IV':
            return true
        default:
            return false
    }
}

export const handleDisableSaveButtonOnTournamentEdit = (
    form,
    setSaveButtonDisable
) => {
    if (form?.tournament_type?.tournament_type_name === 'Individualno') {
        if (
            form?.name &&
            (form?.players_competition?.players_competition_name === '12'
                ? true
                : form?.tournament_category?.tournament_category_name) &&
            form?.players_competition?.players_competition_name &&
            form?.genders?.length > 0 &&
            form?.start_date &&
            form?.application_deadline_date &&
            form?.checkout_deadline_date &&
            form?.club_organizer_uuid
        ) {
            setSaveButtonDisable(false)
        } else {
            setSaveButtonDisable(true)
        }
    } else if (form?.tournament_type?.tournament_type_name === 'Ekipno') {
        if (
            form?.name &&
            form?.players_competition?.players_competition_name &&
            form?.genders?.length > 0 &&
            form?.start_date &&
            form?.application_deadline_date &&
            form?.checkout_deadline_date &&
            form?.club_organizer_uuid
        ) {
            setSaveButtonDisable(false)
        } else {
            setSaveButtonDisable(true)
        }
    } else {
        setSaveButtonDisable(true)
    }
}

export const getTournamentPlayersParticipantsList = async (
    searchValue,
    loadedOptions,
    { page },
    filterParams
) => {
    let { participants, pgCount } = await getPlayersForTournament(
        ['player_1_uuid', 'player_2_uuid', 'uuid'],
        null,
        filterParams,
        page
    )
    const drawType = filterParams?.draw_type_name
    const tournamentType = filterParams?.tournament_type_name || ''

    if (participants) {
        const participantsPromises = participants?.map(async (participant) => {
            let didHePlayedInThisRound = false
            let isParticipantInGroups = false
            let isprevRoundWinner = true

            if (tournamentType !== 'Ekipno') {
                if (drawType === 'Round robin') {
                    isParticipantInGroups = await isParticipantAlreadyInGroup(
                        filterParams?.tournament_uuid,
                        participant?.attributes?.uuid
                    )
                } else if (
                    drawType !== 'Srbija Gran Pri' &&
                    drawType !== 'Round robin'
                ) {
                    if (
                        filterParams?.tournament_uuid &&
                        participant?.attributes?.uuid &&
                        filterParams?.round_number &&
                        drawType
                    )
                        didHePlayedInThisRound = await didPlayedInRound(
                            filterParams?.tournament_uuid,
                            participant?.attributes?.uuid,
                            filterParams?.round_number,
                            drawType
                        )

                    if (filterParams?.round_number > 1) {
                        isprevRoundWinner = await isWinnerFromPrevRound(
                            filterParams?.tournament_uuid,
                            participant?.attributes?.uuid,
                            filterParams?.round_number - 1,
                            drawType,
                            +filterParams?.pair_number * 2,
                            +filterParams?.pair_number * 2 - 1
                        )
                    }
                }
            }

            if (
                !didHePlayedInThisRound &&
                !isParticipantInGroups &&
                isprevRoundWinner
            ) {
                const player_1 = await getSinglePlayer(
                    participant.attributes.player_1_uuid
                )

                let player_2
                if (participant.attributes.player_2_uuid) {
                    player_2 = await getSinglePlayer(
                        participant.attributes.player_2_uuid
                    )
                }
                return {
                    ...participant,
                    attributes: {
                        ...participant.attributes,
                        player_1,
                        player_2,
                    },
                }
            }
        })

        const updatedParticipantsList = await Promise.all(participantsPromises)

        let list = []

        if (page === 1) {
            list.push({
                value: 'BYE',
                uuid: '-1',
            })
        }
        updatedParticipantsList?.map((participant) => {
            if (participant) {
                const value = participant?.attributes?.player_2?.attributes
                    ?.person_info?.given_name
                    ? `${participant?.attributes?.player_1?.attributes?.person_info?.given_name} ${participant?.attributes?.player_1?.attributes?.person_info?.family_name} - ${participant?.attributes?.player_2?.attributes?.person_info?.given_name} ${participant?.attributes?.player_2?.attributes?.person_info?.family_name}`
                    : `${
                          participant?.attributes?.player_1?.attributes
                              ?.person_info?.given_name
                      } ${
                          participant?.attributes?.player_1?.attributes
                              ?.person_info?.family_name
                      } ${formatDate(
                          participant?.attributes?.player_1?.attributes
                              ?.person_info?.birthdate
                      )}`
                let participantDetails = {
                    value: value,
                    uuid: participant?.attributes?.uuid,
                }
                list.push(participantDetails)
            }
        })

        return {
            options: list,
            hasMore: page >= pgCount ? false : true,
            additional: {
                page: page + 1,
            },
        }
    }
}

export const createOrFiltersQueryBySameProperty = ({
    arrayOfValues,
    property,
    logicalOperator = 'or',
    checkOperator = 'eq',
    startIndex = 0,
}) => {
    let filtersQuery = ``
    arrayOfValues.forEach((singleValue, index) => {
        let newString = `&filters[$${logicalOperator}][${
            startIndex + index
        }][${property}][$${checkOperator}]=${singleValue}`
        filtersQuery = filtersQuery.concat(newString)
    })

    if (arrayOfValues.length >= 1) {
        filtersQuery = filtersQuery.concat('&')
    }

    return filtersQuery
}

export const handleRoundsNumber = (numberOfParticipants) => {
    if (numberOfParticipants) {
        return Math.log(+numberOfParticipants) / Math.log(2)
    } else {
        return 0
    }
}

export const handleNumberOfMatchCardInRound = (roundNumber) => {
    if (+roundNumber > 0) {
        return Math.pow(2, +roundNumber)
    } else if (+roundNumber === 0) {
        return 1
    } else {
        return 0
    }
}

export const handleRoundName = (numberOfRounds, roundNumber) => {
    const roundNames = {
        [numberOfRounds]: 'Finale',
        [numberOfRounds - 1]: '1/2 finala',
        [numberOfRounds - 2]: '1/4 finala',
        [numberOfRounds - 3]: '1/8 finala',
        [numberOfRounds - 4]: '1/16 finala',
        [numberOfRounds - 5]: '1/32 finala',
    }

    return roundNames[roundNumber] || `${roundNumber?.toString()}. runda`
}

export const handleRoundNameForEntryPoints = (
    numberOfParticipants,
    roundNumber
) => {
    const numberOfRounds = handleRoundsNumber(numberOfParticipants)
    const roundNames = {
        [numberOfRounds]: 'finalist',
        [numberOfRounds - 1]: '1/2',
        [numberOfRounds - 2]: '1/4',
        [numberOfRounds - 3]: '1/8',
        [numberOfRounds - 4]: '1/16',
        [numberOfRounds - 5]: '1/32',
    }

    return roundNames[roundNumber]
}

export const handleRoundNameForQualificationsEntryPoints = (
    numberOfParticipants,
    roundNumber
) => {
    const numberOfRounds = handleRoundsNumber(numberOfParticipants)

    const roundNames = {
        [numberOfRounds]: 'round_3',
        [numberOfRounds - 1]: 'round_2',
        [numberOfRounds - 2]: 'round_1',
    }

    return roundNames[roundNumber]
}

export const handleGenders = (gendersArray) => {
    let gendersString = ''
    gendersArray?.map(
        ({ gender_name }) =>
            (gendersString = gendersString.concat(`${gender_name}, `))
    )
    return gendersString.trim().slice(0, -1)
}

export const handleDrawTypesByTournamentCategory = (tournament) => {
    const drawArray = []
    if (
        tournament?.attributes?.participants_expected_number ||
        tournament?.attributes?.tournament_have_round_roubin
    ) {
        drawArray?.push('Glavni turnir')
    }

    if (tournament?.attributes?.qualifications_participants_expected_number) {
        drawArray?.push('Kvalifikacije')
    }

    if (
        tournament?.attributes?.tournament_have_round_roubin &&
        tournament?.attributes?.tournament_type?.tournament_type_name ===
            'Individualno'
    ) {
        drawArray?.push('Round robin')
    }

    if (
        tournament?.attributes?.tournament_type?.tournament_type_name ===
            'Individualno' &&
        tournament?.attributes?.tournament_have_grand_prix
    ) {
        drawArray?.push('Srbija Gran Pri')
    }

    if (
        tournament?.attributes?.tournament_type?.tournament_type_name ===
        'Ekipno'
    ) {
        if (tournament?.attributes?.tournament_have_round_roubin) {
            return ['Glavni turnir', 'Round robin']
        } else {
            return ['Glavni turnir']
        }
    } else {
        if (
            tournament?.attributes?.players_competition
                ?.players_competition_name === '10s'
        ) {
            return ['Utešni turnir', ...drawArray]
        } else {
            return [...drawArray]
        }
    }
}

export const isPlayerDisciplinaryPunished = async (playerUuid) => {
    let response = await getPlayerDisciplinaryPenalties(playerUuid)

    if (response) {
        return response?.length > 0
    }
}

export const checkClubMemberships = async (clubUuid) => {
    let response = await getLastFourMemberships(clubUuid)

    if (!isArrayEmpty(response)) {
        let yearNow = new Date().getFullYear()
        let relevantYears = [yearNow - 1, yearNow - 2, yearNow - 3]

        let isPaidInRelevantYears = response.some((membership) => {
            const paymentYear = new Date(
                membership?.attributes?.date_of_payment
            ).getFullYear()
            return relevantYears.includes(paymentYear)
        })

        let yearlyMembership = response.filter((membership) => {
            const paymentYear = new Date(
                membership?.attributes?.date_of_payment
            ).getFullYear()
            return paymentYear === yearNow
        })

        if (yearlyMembership.length > 0) {
            return 'Aktivan'
        }

        if (isPaidInRelevantYears) {
            return 'U mirovanju'
        } else {
            return 'Neaktivan'
        }
    } else {
        return 'Neaktivan'
    }
}

export const handleMainEntryPointsKeyByDrawType = (tournament) => {
    if (
        tournament?.attributes?.tournament_type?.tournament_type_name ===
        'Ekipno'
    ) {
        return ['team_tournament']
    } else {
        if (
            tournament?.attributes?.tournament_category
                ?.tournament_category_name === 'III'
        ) {
            return ['third_category']
        } else if (
            tournament?.attributes?.players_competition
                ?.players_competition_name === '12'
        ) {
            return ['twelve_years']
        } else {
            switch (
                tournament?.attributes?.tournament_category
                    ?.tournament_category_name
            ) {
                case 'A':
                    return ['main_tournament', 'qualifications', 'doubles']
                case 'TOUR':
                    return ['main_tournament', 'qualifications']
                case 'B':
                case 'I':
                case 'IV':
                    return ['main_tournament', 'doubles']
                case 'A/S':
                case 'A/Z':
                case 'II':
                    return ['main_tournament']
                case 'III':
                    return ['third_category']
                default:
                    return null
            }
        }
    }
}

export const entryPointsKeyToDrawTypeName = (entryPointsKey) => {
    switch (entryPointsKey) {
        case 'main_tournament':
        case 'doubles':
        case 'third_category':
        case 'twelve_years':
        case 'team_tournament':
        case 'Glavni turnir':
            return 'Glavni turnir'
        case 'qualifications':
        case 'Kvalifikacije':
            return 'Kvalifikacije'
    }
}

const handleRounRobinKeyName = (numberOfWins) => {
    switch (numberOfWins) {
        case 3:
            return 'three_wins'
        case 2:
            return 'two_wins'
        case 1:
            return 'one_win'
        default:
            return ''
    }
}

const handleTableKeyName = (numberOfTable) => {
    switch (numberOfTable) {
        case 1:
            return 'board_1'
        case 2:
            return 'board_2'
        case 3:
            return 'board_3'
        case 4:
            return 'board_4'
        case 5:
            return 'board_5'
    }
}

export const calculatePlayersPointsOnTournament = async (
    tournament,
    tournamentsEntryPoints,
    participant
) => {
    const mainEntryPointsKeys = handleMainEntryPointsKeyByDrawType(tournament)

    if (!mainEntryPointsKeys || mainEntryPointsKeys.length === 0) {
        return 0
    }

    if (mainEntryPointsKeys[0] === 'team_tournament') {
        let teamTournamentPoints = 0
        const key =
            tournament?.attributes?.players_competition
                ?.players_competition_name
        const { uuid: tournamentUUID } = tournament?.attributes || {}
        const { uuid: participantUUID } = participant?.attributes || {}

        const playersMatches = await getAllParticipantsWinsFromTournament({
            tournament_uuid: tournamentUUID,
            participant_uuid: participantUUID,
        })

        if (!playersMatches) {
            return 0
        }

        playersMatches?.map((match) => {
            teamTournamentPoints +=
                tournamentsEntryPoints?.attributes?.entry_points_schema?.[
                    'team_tournament'
                ]?.points?.[key]?.[
                    handleTableKeyName(match?.attributes?.table_number)
                ]?.points
        })

        return teamTournamentPoints
    }

    if (mainEntryPointsKeys[0] === 'twelve_years') {
        const fetchFromAttributes = async (uuid, fetchFunction) => {
            if (!uuid) return null
            return await fetchFunction(uuid)
        }

        const calculateTotalPoints = async (
            mainEntryPointsKey,
            roundName,
            isLoser,
            expectedNumber
        ) => {
            let points = 0
            let key = `${mainEntryPointsKey} ${expectedNumber}`
            if (isLoser) {
                points =
                    tournamentsEntryPoints?.attributes?.entry_points_schema?.[
                        'twelve_years'
                    ]?.points?.[key]?.[roundName]?.points || 0
            } else {
                if (
                    mainEntryPointsKey === 'Glavni turnir' &&
                    roundName === 'finalist'
                ) {
                    points =
                        tournamentsEntryPoints?.attributes
                            ?.entry_points_schema?.['twelve_years']?.points?.[
                            key
                        ]?.['winner']?.points || 0
                }

                if (
                    mainEntryPointsKey === 'Kvalifikacije' &&
                    roundName === 'round_3'
                ) {
                    points =
                        tournamentsEntryPoints?.attributes
                            ?.entry_points_schema?.['twelve_years']?.points?.[
                            key
                        ]?.['qualifier']?.points || 0
                }
            }
            return points
        }

        const promises = [`Glavni turnir`, `Kvalifikacije`, 'Round robin'].map(
            async (mainEntryPointsKey) => {
                const { lastMatch, prevMatch } =
                    await getPlayersLastMatchOnTournament(
                        tournament?.attributes?.uuid,
                        participant?.attributes?.uuid,
                        entryPointsKeyToDrawTypeName(mainEntryPointsKey)
                    )

                const [participant_1, participant_2] = await Promise.all([
                    fetchFromAttributes(
                        lastMatch?.attributes?.side_1_uuid,
                        getSingleParticipant
                    ),
                    fetchFromAttributes(
                        lastMatch?.attributes?.side_2_uuid,
                        getSingleParticipant
                    ),
                ])

                const [side_1_player_2, side_2_player_2] = await Promise.all([
                    fetchFromAttributes(
                        participant_1?.attributes?.player_2_uuid,
                        getSinglePlayer
                    ),
                    fetchFromAttributes(
                        participant_2?.attributes?.player_2_uuid,
                        getSinglePlayer
                    ),
                ])

                let totalPoints = 0
                const isLoser =
                    lastMatch?.attributes?.loser_participant_uuid ===
                    participant?.attributes?.uuid
                let roundName

                if (mainEntryPointsKey === 'Glavni turnir') {
                    if (side_1_player_2 || side_2_player_2) {
                        if (
                            prevMatch &&
                            isLoser &&
                            (prevMatch?.attributes?.loser_participant_uuid ===
                                '-1' ||
                                prevMatch?.attributes
                                    ?.loser_participant_uuid === null ||
                                prevMatch?.attributes
                                    ?.loser_participant_uuid === '')
                        ) {
                            roundName = handleRoundNameForEntryPoints(
                                tournament?.attributes
                                    ?.doubles_pairs_expected_number,
                                prevMatch?.attributes?.round_number
                            )
                            totalPoints += await calculateTotalPoints(
                                mainEntryPointsKey,
                                roundName,
                                isLoser,
                                tournament?.attributes
                                    ?.doubles_pairs_expected_number
                            )
                        } else {
                            roundName = handleRoundNameForEntryPoints(
                                tournament?.attributes
                                    ?.doubles_pairs_expected_number,
                                lastMatch?.attributes?.round_number
                            )
                            totalPoints += await calculateTotalPoints(
                                mainEntryPointsKey,
                                roundName,
                                isLoser,
                                tournament?.attributes
                                    ?.doubles_pairs_expected_number
                            )
                        }
                    } else {
                        if (
                            prevMatch &&
                            isLoser &&
                            (prevMatch?.attributes?.loser_participant_uuid ===
                                '-1' ||
                                prevMatch?.attributes
                                    ?.loser_participant_uuid === null ||
                                prevMatch?.attributes
                                    ?.loser_participant_uuid === '')
                        ) {
                            roundName = handleRoundNameForEntryPoints(
                                tournament?.attributes
                                    ?.participants_expected_number,
                                prevMatch?.attributes?.round_number
                            )
                            totalPoints += await calculateTotalPoints(
                                mainEntryPointsKey,
                                roundName,
                                isLoser,
                                tournament?.attributes
                                    ?.participants_expected_number
                            )
                        } else {
                            roundName = handleRoundNameForEntryPoints(
                                tournament?.attributes
                                    ?.participants_expected_number,
                                lastMatch?.attributes?.round_number
                            )

                            totalPoints += await calculateTotalPoints(
                                mainEntryPointsKey,
                                roundName,
                                isLoser,
                                tournament?.attributes
                                    ?.participants_expected_number
                            )
                        }
                    }
                }

                if (mainEntryPointsKey === 'Kvalifikacije') {
                    roundName = handleRoundNameForQualificationsEntryPoints(
                        +tournament?.attributes
                            ?.qualifications_participants_expected_number /
                            +tournament?.attributes?.number_of_qualified,
                        lastMatch?.attributes?.round_number
                    )
                    totalPoints += await calculateTotalPoints(
                        mainEntryPointsKey,
                        roundName,
                        isLoser,
                        +tournament?.attributes
                            ?.qualifications_participants_expected_number /
                            +tournament?.attributes?.number_of_qualified
                    )
                }

                return totalPoints
            }
        )

        const pointsData = await Promise.all(promises)

        const mainEntryPointsKey = 'Round robin'

        let roundRobinPoints = 0
        const numberOfWins = await getNumberOfPlayerWinsInRoundRobin({
            tournament_uuid: tournament?.attributes?.uuid,
            participant_uuid: participant?.attributes?.uuid,
        })

        roundRobinPoints =
            tournamentsEntryPoints?.attributes?.entry_points_schema?.[
                'twelve_years'
            ]?.points?.[
                `${mainEntryPointsKey} ${tournament?.attributes?.participants_expected_number}`
            ]?.[handleRounRobinKeyName(numberOfWins)]?.points || 0

        return (
            pointsData.reduce((acc, curr) => acc + curr, 0) + roundRobinPoints
        )
    }
    if (mainEntryPointsKeys[0] === 'third_category') {
        let points = 0

        const { uuid: tournamentUUID } = tournament?.attributes || {}
        const { uuid: participantUUID } = participant?.attributes || {}
        const tournamentSubcategory =
            tournament?.attributes?.tournament_category
                ?.tournament_subcategory_name

        const playersMatches = await getAllParticipantsWinsFromTournament({
            tournament_uuid: tournamentUUID,
            participant_uuid: participantUUID,
        })

        if (!playersMatches) {
            return 0
        }

        const entryPointsSchema =
            tournamentsEntryPoints?.attributes?.entry_points_schema?.[
                'third_category'
            ]
        const everyWinPoints =
            entryPointsSchema?.points?.[`${tournamentSubcategory}`]?.every_win
                ?.points || 0

        playersMatches.forEach(() => {
            points += Number(everyWinPoints)
        })

        return points
    } else {
        const fetchFromAttributes = async (uuid, fetchFunction) => {
            if (!uuid) return null
            return await fetchFunction(uuid)
        }

        const calculateTotalPoints = async (
            mainEntryPointsKey,
            key,
            roundName,
            isLoser
        ) => {
            let points = 0
            if (isLoser) {
                points =
                    tournamentsEntryPoints?.attributes?.entry_points_schema?.[
                        mainEntryPointsKey
                    ]?.points?.[key]?.[roundName]?.points || 0
            } else {
                if (
                    mainEntryPointsKey === 'main_tournament' &&
                    roundName === 'finalist'
                ) {
                    points =
                        tournamentsEntryPoints?.attributes
                            ?.entry_points_schema?.[mainEntryPointsKey]
                            ?.points?.[key]?.['winner']?.points || 0
                }
                if (
                    mainEntryPointsKey === 'qualifications' &&
                    roundName === 'round_3'
                ) {
                    points =
                        tournamentsEntryPoints?.attributes
                            ?.entry_points_schema?.[mainEntryPointsKey]
                            ?.points?.[key]?.['qualifier']?.points || 0
                }
            }
            return points
        }

        const promises = mainEntryPointsKeys.map(async (mainEntryPointsKey) => {
            const { lastMatch, prevMatch } =
                await getPlayersLastMatchOnTournament(
                    tournament?.attributes?.uuid,
                    participant?.attributes?.uuid,
                    entryPointsKeyToDrawTypeName(mainEntryPointsKey)
                )

            const [participant_1, participant_2] = await Promise.all([
                fetchFromAttributes(
                    lastMatch?.attributes?.side_1_uuid,
                    getSingleParticipant
                ),
                fetchFromAttributes(
                    lastMatch?.attributes?.side_2_uuid,
                    getSingleParticipant
                ),
            ])

            const [side_1_player_2, side_2_player_2] = await Promise.all([
                fetchFromAttributes(
                    participant_1?.attributes?.player_2_uuid,
                    getSinglePlayer
                ),
                fetchFromAttributes(
                    participant_2?.attributes?.player_2_uuid,
                    getSinglePlayer
                ),
            ])

            let totalPoints = 0
            const isLoser =
                lastMatch?.attributes?.loser_participant_uuid ===
                participant?.attributes?.uuid
            let key, roundName

            if (mainEntryPointsKey === 'main_tournament') {
                if (side_1_player_2 || side_2_player_2) {
                    let participantsNumber
                    if (
                        +tournament?.attributes
                            ?.doubles_pairs_expected_number === 48 ||
                        +tournament?.attributes
                            ?.doubles_pairs_expected_number === 64
                    ) {
                        participantsNumber = 64
                    } else {
                        participantsNumber =
                            +tournament?.attributes
                                ?.doubles_pairs_expected_number
                    }
                    if (
                        participantsNumber === 48 ||
                        participantsNumber === 64
                    ) {
                        key = `64/48 ${tournament?.attributes?.tournament_category?.tournament_category_name}`
                    } else {
                        key = `${participantsNumber} ${tournament?.attributes?.tournament_category?.tournament_category_name}`
                    }

                    if (
                        prevMatch &&
                        isLoser &&
                        (prevMatch?.attributes?.loser_participant_uuid ===
                            '-1' ||
                            prevMatch?.attributes?.loser_participant_uuid ===
                                null ||
                            prevMatch?.attributes?.loser_participant_uuid ===
                                '')
                    ) {
                        roundName = handleRoundNameForEntryPoints(
                            participantsNumber,
                            prevMatch?.attributes?.round_number
                        )
                        totalPoints += await calculateTotalPoints(
                            'doubles',
                            key,
                            roundName,
                            isLoser
                        )
                    } else {
                        roundName = handleRoundNameForEntryPoints(
                            participantsNumber,
                            lastMatch?.attributes?.round_number
                        )
                        totalPoints += await calculateTotalPoints(
                            'doubles',
                            key,
                            roundName,
                            isLoser
                        )
                    }
                } else {
                    let participantsNumber
                    if (
                        +tournament?.attributes
                            ?.participants_expected_number === 48 ||
                        +tournament?.attributes
                            ?.participants_expected_number === 64
                    ) {
                        participantsNumber = 64
                    } else {
                        participantsNumber =
                            +tournament?.attributes
                                ?.participants_expected_number
                    }
                    if (
                        participantsNumber === 48 ||
                        participantsNumber === 64
                    ) {
                        key = `64/48 ${tournament?.attributes?.tournament_category?.tournament_category_name}`
                    } else {
                        key = `${participantsNumber} ${tournament?.attributes?.tournament_category?.tournament_category_name}`
                    }

                    if (
                        prevMatch &&
                        isLoser &&
                        (prevMatch?.attributes?.loser_participant_uuid ===
                            '-1' ||
                            prevMatch?.attributes?.loser_participant_uuid ===
                                null ||
                            prevMatch?.attributes?.loser_participant_uuid ===
                                '')
                    ) {
                        roundName = handleRoundNameForEntryPoints(
                            participantsNumber,
                            prevMatch?.attributes?.round_number
                        )
                        totalPoints += await calculateTotalPoints(
                            mainEntryPointsKey,
                            key,
                            roundName,
                            isLoser
                        )
                    } else {
                        roundName = handleRoundNameForEntryPoints(
                            participantsNumber,
                            lastMatch?.attributes?.round_number
                        )

                        totalPoints += await calculateTotalPoints(
                            mainEntryPointsKey,
                            key,
                            roundName,
                            isLoser
                        )
                    }
                }
            }

            if (mainEntryPointsKey === 'qualifications') {
                key = `${tournament?.attributes?.qualifications_participants_expected_number}/${tournament?.attributes?.number_of_qualified} ${tournament?.attributes?.tournament_category?.tournament_category_name}`
                roundName = handleRoundNameForQualificationsEntryPoints(
                    +tournament?.attributes
                        ?.qualifications_participants_expected_number /
                        +tournament?.attributes?.number_of_qualified,
                    lastMatch?.attributes?.round_number
                )
                totalPoints += await calculateTotalPoints(
                    mainEntryPointsKey,
                    key,
                    roundName,
                    isLoser
                )
            }

            return totalPoints
        })

        const pointsData = await Promise.all(promises)
        return pointsData.reduce((acc, curr) => acc + curr, 0)
    }
}

export const convertMediaResponseToFile = async (mediaResponse) => {
    try {
        const response = await axios.get(formatImgUrl(mediaResponse.url))
        const blob = new Blob([response.data], {
            type: response.headers['content-type'],
        })

        // Create a File object from the blob
        const file = new File([blob], mediaResponse.name, { type: blob.type })

        return file
    } catch (error) {
        console.error('Error converting media response to File:', error)
        return null
    }
}

export const handleTournamentSubcategories = (numberOfParticipants) => {
    // if (numberOfParticipants <= 16) {
    //     return ['A']
    // } else if (numberOfParticipants >= 17 && numberOfParticipants <= 23) {
    //     return ['A', 'B']
    // } else if (numberOfParticipants >= 24 && numberOfParticipants <= 32) {
    //     return ['A', 'B']
    // } else if (numberOfParticipants >= 33 && numberOfParticipants <= 39) {
    //     return ['A', 'B', 'C']
    // } else if (numberOfParticipants >= 40 && numberOfParticipants <= 48) {
    //     return ['A', 'B', 'C']
    // } else if (numberOfParticipants >= 49 && numberOfParticipants <= 55) {
    //     return ['A', 'B', 'C1', 'C2']
    // } else if (numberOfParticipants >= 56 && numberOfParticipants <= 64) {
    //     return ['A', 'B', 'C1', 'C2']
    // } else {
    //   return ['A', 'B', 'C1', 'C2', 'C3', 'C4', 'C5', 'C5']
    // }
    return ['A', 'B', 'C1', 'C2', 'C3', 'C4', 'C5', 'C5']
}

export const getPlayersNumberForTournament = async (competitionProfileData) => {
    try {
        const tournament_uuid = competitionProfileData?.attributes?.uuid
        if (!tournament_uuid) return

        const fetchSingles = getPlayersForTournament(
            null,
            null,
            {
                tournament_uuid: tournament_uuid,
                is_double: false,
            },
            1
        )

        const fetchDoubles = getPlayersForTournament(
            null,
            null,
            {
                tournament_uuid: tournament_uuid,
                is_double: true,
            },
            1
        )

        const [singlesResult, doublesResult] = await Promise.all([
            fetchSingles,
            fetchDoubles,
        ])

        let participantsNumberObject = {
            singleParticipantsNumber: singlesResult.total || 0,
            doublesParticipantsNumber: doublesResult.total || 0,
        }
        return participantsNumberObject
    } catch (error) {
        console.error('Greška prilikom učitavanja turnira.', error)
    }
}

export const handleGetPlayersForTournament = async (
    filtersParams,
    currentPage,
    participantsToShow,
    allEntries = false,
    rowsPerPage = false
) => {
    const result = await getPlayersForTournament(
        null,
        null,
        filtersParams,
        currentPage,
        allEntries,
        rowsPerPage
    )

    if (!result) return

    const { participants, pgCount, total } = result

    const updatedParticipantsList = await Promise.all(
        participants.map(async (participant) => {
            const player1Promise = getSinglePlayer(
                participant?.attributes?.player_1_uuid
            )

            const player1ClubPromise = getPlayersCurrentClub(
                participant?.attributes?.player_1_uuid
            )

            let player2Promise
            let player2ClubPromise
            if (participantsToShow?.is_double) {
                player2Promise = getSinglePlayer(
                    participant?.attributes?.player_2_uuid
                )
                player2ClubPromise = getPlayersCurrentClub(
                    participant?.attributes?.player_2_uuid
                )
            }
            const [
                player_1,
                player_1_current_club,
                player_2,
                player_2_current_club,
            ] = await Promise.all([
                player1Promise,
                player1ClubPromise,
                player2Promise,
                player2ClubPromise,
            ])

            let player1ClubInfoPromise
            if (player_1_current_club) {
                player1ClubInfoPromise = getSingleClub(
                    player_1_current_club?.attributes?.club_uuid
                )
            }

            let player2ClubInfoPromise
            if (player_2_current_club) {
                player2ClubInfoPromise = getSingleClub(
                    player_2_current_club?.attributes?.club_uuid
                )
            }

            const [player_1_club, player_2_club] = await Promise.all([
                player1ClubInfoPromise,
                player2ClubInfoPromise,
            ])

            return {
                ...participant,
                attributes: {
                    ...participant.attributes,
                    player_1,
                    player_1_club,
                    player_2,
                    player_2_club,
                    ranking_points:
                        participant?.attributes?.ranking_points ||
                        participant?.attributes?.ranking_points === 0
                            ? participant?.attributes?.ranking_points
                            : 'Nisu izračunati',
                },
            }
        })
    )

    return { updatedParticipantsList, pgCount, total }
}

export const getListOfMatches = async (
    searchValue,
    loadedOptions,
    { page },
    filterParams
) => {
    try {
        let { matches, pgCount } = await getMatchesFromTournament(
            ['side_1_uuid', 'side_2_uuid', 'uuid'],
            null,
            filterParams,
            page
        )

        const matchesPromises = matches?.map(async (match) => {
            if (
                match?.attributes?.side_1_uuid !== '-1' &&
                match?.attributes?.side_2_uuid !== '-1' &&
                match?.attributes?.side_1_uuid &&
                match?.attributes?.side_2_uuid
            ) {
                let participant_1
                if (match?.attributes?.side_1_uuid) {
                    participant_1 = await getSingleParticipant(
                        match?.attributes?.side_1_uuid
                    )
                }

                let participant_2
                if (match?.attributes?.side_2_uuid) {
                    participant_2 = await getSingleParticipant(
                        match.attributes.side_2_uuid
                    )
                }

                let side_1_player_1
                if (participant_1?.attributes?.player_1_uuid) {
                    side_1_player_1 = await getSinglePlayer(
                        participant_1?.attributes?.player_1_uuid
                    )
                }

                let side_1_player_2
                if (participant_1?.attributes?.player_2_uuid) {
                    side_1_player_2 = await getSinglePlayer(
                        participant_1?.attributes?.player_2_uuid
                    )
                }

                let side_2_player_1
                if (participant_2?.attributes?.player_1_uuid) {
                    side_2_player_1 = await getSinglePlayer(
                        participant_2.attributes.player_1_uuid
                    )
                }

                let side_2_player_2
                if (participant_2?.attributes?.player_2_uuid) {
                    side_2_player_2 = await getSinglePlayer(
                        participant_2?.attributes?.player_2_uuid
                    )
                }

                return {
                    ...match,
                    attributes: {
                        ...match.attributes,
                        side_1_player_1,
                        side_1_player_2,
                        side_2_player_1,
                        side_2_player_2,
                    },
                }
            }
        })

        const updatedMatches = await Promise.all(matchesPromises)

        let list = []
        updatedMatches?.map((match) => {
            if (match) {
                const value = match?.attributes?.side_1_player_2?.attributes
                    ?.person_info?.given_name
                    ? `${match?.attributes?.side_1_player_1?.attributes?.person_info?.given_name} ${match?.attributes?.side_1_player_1?.attributes?.person_info?.family_name}/${match?.attributes?.side_1_player_2?.attributes?.person_info?.given_name} ${match?.attributes?.side_1_player_2?.attributes?.person_info?.family_name} - ${match?.attributes?.side_2_player_1?.attributes?.person_info?.given_name} ${match?.attributes?.side_2_player_1?.attributes?.person_info?.family_name}/${match?.attributes?.side_2_player_2?.attributes?.person_info?.given_name} ${match?.attributes?.side_2_player_2?.attributes?.person_info?.family_name}`
                    : `${match?.attributes?.side_1_player_1?.attributes?.person_info?.given_name} ${match?.attributes?.side_1_player_1?.attributes?.person_info?.family_name} - ${match?.attributes?.side_2_player_1?.attributes?.person_info?.given_name} ${match?.attributes?.side_2_player_1?.attributes?.person_info?.family_name}`
                let matchDetails = {
                    value: value,
                    uuid: match?.attributes?.uuid,
                }
                list.push(matchDetails)
            }
        })

        return {
            options: list,
            hasMore: page >= pgCount ? false : true,
            additional: {
                page: page + 1,
            },
        }
    } catch (e) {
        console.error(e)
    }
}

export const convertDateFormat = (dateStr) => {
    const [day, month, year] = dateStr.split('.')
    return `${year}-${month}-${day}`
}

export const convertDateFormatWithDay = (dateStr) => {
    const [, date] = dateStr.split(',')
    const [day, month, year] = date.split('.').map((item) => item.trim())
    return `${year}-${month}-${day}`
}

export const getTournamentClubsParticipantsList = async (
    searchValue,
    loadedOptions,
    { page },
    filterParams
) => {
    let { participants, pgCount } = await getClubsForTournament(
        null,
        null,
        filterParams,
        page
    )
    const drawType = filterParams?.draw_type_name
    if (participants) {
        const participantsPromises = participants?.map(async (participant) => {
            let didClubPlayedInThisRound = false
            let isprevRoundWinner = true
            let isParticipantInGroups = false
            if (drawType === 'Glavni turnir') {
                didClubPlayedInThisRound = await didClubPlayedInRound(
                    filterParams?.tournament_uuid,
                    participant?.attributes?.uuid,
                    filterParams?.round_number,
                    drawType
                )

                if (filterParams?.round_number > 1) {
                    isprevRoundWinner = await isClubWinnerFromPrevRound(
                        filterParams?.tournament_uuid,
                        participant?.attributes?.uuid,
                        filterParams?.round_number - 1,
                        drawType,
                        +filterParams?.pair_number * 2,
                        +filterParams?.pair_number * 2 - 1
                    )
                }
            }

            if (drawType === 'Round robin') {
                isParticipantInGroups = await isParticipantAlreadyInGroup(
                    filterParams?.tournament_uuid,
                    participant?.attributes?.uuid
                )
            }

            if (
                !didClubPlayedInThisRound &&
                !isParticipantInGroups &&
                isprevRoundWinner
            ) {
                const club = await getSingleClub(
                    participant?.attributes?.club_uuid
                )

                return {
                    ...participant,
                    attributes: {
                        ...participant.attributes,
                        club,
                    },
                }
            }
        })

        const updatedParticipantsList = await Promise.all(participantsPromises)

        let list = []
        if (page === 1) {
            list.push({
                value: 'BYE',
                uuid: '-1',
            })
        }
        updatedParticipantsList?.map((participant) => {
            if (participant) {
                let participantDetails = {
                    value: participant?.attributes?.club?.attributes?.name,
                    uuid: participant?.attributes?.uuid,
                }
                list.push(participantDetails)
            }
        })

        return {
            options: list,
            hasMore: page >= pgCount ? false : true,
            additional: {
                page: page + 1,
            },
        }
    }
}

export const getTournamentPlayersListForReport = async (
    searchValue,
    loadedOptions,
    { page },
    filterParams,
    alreadySelected = []
) => {
    let given_name = searchValue.split(' ')[0]
    let family_name = searchValue.split(' ')[1]

    let searchParams = {
        given_name: given_name,
        family_name: family_name,
    }

    const result = await getPlayersForTournament(
        null,
        null,
        {
            tournament_uuid: filterParams?.tournament_uuid,
        },
        page
    )

    if (result) {
        const { participants, pgCount, total } = result

        const participantsPromises = participants.map(async (participant) => {
            const player_1 = await getSinglePlayer(
                participant.attributes.player_1_uuid
            )

            const player_1_club = await getPlayersCurrentClub(
                player_1.attributes.uuid
            )
            const player_1_clubInfo = player_1_club
                ? await getSingleClub(player_1_club?.attributes?.club_uuid)
                : null

            let player_2
            let player_2_club
            let player_2_clubInfo
            if (participant?.attributes?.player_2_uuid) {
                player_2 = await getSinglePlayer(
                    participant.attributes.player_2_uuid
                )
                player_2_club = await getPlayersCurrentClub(
                    player_1.attributes.uuid
                )
                player_2_clubInfo = player_2_club
                    ? await getSingleClub(player_2_club?.attributes?.club_uuid)
                    : null
            }
            return participant?.attributes?.player_2_uuid
                ? {
                      ...participant,
                      attributes: {
                          ...participant.attributes,
                          player_1: {
                              ...player_1,
                              club: player_1_clubInfo,
                          },
                          player_2: {
                              ...player_2,
                              club: player_2_clubInfo,
                          },
                      },
                  }
                : {
                      ...participant,
                      attributes: {
                          ...participant.attributes,
                          player_1: {
                              ...player_1,
                              club: player_1_clubInfo,
                          },
                          player_2: undefined,
                      },
                  }
        })

        const updatedParticipantsList = await Promise.all(participantsPromises)

        let list = []
        updatedParticipantsList?.map((participant) => {
            if (participant) {
                if (participant?.attributes?.player_1) {
                    if (
                        !checkCodeExists(
                            alreadySelected,
                            participant?.attributes?.player_1?.attributes?.uuid
                        ) &&
                        !checkCodeExists(
                            list,
                            participant?.attributes?.player_1?.attributes?.uuid
                        ) &&
                        (searchParams.given_name
                            ? checkName(
                                  searchParams.given_name,
                                  participant?.attributes?.player_1?.attributes
                                      ?.person_info?.given_name
                              )
                            : true) &&
                        (searchParams.family_name
                            ? checkName(
                                  searchParams.family_name,
                                  participant?.attributes?.player_1?.attributes
                                      ?.person_info?.family_name
                              )
                            : true)
                    ) {
                        let playerDetails = {
                            name: `${participant?.attributes?.player_1?.attributes?.person_info?.given_name} ${participant?.attributes?.player_1?.attributes?.person_info?.family_name}`,
                            code: participant?.attributes?.player_1?.attributes
                                ?.uuid,
                            label: `${participant?.attributes?.player_1?.attributes?.person_info?.given_name} ${participant?.attributes?.player_1?.attributes?.person_info?.family_name}`,
                            club: participant?.attributes?.player_1?.club
                                ?.attributes?.name,
                        }
                        list.push(playerDetails)
                    }
                }

                if (participant?.attributes?.player_2) {
                    if (
                        !checkCodeExists(
                            alreadySelected,
                            participant?.attributes?.player_2?.attributes?.uuid
                        ) &&
                        !checkCodeExists(
                            list,
                            participant?.attributes?.player_2?.attributes?.uuid
                        ) &&
                        (searchParams.given_name
                            ? checkName(
                                  searchParams.given_name,
                                  participant?.attributes?.player_2?.attributes
                                      ?.person_info?.given_name
                              )
                            : true) &&
                        (searchParams.family_name
                            ? checkName(
                                  searchParams.family_name,
                                  participant?.attributes?.player_2?.attributes
                                      ?.person_info?.family_name
                              )
                            : true)
                    ) {
                        let playerDetails = {
                            name: `${participant?.attributes?.player_2?.attributes?.person_info?.given_name} ${participant?.attributes?.player_2?.attributes?.person_info?.family_name}`,
                            code: participant?.attributes?.player_2?.attributes
                                ?.uuid,
                            label: `${participant?.attributes?.player_2?.attributes?.person_info?.given_name} ${participant?.attributes?.player_2?.attributes?.person_info?.family_name}`,
                            club: participant?.attributes?.player_2?.club
                                ?.attributes?.name,
                        }
                        list.push(playerDetails)
                    }
                }
            }
        })

        return {
            options: list,
            hasMore: page >= pgCount ? false : true,
            additional: {
                page: page + 1,
            },
        }
    }
}

export const filterArrayByCode = (firstArray, secondArray) => {
    const secondCodes = secondArray.map((item) => item.code)
    const filteredArray = firstArray.filter((item) =>
        secondCodes.includes(item.code)
    )
    return filteredArray
}

export const checkCodeExists = (arrayValue, targetCode) => {
    return arrayValue.some((item) => item.code === targetCode)
}

export const checkName = (nameToCheck, fullName) => {
    return fullName.toLowerCase().includes(nameToCheck)
}

export const containsWords = (inputString, wordsArray) => {
    return wordsArray.every((word) => inputString.includes(word))
}

export const removeWordAndJoin = (
    inputString,
    wordToRemove,
    separator = '_'
) => {
    let collectionName = inputString.split('.')[0]
    let joinedString = inputString
        .split('.')[1]
        .split(separator)
        .filter((word) => word !== wordToRemove)
        .join(separator)

    let finalString = collectionName.concat('.').concat(joinedString)
    return finalString
}

export const handleShowContactPerson = (staff) => {
    if (!staff) return
    const contactPerson = staff?.find((person) => person?.title === 'Sekretar')

    return `${contactPerson?.given_name || '-'} ${
        contactPerson?.family_name || ''
    }`
}

export const handlePlayerCheckInCategory = (
    birthdayDate,
    competitionCategory,
    competitionSubCategory
) => {
    let currentYear = new Date().getFullYear()
    let competitionYearStart
    let competitionYearEnd

    const competitionSubCategoryHandler = (subcategory) => {
        switch (subcategory) {
            case 'Crveni': // od 6 do  8 godina, ne racunajuci 8 godina
                competitionYearStart = currentYear - 7
                competitionYearEnd = currentYear - 6
                break
            case 'Narandžasti': // 8 godina
                competitionYearStart = currentYear - 8
                competitionYearEnd = currentYear - 8
                break
            case 'Zeleni': // 9 i 10 godina
                competitionYearStart = currentYear - 10
                competitionYearEnd = currentYear - 9
                break
            default: // od 6 do 10, racunajuci i 10
                competitionYearStart = currentYear - 10
                competitionYearEnd = currentYear
                break
        }
    }

    switch (competitionCategory) {
        case '10s': // od 6 do 10, racunajuci i 10
            competitionSubCategoryHandler(competitionSubCategory)
            break
        case '12': // 10 - 12 godina
            competitionYearStart = currentYear - 12
            competitionYearEnd = currentYear - 10
            break
        case '14': //11-14 godina
            competitionYearStart = currentYear - 14
            competitionYearEnd = currentYear - 11
            break
        case '16': // 13-16 godina
            competitionYearStart = currentYear - 16
            competitionYearEnd = currentYear - 13
            break
        case '18': // 15-18 godina
            competitionYearStart = currentYear - 18
            competitionYearEnd = currentYear - 15
            break
        case 'Seniori': // 18 i stariji od 18 godina
            competitionYearStart = 1899
            competitionYearEnd = currentYear - 18
            break
    }

    let startDate = `${competitionYearStart}-01-01`
    let endDate = `${competitionYearEnd}-12-31`

    const isBetween =
        new Date(startDate).setHours(0, 0, 0, 0) <=
            new Date(birthdayDate).setHours(0, 0, 0, 0) &&
        new Date(birthdayDate).setHours(0, 0, 0, 0) <=
            new Date(endDate).setHours(0, 0, 0, 0)

    return isBetween
}

export const formatStringWithStrongText = (text) => {
    const parts = text.split(/(<strong>.*?<\/strong>)/)
    return parts.map((part, index) => {
        if (part.startsWith('<strong>') && part.endsWith('</strong>')) {
            const boldText = part
                .replace('<strong>', '')
                .replace('</strong>', '')
            return (
                <span
                    key={index}
                    style={{
                        fontWeight: 700,
                        fontFamily: 'Source Sans Pro Bold',
                    }}
                >
                    {boldText}
                </span>
            )
        } else {
            return <span key={index}>{part}</span>
        }
    })
}

export const handleDrawSizeBasedOnGroupNumber = (groupNumber) => {
    if (groupNumber === 1) return 2
    if (groupNumber === 2) return 4
    if (groupNumber <= 4) return 8
    if (groupNumber <= 8) return 16
    if (groupNumber <= 16) return 32
    if (groupNumber <= 32) return 64
    if (groupNumber <= 64) return 128
    return 0
}
