import * as React from "react"

const PaperClip = (props) => (
  <svg
    width={25}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={12.5} cy={12.001} r={12} fill="#fff" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.89 5.818c-.49 0-.959.194-1.305.54L7.26 12.682a3.223 3.223 0 1 0 4.558 4.558l6.324-6.324a.908.908 0 0 1 1.284 1.285l-6.324 6.324a5.04 5.04 0 1 1-7.127-7.127L12.3 5.074a3.663 3.663 0 0 1 5.18 5.18l-6.331 6.323a2.285 2.285 0 0 1-3.232-3.232l5.842-5.836a.908.908 0 1 1 1.284 1.286L9.201 14.63a.47.47 0 0 0 .663.663l6.331-6.324a1.847 1.847 0 0 0-1.305-3.151Z"
      fill="#A4ACB6"
    />
  </svg>
)

export default PaperClip
