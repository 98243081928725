import { Table } from 'react-bootstrap'
import CompetitionClubsParticipantsRow from './row'

import PaginationWrapper from '../Pagination'
import './styles.scss'

const CompetitionClubsParticipants = ({
    clubs,
    enableRemove,
    className,
    handleGetClubsForTournament,
    currentPage,
    setCurrentPage,
    total,
    pageCount,
    competitionProfileData,
    rowsPerPage,
    setRowsPerPage,
}) => {
    return (
        <div className="table-competitors-pagination-wrapper table-competitors-wrapper competitions-participation-table">
            <Table className={`${className} clubs-participants-table`}>
                <thead>
                    <tr>
                        <th style={{ width: '70px', minWidth: '70px' }}>
                            <h5>Pozicija</h5>
                        </th>
                        <th>
                            <h5>Klub</h5>
                        </th>
                        <th style={{ minWidth: '118px' }}>
                            <h5>Region</h5>
                        </th>
                        <th style={{ minWidth: '118px' }}>
                            <h5>Sedište</h5>
                        </th>
                        <th></th>
                        {enableRemove && (
                            <>
                                <th></th>
                                <th></th>
                            </>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {clubs?.map((club, index) => (
                        <CompetitionClubsParticipantsRow
                            club={club}
                            key={index}
                            enableRemove={enableRemove}
                            handleGetClubsForTournament={
                                handleGetClubsForTournament
                            }
                            competitionProfileData={competitionProfileData}
                            index={index}
                            totalClubs={total}
                        />
                    ))}
                </tbody>
            </Table>
            <PaginationWrapper
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageCount={pageCount}
                total={total}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
            />
        </div>
    )
}

export default CompetitionClubsParticipants
