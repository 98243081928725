import React from 'react'
import { Row, Col, Table } from 'react-bootstrap'
import { getKeys } from '../../../../../lib/helpers'
import { numbersOnly, notLeadingZero } from '../../../../../lib/helpers'
import TextField from '../../../../../components/TextField'
const DoublesTournamentPointsTable = ({
    mainCategory,
    entryPoints,
    isEditable = false,
    setEntryPoints,
}) => {
    return (
        <Row>
            {getKeys(
                entryPoints?.entry_points_schema?.[`${mainCategory}`]?.doubles
                    ?.points
            )?.map((tournamentCategory, index) => (
                <Col key={index} xs={12} md={4}>
                    <Table className="table-competitors-pagination-wrapper table-competitors-wrapper">
                        <thead>
                            <tr>
                                <th></th>
                                <th>
                                    <h5>{tournamentCategory}</h5>
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {getKeys(
                                entryPoints?.entry_points_schema?.[
                                    `${mainCategory}`
                                ]?.doubles?.points?.[`${tournamentCategory}`]
                            )?.map((round, index) => (
                                <tr key={index}>
                                    <td>
                                        <p>
                                            {
                                                entryPoints
                                                    ?.entry_points_schema?.[
                                                    `${mainCategory}`
                                                ]?.doubles?.points?.[
                                                    `${tournamentCategory}`
                                                ][`${round}`]?.name
                                            }
                                        </p>
                                    </td>

                                    <td>
                                        {isEditable ? (
                                            <TextField
                                                className="entry-points-input-field"
                                                onChange={(e) => {
                                                    numbersOnly(e)
                                                    notLeadingZero(e)
                                                    const newPointsValue =
                                                        +e.target.value

                                                    setEntryPoints((prev) => {
                                                        return {
                                                            ...prev,
                                                            entry_points_schema:
                                                                {
                                                                    ...prev.entry_points_schema,
                                                                    [`${mainCategory}`]:
                                                                        {
                                                                            ...prev
                                                                                .entry_points_schema[
                                                                                `${mainCategory}`
                                                                            ],
                                                                            doubles:
                                                                                {
                                                                                    ...prev
                                                                                        .entry_points_schema[
                                                                                        `${mainCategory}`
                                                                                    ]
                                                                                        .doubles,
                                                                                    points: {
                                                                                        ...prev
                                                                                            .entry_points_schema[
                                                                                            `${mainCategory}`
                                                                                        ]
                                                                                            .doubles
                                                                                            .points,
                                                                                        [`${tournamentCategory}`]:
                                                                                            {
                                                                                                ...prev
                                                                                                    .entry_points_schema[
                                                                                                    `${mainCategory}`
                                                                                                ]
                                                                                                    .doubles
                                                                                                    .points[
                                                                                                    `${tournamentCategory}`
                                                                                                ],
                                                                                                [`${round}`]:
                                                                                                    {
                                                                                                        ...prev
                                                                                                            .entry_points_schema[
                                                                                                            `${mainCategory}`
                                                                                                        ]
                                                                                                            .doubles
                                                                                                            .points[
                                                                                                            `${tournamentCategory}`
                                                                                                        ][
                                                                                                            `${round}`
                                                                                                        ],
                                                                                                        points: newPointsValue, // Ažuriranje vrednosti bodova za ovu rundu
                                                                                                    },
                                                                                            },
                                                                                    },
                                                                                },
                                                                        },
                                                                },
                                                        }
                                                    })
                                                }}
                                                type="tel"
                                                value={
                                                    entryPoints
                                                        ?.entry_points_schema?.[
                                                        `${mainCategory}`
                                                    ]?.doubles?.points?.[
                                                        `${tournamentCategory}`
                                                    ][`${round}`]?.points || ''
                                                }
                                            >
                                                {entryPoints
                                                    ?.entry_points_schema?.[
                                                    `${mainCategory}`
                                                ]?.doubles?.points?.[
                                                    `${tournamentCategory}`
                                                ][`${round}`]?.points || ''}
                                            </TextField>
                                        ) : (
                                            <p>
                                                {entryPoints
                                                    ?.entry_points_schema?.[
                                                    `${mainCategory}`
                                                ]?.doubles?.points?.[
                                                    `${tournamentCategory}`
                                                ][`${round}`]?.points || ''}
                                            </p>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            ))}
        </Row>
    )
}

export default DoublesTournamentPointsTable
