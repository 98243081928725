import React from 'react'
import Table from 'react-bootstrap/Table'
import { Link } from 'react-router-dom'
import { generateAvatar } from '../../utils/generateAvatar'

import PaginationWrapper from '../Pagination'
import './styles.scss'

const TableRefereePagination = ({
    referees,
    className,
    currentPage,
    setCurrentPage,
    pageCount,
    total,
    rowsPerPage,
    setRowsPerPage,
}) => {
    return (
        <div className={`table-referee-pagination-wrapper ${className}`}>
            <Table responsive className="mb-0 d-none d-md-table">
                <thead>
                    <tr>
                        <th>
                            <h5>Sudija</h5>
                        </th>
                        <th>
                            <h5>Region</h5>
                        </th>
                        <th>
                            <h5>Licenca</h5>
                        </th>
                        <th>
                            <h5>Tip licence</h5>
                        </th>
                        <th>
                            <h5>Bedž (sudija u stolici)</h5>
                        </th>

                        <th>
                            <h5>Bedž (glavni sudija)</h5>
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {referees.map((referee, index) => (
                        <tr key={`referee-${index}`}>
                            <td className="link link-align-start">
                                <div className="d-flex align-items-center justify-content-start">
                                    <img
                                        src={
                                            referee?.attributes?.person_info
                                                ?.picture
                                                ? referee?.attributes
                                                      ?.person_info?.picture
                                                : generateAvatar(
                                                      `${referee?.attributes?.person_info?.given_name} ${referee?.attributes?.person_info?.family_name}`
                                                  )
                                        }
                                        alt={`${referee?.attributes?.person_info?.given_name} ${referee?.attributes?.person_info?.family_name}`}
                                        className="me-2 table-referee-pagination-ref-avatar "
                                    />
                                    <Link
                                        to={`/sudije/${referee?.attributes?.uuid}`}
                                    >
                                        <p className="link">{`${referee?.attributes?.person_info?.given_name} ${referee?.attributes?.person_info?.family_name}`}</p>
                                    </Link>
                                </div>
                            </td>
                            <td>
                                <p className="p-color">
                                    {referee?.attributes?.region?.region_name
                                        ? referee?.attributes?.region
                                              ?.region_name
                                        : '-'}
                                </p>
                            </td>
                            <td>
                                <p className="p-color">
                                    {referee?.attributes?.referees_licenses
                                        ?.attributes?.core_license
                                        ? referee?.attributes?.referees_licenses
                                              ?.attributes?.core_license
                                              ?.core_license_name
                                        : '-'}
                                </p>
                            </td>
                            <td>
                                <p className="p-color">
                                    {referee?.attributes?.referees_licenses
                                        ?.attributes?.national_license_type
                                        ?.national_license_type_name
                                        ? referee?.attributes?.referees_licenses
                                              ?.attributes
                                              ?.national_license_type
                                              ?.national_license_type_name
                                        : '-'}
                                </p>
                            </td>

                            <td>
                                <p className="p-color">
                                    {referee?.attributes?.referees_licenses
                                        ?.attributes?.international_chair_badge
                                        ?.international_chair_badge_name
                                        ? referee?.attributes?.referees_licenses
                                              ?.attributes
                                              ?.international_chair_badge
                                              ?.international_chair_badge_name
                                        : '-'}
                                </p>
                            </td>
                            <td>
                                <p className="p-color">
                                    {referee?.attributes?.referees_licenses
                                        ?.attributes?.international_main_badge
                                        ?.international_main_badge_name
                                        ? referee?.attributes?.referees_licenses
                                              ?.attributes
                                              ?.international_main_badge
                                              ?.international_main_badge_name
                                        : '-'}
                                </p>
                            </td>
                            <td>
                                <Link
                                    to={`/sudije/${referee?.attributes?.uuid}/izmeni-profil-sudije`}
                                >
                                    <p className="link">Izmeni</p>
                                </Link>{' '}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <PaginationWrapper
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageCount={pageCount}
                total={total}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
            />
        </div>
    )
}

export default TableRefereePagination
