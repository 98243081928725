import React, { useEffect, useState } from 'react'
import { Breadcrumb, Col, Row } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import Button from '../../../../../components/Button'
import ConfirmationModal from '../../../../../components/ConfirmationModal'
import { getSingleEntryPoints, updateEntryPoints } from '../../../../../lib/api'
import { getKeys, isEntryPointsDraft } from '../../../../../lib/helpers'
import ClassicTournamentPointsTable from './classicTournamentPointsTable'
import GrandPrixTournamentPointTable from './grandPrixTournamentPointTable'
import TeamTournamentPointsTable from './teamTournamentPointsTable'
import DoublesTournamentPointsTable from './doublesTournamentPointsTable'
import RoundRobinTournamentPointTable from './roundRobinTournamentPointsTable'

function ViewEntryPoints() {
    const { id: uuid } = useParams()
    const [entryPoints, setEntryPoints] = useState(null)
    const [showConfirmSaveDialog, setShowConfirmSaveDialog] = useState(false)

    const handleGetSingleEntryPoints = async (entryUuid) => {
        const response = await getSingleEntryPoints(entryUuid)
        if (response) {
            setEntryPoints(response?.attributes)
        }
    }

    const handleSaveEntryPoints = async () => {
        const response = await updateEntryPoints(uuid, {
            entry_points_schema: JSON.stringify(
                entryPoints?.entry_points_schema
            ),
            entry_points_status: { status_name: 'active' },
        })

        if (response) {
            setEntryPoints(response?.attributes)
            setShowConfirmSaveDialog(false)
        }
    }

    useEffect(() => {
        handleGetSingleEntryPoints(uuid)
    }, [uuid])

    return (
        <Row className="view-entry-points-wrapper h-100">
            <Col>
                <Row className="pt-3">
                    <Col
                        xs={12}
                        md={6}
                        className="d-flex align-items-center justify-content-between"
                    >
                        <Row>
                            <Col className="">
                                <h2 className="d-none d-md-block">
                                    {entryPoints?.entry_points_name}
                                </h2>
                                <p className="d-block d-md-none">
                                    {entryPoints?.entry_points_name}
                                </p>
                                <Breadcrumb className="mt-md-2 mt-1">
                                    <Breadcrumb.Item
                                        linkAs={Link}
                                        linkProps={{ to: '/pocetna' }}
                                    >
                                        Početna
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item
                                        linkAs={Link}
                                        linkProps={{ to: '/takmicenja' }}
                                    >
                                        Takmičenja
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item
                                        linkAs={Link}
                                        linkProps={{
                                            to: '/takmicenja/podesavanje-takmicenja',
                                        }}
                                    >
                                        Podešavanja
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item active>
                                        {entryPoints?.entry_points_name}
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>
                    </Col>
                    {entryPoints &&
                        isEntryPointsDraft(
                            entryPoints?.entry_points_status
                        ) && (
                            <Col
                                xs={12}
                                md={6}
                                className="d-none d-md-flex flex-column"
                            >
                                <Row className="d-flex align-items-center">
                                    <div className="d-flex justify-content-end w-100 mt-2 mt-md-0">
                                        <Button
                                            type="primary"
                                            onClick={() =>
                                                setShowConfirmSaveDialog(true)
                                            }
                                        >
                                            Sačuvaj bodovanje
                                        </Button>
                                    </div>
                                </Row>
                            </Col>
                        )}
                </Row>
                <Row className="mt-4">
                    {entryPoints &&
                        getKeys(entryPoints?.entry_points_schema)?.map(
                            (mainCategory, index) => (
                                <Col key={index} xs={12} className="mt-5 pt-4">
                                    <h2 className="mb-4">
                                        {
                                            entryPoints?.entry_points_schema?.[
                                                `${mainCategory}`
                                            ]?.name
                                        }
                                    </h2>

                                    {mainCategory === 'team_tournament' ? (
                                        <TeamTournamentPointsTable
                                            entryPoints={entryPoints}
                                            mainCategory={mainCategory}
                                            isEditable={
                                                entryPoints &&
                                                isEntryPointsDraft(
                                                    entryPoints?.entry_points_status
                                                )
                                                    ? true
                                                    : false
                                            }
                                            setEntryPoints={setEntryPoints}
                                        />
                                    ) : (
                                        <Row>
                                            <Col>
                                                <Row>
                                                    {getKeys(
                                                        entryPoints
                                                            ?.entry_points_schema?.[
                                                            `${mainCategory}`
                                                        ]?.points
                                                    )?.map(
                                                        (
                                                            tournamentCategory,
                                                            index
                                                        ) => (
                                                            <Col
                                                                key={index}
                                                                xs={12}
                                                                md={4}
                                                            >
                                                                {mainCategory ===
                                                                'grand_prix' ? (
                                                                    <GrandPrixTournamentPointTable
                                                                        mainCategory={
                                                                            mainCategory
                                                                        }
                                                                        tournamentCategory={
                                                                            tournamentCategory
                                                                        }
                                                                        entryPoints={
                                                                            entryPoints
                                                                        }
                                                                        isEditable={
                                                                            entryPoints &&
                                                                            isEntryPointsDraft(
                                                                                entryPoints?.entry_points_status
                                                                            )
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        setEntryPoints={
                                                                            setEntryPoints
                                                                        }
                                                                    />
                                                                ) : mainCategory ===
                                                                  'round_robin' ? (
                                                                    <RoundRobinTournamentPointTable
                                                                        mainCategory={
                                                                            mainCategory
                                                                        }
                                                                        tournamentCategory={
                                                                            tournamentCategory
                                                                        }
                                                                        entryPoints={
                                                                            entryPoints
                                                                        }
                                                                        isEditable={
                                                                            entryPoints &&
                                                                            isEntryPointsDraft(
                                                                                entryPoints?.entry_points_status
                                                                            )
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        setEntryPoints={
                                                                            setEntryPoints
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <ClassicTournamentPointsTable
                                                                        mainCategory={
                                                                            mainCategory
                                                                        }
                                                                        tournamentCategory={
                                                                            tournamentCategory
                                                                        }
                                                                        entryPoints={
                                                                            entryPoints
                                                                        }
                                                                        isEditable={
                                                                            entryPoints &&
                                                                            isEntryPointsDraft(
                                                                                entryPoints?.entry_points_status
                                                                            )
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        setEntryPoints={
                                                                            setEntryPoints
                                                                        }
                                                                    />
                                                                )}
                                                            </Col>
                                                        )
                                                    )}
                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <h4 className="mb-4">
                                                            {
                                                                entryPoints
                                                                    ?.entry_points_schema?.[
                                                                    `${mainCategory}`
                                                                ]?.doubles?.name
                                                            }
                                                        </h4>
                                                        <DoublesTournamentPointsTable
                                                            mainCategory={
                                                                mainCategory
                                                            }
                                                            entryPoints={
                                                                entryPoints
                                                            }
                                                            isEditable={
                                                                entryPoints &&
                                                                isEntryPointsDraft(
                                                                    entryPoints?.entry_points_status
                                                                )
                                                                    ? true
                                                                    : false
                                                            }
                                                            setEntryPoints={
                                                                setEntryPoints
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    )}
                                </Col>
                            )
                        )}
                </Row>
            </Col>
            <ConfirmationModal
                show={showConfirmSaveDialog}
                modalTitle="Potvrda čuvanja"
                modalText={`Jednom kada se bodovanje sačuva menjanje više nije moguće. Jeste li sigurni da želite da sačuvate bodovanje?`}
                onHide={() => setShowConfirmSaveDialog(false)}
                secondButtonText="Sačuvaj"
                onClick={handleSaveEntryPoints}
            />
        </Row>
    )
}

export default ViewEntryPoints
