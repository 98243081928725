import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import AddAdminReferee from '../components/AddAdminReferee'
import { useAppContext } from '../lib/contextLib'
import AdminWrapper from './Admin/AdminWrapper'
import Clubs from './Admin/Clubs'
import SingleClub from './Admin/Clubs/SingleClub'
import Coaches from './Admin/Coaches'
import CoachProfile from './Admin/Coaches/CoachProfile'
import CreateNewCoach from './Admin/Coaches/CreateNewCoach'
import Competitions from './Admin/Competitions'
import CompetitionProfile from './Admin/Competitions/CompetitionProfile'
import CreateOrEditTournament from './Admin/Competitions/CreateOrEditTournament'
import CompetitionsEntryPoints from './Admin/Competitions/EntryPoints'
import Competitors from './Admin/Competitors'
import CompetitorProfile from './Admin/Competitors/CompetitorProfile'
import DeparturePlayer from './Admin/Competitors/CompetitorProfile/DeparturePlayer'
import LendPlayer from './Admin/Competitors/CompetitorProfile/LendPlayer'
import RegisterPlayerToClub from './Admin/Competitors/CompetitorProfile/RegisterPlayerToClub'
import ClubsRanking from './Admin/Ranking/ClubsRanking'
import ClubsRankingSettings from './Admin/Ranking/ClubsRankingSettings'
import CompetitorsRanking from './Admin/Ranking/CompetitorsRanking'
import CompetitorsRankingByCategory from './Admin/Ranking/CompetitorsRankingByCategory'
import Referee from './Admin/Referee'
import CreateNewReferee from './Admin/Referee/CreateNewReferee'
import RefereeProfile from './Admin/Referee/RefereeProfile'
import Statistics from './Admin/Statistics'
import AllTournaments from './Competitor/AllTournaments'
import Competitor from './Competitor/index'
import Profile from './Referee/Profile'
import RefereeCompetitions from './Referee/RefereeCompetitions'
import CreateReport from './Referee/RefereeCompetitions/CreateReport'
import RefereeAdminCompetitions from './RefereeAdmin/RefereeAdminCompetitions'
import RefereeAdminProfile from './RefereeAdmin/RefereeAdminProfile'
import RefereeAdminSingleCompetition from './RefereeAdmin/RefereeAdminSingleCompetition'

import ClubForm from '../components/ClubForm'
import ViewEntryPoints from './Admin/Competitions/EntryPoints/ViewEntryPoints'
import EditProfile from './Competitor/EditProfile'

const ProtectedRoutes = () => {
    const { user } = useAppContext()

    const userRoutesHandler = () => {
        switch (user?.role?.type) {
            case 'admin':
                return (
                    <>
                        <Route path="/pocetna" element={<AdminWrapper />} />
                        <Route path="/takmicari" element={<Competitors />} />
                        <Route
                            path="/takmicari/:id/izmeni-profil-takmicara"
                            element={<EditProfile />}
                        />
                        <Route
                            path="/takmicari/:id"
                            element={<CompetitorProfile />}
                        />
                        <Route path="/takmicenja" element={<Competitions />} />
                        <Route
                            path="/takmicenja/:id"
                            element={<CompetitionProfile />}
                        />
                        <Route
                            path="/takmicenja/kreiraj-takmicenje"
                            element={<CreateOrEditTournament />}
                        />
                        <Route
                            path="/takmicenja/:id/izmeni-takmicenje"
                            element={<CreateOrEditTournament edit />}
                        />
                        <Route
                            path="/takmicenja/podesavanje-takmicenja"
                            element={<CompetitionsEntryPoints />}
                        />

                        <Route
                            path="/takmicenja/podesavanje-takmicenja/:id"
                            element={<ViewEntryPoints />}
                        />

                        <Route
                            path="/pozajmi-igraca/:id"
                            element={<LendPlayer />}
                        />
                        <Route
                            path="/promena-kluba-igraca/:id"
                            element={<RegisterPlayerToClub />}
                        />
                        <Route
                            path="/ispisi-igraca/:id"
                            element={<DeparturePlayer />}
                        />
                        <Route path="/treneri" element={<Coaches />} />
                        <Route
                            path="/treneri/kreiraj-trenera"
                            element={<CreateNewCoach />}
                        />
                        <Route path="/treneri/:id" element={<CoachProfile />} />
                        <Route
                            path="/treneri/:id/izmeni-profil-trenera"
                            element={<CreateNewCoach />}
                        />
                        <Route path="/klubovi" element={<Clubs />} />
                        <Route path="/klubovi/:id" element={<SingleClub />} />
                        <Route
                            path="/klubovi/kreiraj-klub"
                            element={<ClubForm />}
                        />
                        <Route
                            path="/klubovi/:id/izmeni-podatke-kluba"
                            element={<ClubForm />}
                        />
                        <Route path="/sudije" element={<Referee />} />
                        <Route
                            path="/sudije/kreiraj-novog-sudiju"
                            element={<CreateNewReferee />}
                        />
                        <Route
                            path="/sudije/:id"
                            element={<RefereeProfile />}
                        />
                        <Route
                            path="/sudije/:id/izmeni-profil-sudije"
                            element={<CreateNewReferee />}
                        />
                        <Route
                            path="/rang-lista-klubova"
                            element={<ClubsRanking />}
                        />
                        <Route
                            path="/rang-lista-takmicara"
                            element={<CompetitorsRanking />}
                        />
                        <Route
                            path="/rang-lista-po-tipu-licence/:id"
                            element={<CompetitorsRankingByCategory />}
                        />
                        <Route
                            path="/podesavanje-rangiranja-klubova"
                            element={<ClubsRankingSettings />}
                        />
                        <Route path="/statistika" element={<Statistics />} />
                        <Route path="*" element={<Navigate to="/pocetna" />} />
                    </>
                )
            case 'competitor':
                return (
                    <>
                        <Route
                            path="/takmicar/pocetna"
                            element={<Competitor />}
                        />
                        <Route
                            path="/takmicar/takmicenja"
                            element={<AllTournaments />}
                        />
                        <Route
                            path="/takmicar/rang-lista-klubova"
                            element={<ClubsRanking />}
                        />
                        <Route
                            path="/takmicar/rang-lista-takmicara"
                            element={<CompetitorsRanking />}
                        />
                        <Route
                            path="/rang-lista-po-tipu-licence/:id"
                            element={<CompetitorsRankingByCategory />}
                        />
                        <Route path="/klubovi/:id" element={<SingleClub />} />
                        <Route
                            path="/takmicar/takmicenja/:id"
                            element={<CompetitionProfile />}
                        />
                        <Route
                            path="/takmicar/klubovi/:id"
                            element={<SingleClub />}
                        />
                        <Route
                            path="/takmicari/:id"
                            element={<CompetitorProfile />}
                        />
                        <Route
                            path="*"
                            element={<Navigate to="/takmicar/pocetna" />}
                        />
                    </>
                )
            case 'referee':
                if (user?.data?.attributes?.is_administrator) {
                    return (
                        <>
                            <Route
                                path="/administrator-sudjenja/pocetna"
                                element={<RefereeAdminProfile />}
                            />
                            <Route
                                path="/administrator-sudjenja/takmicenja-iz-kalendara-TSS"
                                element={<RefereeAdminCompetitions />}
                            />
                            <Route
                                path="/administrator-sudjenja/individualna-takmicenja"
                                element={<RefereeAdminCompetitions />}
                            />
                            <Route
                                path="/administrator-sudjenja/ekipna-takmicenja"
                                element={<RefereeAdminCompetitions />}
                            />
                            <Route
                                path="/administrator-sudjenja/takmicenja-iz-kalendara-TSS/:id"
                                element={<RefereeAdminSingleCompetition />}
                            />
                            <Route
                                path="/administrator-sudjenja/individualna-takmicenja/:id"
                                element={
                                    <RefereeAdminSingleCompetition
                                        type={'Individualno'}
                                    />
                                }
                            />
                            <Route
                                path="/administrator-sudjenja/ekipna-takmicenja/:id"
                                element={
                                    <RefereeAdminSingleCompetition
                                        type={'Ekipno'}
                                    />
                                }
                            />
                            <Route
                                path="/administrator-sudjenja/takmicenja/:id/dodaj-sudiju"
                                element={<AddAdminReferee />}
                            />
                            <Route
                                path="/administrator-sudjenja/takmicenja/:id/izmeni-sudije"
                                element={<AddAdminReferee edit />}
                            />
                            <Route
                                path="/administrator-sudjenja/klubovi/:id"
                                element={<SingleClub />}
                            />
                            <Route
                                path="*"
                                element={
                                    <Navigate to="/administrator-sudjenja/pocetna" />
                                }
                            />
                            <Route
                                path="/takmicari/:id"
                                element={<CompetitorProfile />}
                            />
                            <Route
                                path="/klubovi/:id"
                                element={<SingleClub />}
                            />
                            <Route
                                path="/rang-lista-klubova"
                                element={<ClubsRanking />}
                            />
                            <Route
                                path="/rang-lista-takmicara"
                                element={<CompetitorsRanking />}
                            />
                            <Route
                                path="/rang-lista-po-tipu-licence/:id"
                                element={<CompetitorsRankingByCategory />}
                            />
                        </>
                    )
                } else {
                    return (
                        <>
                            <Route
                                path="/sudije/pocetna"
                                element={<Profile />}
                            />
                            <Route
                                path="/sudije/takmicenja-iz-kalendara-TSS"
                                element={<RefereeCompetitions />}
                            />
                            <Route
                                path="/sudije/individualna-takmicenja"
                                element={<RefereeCompetitions />}
                            />
                            <Route
                                path="/sudije/ekipna-takmicenja"
                                element={<RefereeCompetitions />}
                            />

                            <Route
                                path="/sudije/takmicenja-iz-kalendara-TSS/:id"
                                element={<CompetitionProfile />}
                            />

                            <Route
                                path="/sudije/individualna-takmicenja/:id"
                                element={
                                    <CompetitionProfile type={'Individualno'} />
                                }
                            />
                            <Route
                                path="/sudije/ekipna-takmicenja/:id"
                                element={<CompetitionProfile type={'Ekipno'} />}
                            />
                            <Route
                                path="/sudije/klubovi/:id"
                                element={<SingleClub />}
                            />
                            <Route
                                path="/sudije/takmicenja-iz-kalendara-TSS/:id/kreiraj-izvestaj"
                                element={<CreateReport />}
                            />
                            <Route
                                path="/sudije/takmicenja-iz-kalendara-TSS/:id/izmeni-izvestaj"
                                element={<CreateReport />}
                            />
                            <Route
                                path="/takmicari/:id"
                                element={<CompetitorProfile />}
                            />
                            <Route
                                path="*"
                                element={<Navigate to="/sudije/pocetna" />}
                            />
                            <Route
                                path="/klubovi/:id"
                                element={<SingleClub />}
                            />
                            <Route
                                path="/rang-lista-klubova"
                                element={<ClubsRanking />}
                            />
                            <Route
                                path="/rang-lista-takmicara"
                                element={<CompetitorsRanking />}
                            />
                            <Route
                                path="/rang-lista-po-tipu-licence/:id"
                                element={<CompetitorsRankingByCategory />}
                            />
                        </>
                    )
                }
        }
    }

    return <Routes>{userRoutesHandler()}</Routes>
}

export default ProtectedRoutes
