import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import TextField from '../../../../../../components/TextField'
import Button from '../../../../../../components/Button'
import { createField } from '../../../../../../lib/api'

const AddFieldModal = ({
    show,
    onHide,
    tournament,
    handleShowTournamentsField,
}) => {
    const [fieldName, setFieldName] = useState('')

    const handleCreateField = async () => {
        const newField = await createField({
            tournament_uuid: tournament?.attributes?.uuid,
            field_name: fieldName,
        })

        if (newField) {
            onHide()
            setFieldName('')
            handleShowTournamentsField()
        }
    }
    return (
        <Modal
            centered
            show={show}
            onHide={onHide}
            dialogClassName="add-penalties-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>Dodavanje terena</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TextField
                    label="Naziv terena"
                    placeholder="Naziv terena"
                    type="text"
                    value={fieldName || ''}
                    onChange={(e) => setFieldName(e.target.value)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button type="text" onClick={onHide}>
                    Zatvori
                </Button>
                <Button
                    onClick={() => handleCreateField()}
                    disabled={fieldName ? false : true}
                >
                    Dodaj teren
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddFieldModal
