import DownloadCloudOutlined from '../../assets/svg/DownloadCloudOutlined'
import PaperClip from '../../assets/svg/PaperClip'
import SuccessIcon from '../../assets/svg/SuccessIcon'
import TrashOutlined from '../../assets/svg/TrashOutlined'

import './styles.scss'
const FileUploadTag = ({
    className,
    fileName,
    deleteFile = false,
    downloadFile = false,
}) => {
    return (
        <div className={`file-upload-tag ${className}`}>
            <div className="d-flex align-items-center name-part">
                <PaperClip className="file-paperclip" />
                <p className="ms-2 file-name">{fileName}</p>
            </div>
            <div className="ms-1 d-flex align-items-center icons">
                {!downloadFile && <SuccessIcon />}
                {deleteFile && (
                    <TrashOutlined
                        width="24px"
                        height="24px"
                        onClick={(e) => {
                            deleteFile && deleteFile(e)
                        }}
                        role="button"
                    />
                )}
                {downloadFile && (
                    <DownloadCloudOutlined
                        width="24px"
                        height="24px"
                        className="download-icon"
                        onClick={(e) => {
                            downloadFile && downloadFile(e)
                        }}
                        role="button"
                    />
                )}
            </div>
        </div>
    )
}

export default FileUploadTag
