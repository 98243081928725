import * as React from 'react'

const TrophyOutline = (props) => (
    <svg
        width={20}
        height={18}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g
            clipPath="url(#a)"
            stroke="#4B852D"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M6.875 18.125h6.25-6.25Zm3.125 0v-5 5Zm5-9.375c0-1.978-.003-5.259-.005-6.25a.625.625 0 0 0-.625-.625l-8.741.01a.625.625 0 0 0-.625.623L4.999 8.75c0 2.51 3.242 4.375 5 4.375 1.757 0 5.001-1.864 5.001-4.375Z" />
            <path d="M15 3.75h3.125v.625c0 2.157-1.31 4.375-3.125 4.375m-10-5H1.875v.625C1.875 6.532 3.185 8.75 5 8.75v-5Z" />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h20v20H0z" />
            </clipPath>
        </defs>
    </svg>
)

export default TrophyOutline
