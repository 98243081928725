import Camera from '../../assets/svg/Camera'
import './style.scss'

const UploadImage = ({
    htmlFor,
    user,
    imageKey,
    textBelow,
    className,
    previewData,
    disabled = false,
}) => (
    <label htmlFor={htmlFor} className={`upload-img-wrapper ${className}`}>
        <div className={`upload-img ${disabled ? 'disabled' : ''}`}>
            {(user && user[imageKey]) || previewData ? (
                <img
                    src={previewData ? previewData : user[imageKey]}
                    alt="image"
                />
            ) : (
                <>
                    <Camera />
                    {disabled ? null : <p>Izaberi fotografiju</p>}
                </>
            )}
        </div>
        <p className="small-text">{textBelow}</p>
    </label>
)

export default UploadImage
