import Danger from '../assets/svg/Danger'
import GreenCheck from '../assets/svg/GreenCheck'
import Warning from '../assets/svg/Warning'
import Pill from '../components/Pill'

export const pillStatusHandler = (status, mobile) => {
    switch (status) {
        case 'Aktivan':
            return (
                <Pill type="active" icon={<GreenCheck />}>
                    <p className={`pill-content ${mobile ? 'small-text' : ''}`}>
                        Aktivan
                    </p>
                </Pill>
            )
        case 'U mirovanju':
            return (
                <Pill type="punished" icon={<Warning />}>
                    <p className={`pill-content ${mobile ? 'small-text' : ''}`}>
                        U mirovanju
                    </p>
                </Pill>
            )
        case 'Neaktivan':
            return (
                <Pill type="inactive" icon={<Danger />}>
                    <p className={`pill-content ${mobile ? 'small-text' : ''}`}>
                        Neaktivan
                    </p>
                </Pill>
            )
        case 'Kažnjen':
            return (
                <Pill type="punished" icon={<Warning />}>
                    <p className={`pill-content ${mobile ? 'small-text' : ''}`}>
                        Kažnjen
                    </p>
                </Pill>
            )
        case 'Draft':
            return (
                <Pill type="punished" icon={<Warning />}>
                    <p className={`pill-content ${mobile ? 'small-text' : ''}`}>
                        Draft
                    </p>
                </Pill>
            )
        default:
            return <p>-</p>
    }
}
