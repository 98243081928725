import React, { useEffect, useState } from 'react'
import { Col, Row, Tab, Tabs } from 'react-bootstrap'
import CompetitionCard from '../../../../../../components/CompetitionsTableCards/CompetitionsCard'
import NoDataForView from '../../../../../../components/NoDataForView'
import TableCompetitionsPagination from '../../../../../../components/TableCompetitonsPagination'
import { isArrayEmpty } from '../../../../../../lib/helpers'

const Organisation = ({
    club,
    getComingCompetitions,
    comingCompetitions,
    getAllFinishedCompetitions,
    finishedCompetitions,
    currentPage,
    setCurrentPage,
    total,
    pageCount,
    searchValue,
    setSearchValue,
    setOrganizationActiveTab,
    rowsPerPage,
    setRowsPerPage,
}) => {
    const [selectedTab, setSelectedTab] = useState('nadolazeca')

    const handleTabSelect = (tab) => {
        setSearchValue('')

        setSelectedTab((_) => {
            tab === 'nadolazeca'
                ? getComingCompetitions(1, {
                      club_organizer_uuid: club?.attributes?.uuid,
                  })
                : getAllFinishedCompetitions(1, {
                      club_organizer_uuid: club?.attributes?.uuid,
                  })
            setOrganizationActiveTab(tab)
            return tab
        })
    }

    useEffect(() => {
        selectedTab === 'nadolazeca'
            ? getComingCompetitions(currentPage, {
                  club_organizer_uuid: club?.attributes?.uuid,
              })
            : getAllFinishedCompetitions(currentPage, {
                  club_organizer_uuid: club?.attributes?.uuid,
              })
    }, [currentPage, selectedTab, searchValue, club, rowsPerPage])

    return (
        <Row className="pt-3 m-0">
            <Col className="p-0 tabular-navigation">
                <Tabs
                    id="uncontrolled-tab-example"
                    onSelect={(tab) => {
                        setCurrentPage(1)
                        setSearchValue('')
                        handleTabSelect(tab)
                    }}
                >
                    <Tab eventKey="nadolazeca" title="Nadolazeća">
                        <Row className="d-none d-md-block">
                            <Col>
                                <TableCompetitionsPagination
                                    data={comingCompetitions}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    pageCount={pageCount}
                                    total={total}
                                    className="d-none d-md-block"
                                    rowsPerPage={rowsPerPage}
                                    setRowsPerPage={setRowsPerPage}
                                />
                            </Col>
                        </Row>

                        <Row className="d-md-none">
                            <Col>
                                {!isArrayEmpty(comingCompetitions) ? (
                                    comingCompetitions.map((result, index) => (
                                        <CompetitionCard
                                            key={index}
                                            result={result}
                                            isWhite={index % 2 !== 0}
                                        />
                                    ))
                                ) : (
                                    <NoDataForView
                                        title={`Nema nadolazećih takmičenja`}
                                        description={`Ovde je prikazana lista nadolazećih takmičenja.`}
                                        className="border-0"
                                        column
                                    />
                                )}
                            </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="zavrsena" title="Završena">
                        <Row className="d-none d-md-block">
                            <Col>
                                <TableCompetitionsPagination
                                    data={finishedCompetitions}
                                    finished={true}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    pageCount={pageCount}
                                    total={total}
                                    className="d-none d-md-block"
                                    rowsPerPage={rowsPerPage}
                                    setRowsPerPage={setRowsPerPage}
                                />
                            </Col>
                        </Row>
                        <Row className="d-md-none">
                            <Col>
                                {!isArrayEmpty(finishedCompetitions) ? (
                                    finishedCompetitions.map(
                                        (result, index) => (
                                            <CompetitionCard
                                                key={index}
                                                result={result}
                                                isWhite={index % 2 !== 0}
                                                finished={true}
                                            />
                                        )
                                    )
                                ) : (
                                    <NoDataForView
                                        title={`Nema završenih takmičenja`}
                                        description={`Ovde je prikazana lista završenih takmičenja.`}
                                        className="border-0"
                                        column
                                    />
                                )}
                            </Col>
                        </Row>
                    </Tab>
                </Tabs>
            </Col>
        </Row>
    )
}

export default Organisation
