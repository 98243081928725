import React from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import ArrowUp from '../../assets/svg/ArrowUp'
import Pill from '../Pill'
import { PieChart, Pie, Cell } from 'recharts'

import './styles.scss'

function StatisticsCard({
    className,
    title,
    number,
    percentage,
    image,
    chartSmall,
    chartMedium,
    chartBig,
}) {
    const RADIAN = Math.PI / 180
    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
        index,
    }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5
        const x = cx + radius * Math.cos(-midAngle * RADIAN)
        const y = cy + radius * Math.sin(-midAngle * RADIAN)

        return (
            <text
                x={x}
                y={y}
                className="chart-percent"
                textAnchor={'middle'}
                dominantBaseline="central"
            >
                {`${(percent * 100).toFixed(1)}`}
            </text>
        )
    }

    return (
        <Card
            className={`statistics-card ${
                chartSmall
                    ? 'chartSmall'
                    : chartBig
                    ? 'chartBig'
                    : chartMedium
                    ? 'chartMedium'
                    : ''
            } ${className}`}
        >
            <Card.Body className={`${!chartMedium && 'p-0'}`}>
                <Row>
                    <Col
                        className={`d-flex ${
                            !(chartSmall || chartMedium || chartBig) &&
                            'justify-content-between'
                        } align-items-center statistics-card-content`}
                    >
                        <div className="statistics-card-info w-100">
                            {number ? (
                                <h5
                                    className={`${
                                        chartSmall || chartMedium
                                            ? 'mb-4'
                                            : chartBig
                                            ? 'mb-5'
                                            : 'mb-3'
                                    }`}
                                >
                                    {title}
                                </h5>
                            ) : (
                                <h2
                                    className={`${
                                        chartSmall || chartMedium
                                            ? 'mb-4'
                                            : chartBig
                                            ? 'mb-5'
                                            : 'mb-3'
                                    }`}
                                >
                                    {title}
                                </h2>
                            )}
                            {number && <h1 className="mb-1">{number}</h1>}
                            {percentage && (
                                <Pill type="badge-success" icon={<ArrowUp />}>
                                    {`${percentage}%`}
                                </Pill>
                            )}
                            {chartSmall && (
                                <div className="statistics-card-chart-sm d-flex align-items-center justify-content-center">
                                    <div className="chart-info d-flex flex-column me-4">
                                        <h5 className="chart-info-text d-flex justify-content-end">
                                            {chartSmall[0].value}%
                                        </h5>
                                        <div className="d-flex align-items-center">
                                            <p className="small-text">
                                                {chartSmall[0].name}
                                            </p>
                                            <div className="chart-info-badge right ms-1"></div>
                                        </div>
                                    </div>
                                    <div className="chart-gender">
                                        <PieChart width={120} height={120}>
                                            <Pie
                                                data={chartSmall}
                                                innerRadius={30}
                                                outerRadius={60}
                                                dataKey="value"
                                                startAngle={-270}
                                            >
                                                {chartSmall.map(
                                                    (entry, index) => (
                                                        <Cell
                                                            key={`cell-${index}`}
                                                            fill={entry?.color}
                                                        />
                                                    )
                                                )}
                                            </Pie>
                                        </PieChart>
                                    </div>
                                    <div className="chart-info d-flex flex-column ms-4">
                                        <h5 className="chart-info-text d-flex justify-content-start">
                                            {chartSmall[1].value}%
                                        </h5>
                                        <div className="d-flex align-items-center">
                                            <div className="chart-info-badge left me-1"></div>
                                            <p className="small-text">
                                                {chartSmall[1].name}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {chartMedium && (
                                <div className="statistics-card-chart-md d-flex align-items-center justify-content-center">
                                    <div className="chart-medium">
                                        <PieChart width={240} height={240}>
                                            <Pie
                                                data={chartMedium}
                                                innerRadius={0}
                                                outerRadius={120}
                                                labelLine={false}
                                                label={renderCustomizedLabel}
                                                dataKey="value"
                                                startAngle={-240}
                                                cx="50%"
                                                cy="50%"
                                            >
                                                {chartMedium.map(
                                                    (entry, index) => (
                                                        <Cell
                                                            key={`cell-${index}`}
                                                            fill={
                                                                chartMedium[
                                                                    index %
                                                                        chartMedium.length
                                                                ]?.color
                                                            }
                                                        />
                                                    )
                                                )}
                                            </Pie>
                                        </PieChart>
                                    </div>
                                </div>
                            )}
                            {chartBig && (
                                <div className="statistics-card-chart-big d-flex align-items-center justify-content-center">
                                    <div className="chart-big">
                                        {chartMedium}
                                    </div>
                                </div>
                            )}
                        </div>
                        {image && (
                            <div className="statistics-card-image">{image}</div>
                        )}
                    </Col>
                </Row>
            </Card.Body>
            {chartMedium && (
                <Card.Footer className="statistics-card-medium-footer">
                    {chartMedium.map(({ segment, color }, index) => (
                        <div
                            key={index}
                            className="segment-wrapper d-flex align-items-center"
                        >
                            <div
                                key={index}
                                className="segment"
                                style={{ backgroundColor: color }}
                            ></div>
                            <p className="small-text">{segment}</p>
                        </div>
                    ))}
                </Card.Footer>
            )}
        </Card>
    )
}

export default StatisticsCard
