import './styles.scss'

const Button = (props) => {
    const {
        leadingIcon,
        endIcon,
        className,
        disabled,
        onClick,
        type = 'primary',
        children,
    } = props

    return (
        <button
            disabled={disabled}
            onClick={onClick}
            className={`button-wrapper btn-variant-${type} d-flex align-items-center justify-content-center ${className}`}
        >
            {leadingIcon && (
                <div className="leading-icon d-flex align-items-center justify-content-center">
                    {leadingIcon}
                </div>
            )}
            <h5>{children}</h5>
            {endIcon && (
                <div className="end-icon d-flex align-items-center justify-content-center">
                    {endIcon}
                </div>
            )}
        </button>
    )
}

export default Button
