import { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Row from 'react-bootstrap/Row'
import { Link, useNavigate, useParams } from 'react-router-dom'
import CloseOutlined from '../../../../assets/svg/CloseOutlined'
import Button from '../../../../components/Button'
import {
    createRefereeLicenses,
    createRefereeTest,
    getClubs,
    getRefereeLicense,
    getRefereeTest,
    getSingleReferee,
    registerUser,
    updateRefereeLicenses,
    updateRefereeProfile,
    updateRefereeTest,
} from '../../../../lib/api'
import { useAppContext } from '../../../../lib/contextLib'
import { checkJMBG, sleep } from '../../../../lib/helpers'
import FirstStep from './FirstStep'
import FourthStep from './FourthStep'
import SecondStep from './SecondStep'
import ThirdStep from './ThirdStep'
import './styles.scss'

const CreateNewReferee = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const { setShowCustomToast } = useAppContext()
    const [page, setPage] = useState(0)
    const [progress, setProgress] = useState(25)
    const [refereeImg, setRefereeImg] = useState(null)
    const [refereePreviewImg, setRefereePreviewImg] = useState()
    const [invitationSending, setInvitationSending] = useState(false)

    const [formData, setFormData] = useState({
        person_info: {},
    })

    const [refereeLicensesData, setRefereeLicensesData] = useState()
    const [refereeTest, setRefereeTest] = useState({})

    function removeIdFields(obj) {
        const result = {}

        for (let key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                result[key] = removeIdFields(obj[key])
            } else if (
                key !== 'id' &&
                key !== 'createdAt' &&
                key !== 'updatedAt' &&
                key !== 'locale' &&
                obj[key] !== null
            ) {
                result[key] = obj[key]
            }
        }
        return result
    }

    const getRefereeProfile = async () => {
        let referee = await getSingleReferee(id)
        if (referee) {
            setFormData(removeIdFields(referee?.attributes))
            referee?.attributes?.person_info?.picture &&
                setRefereePreviewImg(referee?.attributes?.person_info?.picture)
        } else {
            navigate('/sudije')
        }
    }

    const handleGetRefereesLicenses = async () => {
        const refereesLicenses = await getRefereeLicense(id)
        if (refereesLicenses) {
            setRefereeLicensesData(refereesLicenses?.attributes)
        }
    }

    const getRefereesTests = async () => {
        const refereesTests = await getRefereeTest(id)
        if (refereesTests) {
            setRefereeTest(refereesTests?.attributes)
        }
    }

    useEffect(() => {
        if (id) {
            getRefereeProfile()
            handleGetRefereesLicenses()
            getRefereesTests()
        }
    }, [id])

    // Get all clubs
    const getClubList = async (searchValue, loadedOptions, { page }) => {
        await sleep(1000)

        let name = searchValue.split(' ')[0]

        let search_params = {
            name,
        }
        let { clubs, pgCount } = await getClubs(
            ['name', 'uuid'],
            search_params,
            null,
            page
        )

        if (clubs) {
            let list = []
            clubs.map((club) => {
                let clubDetails = {
                    value: `${club?.attributes?.name}`,
                    uuid: club?.attributes?.uuid,
                }
                list.push(clubDetails)
            })

            return {
                options: list,
                hasMore: page >= pgCount ? false : true,
                additional: {
                    page: page + 1,
                },
            }
        }
    }

    const [disableNextButton, setDisableNextButton] = useState(false)

    const handleRequiredFields = () => {
        if (page === 0) {
            if (
                formData.person_info?.given_name &&
                formData.person_info?.family_name &&
                formData?.person_info?.email &&
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                    formData?.person_info?.email
                )
            ) {
                setDisableNextButton(false)
            } else {
                setDisableNextButton(true)
            }
        } else if (page === 1) {
            if (
                !formData?.person_info?.jmbg ||
                (formData?.person_info?.jmbg.length > 0 &&
                    checkJMBG(formData?.person_info?.jmbg))
            ) {
                setDisableNextButton(false)
            } else {
                setDisableNextButton(true)
            }
        } else if (page === 2) {
            setDisableNextButton(false)
        } else if (page === 3) {
            if (refereeLicensesData?.core_license?.core_license_name) {
                setDisableNextButton(false)
            } else {
                setDisableNextButton(true)
            }
        }
    }

    const FormTitles = [
        'Osnovni podaci',
        'Lični podaci',
        'Kontakt podaci',
        'Podaci o licenci',
    ]

    const PageDisplay = () => {
        switch (page) {
            case 0:
                return (
                    <FirstStep
                        setProgress={setProgress}
                        formData={formData}
                        setFormData={setFormData}
                        setRefereeImg={setRefereeImg}
                        refereePreviewImg={refereePreviewImg}
                        setRefereePreviewImg={setRefereePreviewImg}
                    />
                )
            case 1:
                return (
                    <SecondStep
                        setProgress={setProgress}
                        formData={formData}
                        setFormData={setFormData}
                        disableNextButton={disableNextButton}
                        setDisableNextButton={setDisableNextButton}
                    />
                )
            case 2:
                return (
                    <ThirdStep
                        setProgress={setProgress}
                        formData={formData}
                        setFormData={setFormData}
                    />
                )
            case 3:
                return (
                    <FourthStep
                        setProgress={setProgress}
                        getClubList={getClubList}
                        refereeLicensesData={refereeLicensesData}
                        setRefereeLicensesData={setRefereeLicensesData}
                        refereeTest={refereeTest}
                        setRefereeTest={setRefereeTest}
                    />
                )
            default:
                break
        }
    }

    const handleRefereeInvitationSubmit = async () => {
        setInvitationSending(true)
        const auth = {
            email: formData?.person_info?.email,
            password: 'Password1!',
            role: 'referee',
        }

        const user_info = {
            ...formData,
        }

        try {
            let data = await registerUser(auth, user_info, null)

            if (data) {
                await createRefereeLicenses({
                    ...refereeLicensesData,
                    referee_uuid: data?.attributes?.uuid,
                })

                await createRefereeTest({
                    ...refereeTest,
                    referee_uuid: data?.attributes?.uuid,
                })

                setShowCustomToast({
                    show: true,
                    type: 'success',
                    message: 'Sudija uspešno pozvan.',
                })
                setInvitationSending(false)
                navigate('/sudije')
            }
        } catch (error) {
            switch (error.response.data.error.message) {
                case 'Email or Username are already taken':
                    setShowCustomToast({
                        show: true,
                        type: 'error',
                        message: 'Takmičar sa unetim emailom već postoji.',
                    })
                    setInvitationSending(false)
                    break
                case 'Register action is currently disabled':
                    setShowCustomToast({
                        show: true,
                        type: 'error',
                        message: 'Registracija trenutno nije moguća.',
                    })
                    setInvitationSending(false)
                    break

                default:
                    setShowCustomToast({
                        show: true,
                        type: 'error',
                        message: 'Došlo je do greške.',
                    })
                    setInvitationSending(false)
                    break
            }
        }
        setInvitationSending(false)
    }

    const handleUpdateReferee = async () => {
        try {
            const updatedReferee = await updateRefereeProfile(
                id,
                formData,
                refereeImg
            )

            if (updatedReferee) {
                setShowCustomToast({
                    show: true,
                    type: 'success',
                    message: 'Sudija je uspešno ažuriran.',
                })
                navigate('/sudije')
            }
        } catch (error) {
            if (error?.response?.data?.error?.message?.includes('unique')) {
                setShowCustomToast({
                    show: true,
                    type: 'error',
                    message: 'Email mora biti jedinstven!',
                })
            } else {
                setShowCustomToast({
                    show: true,
                    type: 'error',
                    message: 'Došlo je do greške.',
                })
            }
        }
    }

    const handleUpadteRefereesLicenses = async () => {
        try {
            if (refereeLicensesData?.uuid) {
                await updateRefereeLicenses(
                    refereeLicensesData?.uuid,
                    refereeLicensesData
                )
            } else {
                await createRefereeLicenses({
                    ...refereeLicensesData,
                    referee_uuid: id,
                })
            }
        } catch (error) {
            setShowCustomToast({
                show: true,
                type: 'error',
                message: 'Došlo je do greške.',
            })
        }
    }

    const handleUpadteRefereesTest = async () => {
        try {
            if (refereeTest?.uuid) {
                await updateRefereeTest(refereeTest?.uuid, refereeTest)
            } else {
                await createRefereeTest({
                    ...refereeTest,
                    referee_uuid: id,
                })
            }
        } catch (error) {
            setShowCustomToast({
                show: true,
                type: 'error',
                message: 'Došlo je do greške.',
            })
        }
    }

    const handleClickButton1 = () => {
        setPage((currPage) => currPage - 1)
    }

    const handleClickButton2 = () => {
        if (page === FormTitles.length - 1) {
            if (id) {
                handleUpdateReferee()
                handleUpadteRefereesLicenses()
                handleUpadteRefereesTest()
            } else {
                handleRefereeInvitationSubmit()
            }
        } else {
            setPage((currPage) => currPage + 1)
        }
    }

    useEffect(() => {
        handleRequiredFields()
    }, [formData, page, refereeTest, refereeLicensesData])

    return (
        <Row className="create-new-referee-wrapper justify-content-center">
            <Col className="create-referee-wrapper px-0">
                <Row className="justify-content-center">
                    <ProgressBar now={progress} className="px-0" />
                    <Col className="d-flex flex-column align-items-center justify-content-end close-icon mt-2 mt-md-3 me-md-3">
                        <Row className="w-100 align-items-center justify-content-end">
                            <Link
                                to="/sudije"
                                className="d-flex justify-content-end"
                            >
                                <CloseOutlined />
                            </Link>
                        </Row>
                    </Col>
                    <Row className="referee-form-wrapper justify-content-center">
                        <Col md={4}>
                            <Row className="mt-2">
                                <Col
                                    md={12}
                                    className="d-flex align-items-center justify-content-center"
                                >
                                    <h1>{FormTitles[page]}</h1>
                                </Col>
                                <Col
                                    md={12}
                                    className="mt-2 mb-4 d-flex align-items-center justify-content-center"
                                >
                                    <p className="small-text">{`${page + 1}/${
                                        FormTitles.length
                                    }`}</p>
                                </Col>
                                {/* content based on component */}
                                <Col className="mt-2 align-items-center justify-content-center">
                                    {PageDisplay()}
                                </Col>
                                <Col
                                    md={12}
                                    className="mt-4 d-flex flex-md-row flex-column align-items-center justify-content-end form-footer-buttons"
                                >
                                    <Link
                                        to={page === 0 && '/sudije'}
                                        className="link-text fotter-button"
                                        tabIndex="1"
                                    >
                                        <Button
                                            type="text"
                                            className="footer-button"
                                            onClick={handleClickButton1}
                                        >
                                            {page === 0
                                                ? 'Odustani'
                                                : 'Prethodna'}
                                        </Button>
                                    </Link>
                                    <Button
                                        className="footer-button"
                                        onClick={handleClickButton2}
                                        disabled={
                                            disableNextButton ||
                                            invitationSending
                                        }
                                        leadingIcon={
                                            invitationSending ? (
                                                <Spinner
                                                    animation="border"
                                                    variant="primary"
                                                    size="sm"
                                                    className="invitation-spinner"
                                                />
                                            ) : null
                                        }
                                    >
                                        {page === FormTitles.length - 1
                                            ? 'Sačuvaj'
                                            : 'Sledeća'}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Row>
            </Col>
        </Row>
    )
}

export default CreateNewReferee
