import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Slider from 'react-slick'
import TournamentClubMatchCard from '../../../../../../../components/TournamentClubMatchCard'
import TournamentRoundCard from '../../../../../../../components/TournamentRoundCard'
import { getTeamTournamentParticipantsNumber } from '../../../../../../../lib/api'
import {
    handleNumberOfMatchCardInRound,
    handleRoundName,
    handleRoundsNumber,
} from '../../../../../../../lib/helpers'
import { settings } from '../carouselSettings'
import TeamRoundRobin from './TeamRoundRobin'

const TeamDraw = ({ tournament, drawToShow, mainSliderToShow }) => {
    const singleCardHeight = 238

    const [sliderToShow, setSliderToShow] = useState()

    const [numberOfGroups, setNumberOfGroups] = useState(1)

    const handleTeamRoundRobinGroupNumber = async () => {
        if (tournament?.attributes?.tournament_have_round_roubin) {
            const { total } = await getTeamTournamentParticipantsNumber(
                tournament?.attributes?.uuid
            )

            if (!total) return
            let fullGroups = Math.floor(total / 4)
            let rest = total % 4
            if (rest > 0) {
                fullGroups += 1
            }

            setNumberOfGroups(fullGroups)
        } else {
            if (tournament?.attributes?.participants_expected_number === 48) {
                setNumberOfGroups(32)
            } else {
                setNumberOfGroups(
                    +tournament?.attributes?.participants_expected_number / 2
                )
            }
        }
    }

    useEffect(() => {
        handleTeamRoundRobinGroupNumber()
    }, [mainSliderToShow])

    const handleSldierToShow = () => {
        if (drawToShow?.draw_type?.draw_type_name === 'Glavni turnir') {
            const drawSize = numberOfGroups
            return (
                <Slider {...settings(3)} className="rounds-slider">
                    {[...Array(handleRoundsNumber(drawSize) + 1)]?.map(
                        (_, roundIndex) => (
                            <TournamentRoundCard
                                key={roundIndex}
                                index={roundIndex}
                                roundName={handleRoundName(
                                    handleRoundsNumber(drawSize) + 1,
                                    roundIndex + 1
                                )}
                            >
                                {[
                                    ...Array(
                                        handleNumberOfMatchCardInRound(
                                            handleRoundsNumber(drawSize) -
                                                roundIndex
                                        )
                                    ),
                                ]?.map((_, cardiIndex) => (
                                    <Row
                                        key={cardiIndex}
                                        style={{
                                            marginTop:
                                                cardiIndex === 0
                                                    ? `${
                                                          (Math.pow(
                                                              2,
                                                              roundIndex
                                                          ) *
                                                              singleCardHeight) /
                                                              2 -
                                                          singleCardHeight / 2
                                                      }px`
                                                    : `${
                                                          Math.pow(
                                                              2,
                                                              roundIndex
                                                          ) *
                                                              singleCardHeight -
                                                          singleCardHeight
                                                      }px`,

                                            paddingBottom: '20px',
                                        }}
                                    >
                                        <Col className="tournament-match-card-col-wrapper">
                                            <TournamentClubMatchCard
                                                tournament={tournament}
                                                matchDetails={{
                                                    round_number:
                                                        +roundIndex + 1,
                                                    pair_number:
                                                        +cardiIndex + 1,
                                                    tournament_uuid:
                                                        tournament?.attributes
                                                            ?.uuid,
                                                    tournament_type_name:
                                                        tournament
                                                            ?.tournament_type
                                                            ?.tournament_type_name,
                                                    ...drawToShow,
                                                }}
                                                createNextMatch={
                                                    handleRoundName(
                                                        handleRoundsNumber(
                                                            drawSize
                                                        ) + 1,
                                                        roundIndex + 1
                                                    ) === 'Finale'
                                                        ? false
                                                        : true
                                                }
                                                onChange={handleDrawToShow}
                                            />
                                            {cardiIndex % 2 === 0 &&
                                                handleRoundName(
                                                    handleRoundsNumber(
                                                        drawSize
                                                    ) + 1,
                                                    roundIndex + 1
                                                ) !== 'Finale' && (
                                                    <div
                                                        className="match-draw-line"
                                                        style={{
                                                            height: `${
                                                                Math.pow(
                                                                    2,
                                                                    roundIndex
                                                                ) *
                                                                singleCardHeight
                                                            }px`,
                                                            top: `${
                                                                singleCardHeight /
                                                                2
                                                            }px`,
                                                        }}
                                                    ></div>
                                                )}

                                            {roundIndex !== 0 && (
                                                <div
                                                    className="match-draw-back-line"
                                                    style={{
                                                        top: `${
                                                            singleCardHeight / 2
                                                        }px`,
                                                    }}
                                                ></div>
                                            )}
                                        </Col>
                                    </Row>
                                ))}
                            </TournamentRoundCard>
                        )
                    )}
                </Slider>
            )
        } else {
            return (
                <TeamRoundRobin
                    tournament={tournament}
                    drawToShow={drawToShow}
                    numberOfGroups={numberOfGroups}
                />
            )
        }
    }

    const handleDrawToShow = () => {
        const slider = handleSldierToShow()
        setSliderToShow(slider)
    }

    useEffect(() => {
        handleDrawToShow()
    }, [tournament, drawToShow, numberOfGroups, mainSliderToShow])

    return sliderToShow
}

export default TeamDraw
