import React, { useEffect, useState } from 'react'
import { Breadcrumb, Col, Row, Tab, Tabs } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'

import Grid from '../../../../assets/svg/Grid'
import People from '../../../../assets/svg/People'
import Person from '../../../../assets/svg/Person'
import Trash from '../../../../assets/svg/Trash'
import Button from '../../../../components/Button'
import ConfirmationModal from '../../../../components/ConfirmationModal'
import Organisation from './Tabs/Organisation'
import Profile from './Tabs/Profile'
import Results from './Tabs/Results'

import jsPDF from 'jspdf'
import 'jspdf-autotable'
import Change from '../../../../assets/svg/Change'
import DocumentTextOutlined from '../../../../assets/svg/DocumentTextOutlined'
import PrintOutlined from '../../../../assets/svg/PrintOutlined'
import ProfileCard from '../../../../components/ProfileCard'
import { RowsPerPageOptions } from '../../../../components/RowsPerPageDropdown'
import { font } from '../../../../containers/Admin/Competitions/CompetitionProfile/font'
import {
    deleteClub,
    getClubsCoaches,
    getFirstMembership,
    getSingleClub,
    getTournamentReport,
    getTournaments,
} from '../../../../lib/api'
import { useAppContext } from '../../../../lib/contextLib'
import {
    checkClubMemberships,
    formatDate,
    formatImgUrl,
} from '../../../../lib/helpers'
import { generateAvatar } from '../../../../utils/generateAvatar'
import Membership from '../../../Competitor/Membership'
import './styles.scss'

const clubTabs = [
    { eventKey: 'profile', icon: <Person width="20px" />, text: 'Profil' },
    {
        eventKey: 'organisation',
        icon: <People width="20px" />,
        text: 'Organizacija takmičenja',
    },
    {
        eventKey: 'results',
        icon: <Grid width="20px" />,
        text: 'Rezultati kluba',
    },
]

const SingleClub = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [club, setClub] = useState({})
    const [activeTab, setActiveTab] = useState('profile')

    const [resultsToView, setResultsToView] = useState({})
    const [showMembership, setShowMembership] = useState(false)
    const { user, setShowCustomToast } = useAppContext()
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [clubStatus, setClubStatus] = useState('Neaktivan')

    //* Pagination

    const [currentPage, setCurrentPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [total, setTotal] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(RowsPerPageOptions[0])

    const [comingCompetitions, setComingCompetitions] = useState([])
    const [finishedCompetitions, setFinishedCompetitions] = useState([])
    const [organizationActiveTab, setOrganizationActiveTab] =
        useState('nadolazeca')
    const [searchValue, setSearchValue] = useState('')

    // Handle paths in breadcumbs when this component is used in diff flows
    const pathHandler = () => {
        switch (user?.role?.type) {
            case 'admin':
                return '/klubovi'
            case 'competitor':
                return '/takmicar/pocetna'
            case 'referee':
                if (user?.data?.attributes?.is_administrator) {
                    return '/administrator-sudjenja/pocetna'
                } else {
                    return '/sudije/pocetna'
                }
        }
    }

    const backToCompetitionsHandler = () => {
        switch (user?.role?.type) {
            case 'admin':
                return '/takmicenja'
            case 'competitor':
                return '/takmicar/takmicenja'
            case 'referee':
                if (user?.data?.attributes?.is_administrator) {
                    return '/administrator-sudjenja/takmicenja-iz-kalendara-TSS'
                } else {
                    return '/sudije/takmicenja-iz-kalendara-TSS'
                }
        }
    }

    const backToClubsHandler = () => {
        switch (user?.role?.type) {
            case 'admin':
                return '/klubovi'
            default:
                return '#'
        }
    }

    const statusHandler = (status) => {
        switch (status) {
            case true:
                return 'Verifikovano'
            case false:
                return 'Čeka verifikaciju'
            default:
                return 'Priprema'
        }
    }

    const hasTournamentReport = async (tournamentUuid) => {
        try {
            let tournamentReportResponse = await getTournamentReport(
                tournamentUuid
            )

            if (
                tournamentReportResponse &&
                tournamentReportResponse?.length > 0
            ) {
                return tournamentReportResponse[0]
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getComingCompetitions = async (page, presetFilters) => {
        let {
            tournaments,
            pgCount,
            total: totalCount,
        } = await getTournaments(
            null,
            {
                name: searchValue,
            },
            presetFilters
                ? presetFilters === 1
                    ? null
                    : presetFilters
                : filterParams,
            page,
            false,
            false,
            rowsPerPage
        )

        if (tournaments) {
            const tournamentsPromises = tournaments.map(async (tournament) => {
                const club_organizer = await getSingleClub(
                    tournament.attributes.club_organizer_uuid
                )
                return {
                    ...tournament,
                    attributes: {
                        ...tournament.attributes,
                        club_organizer,
                    },
                }
            })

            const updatedTournaments = await Promise.all(tournamentsPromises)
            setComingCompetitions(updatedTournaments)
            setPageCount(pgCount)
            setTotal(totalCount)
            setCurrentPage(page)
        }
    }

    const getAllFinishedCompetitions = async (page, presetFilters) => {
        let {
            tournaments,
            pgCount,
            total: totalCount,
        } = await getTournaments(
            null,
            {
                name: searchValue,
            },
            presetFilters
                ? presetFilters === 1
                    ? null
                    : presetFilters
                : filterParams,
            page,
            true,
            false,
            rowsPerPage
        )

        if (tournaments) {
            const tournamentsPromises = tournaments.map(async (tournament) => {
                const club_organizer = await getSingleClub(
                    tournament.attributes.club_organizer_uuid
                )
                const hasReport = await hasTournamentReport(
                    tournament?.attributes?.uuid
                )
                return {
                    ...tournament,
                    attributes: {
                        ...tournament.attributes,
                        club_organizer,
                        hasReport: hasReport,
                    },
                }
            })

            const updatedTournaments = await Promise.all(tournamentsPromises)

            setFinishedCompetitions(updatedTournaments)
            setPageCount(pgCount)
            setTotal(totalCount)
            setCurrentPage(page)
        }
    }

    const handleTabState = () => {
        switch (activeTab) {
            case 'profile':
                return (
                    <Profile
                        club={club}
                        setShowMembership={setShowMembership}
                        getClub={getClub}
                    />
                )
            case 'organisation':
                return (
                    <Organisation
                        club={club}
                        pageCount={pageCount}
                        total={total}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        comingCompetitions={comingCompetitions}
                        finishedCompetitions={finishedCompetitions}
                        getComingCompetitions={getComingCompetitions}
                        getAllFinishedCompetitions={getAllFinishedCompetitions}
                        setOrganizationActiveTab={setOrganizationActiveTab}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                )
            case 'results':
                return (
                    <>
                        <Results club={club} className="d-md-block d-none" />
                    </>
                )
            default:
                return <Profile club={club} setActiveTab={setActiveTab} />
        }
    }

    const getClub = async (id) => {
        const [singleClub, { total: numberOfCoaches }] = await Promise.all([
            getSingleClub(id),
            getClubsCoaches(id),
        ])

        singleClub.attributes.number_of_coaches = numberOfCoaches
        setClub(singleClub)
    }

    const handleFirstMembership = async () => {
        const firstClubMembership = await getFirstMembership(id)

        if (firstClubMembership) {
            return firstClubMembership[0]
        }
    }

    useEffect(() => {
        getClub(id)
        handleClubStatus(id)
    }, [id, showMembership])

    const handleDelete = async () => {
        try {
            let data = await deleteClub(id)
            if (data) {
                navigate(pathHandler())
                setShowCustomToast({
                    show: true,
                    type: 'success',
                    message: 'Klub je obrisan.',
                })
            }
        } catch (error) {
            setShowCustomToast({
                show: true,
                type: 'error',
                message: 'Došlo je do greške.',
            })
        }
    }

    // Handle show modal
    const handleShowDeleteModal = () => {
        setShowDeleteModal(!showDeleteModal)
    }

    // Handle edit club
    const handleEdit = (e) => {
        navigate(`/klubovi/${id}/izmeni-podatke-kluba`)
    }

    const handlePdfExport = async (print = false) => {
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'portrait' // portrait or landscape

        const marginLeft = 40
        const yCoordinate = 0
        const doc = new jsPDF(orientation, unit, size)
        doc.addFileToVFS('WorkSans-normal.ttf', font)
        doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
        doc.setFont('WorkSans')
        doc.setFontSize(15)

        const profileTitle = `${club?.attributes?.name} - Profil - ${formatDate(
            new Date()
        )}`
        doc.text(profileTitle, marginLeft, 40)

        let firstMembership = await handleFirstMembership()

        // const statusTitle = `Status: ${club?.attributes?.status}`
        // doc.text(statusTitle, marginLeft, 60)

        const clubDetails = [
            [
                'Datum osnivanja',
                club?.attributes?.establishment_date
                    ? formatDate(club?.attributes?.establishment_date)
                    : 'Nema informacije',
            ],
            [
                'Datum prvog učlanjenja',
                firstMembership
                    ? formatDate(firstMembership?.attributes?.date_of_payment)
                    : 'Nema informacije',
            ],
            [
                'Region',
                club?.attributes?.region?.region_name
                    ? club?.attributes?.region?.region_name
                    : 'Nema informacije',
            ],
            [
                'Adresa',
                club?.attributes?.address
                    ? `${club?.attributes?.address?.street}${
                          club?.attributes?.address?.postal_code
                              ? ', ' + club?.attributes?.address?.postal_code
                              : ''
                      }${
                          club?.attributes?.address?.city
                              ? club?.attributes?.address?.postal_code
                                  ? ' ' + club?.attributes?.address?.city
                                  : ', ' + club?.attributes?.address?.city
                              : ''
                      }${
                          club?.attributes?.address?.country
                              ? ', ' + club?.attributes?.address?.country
                              : ''
                      }`
                    : 'Nema informacije',
            ],
            [
                'Telefon kluba',
                club?.attributes?.phone
                    ? club?.attributes?.phone
                    : 'Nema informacije',
            ],
            [
                'Email kluba',
                club?.attributes?.email
                    ? club?.attributes?.email
                    : 'Nema informacije',
            ],
            [
                'Website',
                club?.attributes?.website_url
                    ? club?.attributes?.website_url
                    : 'Nema informacije',
            ],
            [
                'Broj trenera',
                club?.attributes?.number_of_coaches
                    ? club?.attributes?.number_of_coaches
                    : 'Nema informacije',
            ],
        ]

        let detailsInfo = {
            startY: yCoordinate + 50,
            head: [['Detalji', ' ']],
            body: clubDetails,
            styles: {
                font: 'WorkSans',
            },
            columnStyles: {
                0: { cellWidth: 250 },
            },
        }

        doc.autoTable(detailsInfo)

        const staffDetails = club?.attributes?.staff.map((staffMember) => [
            `${staffMember?.title ? staffMember?.title : 'Nema informacije'}`,
            staffMember.given_name
                ? `${staffMember?.given_name} ${
                      staffMember?.family_name ? staffMember?.family_name : ''
                  }`
                : 'Nema informacije',
            `${staffMember?.email ? staffMember?.email : 'Nema informacije'}`,
            `${staffMember?.phone ? staffMember?.phone : 'Nema informacije'}`,
        ])

        let staffInfo = {
            startY: doc.lastAutoTable.finalY + 30,
            head: [['Član kluba', 'Ime i prezime', 'Email', 'Telefon']],
            body: staffDetails?.length > 0 ? staffDetails : [],
            styles: {
                font: 'WorkSans',
            },
            columnStyles: {
                0: { cellWidth: 130 },
                1: { cellWidth: 130 },
                2: { cellWidth: 130 },
            },
        }

        doc.autoTable(staffInfo)

        if (staffDetails?.length <= 0) {
            let noStaffInfo = {
                startY: doc.lastAutoTable.finalY,
                body: [['Nema informacije']],
                styles: {
                    font: 'WorkSans',
                    halign: 'center',
                },
            }

            doc.autoTable(noStaffInfo)
        }

        const openCourtsInfo = [
            [
                'Šljaka',
                club?.attributes?.courts?.number_of_clay_courts
                    ? club?.attributes?.courts?.number_of_clay_courts
                    : '0',
            ],
            [
                'Hard',
                club?.attributes?.courts?.number_of_hard_courts
                    ? club?.attributes?.courts?.number_of_hard_courts
                    : '0',
            ],
            [
                'Tepih',
                club?.attributes?.courts?.number_of_carpet_courts
                    ? club?.attributes?.courts?.number_of_carpet_courts
                    : '0',
            ],
            [
                'Zatvoreni',
                club?.attributes?.courts?.number_of_indoor_courts
                    ? club?.attributes?.courts?.number_of_indoor_courts
                    : '0',
            ],
            [
                'Pod reflektorima',
                club?.attributes?.courts?.number_of_reflector_courts
                    ? club?.attributes?.courts?.number_of_reflector_courts
                    : '0',
            ],
        ]

        let openCourtsDetails = {
            startY: doc.lastAutoTable.finalY + 20,
            head: [['Tereni', ' ']],
            body: openCourtsInfo,
            styles: {
                font: 'WorkSans',
            },
            columnStyles: {
                0: { cellWidth: 250 },
            },
        }

        doc.autoTable(openCourtsDetails)

        if (print) {
            doc.autoPrint()

            var blob = doc.output('blob')
            window.open(URL.createObjectURL(blob), '_blank')
        } else {
            doc.save(
                `${club?.attributes?.name}-${formatDate(new Date()).slice(
                    0,
                    -1
                )}.pdf`
            )
        }
    }

    const handleTournamentsPdf = async (print = false) => {
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'portrait' // portrait or landscape

        const marginLeft = 40
        const doc = new jsPDF(orientation, unit, size)
        doc.addFileToVFS('WorkSans-normal.ttf', font)
        doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
        doc.setFont('WorkSans')
        doc.setFontSize(15)

        const countHelper = () => {
            return `${(currentPage - 1) * rowsPerPage + 1}-${
                rowsPerPage * currentPage
            }`
        }

        const tournamentActiveTabHandler = (tab) => {
            switch (tab) {
                case 'nadolazeca':
                    return 'Nadolazeća takmičenja'
                case 'zavrsena':
                    return 'Završena takmičenja'
                default:
                    return ''
            }
        }

        const title = `${club?.attributes?.name} - ${tournamentActiveTabHandler(
            organizationActiveTab
        )} - ${countHelper()}`

        doc.text(title, marginLeft, 40)

        if (organizationActiveTab === 'nadolazeca') {
            if (comingCompetitions && comingCompetitions?.length > 0) {
                comingCompetitions?.map((tournament, index) => {
                    let tournamentInfo = [
                        [
                            'Naziv',
                            tournament?.attributes?.name
                                ? tournament?.attributes?.name
                                : 'Nema informacije',
                        ],
                        [
                            'Organizator',
                            tournament?.attributes?.club_organizer?.attributes
                                ?.name
                                ? tournament?.attributes?.club_organizer
                                      ?.attributes?.name
                                : 'Nema informacije',
                        ],
                        [
                            'Početak',
                            tournament?.attributes?.start_date
                                ? formatDate(tournament?.attributes?.start_date)
                                : 'Nema informacije',
                        ],
                        [
                            'Prijava do',
                            tournament?.attributes?.application_deadline_date
                                ? formatDate(
                                      tournament?.attributes
                                          ?.application_deadline_date
                                  )
                                : 'Nema informacije',
                        ],
                        [
                            'Odjava do',
                            tournament?.attributes?.checkout_deadline_date
                                ? formatDate(
                                      tournament?.attributes
                                          ?.checkout_deadline_date
                                  )
                                : 'Nema informacije',
                        ],
                        [
                            'Kategorija',
                            tournament?.attributes?.tournament_category
                                ?.tournament_category_name
                                ? tournament?.attributes?.tournament_category
                                      ?.tournament_category_name
                                : 'Nema informacije',
                        ],
                    ]

                    let tournamentContent = {
                        startY:
                            index === 0 ? 50 : doc.lastAutoTable.finalY + 20,
                        head: [[`${index + 1}`, ' ']],
                        body: tournamentInfo,
                        styles: {
                            font: 'WorkSans',
                        },
                        columnStyles: {
                            0: { cellWidth: 250 },
                        },
                    }

                    doc.autoTable(tournamentContent)
                })
            } else {
                let noComingCompetitionSeparator = {
                    startY: 50,
                    head: [['Nadolazeća takmičenja']],
                    body: [],
                    styles: {
                        font: 'WorkSans',
                        halign: 'center',
                    },
                    columnStyles: {
                        0: { cellWidth: 250 },
                    },
                }

                doc.autoTable(noComingCompetitionSeparator)

                let tournamentContent = {
                    startY: doc.lastAutoTable.finalY + 2,
                    body: [['Nema nadolazećih takmičenja']],
                    styles: {
                        font: 'WorkSans',
                        halign: 'center',
                    },
                }

                doc.autoTable(tournamentContent)
            }
        }

        if (organizationActiveTab === 'zavrsena') {
            if (finishedCompetitions && finishedCompetitions?.length > 0) {
                finishedCompetitions?.map((tournament, index) => {
                    let tournamentInfo = [
                        [
                            'Naziv',
                            tournament?.attributes?.name
                                ? tournament?.attributes?.name
                                : 'Nema informacije',
                        ],
                        [
                            'Organizator',
                            tournament?.attributes?.club_organizer?.attributes
                                ?.name
                                ? tournament?.attributes?.club_organizer
                                      ?.attributes?.name
                                : 'Nema informacije',
                        ],
                        [
                            'Početak',
                            tournament?.attributes?.start_date
                                ? formatDate(tournament?.attributes?.start_date)
                                : 'Nema informacije',
                        ],
                        [
                            'Prijava do',
                            tournament?.attributes?.application_deadline_date
                                ? formatDate(
                                      tournament?.attributes
                                          ?.application_deadline_date
                                  )
                                : 'Nema informacije',
                        ],
                        [
                            'Odjava do',
                            tournament?.attributes?.checkout_deadline_date
                                ? formatDate(
                                      tournament?.attributes
                                          ?.checkout_deadline_date
                                  )
                                : 'Nema informacije',
                        ],
                        [
                            'Kategorija',
                            tournament?.attributes?.tournament_category
                                ?.tournament_category_name
                                ? tournament?.attributes?.tournament_category
                                      ?.tournament_category_name
                                : 'Nema informacije',
                        ],
                        [
                            'Status',
                            statusHandler(
                                tournament?.attributes?.hasReport?.attributes
                                    ?.is_verified
                            ),
                        ],
                    ]

                    let tournamentContent = {
                        startY:
                            index === 0 ? 50 : doc.lastAutoTable.finalY + 20,
                        head: [[`${index + 1}`, ' ']],
                        body: tournamentInfo,
                        styles: {
                            font: 'WorkSans',
                        },
                        columnStyles: {
                            0: { cellWidth: 250 },
                        },
                    }

                    doc.autoTable(tournamentContent)
                })
            } else {
                let noFinishedCompetitionSeparator = {
                    startY: 50,
                    head: [['Završena takmičenja']],
                    body: [],
                    styles: {
                        font: 'WorkSans',
                        halign: 'center',
                    },
                    columnStyles: {
                        0: { cellWidth: 250 },
                    },
                }

                doc.autoTable(noFinishedCompetitionSeparator)

                let tournamentContent = {
                    startY: doc.lastAutoTable.finalY + 2,
                    body: [['Nema završenih takmičenja']],
                    styles: {
                        font: 'WorkSans',
                        halign: 'center',
                    },
                }

                doc.autoTable(tournamentContent)
            }
        }

        if (print) {
            doc.autoPrint()

            var blob = doc.output('blob')
            window.open(URL.createObjectURL(blob), '_blank')
        } else {
            doc.save(
                `${club?.attributes?.name}-${formatDate(new Date()).slice(
                    0,
                    -1
                )}.pdf`
            )
        }
    }

    const handleClubStatus = async (clubUuid) => {
        let clubStatusResponse = await checkClubMemberships(clubUuid)

        if (clubStatusResponse) {
            setClubStatus(clubStatusResponse)
        }
    }

    const handlePdfSwitch = async (tab, print = false) => {
        switch (tab) {
            case 'profile':
                return handlePdfExport(print)
            case 'organisation':
                return await handleTournamentsPdf(print)
            default:
                return handlePdfExport(print)
        }
    }

    return (
        <>
            {!showMembership ? (
                <Col className="single-club">
                    <Col>
                        <Row className="pt-3">
                            <Col
                                xs={12}
                                md={5}
                                className="d-flex align-items-center justify-content-between"
                            >
                                <Row>
                                    <Col>
                                        <h2 className="d-none d-md-block">
                                            Klubovi
                                        </h2>
                                        <p className="d-block d-md-none">
                                            Klubovi
                                        </p>
                                        <Breadcrumb className="mt-md-2 mt-1">
                                            <Breadcrumb.Item
                                                linkAs={Link}
                                                linkProps={{
                                                    to: backToCompetitionsHandler(),
                                                }}
                                            >
                                                Takmičenja
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item
                                                linkAs={Link}
                                                linkProps={{
                                                    to: backToClubsHandler(),
                                                }}
                                                active={
                                                    user?.role?.type === 'admin'
                                                        ? false
                                                        : true
                                                }
                                            >
                                                Klubovi
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item
                                                active
                                                className="d-block d-md-none"
                                            >
                                                {club?.attributes?.short_name}
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item
                                                active
                                                className="d-none d-md-block"
                                            >
                                                {club?.attributes?.name}
                                            </Breadcrumb.Item>
                                        </Breadcrumb>
                                    </Col>
                                </Row>

                                <div className="d-flex align-items-center d-md-none">
                                    <Button
                                        type="text"
                                        // onClick={() => setAreFiltersActive(true)}
                                    >
                                        <Change
                                            width="24px"
                                            height="24px"
                                            onClick={handleEdit}
                                        />
                                    </Button>
                                    <Button
                                        type="text"
                                        // onClick={() => setAreFiltersActive(true)}
                                        onClick={() =>
                                            handlePdfSwitch(activeTab, true)
                                        }
                                    >
                                        <PrintOutlined
                                            width="24px"
                                            height="24px"
                                        />
                                    </Button>

                                    <Button type="text" onClick={handleDelete}>
                                        <Trash />
                                    </Button>
                                </div>
                            </Col>
                            <Col xs={12} md={7} className="d-none d-md-block">
                                <Row className="d-flex align-items-center">
                                    <div
                                        className="d-flex justify-content-end w-100 mt-2 mt-md-0"
                                        style={{ gap: '8px' }}
                                    >
                                        <Button
                                            type="text"
                                            leadingIcon={
                                                <DocumentTextOutlined height="24px" />
                                            }
                                            onClick={() =>
                                                handlePdfSwitch(
                                                    activeTab,
                                                    false
                                                )
                                            }
                                        >
                                            Eksportuj u PDF
                                        </Button>
                                        <Button
                                            leadingIcon={<PrintOutlined />}
                                            type="secondary"
                                            onClick={() =>
                                                handlePdfSwitch(activeTab, true)
                                            }
                                        >
                                            Štampaj
                                        </Button>
                                        {user?.role?.type === 'admin' && (
                                            <>
                                                <Button
                                                    leadingIcon={
                                                        <Change height="24px" />
                                                    }
                                                    type="secondary"
                                                    onClick={handleEdit}
                                                >
                                                    Izmeni
                                                </Button>
                                                <Button
                                                    leadingIcon={<Trash />}
                                                    type="secondary"
                                                    onClick={
                                                        handleShowDeleteModal
                                                    }
                                                >
                                                    Obriši
                                                </Button>
                                            </>
                                        )}
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="m-0 card-profile">
                            <ProfileCard
                                name={club?.attributes?.name}
                                email={club?.attributes?.email}
                                avatar={
                                    club?.attributes?.logo?.data
                                        ? formatImgUrl(
                                              club?.attributes?.logo?.data
                                                  ?.attributes?.url
                                          )
                                        : generateAvatar(
                                              club?.attributes?.name
                                                  ? club?.attributes?.name
                                                  : ''
                                          )
                                }
                                // Hardcoded za sada, promeniti ovo kada se sredi kolekcija da ima i status kluba
                                pillContent={clubStatus}
                            />
                            <Col
                                md={12}
                                className="pt-0 pt-md-4 d-flex align-items-center justify-content-start justify-content-md-end"
                            >
                                <Tabs
                                    id="club-selection"
                                    defaultActiveKey={activeTab}
                                    activeKey={activeTab}
                                    onSelect={(k) => setActiveTab(k)}
                                >
                                    {clubTabs.map(
                                        ({ eventKey, icon, text }) => (
                                            <Tab
                                                key={eventKey}
                                                eventKey={eventKey}
                                                title={
                                                    <>
                                                        {icon}
                                                        <p className="ms-2">
                                                            {text}
                                                        </p>
                                                    </>
                                                }
                                                tabClassName="d-flex"
                                            ></Tab>
                                        )
                                    )}
                                </Tabs>
                            </Col>
                        </Row>
                        {handleTabState()}
                    </Col>
                </Col>
            ) : (
                <Membership
                    clubId={club?.attributes?.uuid}
                    setShowMembership={setShowMembership}
                    club={club}
                />
            )}
            {/* Delete club modal */}
            <ConfirmationModal
                modalText="Jeste li sigurni da želite obrisati klub?"
                onHide={() => setShowDeleteModal(false)}
                show={showDeleteModal}
                onClick={handleDelete}
            />
        </>
    )
}

export default SingleClub
