/* eslint-disable array-callback-return */
import { useEffect, useState } from 'react'
import { Card, Col, Row, Spinner } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import logoTss from '../../../assets/images/logo-longer.png'
import Button from '../../../components/Button'
import TextField from '../../../components/TextField'
import { getSinglePlayer, registerUser, updatePlayer } from '../../../lib/api'
import { useAppContext } from '../../../lib/contextLib'
import { formatDate } from '../../../lib/helpers'

const MigrationRegisterPage = () => {
    const navigate = useNavigate()
    const { setShowCustomToast } = useAppContext()
    const { id } = useParams()
    const [playerData, setPlayerData] = useState()
    const [newEmail, setNewEmail] = useState('')
    const [invitationSending, setInvitationSending] = useState(false)

    const handleGetSinglePlayer = async () => {
        const singlePlayerData = await getSinglePlayer(id)

        if (singlePlayerData) {
            setPlayerData(singlePlayerData)
        }
    }

    const validateEmail = (email) => {
        var re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase())
    }

    const handlePlayerInvitationSubmit = async () => {
        if (!newEmail) return

        setInvitationSending(true)
        const auth = {
            email: newEmail,
            password: 'Password1!',
            role: 'competitor',
        }

        const user_info = {
            person_info: {
                given_name: playerData?.attributes?.person_info?.given_name,
                family_name: playerData?.attributes?.person_info?.family_name,
                email: playerData?.attributes?.person_info?.email,
            },
            status: {
                status_name: 'draft',
            },
            legacy_id: playerData?.attributes?.legacy_id,
        }

        try {
            let data = await registerUser(auth, user_info, null)

            if (data) {
                const updatedPlayer = await updatePlayer(id, {
                    person_info: {
                        ...playerData?.attributes?.person_info,
                        email: newEmail,
                    },
                    status: {
                        status_name: 'draft',
                    },
                })

                if (updatedPlayer) {
                    setShowCustomToast({
                        show: true,
                        type: 'success',
                        message: 'Takmičar uspešno pozvan.',
                    })
                } else {
                    setShowCustomToast({
                        show: true,
                        type: 'error',
                        message: 'Došlo je do greške.',
                    })
                }
                setInvitationSending(false)
            }
        } catch (error) {
            switch (error.response.data.error.message) {
                case 'Email or Username are already taken':
                    setShowCustomToast({
                        show: true,
                        type: 'error',
                        message: 'Takmičar sa unetim emailom već postoji.',
                    })
                    setInvitationSending(false)
                    break
                case 'Register action is currently disabled':
                    setShowCustomToast({
                        show: true,
                        type: 'error',
                        message: 'Registracija trenutno nije moguća.',
                    })
                    setInvitationSending(false)
                    break

                default:
                    setShowCustomToast({
                        show: true,
                        type: 'error',
                        message: 'Došlo je do greške.',
                    })
                    setInvitationSending(false)
                    break
            }
        }

        setInvitationSending(false)
        navigate('/')
    }

    useEffect(() => {
        handleGetSinglePlayer()
    }, [])

    return (
        <Row className="d-flex justify-content-center mt-5 forgot-password-wrapper">
            <Col xs={12} md={6} className="mt-5">
                <Row>
                    <Col
                        md={12}
                        className="d-flex align-items-center justify-content-center"
                    >
                        <Link to={'/'}>
                            <img
                                src={logoTss}
                                alt="logo-tss"
                                className="tss-logo"
                            />
                        </Link>
                    </Col>
                    <Col className="mt-5">
                        <Card>
                            <Card.Body>
                                <h2 className="mb-3">Registracija</h2>
                                <p>
                                    Stari ID takmičara:{' '}
                                    {playerData?.attributes?.legacy_id}
                                </p>
                                <p>
                                    Ime i prezime:{' '}
                                    {`${playerData?.attributes?.person_info?.given_name} ${playerData?.attributes?.person_info?.family_name}`}
                                </p>
                                <p>
                                    Pol:{' '}
                                    {
                                        playerData?.attributes?.gender
                                            ?.gender_name
                                    }
                                </p>
                                <p>
                                    Datum rodenja:{' '}
                                    {formatDate(
                                        playerData?.attributes?.person_info
                                            ?.birthdate
                                    )}
                                </p>

                                <Row className="mt-4">
                                    <Col xs={12}>
                                        <TextField
                                            required
                                            type="email"
                                            label="Novi email"
                                            placeholder="Unesite Vaš novi email"
                                            onChange={(e) =>
                                                setNewEmail(e.target.value)
                                            }
                                            value={newEmail}
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Row>
                                            <Col className="d-flex justify-content-end">
                                                <Button
                                                    className="my-3"
                                                    onClick={
                                                        handlePlayerInvitationSubmit
                                                    }
                                                    disabled={
                                                        validateEmail(newEmail)
                                                            ? false
                                                            : true
                                                    }
                                                    leadingIcon={
                                                        invitationSending ? (
                                                            <Spinner
                                                                animation="border"
                                                                variant="primary"
                                                                size="sm"
                                                                className="invitation-spinner"
                                                            />
                                                        ) : null
                                                    }
                                                >
                                                    Potvrdi
                                                </Button>
                                            </Col>
                                        </Row>

                                        <p className="small-text text-center">
                                            Nakon uspešnog slanja mejla, bićete
                                            odvedeni na login stranu gde možete
                                            da se ulogujete na vaš nalog uz
                                            pomoć lozinke dobijene u mejlu.
                                        </p>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col
                        md={12}
                        className="logo-below mt-5 pt-5 d-flex align-items-center justify-content-center"
                    >
                        <p className="chainapp-text">
                            Razvija:{' '}
                            <a
                                href="https://chainapp.tech/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Chainapp Technologies doo
                            </a>
                        </p>{' '}
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default MigrationRegisterPage
