import React from 'react'
import { Col, Pagination } from 'react-bootstrap'
import RowsPerPageDropdown from '../RowsPerPageDropdown'

function PaginationWrapper({
    setCurrentPage,
    currentPage,
    pageCount,
    total,
    rowsPerPage,
    setRowsPerPage,
}) {
    const handleClick = (page) => {
        setCurrentPage(page)
    }
    return (
        <div className="d-flex align-items-center justify-content-start">
            <Col className="p-3 d-flex align-items-center justify-content-between pagination-wrapper">
                <h5 className="ms-3">Ukupno: {total}</h5>
                <div className="d-flex align-items-center">
                    <h5 className="me-3">Redova po stranici:</h5>
                    <RowsPerPageDropdown
                        className="me-3"
                        rowsPerPage={rowsPerPage}
                        onSelect={(e) => setRowsPerPage(e)}
                    />
                    <h5
                        className="me-3"
                        style={{ minWidth: '40px', textAlign: 'end' }}
                    >
                        {total === 0
                            ? total
                            : (currentPage - 1) * rowsPerPage + 1}
                        -
                        {currentPage >= pageCount
                            ? total
                            : currentPage * rowsPerPage}
                    </h5>
                    <Pagination size="sm" className="me-3">
                        <Pagination.Prev
                            disabled={currentPage === 1}
                            onClick={() => handleClick((prev) => prev - 1)}
                        />
                        <Pagination.Next
                            disabled={currentPage >= pageCount}
                            onClick={() => handleClick((prev) => prev + 1)}
                        />
                    </Pagination>
                </div>
            </Col>
        </div>
    )
}

export default PaginationWrapper
