import React from 'react'
import { Modal } from 'react-bootstrap'
import Button from '../../../../../../components/Button'

const ModalAfterPointsSave = ({ show, onHide }) => {
    return (
        <Modal
            centered
            show={show}
            onHide={onHide}
            dialogClassName="add-penalties-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>Obaveštenje</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Bodovi za prikazane igrače su uspešno automatski ažurirani.
                    U slučaju dodatnih izmena, moguća je ponovna automatska ili
                    manuelna izmena.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button type="text" onClick={onHide}>
                    Zatvori
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalAfterPointsSave
