import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { getTournamentReport, isRefereeTournamentAdmin } from '../../lib/api'
import { useAppContext } from '../../lib/contextLib'
import { formatDate, formatImgUrl } from '../../lib/helpers'
import { generateAvatar } from '../../utils/generateAvatar'
import Pill from '../Pill'

function SingleTournamentRow({ result, finished, isWhite, mobile = false }) {
    const { user } = useAppContext()
    const [hasReport, setHasReport] = useState(false)
    const [isRefereeAdmin, setIsRefereeAdmin] = useState(false)

    const pillHandler = (status) => {
        switch (status) {
            case true:
                return (
                    <Pill type="active">
                        <p>Verifikovano</p>
                    </Pill>
                )
            case false:
                return (
                    <Pill type="punished">
                        <p>Čeka verifikaciju</p>
                    </Pill>
                )
            default:
                return (
                    <Pill type="inactive">
                        <p>Priprema</p>
                    </Pill>
                )
        }
    }

    const hasTournamentReport = async (tournamentUuid) => {
        try {
            let tournamentReportResponse = await getTournamentReport(
                tournamentUuid
            )

            if (
                tournamentReportResponse &&
                tournamentReportResponse?.length > 0
            ) {
                setHasReport(tournamentReportResponse[0])
            } else {
                setHasReport(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const checkIsRefereeTournamentAdmin = async (tournamentUuid) => {
        const isAdmin = await isRefereeTournamentAdmin(
            user?.data?.attributes?.uuid,
            tournamentUuid
        )

        if (isAdmin?.length > 0) {
            setIsRefereeAdmin(true)
        }
    }

    const setLocalStorageActiveTab = (activeTab) => {
        localStorage.setItem('competitionActiveTab', activeTab)
    }

    useEffect(() => {
        hasTournamentReport(result?.attributes?.uuid)
        checkIsRefereeTournamentAdmin(result?.attributes?.uuid)
    }, [result])

    return (
        <>
            {!mobile ? (
                <tr key={result.id} className="setting-table-row">
                    <td className="align-middle" style={{ minWidth: '140px' }}>
                        <Link to={`${result?.attributes?.uuid}`}>
                            <p className="link">{result.attributes.name}</p>
                        </Link>
                    </td>
                    <td
                        className="align-middle d-flex align-items-center"
                        style={{ minWidth: '140px' }}
                    >
                        {result?.attributes?.club_organizer?.attributes
                            ?.name ? (
                            <>
                                <img
                                    src={
                                        result?.attributes?.club_organizer
                                            ?.attributes?.logo?.data
                                            ? formatImgUrl(
                                                  result?.attributes
                                                      ?.club_organizer
                                                      ?.attributes?.logo?.data
                                                      ?.attributes?.url
                                              )
                                            : generateAvatar(
                                                  result?.attributes
                                                      ?.club_organizer
                                                      ?.attributes?.name
                                              )
                                    }
                                    alt={`Logo kluba`}
                                    width={'24px'}
                                    height={'24px'}
                                    style={{
                                        maxHeight: '24px',
                                        maxWidth: '24px',
                                        borderRadius: '50%',
                                    }}
                                />
                                <Link
                                    to={`/sudije/klubovi/${result?.attributes?.club_organizer?.attributes?.uuid}`}
                                >
                                    <p className="ms-2 link">
                                        {
                                            result?.attributes?.club_organizer
                                                ?.attributes?.name
                                        }
                                    </p>
                                </Link>
                            </>
                        ) : (
                            <p>-</p>
                        )}
                    </td>
                    <td className="align-middle" style={{ minWidth: '140px' }}>
                        <p>
                            {result?.attributes?.checkout_deadline_date
                                ? formatDate(
                                      result?.attributes?.checkout_deadline_date
                                  )
                                : '-'}
                        </p>
                    </td>
                    <td className="align-middle" style={{ minWidth: '140px' }}>
                        <p>
                            {result?.attributes?.participants_expected_number
                                ? result?.attributes
                                      ?.participants_expected_number
                                : '-'}
                        </p>
                    </td>
                    <td className="align-middle" style={{ minWidth: '103px' }}>
                        <p>
                            {result?.attributes?.tournament_category
                                ?.tournament_category_name
                                ? result?.attributes?.tournament_category
                                      ?.tournament_category_name
                                : '-'}
                        </p>
                    </td>
                    {!finished ? (
                        isRefereeAdmin ? (
                            <td style={{ minWidth: '120px' }}>
                                <Link
                                    to={`/sudije/takmicenja-iz-kalendara-TSS/${result?.attributes?.uuid}`}
                                    onClick={(e) =>
                                        setLocalStorageActiveTab('sudjenje')
                                    }
                                >
                                    <p className="link">Dezigniraj</p>
                                </Link>
                            </td>
                        ) : (
                            <td></td>
                        )
                    ) : null}
                    {finished && (
                        <>
                            <td style={{ minWidth: '170px' }}>
                                {pillHandler(
                                    hasReport?.attributes?.is_verified
                                )}
                            </td>
                            <td>
                                {!hasReport ? (
                                    <Link
                                        to={`/sudije/takmicenja-iz-kalendara-TSS/${result?.attributes?.uuid}/kreiraj-izvestaj`}
                                    >
                                        <p className="link">Dodaj izveštaj</p>
                                    </Link>
                                ) : (
                                    <Link
                                        to={`/sudije/takmicenja-iz-kalendara-TSS/${result?.attributes?.uuid}/izmeni-izvestaj`}
                                    >
                                        {hasReport?.attributes
                                            ?.is_verified ? null : (
                                            <p className="link">
                                                Izmeni izveštaj
                                            </p>
                                        )}
                                    </Link>
                                )}
                            </td>
                        </>
                    )}
                </tr>
            ) : (
                <Card
                    className={`referee-competitions-card ${
                        isWhite
                            ? 'white-referee-competition-card'
                            : 'grey-referee-competition-card'
                    }`}
                >
                    <Card.Body className="py-0">
                        <Row>
                            <Col>
                                <Row className="ref-comp-card-row align-items-center">
                                    <Col xs={5}>
                                        <h5>Naziv</h5>
                                    </Col>
                                    <Col xs={7}>
                                        <Link
                                            to={`${result?.attributes?.uuid}`}
                                        >
                                            <p className="link">
                                                {result?.attributes?.name}
                                            </p>
                                        </Link>
                                    </Col>
                                </Row>
                                <Row className="ref-comp-card-row align-items-center">
                                    <Col xs={5}>
                                        <h5>Organizator</h5>
                                    </Col>
                                    <Col xs={7} className="d-flex">
                                        {result?.attributes?.club_organizer
                                            ?.attributes?.name ? (
                                            <>
                                                <img
                                                    src={
                                                        result?.attributes
                                                            ?.club_organizer
                                                            ?.attributes?.logo
                                                            ?.data
                                                            ? formatImgUrl(
                                                                  result
                                                                      ?.attributes
                                                                      ?.club_organizer
                                                                      ?.attributes
                                                                      ?.logo
                                                                      ?.data
                                                                      ?.attributes
                                                                      ?.url
                                                              )
                                                            : generateAvatar(
                                                                  result
                                                                      ?.attributes
                                                                      ?.club_organizer
                                                                      ?.attributes
                                                                      ?.name
                                                              )
                                                    }
                                                    alt={`Logo kluba`}
                                                    width={'24px'}
                                                    height={'24px'}
                                                    style={{
                                                        maxHeight: '24px',
                                                        maxWidth: '24px',
                                                        borderRadius: '50%',
                                                    }}
                                                />
                                                <Link
                                                    to={`/sudije/klubovi/${result?.attributes?.club_organizer?.attributes?.uuid}`}
                                                >
                                                    <p className="ms-2 link">
                                                        {
                                                            result?.attributes
                                                                ?.club_organizer
                                                                ?.attributes
                                                                ?.name
                                                        }
                                                    </p>
                                                </Link>
                                            </>
                                        ) : (
                                            <p>-</p>
                                        )}
                                    </Col>
                                </Row>

                                <Row className="ref-comp-card-row align-items-center">
                                    <Col xs={5}>
                                        <h5>Odjaviti do</h5>
                                    </Col>
                                    <Col xs={7}>
                                        <p>
                                            {result?.attributes
                                                ?.checkout_deadline_date
                                                ? formatDate(
                                                      result?.attributes
                                                          ?.checkout_deadline_date
                                                  )
                                                : '-'}
                                        </p>
                                    </Col>
                                </Row>

                                <Row className="ref-comp-card-row align-items-center">
                                    <Col xs={5}>
                                        <h5>Broj takmičara</h5>
                                    </Col>
                                    <Col xs={7}>
                                        <p>
                                            {result?.attributes
                                                ?.participants_expected_number
                                                ? result?.attributes
                                                      ?.participants_expected_number
                                                : '-'}
                                        </p>
                                    </Col>
                                </Row>

                                <Row className="ref-comp-card-row align-items-center">
                                    <Col xs={5}>
                                        <h5>Kategorija</h5>
                                    </Col>
                                    <Col xs={7}>
                                        <p>
                                            {result?.attributes
                                                ?.tournament_category
                                                ?.tournament_category_name
                                                ? result?.attributes
                                                      ?.tournament_category
                                                      ?.tournament_category_name
                                                : '-'}
                                        </p>
                                    </Col>
                                </Row>
                                {finished && (
                                    <>
                                        <Row className="ref-comp-card-row align-items-center">
                                            <Col xs={12}>
                                                {pillHandler(
                                                    hasReport?.attributes
                                                        ?.is_verified
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className="ref-comp-card-row align-items-center border-bottom-0">
                                            <Col xs={12}>
                                                {!hasReport ? (
                                                    <Link
                                                        to={`/sudije/takmicenja-iz-kalendara-TSS/${result?.attributes?.uuid}/kreiraj-izvestaj`}
                                                    >
                                                        <p className="link">
                                                            Dodaj izveštaj
                                                        </p>
                                                    </Link>
                                                ) : (
                                                    <Link
                                                        to={`/sudije/takmicenja-iz-kalendara-TSS/${result?.attributes?.uuid}/izmeni-izvestaj`}
                                                    >
                                                        {hasReport?.attributes
                                                            ?.is_verified ? null : (
                                                            <p className="link">
                                                                Izmeni izveštaj
                                                            </p>
                                                        )}
                                                    </Link>
                                                )}
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            )}
        </>
    )
}

export default SingleTournamentRow
