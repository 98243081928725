import React, { useEffect, useState } from 'react'
import { Col, ProgressBar, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import CloseOutlined from '../../assets/svg/CloseOutlined'
import { createClub, getSingleClub, updateClubProfile } from '../../lib/api'
import Button from '../Button'
import {
    StepFive,
    StepFour,
    StepOne,
    StepSix,
    StepThree,
    StepTwo,
} from './Steps'

import { useAppContext } from '../../lib/contextLib'
import { formatImgUrl, isObjectEmpty } from '../../lib/helpers'
import './styles.scss'

let initialForm = {
    name: '',
    short_name: '',
    address: {
        city: '',
        street: '',
    },
    establishment_date: '',
    phone: '',
    email: '',
    region: {
        region_name: '',
    },
    website_url: '',
    club_history: '',
    courts: {
        number_of_clay_courts: 0,
        number_of_carpet_courts: 0,
        number_of_hard_courts: 0,
        number_of_indoor_courts: 0,
        number_of_reflector_courts: 0,
    },
}

const ClubForm = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const { setShowCustomToast } = useAppContext()
    const FormTitles = [
        'Osnovni podaci',
        'Osnovni podaci',
        'Kontakt podaci',
        'Kontakt podaci',
        'Tereni',
        'Istorija kluba',
    ]

    const [step, setStep] = useState(1)

    // This is important for progress bar to show properly % based on step and max step
    const [maxSteps, setMaxSteps] = useState(6)
    const [clubImg, setClubImg] = useState(null)
    const [clubPreviewImg, setClubPreviewImg] = useState(null)
    const [imgs, setImgs] = useState([])
    const [newClub, setNewClub] = useState(initialForm)
    const [availableCites, setAvailableCities] = useState([])
    const [selectedCity, setSelectedCity] = useState([])

    const [disableNextButton, setDisableNextButton] = useState(true)

    const resetForm = () => {
        setNewClub(initialForm)
    }

    const handleCloseForm = () => {
        resetForm()
        navigate('/klubovi')
    }

    const handleRequiredFields = () => {
        if (step === 1) {
            if (newClub.name && newClub.short_name) {
                setDisableNextButton(false)
            } else {
                setDisableNextButton(true)
            }
        } else if (step === 2) {
            if (newClub?.region?.region_name && newClub?.establishment_date) {
                setDisableNextButton(false)
            } else {
                setDisableNextButton(true)
            }
        } else if (step === 3) {
            if (newClub?.email && newClub?.phone) {
                setDisableNextButton(false)
            } else {
                setDisableNextButton(true)
            }
        }
    }

    const handleCreateClub = async () => {
        try {
            const createdClub = await createClub(newClub, clubImg)

            if (createdClub) {
                handleCloseForm()
                setShowCustomToast({
                    show: true,
                    type: 'success',
                    message: 'Klub uspešno kreiran.',
                })
            }
        } catch (error) {
            setShowCustomToast({
                show: true,
                type: 'error',
                message: 'Došlo je do greške.',
            })
        }
    }

    const getClubProfile = async (id) => {
        let club = await getSingleClub(id)
        if (club) {
            setNewClub(filterFields(club?.attributes))
            club?.attributes?.logo?.data &&
                setClubPreviewImg(
                    formatImgUrl(club?.attributes?.logo?.data?.attributes?.url)
                )
            club?.attributes?.address &&
                setSelectedCity({
                    name: club?.attributes?.address?.city,
                    value: club?.attributes?.address?.city,
                })
        } else {
            navigate('/klubovi')
        }
    }

    const updateClub = (e) => {
        const { name, value } = e.target
        setNewClub((state) => ({ ...state, [name]: value }))
    }

    function extractChangeField(id) {
        // Split the ID string using '_' as the separator
        var parts = id.split('_')

        // Return joined strings
        return parts.slice(1, parts.length).join('_')
    }

    const updateClubStaff = (e) => {
        const { id, name, value } = e.target

        let changeField = extractChangeField(id)

        let selectedMember = {}
        let selectedIndex = 0

        if (newClub?.staff) {
            let searchedMember = newClub.staff.find(
                (member) => member.title === name
            )
            if (searchedMember) {
                let searchedMemberIndex = newClub.staff.findIndex(
                    (member) => member.title === name
                )
                selectedMember = searchedMember
                selectedIndex = searchedMemberIndex
            }
        }

        if (isObjectEmpty(selectedMember)) {
            if (newClub?.staff) {
                setNewClub((state) => ({
                    ...state,
                    staff: [
                        ...state?.staff,
                        { title: name, [changeField]: value },
                    ],
                }))
            } else {
                setNewClub((state) => ({
                    ...state,
                    staff: [{ title: name, [changeField]: value }],
                }))
            }
        } else {
            let staff_clone = [...newClub?.staff]
            staff_clone[selectedIndex][changeField] = value
            setNewClub((state) => ({ ...state, staff: [...staff_clone] }))
        }
    }

    const staffValue = (title, field) => {
        let searchedMember = newClub?.staff?.find(
            (member) => member?.title === title
        )

        return searchedMember
            ? searchedMember[field]
                ? searchedMember[field]
                : ''
            : ''
    }

    const handleUpdateClub = async (id) => {
        try {
            const updatedClub = await updateClubProfile(id, newClub, clubImg)

            if (updatedClub) {
                setShowCustomToast({
                    show: true,
                    type: 'success',
                    message: 'Klub uspešno ažuriran.',
                })
                navigate('/klubovi')
            }
        } catch (error) {
            console.log(error)
            setShowCustomToast({
                show: true,
                type: 'error',
                message: 'Došlo je do greške.',
            })
        }
    }

    const forwardStepHandler = () => {
        if (step === 6) {
            if (id) {
                handleUpdateClub(id)
            } else {
                handleCreateClub()
            }
            return
        } else {
            setStep((state) => state + 1)
        }
    }

    const backwardStepHandler = () => {
        if (step === 1) {
            handleCloseForm()
        } else {
            setStep((state) => state - 1)
        }
    }

    const formHandler = () => {
        switch (step) {
            case 1:
                return (
                    <StepOne
                        newClub={newClub}
                        updateClub={updateClub}
                        setClubImg={setClubImg}
                        clubPreviewImg={clubPreviewImg}
                        setClubPreviewImg={setClubPreviewImg}
                    />
                )
            case 2:
                return (
                    <StepTwo
                        newClub={newClub}
                        updateClub={updateClub}
                        setNewClub={setNewClub}
                        selectedCity={selectedCity}
                        setSelectedCity={setSelectedCity}
                        availableCites={availableCites}
                        setAvailableCities={setAvailableCities}
                    />
                )
            case 3:
                return (
                    <StepThree
                        updateClub={updateClub}
                        newClub={newClub}
                        setNewClub={setNewClub}
                        updateClubStaff={updateClubStaff}
                        staffValue={staffValue}
                    />
                )
            case 4:
                return (
                    <StepFour
                        newClub={newClub}
                        setNewClub={setNewClub}
                        updateClubStaff={updateClubStaff}
                        staffValue={staffValue}
                    />
                )
            case 5:
                return (
                    <StepFive
                        newClub={newClub}
                        updateClub={setNewClub}
                        setNewClub={setNewClub}
                    />
                )
            case 6:
                return (
                    <StepSix
                        newClub={newClub}
                        updateClub={updateClub}
                        step={step}
                        imgs={imgs}
                        setImgs={setImgs}
                    />
                )
            default:
                break
        }
    }

    function filterFields(structure) {
        if (Array.isArray(structure)) {
            return structure.map(filterFields)
        } else if (typeof structure === 'object' && structure !== null) {
            const filteredObject = {}

            for (const key in structure) {
                if (
                    key !== 'id' &&
                    key !== 'createdAt' &&
                    key !== 'updatedAt' &&
                    key !== 'gallery' &&
                    key !== 'logo'
                ) {
                    filteredObject[key] = filterFields(structure[key])
                }
            }

            return filteredObject
        }

        return structure
    }

    useEffect(() => {
        handleRequiredFields()
    }, [newClub, step])

    useEffect(() => {
        if (id) {
            getClubProfile(id)
        }
    }, [id])

    return (
        <Row className="create-new-club-wrapper justify-content-center">
            <Col className="create-club-wrapper px-0">
                <Row className="justify-content-center">
                    <ProgressBar
                        now={(step / maxSteps) * 100}
                        className="px-0"
                    />
                    <Col className="d-flex flex-column align-items-center justify-content-end close-icon mt-2 mt-md-3 me-md-3">
                        <Row className="w-100 align-items-center justify-content-end">
                            <div className="d-flex justify-content-end">
                                <CloseOutlined onClick={handleCloseForm} />
                            </div>
                        </Row>
                    </Col>
                    <Row className="club-form-wrapper justify-content-center">
                        <Col className="form-columns p-md-0">
                            <Col className="mt-2">
                                <Col
                                    md={12}
                                    className="d-flex align-items-center justify-content-center"
                                >
                                    <h1>{FormTitles[step - 1]}</h1>
                                </Col>
                                <Col
                                    md={12}
                                    className="mt-2 mb-4 d-flex align-items-center justify-content-center"
                                >
                                    <p className="small-text">{`${step}/${FormTitles.length}`}</p>
                                </Col>
                                {/* content based on component */}
                                <Col className="mt-4 align-items-center justify-content-center">
                                    {formHandler()}
                                </Col>
                                <Col
                                    md={12}
                                    className="mt-4 d-flex flex-md-row flex-column align-items-center justify-content-end form-footer-buttons"
                                >
                                    <Button
                                        type="text"
                                        className="footer-button"
                                        onClick={backwardStepHandler}
                                    >
                                        {step !== 1 ? 'Prethodna' : 'Odustani'}
                                    </Button>
                                    <Button
                                        className="footer-button"
                                        onClick={forwardStepHandler}
                                        disabled={disableNextButton}
                                    >
                                        {step < 6 ? 'Sledeća' : 'Sačuvaj'}
                                    </Button>
                                </Col>
                            </Col>
                        </Col>
                    </Row>
                </Row>
            </Col>
        </Row>
    )
}

export default ClubForm
