import React, { useEffect, useState } from 'react'
import {
    getMatchParticipants,
    getPlayersPrevMatch,
    getSingleMatch,
    getSinglePlayer,
} from '../../lib/api'
import { useAppContext } from '../../lib/contextLib'
import './styles.scss'
const TournamentMatchCardForPDF = ({
    className,
    style,
    matchDetails,
    is_winner = false,
    competitionProfileData,
    show = false,
}) => {
    const { setIsLoading } = useAppContext()
    const side_1_top = matchDetails?.is_double ? 40 : 20
    const side_2_bottom = matchDetails?.is_double ? -20 : -20

    const [sideOneData, setSideOneData] = useState()
    const [sideTwoData, setSideTwoData] = useState()
    const [matchData, setMatchData] = useState()
    const [mounted, setMounted] = useState(false)

    const fetchPlayerData = async (uuid) => {
        if (!uuid) return null
        return await getSinglePlayer(uuid)
    }

    const handleGetSingleMatch = async () => {
        const { round_number, tournament_uuid } = matchDetails || {}

        try {
            if (round_number && tournament_uuid) {
                let match
                let side1PrevMatch
                let side2PrevMatch
                if (matchDetails?.match?.attributes?.uuid) {
                    match = matchDetails?.match
                } else {
                    match = await getSingleMatch(matchDetails)
                }

                if (match) {
                    const {
                        side_1_uuid,
                        side_1_result,
                        side_2_uuid,
                        side_2_result,
                        uuid,
                        loser_participant_uuid,
                    } = match?.attributes || {}

                    if (matchDetails?.round_number > 1) {
                        side1PrevMatch = await getPlayersPrevMatch({
                            ...matchDetails,
                            round_number: matchDetails?.round_number - 1,
                            participant_uuid: side_1_uuid,
                        })

                        side2PrevMatch = await getPlayersPrevMatch({
                            ...matchDetails,
                            round_number: matchDetails?.round_number - 1,
                            participant_uuid: side_2_uuid,
                        })
                    }

                    const matchParticipants = await getMatchParticipants({
                        side_1_uuid,
                        side_2_uuid,
                    })

                    if (matchParticipants) {
                        const participant_1 = matchParticipants.find(
                            (participant) =>
                                participant?.attributes?.uuid === side_1_uuid
                        )
                        const participant_2 = matchParticipants.find(
                            (participant) =>
                                participant?.attributes?.uuid === side_2_uuid
                        )

                        const [
                            side_1_player_1,
                            side_1_player_2,
                            side_2_player_1,
                            side_2_player_2,
                        ] = await Promise.all([
                            fetchPlayerData(
                                participant_1?.attributes?.player_1_uuid
                            ),
                            fetchPlayerData(
                                participant_1?.attributes?.player_2_uuid
                            ),
                            fetchPlayerData(
                                participant_2?.attributes?.player_1_uuid
                            ),
                            fetchPlayerData(
                                participant_2?.attributes?.player_2_uuid
                            ),
                        ])

                        // const player1Rank = await getPlayersRank(
                        //     participant_1?.attributes?.player_1_uuid,
                        //     competitionProfileData?.attributes
                        //         ?.players_competition?.players_competition_name,
                        //     competitionProfileData?.attributes
                        //         ?.players_competition
                        //         ?.players_competition_subcategory_name || null
                        // )

                        let side1Result
                        let side1LoserResult
                        let side2Result
                        let side2LoserResult

                        if (is_winner) {
                            if (side_1_uuid !== loser_participant_uuid) {
                                side1Result = side_1_result
                                side1LoserResult = side_2_result
                            } else {
                                side2Result = side_2_result
                                side2LoserResult = side_1_result
                            }
                        } else {
                            if (
                                side1PrevMatch?.attributes?.side_1_uuid ===
                                side_1_uuid
                            ) {
                                if (
                                    side1PrevMatch?.attributes?.side_1_uuid ===
                                    side1PrevMatch?.attributes
                                        ?.loser_participant_uuid
                                ) {
                                    side1Result =
                                        side1PrevMatch?.attributes
                                            ?.side_2_result

                                    side1LoserResult =
                                        side1PrevMatch?.attributes
                                            ?.side_1_result
                                } else {
                                    side1Result =
                                        side1PrevMatch?.attributes
                                            ?.side_1_result

                                    side1LoserResult =
                                        side1PrevMatch?.attributes
                                            ?.side_2_result
                                }
                            } else {
                                if (
                                    side1PrevMatch?.attributes?.side_2_uuid ===
                                    side1PrevMatch?.attributes
                                        ?.loser_participant_uuid
                                ) {
                                    side1Result =
                                        side1PrevMatch?.attributes
                                            ?.side_1_result

                                    side1LoserResult =
                                        side1PrevMatch?.attributes
                                            ?.side_2_result
                                } else {
                                    side1Result =
                                        side1PrevMatch?.attributes
                                            ?.side_2_result

                                    side1LoserResult =
                                        side1PrevMatch?.attributes
                                            ?.side_1_result
                                }
                            }

                            if (
                                side2PrevMatch?.attributes?.side_1_uuid ===
                                side_2_uuid
                            ) {
                                if (
                                    side2PrevMatch?.attributes?.side_1_uuid ===
                                    side2PrevMatch?.attributes
                                        ?.loser_participant_uuid
                                ) {
                                    side2Result =
                                        side2PrevMatch?.attributes
                                            ?.side_2_result

                                    side2LoserResult =
                                        side2PrevMatch?.attributes
                                            ?.side_1_result
                                } else {
                                    side2Result =
                                        side2PrevMatch?.attributes
                                            ?.side_1_result

                                    side2LoserResult =
                                        side2PrevMatch?.attributes
                                            ?.side_2_result
                                }
                            } else {
                                if (
                                    side2PrevMatch?.attributes?.side_2_uuid ===
                                    side2PrevMatch?.attributes
                                        ?.loser_participant_uuid
                                ) {
                                    side2Result =
                                        side2PrevMatch?.attributes
                                            ?.side_1_result

                                    side2LoserResult =
                                        side2PrevMatch?.attributes
                                            ?.side_2_result
                                } else {
                                    side2Result =
                                        side2PrevMatch?.attributes
                                            ?.side_2_result

                                    side2LoserResult =
                                        side2PrevMatch?.attributes
                                            ?.side_1_result
                                }
                            }
                        }

                        setSideOneData({
                            participant_uuid: side_1_uuid,
                            player_1: side_1_player_1,
                            player_2: side_1_player_2,
                            result: side1Result,
                            loser_result: side1LoserResult,
                        })
                        setSideTwoData({
                            participant_uuid: side_2_uuid,
                            player_1: side_2_player_1,
                            player_2: side_2_player_2,
                            result: side2Result,
                            loser_result: side2LoserResult,
                        })

                        setMatchData({
                            ...matchDetails,
                            uuid: uuid,
                            loser_participant_uuid: loser_participant_uuid,
                        })
                    }
                } else {
                    setMatchData({
                        ...matchDetails,
                    })
                }
            }
            if (is_winner && show && mounted) {
                setTimeout(() => {
                    setIsLoading(false)
                }, 1500)
            }
        } catch (error) {
            console.error('Greška:', error)
            if (is_winner && show && mounted) {
                setTimeout(() => {
                    setIsLoading(false)
                }, 1500)
            }
        }
    }

    const handleShowResult = (result, loserResult) => {
        let resultString = ''

        if (
            result?.result?.length > 0 &&
            loserResult?.result?.length > 0 &&
            result?.result?.length === loserResult?.result?.length
        ) {
            result?.result?.forEach((item, index) => {
                resultString += `${item}:${loserResult?.result[index]} `
            })
        }

        if (resultString) {
            return `${resultString}`
        } else {
            return ''
        }
    }

    useEffect(() => {
        handleGetSingleMatch()
    }, [matchDetails])

    useEffect(() => {
        if (!mounted) {
            setMounted(true)
        }
    }, [])

    return is_winner ? (
        <>
            <div className="winner-line">
                <p className="ms-2">
                    {matchData?.loser_participant_uuid
                        ? matchData?.loser_participant_uuid ===
                          sideOneData?.participant_uuid
                            ? `${
                                  sideTwoData?.player_1?.attributes?.person_info
                                      ?.given_name
                              } 
                    ${
                        sideTwoData?.player_1?.attributes?.person_info
                            ?.family_name
                    } ${
                                  sideTwoData?.player_2
                                      ? `i ${sideTwoData?.player_2?.attributes?.person_info?.given_name} ${sideTwoData?.player_2?.attributes?.person_info?.family_name}`
                                      : ''
                              }`
                            : `${
                                  sideOneData?.player_1?.attributes?.person_info
                                      ?.given_name
                              } 
                    ${
                        sideOneData?.player_1?.attributes?.person_info
                            ?.family_name
                    } ${
                                  sideOneData?.player_2
                                      ? `i ${sideOneData?.player_2?.attributes?.person_info?.given_name} ${sideOneData?.player_2?.attributes?.person_info?.family_name}`
                                      : ''
                              }`
                        : 'Nema pobednika'}
                </p>
            </div>
            <p className="ms-2">
                {handleShowResult(
                    matchData?.loser_participant_uuid ===
                        sideOneData?.participant_uuid
                        ? sideTwoData?.result
                        : sideOneData?.result,
                    matchData?.loser_participant_uuid ===
                        sideOneData?.participant_uuid
                        ? sideTwoData?.loser_result
                        : sideOneData?.loser_result
                ) || <span style={{ opacity: '0' }}>-</span>}
            </p>
        </>
    ) : (
        <div
            className={`tournament-match-card-for-pdf ${className}`}
            style={style}
        >
            <div className="side-1" style={{ top: `-${side_1_top}px` }}>
                <div className="d-flex align-items-center">
                    <p className="me-2">
                        {matchDetails?.round_number === 1 &&
                            matchDetails?.pair_number -
                                1 +
                                matchDetails?.pair_number}
                    </p>

                    <div>
                        <p>
                            {sideOneData?.participant_uuid === '-1'
                                ? 'BYE'
                                : `${
                                      sideOneData?.player_1?.attributes
                                          ?.person_info?.given_name
                                          ? sideOneData?.player_1?.attributes
                                                ?.person_info?.given_name
                                          : ''
                                  } ${
                                      sideOneData?.player_1?.attributes
                                          ?.person_info?.family_name
                                          ? sideOneData?.player_1?.attributes
                                                ?.person_info?.family_name
                                          : ''
                                  }`}
                        </p>

                        {sideOneData?.player_2 && (
                            <p>
                                {
                                    sideOneData?.player_2?.attributes
                                        ?.person_info?.given_name
                                }{' '}
                                {
                                    sideOneData?.player_2?.attributes
                                        ?.person_info?.family_name
                                }
                            </p>
                        )}
                    </div>
                </div>
                <p className="ms-2">
                    {handleShowResult(
                        sideOneData?.result,
                        sideOneData?.loser_result
                    ) || <span style={{ opacity: '0' }}>-</span>}
                </p>
            </div>
            <div className="side-2" style={{ bottom: `${side_2_bottom}px` }}>
                <div className="d-flex align-items-center">
                    <p className="me-2">
                        {matchDetails?.round_number === 1 &&
                            matchDetails?.pair_number * 2}
                    </p>
                    <div>
                        <p>
                            {sideTwoData?.participant_uuid === '-1'
                                ? 'BYE'
                                : `${
                                      sideTwoData?.player_1?.attributes
                                          ?.person_info?.given_name
                                          ? sideTwoData?.player_1?.attributes
                                                ?.person_info?.given_name
                                          : ''
                                  } ${
                                      sideTwoData?.player_1?.attributes
                                          ?.person_info?.family_name
                                          ? sideTwoData?.player_1?.attributes
                                                ?.person_info?.family_name
                                          : ''
                                  }`}
                        </p>

                        {sideTwoData?.player_2 && (
                            <p>
                                {
                                    sideTwoData?.player_2?.attributes
                                        ?.person_info?.given_name
                                }{' '}
                                {
                                    sideTwoData?.player_2?.attributes
                                        ?.person_info?.family_name
                                }
                            </p>
                        )}
                    </div>
                </div>

                <p className="ms-2">
                    {handleShowResult(
                        sideTwoData?.result,
                        sideTwoData?.loser_result
                    ) || <span style={{ opacity: '0' }}>-</span>}
                </p>
            </div>
        </div>
    )
}

export default TournamentMatchCardForPDF
