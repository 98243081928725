import * as React from 'react'

const Danger = (props) => (
    <svg
        width={21}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M10.167 1.668c-4.595 0-8.334 3.738-8.334 8.333 0 4.595 3.739 8.334 8.334 8.334S18.5 14.596 18.5 10s-3.738-8.333-8.333-8.333Zm0 12.817a.8.8 0 1 1 0-1.602.8.8 0 0 1 0 1.602Zm.87-8.059-.23 4.888a.641.641 0 0 1-1.282 0l-.23-4.886v-.002a.871.871 0 1 1 1.74 0h.002Z"
            fill={props.fill ? props.fill : '#D23E3F'}
        />
    </svg>
)

export default Danger
