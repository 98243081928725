import React from 'react'
import { Table } from 'react-bootstrap'
import TextField from '../../../../../components/TextField'
import {
    numbersOnly,
    notLeadingZero,
    getKeys,
} from '../../../../../lib/helpers'
const RoundRobinTournamentPointTable = ({
    mainCategory,
    tournamentCategory,
    entryPoints,
    isEditable,
    setEntryPoints,
}) => {
    return (
        <Table className="table-competitors-pagination-wrapper table-competitors-wrapper">
            <thead>
                <tr>
                    <th></th>
                    <th>
                        <h5>Grupa od {tournamentCategory}</h5>
                    </th>
                </tr>
            </thead>

            <tbody>
                {getKeys(
                    entryPoints?.entry_points_schema?.[`${mainCategory}`]
                        ?.points[`${tournamentCategory}`]
                )?.map((round, index) => (
                    <tr key={index}>
                        <td>
                            <p>{round}. mesto</p>
                        </td>

                        <td>
                            {isEditable ? (
                                <TextField
                                    className="entry-points-input-field"
                                    onChange={(e) => {
                                        numbersOnly(e) // Osigurava da je unos numerički
                                        notLeadingZero(e) // Sprečava početne nule
                                        const newPointsValue = +e.target.value // Konvertuje vrednost unosa u broj

                                        setEntryPoints((prev) => {
                                            return {
                                                ...prev,
                                                entry_points_schema: {
                                                    ...prev.entry_points_schema,
                                                    [`${mainCategory}`]: {
                                                        ...prev
                                                            .entry_points_schema[
                                                            `${mainCategory}`
                                                        ],
                                                        points: {
                                                            ...prev
                                                                .entry_points_schema[
                                                                `${mainCategory}`
                                                            ].points,
                                                            [`${tournamentCategory}`]:
                                                                {
                                                                    ...prev
                                                                        .entry_points_schema[
                                                                        `${mainCategory}`
                                                                    ].points[
                                                                        `${tournamentCategory}`
                                                                    ],
                                                                    [`${round}`]:
                                                                        newPointsValue, // Ovde se ažurira specifična vrednost za rundu
                                                                },
                                                        },
                                                    },
                                                },
                                            }
                                        })
                                    }}
                                    type="tel"
                                    value={
                                        entryPoints?.entry_points_schema?.[
                                            `${mainCategory}`
                                        ]?.points[`${tournamentCategory}`]?.[
                                            `${round}`
                                        ] || ''
                                    }
                                >
                                    {
                                        entryPoints?.entry_points_schema?.[
                                            `${mainCategory}`
                                        ]?.points[`${tournamentCategory}`]?.[
                                            `${round}`
                                        ]
                                    }
                                </TextField>
                            ) : (
                                <p>
                                    {
                                        entryPoints?.entry_points_schema?.[
                                            `${mainCategory}`
                                        ]?.points[`${tournamentCategory}`][
                                            `${round}`
                                        ]
                                    }
                                </p>
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

export default RoundRobinTournamentPointTable
