import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import TournamentMatchCard from '../../../../../../../components/TournamentMatchCard'
import { getLegacyMatches } from '../../../../../../../lib/api'

const LegacyDraw = ({ tournament, drawToShow }) => {
    const [matchesList, setMatchesList] = useState([])
    const handleGetOldMatches = async () => {
        const { matches } = await getLegacyMatches(
            tournament?.attributes?.uuid,
            drawToShow?.legacy_round_number || 1
        )

        setMatchesList(matches)
    }

    useEffect(() => {
        handleGetOldMatches()
    }, [tournament, drawToShow])
    return (
        <>
            <Row className="gx-5 gy-3 px-3">
                {matchesList?.map((match, index) => (
                    <Col xs={12} md={4} key={index}>
                        <TournamentMatchCard
                            matchDetails={{
                                round_number:
                                    drawToShow?.legacy_round_number || 1,
                                tournament_uuid: tournament?.attributes?.uuid,
                                match,
                            }}
                        />
                    </Col>
                ))}
                <Col></Col>
            </Row>
        </>
    )
}

export default LegacyDraw
