import axios from 'axios'
import { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import MultiSelectSearch from '../../../../../components/MultiSelectSearch'
import RadioButton from '../../../../../components/RadioButton'
import TextField from '../../../../../components/TextField'
import { checkJMBG, numbersOnly } from '../../../../../lib/helpers'
import './styles.scss'

const SecondStep = ({
    setProgress,
    formData,
    setFormData,
    disableNextButton,
    setDisableNextButton,
}) => {
    const [availableCites, setAvailableCities] = useState([])
    const [selectedCity, setSelectedCity] = useState([])

    useEffect(() => {
        setProgress(50)
        if (formData?.birth_address?.city) {
            setSelectedCity({
                name: formData?.birth_address?.city,
                value: formData?.birth_address?.city,
            })
        }
    }, [])

    const handleCity = (cityName) => {
        if (cityName?.length > 2) {
            axios
                .get(`${process.env.REACT_APP_GEONAMES_URL}/searchJSON`, {
                    params: {
                        name_startsWith: cityName?.toString(),
                        featureClass: 'P',
                        country: 'RS,XK', // Serbia as country preselected for clubs
                        maxRows: 5,
                        username: `${process.env.REACT_APP_GEONAMES_USERNAME}`,
                    },
                })
                .then((response) => {
                    const cityOptions = []
                    response.data?.geonames?.map((city) => {
                        cityOptions?.push({
                            value: `${city?.name}`,
                            name: city?.name,
                        })
                    })
                    setAvailableCities(cityOptions)
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {
            setAvailableCities([])
        }
    }

    const handleSelectCity = (cityName) => {
        if (!cityName) {
            setSelectedCity((_) => {
                return ''
            })
            setFormData((prev) => {
                return {
                    ...prev,
                    birth_address: {
                        ...prev?.birth_address,
                        city: '',
                    },
                }
            })
        } else {
            setFormData((prev) => ({
                ...prev,
                birth_address: {
                    ...prev?.birth_address,
                    city: cityName?.name,
                },
            }))
            availableCites.map((city) => {
                if (city?.value === cityName?.value) {
                    setSelectedCity((_) => {
                        return city
                    })
                }
            })
        }
    }

    return (
        <Row className="second-step-wrapper">
            <Col md={12} className="mt-3">
                <TextField
                    id="JMBG"
                    name="JMBG"
                    type="text"
                    value={formData?.person_info?.jmbg || ''}
                    onChange={(e) => {
                        numbersOnly(e)
                        e.target.value.length > 0
                            ? checkJMBG(e.target.value)
                                ? setDisableNextButton(false)
                                : setDisableNextButton(true)
                            : setDisableNextButton(false)
                        setFormData((prevState) => ({
                            ...prevState,
                            person_info: {
                                ...prevState.person_info,
                                jmbg: e.target.value,
                            },
                        }))
                    }}
                    label="JMBG"
                    placeholder="Vaš JMBG"
                    minLength={13}
                    maxLength={13}
                />
            </Col>
            <Col md={12} className="mt-3">
                <TextField
                    type="date"
                    label="Datum rođenja"
                    value={formData?.person_info?.birthdate || ''}
                    onChange={(e) =>
                        setFormData((prevState) => ({
                            ...prevState,
                            person_info: {
                                ...prevState.person_info,
                                birthdate: e.target.value,
                            },
                        }))
                    }
                />
            </Col>
            <Col md={12} className="mt-3">
                <MultiSelectSearch
                    label={'Grad rođenja'}
                    animated
                    options={availableCites}
                    noOptionsMessage={'Nema rezultata'}
                    onInputChange={(e) => handleCity(e)}
                    onChange={(e) => handleSelectCity(e)}
                    placeholder={'Pretraži i izaberi grad...'}
                    isMulti={false}
                    value={selectedCity}
                    isClearable={true}
                />
            </Col>

            <Col xs={12} className="mt-3">
                <RadioButton
                    name="Zaposlen"
                    radioValues={['Da', 'Ne']}
                    onChange={(e) => {
                        if (e.target.value === 'Da') {
                            setFormData((prev) => ({
                                ...prev,
                                is_employed: true,
                            }))
                        } else if (e.target.value === 'Ne') {
                            setFormData((prev) => ({
                                ...prev,
                                is_employed: false,
                                parent_name: '',
                                professional_training: '',
                            }))
                        } else {
                            delete formData.is_employed
                        }
                    }}
                    checkedValue={
                        formData?.is_employed === true
                            ? 'Da'
                            : formData?.is_employed === false
                            ? 'Ne'
                            : ''
                    }
                />
            </Col>

            {formData?.is_employed === false && (
                <>
                    <Col md={12} className="mt-3">
                        <TextField
                            placeholder="Ime i prezime roditelja"
                            type="text"
                            value={formData?.parent_name || ''}
                            onChange={(e) => {
                                setFormData((prevState) => ({
                                    ...prevState,
                                    parent_name: e.target.value,
                                }))
                            }}
                            label="Ime i prezime roditelja"
                        />
                    </Col>

                    <Col md={12} className="mt-3">
                        <TextField
                            placeholder="Stručna sprema"
                            type="text"
                            value={formData?.professional_training || ''}
                            onChange={(e) => {
                                setFormData((prevState) => ({
                                    ...prevState,
                                    professional_training: e.target.value,
                                }))
                            }}
                            label="Stručna sprema"
                        />
                    </Col>
                </>
            )}
        </Row>
    )
}

export default SecondStep
