import * as React from 'react'

const GreenCheck = (props) => (
    <svg
        width={21}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M10.167 1.668c-4.595 0-8.334 3.738-8.334 8.333 0 4.595 3.739 8.334 8.334 8.334S18.5 14.596 18.5 10s-3.738-8.333-8.333-8.333Zm4.337 5.54-5.385 6.41a.64.64 0 0 1-.48.23h-.012a.642.642 0 0 1-.476-.213l-2.308-2.564a.64.64 0 1 1 .953-.857l1.815 2.016 4.911-5.846a.641.641 0 0 1 .982.824Z"
            fill="#4B852D"
        />
    </svg>
)

export default GreenCheck
