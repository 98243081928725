import { useEffect, useState } from 'react'
import { Breadcrumb, Card, Col, Row, Tab, Tabs } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Change from '../../../../assets/svg/Change'
import DocumentTextOutlined from '../../../../assets/svg/DocumentTextOutlined'
import FilledMail from '../../../../assets/svg/FilledMail'
import Flag from '../../../../assets/svg/Flag'
import Person from '../../../../assets/svg/Person'
import Trash from '../../../../assets/svg/Trash'
import Button from '../../../../components/Button'
import ConfirmationModal from '../../../../components/ConfirmationModal'
import IconInfoCard from '../../../../components/IconInfoCard'

import PrintOutlined from '../../../../assets/svg/PrintOutlined'
import ProfileCard from '../../../../components/ProfileCard'
import {
    deleteCoach,
    getCoachLicenses,
    getSingleCoach,
    getCoachsClub,
    getSingleClubWithQuery,
} from '../../../../lib/api'
import { formatDate } from '../../../../lib/helpers'
import { generateAvatar } from '../../../../utils/generateAvatar'

import jsPDF from 'jspdf'
import 'jspdf-autotable'
import ArrowRight from '../../../../assets/svg/ArrowRight'
import MedicalHistory from '../../../../components/MedicalHistory'
import { font } from '../../Competitions/CompetitionProfile/font'

import { useAppContext } from '../../../../lib/contextLib'
import './styles.scss'

const CoachProfile = () => {
    const navigate = useNavigate()
    const { setShowCustomToast, setIsLoading } = useAppContext()
    const [showMedicalHistory, setShowMedicalHistory] = useState(false)
    const [profileData, setProfileData] = useState([])
    const [refreshData, setRefreshData] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const { id } = useParams()

    const getCoachProfile = async (id) => {
        try {
            setIsLoading(true)

            const [coachProfile, licenses, coachsClub] = await Promise.all([
                getSingleCoach(id),
                getCoachLicenses(id),
                getCoachsClub(id),
            ])

            if (coachsClub) {
                const clubProfile = await getSingleClubWithQuery(
                    coachsClub?.attributes?.club_uuid,
                    'fields[0]=name&fields[1]=uuid'
                )
                coachProfile.attributes.club = clubProfile
            }
            if (licenses) {
                coachProfile.attributes.licenses = licenses
            }

            setProfileData(coachProfile)

            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
        }
    }

    // Whenever id of the user is changed - Update Profile Data
    useEffect(() => {
        getCoachProfile(id)
        setRefreshData(false)
    }, [id, refreshData])

    // Delete Coach Profile
    const deleteCoachProfile = async () => {
        try {
            let response = await deleteCoach(id)
            if (response) {
                navigate('/treneri')
                setShowCustomToast({
                    show: true,
                    type: 'success',
                    message: 'Trener je obrisan.',
                })
            }
        } catch (error) {
            setShowCustomToast({
                show: true,
                type: 'error',
                message: 'Došlo je do greške.',
            })
        }
    }

    // Handling delete modal
    const handleDeleteModal = () => {
        setShowDeleteModal(!showDeleteModal)
    }

    const createDoc = (orientation, unit, size, font) => {
        const marginLeft = 40
        const doc = new jsPDF(orientation, unit, size)
        doc.addFileToVFS('WorkSans-normal.ttf', font)
        doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
        doc.setFont('WorkSans')
        doc.setFontSize(15)

        const title = `${profileData?.attributes?.person_info?.given_name} ${
            profileData?.attributes?.person_info?.family_name
        } - ${formatDate(new Date())}`
        doc.text(title, marginLeft, 40)

        const generalInfo = [
            ['Ime', profileData?.attributes?.person_info?.given_name],
            ['Prezime', profileData?.attributes?.person_info?.family_name],
            [
                'Datum rođenja',
                profileData?.attributes?.person_info?.birthdate
                    ? formatDate(
                          profileData?.attributes?.person_info?.birthdate
                      )
                    : 'Nema informacije',
            ],
            [
                'Pol',
                profileData?.attributes?.gender
                    ? profileData?.attributes?.gender?.gender_name
                    : 'Nema informacije',
            ],
            [
                'Email',
                profileData?.attributes?.person_info?.email
                    ? profileData?.attributes?.person_info?.email
                    : 'Nema informacije',
            ],
            [
                'Klub',
                profileData?.attributes?.club
                    ? profileData?.attributes?.club?.attributes?.name
                    : 'Nema informacije',
            ],
        ]

        let generalInfoContent = {
            startY: 50,
            head: [['Opšte informacije', ' ']],
            body: generalInfo,
            styles: {
                font: 'WorkSans',
            },
            columnStyles: {
                0: { cellWidth: 250 },
            },
        }

        doc.autoTable(generalInfoContent)

        let coachLicenses = [
            [
                'Nacionalna licenca',
                profileData?.attributes?.licenses?.attributes?.national_license
                    ? profileData?.attributes?.licenses?.attributes
                          ?.national_license?.national_license_name
                    : 'Nema informacije',
            ],
            [
                'Datum isticanja nacionalne licence',
                profileData?.attributes?.licenses?.attributes?.national_license
                    ?.license_expiration_date
                    ? formatDate(
                          profileData?.attributes?.licenses?.attributes
                              ?.national_license?.license_expiration_date
                      )
                    : 'Nema informacije',
            ],
            [
                'Licenca ITF',
                profileData?.attributes?.licenses?.attributes?.itf_license
                    ? profileData?.attributes?.licenses?.attributes?.itf_license
                          ?.itf_license_name
                    : 'Nema informacije',
            ],
            [
                'Licenca GPTCA',
                profileData?.attributes?.licenses?.attributes?.gptca_license
                    ? profileData?.attributes?.licenses?.attributes
                          ?.gptca_license?.gptca_license_name
                    : 'Nema informacije',
            ],
            [
                'Licenca USPTA',
                profileData?.attributes?.licenses?.attributes?.uspta_license
                    ? profileData?.attributes?.licenses?.attributes
                          ?.uspta_license?.uspta_license_name
                    : 'Nema informacije',
            ],
            [
                'Licenca LTA',
                profileData?.attributes?.licenses?.attributes?.lta_license
                    ? profileData?.attributes?.licenses?.attributes?.lta_license
                          ?.lta_license_name
                    : 'Nema informacije',
            ],
            [
                'Licenca PTR',
                profileData?.attributes?.licenses?.attributes?.ptr_license
                    ? profileData?.attributes?.licenses?.attributes?.ptr_license
                          ?.ptr_license_name
                    : 'Nema informacije',
            ],
        ]

        let licensesContent = {
            startY: doc.lastAutoTable.finalY + 20,
            head: [['Licence', ' ']],
            body: coachLicenses,
            styles: {
                font: 'WorkSans',
            },
            columnStyles: {
                0: { cellWidth: 250 },
            },
        }

        doc.autoTable(licensesContent)

        return doc
    }

    const handleExportCoachPDF = () => {
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'portrait' // portrait or landscape

        const doc = createDoc(orientation, unit, size, font)

        doc.save(
            `${profileData?.attributes?.person_info?.given_name}_${
                profileData?.attributes?.person_info?.family_name
            }-${formatDate(new Date()).slice(0, -1)}.pdf`
        )
    }

    const handlePrintCoach = () => {
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'portrait' // portrait or landscape

        const doc = createDoc(orientation, unit, size, font)

        // for printing
        doc.autoPrint()
        doc.output('dataurlnewwindow')
    }

    return (
        <Row className="coach-profile-wrapper h-100">
            {!showMedicalHistory ? (
                <>
                    <Col md={12}>
                        <Row className="pt-3">
                            <Col xs={12} md={6} className="d-flex align-center">
                                <Row>
                                    <Col md={12}>
                                        <h2 className="d-none d-md-block">
                                            Treneri
                                        </h2>
                                        <p className="d-block d-md-none">
                                            Treneri
                                        </p>
                                        <Breadcrumb className="mt-md-2 mt-1">
                                            <Breadcrumb.Item
                                                linkAs={Link}
                                                linkProps={{ to: '/treneri' }}
                                            >
                                                Treneri
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item active>
                                                {
                                                    profileData?.attributes
                                                        ?.person_info
                                                        ?.given_name
                                                }{' '}
                                                {
                                                    profileData?.attributes
                                                        ?.person_info
                                                        ?.family_name
                                                }
                                            </Breadcrumb.Item>
                                        </Breadcrumb>
                                    </Col>
                                </Row>
                                <div className="ms-5 d-flex align-center d-md-none">
                                    <Button
                                        type="text"
                                        onClick={() =>
                                            navigate(
                                                `/treneri/${profileData?.attributes?.uuid}/izmeni-profil-trenera`
                                            )
                                        }
                                    >
                                        <Change height="24px" width="24px" />
                                    </Button>
                                    <Button
                                        type="text"
                                        onClick={handleDeleteModal}
                                    >
                                        <Trash />
                                    </Button>
                                </div>
                            </Col>
                            <Col md={6} className="d-none d-md-block">
                                <Row className="d-flex align-items-center justify-content-end">
                                    <Col
                                        md={7}
                                        className="ps-0 d-flex justify-content-end"
                                    >
                                        <Button
                                            type="text"
                                            leadingIcon={
                                                <DocumentTextOutlined />
                                            }
                                            onClick={() => {
                                                handleExportCoachPDF()
                                            }}
                                            className="me-2"
                                        >
                                            Eksportuj u PDF
                                        </Button>
                                        <Button
                                            type="secondary"
                                            leadingIcon={<PrintOutlined />}
                                            onClick={() => handlePrintCoach()}
                                        >
                                            Štampaj
                                        </Button>
                                        <Button
                                            type="secondary"
                                            leadingIcon={
                                                <Change height="24px" />
                                            }
                                            className="mx-2"
                                            onClick={() =>
                                                navigate(
                                                    `/treneri/${profileData?.attributes?.uuid}/izmeni-profil-trenera`
                                                )
                                            }
                                        >
                                            Izmeni
                                        </Button>
                                        <Button
                                            type="secondary"
                                            leadingIcon={<Trash />}
                                            onClick={handleDeleteModal}
                                        >
                                            Obriši
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="m-0 card-profile mb-3">
                            <ProfileCard
                                name={`${profileData?.attributes?.person_info?.given_name} ${profileData?.attributes?.person_info?.family_name}`}
                                email={
                                    profileData?.attributes?.person_info?.email
                                }
                                avatar={
                                    profileData?.attributes?.person_info
                                        ?.picture
                                        ? profileData?.attributes?.person_info
                                              ?.picture
                                        : generateAvatar(
                                              `${profileData?.attributes?.person_info?.given_name} ${profileData?.attributes?.person_info?.family_name}`
                                          )
                                }
                                className="couch-background"
                            />

                            <Col
                                md={12}
                                className="pt-0 pt-md-4 d-flex align-items-center justify-content-start justify-content-md-end"
                            >
                                <Tabs id="uncontrolled-tab-example">
                                    <Tab
                                        eventKey="profil"
                                        title={
                                            <>
                                                <Person width="20px" />
                                                <p className="ms-2">Profil</p>
                                            </>
                                        }
                                        tabClassName="d-flex"
                                    ></Tab>
                                </Tabs>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col xs={12} md={6}>
                                <Row>
                                    <Col sm={12} className="mb-3">
                                        <IconInfoCard
                                            icon={<Person />}
                                            title="Opšte informacije"
                                        >
                                            <span className="mb-3 d-flex align-items-center">
                                                <h5 className="me-1">
                                                    Ime i prezime:
                                                </h5>
                                                <p className="card-title-color">
                                                    {
                                                        profileData?.attributes
                                                            ?.person_info
                                                            ?.given_name
                                                    }{' '}
                                                    {
                                                        profileData?.attributes
                                                            ?.person_info
                                                            ?.family_name
                                                    }
                                                </p>
                                            </span>
                                            <span className="mb-3 d-flex align-items-center">
                                                <h5 className="me-1">
                                                    Datum rođenja:
                                                </h5>
                                                <p className="card-title-color">
                                                    {formatDate(
                                                        profileData?.attributes
                                                            ?.person_info
                                                            ?.birthdate
                                                    )}
                                                </p>
                                            </span>
                                            <span className="mb-3 d-flex align-items-center">
                                                <h5 className="me-1">Pol:</h5>
                                                <p className="card-title-color">
                                                    {profileData?.attributes
                                                        ?.gender
                                                        ? profileData
                                                              ?.attributes
                                                              ?.gender
                                                              ?.gender_name
                                                        : 'Nema informacije'}
                                                </p>
                                            </span>
                                            <span className="mb-3 d-flex align-items-center">
                                                <h5 className="me-1">Klub:</h5>
                                                {profileData?.attributes?.club
                                                    ?.attributes?.name ? (
                                                    <Link
                                                        to={`/klubovi/${profileData?.attributes?.club?.attributes?.uuid}`}
                                                        className="link"
                                                    >
                                                        {
                                                            profileData
                                                                ?.attributes
                                                                ?.club
                                                                ?.attributes
                                                                ?.name
                                                        }
                                                    </Link>
                                                ) : (
                                                    <p className="card-title-color">
                                                        Nema informacija
                                                    </p>
                                                )}
                                            </span>
                                        </IconInfoCard>
                                    </Col>
                                    <Col sm={12} className="mb-3">
                                        <Card className="competitor-flow-profile-card-wrapper">
                                            <Card.Body className="competitor-flow-profile-card-body membership-card">
                                                <Row className="pb-5">
                                                    <Col className="d-flex align-items-center">
                                                        <h2>
                                                            Lekarski pregled
                                                        </h2>
                                                    </Col>
                                                </Row>
                                                {profileData?.attributes
                                                    ?.medical_history && (
                                                    <Row>
                                                        <Col className="membership-view">
                                                            {profileData?.attributes?.medical_history
                                                                .slice(0, 3)
                                                                .map(
                                                                    (
                                                                        medical,
                                                                        index
                                                                    ) => (
                                                                        <Col
                                                                            key={
                                                                                index
                                                                            }
                                                                            className={`d-flex align-items-center justify-content-between single-membership`}
                                                                        >
                                                                            <Col>
                                                                                <p
                                                                                    className={`membership-year`}
                                                                                >
                                                                                    {`${
                                                                                        index +
                                                                                        1
                                                                                    }. ${formatDate(
                                                                                        medical?.start_date
                                                                                    )} - ${formatDate(
                                                                                        medical?.end_date
                                                                                    )}`}
                                                                                </p>
                                                                            </Col>
                                                                        </Col>
                                                                    )
                                                                )}
                                                        </Col>
                                                    </Row>
                                                )}
                                            </Card.Body>
                                            <Card.Footer className="competitor-flow-profile-card-footer">
                                                <Row>
                                                    <Col className="d-flex align-items-center justify-content-end">
                                                        <Button
                                                            type="text"
                                                            endIcon={
                                                                <ArrowRight
                                                                    disabled={
                                                                        true
                                                                    }
                                                                />
                                                            }
                                                            onClick={() =>
                                                                setShowMedicalHistory(
                                                                    true
                                                                )
                                                            }
                                                            disabled={true}
                                                        >
                                                            Detaljnije
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Card.Footer>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>

                            <Col sm={12} md={6}>
                                <Row>
                                    <Col sm={12} className="mb-3">
                                        <IconInfoCard
                                            icon={<Flag />}
                                            title="Licenca"
                                        >
                                            {profileData?.attributes?.licenses
                                                ?.attributes
                                                ?.national_license && (
                                                <span className="mb-3 d-flex align-items-center">
                                                    <h5 className="me-1">
                                                        Nacionalna licenca:
                                                    </h5>
                                                    <p className="card-title-color">
                                                        {
                                                            profileData
                                                                ?.attributes
                                                                ?.licenses
                                                                ?.attributes
                                                                ?.national_license
                                                                ?.national_license_name
                                                        }
                                                    </p>
                                                </span>
                                            )}

                                            {profileData?.attributes?.licenses
                                                ?.attributes?.national_license
                                                ?.license_expiration_date && (
                                                <span className="mb-3 d-flex align-items-center">
                                                    <h5 className="me-1">
                                                        Datum isticanja
                                                        nacionalne licence:
                                                    </h5>
                                                    <p className="card-title-color">
                                                        {formatDate(
                                                            profileData
                                                                ?.attributes
                                                                ?.licenses
                                                                ?.attributes
                                                                ?.national_license
                                                                ?.license_expiration_date
                                                        )}
                                                    </p>
                                                </span>
                                            )}

                                            {profileData?.attributes?.licenses
                                                ?.attributes?.itf_license && (
                                                <span className="mb-3 d-flex align-items-center">
                                                    <h5 className="me-1">
                                                        ITF:
                                                    </h5>
                                                    <p className="card-title-color">
                                                        {
                                                            profileData
                                                                ?.attributes
                                                                ?.licenses
                                                                ?.attributes
                                                                ?.itf_license
                                                                ?.itf_license_name
                                                        }
                                                    </p>
                                                </span>
                                            )}
                                            {profileData?.attributes?.licenses
                                                ?.attributes?.gptca_license && (
                                                <span className="mb-3 d-flex align-items-center">
                                                    <h5 className="me-1">
                                                        GPTCA:
                                                    </h5>
                                                    <p className="card-title-color">
                                                        {
                                                            profileData
                                                                ?.attributes
                                                                ?.licenses
                                                                ?.attributes
                                                                ?.gptca_license
                                                                ?.gptca_license_name
                                                        }
                                                    </p>
                                                </span>
                                            )}
                                            {profileData?.attributes?.licenses
                                                ?.attributes?.uspta_license && (
                                                <span className="mb-3 d-flex align-items-center">
                                                    <h5 className="me-1">
                                                        USPTA:
                                                    </h5>
                                                    <p className="card-title-color">
                                                        {
                                                            profileData
                                                                ?.attributes
                                                                ?.licenses
                                                                ?.attributes
                                                                ?.uspta_license
                                                                ?.uspta_license_name
                                                        }
                                                    </p>
                                                </span>
                                            )}
                                            {profileData?.attributes?.licenses
                                                ?.attributes?.lta_license && (
                                                <span className="mb-3 d-flex align-items-center">
                                                    <h5 className="me-1">
                                                        LTA:
                                                    </h5>
                                                    <p className="card-title-color">
                                                        {
                                                            profileData
                                                                ?.attributes
                                                                ?.licenses
                                                                ?.attributes
                                                                ?.lta_license
                                                                ?.lta_license_name
                                                        }
                                                    </p>
                                                </span>
                                            )}
                                            {profileData?.attributes?.licenses
                                                ?.attributes?.ptr_license && (
                                                <span className="mb-3 d-flex align-items-center">
                                                    <h5 className="me-1">
                                                        PTR:
                                                    </h5>
                                                    <p className="card-title-color">
                                                        {
                                                            profileData
                                                                ?.attributes
                                                                ?.licenses
                                                                ?.attributes
                                                                ?.ptr_license
                                                                ?.ptr_license_name
                                                        }
                                                    </p>
                                                </span>
                                            )}
                                        </IconInfoCard>
                                    </Col>

                                    <Col sm={12} className="mb-3">
                                        <IconInfoCard
                                            icon={<FilledMail />}
                                            title={
                                                profileData?.attributes
                                                    ?.person_info?.email
                                            }
                                            subtitle="Email"
                                        ></IconInfoCard>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    {/* MODAL - OBRISI TRENERA */}
                    <ConfirmationModal
                        modalText="Jeste li sigurni da želite obrisati trenera?"
                        onHide={() => setShowDeleteModal(false)}
                        show={showDeleteModal}
                        onClick={deleteCoachProfile}
                    />
                </>
            ) : (
                <MedicalHistory
                    medicalHistoryList={
                        profileData?.attributes?.medical_history
                    }
                    userType="coach"
                    profileData={profileData}
                    setShowMedicalHistory={setShowMedicalHistory}
                    setRefreshData={setRefreshData}
                />
            )}
        </Row>
    )
}

export default CoachProfile
