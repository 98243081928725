import React from 'react'
import { Toast as ToastWrapper, ToastContainer } from 'react-bootstrap'
import CloseOutlined from '../../assets/svg/CloseOutlined'
import ToastInfo from '../../assets/svg/ToastInfo'
import ToastSuccess from '../../assets/svg/ToastSuccess'
import ToastWarning from '../../assets/svg/ToastWarning'

import './styles.scss'

function Toast({ type, message, show, onClose }) {
    const handleToastType = (type) => {
        switch (type) {
            case 'info':
                return <ToastInfo width={24} height={24} color="white" />
            case 'success':
                return <ToastSuccess width={24} height={24} color="white" />
            case 'warning':
                return <ToastWarning width={24} height={24} />
            case 'error':
                return <ToastInfo width={24} height={24} color="white" />

            default:
                return <ToastInfo width={24} height={24} color="white" />
        }
    }

    return (
        <ToastContainer position="top-end">
            <ToastWrapper show={show} delay={2000} autohide onClose={onClose}>
                <ToastWrapper.Header
                    className={`toast-header-wrapper toast-header-wrapper-${type} justify-content-between`}
                    closeButton={false}
                >
                    <div className="d-flex">
                        <div className="d-flex align-items-center">
                            <div className="me-2">{handleToastType(type)}</div>
                            <p
                                className={`${
                                    type === 'warning'
                                        ? 'text-black'
                                        : 'text-white'
                                }`}
                            >
                                {message}
                            </p>
                        </div>
                    </div>
                    <div
                        className={`close-icon close-icon-${type} align-items-center d-flex justify-content-center`}
                        onClick={onClose}
                    >
                        <CloseOutlined
                            fill={`${type === 'warning' ? 'black' : 'white'}`}
                        />
                    </div>
                </ToastWrapper.Header>
            </ToastWrapper>
        </ToastContainer>
    )
}

export default Toast
