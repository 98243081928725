import { handleShowContactPerson, formatDate } from '../../lib/helpers'

export const exportCSV = async (filterParams, getAllClubs, setIsLoading) => {
    const filteredClubs = await getAllClubs(1, filterParams, true)
    setIsLoading(true)
    const rows = []
    for (let i = 1; i <= filteredClubs?.length; i++) {
        const c = filteredClubs[i - 1]

        rows.push([
            c?.attributes?.name || '-',
            c?.attributes?.short_name || '-',
            c?.attributes?.region?.region_name || '-',
            c?.attributes?.email || '-',
            handleShowContactPerson(c?.attributes?.staff),
            c?.attributes?.phone || '-',
            c?.attributes?.status || '-',
        ])
    }

    let data = [
        [
            'Klub',
            'Skraćeno ime',
            'Region',
            'Email',
            'Kontakt osoba',
            'Telefon',
            'Status',
        ],
        ...rows,
    ]

    let csvContent = ''
    data.forEach((rowArray) => {
        let row = rowArray.join(',')
        csvContent += row + '\r\n'
    })

    let blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })

    let link = document.createElement('a')
    let url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute(
        'download',
        `Klubovi - Ukupno-${filteredClubs?.length}, ${formatDate(
            new Date()
        ).slice(0, -1)}.csv`
    )
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    setIsLoading(false)
}
