import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { numbersOnly } from '../../../lib/helpers'

import TextField from '../../TextField'

const StepFour = ({ newClub, setNewClub, updateClubStaff, staffValue }) => {
    return (
        <Row className="step-four-wrapper">
            <form>
                <Col md={12}>
                    <h3>Direktor kluba</h3>
                </Col>
                <Col md={12} className="mt-3">
                    <TextField
                        id="Direktor_given_name"
                        name="Direktor"
                        label="Ime"
                        placeholder="Ime direktora"
                        onChange={(e) => updateClubStaff(e)}
                        value={staffValue('Direktor', 'given_name')}
                    />
                </Col>
                <Col md={12} className="mt-3">
                    <TextField
                        id="Direktor_family_name"
                        name="Direktor"
                        label="Prezime"
                        placeholder="Prezime direktora"
                        onChange={(e) => updateClubStaff(e)}
                        value={staffValue('Direktor', 'family_name')}
                    />
                </Col>
                <Col md={12} className="mt-3">
                    <TextField
                        id="Direktor_email"
                        name="Direktor"
                        label="Email"
                        placeholder="Email direktora"
                        onChange={(e) => updateClubStaff(e)}
                        value={staffValue('Direktor', 'email')}
                        type="email"
                    />
                </Col>
                <Col md={12} className="mt-3">
                    <TextField
                        id="Direktor_phone"
                        name="Direktor"
                        label="Telefon"
                        placeholder="Telefon direktora"
                        onChange={(e) => {
                            numbersOnly(e)
                            updateClubStaff(e)
                        }}
                        type="tel"
                        value={staffValue('Direktor', 'phone')}
                    />
                </Col>
                <Col md={12} className="mt-3">
                    <h3>Sekretar</h3>
                </Col>
                <Col md={12} className="mt-3">
                    <TextField
                        id="Sekretar_given_name"
                        name="Sekretar"
                        label="Ime"
                        placeholder="Ime sekretara"
                        onChange={(e) => updateClubStaff(e)}
                        value={staffValue('Sekretar', 'given_name')}
                    />
                </Col>
                <Col md={12} className="mt-3">
                    <TextField
                        id="Sekretar_family_name"
                        name="Sekretar"
                        label="Prezime"
                        placeholder="Prezime sekretara"
                        onChange={(e) => updateClubStaff(e)}
                        value={staffValue('Sekretar', 'family_name')}
                    />
                </Col>
                <Col md={12} className="mt-4">
                    <TextField
                        id="Sekretar_phone"
                        name="Sekretar"
                        label="Telefon"
                        placeholder="Telefon sekretara"
                        onChange={(e) => {
                            numbersOnly(e)
                            updateClubStaff(e)
                        }}
                        type="tel"
                        value={staffValue('Sekretar', 'phone')}
                    />
                </Col>
            </form>
        </Row>
    )
}

export default StepFour
