import { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import Spinner from '../components/Spinner'
import { useAppContext } from '../lib/contextLib'
import { customToastHandler, toastHandler } from '../lib/helpers'
import ScrollToTop from '../lib/hooks/ScrollToTop'
import ProtectedRoutes from './ProtectedRoutes'
import PublicRoutes from './PublicRoutes'

const RoutesWrapper = () => {
    const { authenticated, isLoading, showCustomToast, setShowCustomToast } =
        useAppContext()
    const [showToast, setShowToast] = useState(false)
    useEffect(() => {
        authenticated && setShowToast(true)
    }, [authenticated])

    const handleCustomToast = () => {
        return customToastHandler(showCustomToast, setShowCustomToast)
    }

    return (
        <>
            {!authenticated ? (
                <Container fluid>
                    <Col className="public-main-content d-flex flex-column">
                        <Row className="main-scroll" id="main-scroll-wrapper">
                            <Col>
                                <ScrollToTop />
                                <PublicRoutes />
                                {isLoading && <Spinner />}
                            </Col>
                        </Row>
                    </Col>
                </Container>
            ) : (
                <Container fluid>
                    {showToast &&
                        toastHandler({
                            success: true,
                            showToast,
                            setShowToast,
                            message: 'Uspešno ste se prijavili.',
                        })}
                    {showCustomToast && handleCustomToast()}
                    <Row>
                        <Col
                            sm={0}
                            md={2}
                            className="sidebar-content d-none d-md-flex"
                        >
                            <Sidebar />
                        </Col>
                        <Col
                            sm={12}
                            md={10}
                            className="main-content d-flex flex-column"
                        >
                            <Row className="main-content-row">
                                <Col>
                                    <Header />
                                </Col>
                            </Row>
                            <Row
                                className="main-scroll"
                                id="main-scroll-wrapper"
                            >
                                <Col>
                                    <ScrollToTop />
                                    <ProtectedRoutes />
                                    {isLoading && <Spinner />}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            )}
        </>
    )
}

export default RoutesWrapper
