import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Button from '../../../../../../components/Button'
import Dropdown from '../../../../../../components/Dropdown'
import { useAppContext } from '../../../../../../lib/contextLib'
import AddSpecialPlayerModal from './addSpecialPlayerModal'

const DropDownSection = ({
    participantsToShow,
    setParticipantsToShow,
    className,
    competitionProfileData,
    handleGetPlayersForTournament,
    handleGetPlayersForTournamentWithPoints,
    showSaveButton,
    saveParticipantsPoints,
    setDisableButton,
}) => {
    const { user } = useAppContext()
    const [showSpecialPlayerModal, setShowSpecialPlayerModal] = useState(false)

    return (
        <Row className={`${className} align-items-end`}>
            {competitionProfileData?.attributes
                ?.doubles_pairs_expected_number && (
                <Col xs={12} md={3}>
                    <Dropdown
                        placeholder="Tip učesnika"
                        label="Tip učesnika"
                        value={participantsToShow?.is_double ? 'Dubl' : 'Singl'}
                        handleSelect={(e) =>
                            e === 'Singl'
                                ? setParticipantsToShow((prev) => ({
                                      ...prev,
                                      is_double: false,
                                  }))
                                : setParticipantsToShow((prev) => ({
                                      ...prev,
                                      is_double: true,
                                  }))
                        }
                        dropdownLinks={['Singl', 'Dubl']}
                    />
                </Col>
            )}

            {competitionProfileData?.attributes?.genders?.length > 1 && (
                <Col xs={12} md={3} className="mt-2 mt-md-0">
                    <Dropdown
                        label={'Pol učesnika'}
                        placeholder="Izaberite pol"
                        dropdownLinks={competitionProfileData?.attributes?.genders?.map(
                            (gender) => {
                                return gender?.gender_name
                            }
                        )}
                        value={participantsToShow?.gender}
                        handleSelect={(e) =>
                            setParticipantsToShow((prev) => ({
                                ...prev,
                                gender: e,
                            }))
                        }
                    />
                </Col>
            )}
            {user?.role?.type !== 'competitor' && (
                <Row className="mt-3 mt-md-0 mx-0 px-0 justify-content-end">
                    <Col className="d-flex justify-content-end flex-md-row flex-column">
                        {competitionProfileData?.attributes?.tournament_type
                            ?.tournament_type_name === 'Individualno' && (
                            <div>
                                <Button
                                    type="secondary"
                                    onClick={() =>
                                        setShowSpecialPlayerModal(true)
                                    }
                                >
                                    Dodaj specijalnog igrača
                                </Button>
                            </div>
                        )}

                        {showSaveButton ? (
                            <Button
                                onClick={() => saveParticipantsPoints()}
                                className="ms-md-3 ms-0 mt-3 mt-md-0"
                            >
                                Sačuvaj bodove
                            </Button>
                        ) : (
                            <div className="ms-md-3 ms-0 mt-3 mt-md-0 d-flex flex-column md-align-items-end">
                                <Button
                                    onClick={() => {
                                        setDisableButton(true)
                                        handleGetPlayersForTournamentWithPoints()
                                    }}
                                >
                                    Automatski izračunaj bodove
                                </Button>
                                {competitionProfileData?.attributes?.genders
                                    ?.length > 1 && (
                                    <p className="small-text mt-2">
                                        Bodovi se računaju za selektovani pol
                                    </p>
                                )}
                            </div>
                        )}
                    </Col>
                </Row>
            )}

            <AddSpecialPlayerModal
                show={showSpecialPlayerModal}
                onHide={() => setShowSpecialPlayerModal(false)}
                tournament={competitionProfileData}
                handleGetPlayersForTournament={handleGetPlayersForTournament}
            />
        </Row>
    )
}

export default DropDownSection
