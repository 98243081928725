import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import TrashOutlined from '../../../../../assets/svg/TrashOutlined'
import Button from '../../../../../components/Button'
import ConfirmationModal from '../../../../../components/ConfirmationModal'
import { formatDate } from '../../../../../lib/helpers'

function SingleDisciplinaryPenalty({
    penalty,
    removePenalty = false,
    isAdmin = false,
}) {
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    return (
        <>
            <Row className="mb-3">
                <Col className="d-flex align-items-center justify-content-between">
                    <div>
                        <p>{penalty?.attributes?.penalty_name}</p>

                        <p className="small-text">
                            {penalty?.attributes?.description}
                        </p>
                        <p className="small-text">
                            Važi od:{' '}
                            {formatDate(penalty?.attributes?.start_date)}
                        </p>
                        {penalty?.attributes?.end_date && (
                            <p className="small-text">
                                Važi do:{' '}
                                {formatDate(penalty?.attributes?.end_date)}
                            </p>
                        )}
                        {penalty?.attributes?.penalty_points > 0 && (
                            <p className="small-text">
                                Kazneni bodovi:{' '}
                                {penalty?.attributes?.penalty_points}
                            </p>
                        )}
                    </div>
                    {isAdmin && (
                        <Button
                            type="text"
                            onClick={() => setShowConfirmationModal(true)}
                        >
                            <TrashOutlined width={24} height={24} />
                        </Button>
                    )}
                </Col>
            </Row>
            <ConfirmationModal
                show={showConfirmationModal}
                onHide={() => setShowConfirmationModal(false)}
                modalText="Jeste li sigurni da želite obrisati kaznu?"
                onClick={() => {
                    removePenalty && removePenalty(penalty?.attributes?.uuid)
                    setShowConfirmationModal(false)
                }}
            />
        </>
    )
}

export default SingleDisciplinaryPenalty
