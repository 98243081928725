import { Card, Col, Row } from 'react-bootstrap'

import '../../styles.scss'

const AssemblyClubQualityCard = ({ isWhite, result }) => {
    const TableCellName = {
        senior: 'Učesnik seniorske lige',
        junior: 'Učesnik juniorske lige',
    }
    return (
        <Card
            className={`club-card ${
                isWhite ? 'white-club-card' : 'grey-club-card'
            }`}
        >
            <Card.Body className="py-0">
                <Row>
                    <Col>
                        <Row className="car-row align-items-center">
                            <Col>
                                <h4>Učesnik u ligi</h4>
                            </Col>
                            <Col>
                                <p>
                                    {TableCellName[
                                        result?.league?.toLowerCase()
                                    ] ?? ''}
                                </p>
                            </Col>
                        </Row>

                        <Row className="car-row align-items-center">
                            <Col>
                                <h4>Glasova</h4>
                            </Col>
                            <Col>
                                <p>{result?.votes}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default AssemblyClubQualityCard
