import React from 'react'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Link } from 'react-router-dom'
import { formatDate, formatImgUrl } from '../../lib/helpers'
import { generateAvatar } from '../../utils/generateAvatar'

import './styles.scss'

function TableCardRefereeAdminCompetitions({ data, id, finished, isWhite }) {
    return (
        <Card
            className={`referee-admin-competitions-card ${
                isWhite
                    ? 'white-referee-admin-competition-card'
                    : 'grey-referee-admin-competition-card'
            }`}
        >
            <Card.Body className="py-0">
                <Row>
                    <Col>
                        <Row className="ref-comp-card-row align-items-center">
                            <Col xs={5}>
                                <h5>Naziv</h5>
                            </Col>
                            <Col xs={7}>
                                <Link to={`${data?.attributes?.uuid}`}>
                                    <p className="link">
                                        {data?.attributes?.name}
                                    </p>
                                </Link>
                            </Col>
                        </Row>

                        <Row className="ref-comp-card-row align-items-center">
                            <Col xs={5}>
                                <h5>Organizator</h5>
                            </Col>
                            <Col xs={7} className="d-flex">
                                {data?.attributes?.club_organizer?.attributes
                                    ?.name ? (
                                    <>
                                        <img
                                            src={
                                                data?.attributes?.club_organizer
                                                    ?.attributes?.logo?.data
                                                    ? formatImgUrl(
                                                          data?.attributes
                                                              ?.club_organizer
                                                              ?.attributes?.logo
                                                              ?.data?.attributes
                                                              ?.url
                                                      )
                                                    : generateAvatar(
                                                          data?.attributes
                                                              ?.club_organizer
                                                              ?.attributes?.name
                                                      )
                                            }
                                            alt={`Logo kluba`}
                                            width={'24px'}
                                            height={'24px'}
                                            style={{
                                                maxHeight: '24px',
                                                maxWidth: '24px',
                                                borderRadius: '50%',
                                            }}
                                        />
                                        <Link
                                            to={`/klubovi/${data?.attributes?.club_organizer?.attributes?.uuid}`}
                                        >
                                            <p className="ms-2 link">
                                                {
                                                    data?.attributes
                                                        ?.club_organizer
                                                        ?.attributes?.name
                                                }
                                            </p>
                                        </Link>
                                    </>
                                ) : (
                                    <p>-</p>
                                )}
                            </Col>
                        </Row>
                        <Row className="ref-comp-card-row align-items-center">
                            <Col xs={5}>
                                <h5>Odjaviti do</h5>
                            </Col>
                            <Col xs={7}>
                                <p>
                                    {data?.attributes?.checkout_deadline_date
                                        ? formatDate(
                                              data?.attributes
                                                  ?.checkout_deadline_date
                                          )
                                        : '-'}
                                </p>
                            </Col>
                        </Row>

                        <Row className="ref-comp-card-row align-items-center">
                            <Col xs={5}>
                                <h5>Kategorija</h5>
                            </Col>
                            <Col xs={7}>
                                <p>
                                    {data?.attributes?.tournament_category
                                        ?.tournament_category_name
                                        ? data?.attributes?.tournament_category
                                              ?.tournament_category_name
                                        : '-'}
                                </p>
                            </Col>
                        </Row>
                        {!finished && (
                            <Row className="ref-comp-card-row align-items-center">
                                <Col xs={5}>
                                    <h5></h5>
                                </Col>
                                <Col xs={7}>
                                    <Link
                                        to={
                                            data?.attributes?.designated
                                                ? `/administrator-sudjenja/takmicenja/${data?.attributes?.uuid}/izmeni-sudije`
                                                : `/administrator-sudjenja/takmicenja/${data?.attributes?.uuid}/dodaj-sudiju`
                                        }
                                        state={data?.attributes}
                                    >
                                        <p className="link">
                                            {data?.attributes?.designated
                                                ? 'Izmeni dezigniranje'
                                                : 'Dezigniraj'}
                                        </p>
                                    </Link>
                                </Col>
                            </Row>
                        )}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default TableCardRefereeAdminCompetitions
