import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import Dropdown from '../../../../../components/Dropdown'
import TextField from '../../../../../components/TextField'

import './styles.scss'

function CourtAndBalls({ setProgress, formData, setFormData, surface_type }) {
    useEffect(() => {
        setProgress(50)
    }, [])

    const setField = (e) => {
        let field = e.target.id
        let value = e.target.value
        let mainField = e.target.name

        if (field === 'quality' || field === 'maintenance') {
            if (value > 5) {
                e.target.value = 5
                value = 5
            }
        }

        setFormData((prevData) => ({
            ...prevData,
            [mainField]: { ...prevData[mainField], [field]: value },
        }))
    }

    const setSurfaceMain = (field, e) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: { ...prevData[field], surface: e },
        }))
    }

    const setReflectorsFields = (field, e) => {
        setFormData((prevData) => ({
            ...prevData,
            reflectors: { ...prevData.reflectors, [field]: e },
        }))
    }

    const setBallsRulebookFields = (field, e) => {
        setFormData((prevData) => ({
            ...prevData,
            balls: { ...prevData.balls, [field]: e },
        }))
    }

    return (
        <Row className="court-and-balls-wrapper">
            <h3 className="awards-section-title">Centralni teren</h3>
            <Col className="d-block d-md-flex flex-column flex-md-row mb-4">
                <Col className="d-flex mb-3 mb-md-0">
                    <Col className="align-self-end">
                        <Dropdown
                            dropdownLinks={surface_type}
                            placeholder="Tip"
                            value={formData?.centralCourt?.surface}
                            handleSelect={(e) =>
                                setSurfaceMain('centralCourt', e)
                            }
                            label={'Podloga'}
                        />
                    </Col>
                    <Col className="me-md-3 ms-3 align-self-end">
                        <TextField
                            label="Količina"
                            placeholder="Količina"
                            id="quantity"
                            name="centralCourt"
                            onChange={setField}
                            type={'number'}
                            value={formData?.centralCourt?.quantity}
                        />
                    </Col>
                </Col>
                <Col className="d-flex">
                    <Col className="me-3 align-self-end">
                        <TextField
                            label="Kvalitet (1-5)"
                            placeholder="Kvalitet (1-5)"
                            name="centralCourt"
                            id="quality"
                            onChange={setField}
                            type={'number'}
                            value={formData?.centralCourt?.quality}
                        />
                    </Col>
                    <Col className="align-self-end">
                        <TextField
                            label="Održavanje (1-5)"
                            placeholder="Održavanje (1-5)"
                            name="centralCourt"
                            id="maintenance"
                            onChange={setField}
                            type={'number'}
                            value={formData?.centralCourt?.maintenance}
                        />
                    </Col>
                </Col>
            </Col>
            <h3 className="awards-section-title">Ostali tereni</h3>
            <Col className="d-block d-md-flex flex-column flex-md-row mb-4">
                <Col className="d-flex mb-3 mb-md-0">
                    <Col className="align-self-end">
                        <Dropdown
                            dropdownLinks={surface_type}
                            placeholder="Tip"
                            value={formData?.otherCourts?.surface}
                            handleSelect={(e) =>
                                setSurfaceMain('otherCourts', e)
                            }
                            label={'Podloga'}
                        />
                    </Col>
                    <Col className="me-md-3 ms-3 align-self-end">
                        <TextField
                            label="Količina"
                            placeholder="Količina"
                            id="quantity"
                            name="otherCourts"
                            onChange={setField}
                            type={'number'}
                            value={formData?.otherCourts?.quantity}
                        />
                    </Col>
                </Col>
                <Col className="d-flex">
                    <Col className="me-3 align-self-end">
                        <TextField
                            label="Kvalitet (1-5)"
                            placeholder="Kvalitet (1-5)"
                            name="otherCourts"
                            id="quality"
                            onChange={setField}
                            type={'number'}
                            value={formData?.otherCourts?.quality}
                        />
                    </Col>
                    <Col className="align-self-end">
                        <TextField
                            label="Održavanje (1-5)"
                            placeholder="Održavanje (1-5)"
                            name="otherCourts"
                            id="maintenance"
                            onChange={setField}
                            type={'number'}
                            value={formData?.otherCourts?.maintenance}
                        />
                    </Col>
                </Col>
            </Col>
            <h3 className="awards-section-title">Reflektori</h3>
            <Col className="d-block d-md-flex flex-column flex-md-row mb-4">
                <Col className="d-flex mb-3 mb-md-0">
                    <Col className="align-self-end">
                        <Dropdown
                            dropdownLinks={['DA', 'NE']}
                            placeholder="Upotrebljavani"
                            value={formData?.reflectors?.used || ''}
                            handleSelect={(e) => setReflectorsFields('used', e)}
                            label={'Upotrebljavani'}
                        />
                    </Col>
                    <Col className="align-self-end">
                        <Dropdown
                            dropdownLinks={['DA', 'NE']}
                            placeholder="Žalbe"
                            value={formData?.reflectors?.complaint || ''}
                            handleSelect={(e) =>
                                setReflectorsFields('complaint', e)
                            }
                            label={'Žalbe na kvalitet'}
                            className="me-md-3 ms-3 px-0 complaint-wrapper"
                        />
                    </Col>
                    <Col className="d-none d-md-block align-self-end">
                        <Dropdown
                            dropdownLinks={['DA', 'NE']}
                            placeholder="Po pravilniku TSS-a"
                            value={formData?.reflectors?.rulebook || ''}
                            handleSelect={(e) =>
                                setReflectorsFields('rulebook', e)
                            }
                            label={'Po pravilniku TSS-a'}
                        />
                    </Col>
                </Col>
                <Col className="d-flex d-md-none">
                    <Col className="align-self-end">
                        <Dropdown
                            dropdownLinks={['DA', 'NE']}
                            placeholder="Po pravilniku TSS-a"
                            value={formData?.reflectors?.rulebook || ''}
                            handleSelect={(e) =>
                                setReflectorsFields('rulebook', e)
                            }
                            label={'Po pravilniku TSS-a'}
                        />
                    </Col>
                </Col>
            </Col>
            <h3 className="awards-section-title">Tereni za trening</h3>
            <Col className="d-block d-md-flex flex-column flex-md-row mb-4">
                <Col className="d-flex mb-3 mb-md-0">
                    <Col className="align-self-end">
                        <Dropdown
                            dropdownLinks={surface_type}
                            placeholder="Tip"
                            value={formData?.trainingCourts?.surface}
                            handleSelect={(e) =>
                                setSurfaceMain('trainingCourts', e)
                            }
                            label={'Podloga'}
                        />
                    </Col>
                    <Col className="me-md-3 ms-3 align-self-end">
                        <TextField
                            label="Količina"
                            placeholder="Količina"
                            id="quantity"
                            name="trainingCourts"
                            onChange={setField}
                            type={'number'}
                            value={formData?.trainingCourts?.quantity}
                        />
                    </Col>
                </Col>
                <Col className="d-flex">
                    <Col className="me-3 align-self-end">
                        <TextField
                            label="Kvalitet (1-5)"
                            placeholder="Kvalitet (1-5)"
                            name="trainingCourts"
                            id="quality"
                            onChange={setField}
                            type={'number'}
                            value={formData?.trainingCourts?.quality}
                        />
                    </Col>
                    <Col className="align-self-end">
                        <TextField
                            label="Održavanje (1-5)"
                            placeholder="Održavanje (1-5)"
                            name="trainingCourts"
                            id="maintenance"
                            onChange={setField}
                            type={'number'}
                            value={formData?.trainingCourts?.maintenance}
                        />
                    </Col>
                </Col>
            </Col>
            <Col xs={12} className="mb-4 align-self-end">
                <TextField
                    label={'Generalni izgled terena'}
                    placeholder="Generalni izgled"
                    value={formData?.trainingCourts?.generalAppearance}
                    name="trainingCourts"
                    id="generalAppearance"
                    onChange={setField}
                />
            </Col>
            <h3 className="awards-section-title">Lopte</h3>
            <Col className="d-block d-md-flex flex-column flex-md-row mb-3">
                <Col className="d-flex">
                    <Col className="align-self-end">
                        <TextField
                            label={'Proizvođač'}
                            placeholder="Proizvođač"
                            value={formData?.balls?.manufacturer}
                            name="balls"
                            id="manufacturer"
                            onChange={setField}
                        />
                    </Col>
                    <Col className="ms-3 align-self-end">
                        <TextField
                            label="Kvalitet (1-5)"
                            placeholder="Kvalitet (1-5)"
                            name="balls"
                            id="quality"
                            onChange={setField}
                            type={'number'}
                            value={formData?.balls?.quality}
                        />
                    </Col>
                    <Col className="ms-md-3 d-none d-md-block align-self-end">
                        <Dropdown
                            dropdownLinks={['DA', 'NE']}
                            placeholder="Odobrene od ITF"
                            value={formData?.balls?.rulebookITF || ''}
                            handleSelect={(e) =>
                                setBallsRulebookFields('rulebookITF', e)
                            }
                            label={'Odobrene od ITF'}
                        />
                    </Col>
                </Col>
            </Col>
            <Col
                xs={12}
                className="ms-md-3 mb-3 d-block d-md-none align-self-end"
            >
                <Dropdown
                    dropdownLinks={['DA', 'NE']}
                    placeholder="Odobrene od ITF"
                    value={formData?.balls?.rulebookITF || ''}
                    handleSelect={(e) =>
                        setBallsRulebookFields('rulebookITF', e)
                    }
                    label={'Odobrene od ITF'}
                />
            </Col>
            <Col
                xs={12}
                className="ms-md-3 mb-3 d-block d-md-none align-self-end"
            >
                <Col>
                    <TextField
                        label="Utrošena količina lopti na turniru"
                        placeholder="Količina"
                        name="balls"
                        id="usedBalls"
                        onChange={setField}
                        type={'number'}
                        value={formData?.balls?.usedBalls}
                    />
                </Col>
            </Col>
            <Col className="d-flex align-items-end" xs={12}>
                <Col className="me-3 d-none d-md-block">
                    <TextField
                        label="Utrošena količina lopti na turniru"
                        placeholder="Količina"
                        name="balls"
                        id="usedBalls"
                        onChange={setField}
                        type={'number'}
                        value={formData?.balls?.usedBalls}
                    />
                </Col>
                <Col className="align-self-end">
                    <TextField
                        label="Zamena lopti"
                        placeholder="Količina"
                        name="balls"
                        id="replacement"
                        onChange={setField}
                        type={'number'}
                        value={formData?.balls?.replacement}
                    />
                </Col>
                <Col className="ms-3 align-self-end">
                    <Dropdown
                        dropdownLinks={['DA', 'NE']}
                        placeholder="Po TSS-u"
                        value={formData?.balls?.rulebookTSS || ''}
                        handleSelect={(e) =>
                            setBallsRulebookFields('rulebookTSS', e)
                        }
                        label={'Po pravilniku TSS-a'}
                    />
                </Col>
            </Col>
            <h3 className="awards-section-title mt-4">Fizioterapeut</h3>
            <Col className="d-block d-md-flex flex-column flex-md-row mb-4">
                <Col className="d-flex mb-3 mb-md-0">
                    <Col className="align-self-end">
                        <TextField
                            label="Ime i prezime"
                            placeholder="Ime i prezime"
                            id="name"
                            name="physiotherapist"
                            onChange={setField}
                            value={formData?.physiotherapist?.name}
                        />
                    </Col>
                    <Col className="me-md-3 ms-3 align-self-end">
                        <TextField
                            label={'Poznavanje pravila'}
                            placeholder="Poznavanje"
                            value={formData?.physiotherapist?.rulesKnowledge}
                            name="physiotherapist"
                            id="rulesKnowledge"
                            onChange={setField}
                        />
                    </Col>
                </Col>
                <Col className="d-flex">
                    <Col className="me-3 align-self-end">
                        <TextField
                            label="Specijalizacija"
                            placeholder="Specijalizacija"
                            id="specialization"
                            name="physiotherapist"
                            onChange={setField}
                            value={formData?.physiotherapist?.specialization}
                        />
                    </Col>
                    <Col className="align-self-end">
                        <TextField
                            label="Na turnir poziva"
                            placeholder="Na turnir poziva"
                            id="invite"
                            name="physiotherapist"
                            onChange={setField}
                            value={formData?.physiotherapist?.invite}
                        />
                    </Col>
                </Col>
            </Col>
        </Row>
    )
}

export default CourtAndBalls
