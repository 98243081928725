import jsPDF from 'jspdf'
import { useEffect, useState } from 'react'
import { Breadcrumb, Col, Form, Row, Tab, Tabs } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import DocumentTextOutlined from '../../../../assets/svg/DocumentTextOutlined'
import PrintOutlined from '../../../../assets/svg/PrintOutlined'
import Button from '../../../../components/Button'
import CompetitorsRankingCard from '../../../../components/CompetitorsRankingCard'
import CompetitorsRankingTable from '../../../../components/CompetitorsRankingTable'
import { RowsPerPageOptions } from '../../../../components/RowsPerPageDropdown'
import {
    getPlayersClub,
    getPlayersForRankingList,
    getSingleClub,
    getSingleCompetitorsRankingList,
    getSinglePlayer,
    updatePlayersRanking,
    filterPlayersInRankingList,
} from '../../../../lib/api'
import { useAppContext } from '../../../../lib/contextLib'
import { formatDate } from '../../../../lib/helpers'
import { font } from '../../Competitions/CompetitionProfile/font'
import UpdateRankingListModal from './updateRankingListModal'
import FiltersOffcanvas from './filtersOffcanvas'

const CompetitorsRankingByCategory = () => {
    const { user, setIsLoading } = useAppContext()
    const { id } = useParams()
    const [singleRankingList, setSingleRankingList] = useState()
    const [playersList, setPlayersList] = useState([])
    const [showUpdateRankingListModal, setShowUpdateRankingListModal] =
        useState(false)
    const [isManualPointsChangeEnabled, setIsManualPointsChangeEnabled] =
        useState(false)

    const [currentPage, setCurrentPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [total, setTotal] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(RowsPerPageOptions[0])

    const [showFilters, setShowFilters] = useState(false)

    const [filterParams, setFilterParams] = useState(null)

    const [activeTab, setActiveTab] = useState('full-year')

    const handleGetSingleRankingList = async () => {
        setIsLoading(true)
        const rankingList = await getSingleCompetitorsRankingList(id)

        if (rankingList) {
            setSingleRankingList(rankingList)
        }
        setIsLoading(false)
    }

    const handleGetPlayersList = async ({
        allEntries = false,
        removedFilters = false,
    } = {}) => {
        try {
            setIsLoading(true)
            const { players, pgCount, total } = await getPlayersForRankingList(
                id,
                allEntries ? 1 : currentPage,
                activeTab === 'current-year' ? true : false,
                allEntries,
                rowsPerPage,
                removedFilters ? {} : filterParams
            )

            if (!players) {
                return
            }

            const promises = players?.map(async (player) => {
                const uuid = player?.attributes?.player_uuid

                if (!uuid) return null

                const singlePlayer = await getSinglePlayer(uuid)

                if (!singlePlayer) return null

                const playersClub = await getPlayersClub(uuid)

                const clubInfo = playersClub
                    ? await getSingleClub(playersClub?.attributes?.club_uuid)
                    : null

                return {
                    ...player,
                    player_data: {
                        ...singlePlayer,
                    },
                    club: clubInfo ? clubInfo : null,
                }
            })
            setIsLoading(true)
            const updatedList = await Promise.all(promises)

            if (updatedList) {
                if (allEntries) {
                    return updatedList
                } else {
                    setPageCount(pgCount)
                    setTotal(total)
                    setPlayersList(updatedList)
                }
            }
        } catch (error) {
            setIsLoading(false)
            console.error('An error occurred:', error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleUpdatePlayersRating = async () => {
        setIsLoading(true)
        try {
            await Promise.all(
                playersList?.map((playerFromList) => {
                    const uuid = playerFromList?.attributes?.uuid
                    const total_points =
                        playerFromList?.attributes?.total_points
                    if (!uuid || total_points === undefined) {
                        return Promise.resolve()
                    }
                    return updatePlayersRanking(uuid, { total_points })
                })
            )
            handleGetPlayersList()

            setIsManualPointsChangeEnabled(false)
            setShowUpdateRankingListModal(true)
        } catch (error) {
            setIsLoading(false)
            console.error(
                'Došlo je do greške prilikom ažuriranja igrača:',
                error
            )
        }
        setIsLoading(false)
    }

    const tabNameHelper = (tab) => {
        switch (tab) {
            case 'full-year':
                return 'Poslednjih 365 dana'
            case 'current-year':
                return 'Tekuća godina'
            default:
                return 'Poslednjih 365 dana'
        }
    }

    const handlePDF = async (print = false) => {
        const filteredPlayers = await handleGetPlayersList({ allEntries: true })
        setIsLoading(true)
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'portrait' // portrait or landscape

        const marginLeft = 40
        const marginTop = 40
        const doc = new jsPDF(orientation, unit, size)
        doc.addFileToVFS('WorkSans-normal.ttf', font)
        doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
        doc.setFont('WorkSans')
        doc.setFontSize(15)

        const rankingListName = singleRankingList?.attributes?.name
        doc.text(rankingListName, marginLeft, marginTop)
        const rankingListCompetition =
            singleRankingList?.attributes?.players_competition
                ?.players_competition_name
        const rankingListCompetitionSubcategory =
            singleRankingList?.attributes?.players_competition
                ?.players_competition_subcategory_name
        const competitionText = `Konkurencija: ${rankingListCompetition}${
            rankingListCompetitionSubcategory
                ? ' ' + rankingListCompetitionSubcategory
                : ''
        }`
        doc.text(competitionText, marginLeft, marginTop + 20)
        const rankingListGender =
            singleRankingList?.attributes?.gender?.gender_name
        const genderText = `Pol: ${rankingListGender}`
        doc.text(genderText, marginLeft, marginTop + 40)

        const title = `Takmičari - ${tabNameHelper(activeTab)} - Ukupno: ${
            filteredPlayers?.length
        }, ${formatDate(new Date())}`
        doc.text(title, marginLeft, marginTop + 60)

        const rows = []

        for (let i = 1; i <= filteredPlayers?.length; i++) {
            const player = filteredPlayers[i - 1]
            rows.push([
                i,
                player?.player_data?.attributes.person_info?.given_name +
                    ' ' +
                    player?.player_data?.attributes?.person_info?.family_name,
                player?.club?.attributes?.name
                    ? player?.club?.attributes?.name
                    : 'Nema informacije',
                player?.player_data?.attributes?.person_info?.birthdate
                    ? `${formatDate(
                          player?.player_data?.attributes?.person_info
                              ?.birthdate
                      )}`
                    : 'Nema informacije',
                player?.attributes?.total_points
                    ? player?.attributes?.total_points
                    : player?.attributes?.total_points === 0
                    ? player?.attributes?.total_points
                    : 'Nema informacije',
            ])
        }

        let competitorsContent = {
            startY: marginTop + 80,
            head: [['Pozicija', 'Takmičar', 'Klub', 'Datum rođenja', 'Bodovi']],
            body: rows,
            styles: {
                font: 'WorkSans',
            },
        }

        doc.autoTable(competitorsContent)

        const docFileName = `${
            singleRankingList?.attributes?.name
                ? singleRankingList?.attributes?.name
                : 'Rang-lista'
        }-${tabNameHelper(activeTab)}-${formatDate(new Date()).slice(
            0,
            -1
        )}.pdf`

        if (print) {
            doc.autoPrint()
            var blob = doc.output('blob')
            window.open(URL.createObjectURL(blob), '_blank')
        } else {
            doc.save(docFileName)
        }

        setIsLoading(false)
    }

    const handlePrint = async () => {
        await handlePDF(true)
    }

    const handleFilters = async () => {
        const { players, pgCount, total } = await filterPlayersInRankingList(
            id,
            filterParams,
            currentPage,
            rowsPerPage
        )
        if (!players) {
            return
        }

        const promises = players?.map(async (player) => {
            const uuid = player?.attributes?.player_uuid

            if (!uuid) return null

            const singlePlayer = await getSinglePlayer(uuid)

            if (!singlePlayer) return null

            const playersClub = await getPlayersClub(uuid)

            const clubInfo = playersClub
                ? await getSingleClub(playersClub?.attributes?.club_uuid)
                : null

            return {
                ...player,
                player_data: {
                    ...singlePlayer,
                },
                club: clubInfo ? clubInfo : null,
            }
        })
        setIsLoading(true)
        const updatedList = await Promise.all(promises)

        if (updatedList) {
            setIsLoading(false)
            setShowFilters(false)
            setPlayersList(updatedList)
            setTotal(total)
            setPageCount(pgCount)
        }
    }

    // Remove filters
    const handleRemoveFilters = () => {
        setFilterParams(null)
        setShowFilters(false)
        handleGetPlayersList({ removedFilters: true })
        setCurrentPage(1)
        setPageCount(1)
    }

    useEffect(() => {
        if (id) {
            handleGetSingleRankingList()
        }
    }, [id])

    useEffect(() => {
        if (filterParams) {
            handleFilters()
        } else {
            handleGetPlayersList()
        }
    }, [currentPage, activeTab, rowsPerPage])
    return (
        <Row className="competitors-wrapper h-100">
            <Col md={12}>
                <Row className="pt-3">
                    <Col
                        xs={12}
                        md={4}
                        className="d-flex align-items-center justify-content-between"
                    >
                        <Row>
                            <Col>
                                <h2 className="d-none d-md-block">
                                    Rang lista -{' '}
                                    {singleRankingList?.attributes?.name}
                                </h2>
                                <p className="d-block d-md-none">Rang liste</p>
                                <Breadcrumb className="mt-md-2 mt-1">
                                    <Breadcrumb.Item
                                        linkAs={Link}
                                        linkProps={{
                                            to:
                                                user?.role?.type !==
                                                'competitor'
                                                    ? '/pocetna'
                                                    : '/takmicar/pocetna',
                                        }}
                                    >
                                        Početna
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item
                                        linkAs={Link}
                                        linkProps={{
                                            to:
                                                user?.role?.type !==
                                                'competitor'
                                                    ? '/rang-lista-takmicara'
                                                    : '/takmicar/rang-lista-takmicara',
                                        }}
                                    >
                                        Rang liste
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item active>
                                        Takmičara
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>
                    </Col>

                    {user?.role?.type !== 'competitor' && (
                        <Col xs={12} md={8}>
                            <Row>
                                <Col className="d-flex justify-content-end flex-column flex-md-row">
                                    <Button
                                        type="text"
                                        leadingIcon={
                                            <DocumentTextOutlined height="24px" />
                                        }
                                        onClick={() => handlePDF()}
                                        disabled={!playersList?.length}
                                    >
                                        Eksportuj u PDF
                                    </Button>
                                    <Button
                                        type="secondary"
                                        leadingIcon={
                                            <PrintOutlined height="24px" />
                                        }
                                        onClick={handlePrint}
                                        disabled={!playersList?.length}
                                        className="ms-md-2 mt-2 mt-md-0"
                                    >
                                        Štampaj
                                    </Button>
                                    {isManualPointsChangeEnabled ? (
                                        <Button
                                            className="ms-md-2 mt-2 mt-md-0"
                                            onClick={() =>
                                                handleUpdatePlayersRating()
                                            }
                                        >
                                            Sačuvaj
                                        </Button>
                                    ) : (
                                        <></>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    )}
                </Row>
                <Row className="mt-3">
                    <Col>
                        {user?.role?.type === 'admin' && (
                            <Row>
                                <Col className="d-flex flex-column flex-md-row justify-content-between align-items-center">
                                    <Form.Switch
                                        name="competition-finished-switch"
                                        label={'Omogući manuelnu izmenu bodova'}
                                        checked={isManualPointsChangeEnabled}
                                        onChange={() => {
                                            setIsManualPointsChangeEnabled(
                                                (prev) => !prev
                                            )
                                        }}
                                        disabled={
                                            user?.role?.type === 'competitor'
                                                ? true
                                                : false
                                        }
                                    />

                                    {!singleRankingList?.attributes?.region
                                        ?.region_name && (
                                        <Button
                                            type="secondary"
                                            onClick={() => setShowFilters(true)}
                                        >
                                            Filtriraj
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        )}
                        <Row className="pt-3 m-0">
                            <Col className="p-0 tabular-navigation">
                                <Tabs
                                    defaultActiveKey="full-year"
                                    id="uncontrolled-tab-example"
                                    activeKey={activeTab}
                                    onSelect={(tab) => setActiveTab(tab)}
                                >
                                    <Tab
                                        eventKey="full-year"
                                        title="Poslednjih 365 dana"
                                    >
                                        <Row className="mt-3">
                                            <Col className="d-none d-md-block">
                                                <CompetitorsRankingTable
                                                    data={playersList}
                                                    currentPage={currentPage}
                                                    setCurrentPage={
                                                        setCurrentPage
                                                    }
                                                    pageCount={pageCount}
                                                    total={total}
                                                    isManualPointsChangeEnabled={
                                                        isManualPointsChangeEnabled
                                                    }
                                                    setPlayersList={
                                                        setPlayersList
                                                    }
                                                    rowsPerPage={rowsPerPage}
                                                    setRowsPerPage={
                                                        setRowsPerPage
                                                    }
                                                />
                                            </Col>

                                            <Col className="d-md-none">
                                                {playersList?.map(
                                                    (player, index) => (
                                                        <CompetitorsRankingCard
                                                            player={player}
                                                            key={index}
                                                            isWhite={
                                                                index % 2 !== 0
                                                                    ? true
                                                                    : false
                                                            }
                                                            index={index}
                                                        />
                                                    )
                                                )}
                                            </Col>
                                        </Row>
                                    </Tab>
                                    <Tab
                                        eventKey="current-year"
                                        title="Tekuća godina"
                                    >
                                        <Row className="mt-3">
                                            <Col className="d-none d-md-block">
                                                <CompetitorsRankingTable
                                                    data={playersList}
                                                    currentPage={currentPage}
                                                    setCurrentPage={
                                                        setCurrentPage
                                                    }
                                                    pageCount={pageCount}
                                                    total={total}
                                                    isManualPointsChangeEnabled={
                                                        isManualPointsChangeEnabled
                                                    }
                                                    setPlayersList={
                                                        setPlayersList
                                                    }
                                                    rowsPerPage={rowsPerPage}
                                                    setRowsPerPage={
                                                        setRowsPerPage
                                                    }
                                                />
                                            </Col>

                                            <Col className="d-md-none">
                                                {playersList?.map(
                                                    (player, index) => (
                                                        <CompetitorsRankingCard
                                                            player={player}
                                                            key={index}
                                                            isWhite={
                                                                index % 2 !== 0
                                                                    ? true
                                                                    : false
                                                            }
                                                            index={index}
                                                        />
                                                    )
                                                )}
                                            </Col>
                                        </Row>
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>

            <UpdateRankingListModal
                onHide={() => setShowUpdateRankingListModal(false)}
                show={showUpdateRankingListModal}
            />

            <FiltersOffcanvas
                show={showFilters}
                onHide={() => setShowFilters(false)}
                setFilterParams={setFilterParams}
                filterParams={filterParams}
                handleFilters={handleFilters}
                handleRemoveFilters={handleRemoveFilters}
            />
        </Row>
    )
}

export default CompetitorsRankingByCategory
