import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { formatDate } from '../../../lib/helpers'
import './styles.scss'

function MembershipCard({
    membership,
    className,
    isWhite,
    isAdmin = false,
    setShowConfirmationModal,
}) {
    return (
        <Row
            className={`membership-card-wrapper g-3 ${className} ${
                isWhite ? 'white-membership-card' : 'grey-membership-card'
            }`}
        >
            <Col xs={6} className="mt-3 d-flex align-items-center">
                <h5>Datum poslednje registracije</h5>
            </Col>
            <Col xs={6} className="d-flex align-items-center p-0 mt-3">
                {formatDate(membership?.attributes?.date_of_payment)}
            </Col>
            {isAdmin && (
                <>
                    <hr className="table-card-divider" />
                    <Col xs={12} className="m-0 mb-3 d-flex align-items-center">
                        <p
                            className="red-link link-align-end"
                            onClick={() =>
                                setShowConfirmationModal((_) => {
                                    return
                                    membership?.attributes?.uuid
                                })
                            }
                        >
                            Ukloni
                        </p>
                    </Col>
                </>
            )}
        </Row>
    )
}

export default MembershipCard
