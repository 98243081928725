import React, { useEffect, useState } from 'react'
import { Row, Col, Modal } from 'react-bootstrap'
import Dropdown from '../../../../../../components/Dropdown'
import {
    handleDrawTypesByTournamentCategory,
    handleTournamentSubcategories,
} from '../../../../../../lib/helpers'
import Button from '../../../../../../components/Button'
import {
    autoGenerateDraw,
    autoGenerateTeamDraw,
} from '../../../../../../lib/api'
import { useAppContext } from '../../../../../../lib/contextLib'

const DropDownSection = ({
    drawToShow,
    setDrawToShow,
    className,
    competitionProfileData,
    handleShowSlider,
}) => {
    const [showAutoDrawModal, setShowAutoDrawModal] = useState(false)
    const { setIsLoading, user, setShowCustomToast } = useAppContext()
    const handleAutoGenerateDraw = async () => {
        try {
            if (
                competitionProfileData?.attributes?.uuid &&
                drawToShow?.gender?.gender_name
            ) {
                setIsLoading(true)
                let newDraw
                if (
                    competitionProfileData?.attributes?.tournament_type
                        ?.tournament_type_name === 'Ekipno'
                ) {
                    newDraw = await autoGenerateTeamDraw(
                        competitionProfileData?.attributes?.uuid
                    )
                } else {
                    newDraw = await autoGenerateDraw(
                        competitionProfileData?.attributes?.uuid,
                        drawToShow?.gender?.gender_name
                    )
                }

                if (!newDraw) return

                setShowAutoDrawModal(false)
                handleShowSlider(drawToShow)

                setIsLoading(false)
                setShowCustomToast({
                    show: true,
                    type: 'success',
                    message: 'Uspešno kreiran žreb',
                })
            }
        } catch (error) {
            setShowCustomToast({
                show: true,
                type: 'error',
                message: 'Greška prilikom kreiranja žreba',
            })
            console.error(error)
        } finally {
            setShowAutoDrawModal(false)
            setIsLoading(false)
        }
    }
    useEffect(() => {
        if (drawToShow?.draw_type?.draw_type_name === 'Srbija Gran Pri') {
            setDrawToShow((prev) => ({
                ...prev,
                subtournament_name: handleTournamentSubcategories(
                    competitionProfileData?.attributes
                        ?.participants_expected_number
                )[0],
            }))
        }

        if (competitionProfileData?.attributes?.legacy_id) {
            setDrawToShow((prev) => ({
                ...prev,
                draw_type: {
                    draw_type_name: 'Glavni turnir',
                },
                legacy_round_number: 1,
            }))
        }
    }, [competitionProfileData])
    return (
        <Row className={`${className} gy-2`}>
            {competitionProfileData?.attributes
                ?.doubles_pairs_expected_number && (
                <Col xs={12} md={3}>
                    <Dropdown
                        label={'Singl/dubl'}
                        placeholder="Singl/dubl"
                        dropdownLinks={['Singl', 'Dubl']}
                        value={drawToShow?.is_double ? 'Dubl' : 'Singl'}
                        handleSelect={(e) =>
                            e === 'Singl'
                                ? setDrawToShow((prev) => ({
                                      ...prev,
                                      is_double: false,
                                  }))
                                : setDrawToShow((prev) => ({
                                      ...prev,
                                      is_double: true,
                                  }))
                        }
                    />
                </Col>
            )}
            {competitionProfileData?.attributes?.genders?.length > 1 &&
                competitionProfileData?.attributes?.tournament_type
                    ?.tournament_type_name === 'Individualno' && (
                    <Col xs={12} md={3}>
                        <Dropdown
                            label={'Izaberite pol'}
                            placeholder="Izaberite pol"
                            dropdownLinks={competitionProfileData?.attributes?.genders?.map(
                                (gender) => {
                                    return gender?.gender_name
                                }
                            )}
                            value={drawToShow?.gender?.gender_name}
                            handleSelect={(e) =>
                                setDrawToShow((prev) => ({
                                    ...prev,
                                    gender: {
                                        gender_name: e,
                                    },
                                }))
                            }
                        />
                    </Col>
                )}
            <Col xs={12} md={3}>
                <Dropdown
                    label={'Izaberite žreb'}
                    placeholder="Izaberite žreb"
                    dropdownLinks={handleDrawTypesByTournamentCategory(
                        competitionProfileData
                    )}
                    value={drawToShow?.draw_type?.draw_type_name}
                    handleSelect={(e) =>
                        setDrawToShow((prev) => ({
                            ...prev,
                            qualifying_section_number:
                                e === 'Kvalifikacije' ? 1 : null,
                            draw_type: {
                                draw_type_name: e,
                            },
                        }))
                    }
                />
            </Col>

            {competitionProfileData?.attributes?.legacy_id && (
                <Col xs={12} md={3}>
                    <Dropdown
                        label={'Izaberite kolo'}
                        placeholder="Izaberite kolo"
                        dropdownLinks={[1, 2, 3, 4, 5, 6, 7, 8]}
                        value={drawToShow?.legacy_round_number}
                        handleSelect={(e) =>
                            setDrawToShow((prev) => ({
                                ...prev,
                                legacy_round_number: +e,
                            }))
                        }
                    />
                </Col>
            )}

            {drawToShow?.draw_type?.draw_type_name === 'Srbija Gran Pri' && (
                <Col xs={12} md={3}>
                    <Dropdown
                        label={'Grupa'}
                        placeholder="Izaberi grupu"
                        dropdownLinks={handleTournamentSubcategories(
                            competitionProfileData?.attributes
                                ?.participants_expected_number
                        )}
                        value={drawToShow?.subtournament_name}
                        handleSelect={(e) =>
                            setDrawToShow((prev) => ({
                                ...prev,
                                subtournament_name: e,
                            }))
                        }
                    />
                </Col>
            )}
            {drawToShow?.draw_type?.draw_type_name === 'Kvalifikacije' && (
                <Col xs={12} md={3}>
                    <Dropdown
                        label={'Kvalifikaciona sekcija'}
                        placeholder="Izaberite kvalifikacionu sekciju"
                        dropdownLinks={
                            competitionProfileData?.attributes
                                ?.number_of_qualified &&
                            Array.from(
                                {
                                    length: +competitionProfileData?.attributes
                                        ?.number_of_qualified,
                                },
                                (_, index) => index + 1
                            )
                        }
                        value={drawToShow?.qualifying_section_number}
                        handleSelect={(e) =>
                            setDrawToShow((prev) => ({
                                ...prev,
                                qualifying_section_number: +e,
                            }))
                        }
                    />
                </Col>
            )}

            {drawToShow?.draw_type?.draw_type_name === 'Glavni turnir' &&
                drawToShow?.is_double === false &&
                user?.role?.type !== 'competitor' && (
                    <Col xs={12} md={3} className="d-flex align-items-end">
                        <Button
                            type="secondary"
                            onClick={() => setShowAutoDrawModal(true)}
                        >
                            Kreiraj žreb
                        </Button>
                    </Col>
                )}

            <Modal
                size="md"
                centered
                show={showAutoDrawModal}
                onHide={() => setShowAutoDrawModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>Kreiranje žreba</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Da li ste sigurni da želite da automatski kreirate žreb
                        za prvo kolo turnira?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="text"
                        onClick={() => setShowAutoDrawModal(false)}
                    >
                        Odustani
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            handleAutoGenerateDraw()
                        }}
                    >
                        Kreiraj
                    </Button>
                </Modal.Footer>
            </Modal>
        </Row>
    )
}

export default DropDownSection
