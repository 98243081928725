import { Col, Row } from 'react-bootstrap'
import Dropdown from '../../../../../components/Dropdown'
import RadioButton from '../../../../../components/RadioButton'
import TextField from '../../../../../components/TextField'
import { numbersOnly } from '../../../../../lib/helpers'

const noEntriesTSS = ['Prvih 20', 'Prvih 50', 'Prvih 100']

const noEntriesITF = ['Prvih 20', 'Prvih 50', 'Prvih 100']

const noEntriesETA = ['Prvih 20', 'Prvih 50', 'Prvih 100']

const StepTwo = ({
    form,
    additionalInformationsForm,
    setAdditionalInformationsForm,
    setForm,
}) => {
    return (
        <Row className="create-new-competition-form-wrapper step-two">
            <Col md={12} className="mt-2">
                <RadioButton
                    name={'Stranci'}
                    radioValues={['Da', 'Ne']}
                    radioHiddenValues={[true, false]}
                    className="type-radio-buttons-wrapper"
                    onChange={(e) => {
                        setAdditionalInformationsForm((prevState) => ({
                            ...prevState,
                            are_foreigners_allowed: JSON.parse(e.target.value),
                        }))
                    }}
                    checkedValue={
                        additionalInformationsForm?.are_foreigners_allowed
                            ? 'Da'
                            : additionalInformationsForm?.are_foreigners_allowed ===
                              false
                            ? 'Ne'
                            : ''
                    }
                />
            </Col>
            {form?.tournament_type?.tournament_type_name === 'Ekipno' && (
                <>
                    <Col md={12} className="mt-3">
                        <TextField
                            type="tel"
                            name="max_number_of_strangers_registration"
                            placeholder="Broj stranaca koji se mogu prijaviti"
                            label="Broj stranaca koji se mogu prijaviti"
                            onChange={(e) => {
                                numbersOnly(e)
                                setAdditionalInformationsForm((prevState) => ({
                                    ...prevState,
                                    max_number_of_registered_strangers:
                                        +e.target.value,
                                }))
                            }}
                            value={
                                additionalInformationsForm?.max_number_of_strangers_registration ||
                                ''
                            }
                        />
                    </Col>
                    <Col md={12} className="mt-3">
                        <TextField
                            type="tel"
                            name="max_number_of_strangers_per_match"
                            placeholder="Broj stranaca po meču"
                            label="Broj stranaca po meču"
                            onChange={(e) => {
                                numbersOnly(e)
                                setAdditionalInformationsForm((prevState) => ({
                                    ...prevState,
                                    max_number_of_strangers_per_match:
                                        +e.target.value,
                                }))
                            }}
                            value={
                                additionalInformationsForm?.max_number_of_strangers_per_match ||
                                ''
                            }
                        />
                    </Col>
                </>
            )}

            <Col md={12} className="mt-3">
                <RadioButton
                    name={'Samo iz regiona'}
                    radioValues={['Da', 'Ne']}
                    radioHiddenValues={[true, false]}
                    className="type-radio-buttons-wrapper"
                    onChange={(e) => {
                        setAdditionalInformationsForm((prevState) => ({
                            ...prevState,
                            is_from_region_only: JSON.parse(e.target.value),
                        }))
                    }}
                    checkedValue={
                        additionalInformationsForm?.is_from_region_only
                            ? 'Da'
                            : additionalInformationsForm?.is_from_region_only ===
                              false
                            ? 'Ne'
                            : ''
                    }
                />
            </Col>
            {form?.tournament_type?.tournament_type_name === 'Ekipno' && (
                <>
                    <Col md={12} className="mt-3">
                        <TextField
                            type="tel"
                            name="max_number_of_loans"
                            placeholder="Broj pozajmica"
                            label="Broj pozajmica"
                            onChange={(e) => {
                                numbersOnly(e)
                                setAdditionalInformationsForm((prevState) => ({
                                    ...prevState,
                                    max_number_of_loans: +e.target.value,
                                }))
                            }}
                            value={
                                additionalInformationsForm?.max_number_of_loans ||
                                ''
                            }
                        />
                    </Col>
                    <Col md={12} className="mt-3">
                        <TextField
                            type="tel"
                            name="max_number_of_loans_per_match"
                            placeholder="Maksimalno pozajmljenih po meču"
                            label="Maksimalno pozajmljenih po meču"
                            onChange={(e) => {
                                numbersOnly(e)
                                setAdditionalInformationsForm((prevState) => ({
                                    ...prevState,
                                    max_number_of_loans_per_match:
                                        +e.target.value,
                                }))
                            }}
                            value={
                                additionalInformationsForm?.max_number_of_loans_per_match ||
                                ''
                            }
                        />
                    </Col>
                </>
            )}
            {form?.tournament_type?.tournament_type_name === 'Individualno' && (
                <>
                    <Col md={12} className="mt-3">
                        <Dropdown
                            label={`Ne mogu učestvovati (sa TSS liste)`}
                            placeholder="Izaberi..."
                            dropdownLinks={noEntriesTSS}
                            handleSelect={(e) => {
                                setAdditionalInformationsForm((prevState) => ({
                                    ...prevState,
                                    tss_rank_prohibition: e,
                                }))
                            }}
                            value={
                                additionalInformationsForm?.tss_rank_prohibition ||
                                ''
                            }
                        />
                    </Col>
                    <Col md={12} className="mt-3">
                        <Dropdown
                            label={`Ne mogu učestvovati (sa ITF liste)`}
                            placeholder="Izaberi..."
                            dropdownLinks={noEntriesITF}
                            handleSelect={(e) => {
                                setAdditionalInformationsForm((prevState) => ({
                                    ...prevState,
                                    itf_rank_prohibition: e,
                                }))
                            }}
                            value={
                                additionalInformationsForm?.itf_rank_prohibition ||
                                ''
                            }
                        />
                    </Col>
                    <Col md={12} className="mt-3">
                        <Dropdown
                            label={`Ne mogu učestvovati (sa ETA liste)`}
                            placeholder="Izaberi..."
                            dropdownLinks={noEntriesETA}
                            handleSelect={(e) => {
                                setAdditionalInformationsForm((prevState) => ({
                                    ...prevState,
                                    eta_rank_prohibition: e,
                                }))
                            }}
                            value={
                                additionalInformationsForm?.eta_rank_prohibition ||
                                ''
                            }
                        />
                    </Col>
                </>
            )}
        </Row>
    )
}

export default StepTwo
