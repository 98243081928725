import React from 'react'
import { Row, Col } from 'react-bootstrap'
import TextField from '../TextField'
import { numbersOnly } from '../../lib/helpers'
import './styles.scss'

const CardResultSection = ({
    resultData,
    setResultData,
    oppositeSideResult,
    isEditable,
}) => {
    const handleSetResult = (e, index) => {
        setResultData((prev) => {
            const newResult = [...prev.result]
            newResult[index] = +e.target.value
            return { ...prev, result: newResult }
        })
    }

    const handleGamesDisplay = () => {
        if (isEditable) {
            return (
                resultData?.result?.map((result, index) => (
                    <TextField
                        key={index}
                        type="tel"
                        className="games-result-number-text-field"
                        min={0}
                        value={result?.toString()}
                        pattern="[0-9]*"
                        onChange={(e) => {
                            numbersOnly(e)
                            handleSetResult(e, index)
                        }}
                    />
                )) || []
            )
        } else {
            return (
                resultData?.result?.map((result, index) => (
                    <div key={index} className="games-result-number">
                        <p>{result}</p>
                    </div>
                )) || []
            )
        }
    }

    const handleSetsNumber = () => {
        if (!resultData?.result || !oppositeSideResult?.result) {
            return 0
        }

        return resultData?.result?.reduce(
            (acc, result, index) => {
                if (result > oppositeSideResult?.result[index]) {
                    acc.wonSetsNumber++
                } else if (result < oppositeSideResult?.result[index]) {
                    acc.lostSetsNumber++
                }
                return acc
            },
            { wonSetsNumber: 0, lostSetsNumber: 0 }
        )
    }

    return (
        <Row className="result-wrapper">
            <Col className="d-flex px-0 align-items-center">
                <div
                    className={`sets-result-number ${
                        handleSetsNumber()?.wonSetsNumber >
                        handleSetsNumber()?.lostSetsNumber
                            ? 'green-indicator'
                            : 'red-indicator'
                    }`}
                >
                    <h4>{handleSetsNumber()?.wonSetsNumber}</h4>
                </div>
                {handleGamesDisplay()}

                {resultData?.is_wo && <p className="ms-2">W.O.</p>}
                {resultData?.is_ret && <p className="ms-2">RET</p>}
            </Col>
        </Row>
    )
}

export default CardResultSection
