import { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import CloseOutlined from '../../../../../assets/svg/CloseOutlined'
import Button from '../../../../../components/Button'
import TextField from '../../../../../components/TextField'
import { playersClubDeparture, getPlayersClub } from '../../../../../lib/api'

const DeparturePlayer = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [playersClubsData, setPlayersClubsData] = useState()

    const [departureDate, setSepartureDate] = useState(null)

    const handlePlayersClub = async () => {
        const clubData = await getPlayersClub(id)

        if (clubData) {
            setPlayersClubsData(clubData)
        }
    }

    //navigate to competitor profile
    const handleClose = () => {
        navigate(`/takmicari/${id}`)
    }

    const handlePlayerDeparture = async () => {
        const registration = await playersClubDeparture(
            playersClubsData?.attributes?.uuid,
            departureDate
        )

        if (registration) {
            handleClose()
        }
    }

    useEffect(() => {
        handlePlayersClub()
    }, [])

    return (
        <Row className="competitor-modal">
            <Col>
                <Row className="my-4 me-md-3">
                    <Col className="d-flex align-items-center justify-content-end">
                        <CloseOutlined
                            className="close-icon"
                            onClick={handleClose}
                        />
                    </Col>
                </Row>

                <Row className="competitor-form-wrapper justify-content-center">
                    <Col md={4}>
                        <h1 className="text-center mb-2">Ispiši takmičara</h1>

                        <Row>
                            <Col>
                                <TextField
                                    required
                                    className="mb-3"
                                    type="date"
                                    label="Datum ispisa"
                                    value={departureDate || ''}
                                    onChange={(e) =>
                                        setSepartureDate(e.target.value)
                                    }
                                />

                                <Row className="mt-4">
                                    <Col className="d-flex justify-content-end">
                                        <Button
                                            type="text"
                                            className="me-2"
                                            onClick={handleClose}
                                        >
                                            Odustani
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                handlePlayerDeparture()
                                            }
                                            disabled={
                                                departureDate ? false : true
                                            }
                                        >
                                            Ispiši
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default DeparturePlayer
