import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { numbersOnly } from '../../lib/helpers'
import TextField from '../TextField'
import './styles.scss'

const CardResultSection = ({ resultData, setResultData, isEditable }) => {
    const handleGamesDisplay = () => {
        if (isEditable) {
            return (
                <TextField
                    type="tel"
                    className="games-result-number-text-field"
                    min={0}
                    value={resultData?.toString()}
                    pattern="[0-9]*"
                    onChange={(e) => {
                        numbersOnly(e)
                        setResultData(+e.target.value)
                    }}
                />
            )
        } else {
            return (
                <div className="games-result-number">
                    <p>{resultData}</p>
                </div>
            )
        }
    }
    return (
        <Row className="result-wrapper">
            <Col className="d-flex px-0 align-items-center">
                <div className={`sets-result-number`}>
                    {handleGamesDisplay()}
                </div>
            </Col>
        </Row>
    )
}

export default CardResultSection
