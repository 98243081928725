import React from 'react'
import ClubList from '../../../components/ClubList'

import './styles.scss'

const Clubs = () => {
    const creatingClubHandler = () => {
        return <ClubList />
    }

    return <>{creatingClubHandler()}</>
}

export default Clubs
