import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './styles.scss'

const ProfileInfo = (props) => {
    const { avatar, name, surname, email, alt } = props

    return (
        <Row className="user-card-wrapper ps-3 pe-3 pt-2 pb-2">
            <Col className="d-flex align-items-center p-1">
                <img
                    src={avatar}
                    className="me-3 profile-info-image"
                    alt={alt}
                />

                <div>
                    <p>{`${name} ${surname}`}</p>
                    <p className="small-text email">{email}</p>
                </div>
            </Col>
        </Row>
    )
}

export default ProfileInfo
