import React, { useState } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import Button from '../../../../../../components/Button'
import TextField from '../../../../../../components/TextField'
import { createSpecialPlayer } from '../../../../../../lib/api'
import RadioButton from '../../../../../../components/RadioButton'

const AddSpecialPlayerModal = ({
    show,
    onHide,
    tournament,
    handleGetPlayersForTournament,
}) => {
    const [playerData, setPlayerData] = useState({})
    const handleCreateSpecialPlayer = async () => {
        const newSpecialPlayer = await createSpecialPlayer(
            tournament?.attributes?.uuid,
            playerData
        )

        if (newSpecialPlayer) {
            onHide()
            setPlayerData({})
            handleGetPlayersForTournament()
        }
    }
    return (
        <Modal
            size="lg"
            centered
            show={show}
            onHide={() => {
                setPlayerData({})
                onHide()
            }}
            dialogClassName="create-draw-pair-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>Dodavanje specijalnog igrača</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <TextField
                            label="Ime igrača"
                            onChange={(e) =>
                                setPlayerData((prev) => ({
                                    ...prev,
                                    person_info: {
                                        ...prev?.person_info,
                                        given_name: e.target.value,
                                    },
                                }))
                            }
                            value={playerData?.person_info?.given_name || ''}
                            type="text"
                            placeholder="Unesite ime igrača"
                            required
                        />

                        <TextField
                            label="Prezime igrača"
                            onChange={(e) =>
                                setPlayerData((prev) => ({
                                    ...prev,
                                    person_info: {
                                        ...prev?.person_info,
                                        family_name: e.target.value,
                                    },
                                }))
                            }
                            value={playerData?.person_info?.family_name || ''}
                            type="text"
                            placeholder="Unesite prezime igrača"
                            required
                            className="mt-3"
                        />

                        <RadioButton
                            required
                            name="Pol"
                            checkedValue={playerData?.gender?.gender_name || ''}
                            radioValues={['Muški', 'Ženski']}
                            onChange={(e) => {
                                setPlayerData((prev) => ({
                                    ...prev,
                                    gender: {
                                        gender_name: e.target.value,
                                    },
                                }))
                            }}
                            className="mt-3"
                        />

                        <TextField
                            label="Datum rođenja"
                            onChange={(e) =>
                                setPlayerData((prev) => ({
                                    ...prev,
                                    person_info: {
                                        ...prev?.person_info,
                                        birthdate: e.target.value,
                                    },
                                }))
                            }
                            type="date"
                            placeholder="Unesite datum rođenja igrača"
                            className="mt-3"
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    type="text"
                    onClick={() => {
                        setPlayerData({})
                        onHide()
                    }}
                >
                    Odustani
                </Button>
                <Button
                    onClick={() => handleCreateSpecialPlayer()}
                    disabled={
                        playerData?.person_info?.given_name &&
                        playerData?.person_info?.family_name &&
                        playerData?.gender?.gender_name
                            ? false
                            : true
                    }
                >
                    Dodaj
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddSpecialPlayerModal
