import React, { useEffect, useRef, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Link } from 'react-router-dom'
import {
    formatDate,
    formatImgUrl,
    competitorPillStatusHandler,
} from '../../lib/helpers'
import { pillStatusHandler } from '../../utils/pillHandler'

import { Modal } from 'react-bootstrap'
import { createMedical } from '../../lib/api'
import { useAppContext } from '../../lib/contextLib'
import { generateAvatar } from '../../utils/generateAvatar'
import Button from '../Button'
import FileUploadTag from '../FileUploadTag'
import TextField from '../TextField'
import moment from 'moment'
import './styles.scss'

const TableCardCompetitor = ({
    className,
    competitor,
    setCurrentPage,
    setRefreshData,
    fromHomePage = false,
    tabValue,
}) => {
    const { setShowCustomToast } = useAppContext()
    //* MEDICAL DATA STATES
    const [medicalData, setMedicalData] = useState({
        start_date: '',
        end_date: '',
    })
    const [disabledAddMedical, setDisableAddMedical] = useState(true)
    const [medicalImg, setMedicalImg] = useState(null)
    const [medicalImgTitle, setMedicalImgTitle] = useState('')
    //* MEDICAL DATA STATES

    const [openModal, setOpenModal] = useState(false)

    const inputRef = useRef(null)

    const addPhoto = (e) => {
        const fileReader = new FileReader()
        const file = e.target.files[0]
        setMedicalImgTitle((_) => {
            return file?.name
        })
        const fileSizeKilobytes = file.size / 1024
        if (checkSize(fileSizeKilobytes, 2048)) return
        else {
            fileReader.onload = () => {
                setMedicalImg((_) => {
                    return file
                })
            }
            fileReader.readAsDataURL(file)
        }
    }

    const checkSize = (fileSize, limit) => {
        if (fileSize > limit) {
            alert('Fajl je prevelik')
            return limit
        } else return
    }

    const handleOpenFileInput = () => {
        inputRef.current.click()
    }

    const updateMedicalField = (field, value) => {
        setMedicalData((prev) => ({ ...prev, [field]: value }))
    }

    const submitMedical = async () => {
        try {
            const { certificateData } = await createMedical(
                {
                    ...medicalData,
                    member_uuid: competitor?.attributes?.uuid,
                },
                medicalImg
            )

            if (certificateData) {
                setRefreshData(true)
                setCurrentPage(1)
                setOpenModal(false)
                setMedicalImg(null)
                setMedicalImgTitle('')
                setMedicalData({
                    start_date: '',
                    end_date: '',
                })
                setShowCustomToast({
                    show: true,
                    type: 'success',
                    message: 'Lekarski pregled uspešno produžen.',
                })
            }
        } catch (error) {
            console.error(error)
            setShowCustomToast({
                show: true,
                type: 'error',
                message: 'Došlo je do greške.',
            })
        }
    }

    const handleEdit = () => {
        navigate(
            `/takmicari/${competitor?.attributes?.uuid}/izmeni-profil-takmicara`
        )
    }

    const calculateEndDate = (startDate) => {
        const endDate = moment(startDate).add(6, 'months')
        return endDate.format('YYYY-MM-DD')
    }

    const updateStartDate = (newStartDate) => {
        updateMedicalField('start_date', newStartDate)

        const newEndDate = calculateEndDate(newStartDate)
        updateMedicalField('end_date', newEndDate)
    }

    useEffect(() => {
        if (
            medicalImgTitle &&
            medicalData.start_date &&
            medicalData.end_date &&
            medicalImg
        ) {
            setDisableAddMedical(false)
        } else {
            setDisableAddMedical(true)
        }
    }, [medicalData, medicalImg, medicalImgTitle])

    const handleCloseModal = () => {
        setOpenModal(false)
        setMedicalImg(null)
        setMedicalImgTitle('')
        setMedicalData({
            start_date: '',
            end_date: '',
        })
    }

    return (
        <>
            <Row className={`table-card-wrapper g-3 ${className}`}>
                <Col xs={6} className="mt-3 d-flex align-items-center">
                    <h5>Takmičar</h5>
                </Col>
                <Col xs={6} className="d-flex align-items-center p-0 mt-3">
                    <img
                        src={
                            competitor?.attributes?.person_info?.picture
                                ? competitor?.attributes?.person_info?.picture
                                : generateAvatar(
                                      `${competitor?.attributes?.person_info?.given_name} ${competitor?.attributes?.person_info?.family_name}`
                                  )
                        }
                        alt={`${competitor?.attributes?.person_info?.given_name}
                        ${competitor?.attributes?.person_info?.family_name}`}
                        width={'24px'}
                        height={'24px'}
                        className="me-2 competitor-table-card-avatar"
                    />
                    <Link to={`/takmicari/${competitor?.attributes?.uuid}`}>
                        <p className="link">
                            {competitor?.attributes?.person_info?.given_name}{' '}
                            {competitor?.attributes?.person_info?.family_name}
                        </p>
                    </Link>
                </Col>
                <hr className="table-card-divider" />
                <Col xs={6} className="m-0 d-flex align-items-center">
                    <h5>Datum rođenja</h5>
                </Col>
                <Col xs={6} className="d-flex align-items-center p-0 m-0">
                    <p>
                        {competitor?.attributes?.person_info?.birthdate
                            ? formatDate(
                                  competitor?.attributes?.person_info?.birthdate
                              )
                            : '-'}
                    </p>
                </Col>
                <hr className="table-card-divider" />
                <Col xs={6} className="m-0 d-flex align-items-center">
                    <h5>Klub</h5>
                </Col>
                <Col xs={6} className="d-flex align-items-center p-0 m-0">
                    {competitor?.attributes?.club?.attributes?.name ? (
                        <>
                            <img
                                src={
                                    competitor?.attributes?.club?.attributes
                                        ?.logo?.data
                                        ? formatImgUrl(
                                              competitor?.attributes?.club
                                                  ?.attributes?.logo?.data
                                                  ?.attributes?.url
                                          )
                                        : generateAvatar(
                                              competitor?.attributes?.club
                                                  ?.attributes?.name
                                          )
                                }
                                alt={`Logo kluba`}
                                width={'24px'}
                                height={'24px'}
                                style={{
                                    maxHeight: '24px',
                                    maxWidth: '24px',
                                    borderRadius: '50%',
                                }}
                            />
                            <Link
                                to={`/klubovi/${competitor?.attributes?.club?.attributes?.uuid}`}
                            >
                                <p className="ms-2 link">
                                    {
                                        competitor?.attributes?.club?.attributes
                                            ?.name
                                    }
                                </p>
                            </Link>
                        </>
                    ) : (
                        <p>-</p>
                    )}
                </Col>
                <hr className="table-card-divider" />
                <Col xs={6} className="m-0 d-flex align-items-center">
                    <h5>Lekarski pregled</h5>
                </Col>
                <Col xs={6} className="d-flex align-items-center p-0 m-0">
                    <p>
                        {formatDate(
                            competitor?.attributes?.last_medical?.attributes
                                ?.start_date
                        )}{' '}
                        -{' '}
                        {formatDate(
                            competitor?.attributes?.last_medical?.attributes
                                ?.end_date
                        )}
                    </p>
                </Col>
                <hr className="table-card-divider" />
                <Col xs={6} className="m-0 d-flex align-items-center">
                    <h5>Status</h5>
                </Col>
                <Col xs={6} className="d-flex align-items-center p-0 mb-3">
                    {pillStatusHandler(
                        competitorPillStatusHandler(
                            tabValue === 'kaznjeni'
                                ? 'punished'
                                : competitor?.attributes?.status?.status_name
                        )
                    )}{' '}
                </Col>
                {!fromHomePage && (
                    <>
                        <hr className="table-card-divider" />
                        <Col xs={12} className="d-flex align-items-center m-0">
                            <p
                                className="link link-align-end"
                                onClick={() => setOpenModal(true)}
                            >
                                Produži lekarski pregled
                            </p>
                        </Col>
                        <hr className="table-card-divider" />
                        <Col
                            xs={12}
                            className="d-flex align-items-center m-0 mb-3"
                        >
                            <p
                                className="link link-align-end"
                                onClick={handleEdit}
                            >
                                Izmeni
                            </p>
                        </Col>
                    </>
                )}
            </Row>
            {/* MODAL - PRODUŽI LEKARSKO */}
            <Modal
                size="md"
                centered
                show={openModal}
                onHide={handleCloseModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>Produži lekarski pregled</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TextField
                        type="date"
                        label="Datum obavljenog pregleda"
                        className="mb-4"
                        value={medicalData?.start_date}
                        onChange={(e) => updateStartDate(e.currentTarget.value)}
                        required
                    />
                    <TextField
                        type="date"
                        label="Lekarski pregled važi do"
                        value={medicalData?.end_date}
                        min={medicalData?.start_date}
                        onChange={(e) =>
                            updateMedicalField(
                                'end_date',
                                e.currentTarget.value
                            )
                        }
                        required
                    />
                    {medicalImg && (
                        <FileUploadTag
                            className="mt-3"
                            fileName={medicalImgTitle}
                            deleteFile={() => {
                                setMedicalImg((_) => {
                                    return null
                                })
                                setMedicalImgTitle((_) => {
                                    return ''
                                })
                                inputRef.current.value = null
                            }}
                        />
                    )}
                    <span className="mt-4 mb-4 d-flex">
                        <p>Uploaduj sliku ili sken lekarskog pregleda</p>
                        <h6 className="required-asterisk ms-1">*</h6>
                    </span>
                    <label onClick={handleOpenFileInput} className="w-100">
                        <Button
                            type="secondary"
                            className="w-100"
                            disabled={medicalImg ? true : false}
                        >
                            Uploaduj lekarski pregled
                        </Button>
                    </label>
                    <form>
                        <input
                            ref={inputRef}
                            type="file"
                            id="medicalImage"
                            name="medicalImage"
                            className="d-none invisible"
                            onChange={(e) => addPhoto(e)}
                            accept="image/jpg, image/jpeg, image/png"
                            disabled={medicalImg ? true : false}
                        />
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="text" onClick={handleCloseModal}>
                        Odustani
                    </Button>
                    <Button
                        onClick={submitMedical}
                        disabled={disabledAddMedical}
                    >
                        Produži lekarski pregled
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default TableCardCompetitor
