import { Card, Col, Row } from 'react-bootstrap'

import '../../styles.scss'

const AssemblyInternationalTournamentOrganizationCard = ({
    isWhite,
    result,
}) => {
    return (
        <Card
            className={`club-card ${
                isWhite ? 'white-club-card' : 'grey-club-card'
            }`}
        >
            <Card.Body className="py-0">
                <Row>
                    <Col>
                        <Row className="car-row align-items-center">
                            <Col>
                                <h4>Tip turnira</h4>
                            </Col>
                            <Col>
                                <p>{result?.organization_type ?? ''}</p>
                            </Col>
                        </Row>

                        <Row className="car-row align-items-center">
                            <Col>
                                <h4>Glasova</h4>
                            </Col>
                            <Col>
                                <p>{result?.votes}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default AssemblyInternationalTournamentOrganizationCard
