import { Col, Row } from 'react-bootstrap'
import TextArea from '../../../../../components/TextArea'
import TextField from '../../../../../components/TextField'
import { numbersOnly } from '../../../../../lib/helpers'

const StepThree = ({
    additionalInformationsForm,
    setAdditionalInformationsForm,
}) => {
    return (
        <Row className="create-new-competition-form-wrapper step-three">
            <Col md={12} className="mt-2">
                <TextField
                    id="ball_brand"
                    name="ball_brend"
                    placeholder="Marka lopti"
                    type="text"
                    value={additionalInformationsForm?.ball_brend || ''}
                    onChange={(e) => {
                        setAdditionalInformationsForm((prevState) => ({
                            ...prevState,
                            ball_brend: e.target.value,
                        }))
                    }}
                    label="Marka lopti"
                />
            </Col>
            <Col md={12} className="mt-3">
                <TextField
                    id="registration_fee"
                    name="registration_fee"
                    placeholder="Kotizacija (RSD)"
                    type="number"
                    value={additionalInformationsForm?.registration_fee || ''}
                    onChange={(e) => {
                        numbersOnly(e)
                        setAdditionalInformationsForm((prevState) => ({
                            ...prevState,
                            registration_fee: e.target.value,
                        }))
                    }}
                    label="Kotizacija (RSD)"
                />
            </Col>
            <Col md={12} className="mt-3">
                <TextArea
                    id="additional_details"
                    name="additional_details"
                    label="Dodatne informacije"
                    maxLength={600}
                    rows={4}
                    placeholder="Dodatne informacije..."
                    onChange={(e) => {
                        setAdditionalInformationsForm((prevState) => ({
                            ...prevState,
                            additional_details: e.target.value,
                        }))
                    }}
                    value={additionalInformationsForm?.additional_details || ''}
                />
            </Col>
        </Row>
    )
}

export default StepThree
