import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import Slider from 'react-slick'
import TournamentMatchCard from '../../../../../../components/TournamentMatchCard'
import TournamentRoundCard from '../../../../../../components/TournamentRoundCard'
import {
    getSingleTournament,
    getTournamentParticipantsNumber,
} from '../../../../../../lib/api'
import { useAppContext } from '../../../../../../lib/contextLib'
import {
    handleDrawTypesByTournamentCategory,
    handleNumberOfMatchCardInRound,
    handleRoundName,
    handleRoundsNumber,
    handleDrawSizeBasedOnGroupNumber,
} from '../../../../../../lib/helpers'
import GranPriSlider from './GranPri'
import LegacyDraw from './LegacyDraw'
import RoundRobinSlider from './RoundRobin/roundRobinSlider'
import TeamDraw from './TeamDraw'
import { settings } from './carouselSettings'
import DrawModalForExport from './drawModalForExport'
import DropDownSection from './dropdownSection'
import './styles.scss'

const NewDrawTab = ({
    activeTab,
    showDrawPDFModal,
    setShowDrawPDFModal,
    printDrawSelected = false,
    setPrintDrawSelected = false,
}) => {
    const { setIsLoading } = useAppContext()
    const { id } = useParams()
    const singleCardHeight = 238
    const doublesCardHeight = 312

    const [competitionProfileData, setCompetitionProfileData] = useState()
    const [sliderToShow, setSliderToShow] = useState()
    const [drawToShow, setDrawToShow] = useState({
        is_double: false,
    })

    const handleSingleTournamentData = async () => {
        if (activeTab === 'rezultati') {
            setIsLoading(true)
            const data = await getSingleTournament(id)
            if (data) {
                setCompetitionProfileData(data)
                setDrawToShow((prev) => ({
                    is_double: prev?.is_double,
                    gender: {
                        gender_name: data?.attributes?.genders[0]?.gender_name,
                    },
                    draw_type: {
                        draw_type_name:
                            handleDrawTypesByTournamentCategory(data)[0],
                    },
                }))
            }
            setIsLoading(false)
        }
    }

    const handleRoundRobinGroupNumber = async (drawToShow) => {
        if (!competitionProfileData?.attributes?.tournament_have_round_roubin)
            return 0

        const { total } = await getTournamentParticipantsNumber(
            competitionProfileData?.attributes?.uuid,
            drawToShow?.gender?.gender_name,
            drawToShow?.is_double
        )

        if (!total) return
        let fullGroups = Math.floor(total / 4)
        let rest = total % 4
        if (rest > 0) {
            fullGroups += 1
        }

        return fullGroups
    }

    const handleShowSlider = async (drawToShow) => {
        setIsLoading(true)

        const numberOfGroups = await handleRoundRobinGroupNumber(drawToShow)

        if (drawToShow?.draw_type?.draw_type_name) {
            const cardHeight = drawToShow?.is_double
                ? doublesCardHeight
                : singleCardHeight
            try {
                if (competitionProfileData?.attributes?.legacy_id) {
                    setSliderToShow(
                        <LegacyDraw
                            tournament={competitionProfileData}
                            drawToShow={drawToShow}
                        />
                    )
                } else {
                    if (
                        competitionProfileData?.attributes?.tournament_type
                            ?.tournament_type_name === 'Ekipno'
                    ) {
                        setSliderToShow(
                            <TeamDraw
                                tournament={competitionProfileData}
                                drawToShow={drawToShow}
                                mainSliderToShow={sliderToShow}
                            />
                        )
                    } else {
                        if (
                            drawToShow?.draw_type?.draw_type_name ===
                            'Round robin'
                        ) {
                            setSliderToShow(
                                <RoundRobinSlider
                                    tournament={competitionProfileData}
                                    drawToShow={drawToShow}
                                    numberOfGroups={numberOfGroups}
                                />
                            )
                        } else if (
                            drawToShow?.draw_type?.draw_type_name ===
                            'Srbija Gran Pri'
                        ) {
                            setSliderToShow(
                                <GranPriSlider
                                    tournament={competitionProfileData}
                                    drawToShow={drawToShow}
                                    activeTab={activeTab}
                                />
                            )
                        } else {
                            let drawSize = 0

                            if (
                                competitionProfileData?.attributes
                                    ?.tournament_have_round_roubin
                            ) {
                                drawSize = numberOfGroups
                                drawSize =
                                    handleDrawSizeBasedOnGroupNumber(
                                        numberOfGroups
                                    ) / 2
                            } else if (
                                drawToShow?.draw_type?.draw_type_name ===
                                'Kvalifikacije'
                            ) {
                                drawSize =
                                    +competitionProfileData?.attributes
                                        ?.qualifications_participants_expected_number /
                                    +competitionProfileData?.attributes
                                        ?.number_of_qualified /
                                    2
                            } else if (
                                drawToShow?.draw_type?.draw_type_name ===
                                'Utešni turnir'
                            ) {
                                drawSize = drawToShow?.is_double
                                    ? competitionProfileData?.attributes
                                          ?.doubles_pairs_expected_number / 4
                                    : competitionProfileData?.attributes
                                          ?.participants_expected_number / 4
                            } else {
                                if (
                                    +competitionProfileData?.attributes
                                        ?.participants_expected_number === 48
                                ) {
                                    drawSize = drawToShow?.is_double
                                        ? competitionProfileData?.attributes
                                              ?.doubles_pairs_expected_number /
                                          2
                                        : 64 / 2
                                } else {
                                    drawSize = drawToShow?.is_double
                                        ? competitionProfileData?.attributes
                                              ?.doubles_pairs_expected_number /
                                          2
                                        : competitionProfileData?.attributes
                                              ?.participants_expected_number / 2
                                }
                            }

                            if (drawSize > 0) {
                                setSliderToShow(
                                    <Slider
                                        {...settings(3)}
                                        className="rounds-slider"
                                    >
                                        {[
                                            ...Array(
                                                handleRoundsNumber(drawSize) + 1
                                            ),
                                        ].map((_, roundIndex) => (
                                            <TournamentRoundCard
                                                key={roundIndex}
                                                index={roundIndex}
                                                roundName={handleRoundName(
                                                    handleRoundsNumber(
                                                        drawSize
                                                    ) + 1,
                                                    roundIndex + 1
                                                )}
                                            >
                                                {[
                                                    ...Array(
                                                        handleNumberOfMatchCardInRound(
                                                            handleRoundsNumber(
                                                                drawSize
                                                            ) - roundIndex
                                                        )
                                                    ),
                                                ].map((_, cardiIndex) => (
                                                    <Row
                                                        key={cardiIndex}
                                                        style={{
                                                            marginTop:
                                                                cardiIndex === 0
                                                                    ? `${
                                                                          (Math.pow(
                                                                              2,
                                                                              roundIndex
                                                                          ) *
                                                                              cardHeight) /
                                                                              2 -
                                                                          cardHeight /
                                                                              2
                                                                      }px`
                                                                    : `${
                                                                          Math.pow(
                                                                              2,
                                                                              roundIndex
                                                                          ) *
                                                                              cardHeight -
                                                                          cardHeight
                                                                      }px`,

                                                            paddingBottom:
                                                                '20px',
                                                        }}
                                                    >
                                                        <Col className="tournament-match-card-col-wrapper">
                                                            <TournamentMatchCard
                                                                isFinal={
                                                                    handleRoundName(
                                                                        handleRoundsNumber(
                                                                            drawSize
                                                                        ) + 1,
                                                                        roundIndex +
                                                                            1
                                                                    ) ===
                                                                        'Finale' &&
                                                                    drawToShow
                                                                        ?.draw_type
                                                                        ?.draw_type_name ===
                                                                        'Kvalifikacije'
                                                                        ? true
                                                                        : false
                                                                }
                                                                handleShowSlider={() =>
                                                                    handleShowSlider(
                                                                        drawToShow
                                                                    )
                                                                }
                                                                createNextMatch={
                                                                    true
                                                                }
                                                                matchDetails={{
                                                                    round_number:
                                                                        +roundIndex +
                                                                        1,
                                                                    pair_number:
                                                                        +cardiIndex +
                                                                        1,
                                                                    tournament_uuid:
                                                                        id,
                                                                    ...drawToShow,
                                                                    draw_size:
                                                                        drawSize,
                                                                }}
                                                            />
                                                            {cardiIndex % 2 ===
                                                                0 &&
                                                                handleRoundName(
                                                                    handleRoundsNumber(
                                                                        drawSize
                                                                    ) + 1,
                                                                    roundIndex +
                                                                        1
                                                                ) !==
                                                                    'Finale' && (
                                                                    <div
                                                                        className="match-draw-line"
                                                                        style={{
                                                                            height: `${
                                                                                Math.pow(
                                                                                    2,
                                                                                    roundIndex
                                                                                ) *
                                                                                cardHeight
                                                                            }px`,
                                                                            top: `${
                                                                                cardHeight /
                                                                                2
                                                                            }px`,
                                                                        }}
                                                                    ></div>
                                                                )}

                                                            {roundIndex !==
                                                                0 && (
                                                                <div
                                                                    className="match-draw-back-line"
                                                                    style={{
                                                                        top: `${
                                                                            cardHeight /
                                                                            2
                                                                        }px`,
                                                                    }}
                                                                ></div>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                ))}
                                            </TournamentRoundCard>
                                        ))}
                                    </Slider>
                                )
                            } else {
                                setSliderToShow(
                                    <Row>
                                        <Col>
                                            <Card>
                                                <Card.Body>
                                                    <p>
                                                        Popunite sve podatke
                                                        takmičenja neophodne za
                                                        prikaz žreba kao što su:
                                                        predviđeni broj
                                                        učesnika, broj učesnika
                                                        u kvalifikacijama, broj
                                                        kvalifikanata, broj dubl
                                                        parova (ovo ne važi za
                                                        svaki tip i kategoriju
                                                        takmičenja).
                                                    </p>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                )
                            }
                        }
                    }
                }
            } catch (e) {
                setSliderToShow(
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <p>
                                        Popunite sve podatke takmičenja
                                        neophodne za prikaz žreba kao što su:
                                        predviđeni broj učesnika, broj učesnika
                                        u kvalifikacijama, broj kvalifikanata,
                                        broj dubl parova (ovo ne važi za svaki
                                        tip i kategoriju takmičenja). Za turnire
                                        sa Round robin sistemom je potrebno
                                        dodati igrače na turnir.
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                )
            }
        }
        setIsLoading(false)
    }

    useEffect(() => {
        handleSingleTournamentData()
    }, [activeTab])

    useEffect(() => {
        handleShowSlider(drawToShow)
    }, [competitionProfileData, drawToShow])

    return (
        <Row className="mt-3 tournament-draw-tab">
            <Col>
                <DropDownSection
                    className={'mb-4'}
                    drawToShow={drawToShow}
                    setDrawToShow={setDrawToShow}
                    competitionProfileData={competitionProfileData}
                    handleShowSlider={handleShowSlider}
                />

                <Row className="mt-3">
                    <Col>{sliderToShow}</Col>
                </Row>
            </Col>

            <DrawModalForExport
                show={showDrawPDFModal}
                onHide={() => {
                    setShowDrawPDFModal(false)
                    setPrintDrawSelected(false)
                }}
                competitionProfileData={competitionProfileData}
                drawToShow={drawToShow}
                printDrawSelected={printDrawSelected}
                setPrintDrawSelected={setPrintDrawSelected}
            />
        </Row>
    )
}

export default NewDrawTab
