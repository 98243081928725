import React, { useEffect, useState } from 'react'
import { Row, Col, Modal } from 'react-bootstrap'
import CardParticipantSection from './cardParticipant'
import CardResultSection from './cardResultSection'
import {
    updateMatchResult,
    createTournamentMatch,
    getSingleMatch,
    updateTournamentPlayerParticipant,
    calculateSeed,
} from '../../lib/api'
import Button from '../Button'
import RadioButton from '../RadioButton'
import Checkbox from '../Checkbox'
import { handleRoundsNumber } from '../../lib/helpers'
import './styles.scss'

const ChangeMatchResultModal = ({
    show,
    onHide,
    matchDetails,
    matchPenalties,
    handleGetSingleMatch,
    onChange,
    createNextMatch,
    handleShowSlider,
    isFinal = false,
}) => {
    const { sideOneData, sideTwoData, uuid, loser_participant_uuid } =
        matchDetails || {}

    const [sideOneResult, setSideOneResult] = useState(
        {
            ...sideOneData?.result,
            result: sideOneData?.result?.result || [],
        } || { result: { result: [] } }
    )
    const [sideTwoResult, setSideTwoResult] = useState(
        {
            ...sideTwoData?.result,
            result: sideTwoData?.result?.result || [],
        } || { result: { result: [] } }
    )
    const [matchResultData, setMatchResultData] = useState()

    const handleAddSet = () => {
        setSideOneResult((prev) => ({ ...prev, result: [...prev?.result, 0] }))
        setSideTwoResult((prev) => ({ ...prev, result: [...prev?.result, 0] }))
    }

    const handleRemoveSet = () => {
        setSideOneResult((prev) => ({
            ...prev,
            result: prev?.result?.slice(0, prev?.result?.length - 1),
        }))
        setSideTwoResult((prev) => ({
            ...prev,
            result: prev?.result?.slice(0, prev?.result?.length - 1),
        }))
    }

    const handleAutoSeed = async () => {
        await calculateSeed({
            tournament_uuid: matchDetails?.tournament_uuid,
            gender: matchDetails?.gender?.gender_name,
        })
    }

    const handleSaveChanges = async () => {
        const updatedResult = await updateMatchResult(uuid, {
            ...matchResultData,
            side_1_result: sideOneResult,
            side_2_result: sideTwoResult,
        })

        if (updatedResult) {
            if (isFinal) {
                const participantUUIDforUpdate =
                    matchResultData?.loser_participant_uuid ===
                    matchDetails?.sideOneData?.participant_uuid
                        ? matchDetails?.sideTwoData?.participant_uuid
                        : matchDetails?.sideOneData?.participant_uuid

                const updatedStatus = await updateTournamentPlayerParticipant(
                    participantUUIDforUpdate,
                    {
                        player_status: {
                            status_name: 'Q',
                        },
                    }
                )

                if (updatedStatus) {
                    handleAutoSeed()
                }
            }
            if (createNextMatch) {
                if (
                    matchDetails?.draw_size &&
                    handleRoundsNumber(matchDetails?.draw_size) + 1 ===
                        matchDetails?.round_number
                ) {
                    handleShowSlider()
                } else {
                    const nextMatchData = await getSingleMatch({
                        ...matchDetails,
                        round_number: matchDetails?.round_number + 1,
                        pair_number: Math.floor(
                            (matchDetails?.pair_number + 1) / 2
                        ),
                    })
                    if (nextMatchData) {
                        let secondParticipantObj = {}
                        if (matchDetails.pair_number % 2 === 0) {
                            if (
                                matchResultData?.loser_participant_uuid ===
                                matchDetails?.sideOneData?.participant_uuid
                            ) {
                                secondParticipantObj.side_2_uuid =
                                    matchDetails.sideTwoData.participant_uuid
                            } else {
                                secondParticipantObj.side_2_uuid =
                                    matchDetails.sideOneData.participant_uuid
                            }
                        } else {
                            if (
                                matchResultData?.loser_participant_uuid ===
                                matchDetails?.sideOneData?.participant_uuid
                            ) {
                                secondParticipantObj.side_1_uuid =
                                    matchDetails.sideTwoData.participant_uuid
                            } else {
                                secondParticipantObj.side_1_uuid =
                                    matchDetails.sideOneData.participant_uuid
                            }
                        }
                        const updatedMatch = await updateMatchResult(
                            nextMatchData?.attributes?.uuid,
                            {
                                ...secondParticipantObj,
                            }
                        )

                        if (updatedMatch) {
                            handleShowSlider()
                        }
                    } else {
                        let participantObj = {}

                        if (matchDetails.pair_number % 2 === 0) {
                            if (
                                matchResultData?.loser_participant_uuid ===
                                matchDetails?.sideOneData?.participant_uuid
                            ) {
                                participantObj.side_2_uuid =
                                    matchDetails.sideTwoData.participant_uuid
                            } else {
                                participantObj.side_2_uuid =
                                    matchDetails.sideOneData.participant_uuid
                            }
                        } else {
                            if (
                                matchResultData?.loser_participant_uuid ===
                                matchDetails?.sideOneData?.participant_uuid
                            ) {
                                participantObj.side_1_uuid =
                                    matchDetails.sideTwoData.participant_uuid
                            } else {
                                participantObj.side_1_uuid =
                                    matchDetails.sideOneData.participant_uuid
                            }
                        }

                        const {
                            sideOneData,
                            sideTwoData,
                            uuid,
                            loser_participant_uuid,
                            ...otherAttributes
                        } = matchDetails
                        const nextMatch = await createTournamentMatch({
                            ...otherAttributes,
                            ...participantObj,
                            round_number: matchDetails?.round_number + 1,
                            pair_number: Math.floor(
                                (matchDetails?.pair_number + 1) / 2
                            ),
                        })

                        if (nextMatch) {
                            handleShowSlider()
                        }
                    }
                }
            }
            onHide()
            if (onChange) {
                onChange()
            } else {
                handleGetSingleMatch()
            }
        }
    }

    useEffect(() => {
        setSideOneResult(
            {
                ...sideOneData?.result,
                result: sideOneData?.result?.result || [],
            } || { result: { result: [] } }
        )
        setSideTwoResult(
            {
                ...sideTwoData?.result,
                result: sideTwoData?.result?.result || [],
            } || { result: { result: [] } }
        )
        setMatchResultData((prev) => ({
            ...prev,
            loser_participant_uuid: loser_participant_uuid,
        }))
    }, [matchDetails])

    return (
        <Modal
            size="lg"
            centered
            show={show}
            onHide={onHide}
            dialogClassName="create-draw-pair-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>Izmeni rezultat</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="mb-2">
                    <Col className="d-flex">
                        <Button
                            className="me-2"
                            type="secondary"
                            onClick={handleAddSet}
                        >
                            Dodaj set
                        </Button>
                        <Button type="secondary" onClick={handleRemoveSet}>
                            Obriši set
                        </Button>
                    </Col>
                </Row>
                <Row className="px-2">
                    <Col className="tournament-match-card">
                        <CardParticipantSection
                            participantData={sideOneData}
                            matchData={{ ...matchDetails, ...matchResultData }}
                            matchPenalties={matchPenalties}
                        />
                        <CardResultSection
                            resultData={sideOneResult}
                            setResultData={setSideOneResult}
                            oppositeSideResult={sideTwoResult}
                            isEditable={true}
                        />
                        <CardResultSection
                            resultData={sideTwoResult}
                            setResultData={setSideTwoResult}
                            oppositeSideResult={sideOneResult}
                            isEditable={true}
                        />
                        <CardParticipantSection
                            participantData={sideTwoData}
                            matchData={{ ...matchDetails, ...matchResultData }}
                            matchPenalties={matchPenalties}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <RadioButton
                            required
                            className="mt-3"
                            name="Označi pobednika"
                            radioValues={[
                                `${
                                    sideOneData?.player_1?.attributes
                                        ?.person_info?.given_name
                                } ${
                                    sideOneData?.player_1?.attributes
                                        ?.person_info?.family_name
                                } ${
                                    sideOneData?.player_2
                                        ? `- ${sideOneData?.player_2?.attributes?.person_info?.given_name} ${sideOneData?.player_2?.attributes?.person_info?.family_name}`
                                        : ''
                                }`,
                                `${
                                    sideTwoData?.player_1?.attributes
                                        ?.person_info?.given_name
                                } ${
                                    sideTwoData?.player_1?.attributes
                                        ?.person_info?.family_name
                                } ${
                                    sideTwoData?.player_2
                                        ? `- ${sideTwoData?.player_2?.attributes?.person_info?.given_name} ${sideTwoData?.player_2?.attributes?.person_info?.family_name}`
                                        : ''
                                }`,
                            ]}
                            onChange={(e) => {
                                setMatchResultData((prev) => ({
                                    ...prev,
                                    loser_participant_uuid: e.target.value,
                                }))
                            }}
                            checkedValue={
                                matchResultData?.loser_participant_uuid
                                    ? sideOneData?.participant_uuid ===
                                      matchResultData?.loser_participant_uuid
                                        ? `${
                                              sideTwoData?.player_1?.attributes
                                                  ?.person_info?.given_name
                                          } ${
                                              sideTwoData?.player_1?.attributes
                                                  ?.person_info?.family_name
                                          } ${
                                              sideTwoData?.player_2
                                                  ? `- ${sideTwoData?.player_2?.attributes?.person_info?.given_name} ${sideTwoData?.player_2?.attributes?.person_info?.family_name}`
                                                  : ''
                                          }`
                                        : `${
                                              sideOneData?.player_1?.attributes
                                                  ?.person_info?.given_name
                                          } ${
                                              sideOneData?.player_1?.attributes
                                                  ?.person_info?.family_name
                                          } ${
                                              sideOneData?.player_2
                                                  ? `- ${sideOneData?.player_2?.attributes?.person_info?.given_name} ${sideOneData?.player_2?.attributes?.person_info?.family_name}`
                                                  : ''
                                          }`
                                    : null
                            }
                            radioHiddenValues={[
                                sideTwoData?.participant_uuid,
                                sideOneData?.participant_uuid,
                            ]}
                        />

                        <Checkbox
                            className="mt-3"
                            checked={sideOneResult?.is_ret || false}
                            onChange={() =>
                                setSideOneResult((prev) => ({
                                    ...prev,
                                    is_ret: !prev.is_ret,
                                }))
                            }
                            label={
                                sideTwoData?.player_2
                                    ? 'Prvi par je predao meč zbog povrede (RET)'
                                    : `${sideOneData?.player_1?.attributes?.person_info?.given_name} ${sideOneData?.player_1?.attributes?.person_info?.family_name} predao/la meč zbog povrede (RET)`
                            }
                        />

                        <Checkbox
                            className="mt-2"
                            checked={sideTwoResult?.is_ret || false}
                            onChange={() =>
                                setSideTwoResult((prev) => ({
                                    ...prev,
                                    is_ret: !prev.is_ret,
                                }))
                            }
                            label={
                                sideTwoData?.player_2
                                    ? 'Drugi par je predao meč zbog povrede (RET)'
                                    : `${sideTwoData?.player_1?.attributes?.person_info?.given_name} ${sideTwoData?.player_1?.attributes?.person_info?.family_name} predao/la meč zbog povrede (RET)`
                            }
                        />

                        <Checkbox
                            className="mt-3"
                            checked={sideOneResult?.is_wo || false}
                            onChange={() =>
                                setSideOneResult((prev) => ({
                                    ...prev,
                                    is_wo: !prev.is_wo,
                                }))
                            }
                            label={
                                sideOneData?.player_2
                                    ? 'Prvi par je predao meč bez igre (W.O.)'
                                    : `${sideOneData?.player_1?.attributes?.person_info?.given_name} ${sideOneData?.player_1?.attributes?.person_info?.family_name} predao/la meč bez igre (W.O.)`
                            }
                        />
                        <Checkbox
                            className="mt-2"
                            checked={sideTwoResult?.is_wo || false}
                            onChange={() =>
                                setSideTwoResult((prev) => ({
                                    ...prev,
                                    is_wo: !prev.is_wo,
                                }))
                            }
                            label={
                                sideTwoData?.player_2
                                    ? 'Drugi par je predao meč bez igre (W.O.)'
                                    : `${sideTwoData?.player_1?.attributes?.person_info?.given_name} ${sideTwoData?.player_1?.attributes?.person_info?.family_name} predao/la meč bez igre (W.O.)`
                            }
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button type="text" onClick={onHide}>
                    Odustani
                </Button>
                <Button
                    onClick={handleSaveChanges}
                    disabled={
                        matchResultData?.loser_participant_uuid ? false : true
                    }
                >
                    Sačuvaj
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ChangeMatchResultModal
