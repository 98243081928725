import { Table } from 'react-bootstrap'
import CompetitorsRankingListRow from './competitorsRankingListRow'

import PaginationWrapper from '../Pagination'
import './styles.scss'

const CompetitorsRankingLists = ({
    data,
    currentPage,
    setCurrentPage,
    pageCount,
    total,
    handleGetCompetitorsRankingLists,
    rowsPerPage,
    setRowsPerPage,
    showRegion = false,
}) => {
    return (
        <div className="table-clubs-ranking-wrapper">
            <Table className="mb-0">
                <thead>
                    <tr>
                        <th>
                            <h5>Naziv</h5>
                        </th>
                        <th>
                            <h5>Konkurencija</h5>
                        </th>
                        <th>
                            <h5>Takmičarska legitimacija</h5>
                        </th>
                        <th>
                            <h5>Pol</h5>
                        </th>
                        {showRegion && (
                            <th>
                                <h5>Region</h5>
                            </th>
                        )}
                        <th>
                            <h5>Poslednje ažuriranje</h5>
                        </th>
                        <th></th>
                        {/* <th></th> */}
                    </tr>
                </thead>
                <tbody>
                    {data.map((list, index) => (
                        <CompetitorsRankingListRow
                            key={index}
                            list={list}
                            setCurrentPage={setCurrentPage}
                            handleGetCompetitorsRankingLists={
                                handleGetCompetitorsRankingLists
                            }
                            showRegion={showRegion}
                        />
                    ))}
                </tbody>
            </Table>
            <PaginationWrapper
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageCount={pageCount}
                total={total}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
            />
        </div>
    )
}

export default CompetitorsRankingLists
