import { useState } from 'react'
import { Card, Col, Modal, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {
    removePlayerFromTournament,
    updateTournamentPlayerParticipant,
} from '../../../lib/api'
import { formatDate, formatImgUrl } from '../../../lib/helpers'
import { generateAvatar } from '../../../utils/generateAvatar'
import Button from '../../Button'
import Select from '../../Select'

import { useAppContext } from '../../../lib/contextLib'

const tournamentStatuses = ['QA', 'DA', 'WC', 'WCQ', 'ALT', 'LL']

const CompetitionsParticipantsCard = ({
    index,
    participant,
    enableRemove,
    isWhite,
    handleGetPlayersForTournament,
    isDouble,
    handleAutoSeed,
}) => {
    const { user } = useAppContext()

    const [showStatusModal, setShowStatusModal] = useState(false)
    const [tournamentStatus, setTournamentStatus] = useState('QA')
    const [showRemoveCompetitorModal, setShowRemoveCompetitorModal] =
        useState(false)

    const handleRemoveTournamentParticipant = async (playerUuid) => {
        const removePlayer = await removePlayerFromTournament(playerUuid)

        if (removePlayer) {
            setShowRemoveCompetitorModal(false)
            handleGetPlayersForTournament()
        }
    }

    const handleUpdateStatus = async (participantUuid, selectedStatus) => {
        const updatedStatus = await updateTournamentPlayerParticipant(
            participantUuid,
            selectedStatus
        )

        if (updatedStatus) {
            if (handleAutoSeed && isDouble !== true) {
                setShowStatusModal(false)
                handleAutoSeed()
            } else {
                setShowStatusModal(false)
                handleGetPlayersForTournament()
            }
        }
    }

    return (
        <>
            <Card
                className={`club-card ${
                    isWhite ? 'white-club-card' : 'grey-club-card'
                }`}
            >
                <Card.Body className="py-0">
                    <Row>
                        <Col>
                            <Row className="car-row align-items-center">
                                <Col xs={5}>
                                    <h4>Pozicija</h4>
                                </Col>
                                <Col xs={7}>
                                    <p>{index + 1}</p>
                                </Col>
                            </Row>

                            <Row className="car-row align-items-center">
                                <Col xs={5}>
                                    <h4>Takmičar</h4>
                                </Col>
                                <Col xs={7}>
                                    <div className="d-flex align-items-center">
                                        <img
                                            className="competitor-img me-2"
                                            src={
                                                participant?.attributes
                                                    ?.player_1?.attributes
                                                    ?.person_info?.picture
                                                    ? participant?.attributes
                                                          ?.player_1?.attributes
                                                          ?.person_info?.picture
                                                    : generateAvatar(
                                                          `${participant?.attributes?.player_1?.attributes?.person_info?.given_name} ${participant?.attributes?.player_1?.attributes?.person_info?.family_name}`
                                                      )
                                            }
                                        />
                                        <div>
                                            <div className="d-flex align-items-center">
                                                <Link
                                                    to={`/takmicari/${participant?.attributes?.player_1?.attributes?.uuid}`}
                                                >
                                                    <p className="link">
                                                        {
                                                            participant
                                                                ?.attributes
                                                                ?.player_1
                                                                ?.attributes
                                                                ?.person_info
                                                                ?.given_name
                                                        }{' '}
                                                        {
                                                            participant
                                                                ?.attributes
                                                                ?.player_1
                                                                ?.attributes
                                                                ?.person_info
                                                                ?.family_name
                                                        }
                                                    </p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                    {isDouble && (
                                        <div className="d-flex align-items-center mt-2">
                                            <img
                                                className="competitor-img me-2"
                                                src={
                                                    participant?.attributes
                                                        ?.player_2?.attributes
                                                        ?.person_info?.picture
                                                        ? participant
                                                              ?.attributes
                                                              ?.player_2
                                                              ?.attributes
                                                              ?.person_info
                                                              ?.picture
                                                        : generateAvatar(
                                                              `${participant?.attributes?.player_2?.attributes?.person_info?.given_name} ${participant?.attributes?.player_2?.attributes?.person_info?.family_name}`
                                                          )
                                                }
                                            />
                                            <div>
                                                <div className="d-flex align-items-center">
                                                    <Link
                                                        to={`/takmicari/${participant?.attributes?.player_2?.attributes?.uuid}`}
                                                    >
                                                        <p className="link">
                                                            {
                                                                participant
                                                                    ?.attributes
                                                                    ?.player_2
                                                                    ?.attributes
                                                                    ?.person_info
                                                                    ?.given_name
                                                            }{' '}
                                                            {
                                                                participant
                                                                    ?.attributes
                                                                    ?.player_2
                                                                    ?.attributes
                                                                    ?.person_info
                                                                    ?.family_name
                                                            }
                                                        </p>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Col>
                            </Row>

                            <Row className="car-row align-items-center">
                                <Col xs={5}>
                                    <h4>Klub</h4>
                                </Col>
                                <Col xs={7}>
                                    <div className="d-flex flex-column">
                                        <div className="d-flex align-items-center">
                                            {participant?.attributes
                                                ?.player_1_club?.attributes
                                                ?.name ? (
                                                <>
                                                    <img
                                                        src={
                                                            participant
                                                                ?.attributes
                                                                ?.player_1_club
                                                                ?.attributes
                                                                ?.logo?.data
                                                                ? formatImgUrl(
                                                                      participant
                                                                          ?.attributes
                                                                          ?.player_1_club
                                                                          ?.attributes
                                                                          ?.logo
                                                                          ?.data
                                                                          ?.attributes
                                                                          ?.url
                                                                  )
                                                                : generateAvatar(
                                                                      participant
                                                                          ?.attributes
                                                                          ?.player_1_club
                                                                          ?.attributes
                                                                          ?.name
                                                                  )
                                                        }
                                                        alt={`Logo kluba`}
                                                        className="competitor-img"
                                                    />
                                                    <Link
                                                        to={`/klubovi/${participant?.attributes?.player_1_club?.attributes?.uuid}`}
                                                    >
                                                        <p className="ms-2 link">
                                                            {
                                                                participant
                                                                    ?.attributes
                                                                    ?.player_1_club
                                                                    ?.attributes
                                                                    ?.name
                                                            }
                                                        </p>
                                                    </Link>
                                                </>
                                            ) : (
                                                <p>-</p>
                                            )}
                                        </div>

                                        {isDouble ? (
                                            participant?.attributes
                                                ?.player_2_club?.attributes
                                                ?.name ? (
                                                <div className="d-flex align-items-center mt-2">
                                                    <img
                                                        src={
                                                            participant
                                                                ?.attributes
                                                                ?.player_2_club
                                                                ?.attributes
                                                                ?.logo?.data
                                                                ? formatImgUrl(
                                                                      participant
                                                                          ?.attributes
                                                                          ?.player_2_club
                                                                          ?.attributes
                                                                          ?.logo
                                                                          ?.data
                                                                          ?.attributes
                                                                          ?.url
                                                                  )
                                                                : generateAvatar(
                                                                      participant
                                                                          ?.attributes
                                                                          ?.player_2_club
                                                                          ?.attributes
                                                                          ?.name
                                                                  )
                                                        }
                                                        alt={`Logo kluba`}
                                                        className="competitor-img"
                                                    />
                                                    <Link
                                                        to={`/klubovi/${participant?.attributes?.player_2_club?.attributes?.uuid}`}
                                                    >
                                                        <p className="ms-2 link">
                                                            {
                                                                participant
                                                                    ?.attributes
                                                                    ?.player_2_club
                                                                    ?.attributes
                                                                    ?.name
                                                            }
                                                        </p>
                                                    </Link>
                                                </div>
                                            ) : (
                                                <div className="d-flex align-items-center">
                                                    <p>-</p>
                                                </div>
                                            )
                                        ) : null}
                                    </div>
                                </Col>
                            </Row>

                            <Row className="car-row align-items-center">
                                <Col xs={5}>
                                    <h4>Datum rođenja</h4>
                                </Col>
                                <Col xs={7}>
                                    <p>
                                        {participant?.attributes?.player_1
                                            ?.attributes?.person_info?.birthdate
                                            ? formatDate(
                                                  participant?.attributes
                                                      ?.player_1?.attributes
                                                      ?.person_info?.birthdate
                                              )
                                            : '-'}
                                    </p>

                                    {isDouble && (
                                        <p className="mt-2">
                                            {participant?.attributes?.player_2
                                                ?.attributes?.person_info
                                                ?.birthdate
                                                ? formatDate(
                                                      participant?.attributes
                                                          ?.player_2?.attributes
                                                          ?.person_info
                                                          ?.birthdate
                                                  )
                                                : '-'}
                                        </p>
                                    )}
                                </Col>
                            </Row>

                            <Row className="car-row align-items-center">
                                <Col xs={5}>
                                    <h4>Rang</h4>
                                </Col>
                                <Col xs={7}>
                                    <p>
                                        {participant?.attributes
                                            ?.player_1_ranking_number || ''}
                                    </p>
                                    {isDouble && (
                                        <p className="mt-2">
                                            {participant?.attributes
                                                ?.player_2_ranking_number || ''}
                                        </p>
                                    )}
                                </Col>
                            </Row>

                            <Row className="car-row align-items-center">
                                <Col xs={5}>
                                    <h4>Status</h4>
                                </Col>
                                <Col xs={7}>
                                    <p>
                                        {participant?.attributes?.player_status
                                            ? participant?.attributes
                                                  ?.player_status?.status_name
                                            : '-'}
                                    </p>
                                </Col>
                            </Row>

                            {(user?.role?.type === 'admin' ||
                                user?.role?.type === 'referee') && (
                                <Row className="car-row border-bottom-0 align-items-center">
                                    <Col xs={5}>
                                        <h4>Izmeni status</h4>
                                    </Col>
                                    <Col xs={7}>
                                        <p
                                            className="link"
                                            onClick={() =>
                                                setShowStatusModal(true)
                                            }
                                        >
                                            Izmeni status
                                        </p>
                                    </Col>
                                </Row>
                            )}
                            {enableRemove && (
                                <Row className="car-row border-bottom-0 align-items-center">
                                    <Col xs={5}>
                                        <h4>Ukloni</h4>
                                    </Col>
                                    <Col xs={7}>
                                        <p
                                            className="red-link"
                                            onClick={() =>
                                                setShowRemoveCompetitorModal(
                                                    true
                                                )
                                            }
                                        >
                                            Ukloni
                                        </p>
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <Modal
                size="md"
                centered
                show={showStatusModal}
                onHide={() => setShowStatusModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>Odaberite status takmičenja</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Select
                        value={tournamentStatus || ''}
                        onChange={(e) => setTournamentStatus(e.target.value)}
                        data={tournamentStatuses}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleUpdateStatus(participant?.attributes?.uuid, {
                                player_status: {
                                    status_name: tournamentStatus,
                                },
                            })
                        }
                    >
                        Odaberi
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Ukloniti takmicara */}
            <Modal
                size="md"
                centered
                show={showRemoveCompetitorModal}
                onHide={() => setShowRemoveCompetitorModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>Uklanjanje takmičara</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Da li ste sigurni da želite da uklonite takmičara sa
                        ovog takmičenja?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="text"
                        onClick={() => setShowRemoveCompetitorModal(false)}
                    >
                        Odustani
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            handleRemoveTournamentParticipant(
                                participant?.attributes?.uuid
                            )
                        }}
                    >
                        Ukloni
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CompetitionsParticipantsCard
