import { useEffect, useState } from 'react'
import { Breadcrumb, Col, Row, Tab, Tabs } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import Danger from '../../assets/svg/Danger'
import People from '../../assets/svg/People'
import Person from '../../assets/svg/Person'
import MedicalHistory from '../../components/MedicalHistory'
import ProfileCard from '../../components/ProfileCard'
import { useAppContext } from '../../lib/contextLib'
import {
    competitorPillStatusHandler,
    isPlayerDisciplinaryPunished,
} from '../../lib/helpers'
import ScrollToTop from '../../lib/hooks/ScrollToTop'
import { generateAvatar } from '../../utils/generateAvatar'
import EditProfile from './EditProfile'
import Membership from './Membership'
import Penalties from './Penalties'
import Profile from './Profile'
import Tournaments from './Tournaments'
import './style.scss'

const userTabs = [
    { eventKey: 'profile', icon: <Person width="20px" />, text: 'Profil' },
    {
        eventKey: 'tournaments',
        icon: <People width="20px" />,
        text: 'Moja takmičenja',
    },
    {
        eventKey: 'penalty',
        icon: <Danger fill="#6B6F72" width="20px" />,
        text: 'Kazne',
    },
]

const Competitor = () => {
    const { user } = useAppContext()
    const [activeTab, setActiveTab] = useState('profile')
    const [isEditing, setIsEditing] = useState(false)

    const [showMembership, setShowMembership] = useState(false)
    const [showMedicalHistory, setShowMedicalHistory] = useState(false)
    const [isPunished, setIsPunished] = useState(false)

    const [customTournamentActiveTab, setCustomTournamentActiveTab] =
        useState('upcoming')

    const handleTabState = () => {
        switch (activeTab) {
            case 'profile':
                return (
                    <Profile
                        setIsEditing={setIsEditing}
                        setActiveTab={setActiveTab}
                        setCustomTournamentActiveTab={
                            setCustomTournamentActiveTab
                        }
                        setShowMembership={setShowMembership}
                        setShowMedicalHistory={setShowMedicalHistory}
                    />
                )
            case 'tournaments':
                return (
                    <Tournaments customActiveTab={customTournamentActiveTab} />
                )
            case 'penalty':
                return <Penalties user={user} />
            default:
                return <Profile setIsEditing={setIsEditing} />
        }
    }

    const handleIsPunished = async (playerUuid) => {
        let isPlayerPunished = await isPlayerDisciplinaryPunished(playerUuid)

        setIsPunished(isPlayerPunished)
    }

    useEffect(() => {
        if (user) {
            handleIsPunished(user?.data?.attributes?.uuid)
        }
    }, [user])

    return (
        <Row className="competitor h-100">
            <ScrollToTop step={isEditing} />
            {!showMembership && !showMedicalHistory && !isEditing && (
                <Col>
                    <Row className="d-flex pt-3 justify-content-between align-items-center">
                        <Col xs={8} md={5} className="d-flex flex-column">
                            <h2 className="d-none d-md-block">Početna</h2>
                            <p className="d-block d-md-none">Početna</p>
                            <Breadcrumb className="mt-md-2 mt-1">
                                <Breadcrumb.Item
                                    linkAs={Link}
                                    linkProps={{ to: '/takmicar/pocetna' }}
                                >
                                    {`${user?.data?.attributes?.person_info?.given_name} ${user?.data?.attributes?.person_info?.family_name}`}
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>Profil</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Col>
                        <Row className="m-0 card-profile">
                            <ProfileCard
                                name={`${user?.data?.attributes?.person_info?.given_name} ${user?.data?.attributes?.person_info?.family_name}`}
                                email={user?.email}
                                avatar={
                                    user?.data?.attributes?.person_info?.picture
                                        ? user?.data?.attributes?.person_info
                                              ?.picture
                                        : generateAvatar(
                                              `${user?.data?.attributes?.person_info?.given_name} ${user?.data?.attributes?.person_info?.family_name}`
                                          )
                                }
                                pillContent={competitorPillStatusHandler(
                                    user?.data?.attributes?.status?.status_name
                                )}
                                punished={isPunished}
                            />
                            <Col
                                md={12}
                                className="pt-0 pt-md-4 d-flex align-items-center justify-content-start justify-content-md-end bg-white"
                            >
                                <Tabs
                                    id="club-selection"
                                    defaultActiveKey={activeTab}
                                    activeKey={activeTab}
                                    onSelect={(k) => setActiveTab(k)}
                                >
                                    {userTabs.map(
                                        ({ eventKey, icon, text }) => (
                                            <Tab
                                                key={eventKey}
                                                eventKey={eventKey}
                                                title={
                                                    <>
                                                        {icon}
                                                        <p className="ms-2">
                                                            {text}
                                                        </p>
                                                    </>
                                                }
                                                tabClassName="d-flex"
                                            ></Tab>
                                        )
                                    )}
                                </Tabs>
                            </Col>
                        </Row>
                    </Col>
                    {handleTabState()}
                </Col>
            )}
            {showMembership && (
                <Membership
                    memberships={user?.data?.attributes?.memberships}
                    setShowMembership={setShowMembership}
                    profileData={user?.data}
                />
            )}

            {showMedicalHistory && (
                <MedicalHistory
                    setShowMedicalHistory={setShowMedicalHistory}
                    profileData={user?.data}
                />
            )}

            {isEditing && <EditProfile setIsEditing={setIsEditing} />}
        </Row>
    )
}

export default Competitor
