const color = '#92A9BD'
const textColor = '#2C3136'
const radius = 150
const margin = 5
const font = `normal ${radius}px Source Sans Pro Bold`
const width = radius * 2
const height = radius * 2

export const generateAvatar = (name, refereeCard) => {
    var initials = name
        ?.split(' ')
        ?.map(function (str) {
            return str ? str[0].toUpperCase() : ''
        })
        ?.join('')
    var canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height

    // Get the drawing context
    var ctx = canvas.getContext('2d')
    ctx.beginPath()
    !refereeCard && ctx.arc(radius, radius, radius, 0, 2 * Math.PI, false)
    refereeCard && ctx.rect(0, 0, radius * 2, radius * 2)
    ctx.closePath()
    ctx.fillStyle = color
    ctx.fill()
    ctx.fillStyle = textColor
    ctx.font = font
    ctx.textAlign = 'center'
    ctx.fillText(initials, radius, radius + radius / 3)
    return canvas.toDataURL()
}
