import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import SingleDisciplinaryPenalty from '../../Admin/Competitors/CompetitorProfile/PunishmentsTab/SingleDisciplinaryPenalty'
import SingleTournamentPenalty from '../../Admin/Competitors/CompetitorProfile/PunishmentsTab/SingleTournamentPenalty'
import { getPlayersPenalties, getPlayerPenaltyPoints } from '../../../lib/api'
import { useAppContext } from '../../../lib/contextLib'

import './styles.scss'

const Penalties = ({ user }) => {
    const { setIsLoading } = useAppContext()
    const [tournamentPenalties, setTournamentPenalties] = useState([])
    const [disciplinaryPenalties, setDisciplinaryPenalties] = useState([])
    const [penaltyPoints, setPenaltyPoints] = useState(0)

    const handleGetPenalties = async () => {
        try {
            setIsLoading(true)
            const playerPenaltyPointsPromise = getPlayerPenaltyPoints(
                user?.data?.attributes?.uuid
            )
            const allPenaltiesPromise = getPlayersPenalties(
                user?.data?.attributes?.uuid
            )

            const [playerPenaltyPoints, allPenalties] = await Promise.all([
                playerPenaltyPointsPromise,
                allPenaltiesPromise,
            ])

            setDisciplinaryPenalties(
                allPenalties.filter(
                    (penalty) =>
                        penalty?.attributes?.penalty_type === 'Disciplinary'
                )
            )

            setTournamentPenalties(
                allPenalties.filter(
                    (penalty) =>
                        penalty?.attributes?.penalty_type === 'Tournament'
                )
            )

            setPenaltyPoints(playerPenaltyPoints)
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        handleGetPenalties()
    }, [])
    return (
        <Row className="punishments-tab-wrapper mt-3">
            <Col xs={12}>
                <h5>
                    Ukupno kaznenih bodova u tekućoj sezoni:{' '}
                    {penaltyPoints || 0}
                </h5>
            </Col>
            <Col xs={12} md className="tournament-pun pe-md-0">
                <Card>
                    <Card.Body className="p-4">
                        <Row className="mb-3">
                            <Col>
                                <h2 className="p-0">Kazne sa takmičenja</h2>
                            </Col>
                        </Row>

                        {tournamentPenalties?.map((penalty, index) => (
                            <SingleTournamentPenalty
                                key={index}
                                penalty={penalty}
                                isAdmin={user?.role?.type === 'admin'}
                            />
                        ))}
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} md className="tournament-pun ps-md-0">
                <Card>
                    <Card.Body className="p-4">
                        <Row className="mb-3">
                            <Col>
                                <h2 className="p-0">Disciplinske kazne</h2>
                            </Col>
                        </Row>
                        {disciplinaryPenalties?.map((penalty, index) => (
                            <SingleDisciplinaryPenalty
                                key={index}
                                penalty={penalty}
                                isAdmin={user?.role?.type === 'admin'}
                            />
                        ))}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default Penalties
