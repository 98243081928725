import Form from 'react-bootstrap/Form'
import './styles.scss'

const Checkbox = ({
    id,
    checked,
    disabled,
    onChange,
    label,
    name,
    className,
    ...restProps
}) => {
    return (
        <div className={`checkbox-wrapper ${className}`}>
            <Form.Check
                name={name}
                disabled={disabled}
                checked={checked}
                type="checkbox"
                id={id ? id : 'default-checkbox'}
                label={<p className="checkbox-label">{label}</p>}
                onChange={onChange}
                {...restProps}
            />
        </div>
    )
}

export default Checkbox
