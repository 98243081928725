import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { generateAvatar } from '../../utils/generateAvatar'

import './styles.scss'

const RefereeCard = ({
    className,
    referee,
    isMainReferee = false,
    type,
    onCloseClick,
    showCloseButton = false,
}) => {
    return (
        <Card className={`referee-card ${className}`}>
            <Card.Body className="p-0">
                <Row>
                    <Col sm={12} className="d-flex flex-column">
                        {showCloseButton ? (
                            <div className="close-btn-wrapper">
                                <button
                                    type="button"
                                    className="btn-close"
                                    aria-label="Close"
                                    onClick={() =>
                                        onCloseClick && onCloseClick()
                                    }
                                ></button>
                            </div>
                        ) : null}
                        <div className="d-flex info-card-content-wrapper align-items-center">
                            <img
                                src={
                                    referee?.attributes?.person_info?.picture
                                        ? referee?.attributes?.person_info
                                              ?.picture
                                        : generateAvatar(
                                              `${referee?.attributes?.person_info?.given_name} ${referee?.attributes?.person_info?.family_name}`,
                                              true
                                          )
                                }
                                alt={`${referee?.attributes?.person_info?.given_name} ${referee?.attributes?.person_info?.family_name}`}
                            />
                            <Col>
                                <h3>
                                    {`${referee?.attributes?.person_info?.given_name} ${referee?.attributes?.person_info?.family_name}`}
                                </h3>
                                <p className="subtitle mt-1">{type}</p>
                            </Col>
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default RefereeCard
