import React, { useState } from 'react'
import { Col, Dropdown as DropdownNav, Row } from 'react-bootstrap'

import './styles.scss'

function Dropdown(props) {
    const {
        dropdownLinks,
        className,
        disabled,
        handleSelect,
        placeholder,
        label,
        required,
        value,
        style,
        selectedIndex,
        defaultValue,
        ...rest
    } = props
    const [linkName, setLinkName] = useState(placeholder)

    const onHandleSelect = (link) => {
        setLinkName(link)
    }

    const handleSelectedIndex = (index) => {
        if (selectedIndex) {
            return selectedIndex(index)
        }
    }

    return (
        <Row className={`${className}`}>
            <Col>
                <div className="d-flex">
                    {label && <h5 className="mb-2 me-1">{label}</h5>}
                    {required && <h6 className="required">*</h6>}
                </div>
                <DropdownNav
                    className="dropdown-wrapper align-items-center"
                    onSelect={(e) => {
                        onHandleSelect(e)
                        handleSelect && handleSelect(e)
                    }}
                    defaultValue={defaultValue}
                    {...rest}
                >
                    <DropdownNav.Toggle
                        className="w-100 d-flex justify-content-between align-items-center"
                        disabled={disabled}
                    >
                        <p
                            style={style}
                            className={value ? '' : 'placeholder-style'}
                        >
                            {value
                                ? value
                                : value === ''
                                ? placeholder
                                : linkName}
                        </p>
                    </DropdownNav.Toggle>
                    <DropdownNav.Menu
                        className="w-100"
                        popperConfig={{
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, 2],
                                    },
                                },
                            ],
                        }}
                    >
                        {dropdownLinks?.map((link, index) => (
                            <DropdownNav.Item
                                key={index}
                                eventKey={link}
                                onClick={() => handleSelectedIndex(index)}
                            >
                                <p>{link}</p>
                            </DropdownNav.Item>
                        ))}
                    </DropdownNav.Menu>
                </DropdownNav>
            </Col>
        </Row>
    )
}

export default Dropdown
