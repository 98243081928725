import * as React from 'react'

const Camera = (props) => (
    <svg
        width={21}
        height={17}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.99 1.286C7.353.86 7.886.562 8.51.562h4.107c.624 0 1.156.298 1.521.724a.726.726 0 0 1 .061.083l1.157 1.828c.146.154.313.223.43.223h2.635a2.143 2.143 0 0 1 2.143 2.143v8.57a2.143 2.143 0 0 1-2.143 2.144H2.707a2.143 2.143 0 0 1-2.143-2.143V5.562A2.143 2.143 0 0 1 2.707 3.42h2.678c.094 0 .225-.047.385-.221l1.158-1.83a.714.714 0 0 1 .061-.083Zm1.113.897L6.947 4.011a.714.714 0 0 1-.061.082c-.327.382-.831.755-1.5.755h-2.68a.714.714 0 0 0-.714.715v8.57a.714.714 0 0 0 .715.715H18.42a.714.714 0 0 0 .714-.714V5.562a.715.715 0 0 0-.714-.714h-2.634c-.646 0-1.197-.348-1.545-.755a.723.723 0 0 1-.061-.082l-1.157-1.828a.56.56 0 0 0-.407-.192H8.51a.56.56 0 0 0-.407.192Z"
            fill="#2C3136"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.564 6.276a2.857 2.857 0 1 0 0 5.715 2.857 2.857 0 0 0 0-5.715ZM6.278 9.133a4.286 4.286 0 1 1 8.571 0 4.286 4.286 0 0 1-8.571 0ZM2.885 3.062c0-.395.32-.714.714-.714h1.072c.394 0 .714.32.714.714v.982a.714.714 0 0 1-1.25.473.714.714 0 0 1-1.25-.472v-.983Z"
            fill="#2C3136"
        />
    </svg>
)

export default Camera
