import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { useEffect, useState } from 'react'
import { Breadcrumb, Col, Offcanvas, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import turniriPNG from '../../../assets/images/turniri.png'
import Add from '../../../assets/svg/Add'
import OutlinedFilter from '../../../assets/svg/OutlinedFilter'
import Button from '../../../components/Button'
import Dropdown from '../../../components/Dropdown'
import RadioButton from '../../../components/RadioButton'
import SearchInput from '../../../components/SearchInput'
import TableCardCouches from '../../../components/TableCardCouches'
import TableCoachesPagination from '../../../components/TableCoachesPagination'
import { useAppContext } from '../../../lib/contextLib'
import { font } from '../Competitions/CompetitionProfile/font'

import { RowsPerPageOptions } from '../../../components/RowsPerPageDropdown'
import { getCoachLicenses, getCoaches, getCodebook } from '../../../lib/api'
import { debounce, formatDate } from '../../../lib/helpers'
import './styles.scss'

const searchInputID = `searchInput-coaches`

const Coaches = () => {
    //pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [total, setTotal] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(RowsPerPageOptions[0])

    const initialFilters = {
        gender: '',
    }

    const [filterParams, setFilterParams] = useState(initialFilters)

    const { coaches, setCoaches, setIsLoading } = useAppContext()

    // Implementing codebooks

    const [genders, setGenders] = useState([])
    const [nationalLicense, setNationalLicense] = useState([])
    const [coachUSPTA, setCoachUSPTA] = useState([])
    const [coachPTR, setCoachPTR] = useState([])
    const [coachLTA, setCoachLTA] = useState([])
    const [coachITF, setCoachITF] = useState([])
    const [coachGPTCA, setCoachGPTCA] = useState([])

    const handleCodebooks = async () => {
        await getCodebook('codebooks.gender', setGenders)
        await getCodebook('codebooks.coach-uspta-license', setCoachUSPTA)
        await getCodebook(
            'codebooks.coach-national-license',
            setNationalLicense
        )
        await getCodebook('codebooks.coach-ptr-license', setCoachPTR)
        await getCodebook('codebooks.coach-lta-license', setCoachLTA)
        await getCodebook('codebooks.coach-itf-license', setCoachITF)
        await getCodebook('codebooks.coach-gptca-license', setCoachGPTCA)
    }

    const [showFilters, setShowFilters] = useState(false)

    const extractSearchParameters = (searchValue) => {
        const [given_name, family_name, email] = searchValue.split(' ')
        return { given_name, family_name, email }
    }

    const fetchAllCoachesAndLicenses = async (
        fields,
        searchParams,
        filters,
        page,
        allEntries,
        rowsPerPage
    ) => {
        const { allCoaches, pgCount, total } = await getCoaches(
            fields,
            searchParams,
            filters,
            page,
            allEntries,
            rowsPerPage
        )

        if (allCoaches) {
            const coachesWithLicenses = await Promise.all(
                allCoaches.map(async (coach) => {
                    coach.attributes.licenses = await getCoachLicenses(
                        coach?.attributes?.uuid
                    )
                    return coach
                })
            )

            return { coachesWithLicenses, pgCount, total }
        }

        return null
    }

    const getAllCoaches = async (
        page,
        presetFilters = filterParams,
        allEntries = false
    ) => {
        const searchElement = document.getElementById(searchInputID)

        if (!searchElement) return

        setIsLoading(true)

        const searchParams = extractSearchParameters(searchElement.value)
        const fields = []
        const filtersToUse = presetFilters || filterParams

        const result = await fetchAllCoachesAndLicenses(
            fields,
            searchParams,
            filtersToUse,
            page,
            allEntries,
            rowsPerPage
        )
        const { coachesWithLicenses, pgCount, total } = result

        if (result) {
            if (allEntries) {
                setIsLoading(false)
                return coachesWithLicenses
            } else {
                setCoaches(coachesWithLicenses)
                setPageCount(pgCount)
                setTotal(total)
                setCurrentPage(page)
                setShowFilters(false)
            }
        }

        setIsLoading(false)
    }

    useEffect(() => {
        getAllCoaches(currentPage)
        handleCodebooks()
    }, [currentPage, rowsPerPage])

    const handlePdfExport = async () => {
        const filteredCoaches = await getAllCoaches(1, filterParams, true)
        setIsLoading(true)
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'portrait' // portrait or landscape

        const marginLeft = 40
        const doc = new jsPDF(orientation, unit, size)
        doc.addFileToVFS('WorkSans-normal.ttf', font)
        doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
        doc.setFont('WorkSans')
        doc.setFontSize(15)

        const title = `Treneri - Ukupno: ${
            filteredCoaches?.length
        }, ${formatDate(new Date())}`
        doc.text(title, marginLeft, 40)

        const rows = []

        for (let i = 1; i <= filteredCoaches?.length; i++) {
            const c = filteredCoaches[i - 1]
            rows.push([
                c?.attributes?.person_info?.given_name +
                    ' ' +
                    c.attributes?.person_info?.family_name,
                c?.attributes?.licenses?.attributes?.national_license
                    ? c?.attributes?.licenses?.attributes?.national_license
                          ?.national_license_name
                    : 'Nema informacije',
                c?.attributes?.licenses?.attributes?.itf_license
                    ? c?.attributes?.licenses?.attributes?.itf_license
                          ?.itf_license_name
                    : 'Nema informacije',
                c?.attributes?.licenses?.attributes?.gptca_license
                    ? c?.attributes?.licenses?.attributes?.gptca_license
                          ?.gptca_license_name
                    : 'Nema informacije',
                c?.attributes?.licenses?.attributes?.uspta_license
                    ? c?.attributes?.licenses?.attributes?.uspta_license
                          ?.uspta_license_name
                    : 'Nema informacije',
                c?.attributes?.licenses?.attributes?.lta_license
                    ? c?.attributes?.licenses?.attributes?.lta_license
                          ?.lta_license_name
                    : 'Nema informacije',
                c?.attributes?.licenses?.attributes?.ptr_license
                    ? c?.attributes?.licenses?.attributes?.ptr_license
                          ?.ptr_license_name
                    : 'Nema informacije',
            ])
        }

        let coachesContent = {
            startY: 50,
            head: [
                [
                    'Trener',
                    'Nacionalna licenca',
                    'ITF licenca',
                    'GPTCA licenca',
                    'USPTA licenca',
                    'LTA licenca',
                    'PTR licenca',
                ],
            ],
            body: rows,
            styles: {
                font: 'WorkSans',
            },
        }

        doc.autoTable(coachesContent)

        doc.save(`Treneri-${formatDate(new Date()).slice(0, -1)}.pdf`)
        setShowFilters(false)
        setIsLoading(false)
    }

    // Debounce function for search
    const updateDebounceText = debounce(async function (searchValue) {
        setCurrentPage(1)
        setPageCount(1)
        getAllCoaches(1)
    }, 1000)

    // Remove filters
    const handleDisableFiltering = () => {
        setFilterParams(initialFilters)
        setShowFilters(false)
        getAllCoaches(1, initialFilters)
        setCurrentPage(1)
        setPageCount(1)
    }

    return (
        <Row className="coaches-wrapper h-100">
            {coaches ? (
                <Col>
                    <Row className="pt-3">
                        <Col
                            xs={12}
                            md={4}
                            className="d-flex align-items-center justify-content-between"
                        >
                            <Row>
                                <Col className="">
                                    <h2 className="d-none d-md-block">
                                        Treneri
                                    </h2>
                                    <p className="d-block d-md-none">Treneri</p>
                                    <Breadcrumb className="mt-md-2 mt-1">
                                        <Breadcrumb.Item
                                            linkAs={Link}
                                            linkProps={{ to: `/pocetna` }}
                                        >
                                            Početna
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item active>
                                            Treneri
                                        </Breadcrumb.Item>
                                    </Breadcrumb>
                                </Col>
                            </Row>

                            <div className="d-flex align-items-center d-md-none">
                                <Button
                                    type="text"
                                    onClick={() => setShowFilters(true)}
                                >
                                    <OutlinedFilter />
                                </Button>

                                <Link to="/treneri/kreiraj-trenera">
                                    <Button type="text">
                                        <Add />
                                    </Button>
                                </Link>
                            </div>
                        </Col>
                        <Col xs={12} md={8}>
                            <Row className="d-flex align-items-center">
                                <div className="d-flex justify-content-end w-100 mt-2 mt-md-0">
                                    <SearchInput
                                        id={searchInputID}
                                        onChange={(e) =>
                                            updateDebounceText(e.target.value)
                                        }
                                        placeholder="Pronađi trenera po imenu ili prezimenu"
                                    />
                                    <Button
                                        type="secondary"
                                        className="mx-2 d-none d-md-block"
                                        onClick={() => setShowFilters(true)}
                                    >
                                        Filtriraj
                                    </Button>

                                    <Link
                                        className="d-none d-md-block"
                                        to="/treneri/kreiraj-trenera"
                                        tabIndex="1"
                                    >
                                        <Button>Kreiraj trenera</Button>
                                    </Link>
                                </div>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="m-0 pt-3">
                        <TableCoachesPagination
                            data={coaches}
                            className="d-none d-md-flex"
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            pageCount={pageCount}
                            total={total}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                        />
                        <TableCardCouches
                            data={coaches}
                            className="d-flex d-md-none background-table-card"
                        />
                    </Row>
                </Col>
            ) : (
                <Col
                    md={12}
                    className="d-flex flex-column align-items-center justify-content-center"
                >
                    <img
                        className="no-data-img"
                        src={turniriPNG}
                        alt="Još uvek nije kreiran nijedan trener"
                    ></img>
                    <h2 className="mt-5">Treneri</h2>
                    <p className="mt-2 text-center">
                        Još uvek nije kreiran nijedan trener. Klikni na dugme
                        'Kreiraj trenera' da bi ste kreirali novog.
                    </p>
                    <Link
                        to="/treneri/kreiraj-trenera"
                        style={{ textDecoration: 'none' }}
                        tabIndex="1"
                    >
                        <Button className="mt-4">Kreiraj trenera</Button>
                    </Link>
                </Col>
            )}

            <Offcanvas
                show={showFilters}
                onHide={() => setShowFilters(false)}
                placement="end"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <h3>Filtriraj</h3>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <hr className="p-0 mt-2 mb-2" />
                <Offcanvas.Body>
                    <Row>
                        <Col xs={12}>
                            <RadioButton
                                name="Pol"
                                checkedValue={filterParams?.gender || ''}
                                radioValues={genders}
                                onClick={(e) => {
                                    filterParams?.gender === e.target.value
                                        ? setFilterParams({
                                              ...filterParams,
                                              gender: '',
                                          })
                                        : null
                                }}
                                onChange={(e) => {
                                    setFilterParams({
                                        ...filterParams,
                                        gender: e.target.value,
                                    })
                                }}
                            />
                            <Dropdown
                                label={'Nacionalna licenca'}
                                value={filterParams?.national_license || ''}
                                placeholder="Nacionalna licenca"
                                handleSelect={(e) =>
                                    setFilterParams({
                                        ...filterParams,
                                        national_license:
                                            e === 'Odselektuj' ? '' : e,
                                    })
                                }
                                dropdownLinks={nationalLicense?.reduce(
                                    (accumulator, national_license) => [
                                        ...accumulator,
                                        national_license,
                                    ],
                                    filterParams?.national_license
                                        ? ['Odselektuj']
                                        : []
                                )}
                                className="mt-3"
                            />
                            <Dropdown
                                label={'ITF licenca'}
                                value={filterParams?.itf_license || ''}
                                placeholder="ITF licenca"
                                handleSelect={(e) =>
                                    setFilterParams({
                                        ...filterParams,
                                        itf_license:
                                            e === 'Odselektuj' ? '' : e,
                                    })
                                }
                                dropdownLinks={coachITF?.reduce(
                                    (accumulator, itf_license) => [
                                        ...accumulator,
                                        itf_license,
                                    ],
                                    filterParams?.itf_license
                                        ? ['Odselektuj']
                                        : []
                                )}
                                className="mt-3"
                            />
                            <Dropdown
                                label={'GPTCA licenca'}
                                value={filterParams?.gptca_license || ''}
                                placeholder="GPTCA licenca"
                                handleSelect={(e) =>
                                    setFilterParams({
                                        ...filterParams,
                                        gptca_license:
                                            e === 'Odselektuj' ? '' : e,
                                    })
                                }
                                dropdownLinks={coachGPTCA?.reduce(
                                    (accumulator, gptca_license) => [
                                        ...accumulator,
                                        gptca_license,
                                    ],
                                    filterParams?.gptca_license
                                        ? ['Odselektuj']
                                        : []
                                )}
                                className="mt-3"
                            />
                            <Dropdown
                                label={'USPTA licenca'}
                                value={filterParams?.uspta_license || ''}
                                placeholder="USPTA licenca"
                                handleSelect={(e) =>
                                    setFilterParams({
                                        ...filterParams,
                                        uspta_license:
                                            e === 'Odselektuj' ? '' : e,
                                    })
                                }
                                dropdownLinks={coachUSPTA?.reduce(
                                    (accumulator, uspta_license) => [
                                        ...accumulator,
                                        uspta_license,
                                    ],
                                    filterParams?.uspta_license
                                        ? ['Odselektuj']
                                        : []
                                )}
                                className="mt-3"
                            />
                            <Dropdown
                                label={'LTA licenca'}
                                value={filterParams?.lta_license || ''}
                                placeholder="LTA licenca"
                                handleSelect={(e) =>
                                    setFilterParams({
                                        ...filterParams,
                                        lta_license:
                                            e === 'Odselektuj' ? '' : e,
                                    })
                                }
                                dropdownLinks={coachLTA?.reduce(
                                    (accumulator, lta_license) => [
                                        ...accumulator,
                                        lta_license,
                                    ],
                                    filterParams?.lta_license
                                        ? ['Odselektuj']
                                        : []
                                )}
                                className="mt-3"
                            />
                            <Dropdown
                                label={'PTR licenca'}
                                value={filterParams?.ptr_license || ''}
                                placeholder="PTR licenca"
                                handleSelect={(e) =>
                                    setFilterParams({
                                        ...filterParams,
                                        ptr_license:
                                            e === 'Odselektuj' ? '' : e,
                                    })
                                }
                                dropdownLinks={coachPTR?.reduce(
                                    (accumulator, ptr_license) => [
                                        ...accumulator,
                                        ptr_license,
                                    ],
                                    filterParams?.ptr_license
                                        ? ['Odselektuj']
                                        : []
                                )}
                                className="mt-3"
                            />
                        </Col>
                        <Col md={12} className="mt-3">
                            <Button
                                className="w-100"
                                onClick={() => getAllCoaches(1)}
                            >
                                Prikaži rezultate
                            </Button>
                        </Col>
                        <Col md={12} className="mt-3">
                            <Button
                                type="secondary"
                                className="w-100"
                                onClick={handlePdfExport}
                            >
                                Štampaj rezultate
                            </Button>
                        </Col>
                        <Col md={12} className="mt-3">
                            <Button
                                type="text"
                                className="w-100"
                                onClick={() => handleDisableFiltering()}
                            >
                                Ukloni filtriranje
                            </Button>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>
        </Row>
    )
}

export default Coaches
