import axios from 'axios'

export const downloadImage = (photoUrl, name = 'Image_Download', extension) => {
    axios({
        url: photoUrl,
        method: 'GET',
        responseType: 'blob',
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${name}${extension}`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    })
}
