import axios from 'axios'
import { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Dropdown from '../../../../../components/Dropdown'
import MultiSelectSearch from '../../../../../components/MultiSelectSearch'
import TextField from '../../../../../components/TextField'
import { getCodebook } from '../../../../../lib/api'
import { numbersOnly } from '../../../../../lib/helpers'
import './styles.scss'

const ThirdStep = ({ setProgress, formData, setFormData }) => {
    const [availableCites, setAvailableCities] = useState([])
    const [selectedCity, setSelectedCity] = useState([])

    useEffect(() => {
        setProgress(75)
        if (formData?.residential_address?.city) {
            setSelectedCity({
                name: formData?.residential_address?.city,
                value: formData?.residential_address?.city,
            })
        }
        handleCodebooks()
    }, [])

    //* Codebooks
    const [regions, setRegions] = useState([])

    const handleCodebooks = async () => {
        await getCodebook('codebooks.region', setRegions)
    }

    const handleCity = (cityName) => {
        if (cityName?.length > 2) {
            axios
                .get(`${process.env.REACT_APP_GEONAMES_URL}/searchJSON`, {
                    params: {
                        name_startsWith: cityName?.toString(),
                        featureClass: 'P',
                        country: 'RS,XK', // Serbia as country preselected for clubs
                        maxRows: 5,
                        username: `${process.env.REACT_APP_GEONAMES_USERNAME}`,
                    },
                })
                .then((response) => {
                    const cityOptions = []
                    response.data?.geonames?.map((city) => {
                        cityOptions?.push({
                            value: `${city?.name}`,
                            name: city?.name,
                        })
                    })
                    setAvailableCities(cityOptions)
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {
            setAvailableCities([])
        }
    }

    const handleSelectCity = (cityName) => {
        if (!cityName) {
            setSelectedCity((_) => {
                return ''
            })
            setFormData((prev) => {
                return {
                    ...prev,
                    residential_address: {
                        ...prev?.residential_address,
                        city: '',
                    },
                }
            })
        } else {
            setFormData((prev) => ({
                ...prev,
                residential_address: {
                    ...prev?.residential_address,
                    city: cityName?.name,
                },
            }))
            availableCites.map((city) => {
                if (city?.value === cityName?.value) {
                    setSelectedCity((_) => {
                        return city
                    })
                }
            })
        }
    }

    return (
        <Row className="third-step-wrapper">
            <Col md={12} className="mt-3">
                <Dropdown
                    placeholder="Region"
                    label="Region"
                    value={formData?.region?.region_name || ''}
                    handleSelect={(e) =>
                        setFormData((prevState) => ({
                            ...prevState,
                            region: {
                                ...prevState.region,
                                region_name: e,
                            },
                        }))
                    }
                    dropdownLinks={regions}
                />
            </Col>
            <Col md={12} className="mt-3">
                <MultiSelectSearch
                    label={'Grad stanovanja'}
                    animated
                    options={availableCites}
                    noOptionsMessage={'Nema rezultata'}
                    onInputChange={(e) => handleCity(e)}
                    onChange={(e) => handleSelectCity(e)}
                    placeholder={'Pretraži i izaberi grad...'}
                    isMulti={false}
                    value={selectedCity}
                    isClearable={true}
                />
            </Col>
            <Col md={12} className="mt-3">
                <TextField
                    id="address"
                    name="address"
                    type="text"
                    value={formData.address?.residential_address?.street}
                    placeholder="Ulica i broj"
                    onChange={(e) => {
                        setFormData((prevState) => ({
                            ...prevState,
                            residential_address: {
                                ...prevState.residential_address,
                                street: e.target.value,
                            },
                        }))
                    }}
                    label="Ulica i broj"
                />
            </Col>
            <Col md={12} className="mt-3">
                <TextField
                    id="contactPhone"
                    name="contactPhone"
                    type="text"
                    value={formData?.person_info?.phone}
                    placeholder="Vaš broj telefona"
                    onChange={(e) => {
                        numbersOnly(e)
                        setFormData((prevState) => ({
                            ...prevState,
                            person_info: {
                                ...prevState.person_info,
                                phone: e.target.value,
                            },
                        }))
                    }}
                    label="Kontakt telefon"
                />
            </Col>
            <Col md={12} className="mt-3">
                <TextField
                    id="bank_name"
                    name="bank_name"
                    type="text"
                    value={formData?.bank_name}
                    onChange={(e) => {
                        setFormData((prevState) => ({
                            ...prevState,
                            bank_name: e.target.value,
                        }))
                    }}
                    label="Ime banke"
                    placeholder="Ime banke"
                />
            </Col>

            <Col md={12} className="mt-3">
                <TextField
                    id="account_number"
                    name="account_number"
                    type="text"
                    value={formData.bank_account_number}
                    onChange={(e) => {
                        setFormData((prevState) => ({
                            ...prevState,
                            bank_account_number: e.target.value,
                        }))
                    }}
                    label="Broj računa"
                    placeholder="Broj računa"
                />
            </Col>
        </Row>
    )
}

export default ThirdStep
