import jsPDF from 'jspdf'
import { formatDate } from '../../../../../../lib/helpers'
import { font } from '../../font'
const createDoc = (data, orientation, unit, size, font) => {
    const { competitionProfileData, referees } = data

    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size)
    doc.addFileToVFS('WorkSans-normal.ttf', font)
    doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
    doc.setFont('WorkSans')
    doc.setFontSize(15)

    const title = `${
        competitionProfileData?.attributes?.name
    }, početak (${formatDate(
        competitionProfileData?.attributes?.start_date
    )}) - SUĐENJE`
    doc.text(title, marginLeft, 40)

    const refereesList = []

    referees?.map((referee) => {
        refereesList?.push([
            `${referee?.attributes?.refereeData?.attributes?.person_info?.given_name} ${referee?.attributes?.refereeData?.attributes?.person_info?.family_name}`,
            referee?.attributes?.referee_type?.referee_type_name
                ? referee?.attributes?.referee_type?.referee_type_name
                : 'Nema informacije',
        ])
    })

    let generalInfoContent = {
        startY: 50,
        head: [['Sudija', 'Tip sudije']],
        body: refereesList,
        styles: {
            font: 'WorkSans',
        },
        columnStyles: {
            0: { cellWidth: 250 },
        },
    }

    doc.autoTable(generalInfoContent)

    return doc
}

export const handleExportRefereesTabPDF = (data) => {
    const unit = 'pt'
    const size = 'A4' // Use A1, A2, A3 or A4
    const orientation = 'portrait' // portrait or landscape

    const doc = createDoc(data, orientation, unit, size, font)

    doc.save(`${data?.competitionProfileData?.attributes?.name}-SUĐENJE.pdf`)
}

export const handlePrintRefereesTab = (data) => {
    const unit = 'pt'
    const size = 'A4' // Use A1, A2, A3 or A4
    const orientation = 'portrait' // portrait or landscape

    const doc = createDoc(data, orientation, unit, size, font)

    // for printing
    doc.autoPrint()
    doc.output('dataurlnewwindow')
}
