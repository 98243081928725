import React from 'react'

const ArrowRight = ({ disabled = false, ...props }) => {
    return (
        <svg
            width="12"
            height="21"
            viewBox="0 0 12 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                // fill-rule="evenodd"
                // clip-rule="evenodd"
                d="M0.947426 20.2954C0.389534 19.7376 0.389534 18.833 0.947426 18.2751L8.5087 10.7139L0.947425 3.15259C0.389533 2.5947 0.389533 1.69018 0.947425 1.13229C1.50532 0.574394 2.40984 0.574394 2.96773 1.13229L11.5392 9.70371C12.0971 10.2616 12.0971 11.1661 11.5392 11.724L2.96773 20.2954C2.40984 20.8533 1.50532 20.8533 0.947426 20.2954Z"
                fill={disabled ? '#96989b' : '#2C3136'}
            />
        </svg>
    )
}

export default ArrowRight
