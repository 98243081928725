import React, { useState } from 'react'
import { Row, Col, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { generateAvatar } from '../../utils/generateAvatar'
import { formatDate } from '../../lib/helpers'
import { useNavigate } from 'react-router-dom'
import Button from '../Button'
import TextField from '../TextField'
const PlayersMigrationCard = ({ competitor }) => {
    const navigate = useNavigate()
    const [showModal, setShowModal] = useState(false)
    const [insertedPassword, setInsertedPassword] = useState('')
    return (
        <Row className={`table-card-wrapper g-3`}>
            <Col xs={6} className="mt-3 d-flex align-items-center">
                <h5>Takmičar</h5>
            </Col>
            <Col xs={6} className="d-flex align-items-center p-0 mt-3">
                <img
                    src={
                        competitor?.attributes?.person_info?.picture
                            ? competitor?.attributes?.person_info?.picture
                            : generateAvatar(
                                  `${competitor?.attributes?.person_info?.given_name} ${competitor?.attributes?.person_info?.family_name}`
                              )
                    }
                    alt={`${competitor?.attributes?.person_info?.given_name}
                        ${competitor?.attributes?.person_info?.family_name}`}
                    width={'24px'}
                    height={'24px'}
                    className="me-2 competitor-table-card-avatar"
                />
                <Link to={`/takmicari/${competitor?.attributes?.uuid}`}>
                    <p className="link">
                        {competitor?.attributes?.person_info?.given_name}{' '}
                        {competitor?.attributes?.person_info?.family_name}
                    </p>
                </Link>
            </Col>
            <hr className="table-card-divider" />
            <Col xs={6} className="m-0 d-flex align-items-center">
                <h5>Stara šifra</h5>
            </Col>
            <Col xs={6} className="d-flex align-items-center p-0 m-0">
                <p>{competitor?.attributes?.legacy_id || '-'}</p>
            </Col>
            <hr className="table-card-divider" />
            <Col xs={6} className="m-0 d-flex align-items-center">
                <h5>Datum rođenja</h5>
            </Col>
            <Col xs={6} className="d-flex align-items-center p-0 m-0">
                <p>
                    {competitor?.attributes?.person_info?.birthdate
                        ? formatDate(
                              competitor?.attributes?.person_info?.birthdate
                          )
                        : '-'}
                </p>
            </Col>
            <hr className="table-card-divider" />
            <Col xs={6} className="m-0 d-flex align-items-center"></Col>
            <Col xs={6} className="m-0 d-flex align-items-center">
                <p className="link" onClick={() => setShowModal(true)}>
                    Uloguj se
                </p>
            </Col>

            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Potvrda identiteta</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <p>
                                Ime i prezime:{' '}
                                {
                                    competitor?.attributes?.person_info
                                        ?.given_name
                                }{' '}
                                {
                                    competitor?.attributes?.person_info
                                        ?.family_name
                                }
                            </p>
                            <p>
                                Datum rodjenja:{' '}
                                {formatDate(
                                    competitor?.attributes?.person_info
                                        ?.birthdate
                                )}
                            </p>
                            <h5 className="my-2">Lozinka</h5>
                            <TextField
                                type="text"
                                placeholder="Unesite vašu staru lozinku"
                                value={insertedPassword || ''}
                                onChange={(e) => {
                                    setInsertedPassword(e.target.value)
                                }}
                            />

                            {false && (
                                <p className="error-p mt-3">
                                    Uneli ste pogrešnu lozinku.
                                </p>
                            )}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="secondary"
                        onClick={() => setShowModal(false)}
                    >
                        Odustani
                    </Button>
                    <Button
                        onClick={() =>
                            navigate(
                                `/obnova-starog-naloga/${competitor?.attributes?.uuid}`
                            )
                        }
                        disabled={
                            +insertedPassword ===
                            +competitor?.attributes?.legacy_id * 3 + 7
                                ? false
                                : true
                        }
                    >
                        Potvrdi
                    </Button>
                </Modal.Footer>
            </Modal>
        </Row>
    )
}

export default PlayersMigrationCard
