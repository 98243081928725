import { Table } from 'react-bootstrap'

import PaginationWrapper from '../../Pagination'
import '../styles.scss'
import ClubAssemblyRankingTableRow from './clubAssemblyRankingTableRow'

const ClubsAssemblyRankingTable = ({
    data,
    className,
    currentPage,
    setCurrentPage,
    pageCount,
    total,
    rowsPerPage,
    setRowsPerPage,
    setRefreshData,
}) => {
    return (
        <div className={`table-clubs-ranking-wrapper ${className}`}>
            <Table className="mb-0" responsive>
                <thead>
                    <tr>
                        <th className="d-flex align-items-center justify-content-center">
                            <h5>Broj glasova</h5>
                        </th>
                    </tr>
                    <tr>
                        <th style={{ minWidth: '240px' }}>
                            <h5
                                style={{
                                    marginLeft: '64px',
                                }}
                            >
                                Klub
                            </h5>
                        </th>
                        <th style={{ minWidth: '100px' }}>
                            <h5>Registrovani igrači</h5>
                        </th>
                        <th style={{ minWidth: '80px' }}>
                            <h5>Rangirani igrači</h5>
                        </th>
                        <th style={{ minWidth: '76px' }}>
                            <h5>Tereni</h5>
                        </th>
                        <th style={{ minWidth: '100px' }}>
                            <h5>Kvalitet klubova</h5>
                        </th>
                        <th style={{ minWidth: '134px' }}>
                            <h5>Organizacija međunarodnih turnira</h5>
                        </th>
                        <th style={{ minWidth: '120px' }}>
                            <h5>
                                Dodatni glasovi za organizaciju međ. turnira
                            </h5>
                        </th>
                        <th style={{ minWidth: '134px' }}>
                            <h5>Internacionalni rang</h5>
                        </th>
                        <th style={{ minWidth: '56px', maxWidth: '90px' }}>
                            <h5>Ukupno</h5>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((club, index) => (
                        <ClubAssemblyRankingTableRow
                            key={index}
                            position={
                                currentPage * rowsPerPage +
                                (index + 1) -
                                rowsPerPage
                            }
                            club={club}
                            setRefreshData={setRefreshData}
                        />
                    ))}
                </tbody>
            </Table>
            <PaginationWrapper
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageCount={pageCount}
                total={total}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
            />
        </div>
    )
}

export default ClubsAssemblyRankingTable
