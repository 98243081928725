import jsPDF from 'jspdf'
import 'jspdf-autotable'
import React, { Fragment, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import CloseOutlined from '../../assets/svg/CloseOutlined'
import DocumentTextOutlined from '../../assets/svg/DocumentTextOutlined'
import PrintOutlined from '../../assets/svg/PrintOutlined'
import { font } from '../../containers/Admin/Competitions/CompetitionProfile/font'
import { useAppContext } from '../../lib/contextLib'
import { formatDate, isArrayEmpty } from '../../lib/helpers'
import ScrollToTop from '../../lib/hooks/ScrollToTop'
import Button from '../Button'

import { Link, useParams } from 'react-router-dom'
import {
    getPlayersClubHistory,
    getSingleClub,
    removeClubFromPlayerHistory,
} from '../../lib/api'
import ConfirmationModal from '../ConfirmationModal'
import NoDataForView from '../NoDataForView'
import ClubHistoryCard from './ClubHistoryCard'
import './styles.scss'

const ClubHistory = ({ profileData, setShowClubHistory }) => {
    const { id } = useParams()
    const { user, setShowCustomToast } = useAppContext()
    const [clubHistory, setClubHistory] = useState([])
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)

    // Handle PDF Logic
    const handlePdfExport = (print = false) => {
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'portrait' // portrait or landscape

        const marginLeft = 40
        const doc = new jsPDF(orientation, unit, size)
        doc.addFileToVFS('WorkSans-normal.ttf', font)
        doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
        doc.setFont('WorkSans')
        doc.setFontSize(15)

        const title = `${profileData?.attributes?.person_info?.given_name} ${
            profileData?.attributes?.person_info?.family_name
        } - Istorija klubova (${formatDate(Date.now())})`
        doc.text(title, marginLeft, 40)

        const rows = []
        clubHistory.forEach((clubEntry) => {
            rows.push([
                clubEntry?.club?.attributes?.name,
                clubEntry?.attributes?.is_loan ? 'Pozajmica' : 'Transfer',
                formatDate(clubEntry?.attributes?.joining_date),
                formatDate(clubEntry?.attributes?.departure_date),
            ])
        })

        let positionContent = {
            startY: 50,
            head: [['Klub', 'Tip', 'Datum od', 'Datum do']],
            body: rows,
            styles: {
                font: 'WorkSans',
            },
        }

        doc.autoTable(positionContent)

        if (print) {
            doc.autoPrint()
            var blob = doc.output('blob')
            window.open(URL.createObjectURL(blob), '_blank')
        } else {
            doc.save(
                `${profileData?.attributes?.person_info?.given_name}_${
                    profileData?.attributes?.person_info?.family_name
                }_Istorija_klubova_takmičara_(${formatDate(Date.now())}).pdf`
            )
        }
    }

    const handleDeleteEntryFromClubHistory = async (clubEntryUuid) => {
        try {
            const deletedEntry = await removeClubFromPlayerHistory(
                clubEntryUuid
            )
            if (!deletedEntry) return
            setShowConfirmationModal((_) => {
                return false
            })
            handleGetPlayerClubHistory()
        } catch (error) {
            console.error(error)
        }
    }

    const handlePlayersClubsHistory = async (playersClubs) => {
        const clubs = playersClubs?.map(async (clubItem) => {
            const obj = await getSingleClub(clubItem?.attributes?.club_uuid)
            return { ...clubItem, club: { ...obj } }
        })
        const clubsObjects = await Promise.all(clubs)
        return clubsObjects
    }

    const handleGetPlayerClubHistory = async () => {
        const playersClubHistoryData = await getPlayersClubHistory(
            profileData?.attributes?.uuid,
            1000
        )

        if (playersClubHistoryData) {
            const playersClubs = await handlePlayersClubsHistory(
                playersClubHistoryData
            )
            if (playersClubs) {
                setClubHistory(playersClubs)
            } else {
                setClubHistory([])
            }
        }
    }

    useEffect(() => {
        handleGetPlayerClubHistory()
    }, [id, profileData])

    return (
        <>
            <Row className="medical-history-view">
                <ScrollToTop />
                <Col className="medical-history-wrapper">
                    <Row>
                        <Col className="d-flex align-items-center justify-content-end close-icon">
                            <CloseOutlined
                                onClick={() => setShowClubHistory(false)}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-center flex-column">
                        <Col className="d-flex align-items-center justify-content-center medical-history-title">
                            <h1 className="text-center">
                                Istorija klubova takmičara
                            </h1>
                        </Col>

                        {!isArrayEmpty(clubHistory) ? (
                            <Col
                                xs={12}
                                className="d-flex flex-column align-items-center justify-content-center align-self-center medical-history-container"
                            >
                                <Row className="w-100">
                                    <Col
                                        xs={12}
                                        className={`add-medical-col d-flex d-md-none align-items-center ${
                                            user?.role?.type === 'admin'
                                                ? 'justify-content-between'
                                                : 'justify-content-around'
                                        } gx-2`}
                                    >
                                        <Button
                                            type="text"
                                            onClick={() =>
                                                handlePdfExport(true)
                                            }
                                        >
                                            <PrintOutlined height="24px" />
                                        </Button>
                                        <Button
                                            type="text"
                                            onClick={() => handlePdfExport()}
                                        >
                                            <DocumentTextOutlined height="24px" />
                                        </Button>
                                    </Col>
                                    <Col
                                        xs={12}
                                        className={`d-md-flex d-none align-items-center justify-content-between single-medical`}
                                    >
                                        <Col className="d-flex justify-content-center text-center">
                                            <h5 className={`medical-header`}>
                                                Klub
                                            </h5>
                                        </Col>
                                        <Col className="d-flex justify-content-center">
                                            <h5 className={`medical-header`}>
                                                Tip
                                            </h5>
                                        </Col>
                                        <Col className="d-flex justify-content-center">
                                            <h5 className={`medical-header`}>
                                                Datum od
                                            </h5>
                                        </Col>
                                        <Col className="d-flex justify-content-center">
                                            <h5 className={`medical-header`}>
                                                Datum do
                                            </h5>
                                        </Col>
                                        {user?.role?.type === 'admin' && (
                                            <Col className="d-flex justify-content-center"></Col>
                                        )}
                                    </Col>
                                </Row>
                                <div className="medical-history-list">
                                    {clubHistory?.map((clubEntry, index) => (
                                        <Fragment key={index}>
                                            <Col
                                                className={`d-md-flex d-none align-items-center justify-content-between single-medical`}
                                            >
                                                <Col className="d-flex justify-content-center">
                                                    <p className="medical-info-text">
                                                        <span className="link">
                                                            <Link
                                                                to={`/klubovi/${clubEntry?.club?.attributes?.uuid}`}
                                                                tabIndex="1"
                                                            >
                                                                {
                                                                    clubEntry
                                                                        ?.club
                                                                        ?.attributes
                                                                        ?.name
                                                                }
                                                            </Link>
                                                        </span>
                                                    </p>
                                                </Col>
                                                <Col className="d-flex justify-content-center">
                                                    <p className="medical-info-text">
                                                        {clubEntry?.attributes
                                                            ?.is_loan
                                                            ? 'Pozajmica'
                                                            : 'Transfer'}
                                                    </p>
                                                </Col>
                                                <Col className="d-flex justify-content-center">
                                                    <p className="medical-info-text">
                                                        {formatDate(
                                                            clubEntry
                                                                ?.attributes
                                                                ?.joining_date
                                                        )}
                                                    </p>
                                                </Col>
                                                <Col className="d-flex justify-content-center">
                                                    <p className="medical-info-text">
                                                        {formatDate(
                                                            clubEntry
                                                                ?.attributes
                                                                ?.departure_date
                                                        )}
                                                    </p>
                                                </Col>

                                                {user?.role?.type ===
                                                    'admin' && (
                                                    <Col className="d-flex justify-content-center">
                                                        <p
                                                            className="red-link"
                                                            onClick={() =>
                                                                setShowConfirmationModal(
                                                                    (_) => {
                                                                        return clubEntry
                                                                            ?.attributes
                                                                            ?.uuid
                                                                    }
                                                                )
                                                            }
                                                        >
                                                            Ukloni
                                                        </p>
                                                    </Col>
                                                )}
                                            </Col>
                                            <ClubHistoryCard
                                                isWhite={
                                                    index % 2 !== 0
                                                        ? true
                                                        : false
                                                }
                                                clubEntry={clubEntry}
                                                profileData={profileData}
                                                setShowConfirmationModal={
                                                    setShowConfirmationModal
                                                }
                                                className="d-md-none"
                                            />
                                        </Fragment>
                                    ))}
                                </div>
                                <Col className="d-md-flex d-none align-items-center justify-content-end medical-history-buttons-wrapper px-0">
                                    <Button
                                        type="text"
                                        onClick={() => handlePdfExport()}
                                        className="me-2"
                                    >
                                        Eksportuj u PDF
                                    </Button>

                                    <Button
                                        type="secondary"
                                        onClick={() => handlePdfExport(true)}
                                    >
                                        Štampaj
                                    </Button>
                                </Col>
                            </Col>
                        ) : (
                            <NoDataForView
                                title="Istorija klubova je prazna"
                                description={
                                    'Nema istorije klubova za ovog takmičara.'
                                }
                                className="border-0"
                                column
                            />
                        )}
                    </Row>
                </Col>
            </Row>
            {/* MODAL -  BRISANJE UNOSA */}

            <ConfirmationModal
                show={showConfirmationModal}
                modalText="Jeste li sigurni da želite obrisati ovaj unos iz istorije klubova za ovog takmičara?"
                onHide={() =>
                    setShowConfirmationModal((_) => {
                        return false
                    })
                }
                onClick={() =>
                    handleDeleteEntryFromClubHistory(showConfirmationModal)
                }
            />
        </>
    )
}

export default ClubHistory
