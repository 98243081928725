import * as React from 'react'

const Warning = (props) => (
    <svg
        width={21}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="m18.343 16.222-7.017-13.03a1.317 1.317 0 0 0-2.32 0l-7.015 13.03a1.318 1.318 0 0 0 1.158 1.942h14.034a1.317 1.317 0 0 0 1.16-1.942Zm-8.176-.075a.823.823 0 1 1 0-1.646.823.823 0 0 1 0 1.646Zm.894-8.28-.236 5.022a.659.659 0 1 1-1.318 0l-.236-5.02a.895.895 0 0 1 .885-.935h.009a.895.895 0 0 1 .894.935l.002-.003Z"
            fill="#EB7F00"
        />
    </svg>
)

export default Warning
