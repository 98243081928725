import { Col, Row } from 'react-bootstrap'
import Welcome from '../../components/Welcome'
import Statistics from './Statistics'

import './styles.scss'

const AdminWrapper = () => {
    return (
        <Row className="admin-wrapper">
            <Col>
                <Welcome />
                <Statistics />
            </Col>
        </Row>
    )
}

export default AdminWrapper
