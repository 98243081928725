import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { createMedical } from '../../../lib/api'
import { useAppContext } from '../../../lib/contextLib'
import {
    competitorPillStatusHandler,
    formatDate,
    formatImgUrl,
} from '../../../lib/helpers'
import { generateAvatar } from '../../../utils/generateAvatar'
import { pillStatusHandler } from '../../../utils/pillHandler'
import Button from '../../Button'
import FileUploadTag from '../../FileUploadTag'
import TextField from '../../TextField'
import moment from 'moment'

function SingleCompetitorRow({
    competitor,
    setCurrentPage,
    setRefreshData,
    fromHomePage = false,
    tabValue,
}) {
    const navigate = useNavigate()
    //* MEDICAL DATA STATES
    const [medicalData, setMedicalData] = useState({
        start_date: '',
        end_date: '',
    })

    const [medicalImg, setMedicalImg] = useState(null)
    const [medicalImgTitle, setMedicalImgTitle] = useState('')
    //* MEDICAL DATA STATES

    const [openModal, setOpenModal] = useState(false)
    const [disabledAddMedical, setDisableAddMedical] = useState(true)

    const { setShowCustomToast } = useAppContext()

    const inputRef = useRef(null)

    const addPhoto = (e) => {
        const fileReader = new FileReader()
        const file = e.target.files[0]
        setMedicalImgTitle((_) => {
            return file?.name
        })
        const fileSizeKilobytes = file.size / 1024
        if (checkSize(fileSizeKilobytes, 2048)) return
        else {
            fileReader.onload = () => {
                setMedicalImg((_) => {
                    return file
                })
            }
            fileReader.readAsDataURL(file)
        }
    }

    const checkSize = (fileSize, limit) => {
        if (fileSize > limit) {
            alert('Fajl je prevelik')
            return limit
        } else return
    }

    const handleOpenFileInput = () => {
        inputRef.current.click()
    }

    const updateMedicalField = (field, value) => {
        setMedicalData((prev) => ({ ...prev, [field]: value }))
    }

    const submitMedical = async () => {
        try {
            const { certificateData } = await createMedical(
                {
                    ...medicalData,
                    member_uuid: competitor?.attributes?.uuid,
                },
                medicalImg
            )

            if (certificateData) {
                setRefreshData(true)
                setCurrentPage(1)
                setOpenModal(false)
                setMedicalImg(null)
                setMedicalImgTitle('')
                setMedicalData({
                    start_date: '',
                    end_date: '',
                })
                setShowCustomToast({
                    show: true,
                    type: 'success',
                    message: 'Lekarski pregled uspešno produžen.',
                })
            }
        } catch (error) {
            setShowCustomToast({
                show: true,
                type: 'error',
                message: 'Došlo je do greške.',
            })
        }
    }

    const handleEdit = () => {
        navigate(
            `/takmicari/${competitor?.attributes?.uuid}/izmeni-profil-takmicara`
        )
    }

    const calculateEndDate = (startDate) => {
        const endDate = moment(startDate).add(6, 'months')
        return endDate.format('YYYY-MM-DD')
    }

    const updateStartDate = (newStartDate) => {
        updateMedicalField('start_date', newStartDate)

        const newEndDate = calculateEndDate(newStartDate)
        updateMedicalField('end_date', newEndDate)
    }

    useEffect(() => {
        if (
            medicalImgTitle &&
            medicalData.start_date &&
            medicalData.end_date &&
            medicalImg
        ) {
            setDisableAddMedical(false)
        } else {
            setDisableAddMedical(true)
        }
    }, [medicalData, medicalImg, medicalImgTitle])

    return (
        <>
            <tr>
                <td style={{ minWidth: '100px' }} className="link">
                    <div className="d-flex align-items-center justify-content-start">
                        <img
                            src={
                                competitor?.attributes?.person_info?.picture
                                    ? competitor?.attributes?.person_info
                                          ?.picture
                                    : generateAvatar(
                                          `${competitor?.attributes?.person_info?.given_name} ${competitor?.attributes?.person_info?.family_name}`
                                      )
                            }
                            alt={`${competitor?.attributes?.person_info?.given_name}
                            ${competitor?.attributes?.person_info?.family_name}`}
                            className="me-2 table-competitors-avatar"
                        />
                        <Link to={`/takmicari/${competitor?.attributes?.uuid}`}>
                            <p className="link">
                                {
                                    competitor?.attributes?.person_info
                                        ?.given_name
                                }{' '}
                                {
                                    competitor?.attributes?.person_info
                                        ?.family_name
                                }
                            </p>
                        </Link>
                    </div>
                </td>
                <td style={{ minWidth: '70px' }}>
                    <p>
                        {competitor?.attributes?.person_info?.birthdate
                            ? formatDate(
                                  competitor?.attributes?.person_info?.birthdate
                              )
                            : '-'}
                    </p>
                </td>
                <td
                    style={{ minWidth: '100px' }}
                    className="d-flex align-items-center justify-content-start"
                >
                    {competitor?.attributes?.club?.attributes?.name ? (
                        <>
                            <img
                                src={
                                    competitor?.attributes?.club?.attributes
                                        ?.logo
                                        ? formatImgUrl(
                                              competitor?.attributes?.club
                                                  ?.attributes?.logo?.url
                                          )
                                        : generateAvatar(
                                              competitor?.attributes?.club
                                                  ?.attributes?.name
                                          )
                                }
                                alt={`Logo kluba`}
                                width={'24px'}
                                height={'24px'}
                                style={{
                                    maxHeight: '24px',
                                    maxWidth: '24px',
                                    borderRadius: '50%',
                                }}
                            />
                            <Link
                                to={`/klubovi/${competitor?.attributes?.club?.attributes?.uuid}`}
                            >
                                <p className="ms-2 link">
                                    {
                                        competitor?.attributes?.club?.attributes
                                            ?.name
                                    }
                                </p>
                            </Link>
                        </>
                    ) : (
                        <p>-</p>
                    )}
                </td>
                <td style={{ minWidth: '100px' }}>
                    <p>
                        {formatDate(
                            competitor?.attributes?.last_medical?.attributes
                                ?.start_date
                        )}{' '}
                        -{' '}
                        {formatDate(
                            competitor?.attributes?.last_medical?.attributes
                                ?.end_date
                        )}
                    </p>
                </td>

                <td style={{ minWidth: '100px' }}>
                    {pillStatusHandler(
                        competitorPillStatusHandler(
                            tabValue === 'kaznjeni'
                                ? 'punished'
                                : competitor?.attributes?.status?.status_name
                        )
                    )}
                </td>
                {!fromHomePage && (
                    <>
                        <td
                            style={{ minWidth: '100px' }}
                            className="link link-align-end"
                            onClick={() => setOpenModal(true)}
                        >
                            Produži lekarski pregled
                        </td>
                        <td
                            style={{ minWidth: '100px' }}
                            className="link link-align-end"
                            onClick={handleEdit}
                        >
                            Izmeni
                        </td>
                    </>
                )}
            </tr>
            {/* MODAL - PRODUŽI LEKARSKO */}
            <Modal
                size="md"
                centered
                show={openModal}
                onHide={() => {
                    setOpenModal(false)
                    setMedicalData({})
                    setMedicalImg(null)
                    setMedicalImgTitle('')
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>Produži lekarski pregled</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TextField
                        type="date"
                        label="Datum obavljenog pregleda"
                        className="mb-4"
                        value={medicalData?.start_date || ''}
                        onChange={(e) => updateStartDate(e.currentTarget.value)}
                        required
                    />
                    <TextField
                        type="date"
                        label="Lekarski pregled važi do"
                        value={medicalData?.end_date || ''}
                        min={medicalData?.start_date}
                        onChange={(e) =>
                            updateMedicalField(
                                'end_date',
                                e.currentTarget.value
                            )
                        }
                        required
                    />
                    {medicalImg && (
                        <FileUploadTag
                            className="mt-3"
                            fileName={medicalImgTitle}
                            deleteFile={() => {
                                setMedicalImg((_) => {
                                    return null
                                })
                                setMedicalImgTitle((_) => {
                                    return ''
                                })
                                inputRef.current.value = null
                            }}
                        />
                    )}
                    <span className="mt-4 mb-4 d-flex">
                        <p>Uploaduj sliku ili sken lekarskog pregleda</p>
                        <h6 className="required-asterisk ms-1">*</h6>
                    </span>
                    <label onClick={handleOpenFileInput} className="w-100">
                        <Button
                            type="secondary"
                            className="w-100"
                            disabled={medicalImg ? true : false}
                        >
                            Uploaduj lekarski pregled
                        </Button>
                    </label>
                    <form>
                        <input
                            ref={inputRef}
                            type="file"
                            id="medicalImage"
                            name="medicalImage"
                            className="d-none invisible"
                            onChange={(e) => addPhoto(e)}
                            accept="image/jpg, image/jpeg, image/png"
                            disabled={medicalImg ? true : false}
                        />
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="text" onClick={() => setOpenModal(false)}>
                        Odustani
                    </Button>
                    <Button
                        onClick={submitMedical}
                        disabled={disabledAddMedical}
                    >
                        Produži
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SingleCompetitorRow
