import { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import logoTss from '../../assets/images/logo-longer.png'
import Button from '../../components/Button'
import TextField from '../../components/TextField'
import { changePassword } from '../../lib/api'

import Eye from '../../assets/svg/Eye'
import EyeOff from '../../assets/svg/EyeOff'
import './styles.scss'

const NewPassword = () => {
    const navigate = useNavigate()
    const [code, setCode] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [visibility, setVisibility] = useState(false)
    const [visibilityRepeat, setVisibilityRepeat] = useState(false)
    const [formData, setFormData] = useState({
        code: code,
        password: '',
        passwordConfirmation: '',
    })
    const [disableButton, setDisableButton] = useState(true)

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleLogin = async () => {
        try {
            const changedPassword = await changePassword(formData)

            if (changedPassword) {
                setShowModal(true)
                setFormData({
                    ...formData,
                    password: '',
                    passwordConfirmation: '',
                })
            }
        } catch (error) {
            setErrorMessage('Došlo je do greške. Pokušajte ponovo.')
            console.error(error)
        }
    }

    const togglePassword = (id, index) => {
        let element = document.getElementById(id)
        if (element.type === 'password') {
            element.type = 'text'
            index === 1 ? setVisibility(true) : setVisibilityRepeat(true)
        } else if (element.type === 'text') {
            element.type = 'password'
            index === 1 ? setVisibility(false) : setVisibilityRepeat(false)
        }
    }

    const handleFieldValidation = () => {
        if (
            formData?.password?.length > 5 &&
            formData?.password === formData?.passwordConfirmation
        ) {
            setDisableButton(false)
        } else {
            setDisableButton(true)
        }
    }

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        const codeValue = params.get('code')
        setFormData({
            ...formData,
            code: codeValue,
        })
        setCode(codeValue)
    }, [])

    useEffect(() => {
        handleFieldValidation()
    }, [formData])

    return (
        <Row className="mt-5 new-password-wrapper">
            <Col md={4}></Col>
            <Col md={4}>
                <Row>
                    <Col
                        md={12}
                        className="d-flex align-items-center justify-content-center"
                    >
                        <img
                            src={logoTss}
                            alt="logo-tss"
                            className="tss-logo"
                        />
                    </Col>
                    <Col
                        md={12}
                        className="mb-2 d-flex header align-items-center justify-content-center text-center"
                    >
                        <h1>Zaboravljena / Promena lozinke</h1>
                    </Col>
                    <Col md={12} className="mt-4">
                        <TextField
                            id="account-new-password"
                            name="password"
                            type="password"
                            required
                            label="Nova lozinka (min. 6 karaktera)"
                            placeholder="Unesite novu lozinku"
                            value={formData.password}
                            onChange={onChange}
                            endIcon={visibility ? <EyeOff /> : <Eye />}
                            onIconClick={() =>
                                togglePassword('account-new-password', 1)
                            }
                        />
                    </Col>
                    <Col md={12} className="mt-3">
                        <TextField
                            id="account-new-password-repeat"
                            name="passwordConfirmation"
                            type="password"
                            required
                            label="Potvrda lozinke"
                            placeholder="Potvrdite novu lozinku"
                            value={formData.passwordConfirmation}
                            onChange={onChange}
                            endIcon={visibilityRepeat ? <EyeOff /> : <Eye />}
                            onIconClick={() =>
                                togglePassword('account-new-password-repeat', 2)
                            }
                        />
                    </Col>
                    <Col className="mt-4 mb-5 d-flex align-items-center justify-content-end">
                        <Link to="/" className="link-text" tabIndex="1">
                            <Button type={'text'} className="me-2">
                                Odustani
                            </Button>
                        </Link>
                        <Button onClick={handleLogin} disabled={disableButton}>
                            Sledeća
                        </Button>
                    </Col>
                    <Col
                        md={12}
                        className="logo-below mt-5 pt-5 d-flex align-items-center justify-content-center"
                    >
                        <p className="chainapp-text">
                            Razvija:{' '}
                            <a
                                href="https://chainapp.tech/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Chainapp Technologies doo
                            </a>
                        </p>{' '}
                    </Col>
                </Row>
            </Col>
            <Col md={4}></Col>

            <Modal centered show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>{errorMessage ? 'Greška' : 'Uspešno'}</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {errorMessage
                            ? errorMessage
                            : 'Uspešno ste izvršili promenu lozinke. Ulogujte se sada sa novim kredencijalima.'}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => navigate('/')}>Login strana</Button>
                </Modal.Footer>
            </Modal>
        </Row>
    )
}

export default NewPassword
