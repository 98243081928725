import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

function ScrollToTop({ step }) {
    const { pathname } = useLocation()

    useEffect(() => {
        const mainScrollContainer = document.getElementById(
            'main-scroll-wrapper'
        )
        pathname !== '/' &&
            mainScrollContainer.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
    }, [pathname, step && step])

    return null
}

export default ScrollToTop
