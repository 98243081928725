import axios from 'axios'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { useEffect, useState } from 'react'
import { Breadcrumb, Col, Offcanvas, Row, Tab, Tabs } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import Add from '../../../assets/svg/Add'
import OutlinedFilter from '../../../assets/svg/OutlinedFilter'
import AsyncSelect from '../../../components/AsyncSelect'
import Button from '../../../components/Button'
import Dropdown from '../../../components/Dropdown'
import MultiSelectSearch from '../../../components/MultiSelectSearch'
import NoDataForView from '../../../components/NoDataForView'
import PlayerInvitationModal from '../../../components/PlayerInvitationModal'
import RadioButton from '../../../components/RadioButton'
import { RowsPerPageOptions } from '../../../components/RowsPerPageDropdown'
import SearchInput from '../../../components/SearchInput'
import TableCardCompetitor from '../../../components/TableCardCompetitor'
import TableCompetitorsPagination from '../../../components/TableCompetitorsPagination'
import TextField from '../../../components/TextField'
import { getCodebook, getPlayers } from '../../../lib/api'
import { useAppContext } from '../../../lib/contextLib'
import {
    competitorPillStatusHandler,
    debounce,
    formatDate,
    getClubList,
    isArrayEmpty,
    isPlayerDisciplinaryPunished,
    numbersOnly,
} from '../../../lib/helpers'
import ScrollToTop from '../../../lib/hooks/ScrollToTop'
import { font } from '../Competitions/CompetitionProfile/font'
import './styles.scss'

const searchInputID = `searchInput-competitors`

const Competitors = () => {
    const location = useLocation()
    let activeTab
    if (location.state && location.state.activeTab) {
        activeTab = location.state.activeTab
    }
    //* Context
    const {
        previousLoadedCompetitors,
        yearsList,
        setShowCustomToast,
        setIsLoading,
    } = useAppContext()

    //* Pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [total, setTotal] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(RowsPerPageOptions[0])

    const [refreshData, setRefreshData] = useState(false)

    //* Codebooks
    const [genders, setGenders] = useState([])
    const [regions, setRegions] = useState([])
    const [playersCompetition, setPlayersCompetition] = useState([])

    const handleCodebooks = async () => {
        await getCodebook('codebooks.gender', setGenders)
        await getCodebook('codebooks.region', setRegions)
        await getCodebook('codebooks.players-competition').then((result) => {
            setPlayersCompetition(
                result.reduce((accumulator, currentValue) => {
                    if (
                        !['Seniorke', 'I Liga Seniori', '10', '45'].includes(
                            currentValue
                        )
                    ) {
                        return [...accumulator, currentValue]
                    }
                    return accumulator
                }, [])
            )
        })
    }

    //* Filters
    const [showFilters, setShowFilters] = useState(false)
    const initialFilters = {
        gender: '',
        birthdate: '',
        status: activeTab ? 'draft' : 'active',
        searchDraft: activeTab ? true : false,
    }
    const [filterParams, setFilterParams] = useState(initialFilters)

    //* Selected tab
    const [selectedTab, setSelectedTab] = useState(activeTab || 'aktivni')

    //* Club selector in MultiSelectSearch
    const [selectedClub, setSelectedClub] = useState()

    //* Competitors list
    const [competitorsList, setCompetitorsList] = useState(
        previousLoadedCompetitors
    )

    //* Modals
    const [showInvitationModal, setShowInvitationModal] = useState(false)

    //* States
    const [availableCountries, setAvailableCountries] = useState([])
    const [availableCites, setAvailableCities] = useState([])
    const [selectedCountry, setSelectedCountry] = useState('')
    const [selectedCity, setSelectedCity] = useState('')
    const competitorCompetitions = [
        '10s - Crveni',
        '10s - Narandžasti',
        '10s - Zeleni',
    ]

    const handleClubSelect = (e) => {
        if (!e) {
            setSelectedClub((_) => {
                return null
            })
            filterParams.club_uuid = ''
        } else {
            const { uuid, value } = e

            setSelectedClub({ uuid: uuid, value: value })
            setFilterParams({ ...filterParams, club_uuid: uuid })
        }
    }

    //* Handling competitors by their statuses
    const handleTabAndFilters = (tab) => {
        switch (tab) {
            case 'aktivni':
                return setFilterParams((prev) => {
                    let previous = prev
                    previous.hasOwnProperty('punished') &&
                        delete previous.punished
                    return {
                        ...previous,
                        status: 'active',
                        searchDraft: false,
                    }
                })
            case 'draft':
                return setFilterParams((prev) => {
                    let previous = prev
                    previous.hasOwnProperty('punished') &&
                        delete previous.punished
                    return {
                        ...previous,
                        status: 'draft',
                        searchDraft: true,
                    }
                })
            case 'neaktivni':
                return setFilterParams((prev) => {
                    let previous = prev
                    previous.hasOwnProperty('punished') &&
                        delete previous.punished
                    return {
                        ...previous,
                        status: 'inactive',
                        searchDraft: false,
                    }
                })
            case 'kaznjeni':
                return setFilterParams((prev) => {
                    let previous = prev
                    previous.hasOwnProperty('status') && delete previous.status
                    previous.punished = true
                    return previous
                })
            // case 'zahtevaju-izmenu':
            //     return setFilterParams((prev) => {
            //         let previous = prev
            //         // previous.hasOwnProperty('isActive') &&
            //         //     delete previous.isActive
            //         // previous.hasOwnProperty('isPunished') &&
            //         //     delete previous.isPunished
            //         previous.hasOwnProperty('status') && delete previous.status
            //         return previous
            //     })
            default:
                return
        }
    }

    //* Getting all competitors
    const getAllCompetitors = async (
        page,
        presetFilters,
        allEntries = false
    ) => {
        let search = document.getElementById(searchInputID)
        setIsLoading(true)
        if (search) {
            let given_name = search.value.split(' ')[0]
            let family_name = search.value.split(' ')[1]

            let searchParams = {
                given_name: given_name,
                family_name: family_name,
            }

            let fields = []

            let { competitors, pgCount, total } = await getPlayers(
                fields,
                searchParams,
                presetFilters ? presetFilters : filterParams,
                page,
                allEntries,
                rowsPerPage
            )

            if (competitors) {
                if (allEntries) {
                    setIsLoading(false)
                    return competitors
                } else {
                    setCompetitorsList(competitors)
                    setPageCount(pgCount)
                    setTotal(total)
                    setCurrentPage(page)
                    setShowFilters(false)
                }
            }
        }
        setIsLoading(false)
    }

    const updateDebounceText = debounce(async function () {
        setCurrentPage(1)
        setPageCount(1)
        getAllCompetitors(1)
    }, 1000)

    //* Handling removing filters
    const handleDisableFiltering = () => {
        // Handle filters reset with correct tab
        let tempFilters = { ...initialFilters }
        tempFilters.status =
            selectedTab === 'aktivni'
                ? 'active'
                : selectedTab === 'neaktivni'
                ? 'inactive'
                : 'draft'
        tempFilters.searchDraft = selectedTab === 'draft' ? true : false
        if (selectedTab === 'kaznjeni') {
            tempFilters.hasOwnProperty('status') && delete tempFilters.status
            tempFilters.punished = true
        } else {
            tempFilters.hasOwnProperty('punished') &&
                delete tempFilters.punished
        }

        setFilterParams(tempFilters)
        setSelectedCountry((_) => {
            return ''
        })
        setSelectedCity((_) => {
            return ''
        })
        setSelectedClub(null)
        setShowFilters(false)
        getAllCompetitors(1, tempFilters)
        setCurrentPage(1)
        setPageCount(1)
    }

    const tabNameHelper = (tab) => {
        switch (tab) {
            case 'aktivni':
                return 'Aktivni'
            case 'draft':
                return 'Draft'
            case 'neaktivni':
                return 'Neaktivni'
            case 'kaznjeni':
                return 'Kažnjeni'
            // case 'zahtevaju-izmenu':
            //     return 'Zahtevaju izmenu'
            default:
                return 'Aktivni'
        }
    }

    const handlePdfExport = async () => {
        const filteredPlayers = await getAllCompetitors(1, filterParams, true)
        setIsLoading(true)
        const unit = 'pt'
        const size = 'A4' // Use A1, A2, A3 or A4
        const orientation = 'portrait' // portrait or landscape

        const marginLeft = 40
        const doc = new jsPDF(orientation, unit, size)
        doc.addFileToVFS('WorkSans-normal.ttf', font)
        doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
        doc.setFont('WorkSans')
        doc.setFontSize(15)

        const title = `Takmičari - ${tabNameHelper(selectedTab)} - Ukupno: ${
            filteredPlayers?.length
        }, ${formatDate(new Date())}`
        doc.text(title, marginLeft, 40)

        const rows = []

        for (let i = 1; i <= filteredPlayers?.length; i++) {
            const player = filteredPlayers[i - 1]
            const isPlayerPunished = await handleIsPunished(
                player?.attributes?.uuid
            )
            rows.push([
                player?.attributes.person_info?.given_name +
                    ' ' +
                    player?.attributes?.person_info?.family_name,
                player?.attributes?.person_info?.birthdate
                    ? `${formatDate(
                          player?.attributes?.person_info?.birthdate
                      )}`
                    : 'Nema informacije',
                player?.attributes?.status?.status_name
                    ? `${competitorPillStatusHandler(
                          player?.attributes?.status?.status_name
                      )}${isPlayerPunished === true ? ', Kažnjen' : ''}`
                    : 'Nema informacije',
            ])
        }

        let competitorsContent = {
            startY: 50,
            head: [['Takmičar', 'Datum rođenja', 'Status']],
            body: rows,
            styles: {
                font: 'WorkSans',
            },
        }

        doc.autoTable(competitorsContent)

        doc.save(`Takmičari-${formatDate(new Date()).slice(0, -1)}.pdf`)

        setShowFilters(false)
        setIsLoading(false)
    }

    const handleIsPunished = async (playerUuid) => {
        let isPlayerPunished = await isPlayerDisciplinaryPunished(playerUuid)

        return isPlayerPunished
    }

    useEffect(() => {
        getAllCompetitors(currentPage)
        setRefreshData(false)
    }, [currentPage, refreshData, selectedTab, rowsPerPage])

    useEffect(() => {
        handleCodebooks()
    }, [])

    const handleChangeTab = (tab) => {
        setCurrentPage((_) => {
            return 1
        })
        setSelectedTab((_) => {
            handleTabAndFilters(tab)
            return tab
        })
    }

    const handleCountry = (countryName) => {
        if (countryName?.length > 2) {
            axios
                .get(`${process.env.REACT_APP_GEONAMES_URL}/searchJSON`, {
                    params: {
                        name_startsWith: countryName?.toString(),
                        featureCode: 'PCLI',
                        maxRows: 5,
                        username: `${process.env.REACT_APP_GEONAMES_USERNAME}`,
                    },
                })
                .then((response) => {
                    const countryOptions = []
                    response.data?.geonames?.map((country) => {
                        countryOptions?.push({
                            value: country?.name,
                            countryCode: country?.countryCode,
                        })
                    })
                    setAvailableCountries(countryOptions)
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {
            setAvailableCountries([])
        }
    }

    const handleSelectCountry = (countryCode) => {
        if (!countryCode) {
            setSelectedCountry((_) => {
                return ''
            })
            filterParams.birth_country = ''
        } else {
            axios
                .get(`${process.env.REACT_APP_GEONAMES_URL}/countryInfoJSON`, {
                    params: {
                        country: countryCode?.toString(),
                        username: `${process.env.REACT_APP_GEONAMES_USERNAME}`,
                    },
                })
                .then((response) => {
                    const selectedCountry = response?.data?.geonames[0]
                    filterParams.birth_country = selectedCountry?.countryCode
                    availableCountries.map((country) => {
                        if (
                            country?.countryCode ===
                            selectedCountry?.countryCode
                        ) {
                            setSelectedCountry((_) => {
                                return country
                            })
                        }
                    })
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    const handleCity = (cityName) => {
        if (cityName?.length > 2) {
            axios
                .get(`${process.env.REACT_APP_GEONAMES_URL}/searchJSON`, {
                    params: {
                        name_startsWith: cityName?.toString(),
                        featureClass: 'P',
                        country: selectedCountry?.countryCode,
                        maxRows: 5,
                        username: `${process.env.REACT_APP_GEONAMES_USERNAME}`,
                    },
                })
                .then((response) => {
                    const cityOptions = []
                    response.data?.geonames?.map((city) => {
                        cityOptions?.push({
                            value: `${city?.name}, ${city?.countryCode}`,
                            name: city?.name,
                            countryCode: city?.countryCode,
                        })
                    })
                    setAvailableCities(cityOptions)
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {
            setAvailableCities([])
        }
    }

    const handleSelectCity = (cityName) => {
        if (!cityName) {
            setSelectedCity((_) => {
                return ''
            })
            filterParams.birth_city = ''
        } else {
            filterParams.birth_city = cityName?.name
            availableCites.map((city) => {
                if (city?.value === cityName?.value) {
                    setSelectedCity((_) => {
                        return city
                    })
                }
            })
        }
    }

    const handleNoDataViewDescription = (tab) => {
        switch (tab) {
            case 'aktivni':
                return `Ovde je prikazana lista aktivnih takmičara.`
            case 'draft':
                return `Ovde je prikazana lista draft takmičara.`
            case 'neaktivni':
                return `Ovde je prikazana lista neaktivnih takmičara.`
            case 'kaznjeni':
                return `Ovde je prikazana lista kažnjenih takmičara.`
            default:
                return 'Ovde je prikazana lista takmičara.'
        }
    }

    return (
        <Row className="competitors-wrapper h-100">
            <ScrollToTop step={currentPage} />
            <Col>
                <Row className="pt-3">
                    <Col
                        xs={12}
                        md={4}
                        className="d-flex align-items-center justify-content-between"
                    >
                        <Row>
                            <Col className="">
                                <h2 className="d-none d-md-block">Takmičari</h2>
                                <p className="d-block d-md-none">Takmičari</p>
                                <Breadcrumb className="mt-md-2 mt-1">
                                    <Breadcrumb.Item
                                        linkAs={Link}
                                        linkProps={{ to: '/pocetna' }}
                                    >
                                        Početna
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item active>
                                        Takmičari
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>

                        <div className="d-flex align-items-center d-md-none">
                            <Button
                                type="text"
                                onClick={() => setShowFilters(true)}
                            >
                                <OutlinedFilter />
                            </Button>

                            <Button
                                type="text"
                                onClick={() => setShowInvitationModal(true)}
                            >
                                <Add />
                            </Button>
                        </div>
                    </Col>
                    <Col xs={12} md={8}>
                        <Row className="d-flex align-items-center">
                            <div className="d-flex justify-content-end w-100 mt-2 mt-md-0">
                                <SearchInput
                                    id={searchInputID}
                                    onChange={(e) =>
                                        updateDebounceText(e.target.value)
                                    }
                                    placeholder="Pronađi takmičara imenom, prezimenom ili šifrom"
                                />
                                <Button
                                    type="secondary"
                                    className="mx-2 d-none d-md-block"
                                    onClick={() => setShowFilters(true)}
                                >
                                    Filtriraj
                                </Button>

                                <Button
                                    className="d-none d-md-block"
                                    onClick={() => setShowInvitationModal(true)}
                                >
                                    Pozovi takmičara
                                </Button>
                            </div>
                        </Row>
                    </Col>
                </Row>
                <Row className="m-0 pt-3">
                    <Col className="p-0 tabular-navigation">
                        <Tabs
                            id="uncontrolled-tab-example"
                            activeKey={selectedTab}
                            onSelect={(tab) => {
                                handleChangeTab(tab)
                            }}
                        >
                            <Tab eventKey="aktivni" title="Aktivni">
                                <TableCompetitorsPagination
                                    data={competitorsList}
                                    status="active"
                                    className="d-none d-md-block"
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    pageCount={pageCount}
                                    total={total}
                                    setRefreshData={setRefreshData}
                                    tabValue={'aktivni'}
                                    rowsPerPage={rowsPerPage}
                                    setRowsPerPage={setRowsPerPage}
                                />
                                {!isArrayEmpty(competitorsList) ? (
                                    competitorsList?.map(
                                        (competitor, index) => (
                                            <TableCardCompetitor
                                                key={index}
                                                competitor={competitor}
                                                className={
                                                    'd-md-none background-table-card'
                                                }
                                                setCurrentPage={setCurrentPage}
                                                setRefreshData={setRefreshData}
                                            />
                                        )
                                    )
                                ) : (
                                    <NoDataForView
                                        title={`Nema takmičara`}
                                        description={handleNoDataViewDescription(
                                            'aktivni'
                                        )}
                                        className="d-md-none border-0"
                                        column
                                    />
                                )}
                            </Tab>
                            <Tab eventKey="draft" title="Draft">
                                <TableCompetitorsPagination
                                    data={competitorsList}
                                    status="active"
                                    className="d-none d-md-block"
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    pageCount={pageCount}
                                    total={total}
                                    setRefreshData={setRefreshData}
                                    tabValue={'draft'}
                                    rowsPerPage={rowsPerPage}
                                    setRowsPerPage={setRowsPerPage}
                                />
                                {!isArrayEmpty(competitorsList) ? (
                                    competitorsList?.map(
                                        (competitor, index) => (
                                            <TableCardCompetitor
                                                key={index}
                                                competitor={competitor}
                                                className={
                                                    'd-md-none background-table-card'
                                                }
                                                setCurrentPage={setCurrentPage}
                                                setRefreshData={setRefreshData}
                                            />
                                        )
                                    )
                                ) : (
                                    <NoDataForView
                                        title={`Nema takmičara`}
                                        description={handleNoDataViewDescription(
                                            'draft'
                                        )}
                                        className="d-md-none border-0"
                                        column
                                    />
                                )}
                            </Tab>
                            <Tab eventKey="kaznjeni" title="Kažnjeni">
                                <TableCompetitorsPagination
                                    data={competitorsList}
                                    status="inactive"
                                    className="d-none d-md-block"
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    pageCount={pageCount}
                                    total={total}
                                    setRefreshData={setRefreshData}
                                    tabValue={'kaznjeni'}
                                    rowsPerPage={rowsPerPage}
                                    setRowsPerPage={setRowsPerPage}
                                />
                                {!isArrayEmpty(competitorsList) ? (
                                    competitorsList?.map(
                                        (competitor, index) => (
                                            <TableCardCompetitor
                                                key={index}
                                                competitor={competitor}
                                                className={
                                                    'd-md-none background-table-card'
                                                }
                                                setCurrentPage={setCurrentPage}
                                                setRefreshData={setRefreshData}
                                            />
                                        )
                                    )
                                ) : (
                                    <NoDataForView
                                        title={`Nema takmičara`}
                                        description={handleNoDataViewDescription(
                                            'kaznjeni'
                                        )}
                                        className="d-md-none border-0"
                                        column
                                    />
                                )}
                            </Tab>
                            <Tab eventKey="neaktivni" title="Neaktivni">
                                <TableCompetitorsPagination
                                    data={competitorsList}
                                    status="inactive"
                                    className="d-none d-md-block"
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    pageCount={pageCount}
                                    total={total}
                                    setRefreshData={setRefreshData}
                                    tabValue={'neaktivni'}
                                    rowsPerPage={rowsPerPage}
                                    setRowsPerPage={setRowsPerPage}
                                />
                                {!isArrayEmpty(competitorsList) ? (
                                    competitorsList?.map(
                                        (competitor, index) => (
                                            <TableCardCompetitor
                                                key={index}
                                                competitor={competitor}
                                                className={
                                                    'd-md-none background-table-card'
                                                }
                                                setCurrentPage={setCurrentPage}
                                                setRefreshData={setRefreshData}
                                            />
                                        )
                                    )
                                ) : (
                                    <NoDataForView
                                        title={`Nema takmičara`}
                                        description={handleNoDataViewDescription(
                                            'neaktivni'
                                        )}
                                        className="d-md-none border-0"
                                        column
                                    />
                                )}
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Col>

            <PlayerInvitationModal
                showInvitationModal={showInvitationModal}
                setShowInvitationModal={setShowInvitationModal}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                getAllCompetitors={getAllCompetitors}
            />

            {/* Filter OffCanvas */}
            <Offcanvas
                show={showFilters}
                onHide={() => setShowFilters(false)}
                placement="end"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <h3>Filtriraj</h3>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <hr className="p-0 mt-2 mb-2" />
                <Offcanvas.Body>
                    <Row>
                        <Col md={12}>
                            <TextField
                                type="date"
                                value={filterParams?.period_from || ''}
                                label="Datum od"
                                placeholder="Datum od"
                                onChange={(e) =>
                                    setFilterParams((prev) => ({
                                        ...prev,
                                        period_from: e.target.value,
                                    }))
                                }
                            />
                        </Col>

                        <Col md={12} className="mt-3">
                            <TextField
                                type="date"
                                value={filterParams?.period_to || ''}
                                label="Datum do"
                                placeholder="Datum do"
                                onChange={(e) =>
                                    setFilterParams((prev) => ({
                                        ...prev,
                                        period_to: e.target.value,
                                    }))
                                }
                            />
                        </Col>

                        <Col md={12} className="mt-3">
                            <RadioButton
                                name="Pol"
                                radioValues={genders}
                                onClick={(e) => {
                                    filterParams?.gender === e.target.value
                                        ? setFilterParams({
                                              ...filterParams,
                                              gender: '',
                                          })
                                        : null
                                }}
                                onChange={(e) => {
                                    setFilterParams({
                                        ...filterParams,
                                        gender: e.target.value,
                                    })
                                }}
                                checkedValue={filterParams?.gender || ''}
                            />
                        </Col>
                        <Col md={12} className="mt-3">
                            <Dropdown
                                label={'Godište'}
                                className="yearDropdown"
                                value={filterParams?.birthdate || ''}
                                placeholder="Godište"
                                handleSelect={(e) =>
                                    setFilterParams({
                                        ...filterParams,
                                        birthdate:
                                            e === 'Odselektuj godište' ? '' : e,
                                    })
                                }
                                dropdownLinks={yearsList?.reduce(
                                    (accumulator, year) => [
                                        ...accumulator,
                                        year,
                                    ],
                                    filterParams?.birthdate
                                        ? ['Odselektuj godište']
                                        : []
                                )}
                            />
                        </Col>
                        <Col md={12} className="mt-3">
                            <Dropdown
                                label={'Konkurencija'}
                                className="yearDropdown"
                                value={filterParams?.competition || ''}
                                placeholder="Konkurencija"
                                handleSelect={(e) => {
                                    setFilterParams({
                                        ...filterParams,
                                        competition:
                                            e === 'Odselektuj' ? '' : e,
                                    })
                                }}
                                dropdownLinks={playersCompetition?.reduce(
                                    (accumulator, currentValue) => [
                                        ...accumulator,
                                        currentValue,
                                    ],
                                    filterParams?.competition
                                        ? [
                                              'Odselektuj',
                                              ...competitorCompetitions,
                                          ]
                                        : competitorCompetitions
                                )}
                            />
                        </Col>
                        <Col md={12} className="mt-3">
                            <MultiSelectSearch
                                label="Država rođenja"
                                animated
                                options={availableCountries}
                                noOptionsMessage={'Nema rezultata'}
                                onInputChange={(e) => handleCountry(e)}
                                onChange={(e) =>
                                    handleSelectCountry(e?.countryCode)
                                }
                                isMulti={false}
                                placeholder={'Pretraži i izaberi državu...'}
                                value={selectedCountry}
                                isClearable={true}
                            />
                        </Col>
                        <Col md={12} className="mt-3">
                            <MultiSelectSearch
                                label={'Grad rođenja'}
                                animated
                                options={availableCites}
                                noOptionsMessage={'Nema rezultata'}
                                onInputChange={(e) => handleCity(e)}
                                onChange={(e) => handleSelectCity(e)}
                                placeholder={'Pretraži i izaberi grad...'}
                                isMulti={false}
                                value={selectedCity}
                                isClearable={true}
                            />
                        </Col>
                        {/* <Col md={12} className="mt-3">
                                    <Dropdown
                                        label={'Prva godina registracije'}
                                        className="yearDropdown"
                                        value={filterParams?.memberships || ''}
                                        placeholder="Prva godina registracije"
                                        handleSelect={(e) =>
                                            setFilterParams({
                                                ...filterParams,
                                                memberships:
                                                    e === 'Odselektuj godinu'
                                                        ? ''
                                                        : e,
                                            })
                                        }
                                        dropdownLinks={yearsList?.reduce(
                                            (accumulator, year) => [
                                                ...accumulator,
                                                year,
                                            ],
                                            filterParams?.memberships
                                                ? ['Odselektuj godinu']
                                                : []
                                        )}
                                    />
                                </Col> */}
                        <Col md={12} className="mt-3">
                            <AsyncSelect
                                loadOptions={getClubList}
                                onChange={handleClubSelect}
                                label={'Izaberi klub'}
                                value={selectedClub}
                                placeholder={'Pretraži i izaberi klub...'}
                                noOptionsMessage={'Traženi klub ne postoji'}
                                closeMenuOnSelect={true}
                                isMulti={false}
                                isClearable={true}
                                defaultOptions={false}
                            />
                        </Col>
                        <Col md={12} className="mt-3">
                            <Dropdown
                                label={'Region'}
                                value={filterParams?.region || ''}
                                placeholder="Region"
                                handleSelect={(e) =>
                                    setFilterParams({
                                        ...filterParams,
                                        region: e === 'Odselektuj' ? '' : e,
                                    })
                                }
                                dropdownLinks={regions?.reduce(
                                    (accumulator, region) => [
                                        ...accumulator,
                                        region,
                                    ],
                                    filterParams?.region ? ['Odselektuj'] : []
                                )}
                            />
                        </Col>

                        <Col md={12} className="mt-3">
                            <TextField
                                type="tel"
                                label="Broj turnira (od)"
                                placeholder="Broj turnira (od)"
                                value={
                                    filterParams?.number_of_tournaments_from ||
                                    ''
                                }
                                onChange={(e) => {
                                    numbersOnly(e)
                                    setFilterParams({
                                        ...filterParams,
                                        number_of_tournaments_from:
                                            e.target.value,
                                    })
                                }}
                            />
                        </Col>

                        <Col md={12} className="mt-3">
                            <TextField
                                type="tel"
                                label="Broj turnira (do)"
                                placeholder="Broj turnira (do)"
                                value={
                                    filterParams?.number_of_tournaments_to || ''
                                }
                                onChange={(e) => {
                                    numbersOnly(e)
                                    setFilterParams({
                                        ...filterParams,
                                        number_of_tournaments_to:
                                            e.target.value,
                                    })
                                }}
                            />
                        </Col>

                        <Col md={12} className="mt-3">
                            <TextField
                                type="tel"
                                label="Broj mečeva (od)"
                                placeholder="Broj mečeva (od)"
                                value={
                                    filterParams?.number_of_matches_from || ''
                                }
                                onChange={(e) => {
                                    numbersOnly(e)
                                    setFilterParams({
                                        ...filterParams,
                                        number_of_matches_from: e.target.value,
                                    })
                                }}
                            />
                        </Col>

                        <Col md={12} className="mt-3">
                            <TextField
                                type="tel"
                                label="Broj mečeva (do)"
                                placeholder="Broj mečeva (do)"
                                value={filterParams?.number_of_matches_to || ''}
                                onChange={(e) => {
                                    numbersOnly(e)
                                    setFilterParams({
                                        ...filterParams,
                                        number_of_matches_to: e.target.value,
                                    })
                                }}
                            />
                        </Col>

                        <Col md={12} className="mt-3">
                            <Button
                                className="w-100"
                                onClick={() => getAllCompetitors(1)}
                            >
                                Prikaži rezultate
                            </Button>
                        </Col>
                        <Col md={12} className="mt-3">
                            <Button
                                type="secondary"
                                className="w-100"
                                onClick={handlePdfExport}
                            >
                                Štampaj rezultate
                            </Button>
                            <Button
                                type="text"
                                className="w-100 mt-3"
                                onClick={() => handleDisableFiltering()}
                            >
                                Ukloni filtriranje
                            </Button>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>
        </Row>
    )
}

export default Competitors
