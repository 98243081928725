import React from 'react'
import Table from 'react-bootstrap/Table'
import PaginationWrapper from '../Pagination'
import CompetitorsRankingTableRow from './competitorsRankingTableRow'

const CompetitorsRankingTable = ({
    data,
    currentPage,
    setCurrentPage,
    pageCount,
    total,
    isManualPointsChangeEnabled,
    setPlayersList,
    rowsPerPage,
    setRowsPerPage,
}) => {
    return (
        <div className="table-clubs-ranking-wrapper">
            <Table className="mb-0">
                <thead>
                    <tr>
                        <th>
                            <h5>Pozicija</h5>
                        </th>
                        <th>
                            <h5>Takmičar</h5>
                        </th>
                        <th>
                            <h5>Klub</h5>
                        </th>
                        <th>
                            <h5>Datum rodjenja</h5>
                        </th>
                        <th>
                            <h5>Bodovi</h5>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((player, index) => (
                        <CompetitorsRankingTableRow
                            key={index}
                            player={player}
                            isManualPointsChangeEnabled={
                                isManualPointsChangeEnabled
                            }
                            setPlayersList={setPlayersList}
                            rankPosition={
                                currentPage * rowsPerPage +
                                (index + 1) -
                                rowsPerPage
                            }
                        />
                    ))}
                </tbody>
            </Table>
            <PaginationWrapper
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageCount={pageCount}
                total={total}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
            />
        </div>
    )
}

export default CompetitorsRankingTable
