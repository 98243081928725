import { formatDate } from '../../../../lib/helpers'

const tabNameHelper = (tab) => {
    switch (tab) {
        case 'by-players':
            return 'Bodovi svojih igrača (poslednjih 365 dana)'
        case 'by-players-current-year':
            return 'Bodovi svojih igrača (tekuća godina)'
        case 'general':
            return 'Opšta rang lista'
        case 'assembly':
            return 'Skupština'
        default:
            return 'Bodovi svojih igrača (poslednjih 365 dana)'
    }
}

export const exportCSV = async (clubList, activeTab, setIsLoading) => {
    setIsLoading(true)
    const rows = []
    for (let i = 1; i <= clubList?.length; i++) {
        const c = clubList[i - 1]

        rows.push([
            `${i}.`,
            c?.club_data?.attributes?.name || '-',
            c?.attributes?.registered_player_votes || '0',
            c?.attributes?.ranked_player_votes || '0',
            c?.attributes?.court_votes || '0',
            c?.attributes?.club_quality_votes || '0',
            c?.attributes?.international_organization_votes || '0',
            c?.attributes?.extra_international_organization_votes || '0',
            c?.attributes?.international_rank_votes || '0',
            c?.attributes?.total_votes
                ? c?.attributes?.total_votes > 15
                    ? '15'
                    : c?.attributes?.total_votes
                : '0',
        ])
    }

    let data = [
        [
            'Pozicija',
            'Klub',
            'Registrovani igrači',
            'Rangirani igrači',
            'Tereni',
            'Kvalitet klubova',
            'Organizacija međunarodnih turnira',
            'Dodatni glasovi za organizaciju međunarodnih turnira',
            'Internacionalni rang',
            'Ukupno',
        ],
        ...rows,
    ]

    let csvContent = ''
    data.forEach((rowArray) => {
        let row = rowArray.join(',')
        csvContent += row + '\r\n'
    })

    let blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })

    let link = document.createElement('a')
    let url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute(
        'download',
        `Rang lista klubova - ${tabNameHelper(activeTab)} - Ukupno-${
            clubList?.length
        }, ${formatDate(new Date()).slice(0, -1)}.csv`
    )
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    setIsLoading(false)
}
