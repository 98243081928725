import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { formatDate } from '../../../lib/helpers'
import './styles.scss'

function ClubHistoryCard({
    clubEntry,
    profileData,
    className,
    isWhite,
    setShowConfirmationModal,
}) {
    return (
        <Row
            className={`medical-history-card-wrapper g-3 ${className} ${
                isWhite ? 'white-history-card' : 'grey-history-card'
            }`}
        >
            <Col xs={6} className="mt-3 d-flex align-items-center">
                <h5 className={`medical-header`}>Klub</h5>
            </Col>
            <Col xs={6} className="d-flex align-items-center p-0 mt-3">
                <p className="medical-info-text">
                    <span className="link">
                        <Link
                            to={`/klubovi/${clubEntry?.club?.attributes?.uuid}`}
                            tabIndex="1"
                        >
                            {clubEntry?.club?.attributes?.name}
                        </Link>
                    </span>
                </p>
            </Col>
            <hr className="table-card-divider" />
            <Col xs={6} className="m-0 d-flex align-items-center">
                <h5 className={`medical-header`}>Tip</h5>
            </Col>
            <Col xs={6} className="d-flex align-items-center p-0 m-0">
                <p className="medical-info-text">
                    {clubEntry?.attributes?.is_loan ? 'Pozajmica' : 'Transfer'}
                </p>
            </Col>
            <hr className="table-card-divider" />
            <Col xs={6} className="m-0 d-flex align-items-center">
                <h5 className={`medical-header`}>Datum od</h5>
            </Col>
            <Col xs={6} className="d-flex align-items-center p-0 m-0">
                <p className="medical-info-text">
                    {formatDate(clubEntry?.attributes?.joining_date)}
                </p>
            </Col>
            <hr className="table-card-divider" />
            <Col xs={6} className="m-0 d-flex align-items-center">
                <h5 className={`medical-header`}>Datum do</h5>
            </Col>
            <Col xs={6} className="d-flex align-items-center p-0 m-0">
                <p className="medical-info-text">
                    {formatDate(clubEntry?.attributes?.departure_date)}
                </p>
            </Col>
            <hr className="table-card-divider" />
            <Col
                xs={12}
                className="m-0 d-flex align-items-center justify-content-center mb-3"
            >
                <p
                    className="red-link"
                    onClick={() =>
                        setShowConfirmationModal((_) => {
                            return clubEntry?.attributes?.uuid
                        })
                    }
                >
                    Ukloni
                </p>
            </Col>
        </Row>
    )
}

export default ClubHistoryCard
