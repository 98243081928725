import * as React from 'react'

const ToastInfo = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="ionicon"
        viewBox="0 0 512 512"
        {...props}
    >
        <title>{'Information Circle'}</title>
        <path
            d="M248 64C146.39 64 64 146.39 64 248s82.39 184 184 184 184-82.39 184-184S349.61 64 248 64z"
            fill="none"
            stroke="currentColor"
            strokeMiterlimit={10}
            strokeWidth={32}
        />
        <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={32}
            d="M220 220h32v116"
        />
        <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeMiterlimit={10}
            strokeWidth={32}
            d="M208 340h88"
        />
        <path
            d="M248 130a26 26 0 1 0 26 26 26 26 0 0 0-26-26z"
            stroke="currentColor"
            fill="currentColor"
        />
    </svg>
)

export default ToastInfo
