import './styles.scss'

const Pill = ({
    type,
    children,
    icon,
    frontIcon,
    className,
    onClick,
    role,
}) => (
    <div
        role={role}
        onClick={onClick}
        className={`pill-${type} d-flex align-items-center justify-content-center pill-style ${className}`}
    >
        <div>{icon}</div>
        {children}
        {frontIcon}
    </div>
)

export default Pill
