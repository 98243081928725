import React from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import { deleteMatch, removeSchedule, getMatchsSchedule } from '../../lib/api'
import Button from '../Button'

const DeleteMatchModal = ({
    show,
    onHide,
    matchUuid,
    handleGetSingleMatch,
    onDelete,
}) => {
    const handleDeleteMatch = async () => {
        try {
            const deletedMatchResponse = await deleteMatch(matchUuid)

            if (deletedMatchResponse) {
                const matchSchedules = await getMatchsSchedule(matchUuid)

                if (matchSchedules) {
                    const deleteSchedulesPromises = matchSchedules.map(
                        async (schedule) => {
                            const scheduleUuid = schedule?.attributes?.uuid
                            if (scheduleUuid) {
                                return removeSchedule(scheduleUuid)
                            }
                            throw new Error('Schedule UUID is missing')
                        }
                    )

                    await Promise.all(deleteSchedulesPromises)
                }
                onDelete && onDelete()
                onHide()
                handleGetSingleMatch()
            } else {
                throw new Error('Failed to delete match')
            }
        } catch (error) {
            console.error('An error occurred:', error)
            throw new Error(error)
        }
    }

    return (
        <Modal
            centered
            show={show}
            onHide={onHide}
            dialogClassName="create-draw-pair-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>Obriši meč</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <p>Da li ste sigurni da želite da obrišete meč?</p>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button type="text" onClick={onHide}>
                    Odustani
                </Button>
                <Button onClick={() => handleDeleteMatch()}>Obriši</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteMatchModal
