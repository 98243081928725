import React, { useEffect } from 'react'
import CompetitionReport from '../../../../../components/CompetitionReport'

function Overview({ setProgress, formData }) {
    useEffect(() => {
        setProgress(100)
    }, [])

    const onLinkClick = () => {
        // console.log(formData)
        // set the path for the link
    }

    return (
        <CompetitionReport
            reportData={formData}
            referee
            onLinkClick={onLinkClick}
        />
    )
    // return <h3> Pregled </h3>
}

export default Overview
