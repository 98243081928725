import React, { useState } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import Button from '../Button'
import Dropdown from '../Dropdown'
import AsyncSelect from '../AsyncSelect'
import {
    getTournamentPlayersParticipantsList,
    numbersOnly,
} from '../../lib/helpers'
import { createMatch } from '../../lib/api'
import TextField from '../TextField'

const AddPlayersMatchesModal = ({
    show,
    onHide,
    matchDetails,
    sideOneData,
    sideTwoData,
    handleMatches,
}) => {
    const [matchData, setMatchData] = useState({
        is_double: false,
    })

    const handleCreateMatch = async () => {
        const newMatch = await createMatch({
            ...matchData,
            club_match_uuid: matchDetails?.single_club_match_uuid,
            gender: matchDetails?.gender,
            tournament_uuid: matchDetails?.tournament_uuid,
            draw_type: matchDetails?.draw_type,
            pair_number: null,
        })

        if (newMatch) {
            onHide()
            handleMatches()
            setMatchData({
                is_double: false,
            })
        }
    }

    const loadParticipantsSideOne = async (
        inputValue,
        loadedOptions,
        paginationData
    ) => {
        return getTournamentPlayersParticipantsList(
            inputValue,
            loadedOptions,
            paginationData,
            {
                tournament_uuid: matchDetails?.tournament_uuid,
                club_uuid: sideOneData?.club?.attributes?.uuid,
                is_double: matchData?.is_double,
                gender: matchDetails?.gender?.gender_name,
                tournament_type_name: matchDetails?.tournament_type_name,
            }
        )
    }

    const loadParticipantsSideTwo = async (
        inputValue,
        loadedOptions,
        paginationData
    ) => {
        return getTournamentPlayersParticipantsList(
            inputValue,
            loadedOptions,
            paginationData,
            {
                tournament_uuid: matchDetails?.tournament_uuid,
                club_uuid: sideTwoData?.club?.attributes?.uuid,
                is_double: matchData?.is_double,
                gender: matchDetails?.gender?.gender_name,
                tournament_type_name: matchDetails?.tournament_type_name,
            }
        )
    }

    return (
        <Modal
            centered
            show={show}
            onHide={onHide}
            dialogClassName="create-draw-pair-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>Dodaj meč</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <Dropdown
                            label={'Singl/dubl'}
                            placeholder="Singl/dubl"
                            dropdownLinks={['Singl', 'Dubl']}
                            handleSelect={(e) =>
                                setMatchData((prev) => ({
                                    ...prev,
                                    is_double: e === 'Dubl' ? true : false,
                                }))
                            }
                            value={matchData?.is_double ? 'Dubl' : 'Singl'}
                            required
                        />
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col>
                        <AsyncSelect
                            required
                            loadOptions={loadParticipantsSideOne}
                            onChange={(e) =>
                                setMatchData((prev) => ({
                                    ...prev,
                                    side_1_uuid: e.uuid,
                                }))
                            }
                            label={
                                matchData?.is_double ? 'Par 1' : 'Takmičar 1'
                            }
                            placeholder={'Izaberi igrača ...'}
                            noOptionsMessage={'Nema prijavljenih igrača'}
                            closeMenuOnSelect={true}
                            isMulti={false}
                            cacheUniqs={[
                                matchData?.side_1_uuid || null,
                                matchData?.side_2_uuid || null,
                                matchData?.is_double || null,
                            ]}
                        />

                        <Row className="mt-3">
                            <Col>
                                <AsyncSelect
                                    required
                                    loadOptions={loadParticipantsSideTwo}
                                    onChange={(e) =>
                                        setMatchData((prev) => ({
                                            ...prev,
                                            side_2_uuid: e.uuid,
                                        }))
                                    }
                                    label={
                                        matchData?.is_double
                                            ? 'Par 1'
                                            : 'Takmičar 1'
                                    }
                                    placeholder={'Izaberi igrača ...'}
                                    noOptionsMessage={
                                        'Nema prijavljenih igrača'
                                    }
                                    closeMenuOnSelect={true}
                                    isMulti={false}
                                    cacheUniqs={[
                                        matchData?.side_1_uuid || null,
                                        matchData?.side_2_uuid || null,
                                        matchData?.is_double || null,
                                    ]}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col>
                                <TextField
                                    type="tel"
                                    value={matchData?.table_number || ''}
                                    onChange={(e) => {
                                        numbersOnly(e)
                                        setMatchData((prev) => ({
                                            ...prev,
                                            table_number: +e?.target?.value,
                                        }))
                                    }}
                                    placeholder={'Tabla meča'}
                                    label="Tabla meča"
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button type="text" onClick={onHide}>
                    Odustani
                </Button>
                <Button
                    onClick={() => handleCreateMatch()}
                    disabled={
                        matchData?.side_1_uuid && matchData?.side_2_uuid
                            ? false
                            : true
                    }
                >
                    Dodaj meč
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddPlayersMatchesModal
