import { Card, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { formatDate, formatImgUrl } from '../../lib/helpers'
import { generateAvatar } from '../../utils/generateAvatar'

const CompetitorsRankingCard = ({ isWhite, player, index }) => {
    return (
        <Card
            className={`club-card ${
                isWhite ? 'white-club-card' : 'grey-club-card'
            }`}
        >
            <Card.Body className="py-0">
                <Row>
                    <Col>
                        <Row className="car-row">
                            <Col>
                                <h4>Pozicija</h4>
                            </Col>
                            <Col>
                                <p>{index + 1}.</p>
                            </Col>
                        </Row>

                        <Row className="car-row">
                            <Col>
                                <h4>Takmičar</h4>
                            </Col>
                            <Col>
                                <p>
                                    {
                                        player?.player_data?.attributes
                                            ?.person_info?.given_name
                                    }{' '}
                                    {
                                        player?.player_data?.attributes
                                            ?.person_info?.family_name
                                    }
                                </p>
                            </Col>
                        </Row>

                        <Row className="car-row">
                            <Col>
                                <h4>Klub</h4>
                            </Col>
                            <Col className="d-flex align-items-center justify-content-start">
                                {player?.club?.attributes?.name ? (
                                    <>
                                        <img
                                            src={
                                                player?.club?.attributes?.logo
                                                    ?.data
                                                    ? formatImgUrl(
                                                          player?.club
                                                              ?.attributes?.logo
                                                              ?.data?.attributes
                                                              ?.url
                                                      )
                                                    : generateAvatar(
                                                          player?.club
                                                              ?.attributes?.name
                                                      )
                                            }
                                            alt={`Logo kluba`}
                                            width={'24px'}
                                            height={'24px'}
                                            style={{
                                                maxHeight: '24px',
                                                maxWidth: '24px',
                                                borderRadius: '50%',
                                            }}
                                        />
                                        <Link
                                            to={`/klubovi/${player?.club?.attributes?.uuid}`}
                                        >
                                            <p className="ms-2 link">
                                                {player?.club?.attributes?.name}
                                            </p>
                                        </Link>
                                    </>
                                ) : (
                                    <p>-</p>
                                )}
                            </Col>
                        </Row>

                        <Row className="car-row">
                            <Col>
                                <h4>Datum rodjenja</h4>
                            </Col>
                            <Col>
                                <p>
                                    {formatDate(
                                        player?.player_data?.attributes
                                            ?.person_info?.birthdate
                                    )}
                                </p>
                            </Col>
                        </Row>

                        <Row className="car-row">
                            <Col>
                                <h4>Bodovi</h4>
                            </Col>
                            <Col>
                                <p>{player?.attributes?.total_points}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default CompetitorsRankingCard
