import axios from 'axios'
import { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import MultiSelectSearch from '../../../../../components/MultiSelectSearch'
import TextArea from '../../../../../components/TextArea'
import TextField from '../../../../../components/TextField'
import { numbersOnly } from '../../../../../lib/helpers'

const StepFive = ({ accommodationForm, setAccommodationForm }) => {
    const [availableCites, setAvailableCities] = useState([])
    const [selectedCity, setSelectedCity] = useState([])

    useEffect(() => {
        if (accommodationForm?.address?.city) {
            setSelectedCity({
                name: accommodationForm?.address?.city,
                value: accommodationForm?.address?.city,
            })
        }
    }, [])

    const handleCity = (cityName) => {
        if (cityName?.length > 2) {
            axios
                .get(`${process.env.REACT_APP_GEONAMES_URL}/searchJSON`, {
                    params: {
                        name_startsWith: cityName?.toString(),
                        featureClass: 'P',
                        country: 'RS,XK', // Serbia as country preselected for clubs
                        maxRows: 5,
                        username: `${process.env.REACT_APP_GEONAMES_USERNAME}`,
                    },
                })
                .then((response) => {
                    const cityOptions = []
                    response.data?.geonames?.map((city) => {
                        cityOptions?.push({
                            value: `${city?.name}`,
                            name: city?.name,
                        })
                    })
                    setAvailableCities(cityOptions)
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {
            setAvailableCities([])
        }
    }

    const handleSelectCity = (cityName) => {
        if (!cityName) {
            setSelectedCity((_) => {
                return ''
            })
            setAccommodationForm((prev) => {
                return {
                    ...prev,
                    address: {
                        ...prev?.address,
                        city: '',
                    },
                }
            })
        } else {
            setAccommodationForm((prev) => ({
                ...prev,
                address: {
                    ...prev?.address,
                    city: cityName?.name,
                },
            }))
            availableCites.map((city) => {
                if (city?.value === cityName?.value) {
                    setSelectedCity((_) => {
                        return city
                    })
                }
            })
        }
    }
    return (
        <Row className="create-new-competition-form-wrapper step-five">
            <Col md={12} className="mt-3">
                <TextField
                    id="accommodation_address"
                    name="accommodation_address"
                    type="text"
                    value={accommodationForm?.address?.street || ''}
                    onChange={(e) => {
                        setAccommodationForm((prev) => ({
                            ...prev,
                            address: {
                                ...prev?.address,
                                street: e.target.value,
                            },
                        }))
                    }}
                    placeholder="Unesite ulicu i broj"
                    label="Ulica i broj"
                />
            </Col>
            <Col md={12} className="mt-3">
                <MultiSelectSearch
                    label={'Grad'}
                    animated
                    options={availableCites}
                    noOptionsMessage={'Nema rezultata'}
                    onInputChange={(e) => handleCity(e)}
                    onChange={(e) => handleSelectCity(e)}
                    placeholder={'Pretraži i izaberi grad...'}
                    isMulti={false}
                    value={selectedCity}
                    isClearable={true}
                />
            </Col>
            <Col md={12} className="mt-3">
                <TextField
                    id="accommodation_phone"
                    name="accommodation_phone"
                    type="tel"
                    value={accommodationForm?.phone || ''}
                    onChange={(e) => {
                        numbersOnly(e)
                        setAccommodationForm((prev) => ({
                            ...prev,
                            phone: e.target.value,
                        }))
                    }}
                    placeholder="Unesite broj telefona"
                    label="Telefon"
                />
            </Col>
            <Col md={12} className="mt-3">
                <TextField
                    id="accommodation_email"
                    name="accommodation_email"
                    type="email"
                    value={accommodationForm?.email || ''}
                    onChange={(e) => {
                        setAccommodationForm((prev) => ({
                            ...prev,
                            email: e.target.value,
                        }))
                    }}
                    placeholder="Unesite email"
                    label="Email"
                />
            </Col>
            <Col md={12} className="mt-3">
                <TextArea
                    id="accommodation_more_info"
                    name="accommodation_more_info"
                    label="Dodatne informacije"
                    placeholder="Dodatne informacije"
                    type="text"
                    value={accommodationForm?.additional_informations || ''}
                    onChange={(e) => {
                        setAccommodationForm((prev) => ({
                            ...prev,
                            additional_informations: e.target.value,
                        }))
                    }}
                    rows={4}
                    maxLength={600}
                />
            </Col>
        </Row>
    )
}

export default StepFive
