import React from 'react'
import { Table } from 'react-bootstrap'
import TextField from '../../../../../components/TextField'
import { numbersOnly, notLeadingZero } from '../../../../../lib/helpers'
const GrandPrixTournamentPointTable = ({
    mainCategory,
    tournamentCategory,
    entryPoints,
    isEditable,
    setEntryPoints,
}) => {
    return (
        <Table className="table-competitors-pagination-wrapper table-competitors-wrapper">
            <thead>
                <tr>
                    <th></th>
                    <th>
                        <h5>{tournamentCategory}</h5>
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td>
                        <p>Bodova po pobedi</p>
                    </td>

                    <td>
                        {isEditable ? (
                            <TextField
                                className="entry-points-input-field"
                                onChange={(e) => {
                                    numbersOnly(e)
                                    notLeadingZero(e)
                                    const newPointsPerWinValue = +e.target.value

                                    setEntryPoints((prev) => {
                                        return {
                                            ...prev,
                                            entry_points_schema: {
                                                ...prev.entry_points_schema,
                                                [`${mainCategory}`]: {
                                                    ...prev.entry_points_schema[
                                                        `${mainCategory}`
                                                    ],
                                                    points: {
                                                        ...prev
                                                            .entry_points_schema[
                                                            `${mainCategory}`
                                                        ].points,
                                                        [`${tournamentCategory}`]:
                                                            {
                                                                ...prev
                                                                    .entry_points_schema[
                                                                    `${mainCategory}`
                                                                ].points[
                                                                    `${tournamentCategory}`
                                                                ],
                                                                points_per_win:
                                                                    newPointsPerWinValue,
                                                            },
                                                    },
                                                },
                                            },
                                        }
                                    })
                                }}
                                type="tel"
                                value={
                                    entryPoints?.entry_points_schema?.[
                                        `${mainCategory}`
                                    ]?.points[`${tournamentCategory}`]
                                        ?.points_per_win || ''
                                }
                            >
                                {
                                    entryPoints?.entry_points_schema?.[
                                        `${mainCategory}`
                                    ]?.points[`${tournamentCategory}`]
                                        ?.points_per_win
                                }
                            </TextField>
                        ) : (
                            <p>
                                {
                                    entryPoints?.entry_points_schema?.[
                                        `${mainCategory}`
                                    ]?.points[`${tournamentCategory}`]
                                        ?.points_per_win
                                }
                            </p>
                        )}
                    </td>
                </tr>
            </tbody>
        </Table>
    )
}

export default GrandPrixTournamentPointTable
