import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { deleteCompetitorsRankingList } from '../../lib/api'
import { useAppContext } from '../../lib/contextLib'
import ConfirmationModal from '../ConfirmationModal'
import { formatDate } from '../../lib/helpers'

const CompetitorsRankingListRow = ({
    list,
    handleGetCompetitorsRankingLists,
    showRegion,
}) => {
    const { user } = useAppContext()
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const handleRemoveRankingList = async () => {
        try {
            const deletedRankingList = await deleteCompetitorsRankingList(
                list?.attributes?.uuid
            )

            if (!deletedRankingList) return
            handleGetCompetitorsRankingLists()
            setShowDeleteModal(false)
        } catch (error) {
            setShowDeleteModal(false)
            console.error(error)
        }
    }
    return (
        <tr>
            <td>{list?.attributes?.name || '-'}</td>
            <td>
                {list?.attributes?.players_competition
                    ?.players_competition_name || '-'}
            </td>
            <td>
                {list?.attributes?.players_competition
                    ?.players_competition_subcategory_name || '-'}
            </td>
            <td>{list?.attributes?.gender?.gender_name || '-'}</td>
            {showRegion && (
                <td>{list?.attributes?.region?.region_name || '-'}</td>
            )}
            <td>{formatDate(list?.attributes?.updatedAt) || ''}</td>
            <td>
                <Link
                    to={`/rang-lista-po-tipu-licence/${list?.attributes?.uuid}`}
                >
                    <p className="link">Pregled</p>
                </Link>
            </td>
            {/* <td>
                {user?.role?.type !== 'competitor' && (
                    <p
                        className="red-link"
                        onClick={() => setShowDeleteModal(true)}
                    >
                        Ukloni
                    </p>
                )}
            </td> */}
            {/* Delete list modal */}
            <ConfirmationModal
                modalText="Jeste li sigurni da želite ukloniti listu?"
                onHide={() => handleRemoveRankingList()}
                show={showDeleteModal}
                onClick={handleRemoveRankingList}
            />
        </tr>
    )
}

export default CompetitorsRankingListRow
