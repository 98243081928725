import React, { useEffect, useState } from 'react'
import { Modal, Row, Col, Card } from 'react-bootstrap'
import Button from '../Button'
import AddPlayersMatchesModal from './addPlayersMatchesModal'
import TournamentMatchCard from '../TournamentMatchCard'
import { getMatchesBetweenClubs } from '../../lib/api'
import Dropdown from '../Dropdown'
import { useAppContext } from '../../lib/contextLib'

const ClubsMatchesModal = ({
    tournament,
    show,
    onHide,
    matchDetails,
    sideOneData,
    sideTwoData,
}) => {
    const { user } = useAppContext()
    const [showAddPlayersMatchModal, setShowAddPlayersMatchModal] =
        useState(false)
    const [matches, setMatches] = useState([])
    const [genderToShow, setGenderToShow] = useState()

    const handleMatches = async () => {
        if (
            matchDetails?.single_club_match_uuid &&
            (genderToShow || matchDetails?.gender?.gender_name)
        ) {
            const matchesData = await getMatchesBetweenClubs({
                club_match_uuid: matchDetails?.single_club_match_uuid,
                gender:
                    genderToShow?.gender_name ||
                    tournament?.attributes?.genders[0]?.gender_name,
            })

            if (matchesData) {
                setMatches(matchesData)
            }
        }
    }

    useEffect(() => {
        handleMatches()
    }, [matchDetails, genderToShow])

    return (
        <Modal
            fullscreen={true}
            centered
            show={show}
            onHide={onHide}
            dialogClassName="create-draw-pair-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>
                        Mečevi klubova - {sideOneData?.club?.attributes?.name}
                        {sideOneData?.club?.attributes?.shortname} /{' '}
                        {sideTwoData?.club?.attributes?.name}
                        {sideTwoData?.club?.attributes?.shortname}
                    </h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="align-items-end">
                    <Col xs={12} md={3}>
                        <Dropdown
                            label={'Izaberite pol'}
                            placeholder="Izaberite pol"
                            dropdownLinks={tournament?.attributes?.genders?.map(
                                (gender) => {
                                    return gender?.gender_name
                                }
                            )}
                            value={
                                genderToShow?.gender_name ||
                                tournament?.attributes?.genders[0]?.gender_name
                            }
                            handleSelect={(e) =>
                                setGenderToShow({ gender_name: e })
                            }
                        />
                    </Col>
                    {user?.role?.type !== 'competitor' && (
                        <Col>
                            <Button
                                onClick={() =>
                                    setShowAddPlayersMatchModal(true)
                                }
                            >
                                Dodaj meč
                            </Button>
                        </Col>
                    )}
                </Row>

                <Row className="g-3 mt-4">
                    {matches?.map((match, index) => (
                        <Col key={index} xs={12} md={4}>
                            <Card>
                                <Card.Body>
                                    {match?.attributes?.table_number && (
                                        <h5 className="mb-2">
                                            Tabla:{' '}
                                            {match?.attributes?.table_number}
                                        </h5>
                                    )}
                                    <TournamentMatchCard
                                        matchDetails={{
                                            ...matchDetails,
                                            match,
                                        }}
                                        onDelete={handleMatches}
                                        onChange={handleMatches}
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>

                <AddPlayersMatchesModal
                    show={showAddPlayersMatchModal}
                    onHide={() => setShowAddPlayersMatchModal(false)}
                    matchDetails={{
                        ...matchDetails,
                        gender: genderToShow || matchDetails?.gender,
                        tournament_type_name:
                            tournament?.attributes?.tournament_type
                                ?.tournament_type_name,
                    }}
                    sideOneData={sideOneData}
                    sideTwoData={sideTwoData}
                    handleMatches={handleMatches}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button type="text" onClick={onHide}>
                    Zatvori
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ClubsMatchesModal
