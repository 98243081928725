import {
    updateTournamentClubParticipant,
    getMatches,
} from '../../../../../../../../lib/api'

export const calculateGroupParticipantRank = async (
    groupMatches,
    participantUuid
) => {
    const participantsMatches = groupMatches?.filter(
        (match) =>
            match?.attributes?.side_1_uuid === participantUuid ||
            match?.attributes?.side_2_uuid === participantUuid
    )

    let winsNumber = 0
    participantsMatches?.map((match) => {
        if (match?.attributes?.loser_participant_uuid) {
            if (match?.attributes?.loser_participant_uuid !== participantUuid) {
                winsNumber++
            }
        }
    })

    return {
        winsNumber: winsNumber || 0,
    }
}

function groupByTotalPoints(data) {
    const grouped = {
        firstPlace: [],
        secondPlace: [],
        thirdPlace: [],
        fourthPlace: [],
    }
    let place = 1

    for (let i = 0; i < data.length; i++) {
        const points = data[i].wins
        const nextPoints = data[i + 1]?.wins

        const placeName =
            ['firstPlace', 'secondPlace', 'thirdPlace', 'fourthPlace'][
                place - 1
            ] || `place${place}`

        grouped[placeName].push(data[i])

        if (points !== nextPoints) {
            place += grouped[placeName].length
        }
    }

    return grouped
}

function filterMatchesBySides(matches, uuids) {
    return matches.filter(
        (match) =>
            uuids.includes(match.attributes.side_1_uuid) &&
            uuids.includes(match.attributes.side_2_uuid)
    )
}

function extractParticipantUuids(data) {
    return data.map((item) => item.participant_uuid)
}

export const rankCompetitors = async (groupParticipants, groupMatches) => {
    let competitors = []
    for (let i = 0; i < groupParticipants?.length; i++) {
        const { winsNumber } = await calculateGroupParticipantRank(
            groupMatches,
            groupParticipants[i]?.attributes?.participant?.attributes?.uuid
        )
        competitors.push({
            participant_data: groupParticipants[i],
            participant_uuid:
                groupParticipants[i]?.attributes?.participant?.attributes?.uuid,
            wins: winsNumber,
            group_manual_rank:
                groupParticipants[i]?.attributes?.group_manual_rank || 1,
        })
    }

    competitors?.sort((a, b) => {
        if (a.group_manual_rank !== b.group_manual_rank) {
            return a.group_manual_rank - b.group_manual_rank
        }

        return b.wins - a.wins
    })

    const groupsByTotalPoints = groupByTotalPoints(competitors)
    let finalCompetitorsList = []

    const firstPlaceList =
        (await sortTeamGroup(
            groupsByTotalPoints,
            'firstPlace',
            groupMatches
        )) || []

    const secondPlaceList =
        (await sortTeamGroup(
            groupsByTotalPoints,
            'secondPlace',
            groupMatches
        )) || []

    const thirdPlaceList =
        (await sortTeamGroup(
            groupsByTotalPoints,
            'thirdPlace',
            groupMatches
        )) || []

    const fourthPlaceList =
        (await sortTeamGroup(
            groupsByTotalPoints,
            'fourthPlace',
            groupMatches
        )) || []
    finalCompetitorsList = [
        ...finalCompetitorsList,
        ...firstPlaceList,
        ...secondPlaceList,
        ...thirdPlaceList,
        ...fourthPlaceList,
    ]

    const updateFirstTwo = finalCompetitorsList?.slice(0, 2).map((club) => {
        if (club?.participant_uuid) {
            return updateTournamentClubParticipant(club.participant_uuid, {
                club_status: { status_name: 'DA' },
            })
        }
        return Promise.resolve()
    })

    const updateOthers = finalCompetitorsList?.slice(2).map((club) => {
        if (club?.participant_uuid) {
            return updateTournamentClubParticipant(club.participant_uuid, {
                club_status: { status_name: 'QA' },
            })
        }
        return Promise.resolve()
    })

    await Promise.all([...updateFirstTwo, ...updateOthers])

    return finalCompetitorsList
}

const sortTeamGroup = async (groupsByTotalPoints, key = '', groupMatches) => {
    if (groupsByTotalPoints?.[key]?.length === 1) {
        return groupsByTotalPoints?.[key]
    } else if (groupsByTotalPoints?.[key]?.length === 2) {
        groupsByTotalPoints?.[key]?.sort((a, b) => {
            let match = groupMatches?.find(
                (match) =>
                    (match?.attributes?.side_1_uuid === a.participant_uuid &&
                        match?.attributes?.side_2_uuid ===
                            b.participant_uuid) ||
                    (match?.attributes?.side_1_uuid === b.participant_uuid &&
                        match?.attributes?.side_2_uuid === a.participant_uuid)
            )

            if (match) {
                if (
                    match.attributes?.loser_participant_uuid ===
                    a.participant_uuid
                ) {
                    return 1
                } else if (
                    match.attributes?.loser_participant_uuid ===
                    b.participant_uuid
                ) {
                    return -1
                }
            } else {
                return 0
            }
        })
        return groupsByTotalPoints?.[key] || []
    } else if (groupsByTotalPoints?.[key]?.length > 2) {
        const participantsUUIDS = extractParticipantUuids(
            groupsByTotalPoints?.[key]
        )
        const theirMatches = filterMatchesBySides(
            groupMatches,
            participantsUUIDS
        )
        for (const participant of groupsByTotalPoints?.[key]) {
            let filteredWinsNumber = 0

            let filteredTotalPoints = 0
            let lostPoints = 0

            let setsWonNumber = 0
            let gamesWonNumber = 0

            let setsLostNumber = 0
            let gamesLostNumber = 0

            const participantsSide1Matches = theirMatches.filter(
                (match) =>
                    match.attributes.side_1_uuid ===
                    participant?.participant_uuid
            )

            const participantsSide2Matches = theirMatches.filter(
                (match) =>
                    match.attributes.side_2_uuid ===
                    participant?.participant_uuid
            )

            for (const match of participantsSide1Matches) {
                filteredTotalPoints += +match?.attributes?.side_1_result
                lostPoints += +match?.attributes?.side_2_result

                if (
                    match?.attributes?.loser_participant_uuid &&
                    participant?.participant_uuid !==
                        match?.attributes?.loser_participant_uuid
                ) {
                    filteredWinsNumber++
                }

                const { matches } = await getMatches(
                    `fields[0]=side_1_uuid&fields[1]=side_2_uuid&filters[club_match_uuid]=${match?.attributes?.uuid}&populate[0]=side_1_result&populate[1]=side_2_result`
                )

                matches?.map((match) => {
                    if (!match?.attributes?.loser_participant_uuid) return

                    match?.attributes?.side_1_result?.result?.map(
                        (game, index) => {
                            gamesWonNumber += +game
                            gamesLostNumber +=
                                +match?.attributes?.side_2_result?.result[index]
                            if (
                                game >
                                match?.attributes?.side_2_result?.result[index]
                            ) {
                                setsWonNumber++
                            } else {
                                setsLostNumber++
                            }
                        }
                    )
                })
            }

            for (const match of participantsSide2Matches) {
                filteredTotalPoints += +match?.attributes?.side_2_result
                lostPoints += +match?.attributes?.side_1_result

                if (
                    match?.attributes?.loser_participant_uuid &&
                    participant?.participant_uuid !==
                        match?.attributes?.loser_participant_uuid
                ) {
                    filteredWinsNumber++
                }

                const { matches } = await getMatches(
                    `fields[0]=side_1_uuid&fields[1]=side_2_uuid&filters[club_match_uuid]=${match?.attributes?.uuid}&populate[0]=side_1_result&populate[1]=side_2_result`
                )

                matches?.map((match) => {
                    if (!match?.attributes?.loser_participant_uuid) return

                    match?.attributes?.side_2_result?.result?.map(
                        (game, index) => {
                            gamesWonNumber += +game
                            gamesLostNumber +=
                                +match?.attributes?.side_1_result?.result[index]
                            if (
                                game >
                                match?.attributes?.side_1_result?.result[index]
                            ) {
                                setsWonNumber++
                            } else {
                                setsLostNumber++
                            }
                        }
                    )
                })
            }

            participant.filtered_wins_number = filteredWinsNumber
            participant.filtered_match_difference =
                filteredTotalPoints - lostPoints
            participant.filtered_set_difference = setsWonNumber - setsLostNumber
            participant.filtered_game_difference =
                gamesWonNumber - gamesLostNumber
        }

        groupsByTotalPoints?.[key]?.sort((a, b) => {
            if (b.filtered_wins_number !== a.filtered_wins_number) {
                return b.filtered_wins_number - a.filtered_wins_number
            } else if (
                b.filtered_match_difference !== a.filtered_match_difference
            ) {
                return b.filtered_match_difference - a.filtered_match_difference
            } else if (
                b.filtered_set_difference !== a.filtered_set_difference
            ) {
                return b.filtered_set_difference - a.filtered_set_difference
            } else if (
                b.filtered_game_difference !== a.filtered_game_difference
            ) {
                return b.filtered_game_difference - a.filtered_game_difference
            } else {
                return 0
            }
        })

        return groupsByTotalPoints?.[key] || []
    }
}
