import React, { useEffect, useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import Button from '../../../../../../../../components/Button'
import { useAppContext } from '../../../../../../../../lib/contextLib'
import AddClubToGroupModal from './addClubsToGroupModal'
import {
    getClubParticipantsOfGroup,
    getClubMatchParticipants,
    getSingleClubWithQuery,
    createClubMatch,
    getGroupClubMatches,
    deleteClubMatch,
} from '../../../../../../../../lib/api'
import TeamGroupParticipantCard from '../../../../../../../../components/TeamGroupParticipant'
import TournamentClubMatchCard from '../../../../../../../../components/TournamentClubMatchCard'
import DeleteParticipantsFromGroupModal from '../../RoundRobin/deleteParticipantsFromGroupModal'
import { rankCompetitors } from './roundRobinGroupRankCalculations'

const TeamGroupParticipantsSection = ({
    tournament,
    groupDetails,
    drawToShow,
}) => {
    const { user, setIsLoading } = useAppContext()

    const [showAddClubsModal, setShowAddClubsModal] = useState(false)
    const [groupParticipants, setGroupParticipants] = useState([])
    const [groupMatches, setGroupMatches] = useState([])
    const [showDeleteMatchesModal, setShowDeleteMatchesModal] = useState(false)
    const [rankedParticipantsList, setRankedParticipantsList] = useState([])
    const [isManualRankingEnabled, setIsManualRankingEnabled] = useState(false)

    const handleGetGroupParticipants = async () => {
        try {
            setIsLoading(true)
            const groupParticipantsData = await getClubParticipantsOfGroup({
                tournament_uuid: groupDetails?.tournament_uuid,
                group_number: groupDetails?.group_number,
            })

            if (!groupParticipantsData) return

            const fetchParticipantDetails = async (participant) => {
                const participantData = await getClubMatchParticipants(
                    participant.attributes.participant_uuid
                )
                return {
                    ...participant,
                    attributes: {
                        ...participant.attributes,
                        participant: participantData,
                    },
                }
            }

            const fetchClubDetails = async (participant) => {
                const club = await getSingleClubWithQuery(
                    participant.attributes.participant.attributes.club_uuid,
                    'fields[0]=name&fields[1]=short_name&fields[2]=uuid&populate[logo][populate]'
                )

                return {
                    ...participant,
                    attributes: {
                        ...participant.attributes,
                        club: club,
                    },
                }
            }

            const participantsWithDetails = await Promise.all(
                groupParticipantsData.map(fetchParticipantDetails)
            )
            const participantsWithClub = await Promise.all(
                participantsWithDetails.map(fetchClubDetails)
            )

            setGroupParticipants(participantsWithClub)

            const groupMatchesData = await handleGetGroupMatches()
            const rankedParticipantsData = await rankCompetitors(
                participantsWithClub,
                groupMatchesData
            )

            setGroupMatches(groupMatchesData)
            setRankedParticipantsList(rankedParticipantsData)
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleParticipantsMatches = async () => {
        if (
            !groupParticipants ||
            groupParticipants.length < 3 ||
            groupParticipants.length > 4
        ) {
            return
        }

        const generateCombinations = (arr, n) => {
            let result = []
            let f = (active, start, original) => {
                if (active.length === n) {
                    result.push(active)
                } else {
                    for (let i = start; i < original.length; i++) {
                        f([...active, original[i]], i + 1, original)
                    }
                }
            }
            f([], 0, arr)
            return result
        }

        const matchPromises = []
        const matches = []
        const participantCombinations = generateCombinations(
            groupParticipants,
            2
        )

        for (const [
            index,
            participantsPair,
        ] of participantCombinations.entries()) {
            const [participant1, participant2] = participantsPair

            const matchPromise = createClubMatch({
                draw_type: {
                    draw_type_name: drawToShow?.draw_type?.draw_type_name,
                },
                tournament_uuid: groupDetails?.tournament_uuid,
                round_number: groupDetails?.group_number,
                pair_number: index + 1,
                side_1_uuid: participant1?.attributes?.participant_uuid || null,
                side_2_uuid: participant2?.attributes?.participant_uuid || null,
            })

            matchPromises.push(matchPromise)
        }

        await Promise.all(matchPromises, matches)
        handleGetGroupMatches()
    }

    const handleGetGroupMatches = async () => {
        try {
            const groupMatchesData = await getGroupClubMatches({
                tournament_uuid: groupDetails?.tournament_uuid,
                draw_type: {
                    draw_type_name: drawToShow?.draw_type?.draw_type_name,
                },
                round_number: groupDetails?.group_number,
            })

            if (groupMatchesData) {
                setGroupMatches(groupMatchesData)
                return groupMatchesData
            }
        } catch (error) {
            throw new Error(error)
        }
    }

    const handleDeleteAllGroupMatches = async () => {
        const deletedMatchesPromise = await Promise.all(
            groupMatches?.map(async (match) => {
                const deletedMatch = await deleteClubMatch(
                    match?.attributes?.uuid
                )
                if (deletedMatch) {
                    return deletedMatch
                }
            })
        )

        if (deletedMatchesPromise) {
            setShowDeleteMatchesModal(false)
            handleGetGroupMatches()
        }
    }

    useEffect(() => {
        handleGetGroupParticipants()
    }, [drawToShow])
    return (
        <Row>
            <Col>
                {rankedParticipantsList?.length < 4 &&
                    user?.role?.type !== 'competitor' && (
                        <Row>
                            <Col className="d-flex justify-content-center">
                                <Button
                                    type="secondary"
                                    onClick={() => {
                                        setShowAddClubsModal(true)
                                    }}
                                >
                                    Dodaj klubove
                                </Button>
                            </Col>
                        </Row>
                    )}

                {rankedParticipantsList?.length > 1 &&
                    user?.role?.type !== 'competitor' && (
                        <Row>
                            <Col className="d-flex justify-content-end">
                                <Row>
                                    <Col>
                                        <Form.Switch
                                            name="competition-finished-switch"
                                            label={
                                                'Omogući manuelno rangiranje u grupi'
                                            }
                                            checked={
                                                isManualRankingEnabled || false
                                            }
                                            onChange={() =>
                                                setIsManualRankingEnabled(
                                                    (prev) => !prev
                                                )
                                            }
                                            disabled={
                                                user?.role?.type ===
                                                'competitor'
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <p className="small-text">
                                            Za poništavanje manuelnog
                                            rangiranja, postaviti svima isti
                                            rang
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )}

                {rankedParticipantsList?.map((participant, index) => {
                    return (
                        <TeamGroupParticipantCard
                            key={index}
                            index={index}
                            className="mt-2"
                            participantData={participant?.participant_data}
                            handleGetGroupParticipants={
                                handleGetGroupParticipants
                            }
                            groupMatches={groupMatches}
                            isManualRankingEnabled={isManualRankingEnabled}
                        />
                    )
                })}

                {groupMatches?.length < 1 &&
                    rankedParticipantsList?.length > 2 &&
                    user?.role?.type !== 'competitor' && (
                        <Row>
                            <Col className="d-flex justify-content-end">
                                <Button
                                    onClick={() => handleParticipantsMatches()}
                                    className="mt-3"
                                >
                                    Kreiraj mečeve za ovu grupu
                                </Button>
                            </Col>
                        </Row>
                    )}

                {groupMatches?.length > 0 &&
                    user?.role?.type !== 'competitor' && (
                        <Row>
                            <Col className="d-flex justify-content-end">
                                <Button
                                    onClick={() =>
                                        setShowDeleteMatchesModal(true)
                                    }
                                    className="mt-3"
                                    type="secondary"
                                >
                                    Obriši sve mečeve grupe
                                </Button>
                            </Col>
                        </Row>
                    )}

                <Row className="gx-5 gy-3 mt-4">
                    {groupMatches?.map((match, index) => {
                        return (
                            <Col xs={12} md={4} key={index}>
                                <TournamentClubMatchCard
                                    tournament={tournament}
                                    matchDetails={{
                                        match,
                                        round_number:
                                            groupDetails?.group_number,
                                        tournament_uuid:
                                            tournament?.attributes?.uuid,
                                        tournament_type_name:
                                            tournament?.tournament_type
                                                ?.tournament_type_name,
                                        ...drawToShow,
                                    }}
                                    onChange={handleGetGroupParticipants}
                                />
                            </Col>
                        )
                    })}
                </Row>
            </Col>

            <AddClubToGroupModal
                show={showAddClubsModal}
                onHide={() => setShowAddClubsModal(false)}
                groupDetails={groupDetails}
                drawToShow={drawToShow}
                handleGetGroupParticipants={handleGetGroupParticipants}
            />

            <DeleteParticipantsFromGroupModal
                show={showDeleteMatchesModal}
                onHide={() => setShowDeleteMatchesModal(false)}
                handleDeleteAllGroupMatches={handleDeleteAllGroupMatches}
            />
        </Row>
    )
}

export default TeamGroupParticipantsSection
