import { Accordion, Col, Row, Table } from 'react-bootstrap'
import { formatDate, handleGenders, isArrayEmpty } from '../../lib/helpers'

import './styles.scss'

const CompetitionReport = ({ reportData }) => {
    // console.log(reportData)

    const handleCheckClubDirector = (clubStaff, attributeName = false) => {
        if (!clubStaff) return 'Nema informacija'
        if (isArrayEmpty(clubStaff)) return 'Nema informacija'

        let director = clubStaff?.find((item) => item?.title === 'Direktor')
        return director
            ? !attributeName
                ? `${director?.given_name} ${director?.family_name}`
                : `${
                      director?.[attributeName]
                          ? director?.[attributeName]
                          : 'Nema informacija'
                  }`
            : 'Nema informacija'
    }

    const handleCheckMainRefereeName = (refereeList) => {
        if (!refereeList) return 'Nema informacija'
        if (isArrayEmpty(refereeList)) return 'Nema informacija'

        let mainReferee = refereeList?.find(
            (item) =>
                item?.attributes?.referee_type?.referee_type_name ===
                'Vrhovni sudija'
        )
        return mainReferee
            ? `${mainReferee?.attributes?.refereeData?.attributes?.person_info?.given_name} ${mainReferee?.attributes?.refereeData?.attributes?.person_info?.family_name}`
            : 'Nema informacija'
    }

    const handleCheckMainRefereeLicense = (refereeList) => {
        if (!refereeList) return 'Nema informacija'
        if (isArrayEmpty(refereeList)) return 'Nema informacija'

        let mainReferee = refereeList?.find(
            (item) =>
                item?.attributes?.referee_type?.referee_type_name ===
                'Vrhovni sudija'
        )
        return mainReferee
            ? handleLicensesNames(
                  mainReferee?.attributes?.refereeData?.licenses?.attributes
              )
            : 'Nema informacija'
    }

    const handleLicensesNames = (licenses) => {
        if (!licenses) return 'Nema informacija'
        if (isArrayEmpty(licenses)) return 'Nema informacija'

        const {
            core_license,
            international_chair_badge,
            international_main_badge,
            national_license_type,
        } = licenses

        const validLicenseNames = []

        const licensesData = [
            {
                core_license,
                international_chair_badge,
                international_main_badge,
                national_license_type,
            },
        ]

        licensesData.forEach((item) => {
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    const keyName = key + '_name'
                    const licenseName = item[key][keyName]
                    if (licenseName !== null && licenseName !== undefined) {
                        validLicenseNames.push(licenseName)
                    }
                }
            }
        })

        const concatenatedString = validLicenseNames.join(', ')
        return concatenatedString
    }

    const handleMainRefereeCity = (refereeList) => {
        if (!refereeList) return 'Nema informacija'
        if (isArrayEmpty(refereeList)) return 'Nema informacija'

        let mainReferee = refereeList?.find(
            (item) =>
                item?.attributes?.referee_type?.referee_type_name ===
                'Vrhovni sudija'
        )

        return mainReferee
            ? mainReferee?.attributes?.refereeData?.attributes
                  ?.residential_address?.city
                ? mainReferee?.attributes?.refereeData?.attributes
                      ?.residential_address?.city
                : 'Nema informacija'
            : 'Nema informacija'
    }

    return (
        <Row className="competition-report">
            <Col>
                {/*
            //* Commented code until its declared what it is for 
            */}
                {/* {referee && (
                    <Col className="d-flex aign-items-center justify-content-between result-row">
                        <h3>Rezultati</h3>
                        <p className="link" onClick={onLinkClick}>
                            Pregled
                        </p>
                    </Col>
                )} */}
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <h3>Opšte informacije</h3>
                        </Accordion.Header>
                        <Accordion.Body className="px-0">
                            <Table striped>
                                <tbody>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Puno ime takmičenja</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.name
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="align-middle">
                                            <p>Poslednji dan za prijavu</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {reportData?.attributes
                                                        ?.application_deadline_date
                                                        ? formatDate(
                                                              reportData
                                                                  ?.attributes
                                                                  ?.application_deadline_date
                                                          )
                                                        : 'Nema informacije'}
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Poslednji dan za odjavu</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {reportData?.attributes
                                                        ?.checkout_deadline_date
                                                        ? formatDate(
                                                              reportData
                                                                  ?.attributes
                                                                  ?.checkout_deadline_date
                                                          )
                                                        : 'Nema informacije'}
                                                </p>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="align-middle">
                                            <p>Klub</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {reportData?.attributes
                                                        ?.club_organizer
                                                        ? reportData?.attributes
                                                              ?.club_organizer
                                                              .attributes?.name
                                                        : 'Nema informacija'}
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Adresa kluba</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {reportData?.attributes
                                                        ?.club_organizer
                                                        ?.address?.street &&
                                                    reportData?.attributes
                                                        ?.club_organizer
                                                        ?.address?.city
                                                        ? `${reportData?.attributes?.club_organizer.attributes?.address?.street}, ${reportData?.attributes?.club_organizer.attributes?.address?.city}`
                                                        : 'Nema informacija'}
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Konkurencija</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {reportData?.attributes
                                                        ?.players_competition
                                                        ? `${
                                                              reportData
                                                                  ?.attributes
                                                                  ?.players_competition
                                                                  ?.players_competition_name
                                                          } ${
                                                              reportData
                                                                  ?.attributes
                                                                  ?.players_competition
                                                                  ?.players_competition_subcategory_name
                                                                  ? reportData
                                                                        ?.attributes
                                                                        ?.players_competition
                                                                        ?.players_competition_subcategory_name
                                                                  : ''
                                                          }`
                                                        : 'Nema informacija'}
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Kategorija</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {reportData?.attributes
                                                        ?.tournament_category
                                                        ? `${
                                                              reportData
                                                                  ?.attributes
                                                                  ?.tournament_category
                                                                  ?.tournament_category_name
                                                          } ${
                                                              reportData
                                                                  ?.attributes
                                                                  ?.tournament_category
                                                                  ?.tournament_subcategory_name
                                                                  ? reportData
                                                                        ?.attributes
                                                                        ?.tournament_category
                                                                        ?.tournament_subcategory_name
                                                                  : ''
                                                          }`
                                                        : 'Nema informacija'}
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Pol</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {reportData?.attributes
                                                        ?.genders
                                                        ? handleGenders(
                                                              reportData
                                                                  ?.attributes
                                                                  ?.genders
                                                          )
                                                        : 'Nema informacija'}
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Glavni sudija</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {handleCheckMainRefereeName(
                                                        reportData?.attributes
                                                            ?.referees
                                                    )}
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            <h3>Organizator, sudije, službena lica</h3>
                        </Accordion.Header>
                        <Accordion.Body className="px-0">
                            <Table striped>
                                <tbody>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Direktor kluba</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {handleCheckClubDirector(
                                                        reportData?.attributes
                                                            ?.club_organizer
                                                            ?.attributes?.staff
                                                    )}
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Telefon direktora</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {handleCheckClubDirector(
                                                        reportData?.attributes
                                                            ?.club_organizer
                                                            ?.attributes?.staff,
                                                        'phone'
                                                    )}
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Email direktora</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {handleCheckClubDirector(
                                                        reportData?.attributes
                                                            ?.club_organizer
                                                            ?.attributes?.staff,
                                                        'email'
                                                    )}
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Glavni sudija</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {handleCheckMainRefereeName(
                                                        reportData?.attributes
                                                            ?.referees
                                                    )}
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Grad</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {handleMainRefereeCity(
                                                        reportData?.attributes
                                                            ?.referees
                                                    )}
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Licenca</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {handleCheckMainRefereeLicense(
                                                        reportData?.attributes
                                                            ?.referees
                                                    )}
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>
                            <h3>
                                Takmičari koji su bili prijavljeni ali se nisu
                                pojavili
                            </h3>
                        </Accordion.Header>
                        <Accordion.Body className="px-0">
                            <Table>
                                <thead>
                                    <tr>
                                        <th>
                                            <p>Takmičar</p>
                                        </th>
                                        <th>
                                            <p>Šifra</p>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reportData?.attributes?.competition_report[0]?.report_data?.competitors?.map(
                                        ({ name, code, club }) => (
                                            <tr key={`${name}-${code}`}>
                                                <td className="align-middle align-items-start flex-column">
                                                    <p className="mb-2">
                                                        {name}
                                                    </p>
                                                    <p className="small-text">
                                                        {club
                                                            ? club
                                                            : 'Nema informacija o klubu'}
                                                    </p>
                                                </td>
                                                <td className="align-middle">
                                                    <p>{code}</p>
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>
                            <h3>Nagrade i kotizacije</h3>
                        </Accordion.Header>
                        <Accordion.Body className="px-0">
                            <Table striped>
                                <tbody>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Ukupan nagradni fond</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.awards
                                                            ?.totalAwardFond
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Nagrade (muškarci i žene)</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.awards
                                                            ?.awardsMenWomen
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Nagrade dubl</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.awards
                                                            ?.awardsDouble
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Kotizacija za kvalifikacije</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.awards
                                                            ?.registrationFeeQualifications
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Kotizacija za glavni turnir</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.awards
                                                            ?.registrationFeeTournament
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>
                            <h3>Tereni, lopte i fizioterapeut</h3>
                        </Accordion.Header>
                        <Accordion.Body className="px-0">
                            <h4 className="mb-3">Centralni teren</h4>
                            <Table striped>
                                <tbody>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Podloga</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.centralCourt
                                                            ?.surface
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Količina</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.centralCourt
                                                            ?.quantity
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Kvalitet (1-5)</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.centralCourt
                                                            ?.quality
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Održavanje (1-5)</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.centralCourt
                                                            ?.maintenance
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <h4 className="my-3">Ostali tereni</h4>
                            <Table striped>
                                <tbody>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Podloga</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.otherCourts
                                                            ?.surface
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Količina</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.otherCourts
                                                            ?.quantity
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Kvalitet (1-5)</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.otherCourts
                                                            ?.quality
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Održavanje (1-5)</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.otherCourts
                                                            ?.maintenance
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <h4 className="my-3">Reflektori</h4>
                            <Table striped>
                                <tbody>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Upotrebljavani</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.reflectors?.used
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Žalbe na kvalitet</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.reflectors
                                                            ?.complaint
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Po pravilniku TSS-a</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.reflectors
                                                            ?.rulebook
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <h4 className="my-3">Tereni za trening</h4>
                            <Table striped>
                                <tbody>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Podloga</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.trainingCourts
                                                            ?.surface
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Količina</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.trainingCourts
                                                            ?.quantity
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Kvalitet (1-5)</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.trainingCourts
                                                            ?.quality
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Održavanje (1-5)</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.trainingCourts
                                                            ?.maintenance
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Generalni izgled terena</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.trainingCourts
                                                            ?.generalAppearance
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <h4 className="my-3">Lopte</h4>
                            <Table striped>
                                <tbody>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Proizvođač</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data?.balls
                                                            ?.manufacturer
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Kvalitet (1-5)</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data?.balls
                                                            ?.quality
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Odobrene od ITF</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data?.balls
                                                            ?.rulebookITF
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>
                                                Utrošena količina lopti na
                                                turniru
                                            </p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data?.balls
                                                            ?.usedBalls
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Zamena lopti</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data?.balls
                                                            ?.replacement
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Po pravilniku TSS-a</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data?.balls
                                                            ?.rulebookTSS
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <h4 className="my-3">Fizioterapeut</h4>
                            <Table striped>
                                <tbody>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Ime i prezime</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.physiotherapist
                                                            ?.name
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Poznavanje pravila</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.physiotherapist
                                                            ?.rulesKnowledge
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Specijalizacija</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.physiotherapist
                                                            ?.specialization
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Na turnir poziva</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.physiotherapist
                                                            ?.invite
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header>
                            <h3>Objekti i organizacija turnira</h3>
                        </Accordion.Header>
                        <Accordion.Body className="px-0">
                            <h4 className="mb-3">
                                Kancelarija vrhovnog sudije
                            </h4>
                            <Table striped>
                                <tbody>
                                    <tr>
                                        <td className="align-middle">
                                            <p>
                                                Broj prostorije (odabrati ocenu)
                                            </p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.chiefOffice
                                                            ?.roomNumber
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Prateća oprema</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.chiefOffice
                                                            ?.chiefRating
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Lokacija (uz terene)</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.chiefOffice
                                                            ?.location
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <h4 className="my-3">Komunikacija</h4>
                            <Table striped>
                                <tbody>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Međunarodna linija</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.communication
                                                            ?.internationalLine
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Fax</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.communication?.fax
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Email</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.communication
                                                            ?.email
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Fotokopir</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.communication
                                                            ?.photocopier
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Voki-toki</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.communication
                                                            ?.walkieTalkie
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Ocena</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.communication
                                                            ?.communicationRating
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <h4 className="my-3">Službena lica</h4>
                            <Table striped>
                                <tbody>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Turnirsko osoblje</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.officials
                                                            ?.tournamentStaff
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Drugo osoblje</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.officials
                                                            ?.otherStaff
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Poznavanje pravila</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.officials
                                                            ?.rulesKnowledge
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Ocena</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.officials
                                                            ?.officialsRating
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <h4 className="my-3">Objekti za igrače</h4>
                            <Table striped>
                                <tbody>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Objekat 1</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.playersFacilities
                                                            ?.facility_1
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Objekat 2</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.playersFacilities
                                                            ?.facility_2
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <p>Objekat 3</p>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p>
                                                    {
                                                        reportData?.attributes
                                                            ?.competition_report[0]
                                                            ?.report_data
                                                            ?.playersFacilities
                                                            ?.facility_3
                                                    }
                                                </p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header>
                            <h3>Pregled i zaključak</h3>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Row className="mb-3">
                                <Col>
                                    <h5 className="mb-1">
                                        Uslovi organizacije na turniru koji su
                                        bili dobri
                                    </h5>
                                    <p>
                                        {
                                            reportData?.attributes
                                                ?.competition_report[0]
                                                ?.report_data?.conclusion
                                                ?.goodCondition
                                        }
                                    </p>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <h5 className="mb-1">
                                        Uslovi organizacije na turniru koji
                                        zahtevaju poboljšanja
                                    </h5>
                                    <p>
                                        {
                                            reportData?.attributes
                                                ?.competition_report[0]
                                                ?.report_data?.conclusion
                                                ?.improvementCondition
                                        }
                                    </p>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <h5 className="mb-1">
                                        Saveti za buduće turnire
                                    </h5>
                                    <p>
                                        {
                                            reportData?.attributes
                                                ?.competition_report[0]
                                                ?.report_data?.conclusion?.tips
                                        }
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h5 className="mb-1">Generalne napomene</h5>
                                    <p>
                                        {
                                            reportData?.attributes
                                                ?.competition_report[0]
                                                ?.report_data?.conclusion
                                                ?.generalRemarks
                                        }
                                    </p>
                                </Col>
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Col>
        </Row>
    )
}

export default CompetitionReport
