import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import ClubTournamentResultTable from '../../../../../../components/ClubTournamentResultTable'
import { RowsPerPageOptions } from '../../../../../../components/RowsPerPageDropdown'
import {
    getClubTournamentParticipations,
    getSingleTournament,
} from '../../../../../../lib/api'

const Results = ({ club, className }) => {
    const [clubsTournamentParticipations, setClubsTournamentParticipations] =
        useState([])

    const [currentPage, setCurrentPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [total, setTotal] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(RowsPerPageOptions[0])
    

    const handleGetClubTournamentParticipations = async () => {
        try {
            const clubUuid = club?.attributes?.uuid
            if (!clubUuid) {
                console.error('Club UUID is missing.')
                return
            }

            const { participants, pgCount, total } =
                await getClubTournamentParticipations(
                    clubUuid,
                    currentPage,
                    rowsPerPage
                )
            if (!participants) {
                console.error('No tournament participations data found.')
                return
            }

            const updatedClubsTournamentParticipationsPromises =
                participants.map(async (participant) => {
                    const tournamentUuid =
                        participant?.attributes?.tournament_uuid
                    const tournament = tournamentUuid
                        ? await getSingleTournament(tournamentUuid, [
                              'uuid',
                              'name',
                          ])
                        : null
                    return {
                        ...participant,
                        tournament,
                    }
                })

            const updatedClubsTournamentParticipations = await Promise.all(
                updatedClubsTournamentParticipationsPromises
            )

            setTotal(total)
            setPageCount(pgCount)
            setClubsTournamentParticipations(
                updatedClubsTournamentParticipations
            )
        } catch (error) {
            console.error('An error occurred:', error)
        }
    }

    useEffect(() => {
        handleGetClubTournamentParticipations()
    }, [club, rowsPerPage, currentPage])
    return (
        <Row className="mt-3">
            <Col>
                <ClubTournamentResultTable
                    club={club}
                    tournaments={clubsTournamentParticipations}
                    total={total}
                    pageCount={pageCount}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                />
            </Col>
        </Row>
    )
}

export default Results
