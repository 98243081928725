import React, { useRef } from 'react'
import { Row, Col, Modal, Card } from 'react-bootstrap'
import Button from '../../../../../../components/Button'
import TournamentScheduleCard from '../../../../../../components/TournamentScheduleCard'
import TournamentRoundCard from '../../../../../../components/TournamentRoundCard'
import Slider from 'react-slick'
import { settings } from '../Draw/carouselSettings'
import html2PDF from 'jspdf-html2canvas'

const ScheduleModalForExport = ({
    show,
    onHide,
    schedules,
    tournamentsFields,
    selectedScheduleDate,
}) => {
    const pdfRef = useRef(null)

    const handleDownload = () => {
        let pdfdoc = document.getElementById('draw-pdf')

        html2PDF(pdfdoc, {
            jsPDF: {
                format: 'a4',
                orientation: 'landscape',
            },
            imageType: 'image/jpeg',
            output: `Raspored.pdf`,
        })
    }

    return (
        <Modal
            centered
            show={show}
            onHide={onHide}
            dialogClassName="add-penalties-modal"
            fullscreen={true}
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <Button
                    type="secondary"
                    onClick={() => handleDownload()}
                    className="mb-3"
                >
                    Eksportuj u PDF
                </Button>
                <Row>
                    <Col id="draw-pdf" ref={pdfRef} className="p-4">
                        <h3 className="mb-4">
                            Takmičarski dan: {selectedScheduleDate}
                        </h3>
                        <Slider
                            {...settings(
                                schedules?.length < 3 ? schedules?.length : 3
                            )}
                            className="rounds-slider"
                        >
                            {schedules?.map((scheduleList, index) => (
                                <TournamentRoundCard
                                    roundName={
                                        tournamentsFields[index]?.attributes
                                            ?.field_name
                                    }
                                    key={index}
                                    className="me-3"
                                    index={index}
                                >
                                    <Row>
                                        <Col>
                                            {selectedScheduleDate ? (
                                                <>
                                                    {scheduleList?.map(
                                                        (schedule, index) => (
                                                            <TournamentScheduleCard
                                                                key={index}
                                                                scheduleUuid={
                                                                    schedule
                                                                        ?.attributes
                                                                        ?.uuid
                                                                }
                                                                className="mt-3"
                                                                hideButton={
                                                                    true
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </>
                                            ) : (
                                                <Card>
                                                    <Card.Body>
                                                        <p>
                                                            Potrebno je izabrati
                                                            takmičarski dan pre
                                                            dodavanja meča u
                                                            raspored.
                                                        </p>
                                                    </Card.Body>
                                                </Card>
                                            )}
                                        </Col>
                                    </Row>
                                </TournamentRoundCard>
                            ))}
                        </Slider>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide} type="secondary">
                    Zatvori
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ScheduleModalForExport
