import { useEffect, useState } from 'react'
import { Breadcrumb, Col, Row, Tab, Tabs } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { getClubAssemblyRankingSettings } from '../../../../lib/api'
import AssemblyClubCard from '../../../../components/ClubRankingCardSettings/AssemblyClubCard'
import AssemblyClubQualityCard from '../../../../components/ClubRankingCardSettings/AssemblyClubCard/AssemblyClubQualityCard'
import AssemblyInternationalTournamentOrganizationCard from '../../../../components/ClubRankingCardSettings/AssemblyClubCard/AssemblyInternationalTournamentOrganizationCard'
import AssemblyClubQualityTable from '../../../../components/ClubRankingTableSettings/AssemblySettings/AssemblyClubQualityTable'
import AssemblySettingsTable from '../../../../components/ClubRankingTableSettings/AssemblySettings/AssemblyFieldsTable'
import AssemblyInternationalOrganizationTable from '../../../../components/ClubRankingTableSettings/AssemblySettings/AssemblyInternationalOrganizationTable'
import './styles.scss'

const ClubsRankingSettings = () => {
    const [clubAssemblySettings, setClubAssemblySettings] = useState(null)

    const getClubAssemblySettings = async () => {
        let data = await getClubAssemblyRankingSettings()
        if (!data) return

        setClubAssemblySettings(data?.attributes)
    }

    useEffect(() => {
        getClubAssemblySettings()
    }, [])

    return (
        <Row className="clubs-ranking-settings-wrapper h-100 clubs-ranking-settings">
            <Col md={12}>
                <Row className="pt-3">
                    <Col md={12} className="d-flex flex-column">
                        <h2 className="d-none d-md-block">Podešavanja</h2>
                        <p className="d-block d-md-none">Podešavanja</p>
                        <Breadcrumb className="mt-md-2 mt-1">
                            <Breadcrumb.Item
                                linkAs={Link}
                                linkProps={{ to: '/rang-lista-klubova' }}
                            >
                                Rang liste
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                Podešavanja
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <p className="small-text">
                            Vrednosti unete za polja "od" ili "do", uključene su
                            u opseg.
                        </p>
                    </Col>
                </Row>

                <Row className="pt-3 m-0">
                    <Col className="p-0 tabular-navigation">
                        <Tabs id="uncontrolled-tab-example">
                            <Tab eventKey="bodovanje" title="Bodovanje">
                                <Row>
                                    <Col>
                                        <div className="d-flex align-items-center justify-content-between tab-title">
                                            <h3 className="ps-3 py-3">
                                                Tereni
                                            </h3>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="d-none d-md-block">
                                    <Col>
                                        <AssemblySettingsTable
                                            title_from_to="terena"
                                            data={
                                                clubAssemblySettings?.courts_settings
                                            }
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="d-md-none">
                                        {clubAssemblySettings?.courts_settings?.map(
                                            (result, index) => (
                                                <AssemblyClubCard
                                                    key={index}
                                                    result={result}
                                                    title_from_to={'terena'}
                                                    isWhite={
                                                        index % 2 !== 0
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            )
                                        )}
                                    </Col>
                                </Row>

                                <Row className="mt-3">
                                    <Col>
                                        <div className="d-flex align-items-center justify-content-between tab-title">
                                            <h3 className="ps-3 py-3">
                                                Registrovani igrači
                                            </h3>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="d-none d-md-block">
                                    <Col>
                                        <AssemblySettingsTable
                                            title_from_to="igrača"
                                            data={
                                                clubAssemblySettings?.registered_players_settings
                                            }
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="d-md-none">
                                        {clubAssemblySettings?.registered_players_settings?.map(
                                            (result, index) => (
                                                <AssemblyClubCard
                                                    key={index}
                                                    result={result}
                                                    title_from_to={'igrača'}
                                                    isWhite={
                                                        index % 2 !== 0
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            )
                                        )}
                                    </Col>
                                </Row>

                                <Row className="mt-3">
                                    <Col>
                                        <div className="d-flex align-items-center justify-content-between tab-title">
                                            <h3 className="ps-3 py-3">
                                                Kvalitet klubova
                                            </h3>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="d-none d-md-block">
                                    <Col>
                                        <AssemblyClubQualityTable
                                            data={
                                                clubAssemblySettings?.club_quality_settings
                                            }
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="d-md-none">
                                        {clubAssemblySettings?.club_quality_settings?.map(
                                            (result, index) => (
                                                <AssemblyClubQualityCard
                                                    key={index}
                                                    result={result}
                                                    isWhite={
                                                        index % 2 !== 0
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            )
                                        )}
                                    </Col>
                                </Row>

                                <Row className="mt-3">
                                    <Col>
                                        <div className="d-flex align-items-center justify-content-between tab-title">
                                            <h3 className="ps-3 py-3">
                                                Rangirani igrači
                                            </h3>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="d-none d-md-block">
                                    <Col>
                                        <AssemblySettingsTable
                                            title_from_to="rangiranih igrača"
                                            data={
                                                clubAssemblySettings?.ranked_players_settings
                                            }
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="d-md-none">
                                        {clubAssemblySettings?.ranked_players_settings?.map(
                                            (result, index) => (
                                                <AssemblyClubCard
                                                    key={index}
                                                    result={result}
                                                    title_from_to={'igrača'}
                                                    isWhite={
                                                        index % 2 !== 0
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            )
                                        )}
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <div className="d-flex align-items-center justify-content-between tab-title">
                                            <h3 className="ps-3 py-3">
                                                Organizacija međunarodnih
                                                turnira
                                            </h3>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="d-none d-md-block">
                                    <Col>
                                        <AssemblyInternationalOrganizationTable
                                            data={
                                                clubAssemblySettings?.international_tournament_organization_settings
                                            }
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="d-md-none">
                                        {clubAssemblySettings?.international_tournament_organization_settings?.map(
                                            (result, index) => (
                                                <AssemblyInternationalTournamentOrganizationCard
                                                    key={index}
                                                    result={result}
                                                    isWhite={
                                                        index % 2 !== 0
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            )
                                        )}
                                    </Col>
                                </Row>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default ClubsRankingSettings
