import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Cell, Pie, PieChart } from 'recharts'
import ArrowRight from '../../../../../assets/svg/ArrowRight'
import Button from '../../../../../components/Button'
import {
    getPlayerStatistisc,
    getPlayersRankingLists,
    getSingleCompetitorsRankingList,
} from '../../../../../lib/api'
import {
    calculateCategory,
    calculateSubcategory,
    formatDate,
    formatImgUrl,
} from '../../../../../lib/helpers'
import { generateAvatar } from '../../../../../utils/generateAvatar'
import './styles.scss'

import FileUploadTag from '../../../../../components/FileUploadTag'
import {
    getLastMedicalCerificate,
    getLastMembership,
    getPlayerDocuments,
    getPlayersClub,
    getPlayersClubHistory,
    getPlayersClubLoan,
    getSingleClub,
    getSingleCoach,
} from '../../../../../lib/api'
import { useAppContext } from '../../../../../lib/contextLib'
import { downloadImage } from '../../../../../utils/downloadimage'

const ProfileTab = ({
    competitor,
    setShowMembership,
    setShowMedicalHistory,
    loggedInUser,
    setShowClubHistory,
}) => {
    const { user } = useAppContext()
    const COLORS = ['#4B852D', '#D23E3F']
    const [competitorStatistics, setCompetitorStatistics] = useState({
        total_matches: 0,
        result: [
            { name: 'Pobeda', value: 0 },
            { name: 'Poraza', value: 0 },
        ],
    })

    const [lastMembership, setLastMembership] = useState()
    const [lastMedicalCertificate, setLastMedicalCertificate] = useState()
    const [playersClub, setPlayersClub] = useState()
    const [playersClubLoan, setPlayersClubLoan] = useState()
    const [playersClubHistory, setPlayersClubHistory] = useState([])
    const [coachProfile, setCoachProfile] = useState()
    const [playersDocuments, setPlayersDocuments] = useState([])
    const [playersRankingList, setPlayersRankingList] = useState([])

    const userBasicInfo = [
        {
            label: 'Ime',
            name: competitor?.attributes?.person_info?.given_name,
        },
        {
            label: 'Prezime',
            name: competitor?.attributes?.person_info?.family_name,
        },
        {
            label: 'Trener',
            name: coachProfile
                ? `${coachProfile?.attributes?.person_info?.given_name} ${coachProfile?.attributes?.person_info?.family_name}`
                : false,
        },
        {
            label: 'Email',
            name: competitor?.attributes?.person_info?.email
                ? competitor?.attributes?.person_info?.email
                : false,
        },
    ]

    const userDetails = [
        {
            label: 'Datum rođenja',
            name: competitor?.attributes?.person_info?.birthdate
                ? formatDate(competitor?.attributes?.person_info?.birthdate)
                : false,
        },
        {
            label: 'Mesto rođenja',
            name: competitor?.attributes?.birth_address?.city
                ? competitor?.attributes?.birth_address?.city
                : false,
        },
        {
            label: 'Adresa',
            name:
                competitor?.attributes?.birth_address?.street &&
                competitor?.attributes?.birth_address?.city
                    ? `${competitor?.attributes?.birth_address?.street}, ${competitor?.attributes?.birth_address?.city}`
                    : false,
        },
        {
            label: 'Stranac',
            name:
                competitor?.attributes?.citizenship
                    ?.is_serbian_citizen_internationally_registered !== null &&
                competitor?.attributes?.citizenship
                    ?.is_serbian_citizen_internationally_registered !==
                    undefined
                    ? competitor?.attributes?.citizenship
                          ?.is_serbian_citizen_internationally_registered ===
                          true &&
                      (competitor?.attributes?.citizenship
                          ?.citizenship_alpha_3_code === 'SRB' ||
                          competitor?.attributes?.citizenship
                              ?.second_citizenship_alpha_3_code === 'SRB')
                        ? 'Ne'
                        : 'Da'
                    : false,
        },
        {
            label: 'Dvojno državljanstvo',
            name:
                competitor?.attributes?.citizenship?.citizenship_alpha_3_code &&
                competitor?.attributes?.citizenship
                    ?.second_citizenship_alpha_3_code &&
                competitor?.attributes?.citizenship?.citizenship_type
                    ?.citizenship_type_name === 'Dvojno'
                    ? 'Da'
                    : 'Ne',
        },
        {
            label: 'Pol',
            name: competitor?.attributes?.gender
                ? competitor?.attributes?.gender?.gender_name
                : null,
        },
        {
            label: 'JMBG',
            name: competitor?.attributes?.person_info?.jmbg
                ? competitor?.attributes?.person_info?.jmbg
                : false,
        },
        {
            label: 'Telefon',
            name: competitor?.attributes?.person_info?.phone
                ? competitor?.attributes?.person_info?.phone
                : false,
        },
        {
            label: 'Otac',
            name: competitor?.attributes?.fathers_name
                ? competitor?.attributes?.fathers_name
                : false,
        },
        {
            label: 'Majka',
            name: competitor?.attributes?.mothers_name
                ? competitor?.attributes?.mothers_name
                : false,
        },
        {
            label: 'Ruka kojom igra',
            name: competitor?.attributes?.playing_hand
                ? competitor?.attributes?.playing_hand?.playing_hand_name
                : false,
        },
        {
            label: 'Visina',
            name: competitor?.attributes?.height
                ? competitor?.attributes?.height
                : false,
        },
        {
            label: 'Težina',
            name: competitor?.attributes?.weight
                ? competitor?.attributes?.weight
                : false,
        },
        // {
        //     label: 'Tip takmičarske legitimacije',
        //     name: competitor?.attributes?.licence_type
        //         ? competitor?.attributes?.licence_type
        //         : false,
        // },
        {
            label: 'Datum poslednje registracije',
            name: lastMembership
                ? formatDate(lastMembership?.attributes?.date_of_payment)
                : false,
        },
    ]

    const handlePlayerStatistics = async () => {
        const playerStatisticData = await getPlayerStatistisc(
            competitor?.attributes?.uuid
        )
        if (!playerStatisticData) return

        setCompetitorStatistics({
            total_matches: playerStatisticData?.total_matches || 0,
            result: [
                {
                    name: `Pobeda (${
                        playerStatisticData?.number_of_wins || '0'
                    })`,
                    value: playerStatisticData?.number_of_wins || '0',
                },
                {
                    name: `Poraza (${
                        playerStatisticData?.number_of_defeats || '0'
                    })`,
                    value: playerStatisticData?.number_of_defeats || '0',
                },
            ],
        })
    }

    // Calculate 15 days or less until the end of the medical expiry date
    const endDateArray = competitor?.attributes?.medical_history?.map(
        (value) => value.end_date
    )
    const lastEndDate =
        endDateArray && endDateArray.length > 0
            ? endDateArray[endDateArray.length - 1]
            : null

    const today = new Date()
    const endDate = lastEndDate ? new Date(lastEndDate) : null

    const differenceInDays = endDate
        ? Math.floor((endDate - today) / (1000 * 60 * 60 * 24))
        : null

    const handleLastMembership = async () => {
        const lastMembership = await getLastMembership(
            competitor?.attributes?.uuid
        )

        if (lastMembership) {
            setLastMembership(lastMembership[0])
        }
    }

    const handleLastMedicalCertificate = async () => {
        const lastMedicalCertificate = await getLastMedicalCerificate(
            competitor?.attributes?.uuid
        )

        if (lastMedicalCertificate) {
            setLastMedicalCertificate(lastMedicalCertificate[0])
        }
    }

    const handlePlayersClub = async () => {
        const playersClubData = await getPlayersClub(
            competitor?.attributes?.uuid
        )

        if (playersClubData) {
            const club = await getSingleClub(
                playersClubData?.attributes?.club_uuid
            )
            if (club) {
                setPlayersClub(club)
            }
        }
    }

    const handlePlayersClubLoan = async () => {
        const playersClubLoanData = await getPlayersClubLoan(
            competitor?.attributes?.uuid
        )

        if (playersClubLoanData) {
            const club = await getSingleClub(
                playersClubLoanData?.attributes?.club_uuid
            )
            if (club) {
                setPlayersClubLoan({
                    ...playersClubLoanData,
                    club,
                })
            }
        }
    }

    const handlePlayersHistory = async () => {
        const playersClubHistoryData = await getPlayersClubHistory(
            competitor?.attributes?.uuid
        )

        if (playersClubHistoryData) {
            const playersClubs = await handlePlayersClubsHistory(
                playersClubHistoryData
            )
            if (playersClubs) {
                setPlayersClubHistory(playersClubs)
            }
        }
    }

    const handlePlayersClubsHistory = async (playersClubs) => {
        const clubs = playersClubs?.map(async (clubItem) => {
            const obj = await getSingleClub(clubItem?.attributes?.club_uuid)
            return { ...clubItem, club: { ...obj } }
        })
        const clubsObjects = await Promise.all(clubs)
        return clubsObjects
    }

    const getCoachProfile = async () => {
        if (competitor?.attributes?.coach_uuid) {
            const coach = await getSingleCoach(
                competitor?.attributes?.coach_uuid
            )

            if (coach) {
                setCoachProfile(coach)
            }
        }
    }

    const fetchPlayerSDocuments = async () => {
        let documents = await getPlayerDocuments(competitor?.attributes?.uuid)

        if (documents) {
            setPlayersDocuments(documents?.attributes?.player_documents)
        }
    }

    const handlePlayersRankingLists = async () => {
        if (!competitor?.attributes?.uuid) return
        const playersRankingListsData = await getPlayersRankingLists(
            competitor?.attributes?.uuid
        )

        if (!playersRankingListsData) return

        const updatedRankingLists = await Promise.all(
            playersRankingListsData.map(async (rankingList) => {
                const singleRankingList = await getSingleCompetitorsRankingList(
                    rankingList?.attributes?.ranking_list_uuid
                )

                return {
                    ...rankingList,
                    attributes: {
                        ...rankingList?.attributes,
                        ranking_list: singleRankingList,
                    },
                }
            })
        )
        setPlayersRankingList(updatedRankingLists)
    }

    useEffect(() => {
        handlePlayerStatistics()
        handleLastMembership()
        handleLastMedicalCertificate()
        handlePlayersClub()
        handlePlayersHistory()
        handlePlayersClubLoan()
        getCoachProfile()
        handlePlayersRankingLists()
        user?.role?.type === 'admin' && fetchPlayerSDocuments()
    }, [competitor])

    return (
        <>
            <Row className="d-flex user-profile">
                <Col className="px-0" xs={12} md>
                    <Card className="competitor-flow-profile-card-wrapper">
                        <Card.Body className="competitor-flow-profile-card-body details-card">
                            <Row className="pb-5">
                                <Col className="d-flex justify-content-between details-header-wrapper align-items-center">
                                    <h2>Podaci o takmičaru</h2>
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>
                                    <h3 className="mb-4">Konkurencija</h3>
                                    <Col className="ps-3 details-single-info">
                                        <p className="data-info">
                                            {calculateCategory(
                                                competitor?.attributes
                                                    ?.person_info?.birthdate
                                            )}
                                        </p>

                                        <p className="small-text title">
                                            Konkurencija
                                        </p>
                                    </Col>

                                    {calculateSubcategory(
                                        competitor?.attributes?.person_info
                                            ?.birthdate
                                    ) ? (
                                        <Col className="ps-3 details-single-info">
                                            <p className="data-info">
                                                {calculateSubcategory(
                                                    competitor?.attributes
                                                        ?.person_info?.birthdate
                                                )}
                                            </p>

                                            <p className="small-text title">
                                                Podkategorija konkurencije
                                            </p>
                                        </Col>
                                    ) : null}

                                    {/* {user?.role?.type === 'admin' && (
                                        <Row>
                                            <Col>
                                                {calculateSubcategory(
                                                    competitor?.attributes
                                                        ?.person_info?.birthdate
                                                ) === 'Crveni' && (
                                                    <Checkbox
                                                        className="mt-3"
                                                        label="Dozvoljeno takmičenje u konkurenciji narandžasti"
                                                        checked={
                                                            competitor
                                                                ?.attributes
                                                                ?.is_orange_allowed
                                                        }
                                                        onChange={() => {
                                                            handleChangeOrangeAllowed(
                                                                competitor
                                                                    ?.attributes
                                                                    ?.is_orange_allowed !==
                                                                    null
                                                                    ? !competitor
                                                                          ?.attributes
                                                                          ?.is_orange_allowed
                                                                    : true
                                                            )
                                                        }}
                                                    />
                                                )}
                                                {(calculateSubcategory(
                                                    competitor?.attributes
                                                        ?.person_info?.birthdate
                                                ) === 'Crveni' ||
                                                    calculateSubcategory(
                                                        competitor?.attributes
                                                            ?.person_info
                                                            ?.birthdate
                                                    ) === 'Narandžasti') && (
                                                    <Checkbox
                                                        className="mt-3"
                                                        label="Dozvoljeno takmičenje u konkurenciji zeleni"
                                                        checked={
                                                            competitor
                                                                ?.attributes
                                                                ?.is_green_allowed
                                                        }
                                                        onChange={() => {
                                                            handleChangeGreenAllowed(
                                                                competitor
                                                                    ?.attributes
                                                                    ?.is_green_allowed !==
                                                                    null
                                                                    ? !competitor
                                                                          ?.attributes
                                                                          ?.is_green_allowed
                                                                    : true
                                                            )
                                                        }}
                                                    />
                                                )}{' '}
                                            </Col>
                                        </Row>
                                    )} */}
                                </Col>
                            </Row>
                            <Row className="pb-4">
                                <Col className="d-flex justify-content-between details-header-wrapper align-items-center">
                                    <h3>Osnovni podaci</h3>
                                </Col>
                            </Row>
                            <Row className="pb-4">
                                <Col>
                                    {userBasicInfo.map(
                                        ({ label, name }, index) =>
                                            name && (
                                                <Col
                                                    className="ps-3 details-single-info"
                                                    key={`${name}-${index}`}
                                                >
                                                    <p className="data-info">
                                                        {name}
                                                    </p>

                                                    <p className="small-text title">
                                                        {label}
                                                    </p>
                                                </Col>
                                            )
                                    )}
                                </Col>
                            </Row>
                            {(loggedInUser?.data?.id === competitor?.id &&
                                loggedInUser?.role?.type === 'competitor') ||
                                (loggedInUser?.role?.type !== 'competitor' && (
                                    <>
                                        <Row className="pb-4">
                                            <Col className="d-flex justify-content-between details-header-wrapper align-items-center">
                                                <h3>Detalji</h3>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {userDetails.map(
                                                    ({ label, name }, index) =>
                                                        name && (
                                                            <Col
                                                                className="ps-3 details-single-info"
                                                                key={`${name}-${index}`}
                                                            >
                                                                <p className="data-info">
                                                                    {name}
                                                                </p>

                                                                <p className="small-text title">
                                                                    {label}
                                                                </p>
                                                            </Col>
                                                        )
                                                )}
                                            </Col>
                                        </Row>
                                    </>
                                ))}
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="px-0" xs={12} md>
                    {/* Commented temporarily */}
                    <Card className="competitor-flow-profile-card-wrapper mb-2 mb-md-4">
                        <Card.Body className="competitor-flow-profile-card-body">
                            <Row className="pb-4">
                                <Col>
                                    <h2>Pozicija na rang listama</h2>
                                </Col>
                            </Row>

                            {playersRankingList?.length > 0 ||
                            competitor?.attributes?.eta_14_rank ||
                            competitor?.attributes?.eta_16_rank ||
                            competitor?.attributes?.atp_wta_rank ||
                            competitor?.attributes?.itf_rank ? (
                                <>
                                    {playersRankingList?.map(
                                        (rankingList, index) => (
                                            <Row key={index} className="mb-2">
                                                <Col>
                                                    <span className="d-flex align-items-center">
                                                        <p className="me-2">
                                                            {
                                                                rankingList
                                                                    ?.attributes
                                                                    ?.ranking_list
                                                                    ?.attributes
                                                                    ?.name
                                                            }
                                                            :{' '}
                                                        </p>
                                                        <h5 className="card-title-color">
                                                            {rankingList
                                                                ?.attributes
                                                                ?.ranking_number ||
                                                                'Nema rang'}
                                                        </h5>
                                                    </span>
                                                </Col>
                                            </Row>
                                        )
                                    )}

                                    {competitor?.attributes?.eta_14_rank && (
                                        <Row className="mb-2">
                                            <Col>
                                                <span className="d-flex align-items-center">
                                                    <p className="me-2">
                                                        ETA 14:{' '}
                                                    </p>
                                                    <h5 className="card-title-color">
                                                        {competitor?.attributes
                                                            ?.eta_14_rank ||
                                                            'Nema rang'}
                                                    </h5>
                                                </span>
                                            </Col>
                                        </Row>
                                    )}

                                    {competitor?.attributes?.eta_16_rank && (
                                        <Row className="mb-2">
                                            <Col>
                                                <span className="d-flex align-items-center">
                                                    <p className="me-2">
                                                        ETA 16:{' '}
                                                    </p>
                                                    <h5 className="card-title-color">
                                                        {competitor?.attributes
                                                            ?.eta_16_rank ||
                                                            'Nema rang'}
                                                    </h5>
                                                </span>
                                            </Col>
                                        </Row>
                                    )}
                                    {competitor?.attributes?.atp_wta_rank && (
                                        <Row className="mb-2">
                                            <Col>
                                                <span className="d-flex align-items-center">
                                                    <p className="me-2">
                                                        ATP/WTA:{' '}
                                                    </p>
                                                    <h5 className="card-title-color">
                                                        {competitor?.attributes
                                                            ?.atp_wta_rank ||
                                                            'Nema rang'}
                                                    </h5>
                                                </span>
                                            </Col>
                                        </Row>
                                    )}
                                    {competitor?.attributes?.itf_rank && (
                                        <Row className="mb-2">
                                            <Col>
                                                <span className="d-flex align-items-center">
                                                    <p className="me-2">
                                                        ITF:{' '}
                                                    </p>
                                                    <h5 className="card-title-color">
                                                        {competitor?.attributes
                                                            ?.itf_rank ||
                                                            'Nema rang'}
                                                    </h5>
                                                </span>
                                            </Col>
                                        </Row>
                                    )}
                                </>
                            ) : (
                                <p>Takmičar nije rangiran</p>
                            )}
                        </Card.Body>
                    </Card>

                    <Card className="competitor-flow-profile-card-wrapper">
                        <Card.Body className="competitor-flow-profile-card-body">
                            <Row className="pb-5">
                                <Col>
                                    <h2>Skor takmičara</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="d-flex flex-column competition">
                                    <Col className="d-flex flex-column">
                                        <Col className="position-relative align-self-center">
                                            <PieChart width={292} height={292}>
                                                <Pie
                                                    data={
                                                        competitorStatistics?.result
                                                    }
                                                    innerRadius={131}
                                                    outerRadius={146}
                                                    paddingAngle={2}
                                                    dataKey="value"
                                                    startAngle={-340}
                                                >
                                                    {competitorStatistics?.result.map(
                                                        (entry, index) => (
                                                            <Cell
                                                                key={`cell-${index}`}
                                                                fill={
                                                                    COLORS[
                                                                        index %
                                                                            COLORS.length
                                                                    ]
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </Pie>
                                            </PieChart>
                                            <div className="total-matches">
                                                <p>Ukupno mečeva</p>
                                                <h1>
                                                    {competitorStatistics?.total_matches ||
                                                        '0'}
                                                </h1>
                                            </div>
                                        </Col>
                                        <Col className="matches">
                                            {competitorStatistics?.result?.map(
                                                ({ name }) => (
                                                    <div
                                                        className="match d-flex"
                                                        key={name}
                                                    >
                                                        <div
                                                            className={
                                                                name.includes(
                                                                    'Pobeda'
                                                                )
                                                                    ? 'green'
                                                                    : 'red'
                                                            }
                                                        ></div>
                                                        <p>{name}</p>
                                                    </div>
                                                )
                                            )}
                                        </Col>
                                    </Col>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    {user?.role?.type === 'admin' ? (
                        <Card className="competitor-flow-profile-card-wrapper mt-2 mt-md-4">
                            <Card.Body className="competitor-flow-profile-card-body">
                                <Row className="pb-5">
                                    <Col>
                                        <h2>Dokumenta takmičara</h2>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        {playersDocuments?.length > 0 ? (
                                            playersDocuments?.map(
                                                (document, index) => (
                                                    <FileUploadTag
                                                        key={index}
                                                        fileName={
                                                            document?.file?.data
                                                                ?.attributes
                                                                ?.name
                                                        }
                                                        downloadFile={(e) =>
                                                            downloadImage(
                                                                formatImgUrl(
                                                                    document
                                                                        ?.file
                                                                        ?.data
                                                                        ?.attributes
                                                                        ?.url
                                                                ),
                                                                `${document?.file?.data?.attributes?.hash}`,
                                                                `${document?.file?.data?.attributes?.ext}`
                                                            )
                                                        }
                                                    />
                                                )
                                            )
                                        ) : (
                                            <h4 className="text-danger text-center">
                                                Nema dokumenata!
                                            </h4>
                                        )}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    ) : null}
                </Col>
                <Col className="px-0" xs={12} md>
                    <Card className="competitor-flow-profile-card-wrapper mb-2 mb-md-4">
                        <Card.Body className="competitor-flow-profile-card-body">
                            <Row className="pb-5">
                                <Col>
                                    <h2>Klub</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    xs={12}
                                    className="club d-flex flex-column align-items-center text-center"
                                >
                                    {playersClub?.id ? (
                                        <>
                                            <img
                                                className="mb-3 club-image"
                                                src={
                                                    playersClub?.attributes
                                                        ?.logo?.data
                                                        ? formatImgUrl(
                                                              playersClub
                                                                  ?.attributes
                                                                  ?.logo?.data
                                                                  ?.attributes
                                                                  ?.url
                                                          )
                                                        : generateAvatar(
                                                              playersClub
                                                                  ?.attributes
                                                                  ?.name
                                                          )
                                                }
                                                alt={`Logo kluba`}
                                            />
                                            <Row>
                                                <Col>
                                                    <h3 className="mb-2">
                                                        {
                                                            playersClub
                                                                ?.attributes
                                                                ?.name
                                                        }
                                                    </h3>
                                                    <p className="link">
                                                        {
                                                            playersClub
                                                                ?.attributes
                                                                ?.website_url
                                                        }
                                                    </p>
                                                </Col>
                                            </Row>
                                        </>
                                    ) : (
                                        <p>Nema klub</p>
                                    )}
                                </Col>

                                <Col xs={12} className="my-2">
                                    {new Date(
                                        playersClubLoan?.attributes?.departure_date
                                    ) >= new Date() && (
                                        <p>
                                            <span className="muted-text">
                                                Pozajmljen u{' '}
                                            </span>

                                            <span className="link">
                                                <Link
                                                    to={`/klubovi/${playersClubLoan?.club?.attributes?.uuid}`}
                                                    tabIndex="1"
                                                >
                                                    {
                                                        playersClubLoan?.club
                                                            ?.attributes?.name
                                                    }
                                                </Link>
                                                .{' '}
                                            </span>
                                            <span className="muted-text">
                                                {`Od ${formatDate(
                                                    playersClubLoan?.attributes
                                                        ?.joining_date
                                                )}`}{' '}
                                                do{' '}
                                                {formatDate(
                                                    playersClubLoan?.attributes
                                                        ?.departure_date
                                                )}
                                            </span>
                                        </p>
                                    )}
                                </Col>
                                <Col xs={12}>
                                    {(loggedInUser?.data?.id ===
                                        competitor?.id &&
                                        loggedInUser?.role?.type ===
                                            'competitor') ||
                                        (loggedInUser?.role?.type !==
                                            'competitor' && (
                                            <Row>
                                                <Col className="mt-4 d-flex justify-content-center">
                                                    <Link
                                                        to={
                                                            playersClub?.id
                                                                ? `/ispisi-igraca/${competitor?.attributes?.uuid}`
                                                                : `/promena-kluba-igraca/${competitor?.attributes?.uuid}`
                                                        }
                                                        tabIndex="1"
                                                    >
                                                        <Button type="secondary">
                                                            {playersClub?.id
                                                                ? 'Ispiši igrača'
                                                                : 'Upiši igrača'}
                                                        </Button>
                                                    </Link>

                                                    {playersClub?.id && (
                                                        <Link
                                                            to={`/pozajmi-igraca/${competitor?.attributes?.uuid}`}
                                                            tabIndex="1"
                                                        >
                                                            <Button
                                                                type="secondary"
                                                                className="ms-2"
                                                                disabled={
                                                                    new Date(
                                                                        playersClubLoan?.attributes?.departure_date
                                                                    ) >=
                                                                    new Date()
                                                                }
                                                            >
                                                                Pozajmi igrača
                                                            </Button>
                                                        </Link>
                                                    )}
                                                </Col>
                                            </Row>
                                        ))}
                                    <Row className="mb-3">
                                        <Col>
                                            <h2 className="mt-5">
                                                Prethodni klubovi
                                            </h2>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            {playersClubHistory?.length > 0 ? (
                                                playersClubHistory?.map(
                                                    (clubData, index) => (
                                                        <p
                                                            key={index}
                                                            className="mb-2"
                                                        >
                                                            <span className="link">
                                                                <Link
                                                                    to={`/klubovi/${clubData?.club?.attributes?.uuid}`}
                                                                    tabIndex="1"
                                                                >
                                                                    {
                                                                        clubData
                                                                            ?.club
                                                                            ?.attributes
                                                                            ?.name
                                                                    }
                                                                </Link>
                                                            </span>
                                                            <span className="muted-text">
                                                                : Od{' '}
                                                                {formatDate(
                                                                    clubData
                                                                        ?.attributes
                                                                        ?.joining_date
                                                                )}
                                                            </span>
                                                            {clubData
                                                                ?.attributes
                                                                ?.departure_date && (
                                                                <span className="muted-text">
                                                                    {' '}
                                                                    do{' '}
                                                                    {formatDate(
                                                                        clubData
                                                                            ?.attributes
                                                                            ?.departure_date
                                                                    )}
                                                                </span>
                                                            )}

                                                            {clubData
                                                                ?.attributes
                                                                ?.is_loan && (
                                                                <span>
                                                                    {' '}
                                                                    (Pozajmica)
                                                                </span>
                                                            )}
                                                        </p>
                                                    )
                                                )
                                            ) : (
                                                <p>Nema prethodnih klubova</p>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer className="competitor-flow-profile-card-footer">
                            <Row>
                                <Col className="d-flex align-items-center justify-content-end">
                                    <Button
                                        type="text"
                                        endIcon={<ArrowRight />}
                                        onClick={() => setShowClubHistory(true)}
                                    >
                                        Detaljnije
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card>
                    {(loggedInUser?.data?.id === competitor?.id &&
                        loggedInUser?.role?.type === 'competitor') ||
                        (loggedInUser?.role?.type !== 'competitor' && (
                            <>
                                <Card className="competitor-flow-profile-card-wrapper mb-2 mb-md-4">
                                    <Card.Body className="competitor-flow-profile-card-body membership-card">
                                        <Row className="pb-5">
                                            <Col className="d-flex align-items-center">
                                                {differenceInDays !== null &&
                                                differenceInDays <= 15 ? (
                                                    <h2 className="d-flex align-center">
                                                        Lekarski pregled{' '}
                                                        <span className="text-danger ms-2">
                                                            !
                                                        </span>
                                                    </h2>
                                                ) : (
                                                    <h2>Lekarski pregled</h2>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="membership-view">
                                                <Col
                                                    className={`d-flex align-items-center justify-content-center single-membership`}
                                                >
                                                    <p
                                                        className={`membership-year`}
                                                    >
                                                        {lastMedicalCertificate
                                                            ? `${formatDate(
                                                                  lastMedicalCertificate
                                                                      ?.attributes
                                                                      ?.start_date
                                                              )} - ${formatDate(
                                                                  lastMedicalCertificate
                                                                      ?.attributes
                                                                      ?.end_date
                                                              )}`
                                                            : 'Nema lekarsko uverenje.'}
                                                    </p>
                                                </Col>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                    <Card.Footer className="competitor-flow-profile-card-footer">
                                        <Row>
                                            <Col className="d-flex align-items-center justify-content-end">
                                                <Button
                                                    type="text"
                                                    endIcon={<ArrowRight />}
                                                    onClick={() =>
                                                        setShowMedicalHistory(
                                                            true
                                                        )
                                                    }
                                                >
                                                    Detaljnije
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card.Footer>
                                </Card>

                                <Card className="competitor-flow-profile-card-wrapper">
                                    <Card.Body className="competitor-flow-profile-card-body membership-card">
                                        <Row className="pb-5">
                                            <Col className="d-flex align-items-center">
                                                <h2>Članarina</h2>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col className="membership-view">
                                                <Col
                                                    className={`d-flex align-items-center justify-content-center single-membership`}
                                                >
                                                    <Col className="d-flex justify-content-center">
                                                        <p
                                                            className={`membership-year`}
                                                        >
                                                            {lastMembership
                                                                ? formatDate(
                                                                      lastMembership
                                                                          ?.attributes
                                                                          ?.date_of_payment
                                                                  )
                                                                : 'Nema članarina.'}
                                                        </p>
                                                    </Col>
                                                </Col>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                    <Card.Footer className="competitor-flow-profile-card-footer">
                                        <Row>
                                            <Col className="d-flex align-items-center justify-content-end">
                                                <Button
                                                    type="text"
                                                    endIcon={<ArrowRight />}
                                                    onClick={() =>
                                                        setShowMembership(true)
                                                    }
                                                >
                                                    Detaljnije
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card.Footer>
                                </Card>
                            </>
                        ))}
                </Col>
            </Row>
        </>
    )
}

export default ProfileTab
