import React from 'react'

function CloseOutlined(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="18"
            fill="none"
            viewBox="0 0 17 18"
            {...props}
        >
            <path
                fill={props.fill ? props.fill : '#2C3136'}
                fillRule="evenodd"
                d="M.472.972a1.042 1.042 0 011.473 0L8.5 7.527 15.055.972a1.042 1.042 0 111.473 1.473L9.973 9l6.555 6.555a1.042 1.042 0 01-1.473 1.474L8.5 10.473 1.945 17.03a1.042 1.042 0 11-1.473-1.474L7.027 9 .472 2.445a1.042 1.042 0 010-1.473z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}

export default CloseOutlined
