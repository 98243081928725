import axios from 'axios'
import { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import logoTss from '../../assets/images/logo-longer.png'
import Eye from '../../assets/svg/Eye'
import EyeOff from '../../assets/svg/EyeOff'
import Button from '../../components/Button'
import TextField from '../../components/TextField'
import { confirmInvitationEmail, getUserInfo } from '../../lib/api'
import { useAppContext } from '../../lib/contextLib'
import {
    customToastHandler,
    saveDataInSession,
    toastHandler,
} from '../../lib/helpers'
import './styles.scss'

const Login = () => {
    //* Invitation code part
    const queryParams = new URLSearchParams(window.location.search)
    const invitation_code = queryParams.get('invitation_code')

    const { setAuthenticated, setUser, setShowCustomToast, showCustomToast } =
        useAppContext()
    const navigate = useNavigate()
    const [visibility, setVisibility] = useState(false)
    const [formData, setFormData] = useState({
        identifier: '',
        password: '',
    })
    const [showToast, setShowToast] = useState(false)
    const [error, setError] = useState(null)
    const [pageInitialized, setPageInitialized] = useState(false)

    const handleLogin = async () => {
        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_BASE_URL}api/auth/local`,
                {
                    ...formData,
                }
            )

            if (data) {
                error && setError(null)
                saveDataInSession('tssUserJwt', data?.jwt)
                setAuthenticated(true)
                getUserInfo(data?.user, navigate, setUser)
            }
        } catch (error) {
            setError(error)
            setShowToast(true)
        }
    }

    const handleToast = () => {
        return toastHandler({ error, showToast, setShowToast })
    }

    const togglePassword = (id) => {
        let element = document.getElementById(id)
        if (element.type === 'password') {
            element.type = 'text'
            setVisibility(true)
        } else if (element.type === 'text') {
            element.type = 'password'
            setVisibility(false)
        }
    }

    const handleEnter = (e) => {
        e.preventDefault()
        if (e.key === 'Enter') {
            handleLogin()
        }
    }

    const handleInvitationEmailConfirm = async (invitationCode) => {
        try {
            const data = await confirmInvitationEmail(invitationCode)

            if (data) {
                setShowCustomToast({
                    show: true,
                    type: 'success',
                    message:
                        'Uspešno ste potvrdili Vaš email. Sada se možete ulogovati sa Vašim kredencijalima.',
                })
            }
        } catch (error) {
            switch (error.response.data.error.message) {
                case 'Invalid token':
                    setShowCustomToast({
                        show: true,
                        type: 'error',
                        message: 'Nije pronađen korisnik sa datim kodom.',
                    })
                    break
                default:
                    setShowCustomToast({
                        show: true,
                        type: 'error',
                        message: 'Došlo je do greške.',
                    })
                    break
            }
        }
    }

    useEffect(() => {
        if (pageInitialized) {
            if (invitation_code) {
                handleInvitationEmailConfirm(invitation_code)
            }
        } else {
            setPageInitialized(true)
        }
    }, [invitation_code, pageInitialized])

    const handleCustomToast = () => {
        return customToastHandler(showCustomToast, setShowCustomToast)
    }

    return (
        <>
            {showCustomToast && handleCustomToast()}
            {showToast && handleToast()}
            <Row className="mt-5 login-wrapper">
                <Col md={4}></Col>
                <Col md={4}>
                    <Row>
                        <Col
                            md={12}
                            className="d-flex align-items-center justify-content-center"
                        >
                            <img
                                src={logoTss}
                                alt="logo-tss"
                                className="tss-logo"
                            />
                        </Col>
                        <Col
                            md={12}
                            className="d-flex header align-items-center justify-content-center text-center"
                        >
                            <h1>Prijavi se</h1>
                        </Col>
                        <Col
                            md={12}
                            className="mt-2 mb-2 d-flex align-items-center justify-content-center text-center"
                        >
                            <p className="small-text">Dobrodošli na TSS!</p>
                        </Col>
                        <Col md={12} className="mt-4">
                            <TextField
                                id="1"
                                name="username"
                                type="text"
                                value={formData.identifier}
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        identifier: e.target.value,
                                    })
                                }
                                required
                                label="Korisničko ime"
                                placeholder="Unesite korisničko ime"
                            />
                        </Col>
                        <Col md={12} className="mt-3">
                            <TextField
                                id="account-password"
                                name="password"
                                type="password"
                                required
                                label="Lozinka"
                                placeholder="Unesite lozinku"
                                value={formData.password}
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        password: e.target.value,
                                    })
                                }
                                endIcon={visibility ? <EyeOff /> : <Eye />}
                                onIconClick={() =>
                                    togglePassword('account-password')
                                }
                                onKeyUp={handleEnter}
                            />
                        </Col>
                        <Col md={12} className="mt-3">
                            <Link to="/forgot-password" className="forgot-pass">
                                <p className="small-text">
                                    Zaboravljena / Promena lozinke?
                                </p>
                            </Link>
                        </Col>

                        <Col className="mt-4 mb-5 d-flex align-items-center justify-content-end">
                            <Button onClick={handleLogin}>Prijavi se</Button>
                        </Col>
                        <Col
                            md={12}
                            className="logo-below mt-5 pt-5 d-flex align-items-center justify-content-center"
                        >
                            <p className="chainapp-text">
                                Razvija:{' '}
                                <a
                                    href="https://chainapp.tech/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Chainapp Technologies doo
                                </a>
                            </p>
                        </Col>
                    </Row>
                </Col>
                <Col md={4}></Col>
            </Row>
        </>
    )
}

export default Login
