import { Col, Row } from 'react-bootstrap'
import { addPhoto, checkFileSize } from '../../../lib/helpers'
import TextField from '../../TextField'
import UploadImage from '../../UploadImage'

const StepOne = ({
    newClub,
    updateClub,
    setClubImg,
    clubPreviewImg,
    setClubPreviewImg,
}) => {
    return (
        <Row className="step-one-wrapper">
            <form>
                <Col md={12} className="d-flex justify-content-center">
                    <UploadImage
                        htmlFor="clubImage"
                        previewData={clubPreviewImg}
                        imageKey="clubImage"
                        textBelow={'Unesi logo kluba /.jpg 2MB'}
                    />

                    <input
                        type="file"
                        id="clubImage"
                        name="clubImage"
                        className="d-none invisible"
                        onChange={(e) =>
                            addPhoto(
                                e,
                                setClubImg,
                                setClubPreviewImg,
                                checkFileSize
                            )
                        }
                        accept="image/jpg, image/jpeg, image/png"
                    />
                </Col>
                <Col md={12} className="mt-3">
                    <TextField
                        id="name"
                        name="name"
                        label="Pun naziv kluba"
                        placeholder="Pun naziv kluba"
                        onChange={updateClub}
                        value={newClub?.name}
                        required
                    />
                </Col>
                <Col md={12} className="mt-3">
                    <TextField
                        id="short_name"
                        name="short_name"
                        label="Skraćeni naziv kluba"
                        placeholder="Maksimalno 3 slova"
                        onInput={(e) =>
                            (e.target.value = e.target.value.toUpperCase())
                        }
                        onChange={updateClub}
                        value={newClub?.short_name}
                        required
                        maxLength={3}
                    />
                </Col>
            </form>
        </Row>
    )
}

export default StepOne
