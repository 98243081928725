import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import AsyncSelect from '../../../../../../components/AsyncSelect'
import Button from '../../../../../../components/Button'
import Dropdown from '../../../../../../components/Dropdown'
import { addRefereeOnTournament, getCodebook } from '../../../../../../lib/api'
import { useAppContext } from '../../../../../../lib/contextLib'
import { getRefereesList } from '../../../../../../lib/helpers'

function AddTournamentRefereeModal({
    show,
    onHide,
    tournament,
    refreshData,
    referees,
}) {
    const { setShowCustomToast } = useAppContext()

    //* Codebooks
    const [refereeTypes, setRefereeTypes] = useState([])

    const handleCodebooks = async () => {
        await getCodebook('codebooks.referee-type').then((result) => {
            setRefereeTypes(
                result.reduce((accumulator, currentValue) => {
                    if (
                        ![
                            'Administrator suđenja',
                            'Voditelj turnira',
                            'Vrhovni sudija',
                        ].includes(currentValue)
                    ) {
                        return [...accumulator, currentValue]
                    }
                    return accumulator
                }, [])
            )
        })
    }

    useEffect(() => {
        handleCodebooks()
    }, [])

    const [selectedReferee, setSelectedReferee] = useState(null)
    const [selectedRefereeType, setSelectedRefereeType] = useState('')

    const handleModalClose = () => {
        setSelectedReferee(null)
        setSelectedRefereeType('')
        onHide()
    }

    const handleAddNewTournamentReferee = async () => {
        try {
            const newReferee = {
                tournament_uuid: tournament?.attributes?.uuid,
                referee_uuid: selectedReferee?.uuid,
                referee_type: {
                    referee_type_name: selectedRefereeType,
                },
            }

            const addedReferee = await addRefereeOnTournament(newReferee)

            if (addedReferee) {
                setShowCustomToast({
                    show: true,
                    type: 'success',
                    message: 'Sudija uspešno dodat.',
                })
                handleModalClose()
                refreshData()
            }
        } catch (error) {
            setShowCustomToast({
                show: true,
                type: 'error',
                message: 'Došlo je do greške.',
            })
        }
    }

    const loadReferees = async (inputValue, loadedOptions, paginationData) => {
        return getRefereesList(inputValue, loadedOptions, paginationData, {
            alreadySelectedReferees: referees?.reduce((accumulator, entry) => {
                let tempList = []

                if (entry?.attributes?.referee_uuid) {
                    tempList.push(entry?.attributes?.referee_uuid)
                }

                return [...accumulator, ...tempList]
            }, []),
        })
    }

    return (
        <Modal
            centered
            show={show}
            onHide={() => handleModalClose()}
            dialogClassName="add-tournament-referees-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>Dodavanje sudije</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AsyncSelect
                    loadOptions={loadReferees}
                    onChange={(e) => setSelectedReferee(e)}
                    label={'Izaberi sudiju'}
                    placeholder={'Pretraži i izaberi sudiju...'}
                    noOptionsMessage={'Traženi sudija ne postoji'}
                    closeMenuOnSelect={true}
                    isMulti={false}
                    value={selectedReferee}
                    required
                    className={'mb-3'}
                />
                <Dropdown
                    required
                    label={'Tip sudije'}
                    placeholder="Izaberi tip sudije..."
                    dropdownLinks={refereeTypes}
                    handleSelect={(e) => setSelectedRefereeType(e)}
                    value={selectedRefereeType}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button type="text" onClick={() => handleModalClose()}>
                    Zatvori
                </Button>
                <Button
                    onClick={() => handleAddNewTournamentReferee()}
                    disabled={
                        selectedReferee && selectedRefereeType ? false : true
                    }
                >
                    Dodaj sudiju
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddTournamentRefereeModal
