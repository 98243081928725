import React, { useEffect, useState } from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import CardParticipantSection from '../TournamentMatchCard/cardParticipant'
import './styles.scss'
import Button from '../Button'
import Trash from '../../assets/svg/Trash'
import { deleteGroupParticipant, updateParticipantsGroup } from '../../lib/api'
import NotificationModal from './notificationModal'
import { calculateGroupParticipantRank } from '../../containers/Admin/Competitions/CompetitionProfile/Tabs/Draw/RoundRobin/roundRobinGroupRankCalculations'
import TextField from '../TextField'
import { useAppContext } from '../../lib/contextLib'
const GroupParticipantCard = ({
    index,
    participantData,
    className,
    handleGetGroupParticipants,
    groupMatches,
    isManualRankingEnabled,
}) => {
    const { user, setIsLoading } = useAppContext()
    const [participantStatistics, setParticipantStatistics] = useState({})
    const [showNotificationModal, setShowNotificationModal] = useState(false)
    const [participantManualRank, setParticipantManualRank] = useState(0)

    const handleRemoveGroupParticipant = async () => {
        try {
            if (groupMatches?.length > 0) {
                setShowNotificationModal(true)
            } else {
                const deletedParticipant = await deleteGroupParticipant(
                    participantData?.uuid
                )
                if (!deletedParticipant) return

                handleGetGroupParticipants()
            }
        } catch (error) {
            throw new Error(error)
        }
    }

    const handleGetParticipantStatistics = async () => {
        const statistics = await calculateGroupParticipantRank(
            groupMatches,
            participantData?.participant_uuid
        )
        setParticipantStatistics(statistics)
    }

    const handleUpdateParticipantsGroup = async () => {
        try {
            setIsLoading(true)
            const updatedParticipant = await updateParticipantsGroup(
                participantData?.uuid,
                {
                    group_manual_rank: +participantManualRank,
                }
            )
            if (!updatedParticipant) return
            setParticipantManualRank(0)
            handleGetGroupParticipants()
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        handleGetParticipantStatistics()
    }, [participantData, groupMatches])
    return (
        <Card className={`${className} group-participant-card`}>
            <Card.Body>
                <Row>
                    <Col xs={12} md={4} className="d-flex align-items-center">
                        <Row className="mx-2">
                            <Col className="group-position-col py-2">
                                <p className="mb-1">{index + 1}.</p>
                                <p className="small-text">Pozicija</p>
                            </Col>
                        </Row>
                        <CardParticipantSection
                            participantData={participantData}
                            className={'round-robin-card'}
                        />
                    </Col>
                    <Col>
                        <Row className="gy-2">
                            <Col className="group-statistic-col">
                                <p className="mb-1">
                                    {participantStatistics?.winsNumber || 0}
                                </p>
                                <p className="small-text">Pobede</p>
                            </Col>
                            <Col className="group-statistic-col">
                                <p className="mb-1">
                                    {participantStatistics?.setRatio || '0.00'}
                                </p>
                                <p className="small-text">Set količnik</p>
                            </Col>
                            <Col className="group-statistic-col">
                                <p className="mb-1">
                                    {participantStatistics?.setsWonNumber || 0}
                                </p>
                                <p className="small-text">Osvojeni setovi</p>
                            </Col>
                            <Col className="group-statistic-col">
                                <p className="mb-1">
                                    {participantStatistics?.gameRatio || '0.00'}
                                </p>
                                <p className="small-text">Gem količnik</p>
                            </Col>
                            <Col className="group-statistic-col">
                                <p className="mb-1">
                                    {participantStatistics?.gamesWonNumber || 0}
                                </p>
                                <p className="small-text">Osvojeni gemovi</p>
                            </Col>

                            {user?.role?.type !== 'competitor' && (
                                <Col className="group-statistic-col d-flex justify-content-end">
                                    {isManualRankingEnabled ? (
                                        <div className="d-flex align-items-center">
                                            <TextField
                                                type="number"
                                                value={
                                                    participantManualRank ||
                                                    participantData?.group_manual_rank
                                                }
                                                onChange={(e) => {
                                                    e.target.value <= 4 &&
                                                        e.target.value >= 1 &&
                                                        setParticipantManualRank(
                                                            +e.target.value
                                                        )
                                                }}
                                                style={{ width: '50px' }}
                                            />

                                            <Button
                                                onClick={
                                                    handleUpdateParticipantsGroup
                                                }
                                                type="secondary"
                                                className="ms-2"
                                                disabled={
                                                    !participantManualRank
                                                }
                                            >
                                                Sačuvaj
                                            </Button>
                                        </div>
                                    ) : (
                                        <Button
                                            type="text"
                                            onClick={() =>
                                                handleRemoveGroupParticipant()
                                            }
                                        >
                                            <Trash />
                                        </Button>
                                    )}
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
            </Card.Body>

            <NotificationModal
                show={showNotificationModal}
                onHide={() => setShowNotificationModal(false)}
            />
        </Card>
    )
}

export default GroupParticipantCard
