import * as React from 'react'

const Trash = (props) => (
    <svg
        width={18}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.648 1.455a.354.354 0 0 1 .136-.026H10.359a.355.355 0 0 1 .355.355v1.073H6.43V1.784a.354.354 0 0 1 .219-.329Zm-.937 2.83h8.529l-.846 13.529c-.03.493-.348.757-.715.757H4.464c-.371 0-.69-.27-.715-.75v-.008L2.903 4.286h2.808ZM5 2.858v-1.07A1.782 1.782 0 0 1 6.787 0h3.57a1.782 1.782 0 0 1 1.786 1.787v1.07h4.286a.714.714 0 0 1 0 1.429h-.758l-.85 13.615C14.748 19.058 13.888 20 12.678 20H4.464c-1.198 0-2.08-.934-2.141-2.102L1.472 4.286H.714a.714.714 0 0 1 0-1.429H5Zm1.071 3.546a.714.714 0 0 0-1.428.051l.357 10a.714.714 0 0 0 1.428-.05l-.357-10Zm6.428.051a.714.714 0 1 0-1.427-.05l-.357 10a.714.714 0 0 0 1.427.05l.357-10ZM9.287 6.43a.714.714 0 0 0-1.429 0v10a.714.714 0 1 0 1.429 0v-10Z"
            fill="#2C3136"
        />
    </svg>
)

export default Trash
