import { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import TextField from '../../../../../components/TextField'
import { numbersOnly } from '../../../../../lib/helpers'

const StepFour = ({ form, setForm }) => {
    const [contactPerson, setContactPerson] = useState(
        form?.staff?.find((person) => person?.title === 'Kontakt osoba')
    )
    const [director, setDirector] = useState(
        form?.staff?.find((person) => person?.title === 'Direktor turnira')
    )

    useEffect(() => {
        setForm((prevState) => ({
            ...prevState,
            staff: [
                {
                    ...contactPerson,
                    title: 'Kontakt osoba',
                },
                {
                    ...director,
                    title: 'Direktor turnira',
                },
            ],
        }))
    }, [contactPerson, director])

    return (
        <Row className="create-new-competition-form-wrapper step-four">
            <Col md={12} className="mt-4">
                <h3 className="contact-section-title">Direktor turnira</h3>
            </Col>
            <Col md={12} className="mt-3">
                <TextField
                    id="director_given_name"
                    name="director_given_name"
                    type="text"
                    value={director?.given_name || ''}
                    onChange={(e) =>
                        setDirector((prevState) => ({
                            ...prevState,
                            given_name: e.target.value,
                        }))
                    }
                    placeholder="Unesite ime"
                    label="Ime"
                />
            </Col>
            <Col md={12} className="mt-3">
                <TextField
                    id="director_family_name"
                    name="director_family_name"
                    type="text"
                    value={director?.family_name || ''}
                    onChange={(e) =>
                        setDirector((prevState) => ({
                            ...prevState,
                            family_name: e.target.value,
                        }))
                    }
                    placeholder="Unesite prezime"
                    label="Prezime"
                />
            </Col>
            <Col md={12} className="mt-3">
                <TextField
                    id="director_phone"
                    name="director_phone"
                    type="tel"
                    value={director?.phone || ''}
                    onChange={(e) => {
                        numbersOnly(e)
                        setDirector((prevState) => ({
                            ...prevState,
                            phone: e.target.value,
                        }))
                    }}
                    placeholder="Unesite broj telefona"
                    label="Telefon"
                />
            </Col>
            <Col md={12} className="mt-3">
                <TextField
                    id="director_email"
                    name="director_email"
                    type="email"
                    value={director?.email || ''}
                    onChange={(e) => {
                        setDirector((prevState) => ({
                            ...prevState,
                            email: e.target.value,
                        }))
                    }}
                    placeholder="Unesite email"
                    label="Email"
                />
            </Col>

            <Col md={12} className="mt-4">
                <h3 className="contact-section-title">Kontakt osoba</h3>
            </Col>
            <Col md={12} className="mt-3">
                <TextField
                    id="contact_person_given_name"
                    name="contact_person_given_name"
                    type="text"
                    value={contactPerson?.given_name || ''}
                    onChange={(e) =>
                        setContactPerson((prevState) => ({
                            ...prevState,
                            given_name: e.target.value,
                        }))
                    }
                    placeholder="Unesite ime"
                    label="Ime"
                />
            </Col>
            <Col md={12} className="mt-3">
                <TextField
                    id="contact_person_family_name"
                    name="contact_person_family_name"
                    type="text"
                    value={contactPerson?.family_name || ''}
                    onChange={(e) =>
                        setContactPerson((prevState) => ({
                            ...prevState,
                            family_name: e.target.value,
                        }))
                    }
                    placeholder="Unesite prezime"
                    label="Prezime"
                />
            </Col>
            <Col md={12} className="mt-3">
                <TextField
                    id="contact_person_phone"
                    name="contact_person_phone"
                    type="tel"
                    value={contactPerson?.phone || ''}
                    onChange={(e) => {
                        numbersOnly(e)
                        setContactPerson((prevState) => ({
                            ...prevState,
                            phone: e.target.value,
                        }))
                    }}
                    placeholder="Unesite broj telefona"
                    label="Telefon"
                />
            </Col>
            <Col md={12} className="mt-3">
                <TextField
                    id="contact_person_email"
                    name="contact_person_email"
                    type="email"
                    value={contactPerson?.email || ''}
                    onChange={(e) =>
                        setContactPerson((prevState) => ({
                            ...prevState,
                            email: e.target.value,
                        }))
                    }
                    placeholder="Unesite email"
                    label="Email"
                />
            </Col>
        </Row>
    )
}

export default StepFour
