import React, { useState } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import Button from '../Button'
import AsyncSelect from '../AsyncSelect'
import { getTournamentPlayersParticipantsList } from '../../lib/helpers'
import {
    createTournamentMatch,
    updateMatchResult,
    getSingleMatch,
} from '../../lib/api'

const CreateMatchModal = ({
    show,
    onHide,
    matchDetails,
    handleGetSingleMatch = false,
    createNextMatch,
    handleShowSlider,
}) => {
    const [matchData, setMatchData] = useState()
    const statusToSearch = (drawType) => {
        if (!drawType) return []
        switch (drawType) {
            case 'Kvalifikacije':
                return ['QA', 'WCQ', 'ALT']
            case 'Glavni turnir':
                return ['DA', 'Q', 'WC', 'LL']
            default:
                return []
        }
    }

    const loadParticipants = async (
        inputValue,
        loadedOptions,
        paginationData
    ) => {
        return getTournamentPlayersParticipantsList(
            inputValue,
            loadedOptions,
            paginationData,
            {
                tournament_uuid: matchDetails?.tournament_uuid,
                is_double: matchDetails?.is_double,
                gender: matchDetails?.gender?.gender_name,
                round_number: matchDetails?.round_number,
                pair_number: matchDetails?.pair_number,
                selected_side_1: matchData?.side_1_uuid || null,
                selected_side_2: matchData?.side_2_uuid || null,
                subtournament_name: matchDetails?.subtournament_name
                    ?.tournament_subcategory_name
                    ? matchDetails?.subtournament_name
                          ?.tournament_subcategory_name
                    : null,
                draw_type_name: matchDetails?.draw_type?.draw_type_name,
                player_status: statusToSearch(
                    matchDetails?.draw_type?.draw_type_name
                ),
            }
        )
    }

    const handleSubmitMatch = async () => {
        let match
        if (matchDetails?.uuid) {
            match = await updateMatchResult(matchDetails?.uuid, {
                side_1_uuid:
                    matchDetails?.sideOneData?.participant_uuid ||
                    matchData?.side_1_uuid,
                side_2_uuid:
                    matchDetails?.sideTwoData?.participant_uuid ||
                    matchData?.side_2_uuid,
            })
        } else {
            if (
                matchData?.side_1_uuid === '-1' ||
                matchData?.side_2_uuid === '-1'
            ) {
                matchData.loser_participant_uuid = '-1'
            }
            match = await createTournamentMatch(matchData)
        }

        if (match) {
            if (
                createNextMatch &&
                (matchData?.side_1_uuid === '-1' ||
                    matchData?.side_2_uuid === '-1')
            ) {
                let participantObj = {}

                if (matchData?.side_1_uuid === '-1') {
                    if (matchData.pair_number % 2 === 0) {
                        participantObj.side_2_uuid = matchData?.side_2_uuid
                    } else {
                        participantObj.side_1_uuid = matchData?.side_2_uuid
                    }
                } else {
                    if (matchData.pair_number % 2 === 0) {
                        participantObj.side_2_uuid = matchData?.side_1_uuid
                    } else {
                        participantObj.side_1_uuid = matchData?.side_1_uuid
                    }
                }

                const {
                    side_1_uuid,
                    side_2_uuid,
                    loser_participant_uuid,
                    ...otherAttributes
                } = matchData

                const nextMatchData = await getSingleMatch({
                    ...matchDetails,
                    round_number: matchDetails?.round_number + 1,
                    pair_number: Math.floor(
                        (matchDetails?.pair_number + 1) / 2
                    ),
                })

                if (nextMatchData) {
                    const updatedMatch = await updateMatchResult(
                        nextMatchData?.attributes?.uuid,
                        {
                            ...participantObj,
                        }
                    )
                    if (updatedMatch) {
                        handleShowSlider()
                    }
                } else {
                    const nextMatch = await createTournamentMatch({
                        ...otherAttributes,
                        round_number: otherAttributes.round_number + 1,
                        pair_number: Math.floor(
                            (otherAttributes?.pair_number + 1) / 2
                        ),
                        ...participantObj,
                    })

                    if (nextMatch) {
                        handleShowSlider()
                    }
                }
            }
            setMatchData(null)
            onHide()
            handleGetSingleMatch && handleGetSingleMatch()
        }
    }

    return (
        <Modal
            size="lg"
            centered
            show={show}
            onHide={() => {
                setMatchData(null)
                onHide()
            }}
            dialogClassName="create-draw-pair-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>Dodaj par</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!matchDetails?.sideOneData?.participant_uuid && (
                    <Row>
                        <Col>
                            <AsyncSelect
                                loadOptions={loadParticipants}
                                onChange={(e) =>
                                    setMatchData((prev) => ({
                                        ...prev,
                                        side_1_uuid: e.uuid,
                                        ...matchDetails,
                                    }))
                                }
                                label={
                                    matchDetails?.is_double
                                        ? 'Par 1'
                                        : 'Takmičar 1'
                                }
                                placeholder={'Izaberi igrača ...'}
                                noOptionsMessage={'Nema prijavljenih igrača'}
                                closeMenuOnSelect={true}
                                isMulti={false}
                                cacheUniqs={[
                                    matchData?.side_1_uuid || null,
                                    matchData?.side_2_uuid || null,
                                ]}
                            />
                        </Col>
                    </Row>
                )}
                {!matchDetails?.sideTwoData?.participant_uuid && (
                    <Row className="mt-3">
                        <Col>
                            <AsyncSelect
                                loadOptions={loadParticipants}
                                onChange={(e) =>
                                    setMatchData((prev) => ({
                                        ...prev,
                                        side_2_uuid: e.uuid,
                                        ...matchDetails,
                                    }))
                                }
                                label={
                                    matchDetails?.is_double
                                        ? 'Par 2'
                                        : 'Takmičar 2'
                                }
                                placeholder={
                                    'Pretraži i izaberi igrača po imenu i prezimenu...'
                                }
                                noOptionsMessage={'Traženi igrač ne postoji'}
                                closeMenuOnSelect={true}
                                isMulti={false}
                                cacheUniqs={[
                                    matchData?.side_1_uuid || null,
                                    matchData?.side_2_uuid || null,
                                ]}
                            />
                        </Col>
                    </Row>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    type="text"
                    onClick={() => {
                        setMatchData(null)
                        onHide()
                    }}
                >
                    Odustani
                </Button>
                <Button onClick={() => handleSubmitMatch()}>Dodaj par</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CreateMatchModal
