import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import TextField from '../../../../../components/TextField'

import './styles.scss'

function Awards({ setProgress, formData, setFormData }) {
    useEffect(() => {
        setProgress(16.5)
    }, [])

    const setField = (e) => {
        const field = e.target.id
        const value = e.target.value
        setFormData((prevData) => ({
            ...prevData,
            awards: { ...prevData.awards, [field]: value },
        }))
    }

    return (
        <Row className="awards-wrapper">
            <Col>
                <TextField
                    label="Ukupan nagradni fond"
                    placeholder="Ukupan nagradni fond"
                    id="totalAwardFond"
                    onChange={setField}
                    className="mb-3"
                    type={'number'}
                    value={formData?.awards?.totalAwardFond}
                />
                <TextField
                    label="Nagrade (muškarci i žene)"
                    placeholder="Nagrade (muškarci i žene)"
                    id="awardsMenWomen"
                    onChange={setField}
                    className="mb-3"
                    type={'number'}
                    value={formData?.awards?.awardsMenWomen}
                />
                <TextField
                    label="Nagrade dubl"
                    placeholder="Nagrade dubl"
                    id="awardsDouble"
                    onChange={setField}
                    className="mb-3"
                    type={'number'}
                    value={formData?.awards?.awardsDouble}
                />
                <TextField
                    label="Kotizacija za kvalifikacije"
                    placeholder="Kotizacija za kvalifikacije"
                    id="registrationFeeQualifications"
                    onChange={setField}
                    className="mb-3"
                    type={'number'}
                    value={formData?.awards?.registrationFeeQualifications}
                />
                <TextField
                    label="Kotizacija za glavni turnir"
                    placeholder="Kotizacija za glavni turnir"
                    id="registrationFeeTournament"
                    onChange={setField}
                    type={'number'}
                    value={formData?.awards?.registrationFeeTournament}
                />
            </Col>
        </Row>
    )
}

export default Awards
