import * as React from 'react'
const MaleFemale = ({ className, ...props }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className={`ionicon ${className}`}
        viewBox="0 0 512 512"
        {...props}
    >
        <path d="M426 16h-74a22 22 0 0 0 0 44h20.89l-37.1 37.09A157.68 157.68 0 0 0 216 42c-87.12 0-158 70.88-158 158 0 79.66 59.26 145.72 136 156.46V394h-28a22 22 0 0 0 0 44h28v36a22 22 0 0 0 44 0v-36h28a22 22 0 0 0 0-44h-28v-37.54c76.74-10.74 136-76.8 136-156.46a157.15 157.15 0 0 0-14-64.92l44-44V112a22 22 0 0 0 44 0V38a22 22 0 0 0-22-22zM216 314a114 114 0 1 1 114-114 114.13 114.13 0 0 1-114 114z" />
    </svg>
)
export default MaleFemale
