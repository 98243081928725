import React from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import Button from '../../../../../../../components/Button'

const DeleteParticipantsFromGroupModal = ({
    show,
    onHide,
    handleDeleteAllGroupMatches,
}) => {
    return (
        <Modal
            centered
            show={show}
            onHide={onHide}
            dialogClassName="create-draw-pair-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>Brisanje mečeva grupe</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <p>
                            Da li ste sigurni da želite da obrišete sve mečeve
                            iz grupe?
                        </p>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button type="text" onClick={onHide}>
                    Odustani
                </Button>
                <Button onClick={() => handleDeleteAllGroupMatches()}>
                    Obriši
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteParticipantsFromGroupModal
