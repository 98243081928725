import React from 'react'
import { Dropdown } from 'react-bootstrap'
import './styles.scss'

export const RowsPerPageOptions = [10, 30, 50, 100]

function RowsPerPageDropdown({ onSelect, rowsPerPage, className }) {
    return (
        <Dropdown
            onSelect={(e) => {
                onSelect(e)
            }}
            className={`dropdown-wrapper rows-per-page-dropdown-wrapper align-items-center ${className}`}
        >
            <Dropdown.Toggle
                className="d-flex align-items-center justify-content-between"
                defaultValue={RowsPerPageOptions[0]}
            >
                <p>{rowsPerPage}</p>
            </Dropdown.Toggle>
            <Dropdown.Menu
                style={{ minWidth: '100%' }}
                className="text-center"
                popperConfig={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 2],
                            },
                        },
                    ],
                }}
            >
                {RowsPerPageOptions.map((cur) => (
                    <Dropdown.Item key={cur} eventKey={cur}>
                        <p>{cur}</p>
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default RowsPerPageDropdown
