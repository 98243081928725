import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Card } from 'react-bootstrap'
import { formatImgUrl } from '../../../lib/helpers'
import { generateAvatar } from '../../../utils/generateAvatar'
import { getClubsPlayers } from '../../../lib/api'
import './styles.scss'

const ClubCard = ({ isWhite, club }) => {
    const [playersNumber, setPlayersNumber] = useState(0)
    const handlePlayersNumber = async () => {
        const { total } = await getClubsPlayers(
            club?.club_data?.attributes?.uuid
        )
        if (!total) return
        setPlayersNumber(total)
    }

    useEffect(() => {
        handlePlayersNumber()
    }, [])

    return (
        <Card
            className={`club-card ${
                isWhite ? 'white-club-card' : 'grey-club-card'
            }`}
        >
            <Card.Body className="py-0">
                <Row>
                    <Col>
                        <Row className="car-row align-items-center">
                            <Col xs={5}>
                                <h5>Klub</h5>
                            </Col>
                            <Col xs={7} className="d-flex align-items-center">
                                <img
                                    src={
                                        club?.club_data?.attributes?.logo?.data
                                            ? formatImgUrl(
                                                  club?.club_data?.attributes
                                                      ?.logo?.data?.attributes
                                                      ?.formats?.thumbnail?.url
                                              )
                                            : generateAvatar(
                                                  club?.club_data?.attributes
                                                      ?.name
                                              )
                                    }
                                    className="me-2"
                                    alt={`Logo kluba`}
                                    width={'24px'}
                                    height={'24px'}
                                />
                                <Link
                                    to={`/klubovi/${club?.club_data?.attributes?.uuid}`}
                                >
                                    <p className="link">
                                        {club?.club_data?.attributes?.name}
                                    </p>
                                </Link>
                            </Col>
                        </Row>

                        <Row className="car-row align-items-center">
                            <Col xs={5}>
                                <h5>Broj bodova</h5>
                            </Col>
                            <Col xs={7}>
                                <p>{club?.attributes?.total_points}</p>
                            </Col>
                        </Row>

                        <Row className="car-row align-items-center">
                            <Col xs={5}>
                                <h5>Broj rangiranih igrača</h5>
                            </Col>
                            <Col xs={7}>
                                <p>{playersNumber || 0}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default ClubCard
