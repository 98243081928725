import { AppContextProvider } from "./lib/contextLib";
import RoutesWrapper from "./containers/RoutesWrapper";

function App() {
  return (
    <AppContextProvider>
      <RoutesWrapper />
    </AppContextProvider>
  );
}

export default App;
