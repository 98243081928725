import React from "react";

const NoDataForViewIlustration = () => {
  return (
    <svg
      width="272"
      height="271"
      viewBox="0 0 272 271"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M136.511 256.474C205.449 256.474 261.334 224.21 261.334 184.41C261.334 144.61 205.449 112.346 136.511 112.346C67.5735 112.346 11.6885 144.61 11.6885 184.41C11.6885 224.21 67.5735 256.474 136.511 256.474Z"
        fill="#F5F5F5"
      />
      <path
        d="M66.5714 31.783L62.0836 34.3684C62.1096 34.1725 62.1222 33.9752 62.1216 33.7776V32.4768C62.1216 30.1625 60.4956 29.2248 58.501 30.3847C57.6357 30.9104 56.8958 31.6189 56.333 32.4605C56.0078 29.5554 53.726 28.4714 50.9564 30.0703C47.9591 31.7993 45.5364 36.0323 45.5364 39.4632V41.409C45.5177 42.1839 45.6753 42.953 45.9971 43.6583L42.2735 45.8263C41.6883 46.2072 41.2012 46.7209 40.8518 47.3255C40.5024 47.9301 40.3005 48.6086 40.2627 49.3059C40.2627 50.5905 41.1624 51.1108 42.2735 50.4712L66.5714 36.4226C67.1566 36.0416 67.6437 35.528 67.9931 34.9233C68.3425 34.3187 68.5443 33.6402 68.5822 32.9429C68.5822 31.6584 67.6825 31.1381 66.5714 31.783Z"
        fill="#EBEBEB"
      />
      <path
        d="M85.7582 44.8456L82.3762 46.7968C82.3933 46.6492 82.4023 46.5008 82.4033 46.3523V45.3713C82.4033 43.6261 81.1783 42.9215 79.6662 43.7941C79.0168 44.1919 78.4619 44.7264 78.0402 45.3605C77.7908 43.1925 76.0673 42.3469 73.9806 43.5556C72.7885 44.3321 71.7962 45.3787 71.0843 46.6104C70.3724 47.8422 69.9608 49.2244 69.8831 50.645V52.1138C69.8713 52.698 69.9898 53.2775 70.2299 53.8102L67.4224 55.4362C66.9809 55.7246 66.6135 56.1128 66.3499 56.5695C66.0862 57.0261 65.9338 57.5384 65.9048 58.0649C65.9048 59.0351 66.5823 59.4254 67.4224 58.943L85.7582 48.3577C86.1997 48.0693 86.5671 47.6811 86.8308 47.2245C87.0944 46.7678 87.2469 46.2555 87.2758 45.729C87.2758 44.7534 86.5983 44.3632 85.7582 44.8456Z"
        fill="#EBEBEB"
      />
      <path
        d="M57.1677 209.255L62.7666 206.003C62.7666 206.003 64.1379 194.019 63.2219 184.74C61.9428 171.7 53.5201 156.014 43.4714 159.695C33.4227 163.375 48.073 176.692 51.325 187.743C54.577 198.794 57.1677 209.255 57.1677 209.255Z"
        fill="#25527B"
      />
      <path
        opacity="0.2"
        d="M57.1677 209.255L62.7666 206.003C62.7666 206.003 64.1379 194.019 63.2219 184.74C61.9428 171.7 53.5201 156.014 43.4714 159.695C33.4227 163.375 48.073 176.692 51.325 187.743C54.577 198.794 57.1677 209.255 57.1677 209.255Z"
        fill="black"
      />
      <path
        d="M60.1108 207.894C60.0666 207.893 60.0232 207.883 59.983 207.865C59.9427 207.847 59.9065 207.821 59.8763 207.788C59.8461 207.756 59.8225 207.718 59.8069 207.677C59.7914 207.636 59.7841 207.592 59.7856 207.548C60.7666 178.567 46.344 163.992 46.1976 163.852C46.1597 163.822 46.1285 163.784 46.106 163.742C46.0836 163.699 46.0704 163.652 46.0673 163.604C46.0643 163.556 46.0714 163.508 46.0883 163.463C46.1052 163.418 46.1314 163.377 46.1653 163.343C46.1992 163.308 46.2399 163.282 46.2848 163.264C46.3297 163.247 46.3778 163.239 46.4259 163.242C46.474 163.244 46.521 163.257 46.5639 163.279C46.6067 163.301 46.6445 163.332 46.6746 163.369C46.8209 163.51 61.4549 178.301 60.4631 207.569C60.4617 207.614 60.4514 207.658 60.4329 207.698C60.4143 207.739 60.3879 207.775 60.3551 207.806C60.3223 207.836 60.2838 207.859 60.2419 207.875C60.1999 207.89 60.1553 207.897 60.1108 207.894Z"
        fill="white"
      />
      <path
        d="M54.1814 211.569L58.1651 209.271C58.1651 209.271 58.8426 201.049 54.8102 191.055C51.298 182.383 42.5935 175.147 38.2141 176.659C33.8347 178.171 33.9377 181.282 36.0461 184.54C38.1545 187.797 51.2058 195.564 54.1814 211.569Z"
        fill="#25527B"
      />
      <path
        d="M56.2844 210.697C56.1938 210.697 56.1069 210.661 56.0429 210.597C55.9789 210.533 55.9429 210.446 55.9429 210.355C56.0242 192.036 38.8211 179.651 38.6477 179.527C38.5864 179.471 38.5478 179.395 38.5391 179.313C38.5304 179.231 38.5523 179.148 38.6006 179.081C38.6488 179.014 38.7201 178.967 38.8008 178.949C38.8815 178.931 38.9659 178.944 39.0379 178.985C39.2114 179.109 56.7017 191.7 56.6204 210.372C56.6176 210.459 56.5809 210.542 56.5182 210.602C56.4555 210.663 56.3716 210.697 56.2844 210.697Z"
        fill="white"
      />
      <path
        d="M161.676 186.102L126.988 206.156L126.907 206.112L113.709 198.486C112.561 197.749 111.649 196.696 111.082 195.455C110.515 194.213 110.317 192.835 110.511 191.484L115.389 166.053L150.077 145.999L145.199 171.43C145.005 172.781 145.203 174.159 145.77 175.401C146.337 176.642 147.249 177.695 148.397 178.432L161.676 186.102Z"
        fill="#37474F"
      />
      <path
        d="M115.406 166.053L110.549 191.261L145.248 171.234L149.736 147.918L147.546 147.479L115.406 166.053Z"
        fill="#263238"
      />
      <path
        d="M126.977 206.149L113.704 198.485C112.556 197.747 111.645 196.694 111.078 195.453C110.511 194.212 110.313 192.834 110.506 191.483L115.384 166.052L109.601 162.708L104.007 191.803C103.265 195.651 105.438 200.404 108.831 202.361L126.961 212.827L126.977 206.149Z"
        fill="#263238"
      />
      <path
        d="M161.676 186.102L126.977 206.15V212.833L161.676 192.784V186.102Z"
        fill="#455A64"
      />
      <path
        d="M195.621 21.3061C197.215 22.2275 198.51 24.5581 198.51 26.5364V133.018C198.452 134.087 198.142 135.128 197.607 136.056C197.071 136.983 196.324 137.772 195.426 138.356L62.7066 214.99C61.0047 215.971 58.3273 216.02 56.7446 215.098C55.162 214.177 53.8503 211.846 53.8503 209.868V103.387C53.9089 102.316 54.2186 101.275 54.7544 100.346C55.2901 99.4179 56.0369 98.6287 56.9343 98.0424L189.643 21.4145C191.345 20.428 194.022 20.3793 195.621 21.3061Z"
        fill="#455A64"
      />
      <path
        d="M62.3384 215.174C60.6365 215.971 58.2138 215.954 56.7233 215.098C55.1244 214.177 53.829 211.846 53.829 209.868V103.386C53.8675 102.312 54.179 101.266 54.7341 100.346L60.5389 103.69C59.9834 104.608 59.6718 105.653 59.6338 106.725V213.206C59.6175 215.033 60.8045 215.851 62.3384 215.174Z"
        fill="#263238"
      />
      <path
        d="M198.51 133.018V26.5584C198.51 24.591 197.134 23.7942 195.426 24.7752L62.7069 101.387C61.8093 101.972 61.0623 102.76 60.5265 103.687C59.9906 104.615 59.6811 105.656 59.6229 106.726V213.207C59.6229 215.174 61.005 215.971 62.7069 214.99L195.426 138.357C196.324 137.772 197.071 136.984 197.607 136.056C198.143 135.129 198.452 134.088 198.51 133.018Z"
        fill="#37474F"
      />
      <path
        d="M191.735 30.1896L64.9779 103.414C64.2602 103.882 63.6629 104.513 63.2344 105.255C62.8059 105.997 62.5584 106.829 62.5118 107.685V202.226C62.5118 203.798 63.5958 204.437 64.9779 203.651L191.741 130.427C192.459 129.959 193.056 129.328 193.484 128.586C193.913 127.844 194.16 127.012 194.207 126.156V31.6151C194.207 30.0433 193.101 29.4037 191.735 30.1896Z"
        fill="#25527B"
      />
      <path
        opacity="0.8"
        d="M191.735 30.1896L64.9779 103.414C64.2602 103.882 63.6629 104.513 63.2344 105.255C62.8059 105.997 62.5584 106.829 62.5118 107.685V202.226C62.5118 203.798 63.5958 204.437 64.9779 203.651L191.741 130.427C192.459 129.959 193.056 129.328 193.484 128.586C193.913 127.844 194.16 127.012 194.207 126.156V31.6151C194.207 30.0433 193.101 29.4037 191.735 30.1896Z"
        fill="white"
      />
      <path
        d="M71.547 192.026C70.8749 192.026 70.463 191.484 70.463 190.557V115.685L186.245 48.8457V123.403C186.2 124.198 185.969 124.971 185.57 125.66C185.172 126.349 184.618 126.935 183.952 127.371L72.4955 191.728C72.2106 191.908 71.8837 192.011 71.547 192.026Z"
        fill="#F5F5F5"
      />
      <path
        d="M185.974 49.3225V123.414C185.928 124.163 185.708 124.891 185.332 125.54C184.956 126.189 184.433 126.742 183.806 127.154L72.36 191.495C72.1154 191.649 71.8357 191.738 71.547 191.755C70.8208 191.755 70.7449 190.92 70.7449 190.557V115.842L185.974 49.3225ZM186.516 48.3849L70.2029 115.539V190.557C70.2029 191.641 70.7449 192.297 71.547 192.297C71.9318 192.283 72.306 192.167 72.631 191.961L184.083 127.614C184.791 127.153 185.38 126.532 185.803 125.8C186.226 125.068 186.47 124.247 186.516 123.403V48.374V48.3849Z"
        fill="#E0E0E0"
      />
      <path
        d="M70.2029 115.528V126.059L186.516 58.9051V48.374L70.2029 115.528Z"
        fill="#25527B"
      />
      <path
        d="M143.183 77.5062V79.7717C143.183 80.2324 143.514 80.4221 143.915 80.1945L179.687 59.5443V55.6094L143.915 76.2596C143.705 76.3969 143.53 76.5808 143.403 76.7971C143.276 77.0134 143.201 77.256 143.183 77.5062Z"
        fill="#FAFAFA"
      />
      <path
        d="M183.508 53.3985C183.909 53.1654 184.234 53.3551 184.234 53.8213V56.0868C184.22 56.3393 184.146 56.5849 184.02 56.8041C183.894 57.0234 183.719 57.2102 183.508 57.3497L179.676 59.561V55.6099L183.508 53.3985Z"
        fill="#455A64"
      />
      <path
        d="M183.221 56.5036L182.798 56.3736C182.84 56.1959 182.862 56.0141 182.863 55.8316C182.882 55.659 182.857 55.4843 182.788 55.3247C182.72 55.1651 182.611 55.026 182.473 54.921C182.379 54.8641 182.271 54.834 182.161 54.834C182.051 54.834 181.943 54.8641 181.849 54.921C181.577 55.129 181.358 55.3986 181.21 55.7076C181.062 56.0166 180.99 56.3563 180.999 56.6988C180.979 56.8721 181.005 57.0475 181.073 57.208C181.142 57.3684 181.25 57.5085 181.389 57.6147C181.471 57.6616 181.565 57.6859 181.66 57.6852C181.784 57.6834 181.906 57.6478 182.012 57.5822C182.336 57.3589 182.578 57.0352 182.7 56.6608L183.129 56.7909H183.177C183.211 56.7902 183.244 56.7788 183.272 56.7584C183.299 56.738 183.319 56.7095 183.329 56.6771C183.333 56.6403 183.324 56.6033 183.304 56.572C183.285 56.5406 183.255 56.5166 183.221 56.5036ZM181.849 57.3275C181.804 57.3547 181.751 57.369 181.698 57.369C181.644 57.369 181.592 57.3547 181.546 57.3275C181.459 57.2472 181.392 57.1472 181.352 57.036C181.311 56.9247 181.298 56.8054 181.313 56.6879C181.308 56.4011 181.369 56.117 181.49 55.8569C181.611 55.5969 181.79 55.3677 182.012 55.1866C182.069 55.1504 182.134 55.1299 182.202 55.127C182.239 55.1182 182.278 55.1182 182.316 55.127C182.402 55.208 182.467 55.3081 182.508 55.4191C182.548 55.5302 182.562 55.6491 182.549 55.7665C182.561 56.0631 182.504 56.3584 182.383 56.6292C182.261 56.9001 182.079 57.1391 181.849 57.3275Z"
        fill="#FAFAFA"
      />
      <path
        d="M70.2029 110.438C70.2476 109.595 70.4908 108.774 70.9127 108.042C71.3346 107.31 71.9234 106.688 72.631 106.227L184.083 41.8807C185.427 41.1057 186.516 41.7344 186.516 43.2845V48.3739L70.2029 115.528V110.438Z"
        fill="#37474F"
      />
      <path
        d="M172.831 51.0026C172.499 51.219 172.224 51.5104 172.026 51.8531C171.828 52.1959 171.714 52.5804 171.692 52.9755C171.692 53.7072 172.202 53.9999 172.831 53.6367C173.164 53.421 173.442 53.1292 173.641 52.7853C173.839 52.4414 173.954 52.0552 173.974 51.6584C173.974 50.9484 173.465 50.6395 172.831 51.0026Z"
        fill="#25527B"
      />
      <path
        d="M176.825 48.7047C176.492 48.9199 176.215 49.2108 176.016 49.5538C175.818 49.8967 175.703 50.2818 175.682 50.6776C175.682 51.4039 176.191 51.702 176.825 51.3388C177.157 51.1219 177.433 50.8297 177.631 50.4859C177.829 50.1422 177.943 49.7566 177.963 49.3605C177.963 48.6343 177.454 48.3416 176.825 48.7047Z"
        fill="#25527B"
      />
      <path
        d="M180.814 46.4067C180.483 46.6231 180.207 46.9145 180.009 47.2572C179.812 47.6 179.697 47.9845 179.676 48.3796C179.676 49.1059 180.185 49.404 180.814 49.0408C181.146 48.8236 181.422 48.5313 181.619 48.1876C181.817 47.8439 181.931 47.4585 181.952 47.0625C181.947 46.3362 181.443 46.0707 180.814 46.4067Z"
        fill="#25527B"
      />
      <path
        d="M121.194 118.004L135.525 109.733C136.43 109.191 137.151 109.63 137.151 110.682V127.223C137.121 127.792 136.958 128.345 136.676 128.839C136.393 129.333 135.999 129.755 135.525 130.069L121.194 138.34C120.289 138.882 119.568 138.437 119.568 137.391V120.866C119.594 120.294 119.755 119.737 120.038 119.24C120.32 118.742 120.717 118.319 121.194 118.004Z"
        fill="#25527B"
      />
      <path
        d="M125.129 138.612L133.801 133.614C134.452 133.235 134.972 133.506 134.972 134.205C134.943 134.599 134.821 134.981 134.618 135.32C134.415 135.659 134.135 135.945 133.801 136.156L125.129 141.154C124.479 141.528 123.953 141.262 123.953 140.563C123.982 140.168 124.103 139.785 124.307 139.446C124.512 139.107 124.793 138.821 125.129 138.612Z"
        fill="#455A64"
      />
      <path
        d="M128.235 117.397C129.758 116.519 130.988 117.229 130.988 118.985C130.937 119.94 130.661 120.869 130.182 121.697C129.704 122.525 129.036 123.228 128.235 123.749C126.717 124.627 125.487 123.917 125.487 122.161C125.538 121.207 125.814 120.278 126.291 119.451C126.769 118.623 127.435 117.919 128.235 117.397Z"
        fill="#FAFAFA"
      />
      <path
        d="M133.66 126.779C133.66 123.901 131.633 122.617 129.14 124.069L127.579 124.974C125.08 126.416 123.059 129.917 123.059 132.801V133.576L133.66 127.462V126.779Z"
        fill="#FAFAFA"
      />
      <path
        d="M147.627 102.731L161.953 94.4596C162.863 93.9176 163.579 94.3566 163.579 95.4081V111.95C163.55 112.518 163.387 113.072 163.105 113.567C162.822 114.061 162.428 114.482 161.953 114.795L147.627 123.066C146.722 123.608 146.001 123.164 146.001 122.118V105.576C146.03 105.007 146.193 104.454 146.475 103.959C146.758 103.465 147.152 103.044 147.627 102.731Z"
        fill="#25527B"
      />
      <path
        opacity="0.2"
        d="M147.627 102.731L161.953 94.4596C162.863 93.9176 163.579 94.3566 163.579 95.4081V111.95C163.55 112.518 163.387 113.072 163.105 113.567C162.822 114.061 162.428 114.482 161.953 114.795L147.627 123.066C146.722 123.608 146.001 123.164 146.001 122.118V105.576C146.03 105.007 146.193 104.454 146.475 103.959C146.758 103.465 147.152 103.044 147.627 102.731Z"
        fill="black"
      />
      <path
        d="M151.562 123.338L160.234 118.34C160.879 117.966 161.405 118.232 161.405 118.931C161.377 119.325 161.256 119.708 161.053 120.046C160.849 120.385 160.569 120.672 160.234 120.882L151.562 125.88C150.912 126.254 150.386 125.988 150.386 125.289C150.415 124.894 150.536 124.512 150.741 124.172C150.945 123.833 151.227 123.547 151.562 123.338Z"
        fill="#455A64"
      />
      <path
        d="M154.668 102.124C156.191 101.245 157.421 101.956 157.421 103.712C157.371 104.667 157.095 105.596 156.616 106.424C156.138 107.252 155.47 107.955 154.668 108.476C153.151 109.354 151.92 108.644 151.92 106.888C151.972 105.934 152.248 105.005 152.725 104.178C153.203 103.35 153.868 102.646 154.668 102.124Z"
        fill="#FAFAFA"
      />
      <path
        d="M160.093 111.506C160.093 108.628 158.066 107.343 155.573 108.796L154.012 109.701C151.514 111.143 149.492 114.644 149.492 117.527V118.303L160.093 112.194V111.506Z"
        fill="#FAFAFA"
      />
      <path
        d="M94.788 133.24L109.118 124.969C110.024 124.427 110.744 124.872 110.744 125.918V142.46C110.716 143.028 110.554 143.582 110.271 144.077C109.989 144.571 109.594 144.992 109.118 145.305L94.788 153.576C93.8828 154.118 93.162 153.679 93.162 152.628V136.086C93.1918 135.517 93.3545 134.964 93.6369 134.47C93.9192 133.976 94.3135 133.555 94.788 133.24Z"
        fill="#25527B"
      />
      <path
        opacity="0.5"
        d="M94.788 133.24L109.118 124.969C110.024 124.427 110.744 124.872 110.744 125.918V142.46C110.716 143.028 110.554 143.582 110.271 144.077C109.989 144.571 109.594 144.992 109.118 145.305L94.788 153.576C93.8828 154.118 93.162 153.679 93.162 152.628V136.086C93.1918 135.517 93.3545 134.964 93.6369 134.47C93.9192 133.976 94.3135 133.555 94.788 133.24Z"
        fill="white"
      />
      <path
        d="M98.7229 153.846L107.395 148.849C108.045 148.475 108.566 148.741 108.566 149.44C108.537 149.834 108.416 150.216 108.213 150.555C108.01 150.894 107.729 151.181 107.395 151.391L98.7229 156.388C98.0725 156.762 97.5468 156.497 97.5468 155.798C97.5754 155.403 97.6968 155.02 97.9012 154.681C98.1056 154.342 98.3871 154.056 98.7229 153.846Z"
        fill="#455A64"
      />
      <path
        d="M101.829 132.633C103.352 131.755 104.582 132.471 104.582 134.221C104.531 135.177 104.254 136.107 103.776 136.936C103.298 137.764 102.63 138.469 101.829 138.991C100.311 139.864 99.0806 139.154 99.0806 137.397C99.1325 136.443 99.4084 135.515 99.8857 134.687C100.363 133.86 101.029 133.156 101.829 132.633Z"
        fill="#FAFAFA"
      />
      <path
        d="M107.254 142.004C107.254 139.12 105.227 137.841 102.734 139.294L101.189 140.199C98.6904 141.641 96.6687 145.147 96.6687 148.031V148.806L107.27 142.687L107.254 142.004Z"
        fill="#FAFAFA"
      />
      <path
        d="M232.179 98.9852L226.423 95.6627C220.619 92.4107 212.662 92.8714 203.844 97.9608C195.594 102.714 188.071 110.649 182.402 119.83C175.844 130.399 171.719 142.638 171.719 153.608C171.719 163.841 175.302 171.017 181.096 174.383L186.912 177.716C192.765 181.445 201.004 181.114 210.218 175.824C227.963 165.575 242.347 140.66 242.347 120.172C242.326 109.484 238.423 102.15 232.179 98.9852Z"
        fill="#25527B"
      />
      <path
        opacity="0.1"
        d="M186.684 177.581L181.096 174.383C175.302 171.022 171.725 163.835 171.725 153.603C171.725 142.632 175.849 130.405 182.402 119.836L188.733 123.516C182.174 134.085 178.061 146.307 178.061 157.266C178.061 167.05 181.334 174.041 186.684 177.581Z"
        fill="black"
      />
      <path
        opacity="0.4"
        d="M231.946 98.8497C226.223 96.0747 218.586 96.7793 210.201 101.614C201.941 106.389 194.418 114.329 188.733 123.516L182.402 119.836C188.077 110.622 195.605 102.72 203.849 97.9662C212.662 92.8714 220.624 92.3891 226.434 95.6627L231.946 98.8497Z"
        fill="white"
      />
      <path
        d="M195.784 175.868C193.448 175.917 191.147 175.289 189.161 174.058L189.069 173.998L188.884 173.895C184.651 171.012 182.321 165.115 182.321 157.261C182.321 147.505 185.968 136.064 192.337 125.825C197.865 116.893 204.949 109.636 212.293 105.392C216.602 102.904 220.819 101.598 224.472 101.598C226.328 101.57 228.167 101.957 229.854 102.731L230.049 102.839L230.147 102.888C235.084 105.387 237.914 111.696 237.914 120.2C237.914 139.283 224.472 162.562 207.947 172.096C203.627 174.557 199.437 175.868 195.784 175.868Z"
        fill="#FAFAFA"
      />
      <path
        d="M211.605 144.98C203.426 147.148 196.792 142.313 196.792 134.14V128.899C196.792 121.966 202.413 114.844 209.345 112.985L213.86 111.777C220.792 109.918 226.413 114.031 226.413 120.964V126.205C226.391 134.378 219.784 142.785 211.605 144.98Z"
        fill="#263238"
      />
      <path
        d="M188.993 195.662C189.047 194.795 190.429 185.31 191.383 179.944L200.662 180.741L197.28 200.302L191.193 199.906C188.765 199.998 188.955 196.519 188.993 195.662Z"
        fill="#FFA8A7"
      />
      <path
        d="M231.74 165.641L238.277 186.101L230.104 190.578L225.204 171.332L231.74 165.641Z"
        fill="#FFA8A7"
      />
      <path
        d="M217.432 148.118C221.768 147.034 226.575 147.576 228.223 151.044C229.871 154.513 233.475 166.974 233.475 166.974C231.232 169.996 227.95 172.079 224.261 172.822L217.432 148.118Z"
        fill="#25527B"
      />
      <path
        opacity="0.3"
        d="M217.432 148.118C221.768 147.034 226.575 147.576 228.223 151.044C229.871 154.513 233.475 166.974 233.475 166.974C231.232 169.996 227.95 172.079 224.261 172.822L217.432 148.118Z"
        fill="black"
      />
      <path
        d="M208.423 150.708L204.803 151.852C200.863 154.02 199.443 157.5 197.789 161.982C196.583 165.165 196.473 168.659 197.475 171.911L202.087 186.085C202.087 186.085 199.919 192.686 197.421 199.055C208.993 203.391 223.437 195.618 226.922 189.245C227.074 188.047 226.873 182.898 227.15 176.903C227.453 170.508 228.201 163.18 227.345 159.505C225.746 152.692 222.759 146.692 216.808 148.286L208.423 150.708Z"
        fill="#F5F5F5"
      />
      <path
        d="M204.803 151.853C200.857 153.554 195.047 156.026 193.058 167.403C191.031 178.931 190.641 181.652 190.641 181.652C190.641 181.652 194.093 184.703 200.646 182.909C200.646 182.909 206.424 159.793 204.803 151.853Z"
        fill="#25527B"
      />
      <path
        opacity="0.3"
        d="M204.803 151.853C200.857 153.554 195.047 156.026 193.058 167.403C191.031 178.931 190.641 181.652 190.641 181.652C190.641 181.652 194.093 184.703 200.646 182.909C200.646 182.909 206.424 159.793 204.803 151.853Z"
        fill="black"
      />
      <path
        d="M198.651 167.196C203.285 165.955 207.047 161.19 207.047 156.556V141.651C202.407 142.898 198.651 147.662 198.651 152.296V167.196Z"
        fill="#263238"
      />
      <path
        d="M200.819 182.194C201.551 179.12 202.321 175.624 203.047 171.896C203.047 171.896 203.014 179.603 201.876 185.446L200.819 182.194Z"
        fill="#E0E0E0"
      />
      <path
        d="M221.551 116.628C223.112 117.273 225.047 121.3 224.857 130.877C224.694 139.007 222.429 141.592 221.291 142.47C220.152 143.349 217.957 143.587 215.827 143.75V148.595C215.827 148.595 218.776 151.76 218.586 154.015C218.396 156.27 214.331 157.901 211.573 156.47C209.751 155.425 208.201 153.965 207.047 152.21V140.514C207.047 140.514 205.605 143.121 203.253 141.945C200.901 140.769 200.809 136.324 202.169 134.357C203.529 132.389 206.505 132.102 207.047 134.172C207.047 134.172 210.445 133.408 214.976 129.294C218.611 125.993 220.943 121.5 221.551 116.628Z"
        fill="#FFA8A7"
      />
      <path
        d="M217.052 130.937C217.065 131.217 216.991 131.494 216.842 131.731C216.693 131.969 216.475 132.155 216.217 132.265C215.735 132.416 215.318 132.08 215.296 131.506C215.282 131.226 215.354 130.949 215.502 130.712C215.651 130.475 215.868 130.288 216.125 130.178C216.635 130.026 217.025 130.362 217.052 130.937Z"
        fill="#263238"
      />
      <path
        d="M217.285 137.717L219.329 137.923C219.278 138.245 219.119 138.54 218.877 138.759C218.636 138.978 218.326 139.108 218.001 139.126C217.437 139.066 217.117 138.438 217.285 137.717Z"
        fill="#F28F8F"
      />
      <path
        d="M224.261 125.89L222.478 124.942C222.803 124.27 223.464 123.934 223.957 124.199C224.45 124.465 224.581 125.202 224.261 125.89Z"
        fill="#263238"
      />
      <path
        d="M223.849 128.384C223.863 128.664 223.79 128.94 223.64 129.177C223.491 129.414 223.272 129.599 223.014 129.706C222.532 129.864 222.12 129.522 222.093 128.948C222.079 128.669 222.151 128.393 222.3 128.156C222.448 127.92 222.665 127.734 222.922 127.625C223.41 127.468 223.822 127.809 223.849 128.384Z"
        fill="#263238"
      />
      <path
        d="M219.703 128.915L219.843 134.985L222.624 133.246L219.703 128.915Z"
        fill="#F28F8F"
      />
      <path
        d="M215.827 143.749C213.578 144.009 208.955 143.717 208.239 141.798C208.493 142.722 209.07 143.524 209.865 144.058C211.247 145.055 215.827 145.364 215.827 145.364V143.749Z"
        fill="#F28F8F"
      />
      <path
        d="M202.304 165.917L197.876 163.358H197.828C195.627 162.112 192.608 162.274 189.264 164.226C186.137 166.03 183.302 169.039 181.134 172.518C178.652 176.529 177.085 181.19 177.085 185.331C177.085 189.206 178.446 191.933 180.641 193.206L185.139 195.775C187.356 197.19 190.489 197.065 193.969 195.055C200.695 191.168 206.153 181.721 206.153 173.954C206.153 169.9 204.673 167.114 202.304 165.917Z"
        fill="#37474F"
      />
      <path
        d="M185.047 195.716L180.641 193.201C178.44 191.928 177.085 189.201 177.085 185.321C177.085 181.163 178.646 176.524 181.134 172.519L185.839 175.229C183.351 179.239 181.79 183.868 181.79 188.025C181.779 191.722 183.031 194.377 185.047 195.716Z"
        fill="#263238"
      />
      <path
        d="M202.212 165.852C200.044 164.801 197.15 165.066 193.968 166.904C190.787 168.741 188.006 171.722 185.838 175.207L181.134 172.497C183.302 169.012 186.142 165.993 189.264 164.204C192.608 162.275 195.627 162.091 197.828 163.332H197.876L202.212 165.852Z"
        fill="#455A64"
      />
      <path
        d="M199.253 176.296L195.193 178.642V172.138C195.193 171.092 194.651 170.567 193.968 170.957C193.286 171.347 192.744 172.507 192.744 173.553V180.057L188.679 182.404C188.342 182.671 188.072 183.012 187.889 183.4C187.706 183.788 187.614 184.213 187.622 184.642C187.622 185.542 188.099 185.997 188.679 185.656L192.744 183.315V189.819C192.744 190.865 193.286 191.39 193.968 191C194.651 190.61 195.193 189.445 195.193 188.404V181.9L199.253 179.553C199.59 179.287 199.861 178.946 200.044 178.558C200.228 178.17 200.319 177.744 200.31 177.315C200.31 176.415 199.833 175.96 199.253 176.296Z"
        fill="#F5F5F5"
      />
    </svg>
  );
};

export default NoDataForViewIlustration;
