import React from 'react'
import { Modal } from 'react-bootstrap'
import Button from '../../../../components/Button'
const UpdateRankingListModal = ({ show, onHide, modalText = false }) => {
    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>Obaveštenje</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {modalText ? modalText : 'Rang lista je uspešno ažurirana.'}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button type="text" onClick={onHide}>
                    Zatvori
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default UpdateRankingListModal
