import * as React from 'react'

const InformationCircle = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="none"
        viewBox="0 0 32 32"
        {...props}
    >
        <path
            d="M16 3.5C9.107 3.5 3.5 9.107 3.5 16c0 6.892 5.607 12.5 12.5 12.5 6.892 0 12.5-5.608 12.5-12.5 0-6.893-5.608-12.5-12.5-12.5Zm0 5.125a1.625 1.625 0 1 1 0 3.25 1.625 1.625 0 0 1 0-3.25Zm3 14.125h-5.5a1 1 0 0 1 0-2h1.75v-5.5h-1a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v6.5H19a1 1 0 0 1 0 2Z"
            fill="#214B73"
        />
    </svg>
)

export default InformationCircle
