import * as React from 'react'

const Accessibility = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        fill="none"
        viewBox="0 0 32 32"
        {...props}
    >
        <g clipPath="url(#a)">
            <path
                d="M16 7a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7Z"
                fill="#214B73"
            />
            <path
                d="m27 7.05-.028.007-.027.009a7.112 7.112 0 0 0-.187.055c-1.163.341-6.808 1.933-10.785 1.933-3.696 0-8.83-1.375-10.473-1.842A4.61 4.61 0 0 0 5 7.05c-1.187-.313-2 .893-2 1.996 0 1.092.982 1.612 1.972 1.985v.017l5.952 1.859c.608.233.77.471.85.678.258.661.052 1.972-.022 2.43l-.362 2.812L9.378 29.84l-.017.092-.014.08c-.145 1.008.596 1.988 2 1.988 1.225 0 1.765-.846 2-1.996.234-1.151 1.75-9.849 2.625-9.849s2.677 9.849 2.677 9.849c.235 1.15.775 1.996 2 1.996 1.408 0 2.149-.984 2-1.996a3.52 3.52 0 0 0-.047-.254l-2.04-10.92-.361-2.813c-.262-1.638-.052-2.18.02-2.306a.07.07 0 0 0 .005-.01c.067-.125.375-.405 1.092-.674l5.58-1.95c.034-.01.068-.02.101-.033 1-.375 2-.894 2-1.996 0-1.102-.812-2.31-2-1.998Z"
                fill="#25527B"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h32v32H0z" />
            </clipPath>
        </defs>
    </svg>
)

export default Accessibility
