import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import {
    createClubMatch,
    getSingleClubMatch,
    updateClubMatch,
} from '../../lib/api'
import Button from '../Button'
import RadioButton from '../RadioButton'
import CardParticipantSection from './cardParticipant'
import CardResultSection from './cardResultSection'
import './styles.scss'

const ChangeMatchResultModal = ({
    show,
    onHide,
    matchDetails,
    handleGetSingleMatch,
    onChange = false,
    createNextMatch = false,
}) => {
    const {
        sideOneData,
        sideTwoData,
        single_club_match_uuid,
        loser_participant_uuid,
    } = matchDetails || {}

    const [sideOneResult, setSideOneResult] = useState(0)
    const [sideTwoResult, setSideTwoResult] = useState(0)

    const [matchResultData, setMatchResultData] = useState({
        loser_participant_uuid: loser_participant_uuid,
    })

    const handleSaveChanges = async () => {
        const updatedResult = await updateClubMatch(single_club_match_uuid, {
            ...matchResultData,
            side_1_result: sideOneResult,
            side_2_result: sideTwoResult,
            loser_participant_uuid: matchResultData?.loser_participant_uuid,
        })

        if (updatedResult) {
            if (createNextMatch) {
                const nextMatchData = await getSingleClubMatch({
                    ...matchDetails,
                    round_number: matchDetails?.round_number + 1,
                    pair_number: Math.floor(
                        (matchDetails?.pair_number + 1) / 2
                    ),
                })

                if (nextMatchData) {
                    let secondParticipantObj = {}
                    if (matchDetails.pair_number % 2 === 0) {
                        if (
                            matchResultData?.loser_participant_uuid ===
                            matchDetails?.sideOneData?.participant_uuid
                        ) {
                            secondParticipantObj.side_2_uuid =
                                matchDetails.sideTwoData.participant_uuid
                        } else {
                            secondParticipantObj.side_2_uuid =
                                matchDetails.sideOneData.participant_uuid
                        }
                    } else {
                        if (
                            matchResultData?.loser_participant_uuid ===
                            matchDetails?.sideOneData?.participant_uuid
                        ) {
                            secondParticipantObj.side_1_uuid =
                                matchDetails.sideTwoData.participant_uuid
                        } else {
                            secondParticipantObj.side_1_uuid =
                                matchDetails.sideOneData.participant_uuid
                        }
                    }
                    await updateClubMatch(nextMatchData?.attributes?.uuid, {
                        ...secondParticipantObj,
                    })
                } else {
                    let participantObj = {}

                    if (matchDetails.pair_number % 2 === 0) {
                        if (
                            matchResultData?.loser_participant_uuid ===
                            matchDetails?.sideOneData?.participant_uuid
                        ) {
                            participantObj.side_2_uuid =
                                matchDetails.sideTwoData.participant_uuid
                        } else {
                            participantObj.side_2_uuid =
                                matchDetails.sideOneData.participant_uuid
                        }
                    } else {
                        if (
                            matchResultData?.loser_participant_uuid ===
                            matchDetails?.sideOneData?.participant_uuid
                        ) {
                            participantObj.side_1_uuid =
                                matchDetails.sideTwoData.participant_uuid
                        } else {
                            participantObj.side_1_uuid =
                                matchDetails.sideOneData.participant_uuid
                        }
                    }

                    const {
                        sideOneData,
                        sideTwoData,
                        uuid,
                        loser_participant_uuid,
                        ...otherAttributes
                    } = matchDetails
                    await createClubMatch({
                        ...otherAttributes,
                        ...participantObj,
                        round_number: matchDetails?.round_number + 1,
                        pair_number: Math.floor(
                            (matchDetails?.pair_number + 1) / 2
                        ),
                    })
                }
            }

            onHide()
            if (onChange) {
                onChange()
            } else {
                handleGetSingleMatch()
            }
        }
    }

    useEffect(() => {
        setMatchResultData((prev) => ({
            ...prev,
            loser_participant_uuid: loser_participant_uuid,
        }))
    }, [matchDetails])

    useEffect(() => {
        setSideOneResult(sideOneData?.result ? sideOneData?.result : 0)
        setSideTwoResult(sideTwoData?.result ? sideTwoData?.result : 0)
    }, [sideOneData, sideTwoData])

    return (
        <Modal
            size="lg"
            centered
            show={show}
            onHide={onHide}
            dialogClassName="create-draw-pair-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <h2>Izmeni rezultat</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="px-2">
                    <Col className="tournament-match-card">
                        <CardParticipantSection
                            participantData={sideOneData}
                            matchData={{
                                ...matchDetails,
                                ...matchResultData,
                            }}
                        />
                        <CardResultSection
                            resultData={sideOneResult}
                            setResultData={setSideOneResult}
                            isEditable={true}
                        />
                        <CardResultSection
                            resultData={sideTwoResult}
                            setResultData={setSideTwoResult}
                            isEditable={true}
                        />
                        <CardParticipantSection
                            participantData={sideTwoData}
                            matchData={{
                                ...matchDetails,
                                ...matchResultData,
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <RadioButton
                            required
                            className="mt-3"
                            name="Označi pobednika"
                            radioValues={[
                                sideOneData?.club?.attributes?.name,
                                sideTwoData?.club?.attributes?.name,
                            ]}
                            onChange={(e) => {
                                setMatchResultData((prev) => ({
                                    ...prev,
                                    loser_participant_uuid: e.target.value,
                                }))
                            }}
                            checkedValue={
                                matchResultData?.loser_participant_uuid
                                    ? sideOneData?.participant_uuid ===
                                      matchResultData?.loser_participant_uuid
                                        ? sideTwoData?.club?.attributes?.name
                                        : sideOneData?.club?.attributes?.name
                                    : null
                            }
                            radioHiddenValues={[
                                sideTwoData?.participant_uuid,
                                sideOneData?.participant_uuid,
                            ]}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button type="text" onClick={onHide}>
                    Odustani
                </Button>
                <Button
                    onClick={handleSaveChanges}
                    disabled={
                        matchResultData?.loser_participant_uuid ? false : true
                    }
                >
                    Sačuvaj
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ChangeMatchResultModal
