import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import TextField from '../../../../../components/TextField'

// Styles
import './styles.scss'

function TournamentOrganization({ setProgress, formData, setFormData }) {
    useEffect(() => {
        setProgress(65.5)
    }, [])

    const setField = (e) => {
        let field = e.target.id
        let value = e.target.value
        let mainField = e.target.name

        setFormData((prevData) => ({
            ...prevData,
            [mainField]: { ...prevData[mainField], [field]: value },
        }))
    }

    return (
        <Row className="tournament-organization-wrapper">
            <h3 className="tournament-section-title">
                Kancelarija vrhovnog sudije
            </h3>
            <Col className="d-block d-md-flex flex-column flex-md-row mb-4 align-items-end">
                <Col className="me-md-3 mb-3 mb-md-0" xs={12} md>
                    <TextField
                        label="Broj prostorije (odabrati ocenu)"
                        placeholder="Broj"
                        id="roomNumber"
                        name="chiefOffice"
                        onChange={setField}
                        type={'number'}
                        value={formData.chiefOffice.roomNumber}
                    />
                </Col>
                <Col className="me-3  mb-3 mb-md-0" xs={12} md={5}>
                    <TextField
                        label="Prateća oprema (odabrati ocenu)"
                        placeholder="Ocena"
                        id="chiefRating"
                        name="chiefOffice"
                        onChange={setField}
                        type={'number'}
                        value={formData.chiefOffice.chiefRating}
                    />
                </Col>
                <Col xs={12} md>
                    <TextField
                        label="Lokacija (uz terene itd.)"
                        placeholder="Lokacija"
                        id="location"
                        name="chiefOffice"
                        onChange={setField}
                        type={'text'}
                        value={formData.chiefOffice.location}
                    />
                </Col>
            </Col>
            <h3 className="tournament-section-title">Komunikacija</h3>
            <Col
                className="d-block d-md-flex flex-column flex-md-row mb-4 align-items-end"
                xs={12}
            >
                <Col className="me-md-3 mb-3 mb-md-0">
                    <TextField
                        label="Međunarodna linija"
                        placeholder="Broj"
                        id="internationalLine"
                        name="communication"
                        onChange={setField}
                        type={'number'}
                        value={formData.communication.internationalLine}
                    />
                </Col>
                <Col className="me-md-3 mb-3 mb-md-0">
                    <TextField
                        label="Fax"
                        placeholder="Fax"
                        id="fax"
                        name="communication"
                        onChange={setField}
                        type={'number'}
                        value={formData.communication.fax}
                    />
                </Col>
                <Col>
                    <TextField
                        label="Email"
                        placeholder="Email"
                        id="email"
                        name="communication"
                        onChange={setField}
                        type={'email'}
                        value={formData.communication.email}
                    />
                </Col>
            </Col>
            <Col
                className="d-block d-md-flex flex-column flex-md-row mb-4 align-items-end"
                xs={12}
            >
                <Col className="me-md-3 mb-3 mb-md-0">
                    <TextField
                        label="Fotokopir"
                        placeholder="Fotokopir"
                        id="photocopier"
                        name="communication"
                        onChange={setField}
                        type={'number'}
                        value={formData.communication.photocopier}
                    />
                </Col>
                <Col className="me-md-3 mb-3 mb-md-0">
                    <TextField
                        label="Voki-toki"
                        placeholder="Voki-toki"
                        id="walkieTalkie"
                        name="communication"
                        onChange={setField}
                        type={'number'}
                        value={formData.communication.walkieTalkie}
                    />
                </Col>
                <Col>
                    <TextField
                        label="Ocena"
                        placeholder="Ocena"
                        id="communicationRating"
                        name="communication"
                        onChange={setField}
                        type={'number'}
                        value={formData.communication.communicationRating}
                    />
                </Col>
            </Col>
            <h3 className="tournament-section-title">Službena lica</h3>
            <Col
                className="d-block d-md-flex flex-column flex-md-row mb-4 align-items-end"
                xs={12}
            >
                <Col className="me-md-3 mb-3 mb-md-0">
                    <TextField
                        label="Turnirsko osoblje"
                        placeholder="Turnirsko osoblje"
                        id="tournamentStaff"
                        name="officials"
                        onChange={setField}
                        type={'number'}
                        value={formData.officials.tournamentStaff}
                    />
                </Col>
                <Col className="me-md-3 mb-3 mb-md-0">
                    <TextField
                        label="Drugo osobolje"
                        placeholder="Drugo osobolje"
                        id="otherStaff"
                        name="officials"
                        onChange={setField}
                        type={'number'}
                        value={formData.officials.otherStaff}
                    />
                </Col>
                <Col className="me-md-3 mb-3 mb-md-0">
                    <TextField
                        label="Poznavanje pravila"
                        placeholder="Poznavanje pravila"
                        id="rulesKnowledge"
                        name="officials"
                        onChange={setField}
                        value={formData.officials.rulesKnowledge}
                    />
                </Col>
                <Col md={2}>
                    <TextField
                        label="Ocena"
                        placeholder="Ocena"
                        id="officialsRating"
                        name="officials"
                        onChange={setField}
                        type={'number'}
                        value={formData.officials.officialsRating}
                    />
                </Col>
            </Col>
            <h3 className="tournament-section-title">Objekti za igrače</h3>
            <Col
                className="d-block d-md-flex flex-column flex-md-row mb-4 align-items-end"
                xs={12}
            >
                <Col className="me-md-3 mb-3 mb-md-0">
                    <TextField
                        label="Objekat 1"
                        placeholder="Objekat 1"
                        id="facility_1"
                        name="playersFacilities"
                        onChange={setField}
                        type={'text'}
                        value={formData.playersFacilities.facility_1}
                    />
                </Col>
                <Col className="me-md-3 mb-3 mb-md-0">
                    <TextField
                        label="Objekat 2"
                        placeholder="Objekat 2"
                        id="facility_2"
                        name="playersFacilities"
                        onChange={setField}
                        type={'text'}
                        value={formData.playersFacilities.facility_2}
                    />
                </Col>
                <Col>
                    <TextField
                        label="Objekat 3"
                        placeholder="Objekat 3"
                        id="facility_3"
                        name="playersFacilities"
                        onChange={setField}
                        type={'text'}
                        value={formData.playersFacilities.facility_3}
                    />
                </Col>
            </Col>
        </Row>
    )
}

export default TournamentOrganization
