import jsPDF from 'jspdf'
import { formatDate, handleShowYesNo } from '../../../../../../lib/helpers'
import { font } from '../../font'
const createDoc = (data, orientation, unit, size, font) => {
    const {
        competitionProfileData,
        tournamentsAdditionalInformationsData,
        accommodationData,
        tournamentsEntryPoints,
    } = data

    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size)
    doc.addFileToVFS('WorkSans-normal.ttf', font)
    doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
    doc.setFont('WorkSans')
    doc.setFontSize(15)

    const title = `${
        competitionProfileData?.attributes?.name
    }, početak (${formatDate(competitionProfileData?.attributes?.start_date)})`
    doc.text(title, marginLeft, 40)

    const generalInfo = [
        [
            'Naziv takmičenja',
            competitionProfileData?.attributes?.name || 'Nema informacija',
        ],
        [
            'Šablon bodovanja',
            tournamentsEntryPoints?.attributes?.entry_points_name ||
                'Nema informacija',
        ],
        [
            'Region',
            competitionProfileData?.attributes?.region?.region_name ||
                'Nema informacija',
        ],
        [
            'Tip takmičenja',
            competitionProfileData?.attributes?.tournament_type
                ?.tournament_type_name || 'Nema informacija',
        ],
        [
            'Tip organizovanja takmičenja',
            competitionProfileData?.attributes?.tournament_organisation_type
                ?.tournament_organisation_type_name || 'Nema informacija',
        ],
        [
            'Konkurencija',
            competitionProfileData?.attributes?.players_competition
                ?.players_competition_name || 'Nema informacija',
        ],
        [
            'Takmičarska legitimacija',
            competitionProfileData?.attributes?.players_competition
                ?.players_competition_subcategory_name || 'Nema informacija',
        ],
        [
            'Format',
            competitionProfileData?.attributes?.tournament_format
                ?.tournament_format_name || 'Nema informacija',
        ],
        [
            'Kategorija turnira',
            competitionProfileData?.attributes?.tournament_category
                ?.tournament_category_name || 'Nema informacija',
        ],
        [
            'Potkategorija turnira',
            competitionProfileData?.attributes?.tournament_category
                ?.tournament_subcategory_name || 'Nema informacija',
        ],
        [
            'Očekivani broj takmičara',
            competitionProfileData?.attributes?.participants_expected_number ||
                'Nema informacija',
        ],
        [
            'Očekivani broj dubl parova',
            competitionProfileData?.attributes?.doubles_pairs_expected_number ||
                'Nema informacija',
        ],
        [
            'Broj takmičara u kvalifikacijama',
            competitionProfileData?.attributes
                ?.qualifications_participants_expected_number ||
                'Nema informacija',
        ],
        [
            'Broj kvalifikanata',
            competitionProfileData?.attributes?.number_of_qualified ||
                'Nema informacija',
        ],
        [
            'Broj specijalnih pozivnica (WC)',
            competitionProfileData?.attributes?.wc_number || 'Nema informacija',
        ],
        [
            'Broj specijalnih pozivnica (WCQ)',
            competitionProfileData?.attributes?.wc_qualification_number ||
                'Nema informacija',
        ],
        [
            'Stranci',
            handleShowYesNo(
                tournamentsAdditionalInformationsData?.attributes
                    ?.are_foreigners_allowed
            ) || 'Nema informacija',
        ],
        [
            'Broj stranaca koji se mogu prijaviti',
            tournamentsAdditionalInformationsData?.attributes
                ?.max_number_of_registered_strangers || 'Nema informacija',
        ],
        [
            'Broj stranaca po meču',
            tournamentsAdditionalInformationsData?.attributes
                ?.max_number_of_strangers_per_match || 'Nema informacija',
        ],
        [
            'Samo iz regiona',
            handleShowYesNo(
                tournamentsAdditionalInformationsData?.attributes
                    ?.is_from_region_only
            ) || 'Nema informacija',
        ],
        [
            'Broj pozajmica',
            tournamentsAdditionalInformationsData?.attributes
                ?.max_number_of_loans || 'Nema informacija',
        ],
        [
            'Broj pozajmica po meču',
            tournamentsAdditionalInformationsData?.attributes
                ?.max_number_of_loans_per_match || 'Nema informacija',
        ],
        [
            'Ne mogu učestvovati (sa TSS liste)',
            tournamentsAdditionalInformationsData?.attributes
                ?.tss_rank_prohibition || 'Nema informacija',
        ],
        [
            'Ne mogu učestvovati (sa ITF liste)',
            tournamentsAdditionalInformationsData?.attributes
                ?.itf_rank_prohibition || 'Nema informacija',
        ],
        [
            'Ne mogu učestvovati (sa ETA liste)',
            tournamentsAdditionalInformationsData?.attributes
                ?.eta_rank_prohibition || 'Nema informacija',
        ],
        [
            'Organizator',
            competitionProfileData?.attributes?.club_organizer?.attributes
                ?.name || 'Nema informacija',
        ],
        [
            'Adresa organizatora',
            competitionProfileData?.attributes?.club_organizer?.attributes
                ?.address
                ? competitionProfileData?.attributes?.club_organizer?.attributes
                      ?.address?.street +
                  ', ' +
                  competitionProfileData?.attributes?.club_organizer?.attributes
                      ?.address?.city
                : 'Nema informacija',
        ],
        [
            'Početak takmičenja',
            formatDate(competitionProfileData?.attributes?.start_date) ||
                'Nema informacija',
        ],
        [
            'Kraj takmičenja',
            formatDate(competitionProfileData?.attributes?.end_date) ||
                'Nema informacija',
        ],
        [
            'Krajnji datum za prijavu',
            formatDate(
                competitionProfileData?.attributes?.application_deadline_date
            ) || 'Nema informacija',
        ],
        [
            'Krajnji datum za odjavu',
            formatDate(
                competitionProfileData?.attributes?.checkout_deadline_date
            ) || 'Nema informacija',
        ],
        [
            'Kotizacija (RSD)',
            tournamentsAdditionalInformationsData?.attributes
                ?.registration_fee || 'Nema informacija',
        ],
        [
            'Lopte',
            tournamentsAdditionalInformationsData?.attributes?.ball_brend ||
                'Nema informacija',
        ],
        [
            'Napomena',
            tournamentsAdditionalInformationsData?.attributes
                ?.additional_details || 'Nema informacija',
        ],
    ]

    let generalInfoContent = {
        startY: 50,
        head: [['Opšte informacije', ' ']],
        body: generalInfo,
        styles: {
            font: 'WorkSans',
        },
        columnStyles: {
            0: { cellWidth: 250 },
        },
    }

    doc.autoTable(generalInfoContent)

    // Licenses

    const accommodationInfo = [
        [
            'Adresa',
            accommodationData?.attributes?.address?.street ||
                'Nema informacija',
        ],
        [
            'Grad',
            accommodationData?.attributes?.address?.city || 'Nema informacija',
        ],
        ['Telefon', accommodationData?.attributes?.phone || 'Nema informacija'],
        ['Email', accommodationData?.attributes?.email || 'Nema informacija'],
        [
            'Napomena',
            accommodationData?.attributes?.additional_informations ||
                'Nema informacija',
        ],
    ]

    let accommodationContent = {
        startY: doc.lastAutoTable.finalY + 20,
        head: [['Smeštaj', ' ']],
        body: accommodationInfo,
        styles: {
            font: 'WorkSans',
        },
        columnStyles: {
            0: { cellWidth: 250 },
        },
        pageBreak: 'avoid', //to place accommodation table into new page
    }

    doc.autoTable(accommodationContent)

    return doc
}

export const handleExportInformationTabPDF = (data) => {
    const unit = 'pt'
    const size = 'A4' // Use A1, A2, A3 or A4
    const orientation = 'portrait' // portrait or landscape

    const doc = createDoc(data, orientation, unit, size, font)

    doc.save(`${data?.competitionProfileData?.attributes?.name}.pdf`)
}

export const handlePrintInformationTab = (data) => {
    const unit = 'pt'
    const size = 'A4' // Use A1, A2, A3 or A4
    const orientation = 'portrait' // portrait or landscape

    const doc = createDoc(data, orientation, unit, size, font)

    // for printing
    doc.autoPrint()
    doc.output('dataurlnewwindow')
}
