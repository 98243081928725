import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ForgotPassword from './ForgotPassword'
import Login from './Login'
import NewPassword from './NewPassword'
import MigrationPage from './Migration'
import MigrationRegisterPage from './Migration/MigrationRegisterPage'

const PublicRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/email-invitation-confirm" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/new-password" element={<NewPassword />} />
            <Route path="/kreiraj-nalog" element={<MigrationPage />} />
            <Route
                path="/obnova-starog-naloga/:id"
                element={<MigrationRegisterPage />}
            />
        </Routes>
    )
}

export default PublicRoutes
