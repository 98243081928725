import jsPDF from 'jspdf'
import React, { useEffect, useState } from 'react'
import { Col, Row, Card } from 'react-bootstrap'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { font } from '../../../assets/fonts/font'
import OutlinedFilter from '../../../assets/svg/OutlinedFilter'
import StatisticsCardImage1 from '../../../assets/svg/StatisticsCardImage1'
import StatisticsCardImage2 from '../../../assets/svg/StatisticsCardImage2'
import StatisticsCardImage4 from '../../../assets/svg/StatisticsCardImage4'
import StatisticsCardImage5 from '../../../assets/svg/StatisticsCardImage5'
import Button from '../../../components/Button'
import StatisticsCard from '../../../components/StatisticsCard'
import TextField from '../../../components/TextField'
import { useAppContext } from '../../../lib/contextLib'
import { getStatisticsData } from '../../../lib/api'
import TournamentRoundCard from '../../../components/TournamentRoundCard'
import Slider from 'react-slick'
import { settings } from '../Competitions/CompetitionProfile/Tabs/Draw/carouselSettings'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
} from 'chart.js'
import { Bar, Doughnut, Pie } from 'react-chartjs-2'
import Pill from '../../../components/Pill'
import './styles.scss'
import { formatDate } from '../../../lib/helpers'
import { Link } from 'react-router-dom'

function Statistics() {
    const { user, setIsLoading } = useAppContext()

    const colors = [
        '#e4b6ac',
        '#d9988b',
        '#d18272',
        '#c36451',
        '#b54f3e',
        '#a93d2e',
        '#214B73',
        '#466c8f',
    ]
    const [showFilters, setShowFilters] = useState(false)
    const [filterParams, setFilterParamas] = useState({})
    const [statisticsData, setStatisticsData] = useState({})

    const initStatistics = async (removedFilters = false) => {
        try {
            setIsLoading(true)

            const statistics = await getStatisticsData(
                removedFilters ? {} : filterParams
            )

            const total_clubs_number =
                statistics?.clubs_beograd +
                statistics?.clubs_vojvodina +
                statistics?.clubs_istocna_srbija +
                statistics?.clubs_zapadna_srbija

            setStatisticsData({
                ...statistics,
                total_clubs_number,
            })
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.error('Failed to initialize statistics:', error)
        }
    }

    const handlePdfExport = () => {
        const unit = 'pt'
        const size = 'A4'
        const orientation = 'landscape'

        const marginLeft = 40
        const doc = new jsPDF(orientation, unit, size)
        doc.addFileToVFS('WorkSans-normal.ttf', font)
        doc.addFont('WorkSans-normal.ttf', 'WorkSans', 'normal')
        doc.setFont('WorkSans')
        doc.setFontSize(15)

        doc.text(`Statistika - ${formatDate(new Date()).slice(0, -1)}.`, marginLeft, 40)

        let regionsText = ''

        statisticsData?.regions?.map((region) => {
            regionsText += `${region?.segment}. `
        })

        let statisticsContent = {
            startY: 50,
            head: [['', '']],
            body: [
                [
                    'Aktivni takmičari',
                    (
                        statisticsData?.total_male_players +
                        statisticsData?.total_female_players
                    )?.toString(),
                ],
                ['Klubovi', statisticsData?.total_clubs_number?.toString()],
                ['Sudije', statisticsData?.referees_number?.toString()],
                ['Treneri', statisticsData?.coaches_number?.toString()],
                [
                    'Završena takmičenja',
                    statisticsData?.finished_tournaments_number?.toString(),
                ],
                [
                    'Nezavršena takmičenja',
                    statisticsData?.unfinished_tournaments_number?.toString(),
                ],
                ['Muškarci', statisticsData?.total_male_players],
                ['Žene', statisticsData?.total_female_players],
            ],
            styles: {
                font: 'WorkSans',
            },
            columnStyles: {
                0: { cellWidth: 250 },
            },
        }

        doc.autoTable(statisticsContent)
        doc.save(`Statistika-${formatDate(new Date()).slice(0, -1)}.pdf`)
        setShowFilters(false)
    }

    const handleShowResults = async () => {
        setShowFilters(false)
        initStatistics()
    }

    const handleRemoveFilters = async () => {
        setFilterParamas()
        setShowFilters(false)
        initStatistics(true)
    }

    useEffect(() => {
        initStatistics()
    }, [user])

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        ArcElement,
        Title,
        Tooltip,
        Legend
    )

    const playersOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Broj takmičara po konkurenciji',
            },
        },
    }

    const playersLabels = ['10s', '12', '14', '16', '18', 'Seniori']

    const tournamentsCategoriesOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Broj takmičenja po kategorijama',
            },
        },
    }

    const tournamentsCategoriesLabels = [
        'A',
        'B',
        'TOUR',
        'A/S',
        'A/Z',
        'I',
        'II',
        'III',
        'IV',
    ]

    const dataView = () => {
        return (
            <Col className="mt-2">
                <Row>
                    <Col className="d-none d-md-block">
                        <Slider {...settings(3)}>
                            <TournamentRoundCard
                                roundName="Takmičari"
                                className="me-2"
                                index={0}
                            >
                                <Link to="/takmicari">
                                    <StatisticsCard
                                        title={'Ukupno aktivnih takmičara'}
                                        number={
                                            statisticsData?.total_male_players +
                                            statisticsData?.total_female_players
                                        }
                                        image={<StatisticsCardImage1 />}
                                        className="mb-3"
                                    />
                                </Link>
                                <Link
                                    to="/takmicari"
                                    state={{
                                        activeTab: 'draft',
                                    }}
                                >
                                    <StatisticsCard
                                        title={'Ukupno draft takmičara'}
                                        number={
                                            statisticsData?.number_of_draft_players ||
                                            0
                                        }
                                        image={<StatisticsCardImage4 />}
                                        className="mb-3"
                                    />
                                </Link>
                                <Card>
                                    <Card.Body>
                                        <h3 className="mb-3">Pol</h3>
                                        <Doughnut
                                            data={{
                                                labels: ['Muški', 'Ženski'],
                                                datasets: [
                                                    {
                                                        label: 'Pol',
                                                        data: [
                                                            statisticsData?.total_male_players,
                                                            statisticsData?.total_female_players,
                                                        ],
                                                        backgroundColor: [
                                                            colors[6],
                                                            colors[1],
                                                        ],
                                                        borderWidth: 1,
                                                    },
                                                ],
                                            }}
                                        />
                                    </Card.Body>
                                </Card>
                                <Bar
                                    options={playersOptions}
                                    data={{
                                        labels: playersLabels,
                                        datasets: [
                                            {
                                                label: 'Muški',
                                                data: [
                                                    statisticsData?.male_10_players_number,
                                                    statisticsData?.male_12_players_number,
                                                    statisticsData?.male_14_players_number,
                                                    statisticsData?.male_16_players_number,
                                                    statisticsData?.male_18_players_number,
                                                    statisticsData?.male_100_players_number,
                                                ],
                                                backgroundColor: '#214B73',
                                            },
                                            {
                                                label: 'Ženski',
                                                data: [
                                                    statisticsData?.female_10_players_number,
                                                    statisticsData?.female_12_players_number,
                                                    statisticsData?.female_14_players_number,
                                                    statisticsData?.female_16_players_number,
                                                    statisticsData?.female_18_players_number,
                                                    statisticsData?.female_100_players_number,
                                                ],
                                                backgroundColor: '#d18272',
                                            },
                                        ],
                                    }}
                                />
                            </TournamentRoundCard>

                            <TournamentRoundCard
                                roundName="Takmičenja"
                                className="me-2"
                                index={1}
                            >
                                <StatisticsCard
                                    title={'Završena takmičenja'}
                                    number={statisticsData?.finished_tournaments_number?.toString()}
                                    image={<StatisticsCardImage1 />}
                                    className="mb-3"
                                />
                                <StatisticsCard
                                    title={'Nezavršena takmičenja'}
                                    number={statisticsData?.unfinished_tournaments_number?.toString()}
                                    image={<StatisticsCardImage4 />}
                                    className="mb-3"
                                />
                                {/* <StatisticsCard
                                    title={'Odigranih mečeva'}
                                    number={statisticsData?.total_matches?.toString()}
                                    image={<StatisticsCardImage3 />}
                                    className="mb-3"
                                /> */}

                                <Bar
                                    options={tournamentsCategoriesOptions}
                                    data={{
                                        labels: tournamentsCategoriesLabels,
                                        datasets: [
                                            {
                                                label: '',
                                                data: [
                                                    statisticsData?.A_tournaments_number,
                                                    statisticsData?.B_tournaments_number,
                                                    statisticsData?.TOUR_tournaments_number,
                                                    statisticsData[
                                                        'A/S_tournaments_number'
                                                    ],
                                                    statisticsData[
                                                        'A/Z_tournaments_number'
                                                    ],
                                                    statisticsData?.I_tournaments_number,
                                                    statisticsData?.II_tournaments_number,
                                                    statisticsData?.III_tournaments_number,
                                                    statisticsData?.IV_tournaments_number,
                                                ],
                                                backgroundColor: colors[2],
                                            },
                                        ],
                                    }}
                                />
                            </TournamentRoundCard>

                            <TournamentRoundCard
                                roundName="Klubovi"
                                className="me-2"
                                index={2}
                            >
                                <StatisticsCard
                                    title={'Klubovi'}
                                    number={statisticsData?.total_clubs_number?.toString()}
                                    image={<StatisticsCardImage5 />}
                                    className="mb-3"
                                />
                                <Card>
                                    <Card.Body>
                                        <h3 className="mb-3">
                                            Klubovi po regionima
                                        </h3>
                                        <Pie
                                            data={{
                                                labels: [
                                                    'Beograd',
                                                    'Vojvodina',
                                                    'Istočna Srbija',
                                                    'Zapadna Srbija i Šumadija',
                                                ],
                                                datasets: [
                                                    {
                                                        label: 'Broj klubova',
                                                        data: [
                                                            statisticsData?.clubs_beograd,
                                                            statisticsData?.clubs_vojvodina,
                                                            statisticsData?.clubs_istocna_srbija,
                                                            statisticsData?.clubs_zapadna_srbija,
                                                        ],
                                                        backgroundColor: [
                                                            colors[2],
                                                            colors[7],
                                                            colors[4],
                                                            colors[6],
                                                        ],
                                                        borderWidth: 1,
                                                    },
                                                ],
                                            }}
                                        />
                                    </Card.Body>
                                </Card>
                            </TournamentRoundCard>
                            <TournamentRoundCard roundName="Ostalo" index={3}>
                                <StatisticsCard
                                    title={'Sudije'}
                                    number={statisticsData?.referees_number?.toString()}
                                    image={<StatisticsCardImage2 />}
                                    className="mb-3"
                                />
                                <StatisticsCard
                                    title={'Treneri'}
                                    number={statisticsData?.coaches_number?.toString()}
                                    image={<StatisticsCardImage5 />}
                                    className="mb-3"
                                />
                            </TournamentRoundCard>
                        </Slider>
                    </Col>
                    <Col className="d-block d-md-none">
                        <Slider {...settings(1)}>
                            <TournamentRoundCard
                                roundName="Takmičari"
                                className="me-2"
                                index={0}
                            >
                                <Link to="/takmicari">
                                    <StatisticsCard
                                        title={'Ukupno aktivnih takmičara'}
                                        number={
                                            statisticsData?.total_male_players +
                                            statisticsData?.total_female_players
                                        }
                                        image={<StatisticsCardImage1 />}
                                        className="mb-3"
                                    />
                                </Link>
                                <Link
                                    to="/takmicari"
                                    state={{
                                        activeTab: 'draft',
                                    }}
                                >
                                    <StatisticsCard
                                        title={'Ukupno draft takmičara'}
                                        number={
                                            statisticsData?.number_of_draft_players ||
                                            0
                                        }
                                        image={<StatisticsCardImage4 />}
                                        className="mb-3"
                                    />
                                </Link>
                                <Card>
                                    <Card.Body>
                                        <h3 className="mb-3">Pol</h3>
                                        <Doughnut
                                            data={{
                                                labels: ['Muški', 'Ženski'],
                                                datasets: [
                                                    {
                                                        label: 'Pol',
                                                        data: [
                                                            statisticsData?.total_male_players,
                                                            statisticsData?.total_female_players,
                                                        ],
                                                        backgroundColor: [
                                                            colors[6],
                                                            colors[1],
                                                        ],
                                                        borderWidth: 1,
                                                    },
                                                ],
                                            }}
                                        />
                                    </Card.Body>
                                </Card>
                                <Bar
                                    options={playersOptions}
                                    data={{
                                        labels: playersLabels,
                                        datasets: [
                                            {
                                                label: 'Muški',
                                                data: [
                                                    statisticsData?.male_10_players_number,
                                                    statisticsData?.male_12_players_number,
                                                    statisticsData?.male_14_players_number,
                                                    statisticsData?.male_16_players_number,
                                                    statisticsData?.male_18_players_number,
                                                    statisticsData?.male_100_players_number,
                                                ],
                                                backgroundColor: '#214B73',
                                            },
                                            {
                                                label: 'Ženski',
                                                data: [
                                                    statisticsData?.female_10_players_number,
                                                    statisticsData?.female_12_players_number,
                                                    statisticsData?.female_14_players_number,
                                                    statisticsData?.female_16_players_number,
                                                    statisticsData?.female_18_players_number,
                                                    statisticsData?.female_100_players_number,
                                                ],
                                                backgroundColor: '#d18272',
                                            },
                                        ],
                                    }}
                                />
                            </TournamentRoundCard>

                            <TournamentRoundCard
                                roundName="Takmičenja"
                                className="me-2"
                                index={1}
                            >
                                <StatisticsCard
                                    title={'Završena takmičenja'}
                                    number={statisticsData?.finished_tournaments_number?.toString()}
                                    image={<StatisticsCardImage1 />}
                                    className="mb-3"
                                />
                                <StatisticsCard
                                    title={'Nezavršena takmičenja'}
                                    number={statisticsData?.unfinished_tournaments_number?.toString()}
                                    image={<StatisticsCardImage4 />}
                                    className="mb-3"
                                />
                                {/* <StatisticsCard
                                    title={'Odigranih mečeva'}
                                    number={statisticsData?.total_matches}
                                    image={<StatisticsCardImage3 />}
                                    className="mb-3"
                                /> */}
                                <Row className="mt-3">
                                    <Col>
                                        <Bar
                                            options={
                                                tournamentsCategoriesOptions
                                            }
                                            data={{
                                                labels: tournamentsCategoriesLabels,
                                                datasets: [
                                                    {
                                                        label: '',
                                                        data: [
                                                            statisticsData?.A_tournaments_number,
                                                            statisticsData?.B_tournaments_number,
                                                            statisticsData?.TOUR_tournaments_number,
                                                            statisticsData[
                                                                'A/S_tournaments_number'
                                                            ],
                                                            statisticsData[
                                                                'A/Z_tournaments_number'
                                                            ],
                                                            statisticsData?.I_tournaments_number,
                                                            statisticsData?.II_tournaments_number,
                                                            statisticsData?.III_tournaments_number,
                                                            statisticsData?.IV_tournaments_number,
                                                        ],
                                                        backgroundColor:
                                                            colors[2],
                                                    },
                                                ],
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </TournamentRoundCard>

                            <TournamentRoundCard
                                roundName="Klubovi"
                                className="me-2"
                                index={2}
                            >
                                <StatisticsCard
                                    title={'Klubovi'}
                                    number={statisticsData?.total_clubs_number?.toString()}
                                    image={<StatisticsCardImage5 />}
                                    className="mb-3"
                                />
                                <Card>
                                    <Card.Body>
                                        <h3 className="mb-3">
                                            Klubovi po regionima
                                        </h3>
                                        <Pie
                                            data={{
                                                labels: [
                                                    'Beograd',
                                                    'Vojvodina',
                                                    'Istočna Srbija',
                                                    'Zapadna Srbija i Šumadija',
                                                ],
                                                datasets: [
                                                    {
                                                        label: 'Broj klubova',
                                                        data: [
                                                            statisticsData?.clubs_beograd,
                                                            statisticsData?.clubs_vojvodina,
                                                            statisticsData?.clubs_istocna_srbija,
                                                            statisticsData?.clubs_zapadna_srbija,
                                                        ],
                                                        backgroundColor: [
                                                            colors[2],
                                                            colors[7],
                                                            colors[4],
                                                            colors[6],
                                                        ],
                                                        borderWidth: 1,
                                                    },
                                                ],
                                            }}
                                        />
                                    </Card.Body>
                                </Card>
                            </TournamentRoundCard>
                            <TournamentRoundCard roundName="Ostalo" index={3}>
                                <StatisticsCard
                                    title={'Sudije'}
                                    number={statisticsData?.referees_number?.toString()}
                                    image={<StatisticsCardImage2 />}
                                    className="mb-3"
                                />
                                <StatisticsCard
                                    title={'Treneri'}
                                    number={statisticsData?.coaches_number?.toString()}
                                    image={<StatisticsCardImage5 />}
                                    className="mb-3"
                                />
                            </TournamentRoundCard>
                        </Slider>
                    </Col>
                </Row>
            </Col>
        )
    }

    return (
        <Col className="statistics-wrapper pt-3">
            <Col className="d-flex justify-content-end align-items-center">
                <Button
                    type="secondary"
                    className="d-none d-md-flex"
                    onClick={() => setShowFilters(true)}
                >
                    Filtriraj
                </Button>
                <Button
                    type="text"
                    className="d-flex d-md-none"
                    onClick={() => setShowFilters(true)}
                >
                    <OutlinedFilter />
                </Button>
            </Col>
            <Row className="mt-3">
                <Col className="d-flex">
                    {filterParams?.period_from && (
                        <Pill type="default" className={'me-2'}>
                            <p>od {formatDate(filterParams?.period_from)}</p>
                        </Pill>
                    )}
                    {filterParams?.period_to && (
                        <Pill type="default">
                            <p>do {formatDate(filterParams?.period_to)}</p>
                        </Pill>
                    )}
                </Col>
            </Row>
            {dataView()}
            <Offcanvas
                show={showFilters}
                onHide={() => setShowFilters(false)}
                placement="end"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <h3>Filtriraj</h3>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <hr className="p-0 mt-2 mb-2" />
                <Offcanvas.Body>
                    <Row>
                        <Col md={12}>
                            <h4>Period za filtriranje</h4>
                            <TextField
                                className="mt-3"
                                type="date"
                                value={filterParams?.period_from || ''}
                                label="Datum od"
                                placeholder="Datum od"
                                onChange={(e) =>
                                    setFilterParamas((prev) => ({
                                        ...prev,
                                        period_from: e.target.value,
                                    }))
                                }
                            />
                            <TextField
                                className="mt-3"
                                type="date"
                                value={filterParams?.period_to || ''}
                                label="Datum do"
                                placeholder="Datum do"
                                onChange={(e) =>
                                    setFilterParamas((prev) => ({
                                        ...prev,
                                        period_to: e.target.value,
                                    }))
                                }
                            />
                        </Col>

                        <Col md={12} className="mt-5">
                            <Button
                                className="w-100"
                                onClick={handleShowResults}
                            >
                                Prikaži rezultate
                            </Button>
                        </Col>
                        <Col md={12} className="mt-3">
                            <Button
                                type="secondary"
                                className="w-100"
                                onClick={handlePdfExport}
                            >
                                Štampaj rezultate
                            </Button>
                        </Col>
                        <Col md={12} className="mt-3">
                            <Button
                                type="text"
                                className="w-100"
                                onClick={handleRemoveFilters}
                            >
                                Ukloni filtriranje
                            </Button>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>
        </Col>
    )
}

export default Statistics
