import React from 'react'
import { Col, Row } from 'react-bootstrap'
import TextArea from '../../TextArea'

const StepSix = ({ newClub, updateClub }) => {
    return (
        <Row className="step-six-wrapper">
            <form>
                <Col md={12}>
                    <TextArea
                        id="club_history"
                        name="club_history"
                        label="Istorijat kluba"
                        onChange={updateClub}
                        value={newClub?.club_history || ''}
                        maxLength={600}
                        className="club-description-textarea"
                    />
                    <p className="small-text club-history-max-length ms-3">
                        Unesite tekst do 600 karaktera
                    </p>
                </Col>
            </form>
        </Row>
    )
}

export default StepSix
