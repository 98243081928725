import { Card, Col, Row } from 'react-bootstrap'

import '../styles.scss'

const AssemblyClubCard = ({ isWhite, result, title_from_to }) => {
    return (
        <Card
            className={`club-card ${
                isWhite ? 'white-club-card' : 'grey-club-card'
            }`}
        >
            <Card.Body className="py-0">
                <Row>
                    <Col>
                        <Row className="car-row align-items-center">
                            <Col>
                                <h4>
                                    Broj {title_from_to?.toLowerCase()} (od)
                                </h4>
                            </Col>
                            <Col>
                                <p>{result?.number_from}</p>
                            </Col>
                        </Row>

                        <Row className="car-row align-items-center">
                            <Col>
                                <h4>
                                    Broj {title_from_to?.toLowerCase()} (do)
                                </h4>
                            </Col>
                            <Col>
                                <p>{result?.number_to}</p>
                            </Col>
                        </Row>

                        <Row className="car-row align-items-center">
                            <Col>
                                <h4>Glasova</h4>
                            </Col>
                            <Col>
                                <p>{result?.votes}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default AssemblyClubCard
