import { Row, Col, Card } from 'react-bootstrap'
import Button from '../Button'

import './styles.scss'

const IconInfoCard = ({
    className,
    icon,
    title,
    subtitle,
    children,
    buttonText,
    onClick,
    isImage,
}) => {
    return (
        <Card className={`info-card ${className}`}>
            <Card.Body className="p-0">
                <Row>
                    <Col sm={12} className="d-flex flex-column">
                        <div className="d-flex info-card-content-wrapper">
                            <div
                                className={`${isImage ? 'card-photo' : 'icon'}`}
                            >
                                {icon}
                            </div>
                            <Row className="w-100 info-card-content">
                                <Col sm={12}>
                                    <h3 className="mb-4">{title}</h3>
                                    {subtitle && (
                                        <p
                                            className={`subtitle ${
                                                children
                                                    ? 'subtitle-padding'
                                                    : ''
                                            }`}
                                        >
                                            {subtitle}
                                        </p>
                                    )}
                                    {children}
                                </Col>
                                {buttonText && (
                                    <Col
                                        sm={12}
                                        className="d-flex justify-content-end mt-4"
                                    >
                                        <Button onClick={onClick} type="text">
                                            {buttonText}
                                        </Button>
                                    </Col>
                                )}
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default IconInfoCard
