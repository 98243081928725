import React from 'react'
import { Col, Row } from 'react-bootstrap'
import DownloadCloudOutlined from '../../../assets/svg/DownloadCloudOutlined'
import { formatDate, formatImgUrl } from '../../../lib/helpers'
import { downloadImage } from '../../../utils/downloadimage'
import Button from '../../Button'
import './styles.scss'

function MedicalHistoryCard({ medical, profileData, className, isWhite }) {
    return (
        <Row
            className={`medical-history-card-wrapper g-3 ${className} ${
                isWhite ? 'white-history-card' : 'grey-history-card'
            }`}
        >
            <Col xs={6} className="mt-3 d-flex align-items-center">
                <h5 className={`medical-header`}>Datum obavljenog pregleda</h5>
            </Col>
            <Col xs={6} className="d-flex align-items-center p-0 mt-3">
                <p className="medical-info-text">
                    {formatDate(medical?.attributes?.start_date)}
                </p>
            </Col>
            <hr className="table-card-divider" />
            <Col xs={6} className="m-0 d-flex align-items-center">
                <h5 className={`medical-header`}>Lekarski pregled traje do</h5>
            </Col>
            <Col xs={6} className="d-flex align-items-center p-0 m-0">
                <p className="medical-info-text">
                    {formatDate(medical?.attributes?.end_date)}
                </p>
            </Col>
            <hr className="table-card-divider" />
            <Col xs={6} className="m-0 d-flex align-items-center mb-3">
                <h5 className={`medical-header`}>Slika</h5>
            </Col>
            <Col xs={6} className={`d-flex align-items-center p-0 m-0 mb-3`}>
                <Button
                    type="text"
                    onClick={() =>
                        downloadImage(
                            formatImgUrl(
                                medical?.attributes?.attachment?.data
                                    ?.attributes?.url
                            ),
                            `${profileData?.attributes?.person_info?.given_name}_${profileData?.attributes?.person_info?.family_name}_medicalID_${medical?.id}`,
                            medical?.attributes?.attachment?.data?.attributes
                                ?.ext
                        )
                    }
                >
                    <DownloadCloudOutlined width="24px" height="24px" />
                </Button>
            </Col>
        </Row>
    )
}

export default MedicalHistoryCard
