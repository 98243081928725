import { useState } from 'react'
import { Breadcrumb, Card, Col, Row, Tab, Tabs } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Email from '../../../assets/svg/Email'
import Home from '../../../assets/svg/Home'
import IconInfoCard from '../../../components/IconInfoCard'
import { useAppContext } from '../../../lib/contextLib'
// Icons, Images
import Flag from '../../../assets/svg/Flag'
import Person from '../../../assets/svg/Person'

import Call from '../../../assets/svg/Call'
import Wallet from '../../../assets/svg/Wallet'

import './styles.scss'

import ProfileCard from '../../../components/ProfileCard'

import ArrowRight from '../../../assets/svg/ArrowRight'
import Button from '../../../components/Button'
import MedicalHistory from '../../../components/MedicalHistory'
import { formatDate, isArrayEmpty } from '../../../lib/helpers'
import { generateAvatar } from '../../../utils/generateAvatar'

const RefereeAdminProfile = () => {
    const { user } = useAppContext()

    const { data } = user
    const [selectedTab, setSelectedTab] = useState('profil')
    const [showMedicalHistory, setShowMedicalHistory] = useState(false)

    return (
        <Row className="admin-referee-profile-wrapper h-100">
            {!showMedicalHistory ? (
                <>
                    <Col md={12}>
                        <Row className="pt-3">
                            <Col xs={12} md={6} className="d-flex flex-column">
                                <h2 className="d-none d-md-block">
                                    {`${data?.attributes?.person_info?.given_name} ${data?.attributes?.person_info?.family_name}`}{' '}
                                </h2>
                                <p className="d-block d-md-none">{`${data?.attributes?.person_info?.given_name} ${data?.attributes?.person_info?.family_name}`}</p>
                                <Breadcrumb className="mt-md-2 mt-1">
                                    <Breadcrumb.Item
                                        linkAs={Link}
                                        linkProps={{
                                            to: '/administrator-sudjenja/pocetna',
                                        }}
                                    >
                                        Početna
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item active>
                                        {`${data?.attributes?.person_info?.given_name} ${data?.attributes?.person_info?.family_name}`}{' '}
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>
                        <Row className="m-0 card-profile">
                            <ProfileCard
                                name={`${data?.attributes?.person_info?.given_name} ${data?.attributes?.person_info?.family_name}`}
                                email={data?.attributes?.person_info?.email}
                                avatar={
                                    data?.attributes?.person_info?.picture
                                        ? data?.attributes?.person_inf?.picture
                                        : generateAvatar(
                                              `${data?.attributes?.person_info?.given_name} ${data?.attributes?.person_info?.family_name}`
                                          )
                                }
                                className="referee-admin-background"
                            />
                            <Col
                                md={12}
                                className="pt-0 pt-md-4 d-flex align-items-center justify-content-start justify-content-md-end"
                            >
                                <Tabs
                                    id="uncontrolled-tab-example"
                                    activeKey={selectedTab}
                                    onSelect={(tab) => setSelectedTab(tab)}
                                >
                                    <Tab
                                        eventKey="profil"
                                        title={
                                            <>
                                                <Person width="20px" />
                                                <p className="ms-2">Profil</p>
                                            </>
                                        }
                                        tabClassName="d-flex"
                                    ></Tab>
                                </Tabs>
                            </Col>
                        </Row>
                        {/* Kartice */}
                        <Row className="mt-4 m-0 layout-gap">
                            <Col xs={12} md className="px-0">
                                <IconInfoCard
                                    icon={<Call />}
                                    title="Kontakt"
                                    className="mb-2 mb-md-3"
                                >
                                    <span className="mb-3 d-flex align-items-center">
                                        <Home className="small-icon me-2" />
                                        <h5 className="me-1">Adresa:</h5>
                                        <p className="card-title-color">
                                            {data?.attributes
                                                ?.residential_address?.street
                                                ? data?.attributes
                                                      ?.residential_address
                                                      ?.street +
                                                  ', ' +
                                                  data?.attributes
                                                      ?.residential_address
                                                      ?.city
                                                : 'Nema informacija'}
                                        </p>
                                    </span>
                                    <span className="mb-3 d-flex align-items-center">
                                        <Call className="small-icon me-2" />
                                        <h5 className="me-1">Telefon:</h5>
                                        <p className="card-title-color">
                                            {data?.attributes?.person_info
                                                ?.phone
                                                ? data?.attributes?.person_info
                                                      ?.phone
                                                : 'Nema informacija'}
                                        </p>
                                    </span>
                                    <span className="d-flex align-items-center">
                                        <Email className="small-icon me-2" />
                                        <h5 className="me-1">Email:</h5>
                                        <p className="link card-title-color pb-1">
                                            {data?.attributes?.person_info
                                                ?.email
                                                ? data?.attributes?.person_info
                                                      ?.email
                                                : 'Nema informacija'}
                                        </p>
                                    </span>
                                </IconInfoCard>
                                <IconInfoCard
                                    className="mb-2 mb-md-3"
                                    icon={<Person />}
                                    title="Opšte informacije"
                                >
                                    <span className="mb-3 d-flex align-items-center">
                                        <h5 className="me-1">Ime i prezime:</h5>
                                        <p className="card-title-color">
                                            {`${data?.attributes?.person_info?.given_name} ${data?.attributes?.person_info?.family_name}`}
                                        </p>
                                    </span>
                                    <span className="mb-3 d-flex align-items-center">
                                        <h5 className="me-1">Datum rođenja:</h5>
                                        <p className="card-title-color">
                                            {data?.attributes?.person_info
                                                ?.birthdate
                                                ? formatDate(
                                                      data?.attributes
                                                          ?.person_info
                                                          ?.birthdate
                                                  )
                                                : 'Nema informacija'}
                                        </p>
                                    </span>
                                    <span className="mb-3 d-flex align-items-center">
                                        <h5 className="me-1">JMBG:</h5>
                                        <p className="card-title-color">
                                            {data?.attributes?.person_info?.jmbg
                                                ? data?.attributes?.person_info
                                                      ?.jmbg
                                                : 'Nema informacija'}
                                        </p>
                                    </span>
                                    <span className="d-flex mb-3 align-items-center">
                                        <h5 className="me-1">Mesto rođenja:</h5>
                                        <p className="card-title-color">
                                            {data?.attributes?.birth_address
                                                ?.city
                                                ? data?.attributes
                                                      ?.birth_address?.city
                                                : 'Nema informacija'}
                                        </p>
                                    </span>
                                    <span className="d-flex align-items-center">
                                        <h5 className="me-1">Pol:</h5>
                                        <p className="card-title-color">
                                            {data?.attributes?.gender
                                                ? data?.attributes?.gender
                                                      ?.gender_name
                                                : 'Nema informacija'}
                                        </p>
                                    </span>
                                </IconInfoCard>

                                <Card className="border-0">
                                    <Card.Body className="admin-region-card">
                                        <h2 className="mb-2">
                                            {data?.attributes?.region
                                                ? data?.attributes?.region
                                                      ?.region_name
                                                : 'Nema informacija'}
                                        </h2>
                                        <p className="mb-2 card-title-color">
                                            Region
                                        </p>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col xs={12} md className="px-0">
                                <IconInfoCard
                                    icon={<Flag />}
                                    title="Licenca"
                                    className="mb-2 mb-md-3"
                                >
                                    {data?.attributes?.license ===
                                        'Medjunarodni' && (
                                        <>
                                            <span className="mb-3 d-flex align-items-center">
                                                <h5 className="me-1">
                                                    Licenca:
                                                </h5>
                                                <p className="card-title-color">
                                                    {data?.attributes?.license}
                                                </p>
                                            </span>
                                            {data?.attributes
                                                ?.is_main_referee ||
                                            data?.attributes
                                                ?.is_chair_referee ? (
                                                <span className="mb-3 d-flex align-items-center">
                                                    <h5 className="me-1">
                                                        Tip sudije:
                                                    </h5>
                                                    <p className="card-title-color">
                                                        {data?.attributes
                                                            ?.is_main_referee &&
                                                        data?.attributes
                                                            ?.is_chair_referee
                                                            ? 'Glavni sudija, Sudija u stolici'
                                                            : ''}
                                                        {data?.attributes
                                                            ?.is_main_referee &&
                                                            !data?.attributes
                                                                ?.is_chair_referee &&
                                                            'Glavni sudija'}
                                                        {data?.is_main_referee &&
                                                            data?.attributes
                                                                ?.is_chair_referee &&
                                                            'Sudija u stolici'}
                                                    </p>
                                                </span>
                                            ) : null}
                                            {data?.attributes?.badge && (
                                                <span className="mb-3 d-flex align-items-center">
                                                    <h5 className="me-1">
                                                        Bedž glavnog sudije:
                                                    </h5>
                                                    <p className="card-title-color">
                                                        {
                                                            data?.attributes
                                                                ?.badge
                                                        }
                                                    </p>
                                                </span>
                                            )}
                                            {data?.attributes?.license_date && (
                                                <span className="mb-3 d-flex align-items-center">
                                                    <h5 className="me-1">
                                                        Datum sticanje licence
                                                        glavnog sudije:
                                                    </h5>
                                                    <p className="card-title-color">
                                                        {formatDate(
                                                            data?.attributes
                                                                ?.license_date
                                                        )}
                                                    </p>
                                                </span>
                                            )}
                                            {data?.attributes?.chair_badge && (
                                                <span className="mb-3 d-flex align-items-center">
                                                    <h5 className="me-1">
                                                        Bedž sudije u stolici:
                                                    </h5>
                                                    <p className="card-title-color">
                                                        {
                                                            data?.attributes
                                                                ?.chair_badge
                                                        }
                                                    </p>
                                                </span>
                                            )}
                                            {data?.attributes
                                                ?.license_date_chair_referee && (
                                                <span className="mb-3 d-flex align-items-center">
                                                    <h5 className="me-1">
                                                        Datum sticanje licence
                                                        sudije u stolici:
                                                    </h5>
                                                    <p className="card-title-color">
                                                        {formatDate(
                                                            data?.attributes
                                                                ?.license_date_chair_referee
                                                        )}
                                                    </p>
                                                </span>
                                            )}
                                        </>
                                    )}
                                    {data?.attributes?.license ===
                                        'Nacionalni' && (
                                        <>
                                            <span className="mb-3 d-flex align-items-center">
                                                <h5 className="me-1">
                                                    Licenca:
                                                </h5>
                                                <p className="card-title-color">
                                                    {data?.attributes?.license}
                                                </p>
                                            </span>
                                            <span className="mb-3 d-flex align-items-center">
                                                <h5 className="me-1">
                                                    Tip licence:
                                                </h5>
                                                <p className="card-title-color">
                                                    {
                                                        data?.attributes
                                                            ?.license_type
                                                    }
                                                </p>
                                            </span>
                                            <span className="mb-3 d-flex align-items-center">
                                                <h5 className="me-1">
                                                    Datum poslednjeg testiranja:
                                                </h5>
                                                <p className="card-title-color">
                                                    {formatDate(
                                                        data?.attributes
                                                            ?.last_test_date
                                                    )}
                                                </p>
                                            </span>
                                            <span className="mb-3 d-flex align-items-center">
                                                <h5 className="me-1">
                                                    Rezultat testiranja:
                                                </h5>
                                                <p className="card-title-color">
                                                    {
                                                        data?.attributes
                                                            ?.test_result
                                                    }
                                                </p>
                                            </span>
                                        </>
                                    )}
                                    {data?.attributes?.license ===
                                        'Koordinator' && (
                                        <>
                                            <span className="mb-3 d-flex align-items-center">
                                                <h5 className="me-1">
                                                    Licenca:
                                                </h5>
                                                <p className="card-title-color">
                                                    {data?.attributes?.license}
                                                </p>
                                            </span>

                                            <span className="mb-3 d-flex align-items-center">
                                                <h5 className="me-1">
                                                    Datum poslednjeg testiranja:
                                                </h5>
                                                <p className="card-title-color">
                                                    {formatDate(
                                                        data?.attributes
                                                            ?.cordinator_last_test_date
                                                    )}
                                                </p>
                                            </span>
                                            <span className="mb-3 d-flex align-items-center">
                                                <h5 className="me-1">
                                                    Rezultat testiranja:
                                                </h5>
                                                <p className="card-title-color">
                                                    {
                                                        data?.attributes
                                                            ?.cordinator_test_result
                                                    }
                                                </p>
                                            </span>
                                            <span className="mb-3 d-flex align-items-center">
                                                <h5 className="me-1">Klub:</h5>
                                                <p className="card-title-color">
                                                    {data?.attributes?.club}
                                                </p>
                                            </span>
                                        </>
                                    )}
                                </IconInfoCard>

                                <Row className="m-0 layout-gap mb-2 mb-md-3">
                                    <Col md className="px-0">
                                        <IconInfoCard
                                            icon={<Wallet />}
                                            className="small-card-wrapper"
                                            title="Tekući račun"
                                        >
                                            <h5 className="account-card">
                                                {data?.attributes?.bank_name}
                                            </h5>
                                            <p className="mb-3">Naziv banke</p>
                                            <h5 className="me-1 account-card">
                                                {
                                                    data?.attributes
                                                        ?.account_number
                                                }
                                            </h5>
                                            <p className="card-title-color">
                                                Broj računa
                                            </p>
                                        </IconInfoCard>
                                    </Col>
                                </Row>

                                <Card className="competitor-flow-profile-card-wrapper">
                                    <Card.Body className="competitor-flow-profile-card-body membership-card">
                                        <Row className="pb-5">
                                            <Col className="d-flex align-items-center">
                                                <h2>Lekarski pregled</h2>
                                            </Col>
                                        </Row>
                                        {!isArrayEmpty(
                                            data?.attributes?.medical_history
                                        ) ? (
                                            <Row>
                                                <Col className="membership-view">
                                                    {data?.attributes?.medical_history
                                                        .slice(0, 3)
                                                        .map(
                                                            (
                                                                medical,
                                                                index
                                                            ) => (
                                                                <Col
                                                                    key={index}
                                                                    className={`d-flex align-items-center justify-content-between single-membership`}
                                                                >
                                                                    <Col>
                                                                        <p
                                                                            className={`membership-year`}
                                                                        >
                                                                            {`${
                                                                                index +
                                                                                1
                                                                            }. ${formatDate(
                                                                                medical?.start_date
                                                                            )} - ${formatDate(
                                                                                medical?.end_date
                                                                            )}`}
                                                                        </p>
                                                                    </Col>
                                                                </Col>
                                                            )
                                                        )}
                                                </Col>
                                            </Row>
                                        ) : (
                                            <Row>
                                                <Col className="membership-view">
                                                    <p className="membership-year">
                                                        Nema istorije
                                                    </p>
                                                </Col>
                                            </Row>
                                        )}
                                    </Card.Body>
                                    <Card.Footer className="competitor-flow-profile-card-footer">
                                        <Row>
                                            <Col className="d-flex align-items-center justify-content-end">
                                                <Button
                                                    type="text"
                                                    endIcon={
                                                        <ArrowRight
                                                            disabled={isArrayEmpty(
                                                                data?.attributes
                                                                    ?.medical_history
                                                            )}
                                                        />
                                                    }
                                                    onClick={() =>
                                                        setShowMedicalHistory(
                                                            true
                                                        )
                                                    }
                                                    disabled={isArrayEmpty(
                                                        data?.attributes
                                                            ?.medical_history
                                                    )}
                                                >
                                                    Detaljnije
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </>
            ) : (
                <MedicalHistory
                    medicalHistoryList={data?.attributes?.medical_history}
                    profileData={data}
                    userType="referee"
                    setShowMedicalHistory={setShowMedicalHistory}
                />
            )}
        </Row>
    )
}

export default RefereeAdminProfile
