import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import logoTss from '../../assets/images/logo-longer.png'
import PlayersMigration from './PlayersMigration'

const MigrationPage = () => {
    return (
        <Row className="mt-5 forgot-password-wrapper">
            <Col md={3}></Col>
            <Col md={6}>
                <Row>
                    <Col
                        md={12}
                        className="d-flex align-items-center justify-content-center"
                    >
                        <Link to={'/'}>
                            <img
                                src={logoTss}
                                alt="logo-tss"
                                className="tss-logo"
                            />
                        </Link>
                    </Col>
                    <Col
                        md={12}
                        className="d-flex header align-items-center justify-content-center text-center"
                    >
                        <h1>Kreiranje naloga</h1>
                    </Col>
                    <Col md={12} className="mt-2 mb-2">
                        <PlayersMigration />
                    </Col>

                    <Col
                        md={12}
                        className="logo-below mt-5 pt-5 d-flex align-items-center justify-content-center"
                    >
                        <p className="chainapp-text">
                            Razvija:{' '}
                            <a
                                href="https://chainapp.tech/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Chainapp Technologies doo
                            </a>
                        </p>{' '}
                    </Col>
                </Row>
            </Col>
            <Col md={3}></Col>
        </Row>
    )
}

export default MigrationPage
