import Form from 'react-bootstrap/Form'
import './styles.scss'

const TextArea = (props) => {
    const { id, label, rows, required, className, ...inputProps } = props
    return (
        <Form.Group className={`mb-3 text-area-wrapper ${className}`}>
            <Form.Label htmlFor={id} className="me-1 d-flex">
                <h5 className="me-1">{label}</h5>
                {required && <h6 className="required">*</h6>}
            </Form.Label>
            <Form.Control id={id} as="textarea" rows={rows} {...inputProps} />
        </Form.Group>
    )
}

export default TextArea
