import './styles.scss'

const Avatar = ({ img, alt, className }) => {
    return (
        <div className={`avatar-img ${className}`}>
            <img src={`${img}`} alt={alt} />
        </div>
    )
}

export default Avatar
