import { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import NoDataForView from '../../../../../components/NoDataForView'
import TournamentsTable from '../../../../../components/TournamentsTable'
import TournamentsTableCard from '../../../../../components/TournamentsTableCard'
import { isArrayEmpty } from '../../../../../lib/helpers'
import './styles.scss'

const TournamentsTab = ({
    customActiveTab,
    currentPage,
    setCurrentPage,
    pageCount,
    total,
    comingCompetitions,
    finishedCompetitions,
    setTournamentsTabSelectedSubTab,
    getAllFinishedCompetitions,
    getComingCompetitions,
    rowsPerPage,
    setRowsPerPage,
}) => {
    const [selectedTab, setSelectedTab] = useState(customActiveTab)

    useEffect(() => {
        selectedTab === 'nadolazeca'
            ? getComingCompetitions(currentPage, 1)
            : getAllFinishedCompetitions(currentPage, 1)
    }, [currentPage, selectedTab, rowsPerPage])

    return (
        <Row className="tournaments-tab-wrapper">
            <Col className="p-0 tabular-navigation">
                <Tabs
                    id="uncontrolled-tab-example"
                    activeKey={selectedTab}
                    onSelect={(tab) => {
                        setSelectedTab(tab)
                        setTournamentsTabSelectedSubTab(tab)
                    }}
                >
                    <Tab eventKey="nadolazeca" title="Nadolazeća">
                        <Row className="d-none d-md-block">
                            <Col>
                                <TournamentsTable
                                    data={comingCompetitions}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    pageCount={pageCount}
                                    total={total}
                                    rowsPerPage={rowsPerPage}
                                    setRowsPerPage={setRowsPerPage}
                                />
                            </Col>
                        </Row>
                        <Row className="d-block d-md-none">
                            <Col>
                                {!isArrayEmpty(comingCompetitions) ? (
                                    comingCompetitions?.map((result, index) => (
                                        <TournamentsTableCard
                                            key={index}
                                            result={result}
                                            isWhite={index % 2 !== 0}
                                        />
                                    ))
                                ) : (
                                    <NoDataForView
                                        title={`Nema nadolazećih takmičenja`}
                                        description={`Ovde je prikazana lista nadolazećih takmičenja.`}
                                        className="border-0"
                                        column
                                    />
                                )}
                            </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="zavrsena" title="Završena">
                        <Row className="d-none d-md-block">
                            <Col>
                                <TournamentsTable
                                    data={finishedCompetitions}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    pageCount={pageCount}
                                    total={total}
                                    rowsPerPage={rowsPerPage}
                                    setRowsPerPage={setRowsPerPage}
                                    finished
                                />
                            </Col>
                        </Row>
                        <Row className="d-block d-md-none mt-3">
                            <Col>
                                {!isArrayEmpty(finishedCompetitions) ? (
                                    finishedCompetitions?.map(
                                        (result, index) => (
                                            <TournamentsTableCard
                                                key={index}
                                                result={result}
                                                isWhite={index % 2 !== 0}
                                                className="d-md-none"
                                                finished={true}
                                            />
                                        )
                                    )
                                ) : (
                                    <NoDataForView
                                        title={`Nema završenih takmičenja`}
                                        description={`Ovde je prikazana lista završenih takmičenja.`}
                                        className="border-0"
                                        column
                                    />
                                )}
                            </Col>
                        </Row>
                    </Tab>
                </Tabs>
            </Col>
        </Row>
    )
}

export default TournamentsTab
