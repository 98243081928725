import React, { useEffect, useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import Button from '../../../../../../../components/Button'
import {
    getParticipantsOfGroup,
    getSinglePlayer,
    getSingleParticipant,
} from '../../../../../../../lib/api'
import GroupParticipantCard from '../../../../../../../components/GroupParticipantCard'
import AddPlayerToGroupModal from './addPlayerToGroupModal'
import TournamentMatchCard from '../../../../../../../components/TournamentMatchCard'
import {
    createTournamentMatch,
    deleteMatch,
    getGroupMatches,
    calculateSeed,
} from '../../../../../../../lib/api'
import NotificationModal from '../../../../../../../components/GroupParticipantCard/notificationModal'
import DeleteParticipantsFromGroupModal from './deleteParticipantsFromGroupModal'
import { rankCompetitors } from './roundRobinGroupRankCalculations'
import { useAppContext } from '../../../../../../../lib/contextLib'

const GroupParticipantsSection = ({ groupDetails, drawToShow }) => {
    const { user, setIsLoading } = useAppContext()
    const [showAddPlayersModal, setShowAddPlayersModal] = useState(false)
    const [groupParticipants, setGroupParticipants] = useState([])
    const [groupMatches, setGroupMatches] = useState([])
    const [showNotificationModal, setShowNotificationModal] = useState(false)
    const [showDeleteMatchesModal, setShowDeleteMatchesModal] = useState(false)
    const [rankedParticipantsList, setRankedParticipantsList] = useState([])
    const [isManualRankingEnabled, setIsManualRankingEnabled] = useState(false)

    const handleGetGroupParticipants = async (doCalculateSeed = false) => {
        try {
            setIsLoading(true)
            const groupParticipantsData = await getParticipantsOfGroup({
                ...groupDetails,
                ...drawToShow,
            })

            if (!groupParticipantsData) return

            const fetchParticipantDetails = async (participant) => {
                const participantData = await getSingleParticipant(
                    participant.attributes.participant_uuid
                )
                return {
                    ...participant,
                    attributes: {
                        ...participant.attributes,
                        participant: participantData,
                    },
                }
            }

            const fetchPlayerDetails = async (participant) => {
                const playerOnePromise = getSinglePlayer(
                    participant.attributes.participant.attributes.player_1_uuid
                )
                const playerTwoPromise = participant.attributes.participant
                    .attributes.player_2_uuid
                    ? getSinglePlayer(
                          participant.attributes.participant.attributes
                              .player_2_uuid
                      )
                    : Promise.resolve(null)

                const [playerOneData, playerTwoData] = await Promise.all([
                    playerOnePromise,
                    playerTwoPromise,
                ])

                return {
                    ...participant,
                    attributes: {
                        ...participant.attributes,
                        player_1: playerOneData,
                        player_2: playerTwoData,
                    },
                }
            }

            const participantsWithDetails = await Promise.all(
                groupParticipantsData.map(fetchParticipantDetails)
            )
            const participantsWithPlayers = await Promise.all(
                participantsWithDetails.map(fetchPlayerDetails)
            )

            setGroupParticipants(participantsWithPlayers)
            const groupMatches = await handleGetGroupMatches()
            const rankedParticipantsData = await rankCompetitors(
                participantsWithPlayers,
                groupMatches,
                doCalculateSeed
            )

            if (doCalculateSeed) {
                await calculateSeed({
                    tournament_uuid: groupDetails?.tournament_uuid,
                    gender: drawToShow?.gender?.gender_name,
                })
            }
            setRankedParticipantsList(rankedParticipantsData)
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleParticipantsMatches = async () => {
        if (
            !groupParticipants ||
            groupParticipants.length < 3 ||
            groupParticipants.length > 4
        ) {
            return
        }

        const generateCombinations = (arr, n) => {
            let result = []
            let f = (active, start, original) => {
                if (active.length === n) {
                    result.push(active)
                } else {
                    for (let i = start; i < original.length; i++) {
                        f([...active, original[i]], i + 1, original)
                    }
                }
            }
            f([], 0, arr)
            return result
        }

        const matchPromises = []
        const matches = []
        const participantCombinations = generateCombinations(
            groupParticipants,
            2
        )

        for (const [
            index,
            participantsPair,
        ] of participantCombinations.entries()) {
            const [participant1, participant2] = participantsPair

            const matchPromise = createTournamentMatch({
                draw_type: {
                    draw_type_name: drawToShow?.draw_type?.draw_type_name,
                },
                tournament_uuid: groupDetails?.tournament_uuid,
                is_double: drawToShow?.is_double,
                gender: { gender_name: drawToShow?.gender?.gender_name },
                round_number: groupDetails?.group_number,
                pair_number: index + 1,
                side_1_uuid: participant1?.attributes?.participant_uuid || null,
                side_2_uuid: participant2?.attributes?.participant_uuid || null,
            })

            matchPromises.push(matchPromise)
        }

        await Promise.all(matchPromises, matches)
        handleGetGroupMatches()
    }

    const handleGetGroupMatches = async () => {
        try {
            const groupMatchesData = await getGroupMatches({
                tournament_uuid: groupDetails?.tournament_uuid,
                is_double: drawToShow?.is_double,
                gender: { gender_name: drawToShow?.gender?.gender_name },
                draw_type: {
                    draw_type_name: drawToShow?.draw_type?.draw_type_name,
                },
                round_number: groupDetails?.group_number,
            })

            if (groupMatchesData) {
                setGroupMatches(groupMatchesData)
                return groupMatchesData
            }
        } catch (error) {
            throw new Error(error)
        }
    }

    const handleDeleteAllGroupMatches = async () => {
        const deletedMatchesPromise = await Promise.all(
            groupMatches?.map(async (match) => {
                const deletedMatch = await deleteMatch(match?.attributes?.uuid)
                if (deletedMatch) {
                    return deletedMatch
                }
            })
        )

        if (deletedMatchesPromise) {
            setShowDeleteMatchesModal(false)
            handleGetGroupMatches()
        }
    }

    useEffect(() => {
        handleGetGroupParticipants()
    }, [drawToShow])

    return (
        <Row>
            <Col>
                {rankedParticipantsList?.length < 4 &&
                    user?.role?.type !== 'competitor' && (
                        <Row>
                            <Col className="d-flex justify-content-center">
                                <Button
                                    type="secondary"
                                    onClick={() => {
                                        groupMatches?.length > 0
                                            ? setShowNotificationModal(true)
                                            : setShowAddPlayersModal(true)
                                    }}
                                >
                                    Dodaj takmičara
                                </Button>
                            </Col>
                        </Row>
                    )}
                {rankedParticipantsList?.length > 1 &&
                    user?.role?.type !== 'competitor' && (
                        <Row>
                            <Col className="d-flex justify-content-end">
                                <Row>
                                    <Col>
                                        <Form.Switch
                                            name="competition-finished-switch"
                                            label={
                                                'Omogući manuelno rangiranje u grupi'
                                            }
                                            checked={
                                                isManualRankingEnabled || false
                                            }
                                            onChange={() =>
                                                setIsManualRankingEnabled(
                                                    (prev) => !prev
                                                )
                                            }
                                            disabled={
                                                user?.role?.type ===
                                                'competitor'
                                                    ? true
                                                    : false
                                            }
                                        />
                                        <p className="small-text">
                                            Za poništavanje manuelnog
                                            rangiranja, postaviti svima isti
                                            rang
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )}

                {rankedParticipantsList?.map((participant, index) => {
                    return (
                        <GroupParticipantCard
                            key={index}
                            index={index}
                            participantData={{
                                uuid: participant?.participant_data?.attributes
                                    ?.uuid,
                                player_1:
                                    participant?.participant_data?.attributes
                                        ?.player_1,
                                player_2:
                                    participant?.participant_data?.attributes
                                        ?.player_2,
                                participant_uuid:
                                    participant?.participant_data?.attributes
                                        ?.participant_uuid,
                                group_manual_rank:
                                    participant?.participant_data?.attributes
                                        ?.group_manual_rank,
                            }}
                            handleGetGroupParticipants={() =>
                                handleGetGroupParticipants(true)
                            }
                            className="mt-2"
                            groupMatches={groupMatches}
                            isManualRankingEnabled={isManualRankingEnabled}
                        />
                    )
                })}

                {groupMatches?.length < 1 &&
                    rankedParticipantsList?.length > 2 &&
                    user?.role?.type !== 'competitor' && (
                        <Row>
                            <Col className="d-flex justify-content-end">
                                <Button
                                    onClick={() => handleParticipantsMatches()}
                                    className="mt-3"
                                >
                                    Kreiraj mečeve za ovu grupu
                                </Button>
                            </Col>
                        </Row>
                    )}
                {groupMatches?.length > 0 &&
                    user?.role?.type !== 'competitor' && (
                        <Row>
                            <Col className="d-flex justify-content-end">
                                <Button
                                    onClick={() =>
                                        setShowDeleteMatchesModal(true)
                                    }
                                    className="mt-3"
                                    type="secondary"
                                >
                                    Obriši sve mečeve grupe
                                </Button>
                            </Col>
                        </Row>
                    )}

                <Row className="gx-5 gy-3 mt-4">
                    {groupMatches?.map((match, index) => {
                        return (
                            <Col xs={12} md={4} key={index}>
                                <TournamentMatchCard
                                    matchDetails={{
                                        round_number:
                                            match?.attributes?.round_number,
                                        pair_number:
                                            match?.attributes?.pair_number,
                                        tournament_uuid:
                                            match?.attributes?.tournament_uuid,
                                        match,
                                        ...drawToShow,
                                    }}
                                    isDeleteEnabled={false}
                                    onDelete={handleGetGroupMatches}
                                    onChange={() =>
                                        handleGetGroupParticipants(true)
                                    }
                                />
                            </Col>
                        )
                    })}
                </Row>
            </Col>

            <AddPlayerToGroupModal
                show={showAddPlayersModal}
                onHide={() => setShowAddPlayersModal(false)}
                groupDetails={{
                    ...groupDetails,
                    ...drawToShow,
                }}
                handleGetGroupParticipants={() =>
                    handleGetGroupParticipants(true)
                }
            />

            <NotificationModal
                show={showNotificationModal}
                onHide={() => setShowNotificationModal(false)}
            />

            <DeleteParticipantsFromGroupModal
                show={showDeleteMatchesModal}
                onHide={() => setShowDeleteMatchesModal(false)}
                handleDeleteAllGroupMatches={handleDeleteAllGroupMatches}
            />
        </Row>
    )
}

export default GroupParticipantsSection
