import React, { useEffect, useState } from 'react'
import { Card, Col, Modal, Row } from 'react-bootstrap'
import Add from '../../../../../../assets/svg/Add'
import ArrowRight from '../../../../../../assets/svg/ArrowRight'
import Button from '../../../../../../components/Button'
import {
    addClubPhotosToGallery,
    getFirstMembership,
    getLastMembership,
} from '../../../../../../lib/api'
import {
    addMultiplePhoto,
    checkFileSize,
    formatDate,
    formatImgUrl,
    isArrayEmpty,
} from '../../../../../../lib/helpers'

import DownloadCloudOutlined from '../../../../../../assets/svg/DownloadCloudOutlined'
import { useAppContext } from '../../../../../../lib/contextLib'
import { downloadImage } from '../../../../../../utils/downloadimage'
import './style.scss'
const Profile = ({ club, setShowMembership, getClub }) => {
    const { user, setShowCustomToast } = useAppContext()
    const [addPhotoModal, setAddPhotoModal] = useState(false)
    const [imgs, setImgs] = useState([])
    const [previewImgs, setPreviewImgs] = useState([])
    const [lastMembership, setLastMembership] = useState()
    const [firstMembership, setFirstMembership] = useState()

    const clubDetails = [
        {
            label: 'Datum osnivanja',
            name: club?.attributes?.establishment_date
                ? formatDate(club?.attributes?.establishment_date)
                : false,
        },
        {
            label: 'Datum prvog učlanjenja',
            name: firstMembership
                ? formatDate(firstMembership?.attributes?.date_of_payment)
                : false,
        },
        {
            label: 'Region',
            name: club?.attributes?.region
                ? club?.attributes?.region?.region_name
                : false,
        },
        {
            label: 'Adresa',
            name: club?.attributes?.address
                ? `${club?.attributes?.address?.street}${
                      club?.attributes?.address?.postal_code
                          ? ', ' + club?.attributes?.address?.postal_code
                          : ''
                  }${
                      club?.attributes?.address?.city
                          ? club?.attributes?.address?.postal_code
                              ? ' ' + club?.attributes?.address?.city
                              : ', ' + club?.attributes?.address?.city
                          : ''
                  }${
                      club?.attributes?.address?.country
                          ? ', ' + club?.attributes?.address?.country
                          : ''
                  }`
                : false,
        },
        {
            label: 'Telefon kluba',
            name: club?.attributes?.phone ? club?.attributes?.phone : false,
        },
        {
            label: 'Email kluba',
            name: club?.attributes?.email ? club?.attributes?.email : false,
        },
        {
            label: 'Website',
            name: club?.attributes?.website_url
                ? club?.attributes?.website_url
                : false,
        },
        {
            label: 'Broj trenera',
            name: club?.attributes?.number_of_coaches
                ? club?.attributes?.number_of_coaches
                : false,
        },
    ]

    const handleLastMembership = async () => {
        const lastMembership = await getLastMembership(club?.attributes?.uuid)

        if (lastMembership) {
            setLastMembership(lastMembership[0])
        }
    }

    const handleFirstMembership = async () => {
        const firstClubMembership = await getFirstMembership(
            club?.attributes?.uuid
        )

        if (firstClubMembership) {
            setFirstMembership(firstClubMembership[0])
        }
    }

    const handleAddClubPhotos = async () => {
        try {
            const data = await addClubPhotosToGallery(
                club?.attributes?.uuid,
                imgs,
                club?.attributes?.gallery?.length > 0
                    ? club?.attributes?.gallery
                    : []
            )
            if (data && data?.attributes?.gallery?.length > 0) {
                getClub(club?.attributes?.uuid)
                setImgs([])
                setPreviewImgs([])
                setAddPhotoModal(false)
                setShowCustomToast({
                    show: true,
                    type: 'success',
                    message: 'Galerija kluba uspešno ažurirana.',
                })
            }
        } catch (error) {
            setShowCustomToast({
                show: true,
                type: 'error',
                message: 'Došlo je do greške.',
            })
        }
    }

    const handleClosePhotosModal = () => {
        setImgs([])
        setPreviewImgs([])
        setAddPhotoModal(false)
    }

    useEffect(() => {
        handleLastMembership()
        handleFirstMembership()
    }, [club])

    return (
        <Row md={12} className="d-flex mt-3 club-profile-section m-0">
            <Col className="px-0" xs={12} md>
                <Card className="admin-flow-club-profile-card-wrapper mb-2 mb-md-4">
                    <Card.Body className="admin-flow-club-profile-card-body details-card">
                        <Row className="pb-5">
                            <Col className="d-flex align-items-center">
                                <h2>Detalji</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {clubDetails?.map(
                                    ({ name, label }, index) =>
                                        name && (
                                            <Col
                                                className="ps-3 details-single-info"
                                                key={`${
                                                    name ? name : label
                                                }-${index}`}
                                            >
                                                <p className="data-info">
                                                    {name}
                                                </p>
                                                <p className="small-text title">
                                                    {label}
                                                </p>
                                            </Col>
                                        )
                                )}
                                {!isArrayEmpty(club?.attributes?.staff)
                                    ? club?.attributes?.staff?.map((staff) =>
                                          [
                                              {
                                                  label: staff?.title,
                                                  name: staff?.family_name
                                                      ? `${staff?.given_name} ${staff?.family_name}`
                                                      : staff?.given_name,
                                              },
                                              {
                                                  label: `${staff?.title} - Telefon`,
                                                  name: staff?.phone
                                                      ? staff?.phone
                                                      : false,
                                              },
                                              {
                                                  label: `${staff?.title} - Email`,
                                                  name: staff?.email
                                                      ? staff?.email
                                                      : false,
                                              },
                                          ].map(
                                              ({ name, label }, index) =>
                                                  name && (
                                                      <Col
                                                          className="ps-3 details-single-info"
                                                          key={`${
                                                              name
                                                                  ? name
                                                                  : label
                                                          }-${index}`}
                                                      >
                                                          <p className="data-info">
                                                              {name}
                                                          </p>
                                                          <p className="small-text title">
                                                              {label}
                                                          </p>
                                                      </Col>
                                                  )
                                          )
                                      )
                                    : null}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className="admin-flow-club-profile-card-wrapper">
                    <Card.Body className="admin-flow-club-profile-card-body history-card">
                        <Row className="pb-5">
                            <Col className="d-flex align-items-center">
                                <h2>Istorijat kluba</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className="history-text">
                                    {club?.attributes?.club_history
                                        ? club?.attributes?.club_history
                                        : 'Nema informacija o istoriji kluba'}
                                </p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="px-0" xs={12} md>
                <Card className="admin-flow-club-profile-card-wrapper">
                    <Card.Body className="admin-flow-club-profile-card-body courts-card">
                        <Row className="pb-5">
                            <Col>
                                <h2>Tereni</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex flex-column">
                                <Col className="courts-card-section">
                                    <Row className="align-items-center single-court">
                                        <Col className="d-flex align-items-center">
                                            <img
                                                src="/images/slag.png"
                                                alt="slag"
                                                className="me-2"
                                                width={'20px'}
                                                height={'20px'}
                                                style={{
                                                    maxWidth: '20px',
                                                    maxHeight: '20px',
                                                    borderRadius: '50%',
                                                }}
                                            />
                                            <Col>
                                                <p className="courts-number">
                                                    {club?.attributes?.courts
                                                        ?.number_of_clay_courts
                                                        ? club?.attributes?.courts?.number_of_clay_courts.toString()
                                                        : '0'}
                                                </p>
                                                <p className="small-text small-text-gray">
                                                    Šljaka
                                                </p>
                                            </Col>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center single-court">
                                        <Col className="d-flex align-items-center">
                                            <img
                                                src="/images/hard.jpg"
                                                alt="fake grass"
                                                className="me-2"
                                                width={'20px'}
                                                height={'20px'}
                                                style={{
                                                    maxWidth: '20px',
                                                    maxHeight: '20px',
                                                    borderRadius: '50%',
                                                }}
                                            />
                                            <Col>
                                                <p className="courts-number">
                                                    {club?.attributes?.courts
                                                        ?.number_of_hard_courts
                                                        ? club?.attributes?.courts?.number_of_hard_courts.toString()
                                                        : '0'}
                                                </p>
                                                <p className="small-text small-text-gray">
                                                    Hard
                                                </p>
                                            </Col>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center single-court">
                                        <Col className="d-flex align-items-center">
                                            <img
                                                src="/images/fake-grass.png"
                                                alt="slag"
                                                className="me-2"
                                                width={'20px'}
                                                height={'20px'}
                                                style={{
                                                    maxWidth: '20px',
                                                    maxHeight: '20px',
                                                    borderRadius: '50%',
                                                }}
                                            />
                                            <Col>
                                                <p className="courts-number">
                                                    {club?.attributes?.courts
                                                        ?.number_of_carpet_courts
                                                        ? club?.attributes?.courts?.number_of_carpet_courts.toString()
                                                        : '0'}
                                                </p>
                                                <p className="small-text small-text-gray">
                                                    Tepih
                                                </p>
                                            </Col>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center single-court">
                                        <Col className="d-flex align-items-center">
                                            <img
                                                src="/images/tennis_ball.png"
                                                alt="slag"
                                                className="me-2"
                                                width={'20px'}
                                                height={'20px'}
                                                style={{
                                                    maxWidth: '20px',
                                                    maxHeight: '20px',
                                                    borderRadius: '50%',
                                                }}
                                            />
                                            <Col>
                                                <p className="courts-number">
                                                    {club?.attributes?.courts
                                                        ?.number_of_indoor_courts
                                                        ? club?.attributes?.courts?.number_of_indoor_courts.toString()
                                                        : '0'}
                                                </p>
                                                <p className="small-text small-text-gray">
                                                    Zatvoreni
                                                </p>
                                            </Col>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center single-court">
                                        <Col className="d-flex align-items-center">
                                            <img
                                                src="/images/reflectors.png"
                                                alt="slag"
                                                className="me-2"
                                                width={'20px'}
                                                height={'20px'}
                                                style={{
                                                    maxWidth: '20px',
                                                    maxHeight: '20px',
                                                    borderRadius: '50%',
                                                }}
                                            />
                                            <Col>
                                                <p className="courts-number">
                                                    {club?.attributes?.courts
                                                        ?.number_of_reflector_courts
                                                        ? club?.attributes?.courts?.number_of_reflector_courts.toString()
                                                        : '0'}
                                                </p>
                                                <p className="small-text small-text-gray">
                                                    Pod reflektorima
                                                </p>
                                            </Col>
                                        </Col>
                                    </Row>
                                </Col>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col className="px-0" xs={12} md>
                <Card className="admin-flow-club-profile-card-wrapper mb-2 mb-md-4">
                    <Card.Body className="admin-flow-club-profile-card-body details-card">
                        <Row className="pb-5">
                            <Col className="d-flex align-items-center">
                                <h2>Galerija</h2>
                            </Col>
                        </Row>
                        {!isArrayEmpty(club?.attributes?.gallery) ? (
                            <Row className="g-2">
                                {club?.attributes?.gallery?.map(
                                    (img, index) => (
                                        <Col
                                            xs={3}
                                            key={index}
                                            className="club-uploaded-img"
                                        >
                                            <div
                                                className="single-club-image-wrapper"
                                                onClick={() =>
                                                    downloadImage(
                                                        formatImgUrl(
                                                            img?.photo?.data
                                                                ?.attributes
                                                                ?.url
                                                        ),
                                                        `${img?.photo?.data?.attributes?.hash}`,
                                                        `${img?.photo?.data?.attributes?.ext}`
                                                    )
                                                }
                                            >
                                                <div className="download-icon-wrapper">
                                                    <DownloadCloudOutlined
                                                        width="24px"
                                                        height="24px"
                                                        className="download-icon"
                                                    />
                                                </div>
                                                <div className="single-image-wrapper">
                                                    <img
                                                        src={formatImgUrl(
                                                            img?.photo?.data
                                                                ?.attributes
                                                                ?.url
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                )}
                            </Row>
                        ) : (
                            <p className="text-start">Nema unetih slika</p>
                        )}
                    </Card.Body>
                    {(isArrayEmpty(club?.attributes?.gallery) ||
                        club?.attributes?.gallery?.length < 8) && (
                        <Card.Footer className="admin-flow-club-profile-card-footer">
                            <Row>
                                <Col className="d-flex align-items-center justify-content-end">
                                    <Button
                                        type="text"
                                        leadingIcon={<Add />}
                                        onClick={() => setAddPhotoModal(true)}
                                    >
                                        Dodaj fotografije
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Footer>
                    )}
                </Card>
                <Card className="admin-flow-club-profile-card-wrapper">
                    <Card.Body className="admin-flow-club-profile-card-body membership-card">
                        <Row className="pb-5">
                            <Col className="d-flex align-items-center">
                                <h2>Članarina</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="membership-view">
                                <Col
                                    className={`d-flex align-items-center justify-content-center single-membership`}
                                >
                                    <Col className="d-flex justify-content-center">
                                        <p className={`membership-year`}>
                                            {lastMembership
                                                ? formatDate(
                                                      lastMembership?.attributes
                                                          ?.date_of_payment
                                                  )
                                                : 'Nema članarina.'}
                                        </p>
                                    </Col>
                                </Col>
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer className="admin-flow-club-profile-card-footer">
                        <Row>
                            <Col className="d-flex align-items-center justify-content-end">
                                <Button
                                    type="text"
                                    endIcon={
                                        <ArrowRight
                                            disabled={
                                                !lastMembership &&
                                                user?.role?.type !== 'admin'
                                            }
                                        />
                                    }
                                    onClick={() => setShowMembership(true)}
                                    disabled={
                                        !lastMembership &&
                                        user?.role?.type !== 'admin'
                                    }
                                >
                                    Detaljnije
                                </Button>
                            </Col>
                        </Row>
                    </Card.Footer>
                </Card>
            </Col>

            <Modal
                centered
                show={addPhotoModal}
                onHide={() => handleClosePhotosModal()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h2>Dodaj fotografije</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="upload-images-wrapper">
                        <h4>{`Moguće dodati ${
                            club?.attributes?.gallery || previewImgs?.length > 0
                                ? 'još'
                                : 'ukupno'
                        } ${
                            club?.attributes?.gallery
                                ? 8 -
                                  club?.attributes?.gallery?.length -
                                  previewImgs?.length
                                : 8 - previewImgs?.length
                        } ${
                            club?.attributes?.gallery
                                ? club?.attributes?.gallery?.length +
                                      previewImgs?.length ===
                                  7
                                    ? 'fotografiju'
                                    : club?.attributes?.gallery?.length +
                                          previewImgs?.length >=
                                          4 &&
                                      club?.attributes?.gallery?.length +
                                          previewImgs?.length <
                                          7
                                    ? 'fotografije'
                                    : ' fotografija'
                                : ' fotografija'
                        }`}</h4>

                        <Row className="g-2">
                            {previewImgs?.map((img, index) => (
                                <Col
                                    xs={3}
                                    key={index}
                                    className="club-uploaded-img-modal"
                                >
                                    <img src={img} />
                                </Col>
                            ))}
                        </Row>
                        {(isArrayEmpty(club?.attributes?.gallery) ||
                            previewImgs?.length +
                                club?.attributes?.gallery?.length <
                                8) && (
                            <>
                                <label htmlFor="clubImages">
                                    Dodaj fotografiju
                                </label>
                                <input
                                    type="file"
                                    id="clubImages"
                                    name="profile-img"
                                    className="d-none invisible"
                                    onChange={(e) =>
                                        addMultiplePhoto(
                                            e,
                                            imgs,
                                            setImgs,
                                            previewImgs,
                                            setPreviewImgs,
                                            checkFileSize
                                        )
                                    }
                                    accept="image/jpg, image/jpeg, image/png"
                                    multiple
                                    disabled={previewImgs.length >= 8 && true}
                                />
                            </>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="text"
                        onClick={() => handleClosePhotosModal()}
                    >
                        Odustani
                    </Button>
                    <Button onClick={() => handleAddClubPhotos()}>Dodaj</Button>
                </Modal.Footer>
            </Modal>
        </Row>
    )
}

export default Profile
