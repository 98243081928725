import React from 'react'
import { Col, Row } from 'react-bootstrap'

import CheckBox from '../../Checkbox'
import TextField from '../../TextField'

const fieldTypes = [
    {
        name: 'number_of_clay_courts',
        nameToShow: 'Šljaka',
    },
    {
        name: 'number_of_carpet_courts',
        nameToShow: 'Tepih',
    },
    {
        name: 'number_of_hard_courts',
        nameToShow: 'Hard',
    },
    {
        name: 'number_of_indoor_courts',
        nameToShow: 'Zatvoreni',
    },
    {
        name: 'number_of_reflector_courts',
        nameToShow: 'Pod reflektorima',
    },
]

const AllFields = ({
    text,
    id,
    updateClub,
    fieldsType,
    totalNumber,
    newClub,
}) => (
    <Col
        md={12}
        className="d-flex align-items-center justify-content-between all-fields-wrapper"
    >
        <p className="all-fields-text">{text}</p>
        <TextField
            id={id}
            type="number"
            value={newClub.courts[fieldsType][totalNumber]}
            onChange={(e) =>
                updateClub((state) => ({
                    ...state,
                    courts: {
                        ...state.courts,
                        [fieldsType]: {
                            ...state.courts[fieldsType],
                            total_number: +e.target.value,
                        },
                    },
                }))
            }
            className="all-fields-input"
            min={0}
        />
    </Col>
)

const SingleField = ({
    name,
    nameToShow,
    updateClub,
    newClub,
    fieldType,
    subType,
}) => {
    return (
        <Col
            md={12}
            className="d-flex align-items-center justify-content-between single-field-wrapper mt-3"
        >
            <CheckBox
                checked={
                    // subType
                    //     ? newClub.courts[fieldType][subType][name] > 0
                    //     : newClub.courts[fieldType][name] > 0
                    newClub?.courts?.[name] > 0
                }
                onChange={() =>
                    // updateClub((state) => ({
                    //     ...state,
                    //     courts: {
                    //         ...state.courts,
                    //         [fieldType]: {
                    //             ...state.courts[fieldType],
                    //             [name]: 0,
                    //         },
                    //     },
                    // }))
                    updateClub((state) => ({
                        ...state,
                        courts: {
                            ...state.courts,
                            [name]: 0,
                        },
                    }))
                }
                label={nameToShow}
                className="single-field-checkbox"
                name={
                    // subType
                    //     ? `${fieldType}-${subType}-${name}`
                    //     : `${fieldType}-${name}`
                    name
                }
            />
            <TextField
                id={name}
                type="number"
                value={newClub?.courts?.[name]}
                onChange={(e) =>
                    updateClub((state) => ({
                        ...state,
                        courts: {
                            ...state.courts,
                            [name]: +e.target.value,
                        },
                    }))
                }
                className="single-field-input"
                min={0}
            />
        </Col>
    )
}

const StepFive = ({ step, updateClub, newClub }) => {
    return (
        <Row className="step-five-wrapper">
            <form>
                <Col md={12}>
                    <h4>Broj terena</h4>
                </Col>
                <Col md={12} className="mt-4">
                    {fieldTypes.map((field, i) => (
                        <SingleField
                            key={i}
                            newClub={newClub}
                            updateClub={updateClub}
                            {...field}
                        />
                    ))}
                </Col>
            </form>
        </Row>
    )
}

export default StepFive
