import html2PDF from 'jspdf-html2canvas'
import { useRef } from 'react'
import { Col, Modal, Row, Table } from 'react-bootstrap'
import { formatDate } from '../../lib/helpers'
import Button from '../Button'
import ScheduleCard from '../ScheduleCard'

import './styles.scss'

const PDFScheduleModal = ({ show, onHide, competitionProfileData }) => {
    const pdfRef = useRef(null)

    const handleDownload = () => {
        let pdfdoc = document.getElementById('schedule-pdf')

        html2PDF(pdfdoc, {
            jsPDF: {
                format: 'a4',
            },
            imageType: 'image/jpeg',
            output: `${competitionProfileData?.attributes?.name}-Raspored.pdf`,
        })
    }

    return (
        <Modal fullscreen={true} show={show} onHide={onHide}>
            <Modal.Body>
                <Row className="mb-3">
                    <Col className="d-flex">
                        <Button
                            type="secondary"
                            onClick={handleDownload}
                            className="me-3"
                        >
                            Eksportuj u PDF
                        </Button>
                        <Button type="secondary" onClick={onHide}>
                            Odustani
                        </Button>
                    </Col>
                </Row>

                <Row id="schedule-pdf" ref={pdfRef}>
                    <Col>
                        <h2 className="pb-3">
                            {competitionProfileData?.attributes?.name} -
                            Raspored
                        </h2>

                        <Table striped bordered>
                            <thead>
                                <tr>
                                    <th>Datum</th>
                                    <th>Grad, Klub</th>
                                    <th>Kategorija</th>
                                    <th>Konkurencija</th>
                                    <th>Glavni sudija</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {formatDate(new Date().toISOString())}
                                    </td>
                                    <td>
                                        {
                                            competitionProfileData?.attributes
                                                ?.club_organizer_id?.data
                                                ?.attributes?.name
                                        }
                                        ,{' '}
                                        {
                                            competitionProfileData?.attributes
                                                ?.club_organizer_id?.data
                                                ?.attributes?.city
                                        }
                                    </td>
                                    <td>
                                        {
                                            competitionProfileData?.attributes
                                                ?.category
                                        }
                                    </td>
                                    <td>
                                        {
                                            competitionProfileData?.attributes
                                                ?.competition
                                        }
                                    </td>
                                    <td>
                                        {
                                            competitionProfileData?.attributes
                                                ?.main_referee_id?.name
                                        }{' '}
                                        {
                                            competitionProfileData?.attributes
                                                ?.main_referee_id?.surname
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </Table>

                        <Row>
                            {competitionProfileData?.attributes?.fields
                                ?.sort(
                                    (a, b) => a.field_number - b.field_number
                                )
                                .map((field, index) => (
                                    <Col
                                        xs={12}
                                        md={3}
                                        className="pt-3"
                                        key={index}
                                        style={{
                                            width: '350px',
                                            borderRight: '1px solid #ccc',
                                        }}
                                    >
                                        <h5 className="mb-3 text-center">
                                            {field?.field_number}. teren
                                        </h5>
                                        {competitionProfileData?.attributes?.schedules
                                            ?.filter(
                                                (schedule) =>
                                                    schedule?.court ===
                                                    field?.field_number
                                            )
                                            .sort((a, b) => {
                                                if (!a?.time) return -1
                                                if (!b?.time) return 1

                                                const dateTime1 = new Date(
                                                    `${a?.date}T${a?.time}`
                                                )
                                                const dateTime2 = new Date(
                                                    `${b?.date}T${b?.time}`
                                                )
                                                return dateTime1 - dateTime2
                                            })
                                            .map((schedule, index) => {
                                                const match =
                                                    competitionProfileData?.attributes?.matches?.find(
                                                        (match) =>
                                                            match?.id ===
                                                            schedule?.match_id
                                                    )

                                                return (
                                                    <ScheduleCard
                                                        key={index}
                                                        data={match}
                                                        schedule={schedule}
                                                        className={'mb-2'}
                                                        hideRemoveButton={true}
                                                        hideLinks={true}
                                                    />
                                                )
                                            })}
                                    </Col>
                                ))}
                        </Row>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default PDFScheduleModal
