import { Card, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { formatImgUrl, formatDate, formatTime } from '../../lib/helpers'
import avatar from '../../assets/images/tennis-player-with-racket.png'
import Button from '../Button'
import { removeSchedule } from '../../lib/api'

const ScheduleCard = ({
    data,
    schedule,
    className,
    handleInitCompetitionData,
    hideRemoveButton = false,
    hideLinks = false,
}) => {
    const handleRemoveSchedule = async (scheduleId) => {
        const removedSchedule = await removeSchedule(scheduleId)

        if (removedSchedule) {
            handleInitCompetitionData()
        }
    }

    return (
        <Card
            className={`finish-result-card px-0 ${className} ${
                data?.is_side_one_winner !== null
                    ? 'finished-match-result-card'
                    : ''
            }`}
        >
            <Card.Body>
                <Row className="row-segment">
                    <Col>
                        <p>{formatDate(schedule?.date)}</p>
                        <p className="small-text">{schedule?.time_text}</p>
                        <p className="small-text">
                            {formatTime(schedule?.time)}
                        </p>
                    </Col>
                    <Col className="text-end">
                        <p>{data?.gender}</p>
                        <p className="small-text">
                            {data?.is_double ? 'Dubl' : 'Singl'}
                        </p>
                        <p className="small-text">{schedule?.round}. kolo</p>
                    </Col>

                    {schedule?.first_free_field === true && (
                        <Col xs={12}>
                            <p className="small-text">
                                Igra se na prvom slobodnom terenu
                            </p>
                        </Col>
                    )}
                </Row>
                <Row
                    className={
                        data?.is_double ? `last-row-segment` : 'row-segment'
                    }
                >
                    <Col className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center competitor-name">
                            <img
                                src={
                                    data?.side_1_player_1?.img
                                        ? formatImgUrl(
                                              data?.side_1_player_1?.img?.url
                                          )
                                        : avatar
                                }
                                className="competitor-img"
                            />
                            <div>
                                {data ? (
                                    <Link
                                        to={`/takmicari/${data?.side_1_player_1?.id}`}
                                        className={`${hideLinks ? '' : 'link'}`}
                                    >{`${data?.side_1_player_1?.name} ${data?.side_1_player_1?.surname} ${data?.side_1_player_1?.code}`}</Link>
                                ) : (
                                    <p>Nepoznat</p>
                                )}
                            </div>
                        </div>
                    </Col>
                </Row>
                {data?.is_double && (
                    <Row className="row-segment">
                        <Col className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center competitor-name">
                                <img
                                    src={
                                        data?.side_1_player_2?.img
                                            ? formatImgUrl(
                                                  data?.side_1_player_2?.img
                                                      ?.url
                                              )
                                            : avatar
                                    }
                                    className="competitor-img"
                                />
                                <div>
                                    {data ? (
                                        <Link
                                            to={`/takmicari/${data?.side_1_player_2?.id}`}
                                            className={`${
                                                hideLinks ? '' : 'link'
                                            }`}
                                        >{`${data?.side_1_player_2?.name} ${data?.side_1_player_2?.surname} ${data?.side_1_player_2?.code}`}</Link>
                                    ) : (
                                        <p>Nepoznat</p>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                )}

                <Row
                    className={
                        data?.is_double ? `last-row-segment` : 'row-segment'
                    }
                >
                    <Col className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center competitor-name">
                            <img
                                src={
                                    data?.side_2_player_1?.img
                                        ? formatImgUrl(
                                              data?.side_2_player_1?.img?.url
                                          )
                                        : avatar
                                }
                                className="competitor-img"
                            />
                            <div>
                                {data ? (
                                    <Link
                                        to={`/takmicari/${data?.side_2_player_1?.id}`}
                                        className={`${hideLinks ? '' : 'link'}`}
                                    >{`${data?.side_2_player_1?.name} ${data?.side_2_player_1?.surname} ${data?.side_2_player_1?.code}`}</Link>
                                ) : (
                                    <p>Nepoznat</p>
                                )}
                            </div>
                        </div>
                    </Col>
                </Row>

                {data?.is_double && (
                    <Row className="row-segment">
                        <Col className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center competitor-name">
                                <img
                                    src={
                                        data?.side_2_player_2?.img
                                            ? formatImgUrl(
                                                  data?.side_2_player_2?.img
                                                      ?.url
                                              )
                                            : avatar
                                    }
                                    className="competitor-img"
                                />
                                <div>
                                    {data ? (
                                        <Link
                                            to={`/takmicari/${data?.side_2_player_2?.id}`}
                                            className={`${
                                                hideLinks ? '' : 'link'
                                            }`}
                                        >{`${data?.side_2_player_2?.name} ${data?.side_2_player_2?.surname} ${data?.side_2_player_2?.code}`}</Link>
                                    ) : (
                                        <p>Nepoznat</p>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                )}
                {!hideRemoveButton && (
                    <Row>
                        <Col className="px-0">
                            <Button
                                type="text"
                                className="w-100"
                                onClick={() =>
                                    handleRemoveSchedule(schedule?.id)
                                }
                            >
                                Ukloni meč
                            </Button>
                        </Col>
                    </Row>
                )}
            </Card.Body>
        </Card>
    )
}

export default ScheduleCard
