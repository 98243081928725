import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Close from '../../../assets/svg/CloseOutlined'
import { useAppContext } from '../../../lib/contextLib'

import { useNavigate } from 'react-router-dom'
import DraftPlayerEdit from './DraftPlayerEdit'
import PlayerEdit from './PlayerEdit'
import './style.scss'

const EditProfile = ({ setIsEditing }) => {
    const { user } = useAppContext()
    const navigate = useNavigate()

    const handleExit = () => {
        navigate(`/takmicari`)
    }

    const handleContentShow = () => {
        switch (user?.role?.type) {
            case 'admin':
                return <DraftPlayerEdit setIsEditing={setIsEditing} />
            case 'competitor':
                return user?.data?.attributes?.status?.status_name !==
                    'draft' ? (
                    <PlayerEdit setIsEditing={setIsEditing} />
                ) : (
                    <DraftPlayerEdit setIsEditing={setIsEditing} />
                )
            default:
                return <DraftPlayerEdit setIsEditing={setIsEditing} />
        }
    }

    return (
        <Col className="player-edit-profile align-items-start">
            <Row className="mb-4">
                <Col className="d-flex align-items-center justify-content-between">
                    <h1>Detalji profila</h1>
                    <Close
                        role="button"
                        onClick={(e) =>
                            setIsEditing ? setIsEditing(false) : handleExit()
                        }
                    />
                </Col>
            </Row>
            <Row>
                <Col className="d-flex flex-column align-items-center">
                    {handleContentShow()}
                </Col>
            </Row>
        </Col>
    )
}

export default EditProfile
